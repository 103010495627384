import React, { useState } from 'react';
import {
  Typography,
  Paper,
  Box,
  CircularProgress,
  Grid,
  LinearProgress,
  Backdrop,
} from '@material-ui/core';

import clsx from 'clsx';
import '@fontsource/roboto';
import useAuth from 'hooks/useAuth';
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { useDasboardStyles } from './Style';
import { SScheduler } from '../Scheduler/index';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import moment from 'moment';
import { startOfDay } from 'date-fns';

const LinearProgressYellow = withStyles(() => ({
  colorPrimary: {
    backgroundColor: '#E8EBF6',
  },
  bar: {
    backgroundColor: 'rgb(255,154,29)',
  },
}))(LinearProgress);

const LinearProgressBlue = withStyles(() => ({
  colorPrimary: {
    backgroundColor: '#E8EBF6',
  },
  bar: {
    backgroundColor: 'rgb(31,41,156)',
  },
}))(LinearProgress);

// const LinearProgressRed = withStyles(() => ({
//   colorPrimary: {
//     backgroundColor: "#E8EBF6",
//   },
//   bar: {
//     backgroundColor: "rgb(233,89,74)",
//   },
// }))(LinearProgress);

const LinearProgressGreen = withStyles(() => ({
  colorPrimary: {
    backgroundColor: '#E8EBF6',
  },
  bar: {
    backgroundColor: '#3D9E5A',
  },
}))(LinearProgress);

export function SDashboard(props) {
  let theme = createTheme();
  theme = responsiveFontSizes(theme);
  const classes = useDasboardStyles();

  const auth = useAuth();

  // const [allShift, setAllShifts] = useState([]);
  const [todaysShift, setTodaysShift] = useState(0);
  const [currentShiftEmp, setCurrentShiftEmp] = useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [counts, setCounts] = React.useState({
    genderCount: {
      total: 0,
      male: 0,
      female: 0,
      others: 0,
    },
    absent: 0,
    attendence: 0,
    late: 0,
    totalProjects: 0,
    newJoiners: 0,
    onHoliday: 0,
    todaysTasks: 0,
  });

  const [shiftsDashboardData, setShiftsDashboardData] = useState({
    inProgress: 0,
    assigned: 0,
    completed: 0,
  });

  const getAllShifts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/shift/getAllShifts`,
        {
          headers: {
            token: auth.token,
          },
        }
      );
      if (response.status === 200) {
        const data = response.data.data;
        // setAllShifts(data);

        const todaysShifts = data.filter((shift) => {
          const start = moment(shift.start_time).format('YYYY-MM-DD');
          const end = moment(shift.end_time).format('YYYY-MM-DD');
          const current = moment().format('YYYY-MM-DD');
          return start <= current && end >= current;
        });
        let employeeArr = [
          ...new Set(
            todaysShifts.map((shift) => {
              return shift.employee_id;
            })
          ),
        ];

        setTodaysShift(todaysShifts.length);
        setCurrentShiftEmp(employeeArr.length);
      }
    } catch (e) {
      console.log('Err while fetchin shifts', e);
    }
  };

  const getResourceUtil = () => {
    let percentage = (
      Math.round((currentShiftEmp / counts.genderCount.total) * 100 * 10) / 10
    ).toFixed(1);
    return isFinite(percentage) ? percentage : 0;
  };

  const getCounts = async () => {
    const orgId = auth.user.org_id._id;
    let count = {};
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/org/getCounts/${orgId}`,
        {
          headers: {
            token: auth.token,
          },
        }
      );

      if (response.data.status === true) {
        count = response.data.data;
      }
      setCounts({ ...count });
    } catch (e) {
      console.log(e);
    }
  };

  const getDashboardData = () => {
    try {
      let date = new Date().toISOString();
      date = startOfDay(new Date());
      date = moment(date).toISOString();
      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/scheduler/dashboard-data?date=${date}`)
        .then((response) => {
          // if (response.data.status === true) {
          // count = response.data.data;
          setShiftsDashboardData(response.data);
          // }
        });
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    setIsLoading(true);
    getDashboardData();
    getCounts();
    getAllShifts();
    setIsLoading(false);
  }, []);

  const { assigned, inProgress, completed } = shiftsDashboardData;

  const shiftScheduledToday = assigned;

  return (
    <React.Fragment>
      <div className='root'>
        <ThemeProvider theme={theme}>
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: props.isDrawerOpen,
            })}
          >
            <Typography
              style={{
                textAlign: 'left',
                minWidth: '230px',
              }}
            >
              Home {'>'} Shift Management {'>'} Dashboard
            </Typography>

            <Grid
              container
              spacing={2}
              style={{ marginTop: '10px', minWidth: '170px' }}
            >
              <Grid item xs={12} md={12} lg={4}>
                {/* ---- Paper Cards -------- */}
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3} lg={6}>
                    <Paper
                      style={{
                        backgroundColor: '#1F299C',
                        padding: '1px 0px 0px 10px',
                        minWidth: '170px',
                      }}
                    >
                      <div
                        style={{
                          height: '170px',
                          position: 'relative',
                        }}
                      >
                        <h2
                          style={{
                            color: 'white',
                            textAlign: 'left',
                            marginBottom: '0px',
                          }}
                        >
                          Shift Schedule
                        </h2>
                        <div
                          style={{
                            color: 'white',
                            textAlign: 'left',
                            marginTop: '0px',
                          }}
                        >
                          Today
                        </div>
                        <div
                          style={{
                            position: 'absolute',
                            right: '10px',
                            bottom: '10px',
                            color: 'white',
                            fontWeight: '100',
                            fontSize: '.8em',
                          }}
                        >
                          <Typography
                            variant='h2'
                            component={'span'}
                            style={{
                              fontWeight: '700',
                            }}
                          >
                            {shiftScheduledToday}
                          </Typography>
                          <br></br>
                          <span>Total </span>
                        </div>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={6}>
                    <Paper
                      style={{
                        backgroundColor: '#1F299C',
                        padding: '1px 0px 0px 10px',
                      }}
                    >
                      <div
                        style={{
                          height: '170px',
                          position: 'relative',
                        }}
                      >
                        <h2
                          style={{
                            color: 'white',
                            textAlign: 'left',
                            marginBottom: '0px',
                          }}
                        >
                          On Holiday
                        </h2>
                        <div
                          style={{
                            color: 'white',
                            textAlign: 'left',
                            marginTop: '0px',
                          }}
                        >
                          Current Week
                        </div>
                        <div
                          style={{
                            position: 'absolute',
                            right: '10px',
                            bottom: '10px',
                            color: 'white',
                            fontWeight: '100',
                            fontSize: '.8em',
                          }}
                        >
                          <Typography
                            variant='h2'
                            component={'span'}
                            style={{
                              fontWeight: '700',
                            }}
                          >
                            {counts.onHoliday}
                          </Typography>
                          <br></br>
                          <span>Employee </span>
                        </div>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={6}>
                    <Paper
                      style={{
                        backgroundColor: '#1F299C',
                        padding: '1px 0px 0px 10px',
                      }}
                    >
                      <div
                        style={{
                          height: '170px',
                          position: 'relative',
                        }}
                      >
                        <h2
                          style={{
                            color: 'white',
                            textAlign: 'left',
                            marginBottom: '0px',
                          }}
                        >
                          Attendance
                        </h2>
                        <div
                          style={{
                            color: 'white',
                            textAlign: 'left',
                            marginTop: '0px',
                          }}
                        >
                          Today
                        </div>
                        <div
                          style={{
                            position: 'absolute',
                            right: '10px',
                            bottom: '10px',
                            color: 'white',
                            fontWeight: '100',
                            fontSize: '.8em',
                          }}
                        >
                          <Typography
                            variant='h2'
                            component={'span'}
                            style={{
                              fontWeight: '700',
                            }}
                          >
                            {counts.attendence}
                          </Typography>
                          <br></br>
                          <span>Total </span>
                        </div>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={6}>
                    <Paper
                      style={{
                        backgroundColor: '#EE433C',
                        padding: '1px 0px 0px 10px',
                      }}
                    >
                      <div
                        style={{
                          height: '170px',
                          position: 'relative',
                        }}
                      >
                        <h2
                          style={{
                            color: 'white',
                            textAlign: 'left',
                            marginBottom: '0px',
                          }}
                        >
                          Late
                        </h2>
                        <div
                          style={{
                            color: 'white',
                            textAlign: 'left',
                            marginTop: '0px',
                          }}
                        >
                          Today
                        </div>
                        <div
                          style={{
                            position: 'absolute',
                            right: '10px',
                            bottom: '10px',
                            color: 'white',
                            fontWeight: '100',
                            fontSize: '.8em',
                          }}
                        >
                          <Typography
                            variant='h2'
                            component={'span'}
                            style={{
                              fontWeight: '700',
                            }}
                          >
                            {counts.late}
                          </Typography>
                          <br></br>
                          <span>Total </span>
                        </div>
                      </div>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
              {/* Paper card ends........................ */}

              <Grid item xs={12} md={12} lg={8} style={{ textAlign: 'left' }}>
                <Paper>
                  <div>
                    <Grid
                      container
                      spacing={1}
                      style={{
                        padding: '5px 10px 5px 10px',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Grid item xs={12} md={5} lg={5}>
                        <Typography
                          variant='h6'
                          component={'span'}
                          style={{
                            fontWeight: '600',
                            color: 'rgb(22,33,91)',
                          }}
                        >
                          &nbsp; Shift Schedules
                        </Typography>

                        <Typography
                          variant='subtitle1'
                          component={'span'}
                          style={{
                            color: 'rgb(31,41,156)',
                          }}
                        >
                          &nbsp; | &nbsp; Today
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={7}
                        lg={7}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <span
                            className={classes.dot}
                            style={{
                              backgroundColor: 'rgb(31,41,156)',
                            }}
                          ></span>
                          <Typography
                            component={'span'}
                            variant='subtitle2'
                            className={classes.colorBlue}
                          >
                            &nbsp; Shifts in Progress &nbsp;&nbsp;
                          </Typography>
                        </div>

                        <div>
                          <span
                            className={classes.dot}
                            style={{
                              backgroundColor: 'rgb(255,154,29)',
                            }}
                          ></span>
                          <Typography
                            component={'span'}
                            variant='subtitle2'
                            className={classes.colorBlue}
                          >
                            &nbsp; Shifts &nbsp;&nbsp;
                          </Typography>
                        </div>

                        <div>
                          <span
                            className={classes.dot}
                            style={{
                              backgroundColor: '#3D9E5A',
                            }}
                          ></span>
                          <Typography
                            component={'span'}
                            variant='subtitle2'
                            className={classes.colorBlue}
                          >
                            &nbsp; Shifts Completed &nbsp;&nbsp;{' '}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      spacing={2}
                      style={{
                        padding: '5px 10px 5px 10px',
                        textAlign: 'center',
                        marginTop: '30px',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Grid item xs={12} md={5}>
                        <Box
                          position='relative'
                          display='inline-flex'
                          style={{
                            border: '1px solid lightgray',
                            borderRadius: '50%',
                            textAlign: 'center',
                          }}
                        >
                          <CircularProgress
                            variant='determinate'
                            // value={(todaysHrs / 8) * 100}
                            //lets say total emps are 200
                            // 180 have shifts
                            size={230}
                            thickness={2.0}
                            value={
                              (currentShiftEmp ||
                                0 / counts.genderCount.total ||
                                0) * 100
                            }
                            style={{
                              color: '#3D9E5A',
                            }}
                          />
                          <Box
                            top={0}
                            left={0}
                            bottom={0}
                            right={0}
                            position='absolute'
                            display='flex'
                            alignItems='center'
                            justifyContent='center'
                          >
                            <Typography
                              variant='h2'
                              component={'span'}
                              style={{
                                color: 'rgb(22,33,91)',
                                fontWeight: '600',
                                position: 'relative',
                              }}
                            >
                              {getResourceUtil()}
                              <span
                                className={classes.colorBlue}
                                style={{
                                  position: 'absolute',
                                  right: '-15px',
                                  top: '5px',
                                  fontWeight: '200',
                                  fontSize: '20px',
                                }}
                              >
                                %
                              </span>
                              <br></br>
                              <Typography
                                className={classes.colorBlue}
                                variant='subtitle1'
                                component={'div'}
                                style={{
                                  fontWeight: '600',
                                }}
                              >
                                {currentShiftEmp || 0}/
                                {counts.genderCount.total || 0}
                              </Typography>
                              <Typography
                                variant='subtitle1'
                                className={classes.colorBlue}
                                component={'div'}
                              >
                                Employee
                              </Typography>
                            </Typography>
                          </Box>
                        </Box>

                        <Typography
                          className={classes.colorBlue}
                          variant='body1'
                          component={'div'}
                          style={{
                            textAlign: 'center',
                            fontWeight: '600',
                            margin: '10px 0px 30px',
                          }}
                        >
                          Planned Occupancy Utilisation
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={7}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          padding: '0px 20px',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            color: 'rgb(22,33,91)',
                            marginBottom: '10px',
                          }}
                        >
                          <Typography variant='body1'>
                            Shift in Progress
                          </Typography>

                          <Typography
                            variant='body1'
                            style={{
                              fontWeight: '600',
                            }}
                          >
                            {shiftsDashboardData?.inProgress}
                          </Typography>
                        </div>

                        <Box sx={{ width: '100%' }}>
                          <LinearProgressBlue
                            variant='determinate'
                            thickness={5}
                            value={
                              (shiftsDashboardData.inProgress /
                                shiftScheduledToday) *
                              100
                            }
                            style={{
                              height: '8px',
                              borderRadius: '10px',
                            }}
                          />
                        </Box>

                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            color: 'rgb(22,33,91)',
                            marginTop: '50px',
                            marginBottom: '10px',
                          }}
                        >
                          <Typography variant='body1'>
                            Shifts Assigned
                          </Typography>

                          <Typography
                            variant='body1'
                            style={{
                              fontWeight: '600',
                            }}
                          >
                            {shiftsDashboardData?.assigned}
                          </Typography>
                        </div>

                        <Box sx={{ width: '100%' }}>
                          <LinearProgressYellow
                            variant='determinate'
                            thickness={5}
                            value={
                              (shiftsDashboardData.assigned /
                                shiftScheduledToday) *
                              100
                            }
                            style={{
                              height: '8px',
                              borderRadius: '10px',
                            }}
                          />
                        </Box>

                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            color: 'rgb(22,33,91)',
                            marginTop: '50px',
                            marginBottom: '10px',
                          }}
                        >
                          <Typography variant='body1'>Completed</Typography>

                          <Typography
                            variant='body1'
                            style={{
                              fontWeight: '600',
                            }}
                          >
                            {shiftsDashboardData?.completed}
                          </Typography>
                        </div>

                        <Box sx={{ width: '100%' }}>
                          <LinearProgressGreen
                            variant='determinate'
                            thickness={5}
                            value={
                              (shiftsDashboardData.completed /
                                shiftScheduledToday) *
                              100
                            }
                            style={{
                              height: '8px',
                              borderRadius: '10px',
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </div>
                </Paper>
              </Grid>
            </Grid>
            <Grid>
              <SScheduler />
            </Grid>
          </main>
        </ThemeProvider>
      </div>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </React.Fragment>
  );
}

// export default SM_Dashboard;
