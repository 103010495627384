import React, { useState } from "react";
import Sidebar from "pages/sidebar/Sidebar";
import ReactColor from "./ReactColor";

const AddPositionSidebar = ({ sidebarHandler }) => {
  const [color, setColor] = useState(false);

  const reactColorHandler = () => {
    setColor(!color);
  };

  return (
    <Sidebar modalTitle="Add Position" sidebarHandler={sidebarHandler}>
      <div className="h-screen  text-gray-900">
        <div className="bg-gray-100 rounded-md px-4 py-3 flex items-center justify-between">
          <p className="font-bold">Active</p>
          <label className="flex items-center cursor-pointer relative">
            <input type="checkbox" className="sr-only" />
            <div className="toggle-bg bg-gray-200 border-2 border-gray-200 h-6 w-11 rounded-full"></div>
          </label>
        </div>
        <div className="mt-4">
          <p className="font-semibold">Position Title *</p>
          <div className="bg-gray-100 border-2 rounded-md outline-0 px-2 py-3 w-full font-bold mt-1 focus:ring focus:outline-none flex justify-between items-center">
            <p>Manager</p>
            <i className="fa-solid fa-magnifying-glass"></i>
          </div>
        </div>
        <div className="mt-10 flex justify-between items-center flex-wrap gap-2">
          <div className="flex justify-between items-center border rounded-md p-3 sm:gap-32 gap-5">
            <div className="flex items-center gap-3">
              <i className="fa-solid fa-palette"></i>
              <p className="font-bold">Position Color</p>
            </div>
            <p
              className="w-6 h-6 rounded-full bg-red-900 cursor-pointer"
              onClick={reactColorHandler}
            ></p>
          </div>
          <p className="border border-blue-700 px-2 rounded-md p-3 cursor-pointer font-semibold">
            Reset Color
          </p>
        </div>
        <div>{color && <ReactColor />}</div>
      </div>
      <div className="border-b-2 border-gray-300 my-5"></div>
      <div className="flex justify-end gap-5">
        <p
          className="py-1 px-3 font-semibold border cursor-pointer border-blue-700 rounded"
          onClick={sidebarHandler}
        >
          Cancel
        </p>
        <p className="py-1 px-3 font-semibold border cursor-pointer border-blue-700 rounded bg-indigo-900 text-gray-50">
          Save
        </p>
      </div>
    </Sidebar>
  );
};

export default AddPositionSidebar;
