import {
  getAllAssetRepo,
  getAllAssetGroupByUserRepo,
  createAssetRepo,
  updateAssetRepo,
  getAssetByIdRepo,
  getAssetByUserIdRepo,
} from "store/repositories/AssetManagement/allAssets";
import {
  getAllAssetFulfilled,
  getAllAssetPending,
  getAllAssetRejected,
  getAllAssetGroupByUserFulfilled,
  getAllAssetGroupByUserPending,
  getAllAssetGroupByUserRejected,
  createAssetFulfilled,
  createAssetPending,
  createAssetRejected,
  updateAssetFulfilled,
  updateAssetPending,
  updateAssetRejected,
  getAssetByIdFulfilled,
  getAssetByIdPending,
  getAssetByIdRejected,
  getAssetByUserIdPending,
  getAssetByUserIdFulfilled,
  getAssetByUserIdRejected,
} from "store/slices/AssetManagement/allAssets";

export const getAllAsset = (params) => {
  return (dispatch) => {
    dispatch(getAllAssetPending());
    getAllAssetRepo(params)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data?.data);
          dispatch(getAllAssetFulfilled(response?.data?.data));
        } else {
          dispatch(getAllAssetRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(getAllAssetRejected(error.message));
        }
      });
  };
};

export const getAllAssetGroupByUser = (params) => {
  return (dispatch) => {
    dispatch(getAllAssetGroupByUserPending());
    getAllAssetGroupByUserRepo(params)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data?.data);
          dispatch(getAllAssetGroupByUserFulfilled(response?.data?.data));
        } else {
          dispatch(getAllAssetGroupByUserRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(getAllAssetGroupByUserRejected(error.message));
        }
      });
  };
};

export const createAsset = (params) => {
  return (dispatch) => {
    dispatch(createAssetPending());
    createAssetRepo(params)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data?.data);
          dispatch(createAssetFulfilled(response?.data?.data));
        } else {
          dispatch(createAssetRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(createAssetRejected(error.message));
        }
      });
  };
};

export const updateAsset = (id, params) => {
  return (dispatch) => {
    dispatch(updateAssetPending());
    updateAssetRepo(id, params)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data?.data);
          dispatch(updateAssetFulfilled(response?.data?.data));
        } else {
          dispatch(updateAssetRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(updateAssetRejected(error.message));
        }
      });
  };
};
export const getAssetById = (id) => {
  return (dispatch) => {
    dispatch(getAssetByIdPending());
    getAssetByIdRepo(id)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data?.data);
          dispatch(getAssetByIdFulfilled(response?.data?.data));
        } else {
          dispatch(getAssetByIdRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(getAssetByIdRejected(error.message));
        }
      });
  };
};

export const getAssetByUserId = (id) => {
  return (dispatch) => {
    dispatch(getAssetByUserIdPending());
    getAssetByUserIdRepo(id)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data?.data);
          dispatch(getAssetByUserIdFulfilled(response?.data?.data));
        } else {
          dispatch(getAssetByUserIdRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(getAssetByUserIdRejected(error.message));
        }
      });
  };
};
