import React, { useState } from "react";
import NewRequestSidebar from "./NewRequestSidebar";

const AssetMangement = () => {
  const [sidebar, setSidebar] = useState(false);

  const sidebarHandler = () => {
    setSidebar(!sidebar);
  };

  return (
    <div>
      {sidebar && <NewRequestSidebar sidebarHandler={sidebarHandler} />}
      <p className="text-3xl my-5 font-bold">Asset Management</p>
      <div className="flex gap-2 flex-wrap justify-between">
        <div className="flex gap-2 flex-wrap">
          <input
            type="text"
            placeholder="Search..."
            className="bg-gray-100 border-2 rounded outline-0 px-2 py-1 sm:w-72 w-full font-semibold"
          />
          <select className="bg-gray-100 border-2 rounded outline-0 px-2 py-1 sm:w-72 w-full font-semibold">
            <option>All Request</option>
            <option>Pending</option>
            <option>Return</option>
            <option>Accepted</option>
          </select>
          <select className="bg-gray-100 border-2 rounded outline-0 px-2 py-1 sm:w-72 w-full font-semibold">
            <option>This Month</option>
            <option>Today</option>
            <option>Week</option>
            <option>3 Month</option>
            <option>6 Month</option>
            <option>1 Year</option>
          </select>
        </div>
        <div>
          <button
            className="bg-blue-900 px-3 py-1 rounded text-gray-100"
            onClick={sidebarHandler}
          >
            + New Request
          </button>
        </div>
      </div>
    </div>
  );
};

export default AssetMangement;
