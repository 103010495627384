import { getUsersByOrgIdRepo, getOrgCountsRepo } from "../../repositories/HrManagement/Dashboard";
import {
  getUsersByOrgIdPending,
  getUsersByOrgIdFulfilled,
  getUsersByOrgIdRejected,
  getOrgCountPending,
  getOrgCountFulfilled,
  getOrgCountRejected
} from "../../slices/HrManagement/Dashboard";

export const getUsersByOrgId = (id, params) => {
  return async (dispatch) => {
    dispatch(getUsersByOrgIdPending());
    await getUsersByOrgIdRepo(id, params)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getUsersByOrgIdFulfilled(response?.data?.data));
        } else {
          dispatch(getUsersByOrgIdRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(getUsersByOrgIdRejected(error.message));
        }
      });
  };
};

export const getOrgCounts = (id) => {
  return async (dispatch) => {
    dispatch(getOrgCountPending());
    await getOrgCountsRepo(id)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getOrgCountFulfilled(response?.data?.data));
        } else {
          dispatch(getOrgCountRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(getOrgCountRejected(error.message));
        }
      });
  };
};

