import React, { useState } from "react";
import AddPositionSidebar from "./AddPositionSidebar";

const AssetMangement = () => {
  const [sidebar, setSidebar] = useState(false);

  const sidebarHandler = () => {
    setSidebar(!sidebar);
  };

  return (
    <div>
      {sidebar && <AddPositionSidebar sidebarHandler={sidebarHandler} />}
      <div className="flex gap-2 flex-wrap justify-between border p-5">
        <div className="flex gap-2 flex-wrap">
          <input
            type="text"
            placeholder="Search..."
            className="bg-gray-100 border-2 rounded outline-0 px-2 py-2 sm:w-72 w-full"
          />
          <select className="bg-gray-100 border-2 rounded outline-0 px-2 py-2 sm:w-72 w-full">
            <option>All</option>
            <option>Active</option>
            <option>Deactive</option>
          </select>
          <select className="bg-gray-100 border-2 rounded outline-0 px-2 py-2 sm:w-72 w-full">
            <option>All Positions</option>
          </select>
        </div>
        <div>
          <button
            className="bg-blue-900 px-3 py-2 rounded text-gray-100"
            onClick={sidebarHandler}
          >
            + Add Positions
          </button>
        </div>
      </div>
    </div>
  );
};

export default AssetMangement;
