import {
    getAllTicketsandQueriesRepo} from "../../repositories/HrManagement/QueriesAndTickets";
import {
    getTicketsPending,
    getTicketsFulfilled,
    getTicketsRejected
} from "../../slices/HrManagement/QueriesAndTickets";

export const getAllTicketsandQueries = () => {
    return async (dispatch) => {
        dispatch(getTicketsPending());
        await getAllTicketsandQueriesRepo()
            .then((response) => {
                if (response.status === 200) {
                    dispatch(getTicketsFulfilled(response?.data));
                } else {
                    dispatch(getTicketsRejected(response?.message));
                }
            })
            .catch((error) => {
                if (error && error.response) {

                    dispatch(getTicketsRejected(error.message));
                }
            });
    };
};

