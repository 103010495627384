import axios from "axios";
import { Urls, BASE_URL } from "store/urls";

export const getAllSchedulesRepo = (params) => {
  return axios({
    baseURL: BASE_URL,
    method: "GET",
    url: Urls.GET_ALL_SCHEDULES,
    params: params
  });
};

export const getAllJobSitesRepo = () => {
  return axios({
    baseURL: BASE_URL,
    method: "GET",
    url: Urls.GET_ALL_JOB_SITES,
  });
};



