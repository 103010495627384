import axios from 'axios';
import { Urls, BASE_URL } from 'store/urls';

export const getShiftTasksRepo = (page = 1, limit = 25) => {
  return axios({
    method: 'GET',
    url: `${Urls.GET_SHIFT_TASKS}?page_number=${page}&limit=${limit}`,
  });
};

export const addShiftTaskRepo = (task) => {
  return axios({
    method: 'post',
    url: Urls.POST_SHIFT_TASK,
    data: task,
  });
};

export const updateShiftTaskRepo = (task) => {
  return axios({
    method: 'put',
    url: Urls.PUT_SHIFT_TASK,
    data: task,
  });
};

export const deleteShiftTaskRepo = (id) => {
  return axios({
    method: 'delete',
    url: Urls.DELETE_SHIFT_TASK + id,
  });
};

export const searchShiftTaskRepo = (obj) => {
  const data = {
    limit: obj?.limit || 25,
    pageNumber: obj?.page || 1,
    status: obj?.status || '',
    searchKey: obj?.searchKey || '',
  };
  return axios({
    baseURL: BASE_URL,
    method: 'POST',
    url: Urls.SEARCH_SHIFTTASK,
    data,
  });
};
