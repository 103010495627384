import React, { useState, useEffect } from "react";
import Sidebar from "pages/sidebar/Sidebar";
import Select from "react-select";
import {
  getAllAssetSubCategory,
  createAssetSubCategory,
  updateAssetSubCategory,
} from "store/actions/AssetManagement/assetSubCategory";
import {
  clearCreateAssetSubCategory,
  clearUpdateAssetSubCategory,
} from "store/slices/AssetManagement/assetSubCategory";
import { useDispatch, useSelector } from "react-redux";

const AddSubCategorySidebar = ({
  subCategoryInitialValues,

  setIsOpenForm,
  categoryList,
}) => {
  const dispatch = useDispatch();
  const { assetSubCategory } = useSelector((state) => state);
  let initialCategory = categoryList.find(
    (item) => item._id == subCategoryInitialValues?.category_id
  );
  const [selectedCategory, setSelectedCategory] = useState(
    initialCategory ? initialCategory : null
  );
  const [subCategoryName, setSubCategoryName] = useState(
    subCategoryInitialValues ? subCategoryInitialValues?.name : ""
  );
  const onChangeCategory = (val) => {
    setSelectedCategory(val);
  };

  const handleSubmit = () => {
    if (subCategoryName && selectedCategory?._id) {
      let params = {};
      params.name = subCategoryName;
      params.category_id = selectedCategory._id;
      if (subCategoryInitialValues?._id) {
        params._id = subCategoryInitialValues?._id;
        dispatch(updateAssetSubCategory(params));
      } else {
        dispatch(createAssetSubCategory(params));
      }
    } else {
      //validation errors
    }
  };

  useEffect(() => {
    if (assetSubCategory?.createSubCategory?.fulfilled) {
      dispatch(getAllAssetSubCategory());
      setIsOpenForm(false);
      dispatch(clearCreateAssetSubCategory());
    }
  }, [assetSubCategory?.createSubCategory]);
  useEffect(() => {
    if (assetSubCategory?.updateSubCategory?.fulfilled) {
      dispatch(getAllAssetSubCategory());
      setIsOpenForm(false);
      dispatch(clearUpdateAssetSubCategory());
    }
  }, [assetSubCategory?.updateSubCategory]);

  return (
    <Sidebar
      sidebarHandler={() => setIsOpenForm(false)}
      modalTitle={
        subCategoryInitialValues?._id
          ? "Update Sub Category"
          : "Add Sub Category"
      }
    >
      <div className="h-screen">
        <div>
          <p className="text-blue-900 font-semibold">Sub - Category</p>
          <input
            type="text"
            className="bg-gray-100 border-2 rounded-md outline-0 px-2 py-3 w-full font-bold mt-1 focus:ring focus:outline-none"
            onChange={(e) => {
              setSubCategoryName(e.target.value);
            }}
            value={subCategoryName}
          />
        </div>
        <div className="mt-4">
          <p className="text-blue-900 font-semibold">Category</p>
          <Select
            placeholder="SelectCategory"
            className=" rounded-md outline-0 px-2 py-3 w-full font-bold mt-1 focus:ring focus:outline-none"
            classNamePrefix="select"
            value={selectedCategory}
            options={categoryList}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option._id}
            onChange={(val) => onChangeCategory(val)}
            isClearable={true}
          />
        </div>
      </div>
      <div className="border-b-2 border-gray-300 my-5"></div>
      <div className="flex justify-end gap-5">
        <p
          className="py-1 px-3 font-bold border cursor-pointer border-blue-700 rounded"
          onClick={() => setIsOpenForm(false)}
        >
          Cancel
        </p>
        <p
          className="py-1 px-3 font-bold border cursor-pointer border-blue-700 rounded bg-indigo-700 text-gray-50"
          onClick={() => {
            handleSubmit();
          }}
        >
          Save
        </p>
      </div>
    </Sidebar>
  );
};

export default AddSubCategorySidebar;
