import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import Sidebar from "pages/sidebar/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import {
  createAsset,
  updateAsset,
  getAssetById,
} from "store/actions/AssetManagement/allAssets";
import Api from "hooks/AjaxAction";
import Select from "react-select";
import useAuth from "hooks/useAuth";
import {
  clearCreateAsset,
  clearUpdateAsset,
} from "store/slices/AssetManagement/allAssets";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const AddUpdateAsset = ({
  id,
  assetAddUpdateHandler,
  categoryList,
  subCategoryList,
  conditionList,
  assetInitialValues,
  readOnly,
}) => {
  const auth = useAuth();
  const initialValue = {
    category: null,
    sub_category: null,
    about: "",
    amount: "",
    asset_no: "",
    available_quantity: null,
    brand: "",
    condition: null,
    image: "",
    purchase_date: new Date(),
    serial: "",
    warranty_from: new Date(),
    warranty_to: new Date(),
  };

  const dispatch = useDispatch();
  const { addedAsset, updatedAsset } = useSelector((state) => state.allAsset);
  const { register, handleSubmit, reset, control, setValue, watch, getValues } =
    useForm({
      defaultValues: assetInitialValues ? assetInitialValues : initialValue,
    });
  const [fileName, setFileName] = useState("");
  const onSelectFile = (files) => {
    const onSuccess = (data) => {
      console.log("....on uploading image", data);
      setValue("image", data.file_url);
      setFileName(data.filename);
    };
    // Upload to S3
    Api.fileUpload(files, "org-glen", auth.token, onSuccess);
  };

  const onSubmit = (data) => {
    let params = { ...data };
    params.available_quantity = 1;
    if (id) {
      dispatch(updateAsset(id, params));
    } else {
      dispatch(createAsset(params));
    }
  };
  // useEffect(() => {
  //   if (assetInitialValues?.purchase_date) {
  //     setValue("purchase_date", new Date(assetInitialValues.purchase_date));
  //   }
  // }, [assetInitialValues?.purchase_date]);

  useEffect(() => {
    if (addedAsset.fulfilled) {
      dispatch(clearCreateAsset());
      assetAddUpdateHandler();
    }
  }, [addedAsset]);
  useEffect(() => {
    if (updatedAsset.fulfilled) {
      dispatch(clearUpdateAsset());
      assetAddUpdateHandler();
    }
  }, [updatedAsset]);

  return (
    <Sidebar
      sidebarHandler={() => assetAddUpdateHandler()}
      modalTitle={readOnly ? "View Asset" : id ? "Update Asset" : "Add Asset"}
    >
      <div className="font-bold">
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <div>
            <p className="text-base mb-1">Category*</p>
            <Controller
              control={control}
              name="category"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Select
                  inputRef={ref}
                  placeholder="Select Category"
                  className="basic-single"
                  classNamePrefix="select"
                  value={categoryList?.find((c) => c?._id === value)}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option._id}
                  options={categoryList}
                  onChange={(val) => {
                    onChange(val._id);
                  }}
                  isDisabled={readOnly}
                />
              )}
            />
          </div>
          <div className="mt-4">
            <p className="text-base mb-1">Sub Category*</p>
            <Controller
              control={control}
              name="sub_category"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Select
                  inputRef={ref}
                  placeholder="Select Sub Category"
                  className="basic-single"
                  classNamePrefix="select"
                  value={subCategoryList?.find((c) => c?._id === value)}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option._id}
                  options={subCategoryList}
                  onChange={(val) => onChange(val._id)}
                  isDisabled={readOnly}
                />
              )}
            />
          </div>

          <div className="mt-4">
            <p className="text-base">Asset No</p>
            <div className="flex gap-2 items-center">
              <input
                {...register("asset_no", { required: true })}
                placeholder="Asset No."
                className="bg-gray-100 border-2 rounded-md outline-0 px-2 py-3 w-full font-semibold mt-1 focus:ring focus:outline-none"
                disabled={readOnly}
              />
            </div>
          </div>

          <div className="mt-4">
            <p className="text-base">Item Description</p>
            <textarea
              {...register("about", { required: true })}
              className="bg-gray-100 border-2 rounded-md outline-0 px-2 py-3 w-full font-semibold mt-1 focus:ring focus:outline-none h-28"
              placeholder="Item Description .. "
              disabled={readOnly}
            />
          </div>

          <div className="mt-4">
            <p className="text-base">Brand*</p>
            <input
              {...register("brand", { required: true })}
              placeholder="Brand"
              className="bg-gray-100 border-2 rounded-md outline-0 px-2 py-3 w-full font-semibold mt-1 focus:ring focus:outline-none"
              disabled={readOnly}
            />
          </div>
          <div className="mt-4">
            <p className="text-base">Serial No</p>
            <div className="flex gap-2 items-center">
              <input
                {...register("serial", { required: true })}
                placeholder="Serial No. "
                className="bg-gray-100 border-2 rounded-md outline-0 px-2 py-3 w-full font-semibold mt-1 focus:ring focus:outline-none"
                disabled={readOnly}
              />
            </div>
          </div>

          <div className="mt-4">
            <div className="flex justify-between items-center gap-2">
              <div className="w-full">
                <p className="text-base mb-1">Purchase date</p>
                <Controller
                  name="purchase_date"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Enter Date of Purchase",
                    },
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        id="date-picker-dialog"
                        format="MM/dd/yyyy"
                        margin="normal"
                        value={value}
                        onChange={(v) =>
                          setValue("purchase_date", moment(v).format())
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        disabled={readOnly}
                      />
                    </MuiPickersUtilsProvider>
                  )}
                />
              </div>

              {/* <div className="w-full">
                <p className="text-base mb-1">Quantity</p>
                <input
                  className="bg-gray-100 border-2 rounded-md outline-0 px-2 py-3 w-full font-semibold focus:ring focus:outline-none"
                  type="number"
                  {...register("available_quantity", {
                    valueAsNumber: true,
                    required: true,
                  })}
                />
              </div> */}
            </div>
          </div>
          <div className="mt-4">
            <p className="text-base mb-1">Warranty</p>
            <div className="flex justify-between items-center gap-2">
              <Controller
                name="warranty_from"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Enter Date of Purchase",
                  },
                }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      id="date-picker-dialog"
                      format="MM/dd/yyyy"
                      margin="normal"
                      value={value}
                      onChange={(v) =>
                        setValue("warranty_from", moment(v).format())
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      disabled={readOnly}
                    />
                  </MuiPickersUtilsProvider>
                )}
              />
              {/* <input
                type="date"
                {...register("warranty_from", {
                  setValueAs: (v) => moment(v).format(),
                  required: true,
                })}
                className="bg-gray-100 border-2 rounded-md outline-0 px-2 py-3 w-full font-semibold focus:ring focus:outline-none"
              /> */}
              <p className="text-base text-blue-400">To</p>
              <Controller
                name="warranty_to"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Enter Date of Purchase",
                  },
                }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      id="date-picker-dialog"
                      format="MM/dd/yyyy"
                      margin="normal"
                      value={value}
                      onChange={(v) =>
                        setValue("warranty_to", moment(v).format())
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      disabled={readOnly}
                    />
                  </MuiPickersUtilsProvider>
                )}
              />
              {/* <input
                type="date"
                {...register("warranty_to", {
                  setValueAs: (v) => moment(v).format(),
                  required: true,
                })}
                className="bg-gray-100 border-2 rounded-md outline-0 px-2 py-3 w-full font-semibold focus:ring focus:outline-none"
              /> */}
            </div>
          </div>

          <div className="mt-4">
            <p className="text-base mb-1">Amount</p>
            <div className="flex justify-between items-center gap-2">
              <input
                {...register("amount", { required: true })}
                placeholder="Amount"
                className="bg-gray-100 border-2 rounded-md outline-0 px-2 py-3 w-full font-semibold focus:ring focus:outline-none"
                disabled={readOnly}
              />
            </div>
          </div>

          <div className="mt-4">
            <p className="text-base mb-1">Asset Condition</p>
            <Controller
              control={control}
              name="condition"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Select
                  inputRef={ref}
                  placeholder="Select Asset Condition"
                  className="basic-single"
                  classNamePrefix="select"
                  value={conditionList?.find((c) => c?._id === value)}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option._id}
                  options={conditionList}
                  onChange={(val) => onChange(val._id)}
                  isDisabled={readOnly}
                />
              )}
            />
          </div>

          <div className="mt-4">
            <p className="text-base mb-1">Attach Asset Photo *</p>
            <input
              onChange={(e) => onSelectFile(e.target.files)}
              // style={{ display: "none" }}
              type="file"
              disabled={readOnly}
            />
            <div className="flex justify-between items-center gap-2  mt-1">
              <input
                className="bg-gray-100 border-2 rounded-md outline-0 px-2 py-3 w-full font-semibold focus:ring focus:outline-none"
                {...register("image")}
                placeholder="Image address"
                disabled={readOnly}
              />
            </div>
          </div>

          <div className="border-b-2 border-gray-300 my-5"></div>
          <div className="flex justify-end gap-5">
            <p
              className="py-1 px-3 font-semibold border cursor-pointer border-blue-700 rounded"
              onClick={() => assetAddUpdateHandler()}
            >
              Cancel
            </p>
            {!readOnly && (
              <p
                className="py-1 px-3 font-semibold border cursor-pointer border-blue-700 rounded bg-indigo-700 text-gray-50"
                onClick={handleSubmit((data) => onSubmit(data))}
              >
                Save
              </p>
            )}
          </div>
        </form>
      </div>
    </Sidebar>
  );
};

export default AddUpdateAsset;
