import React, { useState } from "react";
import SoftwareDeveloperDetails from "./SoftwareDeveloperDetails";
import NewRequestSidebar from "./NewRequestSidebar";

const SoftwareDeveloper = () => {
  const [sidebar, setSidebar] = useState(false);
  const [softDetails, setSoftDetails] = useState(true);

  const newRequestHanlder = () => {
    setSidebar(!sidebar);
  };

  const softDetailsHanlder = () => {
    setSoftDetails(!softDetails);
  };

  return (
    <div className="mt-4">
      {sidebar && <NewRequestSidebar newRequestHanlder={newRequestHanlder} />}
      <div className="border-2 px-4 py-2 bg-gray-100 flex justify-between flex-wrap">
        <div className="flex-wrap flex items-center gap-2">
          <i className="fa-regular fa-user border w-8 h-8 flex justify-center items-center bg-blue-800 rounded-full font-bold text-white"></i>
          <p className="font-semibold text-lg">Software Developer</p>
        </div>
        <div className="flex-wrap flex items-center gap-6 sm:justify-between justify-center">
          <p className="text-blue-700 text-lg">Total Assets: 03</p>
          <div className="flex items-center gap-2">
            <p className="bg-green-800 te w-3 h-3 rounded-full"></p>
            <p className="text-green-500 font-bold">Active</p>
          </div>
          <div
            className="border py-2 px-4 bg-white rounded-md font-bold text-blue-900 cursor-pointer"
            onClick={newRequestHanlder}
          >
            <p>+ Add Asset</p>
          </div>
          <i className="fa-solid fa-trash border bg-white p-2 rounded-md cursor-pointer"></i>
          <i className="fa-solid fa-pen border bg-white p-2 rounded-md cursor-pointer"></i>
          <i
            className={
              softDetails
                ? "fa-solid fa-angle-down cursor-pointer"
                : "fa-solid fa-angle-up cursor-pointer"
            }
            onClick={softDetailsHanlder}
          ></i>
        </div>
      </div>
      {softDetails && <SoftwareDeveloperDetails />}
      <div className="cursor-pointer border-2 px-4 py-2 bg-gray-100 flex justify-between flex-wrap my-2">
        <div className="flex-wrap flex items-center gap-2">
          <i className="fa-regular fa-user border w-8 h-8 flex justify-center items-center bg-blue-800 rounded-full font-bold text-white"></i>
          <p className="font-semibold text-lg">Security Guard</p>
        </div>
        <div className="flex-wrap flex items-center gap-6 sm:justify-between justify-center">
          <p className="text-blue-700 text-lg">Total Assets: 03</p>
          <div className="flex items-center gap-2">
            <p className="bg-green-800 te w-3 h-3 rounded-full"></p>
            <p className="text-green-500 font-bold">Active</p>
          </div>
          <div className="border py-2 px-4 bg-white rounded-md font-bold text-blue-900 cursor-pointer">
            <p>+ Add Asset</p>
          </div>
          <i className="fa-solid fa-trash border bg-white p-2 rounded-md cursor-pointer"></i>
          <i className="fa-solid fa-pen border bg-white p-2 rounded-md cursor-pointer"></i>
          <i
            className={
              softDetails
                ? "fa-solid fa-angle-down cursor-pointer"
                : "fa-solid fa-angle-up cursor-pointer"
            }
            onClick={softDetailsHanlder}
          ></i>
        </div>
      </div>
      <div className="cursor-pointer border-2 px-4 py-2 bg-gray-100 flex justify-between flex-wrap mb-2">
        <div className="flex-wrap flex items-center gap-2">
          <i className="fa-regular fa-user border w-8 h-8 flex justify-center items-center bg-blue-800 rounded-full font-bold text-white"></i>
          <p className="font-semibold text-lg">Team Lead</p>
        </div>
        <div className="flex-wrap flex items-center gap-6 sm:justify-between justify-center">
          <p className="text-blue-700 text-lg">Total Assets: 03</p>
          <div className="flex items-center gap-2">
            <p className="bg-green-800 te w-3 h-3 rounded-full"></p>
            <p className="text-green-500 font-bold">Active</p>
          </div>
          <div className="border py-2 px-4 bg-white rounded-md font-bold text-blue-900 cursor-pointer">
            <p>+ Add Asset</p>
          </div>
          <i className="fa-solid fa-trash border bg-white p-2 rounded-md cursor-pointer"></i>
          <i className="fa-solid fa-pen border bg-white p-2 rounded-md cursor-pointer"></i>
          <i
            className={
              softDetails
                ? "fa-solid fa-angle-down cursor-pointer"
                : "fa-solid fa-angle-up cursor-pointer"
            }
            onClick={softDetailsHanlder}
          ></i>
        </div>
      </div>
    </div>
  );
};

export default SoftwareDeveloper;
