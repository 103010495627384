import axios from "axios";
import { Urls, BASE_URL } from "store/urls";

export const getAllResignationsRepo = () => {
  return axios({
    baseURL: BASE_URL,
    method: "GET",
    url: Urls.GET_ALL_HR_RESIGNATIONS,
  });
};

export const updateResignationByIdRepo = (data) => {
    return axios({
      baseURL: BASE_URL,
      method: "PUT",
      url: Urls.UPDATE_HR_RESIGNATION,
      data: data
    });
  };


