import React, { useState, useEffect } from "react";
import {
  AppBar,
  Hidden,
  Tabs,
  CssBaseline,
  Container,
  Tab,
  Box,
  Avatar,
  Typography,
  Grid,
  Paper,
  Divider,
  InputBase,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import myStyles from "../ProfileStyles";
import { withStyles } from "@material-ui/styles";

//------------------------------------
import moment from "moment";
import Scrollbars from "react-custom-scrollbars";
import FolderIcon from "@material-ui/icons/Folder";
import { SaveAlt } from "@material-ui/icons";

// REPLICATING FILES IN THE LIST
function generate(element) {
  return [0, 1, 2, 3, 4, 5].map((value) =>
    React.cloneElement(element, {
      key: value,
    })
  );
}

const CssInputBase = withStyles({
  root: {
    "& .Mui-disabled": {
      color: "black",
    },
  },
})(InputBase);

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ padding: "24px 0px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    padding: "10px",
  },
  label: {
    color: "royalblue",
  },
  infoValues: {
    fontWeight: "600",
    height: "30px",
    marginBottom: "10px",
    lineHeight: "1",
    wordWrap: "break-word",
  },
  info: {
    height: "30px",
    marginBottom: "10px",
    lineHeight: "1",
    wordWrap: "break-word",
  },
}));

function ScrollableTabsButtonAuto(props) {
  const myClasses = useStyles();
  const classes = myStyles();
  const [value, setValue] = useState(0);
  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [fname, setFname] = useState(" ");
  const [lname, setLname] = useState(" ");
  const [dob, setDob] = useState(" ");
  const [gender, setGender] = useState(" ");
  const [bloodGroup, setBloodGroup] = useState(" ");
  const [bio, setBio] = useState(" ");
  const [maritalStatus, setMaritalStatus] = useState(" ");
  const [curr_addr, setCurrentAddress] = useState(" ");
  const [permanent_addr, setPermanentAddress] = useState(" ");
  const [mailing_addr, setMailingAddress] = useState(" ");
  const [empId, setEmpId] = useState(" ");
  const [role, setRole] = useState(" ");
  const [joiningDate, setJoiningDate] = useState(" ");
  const [position, setPosition] = useState(" ");
  const [email, setEmail] = useState(" ");
  const [drivingLicence, setDrivingLicence] = useState(" ");
  const [contact, setContact] = useState(" ");
  const [contactOfficial, setContactOfficial] = useState("");
  const [contactAlternate, setContactAlternate] = useState("");
  const [healthIssues, setHealthIssues] = useState(" ");
  const [healthCard, setHealthCard] = useState(" ");
  const [provinceOfIssue, setProvinceOfIssue] = useState(" ");
  const [emergencyContactName, setEmergencyContactName] = useState(" ");
  const [emergencyContactRelation, setEmergencyContactRelation] = useState(" ");
  const [emergencyContact, setEmergencyContact] = useState(" ");
  const [emergencyContactEmail, setEmergencyContactEmail] = useState(" ");
  const [sinNo, setSinNo] = useState(" ");
  const [accountNo, setAccountNo] = useState(" ");
  const [bankName, setBankName] = useState(" ");
  const [institutionNo, setInstitutionNo] = useState(" ");
  const [transitNo, setTransitNo] = useState(" ");
  const [l1Name, setL1Name] = useState(" ");
  const [l1Email, setL1Email] = useState(" ");
  const [l1Contact, setL1Contact] = useState(" ");
  const [hrName, setHrName] = useState(" ");
  const [hrEmail, setHrEmail] = useState(" ");
  const [hrContact, setHrContact] = useState(" ");
  const [supervisorName, setSupervisorName] = useState(" ");
  const [supervisorEmail, setSupervisorEmail] = useState(" ");
  const [supervisorContact, setSupervisorContact] = useState(" ");
  const [PersonalDoc, setPeronalDoc] = useState([]);
  const [AdditionalDoc, setAdditionalDoc] = useState([]);
  const [CompanyDoc, setCompanyDoc] = useState([]);
  const [OfficialDoc, setOfficialDoc] = useState([]);
  const [degree, setDegree] = useState([]);
  const [certification, setCertification] = useState([]);
  const [lastPunchedIn, setLastPunchin] = useState("");

  const getDocumentList = (data, type = "") => {
    return (
      data &&
      data.map((item, key) => {
        return (
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <FolderIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={item.name} />
            <ListItemSecondaryAction>
              <IconButton edge='end' aria-label='delete'>
                <a target='_blank' rel='noopener noreferrer' href={item.url}>
                  <SaveAlt />
                </a>
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })
    );
  };

  useEffect(() => {
    console.log("props", props);
    let lastPunch = props.last_punchin_timestamp
      ? moment(props.last_punchin_timestamp).format("ddd, MMM Do YYYY, hh:mm a")
      : "Not punched in yet";
    setLastPunchin(lastPunch);
    if (props.readOnly || props.operation === "edit") {
      setFname(props.first_name);
      setLname(props.last_name);
      setDob(moment(props.dob).format("YYYY-MM-DD"));
      setGender(props.gender);
      setBloodGroup(props.blood_group);
      setBio(props.about_user);
      setPeronalDoc(props.personal_docs);
      setAdditionalDoc(props.additional_docs);
      setCompanyDoc(props.company_docs);
      setOfficialDoc(props.official_docs);

      if (props.family_details) {
        // setFather(props.family_details.father);
        // setMother(props.family_details.mother);
        // setSiblings(props.family_details.siblings);
        setMaritalStatus(props.family_details.marital_status);
      }
      if (props.residency) {
        setCurrentAddress(props.residency.curr_addr);
        setMailingAddress(props.residency.mailing_addr);
      }
      if (props.residency) {
        setPermanentAddress(props.residency.permanent_addr);
      }
      setEmpId(props.emp_code);
      setRole(props.role);
      setJoiningDate(moment(props.doj).format("YYYY-MM-DD"));
      setPosition(props.position);
      setEmail(props.email);
      setDrivingLicence(props.driving_licence_no);
      setContact(props.phone);
      setContactAlternate(props.alternate_contact_no);
      setContactOfficial(props.official_contact_no);

      if (props.medical_details) {
        setHealthIssues(props.medical_details.health_issues);
        setHealthCard(props.medical_details.health_id);
        setProvinceOfIssue(props.medical_details.province_of_issue);
      }
      if (props.emergency_contacts) {
        setEmergencyContactName(props.emergency_contacts[0].name);
        setEmergencyContactRelation(props.emergency_contacts[0].relationship);
        setEmergencyContact(props.emergency_contacts[0].mobile_no.mobile);
        setEmergencyContactEmail(props.emergency_contacts[0].landline_no);
      }
      if (props.account_details) {
        // setAccountHolderName(props.account_details.account_holder_name);
        setAccountNo(props.account_details.account_no);
        // setAccountType(props.account_details.account_type);
        // setIfscCode(props.account_details.ifsc_code);
        setSinNo(props.account_details.sin);
        setBankName(props.account_details.bank_name);
        setInstitutionNo(props.account_details.institution_no);
        setTransitNo(props.account_details.transit_no);
      }
      if (props.educational_details) {
        if (props.educational_details?.degree_details[0]?.degree_name)
          setDegree(props.educational_details.degree_details);
        else setDegree([]);
        if (
          props.educational_details?.certificates_and_licenses[0]
            ?.certificte_name
        )
          setCertification(props.educational_details.certificates_and_licenses);
        else setCertification([]);
      }

      if (props.l1_manager_details) {
        setL1Name(props.l1_manager_details.l1_name);
        setL1Contact(props.l1_manager_details.l1_contact);
        setL1Email(props.l1_manager_details.l1_email);
      }
      if (props.hr_manager_details) {
        setHrName(props.hr_manager_details.hr_name);
        setHrContact(props.hr_manager_details.hr_contact);
        setHrEmail(props.hr_manager_details.hr_email);
      }
      if (props.supervisor_details) {
        setSupervisorName(props.supervisor_details.sup_name);
        setSupervisorContact(props.supervisor_details.sup_contact);
        setSupervisorEmail(props.supervisor_details.sup_email);
      }
    }
  }, [
    props.readOnly,
    props,
    props.about_user,
    props.account_details,
    props.blood_group,
    props.dob,
    props.doj,
    props.driving_licence_no,
    props.email,
    props.emergency_contacts,
    props.emp_code,
    props.about_user,
    props.account_details,
    props.blood_group,
    props.dob,
    props.doj,
    props.driving_licence_no,
    props.email,
    props.emergency_contacts,
    props.emp_code,
    props.family_details,
    props.first_name,
    props.gender,
    props.health_id,
    props.health_issues,
    props.last_name,
    props.medical_details,
    props.operation,
    props.province_of_issue,
    props.role,
    props.supervisor_details,
    props.hr_manager_details,
    props.l1_manager_details,
  ]);

  // const handleFormSubmit = async e => {
  //   e.preventDefault();
  //   // var datOfBirth = dob || "";
  //   // var dateOfJoin = joiningDate || "";
  //   // let dobSplit = datOfBirth.split("-");
  //   // let dojsplit = dateOfJoin.split("-");

  //   const body = {
  //     first_name: fname,
  //     last_name: lname,
  //     gender,
  //     image_url: "",
  //     country_code: "",
  //     phone: contact,
  //     alternate_contact_no: "",
  //     about_user: bio,
  //     email,
  //     dob: dob, //`${dobSplit[2]}-${dobSplit[1]}-${dobSplit[0]}`,
  //     doj: joiningDate, //`${dojsplit[2]}-${dojsplit[1]}-${dojsplit[0]}`,
  //     known_languages: ["English"],
  //     family_details: {
  //       // father,
  //       // mother,
  //       // siblings,
  //       marital_status: maritalStatus
  //     },
  //     current_shifts: [],
  //     company: props.company,
  //     city: "",
  //     country: "",
  //     department: "",
  //     blood_group: bloodGroup,
  //     emp_code: empId,
  //     org_id: props.org_id._id,
  //     role,
  //     driving_licence_no: drivingLicence,
  //     // designation: "",
  //     medical_details: {
  //       health_issues: healthIssues,
  //       health_id: healthCard,
  //       province_of_issue: provinceOfIssue
  //     },
  //     residency: {
  //       curr_addr: currentAddress,
  //       permanent_addr: permanentAddress,
  //       mailing_addr: mailingAddress
  //     },
  //     emergency_contacts: [
  //       {
  //         name: emergencyContactName,
  //         relationship: emergencyContactRelation,
  //         mobile_no: {
  //           country_code: "",
  //           mobile: emergencyContact
  //         },
  //         landline_no: emergencyContactEmail
  //       }
  //     ],
  //     educational_details: [
  //       {
  //         school: graduationInstitute,
  //         degree: "Graduation",
  //         field_of_study: graduationSpec,
  //         start_year: graduationStartDate,
  //         end_year: graduationEndDate,
  //         grade: "",
  //         activities: "",
  //         description: "",
  //         attachments_url: []
  //       },
  //       {
  //         school: postGraduationInstitute,
  //         degree: "Post Graduation",
  //         field_of_study: postGraduationSpec,
  //         start_year: postGraduationStartDate,
  //         end_year: postGraduationEndDate,
  //         grade: "",
  //         activities: "",
  //         description: "",
  //         attachments_url: []
  //       }
  //     ],
  //     account_details: {
  //       sin: sinNo,
  //       account_no: accountNo,
  //       bank_name:  bankName,
  //       institution_no : institutionNo,
  //       transit_no : transitNo
  //     },
  //     l1_manager_details: {
  //       l1_name: l1Name,
  //       l1_contact: l1Contact,
  //       l1_email: l1Email
  //     },
  //     hr_manager_details: {
  //       hr_name: hrName,
  //       hr_contact: hrContact,
  //       hr_email: hrEmail
  //     },
  //     supervisor_details: {
  //       sup_name: supervisorName,
  //       sup_contact: supervisorContact,
  //       sup_email: supervisorEmail
  //     },
  //     user: props
  //   };
  //   console.log("Form Submitted", body);
  //   try {
  //     let response;

  //     // Call Update API
  //     body._id = props._id;
  //     response = await axios.put(
  //       `${process.env.REACT_APP_API_BASE_URL}/users/update`,
  //       body
  //     );

  //     if (response.status === 200) {
  //       console.log("Response Data >>", response.data.data);
  //       props.closeForm();
  //     }
  //   } catch (e) {
  //     if (e.response.status === 400) {
  //       console.log("Error in updating changes")
  //     }
  //   }
  // };

  const Certification = (props) => {
    return (
      <Grid item xs={12} sm={6} lg={4} xl={4} spacing={1}>
        <Paper component='div' className={classes.papersub}>
          <Grid container direction='row' justify='space-evenly'>
            <Grid
              item
              xs={5}
              sm={5}
              md={5}
              lg={5}
              style={{ textAlign: "left" }}
            >
              {" "}
              <Typography variant='subtitle1' className={classes.info}>
                &nbsp; Name :
              </Typography>
              <Typography variant='subtitle1' className={classes.info}>
                &nbsp; Issued By :{" "}
              </Typography>
              <Typography variant='subtitle1' className={classes.info}>
                &nbsp; Issued Date :{" "}
              </Typography>
              <Typography variant='subtitle1' className={classes.info}>
                &nbsp; Expiration Date :{" "}
              </Typography>
            </Grid>

            <Grid
              item
              xs={7}
              style={{
                textAlign: "left",
                marginTop: "-7px",
                fontSize: ".8rem",
              }}
            >
              {" "}
              <CssInputBase
                className={classes.infoValues}
                name='grad_begin'
                disabled={props.readOnly}
                value={props.certificte_name}
                inputProps={{ "aria-label": "naked" }}
              />
              <CssInputBase
                className={classes.infoValues}
                name='pg_begin'
                disabled={props.readOnly}
                value={props.issusing_orgnization}
                inputProps={{ "aria-label": "naked" }}
              />
              <CssInputBase
                className={classes.infoValues}
                name='pg_completion'
                disabled={props.readOnly}
                value={props.issuing_date}
                inputProps={{ "aria-label": "naked" }}
              />
              <CssInputBase
                className={classes.infoValues}
                name='pg_institute'
                disabled={props.readOnly}
                value={props.does_expire ? "Nill" : props.expiration_date}
                inputProps={{ "aria-label": "naked" }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  };
  const Degree = (props) => {
    return (
      <Grid item xs={12} sm={6} lg={4} xl={4} spacing={1}>
        <Paper component='div' className={classes.papersub}>
          <Grid container direction='row' justify='space-evenly'>
            <Grid item xs={4} style={{ textAlign: "left" }}>
              {" "}
              <Typography variant='subtitle1' className={classes.info}>
                &nbsp; Degree :
              </Typography>
              <Typography variant='subtitle1' className={classes.info}>
                &nbsp; Start Year :{" "}
              </Typography>
              <Typography variant='subtitle1' className={classes.info}>
                &nbsp; End Year :{" "}
              </Typography>
              <Typography variant='subtitle1' className={classes.info}>
                &nbsp; Remarks :{" "}
              </Typography>
            </Grid>

            <Grid
              item
              xs={8}
              style={{
                textAlign: "left",
                marginTop: "-7px",
                fontSize: ".8rem",
              }}
            >
              {" "}
              <CssInputBase
                className={classes.infoValues}
                name='grad_begin'
                disabled={props.readOnly}
                value={props.degree_name}
                inputProps={{ "aria-label": "naked" }}
              />
              <CssInputBase
                className={classes.infoValues}
                name='grad_begin'
                disabled={props.readOnly}
                value={props.start_year}
                inputProps={{ "aria-label": "naked" }}
              />
              <CssInputBase
                className={classes.infoValues}
                name='grad_completion'
                disabled={props.readOnly}
                value={props.end_year}
                inputProps={{ "aria-label": "naked" }}
              />
              <CssInputBase
                className={classes.infoValues}
                name='grad_institute'
                disabled={props.readOnly}
                value={props.remarks}
                inputProps={{ "aria-label": "naked" }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <Container className={classes.container}>
          <Grid
            container
            justify='space-evenly'
            className={classes.profileBrief}
            style={{ color: "white", marginBottom: "2%" }}
          >
            <Grid item xs={11} sm={5}>
              <Grid container spacing={5} alignItems='center'>
                {/* ***custom Avatar*** */}

                <Grid item xs={4} sm={6} md={4} lg={5}>
                  <img
                    alt='emp-avatar'
                    src={
                      props.image_url ||
                      "https://image.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg"
                    }
                    className={classes.mainAvatar}
                  />
                </Grid>

                <Grid item xs={8} sm={6} lg={7}>
                  <ThemeProvider theme={theme}>
                    <Grid container direction='column' spacing={6}>
                      <Grid item>
                        <Typography align='left' variant='h5'>
                          {" "}
                          {props.first_name} {props.last_name}
                        </Typography>
                        <Typography
                          align='left'
                          variant='subtitle1'
                          className={classes.fadedWhite}
                        >
                          {props.role}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Hidden smDown>
                          <div style={{ textAlign: "left" }}>
                            <Typography
                              variant='subtitle2'
                              className={classes.fadedWhite}
                              display='inline'
                            >
                              {" "}
                              Employee Id &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                            </Typography>
                            <Typography
                              variant='body2'
                              display='inline'
                              style={{ fontWeight: "600" }}
                            >
                              {props.emp_code}
                            </Typography>
                          </div>

                          <div style={{ textAlign: "left" }}>
                            <Typography
                              align='left'
                              variant='subtitle2'
                              className={classes.fadedWhite}
                              display='inline'
                            >
                              {" "}
                              Last punched in &nbsp; &nbsp;
                            </Typography>
                            <Typography
                              variant='body2'
                              display='inline'
                              style={{ fontWeight: "600" }}
                            >
                              {lastPunchedIn}
                            </Typography>
                          </div>
                        </Hidden>
                      </Grid>
                    </Grid>
                  </ThemeProvider>
                </Grid>
              </Grid>

              <Hidden mdUp>
                <Grid item xs={12} sm={10}>
                  <ThemeProvider theme={theme}>
                    <div style={{ textAlign: "left" }}>
                      <Typography
                        variant='subtitle2'
                        className={classes.fadedWhite}
                        display='inline'
                      >
                        {" "}
                        Employee Id &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                      </Typography>
                      <Typography
                        variant='body2'
                        display='inline'
                        style={{ fontWeight: "600" }}
                      >
                        {props.emp_code}
                      </Typography>
                    </div>

                    <div style={{ textAlign: "left" }}>
                      <Typography
                        align='left'
                        variant='subtitle2'
                        className={classes.fadedWhite}
                        display='inline'
                      >
                        {" "}
                        Last punched in &nbsp; &nbsp;{" "}
                      </Typography>
                      <Typography
                        variant='body2'
                        display='inline'
                        style={{ fontWeight: "600" }}
                      >
                        {props.last_login_timestamp && (
                          <b>
                            {moment(props.last_login_timestamp).format(
                              "ddd, MMM Do YY, h:mm a"
                            )}
                          </b>
                        )}
                      </Typography>
                    </div>
                  </ThemeProvider>
                </Grid>
              </Hidden>
            </Grid>

            {/* ------------------------------------------------------------------------------ */}
            <Hidden xsDown>
              <Divider
                orientation='vertical'
                flexItem
                style={{ backgroundColor: "rgba(255,255,255,0.7)" }}
              ></Divider>
            </Hidden>

            <Hidden smUp>
              <Grid item xs={11}>
                <Divider
                  orientation='horizontal'
                  style={{ backgroundColor: "rgba(255,255,255,0.7)" }}
                />
              </Grid>
            </Hidden>

            {/* {ResponsiveDivider()} */}
            {/* ------------------------------------------------------------------------------ */}

            <Grid item xs={11} sm={5}>
              <div className={classes.containerPad}>
                <ThemeProvider theme={theme}>
                  <Typography variant='subtitle1' gutterBottom align='left'>
                    Bio
                  </Typography>

                  <Typography
                    variant='body2'
                    gutterBottom
                    align='left'
                    className={classes.bioContent}
                  >
                    {props.about_user}
                  </Typography>
                </ThemeProvider>
              </div>
            </Grid>
          </Grid>

          <div className={myClasses.root}>
            <AppBar
              position='static'
              style={{
                borderRadius: " 5px",
                boxShadow: "lightgrey 0px 0px 2px 1px",
                backgroundColor: "#fff",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor='primary'
                textColor='primary'
                variant='scrollable'
                scrollButtons='auto'
                aria-label='scrollable auto tabs example'
              >
                <Tab
                  className={myClasses.label}
                  label='Basic Information'
                  {...a11yProps(0)}
                />
                <Tab
                  className={myClasses.label}
                  label='Employee Details'
                  {...a11yProps(1)}
                />
                <Tab
                  className={myClasses.label}
                  label='Education'
                  {...a11yProps(2)}
                />
                <Tab
                  className={myClasses.label}
                  label='Bank Details'
                  {...a11yProps(3)}
                />
                <Tab
                  className={myClasses.label}
                  label='Documents'
                  {...a11yProps(4)}
                />
                <Tab
                  className={myClasses.label}
                  label='Organisation'
                  {...a11yProps(5)}
                />
              </Tabs>
            </AppBar>

            <TabPanel value={value} index={0}>
              {/* BASIC INFO PANEL */}
              <Grid container direction='row' spacing={3}>
                {/* Personal------------  */}
                <Grid item xs={12} sm={6} lg={4} xl={4} spacing={3}>
                  <Paper component='div' className={classes.paper}>
                    <Grid container>
                      <Typography variant='h5' color='primary'
                        style={{ paddingLeft: '7px', paddingTop: '10px' }}
                      >
                        Personal
                      </Typography>
                    </Grid>
                    <Grid container direction='row' justify='space-between'
                      style={{ marginTop: "20px" }}

                    >

                      <Grid
                        item
                        xs={5}
                        sm={5}
                        md={4}
                        lg={6}
                        style={{ textAlign: "left" }}
                      >
                        <Typography
                          variant='subtitle1'
                          className={classes.info}
                        >
                          &nbsp; First Name :{" "}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={classes.info}
                        >
                          &nbsp; Last Name :{" "}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={classes.info}
                        >
                          &nbsp; Date Of Birth :{" "}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={classes.info}
                        >
                          &nbsp; Gender :{" "}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={classes.info}
                        >
                          &nbsp; Blood Group :{" "}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={classes.info}
                        >
                          &nbsp; Marital Status :{" "}
                        </Typography>

                      </Grid>

                      <Grid
                        item
                        xs={6}
                        sm={6}
                        lg={5}
                        style={{
                          textAlign: "left",
                          marginTop: "-7px",
                          fontSize: ".8rem",
                        }}
                      >
                        <CssInputBase
                          name='first_name'
                          disabled={props.readOnly}
                          className={classes.infoValues}
                          value={fname}
                          // onChange={e => setFname(e.target.value)}
                          inputProps={{ "aria-label": "naked" }}
                        />
                        <CssInputBase
                          name='last_name'
                          className={classes.infoValues}
                          disabled={props.readOnly}
                          value={lname}
                          // onChange={e => {
                          //   setLname(e.target.value)
                          // }}
                          inputProps={{ "aria-label": "naked" }}
                        />
                        <CssInputBase
                          name='date_of_birth'
                          className={classes.infoValues}
                          disabled={props.readOnly}
                          value={dob}
                          // onChange={e => {
                          //   setDob(e.target.value)
                          // }}
                          inputProps={{ "aria-label": "naked" }}
                        />
                        <CssInputBase
                          name='gender'
                          className={classes.infoValues}
                          disabled={props.readOnly}
                          value={gender}
                          // onChange={e => {
                          //   setGender(e.target.value)
                          // }}
                          inputProps={{ "aria-label": "naked" }}
                        />
                        <CssInputBase
                          name='blood_group'
                          disabled={props.readOnly}
                          value={bloodGroup}
                          // onChange={e => {
                          // setBloodGroup(e.target.value)
                          // }}
                          className={classes.infoValues}
                          inputProps={{ "aria-label": "naked" }}
                        />
                        <CssInputBase
                          className={classes.infoValues}
                          name='maritalStatus'
                          disabled={props.readOnly}
                          value={maritalStatus}
                          // onChange={e => setMaritalStatus(e.target.value)}
                          inputProps={{ "aria-label": "naked" }}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                {/* Address */}
                <Grid item xs={12} sm={6} lg={4} spacing={3}>
                  <Paper component='div' className={classes.paper}>
                    <Grid container>
                      <Typography variant='h5'
                        style={{ paddingLeft: '7px', paddingTop: '10px' }}
                        color='primary'>
                        Current Address
                      </Typography>
                    </Grid>
                    <Grid container direction='row' justify='space-between'
                      style={{ marginTop: "20px" }}
                    >
                      <Grid
                        item
                        xs={5}
                        sm={6}
                        md={4}
                        lg={6}
                        style={{ textAlign: "left" }}
                      >
                        <Typography
                          variant='subtitle1'
                          className={classes.info}
                        >
                          &nbsp; Street Address:{" "}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={classes.info}
                        >
                          &nbsp; Apt/Unit#:{" "}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={classes.info}
                        >
                          &nbsp; City:{" "}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={classes.info}
                        >
                          &nbsp; Province:{" "}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={classes.info}
                        >
                          &nbsp; Postal Code:{" "}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xs={5}
                        sm={4}
                        md={6}
                        lg={6}
                        style={{ textAlign: "left" }}
                      >
                        <Typography
                          variant='body1'
                          display='block'
                          className={classes.infoValues}
                          gutterBottom
                        >
                          {" "}
                          {curr_addr.street_addr || ""}{" "}
                        </Typography>
                        <Typography
                          variant='body1'
                          display='block'
                          className={classes.infoValues}
                          gutterBottom
                        >
                          {" "}
                          {curr_addr.apt_unit || ""}{" "}
                        </Typography>
                        <Typography
                          variant='body1'
                          display='block'
                          className={classes.infoValues}
                          gutterBottom
                        >
                          {" "}
                          {curr_addr.city || ""}{" "}
                        </Typography>
                        <Typography
                          variant='body1'
                          display='block'
                          className={classes.infoValues}
                          gutterBottom
                        >
                          {" "}
                          {curr_addr.province || ""}{" "}
                        </Typography>
                        <Typography
                          variant='body1'
                          display='block'
                          className={classes.infoValues}
                          gutterBottom
                        >
                          {" "}
                          {curr_addr.postal_code || ""}{" "}
                        </Typography>
                      </Grid>

                      {/* <Grid item xs={6} sm={5} style={{ textAlign: "left" }}>
                        <Typography
                          variant='subtitle1'
                          className={classes.address}
                        >
                          &nbsp; Current Address:{" "}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={classes.address}
                        >
                          &nbsp; Permanent Address:{" "}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={classes.address}
                        >
                          &nbsp; Mailing Address:{" "}
                        </Typography>
                      </Grid>

                      <Grid item xs={5} sm={6} style={{ textAlign: "left" }}>
                        <Typography
                          variant='body1'
                          display='block'
                          className={classes.addressValue}
                          gutterBottom
                        >
                          {currentAddress}
                        </Typography>
                        <Typography
                          variant='body1'
                          display='block'
                          className={classes.addressValue}
                          gutterBottom
                        >
                          {" "}
                          {permanentAddress}
                        </Typography>
                        <Typography
                          variant='body1'
                          display='block'
                          className={classes.addressValue}
                          gutterBottom
                        >
                          {mailingAddress}
                        </Typography>
                      </Grid> */}
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} spacing={3}>
                  <Paper component='div' className={classes.paper}>
                    <Grid container>
                      <Typography variant='h5'
                        style={{ paddingLeft: '7px', paddingTop: '10px' }}
                        color='primary'
                      >
                        Permanent Address
                      </Typography>
                    </Grid>
                    <Grid container direction='row' justify='space-between'
                      style={{ marginTop: "20px" }}
                    >

                      <Grid
                        item
                        xs={5}
                        sm={6}
                        md={4}
                        lg={6}
                        style={{ textAlign: "left" }}
                      >
                        <Typography
                          variant='subtitle1'
                          className={classes.info}
                        >
                          &nbsp; Street Address:{" "}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={classes.info}
                        >
                          &nbsp; Apt/Unit#:{" "}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={classes.info}
                        >
                          &nbsp; City:{" "}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={classes.info}
                        >
                          &nbsp; Province:{" "}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={classes.info}
                        >
                          &nbsp; Postal Code:{" "}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xs={5}
                        sm={4}
                        md={6}
                        lg={6}
                        style={{ textAlign: "left" }}
                      >
                        <Typography
                          variant='body1'
                          display='block'
                          className={classes.infoValues}
                          gutterBottom
                        >
                          {" "}
                          {permanent_addr.street_addr || ""}{" "}
                        </Typography>
                        <Typography
                          variant='body1'
                          display='block'
                          className={classes.infoValues}
                          gutterBottom
                        >
                          {" "}
                          {permanent_addr.apt_unit || ""}{" "}
                        </Typography>
                        <Typography
                          variant='body1'
                          display='block'
                          className={classes.infoValues}
                          gutterBottom
                        >
                          {" "}
                          {permanent_addr.city || ""}{" "}
                        </Typography>
                        <Typography
                          variant='body1'
                          display='block'
                          className={classes.infoValues}
                          gutterBottom
                        >
                          {" "}
                          {permanent_addr.province || ""}{" "}
                        </Typography>
                        <Typography
                          variant='body1'
                          display='block'
                          className={classes.infoValues}
                          gutterBottom
                        >
                          {" "}
                          {permanent_addr.postal_code || ""}{" "}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6} lg={4} spacing={3}>
                  <Paper component='div' className={classes.paper}>
                      <Grid container>
                        <Typography variant='h5'
                          style={{ paddingLeft: '7px', paddingTop: '10px' }}
                          color='primary'
                        >
                          Mailing Address
                        </Typography>
                      </Grid>
                    <Grid container direction='row' justify='space-between' style={{marginTop:'20px'}}>

                      <Grid
                        item
                        xs={5}
                        sm={6}
                        md={4}
                        lg={6}
                        style={{ textAlign: "left" }}
                      >
                        <Typography
                          variant='subtitle1'
                          className={classes.info}
                        >
                          &nbsp; Street Address:{" "}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={classes.info}
                        >
                          &nbsp; Apt/Unit#:{" "}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={classes.info}
                        >
                          &nbsp; City:{" "}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={classes.info}
                        >
                          &nbsp; Province:{" "}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={classes.info}
                        >
                          &nbsp; Postal Code:{" "}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xs={5}
                        sm={4}
                        md={6}
                        lg={6}
                        style={{ textAlign: "left" }}
                      >
                        <Typography
                          variant='body1'
                          display='block'
                          className={classes.infoValues}
                          gutterBottom
                        >
                          {" "}
                          {mailing_addr.street_addr || ""}{" "}
                        </Typography>
                        <Typography
                          variant='body1'
                          display='block'
                          className={classes.infoValues}
                          gutterBottom
                        >
                          {" "}
                          {mailing_addr.apt_unit || ""}{" "}
                        </Typography>
                        <Typography
                          variant='body1'
                          display='block'
                          className={classes.infoValues}
                          gutterBottom
                        >
                          {" "}
                          {mailing_addr.city || ""}{" "}
                        </Typography>
                        <Typography
                          variant='body1'
                          display='block'
                          className={classes.infoValues}
                          gutterBottom
                        >
                          {" "}
                          {mailing_addr.province || ""}{" "}
                        </Typography>
                        <Typography
                          variant='body1'
                          display='block'
                          className={classes.infoValues}
                          gutterBottom
                        >
                          {" "}
                          {mailing_addr.postal_code || ""}{" "}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </TabPanel>
            {/* ------------------------------------------------------------ */}
            <TabPanel value={value} index={1}>
              {/* EMPLOYEE INFO PANEL */}
              <Grid container direction='row' spacing={4}>
                {/* Graduation------------  */}
                <Grid item xs={12} sm={6} lg={4}>
                  <Paper component='div' className={classes.paper}>
                    <Grid
                      container
                    >
                      <Typography variant='h5'
                        style={{ paddingLeft: '7px', paddingTop: '10px' }}
                        color='primary'
                      >
                        Personal
                      </Typography>
                    </Grid>
                    <Grid container direction='row' justify='space-between' style={{marginTop:'20px'}}>
                      <Grid item xs={6} sm={5} style={{ textAlign: "left" }}>
                        <Typography
                          variant='subtitle1'
                          className={myClasses.info}
                        >
                          &nbsp; SIN :{" "}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={myClasses.info}
                        >
                          &nbsp; Start Date :{" "}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={myClasses.info}
                        >
                          &nbsp; Position :{" "}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={myClasses.info}
                        >
                          &nbsp; Official No :{" "}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={myClasses.info}
                        >
                          &nbsp; Personal No :{" "}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={myClasses.info}
                        >
                          &nbsp; Alternate No :{" "}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={myClasses.info}
                        >
                          &nbsp; Email :{" "}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={myClasses.info}
                        >
                          &nbsp; Driving License :{" "}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        sm={7}
                        style={{
                          textAlign: "left",
                          fontSize: ".8rem",
                        }}
                      >
                        <Typography
                          variant='body1'
                          display='block'
                          className={myClasses.infoValues}
                          gutterBottom
                        >
                          {sinNo}
                        </Typography>
                        <Typography
                          variant='body1'
                          display='block'
                          className={myClasses.infoValues}
                          gutterBottom
                        >
                          {joiningDate}
                        </Typography>
                        <Typography
                          variant='body1'
                          display='block'
                          className={myClasses.infoValues}
                          gutterBottom
                        >
                          {position}
                        </Typography>
                        <Typography
                          variant='body1'
                          display='block'
                          className={myClasses.infoValues}
                          gutterBottom
                        >
                          {contactOfficial}
                        </Typography>
                        <Typography
                          variant='body1'
                          display='block'
                          className={myClasses.infoValues}
                          gutterBottom
                        >
                          {contact}
                        </Typography>
                        <Typography
                          variant='body1'
                          display='block'
                          className={myClasses.infoValues}
                          gutterBottom
                        >
                          {contactAlternate}
                        </Typography>
                        <Typography
                          variant='body1'
                          display='block'
                          className={myClasses.infoValues}
                          gutterBottom
                        >
                          {email}
                        </Typography>
                        <Typography
                          variant='body1'
                          display='block'
                          className={myClasses.infoValues}
                          gutterBottom
                        >
                          {drivingLicence}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

                {/* --------------------------Medical Details------------------ */}
                <Grid item xs={12} sm={6} lg={4}>
                  <Paper component='div' className={classes.paper}>
                      <Grid container>
                        <Typography variant='h5'
                          style={{ paddingLeft: '7px', paddingTop: '10px' }}
                          color='primary'
                        >
                          Medical Details
                        </Typography>
                      </Grid>
                    <Grid container direction='row' justify='space-between' style={{marginTop:'20px'}}>

                      <Grid item xs={6} lg={6} style={{ textAlign: "left" }}>
                        <Typography
                          variant='subtitle1'
                          className={classes.info}
                        >
                          &nbsp; Health Issues :{" "}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={classes.info}
                        >
                          &nbsp; Health Card :{" "}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={classes.info}
                        >
                          &nbsp; Province Of Issue:{" "}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xs={5}
                        lg={5}
                        style={{
                          textAlign: "left",
                          marginTop: "-7px",
                          fontSize: ".8rem",
                        }}
                      >
                        <CssInputBase
                          className={classes.infoValues}
                          name='health_issues'
                          disabled={props.readOnly}
                          value={healthIssues}
                          // onChange={e => setHealthIssues(e.target.value)}
                          inputProps={{ "aria-label": "naked" }}
                        />

                        <CssInputBase
                          className={classes.infoValues}
                          name='health_card_id'
                          disabled={props.readOnly}
                          value={healthCard}
                          // onChange={e => setHealthCard(e.target.value)}
                          inputProps={{ "aria-label": "naked" }}
                        />
                        <CssInputBase
                          className={classes.infoValues}
                          name='health_card_province'
                          disabled={props.readOnly}
                          value={provinceOfIssue}
                          // onChange={e => setProvinceOfIssue(e.target.value)}
                          inputProps={{ "aria-label": "naked" }}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

                {/* emergency----------------- */}
                <Grid item xs={12} sm={6} lg={4}>
                  <Paper component='div' className={classes.paper}>
                    <Grid container>
                      <Typography variant='h5'
                        style={{ paddingLeft: '7px', paddingTop: '10px' }}
                        color='primary'
                      >
                        In Case of Emergency
                      </Typography>
                    </Grid>
                    <Grid container direction='row' justify='space-between'
                      style={{ textAlign: "left", marginTop: '20px' }}
                    >

                      <Grid item xs={6} lg={6}>
                        <Typography
                          variant='subtitle1'
                          className={classes.info}
                        >
                          &nbsp; Preferred Contact :{" "}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={classes.info}
                        >
                          &nbsp; Relation :{" "}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={classes.info}
                        >
                          &nbsp; Contact :{" "}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={classes.info}
                        >
                          &nbsp; Email :{" "}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xs={5}
                        lg={6}
                        style={{
                          textAlign: "left",
                          fontSize: ".8rem",
                        }}
                      >
                        <Typography
                          variant='subtitle1'
                          className={classes.infoValues}
                          gutterBottom
                        >
                          {emergencyContact}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={classes.infoValues}
                        >
                          {emergencyContactRelation}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={classes.infoValues}
                        >
                          {emergencyContactName}
                        </Typography>

                        <Typography
                          variant='subtitle1'
                          className={classes.infoValues}
                        >
                          {emergencyContactEmail}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </TabPanel>
            {/* ---------------------------------------------------- */}
            <TabPanel value={value} index={2}>
              {/* EDUCATION PANEL */}
              <Grid container direction='row' spacing={4}>
                {/* Graduation------------  */}
                <Grid item xs={12} sm={12} lg={12}>
                  <Paper component='div' className={classes.paperMain}>
                    <Grid container direction='column' justify='space-between'>
                      <Grid item xs={12} className={classes.labelsub}>
                        <Typography variant='h5' color='primary'>
                          Degrees
                        </Typography>
                      </Grid>
                      <Grid container direction='row' spacing={3}>
                        {degree.length ? (
                          degree.map((d) => {
                            return Degree({ ...d, readOnly: props.readOnly });
                          })
                        ) : (
                          <>
                            <p
                              style={{
                                color: "grey",
                                margin: "auto",
                                padding: "50px",
                              }}
                            >
                              No degrees added
                            </p>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                {/* Post Graduation----------------- */}
                <Grid item xs={12} sm={12} lg={12}>
                  <Paper component='div' className={classes.paperMain}>
                    <Grid container direction='column' justify='space-between'>
                      <Grid item xs={12} className={classes.labelsub}>
                        <Typography variant='h5' color='primary'>
                          License/Certifications
                        </Typography>
                      </Grid>
                      <Grid container direction='row' spacing={3}>
                        {certification.length ? (
                          certification.map((d) => {
                            return Certification({
                              ...d,
                              readOnly: props.readOnly,
                            });
                          })
                        ) : (
                          <>
                            <p
                              style={{
                                color: "grey",
                                margin: "auto",
                                padding: "50px",
                              }}
                            >
                              No certification/liscence added
                            </p>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </TabPanel>
            {/* -------------------------------------------------------------- */}
            <TabPanel value={value} index={3}>
              {/* BANK DETAILS PANEL */}
              <Grid container direction='row' spacing={4}>
                <Grid item xs={12} sm={8} md={7} lg={6}>
                  <Paper component='div' className={classes.paper}>
                    <Grid container>
                      <Typography variant='h5'
                        style={{ paddingLeft: '7px', paddingTop: '10px' }}
                        color='primary'
                      >
                        Account details
                      </Typography>
                    </Grid>
                    <Grid container direction='row' justify='space-between'
                      style={{ marginTop: '20px' }}
                    >

                      <Grid item xs={6} style={{ textAlign: "left" }}>
                        <Typography
                          variant='subtitle1'
                          className={classes.info}
                        >
                          &nbsp; Account No. :{" "}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={classes.info}
                        >
                          &nbsp; Bank Name :{" "}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={classes.info}
                        >
                          &nbsp; Institution No. :{" "}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={classes.info}
                        >
                          &nbsp; Transit No. :{" "}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xs={5}
                        style={{
                          textAlign: "left",
                          marginTop: "-7px",
                          fontSize: ".8rem",
                        }}
                      >
                        <CssInputBase
                          className={classes.infoValues}
                          name='acc_no'
                          disabled={props.readOnly}
                          value={accountNo}
                          // onChange={e => {
                          // setAccountNo(e.target.value)
                          // }}
                          inputProps={{ "aria-label": "naked" }}
                        />
                        <CssInputBase
                          className={classes.infoValues}
                          name='bank_name'
                          disabled={props.readOnly}
                          value={bankName}
                          // onChange={e => {
                          //   setBankName(e.target.value);
                          // }}
                          inputProps={{ "aria-label": "naked" }}
                        />
                        <CssInputBase
                          className={classes.infoValues}
                          name='institution_no'
                          disabled={props.readOnly}
                          value={institutionNo}
                          // onChange={e => {
                          //   setInstitutionNo(e.target.value);
                          // }}
                          inputProps={{ "aria-label": "naked" }}
                        />
                        <CssInputBase
                          className={classes.infoValues}
                          name='transit_no'
                          disabled={props.readOnly}
                          value={transitNo}
                          // onChange={e => {
                          //   setTransitNo(e.target.value);
                          // }}
                          inputProps={{ "aria-label": "naked" }}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </TabPanel>
            {/* --------------------------------------------------------- */}
            <TabPanel value={value} index={4}>
              {/* DOCUMENTS PANEL */}
              <Grid container direction='row' spacing={4}>
                {/* Personal------------  */}
                <Grid item xs={12} sm={6} lg={4}>
                  <Paper component='div' className={classes.paper}>
                    <Grid container direction='row' justify='space-evenly'>
                      <Grid item xs={12} className={classes.label}>
                        <Typography variant='h5' color='primary'>
                          Personal Docs
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Scrollbars
                          style={{ width: "100%", height: "175px" }}
                          autoHide
                          autoHideTimeout={1000}
                        >
                          <List dense>{getDocumentList(PersonalDoc)}</List>
                        </Scrollbars>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                {/* Company----------------- */}
                <Grid item xs={12} sm={6} lg={4}>
                  <Paper component='div' className={classes.paper}>
                    <Grid container direction='row' justify='space-evenly'>
                      <Grid item xs={12} className={classes.label}>
                        <Typography variant='h5' color='primary'>
                          Company Docs
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Scrollbars
                          style={{ width: "100%", height: "175px" }}
                          autoHide
                          autoHideTimeout={1000}
                        >
                          <List dense>{getDocumentList(CompanyDoc)}</List>
                        </Scrollbars>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={6} lg={4}>
                  <Paper component='div' className={classes.paper}>
                    <Grid container direction='row' justify='space-evenly'>
                      <Grid item xs={12} className={classes.label}>
                        <Typography variant='h5' color='primary'>
                          Official Docs
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Scrollbars
                          style={{ width: "100%", height: "175px" }}
                          autoHide
                          autoHideTimeout={1000}
                        >
                          <List dense>{getDocumentList(OfficialDoc)}</List>
                        </Scrollbars>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

                {/* Extra */}
                <Grid item xs={12} sm={6} lg={4}>
                  <Paper component='div' className={classes.paper}>
                    <Grid container direction='row' justify='space-evenly'>
                      <Grid item xs={12} className={classes.label}>
                        <Typography variant='h5' color='primary'>
                          Extra Docs
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Scrollbars
                          style={{ width: "100%", height: "175px" }}
                          autoHide
                          autoHideTimeout={1000}
                        >
                          <List dense>{getDocumentList(AdditionalDoc)}</List>
                        </Scrollbars>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </TabPanel>
            {/* -------------------------------------------------------- */}
            <TabPanel value={value} index={5}>
              {/* ORGANISATION PANEL */}
              <Grid container direction='row' spacing={4}>
                {/* L1 Manager Details------------  */}
                <Grid item xs={12} sm={6} lg={4}>
                  <Paper component='div' className={classes.paper}>
                    <Grid
                      container
                      direction='row'
                      justify='space-between'
                      alignItems='center'
                    >
                      <Grid
                        item
                        xs={9}
                        style={{
                          textAlign: "left",
                          margin: "30px 0px 30px 0px",
                        }}
                      >
                        <Typography variant='h5'
                          style={{
                            paddingLeft: '7px'
                          }}
                          color='primary'>
                          L1 Manager Details
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xs={3}
                      >
                        <img
                          alt='manager-avatar'
                          // change later
                          src={
                            props.manager_id ||
                            "https://image.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg"
                          }
                          className={classes.avatar}
                        />
                      </Grid>

                    </Grid>
                    <Grid
                      container
                      direction='row'
                      justify='space-between'
                      alignItems='center'
                    >
                      <Grid item xs={3} style={{ textAlign: "left" }}>
                        <Typography
                          variant='subtitle1'
                          className={myClasses.info}
                        >
                          &nbsp; Name :{" "}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={myClasses.info}
                        >
                          &nbsp; Email :{" "}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={myClasses.info}
                        >
                          &nbsp; Contact :{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={9} style={{ textAlign: "left" }}>
                        <Typography
                          variant='body1'
                          display='block'
                          className={myClasses.infoValues}
                          gutterBottom
                        >
                          {l1Name}
                        </Typography>
                        <Typography
                          variant='body1'
                          display='block'
                          className={myClasses.infoValues}
                          gutterBottom
                        >
                          {l1Email}
                        </Typography>
                        <Typography
                          variant='body1'
                          display='block'
                          className={myClasses.infoValues}
                          gutterBottom
                        >
                          {l1Contact}
                        </Typography>
                      </Grid>

                    </Grid>
                  </Paper>
                </Grid>
                {/* HR Manager ------------  */}
                <Grid item xs={12} sm={6} lg={4}>
                  <Paper component='div' className={classes.paper}>
                    <Grid
                      container
                      direction='row'
                      justify='space-between'
                      alignItems='center'
                    >
                      <Grid
                        item
                        xs={9}
                        style={{
                          textAlign: "left",
                          margin: "30px 0px 30px 0px",
                        }}
                      >
                        <Typography variant='h5'
                          style={{
                            paddingLeft: '7px'
                          }}
                          color='primary'>
                          HR Manager Details
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xs={3}
                      >
                        <img
                          alt='hr-avatar'
                          // change later
                          src={
                            props.hr_id ||
                            "https://image.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg"
                          }
                          className={classes.avatar}
                        />
                      </Grid>

                      <Grid item xs={3} style={{ textAlign: "left" }}>
                        <Typography
                          variant='subtitle1'
                          className={myClasses.info}
                        >
                          &nbsp; Name :{" "}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={myClasses.info}
                        >
                          &nbsp; Email :{" "}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={myClasses.info}
                        >
                          &nbsp; Contact :{" "}
                        </Typography>
                      </Grid>

                      <Grid item xs={4} style={{ textAlign: "left" }}>
                        <Typography
                          variant='body1'
                          display='block'
                          className={myClasses.infoValues}
                          gutterBottom
                        >
                          {hrName}
                        </Typography>
                        <Typography
                          variant='body1'
                          display='block'
                          className={myClasses.infoValues}
                          gutterBottom
                        >
                          {hrEmail}
                        </Typography>
                        <Typography
                          variant='body1'
                          display='block'
                          className={myClasses.infoValues}
                          gutterBottom
                        >
                          {hrContact}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                {/* Supervisor ------------  */}
                <Grid item xs={12} sm={6} lg={4}>
                  <Paper component='div' className={classes.paper}>
                    <Grid
                      container
                      direction='row'
                      justify='space-between'
                      alignItems='center'
                    >
                      <Grid
                        item
                        xs={9}
                        style={{
                          textAlign: "left",
                          margin: "30px 0px 30px 0px",
                        }}
                      >
                        <Typography variant='h5'
                          style={{
                            paddingLeft: '7px'
                          }}
                          color='primary'>
                          Supervisor Details
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xs={3}
                      >
                        <img
                          alt='supervisor-avatar'
                          // change later
                          src={
                            props.hr_id ||
                            "https://image.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg"
                          }
                          className={classes.avatar}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction='row'
                      justify='space-between'
                      alignItems='center'
                    >

                      <Grid item xs={6} style={{ textAlign: "left" }}>
                        <Typography
                          variant='subtitle1'
                          className={myClasses.info}
                        >
                          &nbsp; Name :{" "}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={myClasses.info}
                        >
                          &nbsp; Email :{" "}
                        </Typography>
                        <Typography
                          variant='subtitle1'
                          className={myClasses.info}
                        >
                          &nbsp; Contact :{" "}
                        </Typography>
                      </Grid>

                      <Grid item xs={4} style={{ textAlign: "left" }}>
                        <Typography
                          variant='body1'
                          display='block'
                          className={myClasses.infoValues}
                          gutterBottom
                        >
                          {supervisorName}
                        </Typography>
                        <Typography
                          variant='body1'
                          display='block'
                          className={myClasses.infoValues}
                          gutterBottom
                        >
                          {supervisorEmail}
                        </Typography>
                        <Typography
                          variant='body1'
                          display='block'
                          className={myClasses.infoValues}
                          gutterBottom
                        >
                          {supervisorContact}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </TabPanel>
          </div>
        </Container>
      </CssBaseline>
    </ThemeProvider>
  );
}

export default ScrollableTabsButtonAuto;
