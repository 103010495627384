import {
  addShiftTagRepo,
  deleteShiftTagRepo,
  getShiftTagsRepo,
  searchShiftTagRepo,
  updateShiftTagRepo,
} from 'store/repositories/ShiftManagement/ShiftTags';
import {
  addShiftTagFulfilled,
  addShiftTagPending,
  addShiftTagRejected,
  deleteShiftTagFulfilled,
  deleteShiftTagPending,
  deleteShiftTagRejected,
  getShiftTagsFulfilled,
  getShiftTagsPending,
  getShiftTagsRejected,
  serachShiftTagsFulfilled,
  serachShiftTagsPending,
  serachShiftTagsRejected,
  updateShiftTagFulfilled,
  updateShiftTagPending,
  updateShiftTagRejected,
} from 'store/slices/ShiftManagement/ShiftTags';

export const getShiftTags = (page = 1, limit = 25) => {
  return async (dispatch) => {
    dispatch(getShiftTagsPending());
    await getShiftTagsRepo(page, limit)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getShiftTagsFulfilled(response?.data?.data));
        } else {
          dispatch(getShiftTagsRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(getShiftTagsRejected(error.message));
        }
      });
  };
};

export const addShiftTag = (Tag) => {
  return async (dispatch) => {
    dispatch(addShiftTagPending());
    await addShiftTagRepo(Tag)
      .then((res) => {
        if (res.status === 200) {
          dispatch(addShiftTagFulfilled(res.data));
          dispatch(getShiftTags());
        } else {
          dispatch(addShiftTagRejected(res?.error));
        }
      })
      .catch((err) => {
        if (err) {
          dispatch(addShiftTagRejected(err.message));
        }
      });
  };
};

export const updateShiftTag = (Tag) => {
  return async (dispatch) => {
    dispatch(updateShiftTagPending());
    await updateShiftTagRepo(Tag)
      .then((res) => {
        if (res.status === 200) {
          dispatch(updateShiftTagFulfilled(res.data));
          dispatch(getShiftTags());
        } else {
          dispatch(updateShiftTagRejected(res?.error));
        }
      })
      .catch((err) => {
        if (err) {
          dispatch(updateShiftTagRejected(err.message));
        }
      });
  };
};

export const deleteShiftTag = (id) => {
  return async (dispatch) => {
    dispatch(deleteShiftTagPending());
    await deleteShiftTagRepo(id)
      .then((res) => {
        if (res.status === 200) {
          dispatch(deleteShiftTagFulfilled(res.data));
          dispatch(getShiftTags());
        } else {
          dispatch(deleteShiftTagRejected(res?.error));
        }
      })
      .catch((err) => {
        if (err) {
          dispatch(deleteShiftTagRejected(err.message));
        }
      });
  };
};

export const serachShiftTags = (obj) => {
  return async (dispatch) => {
    dispatch(serachShiftTagsPending());
    searchShiftTagRepo(obj)
      .then((response) => {
        if (response.status === 200) {
          dispatch(serachShiftTagsFulfilled(response?.data));
        } else {
          dispatch(serachShiftTagsRejected(response?.data));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(serachShiftTagsRejected(error.message));
        }
      });
  };
};
