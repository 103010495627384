import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  assetConditionList: {},
  createAssetCondition: {},
  deleteAssetCondition: {},
  updateAssetCondition: {},
};

export const assetConditionSlice = createSlice({
  name: "AssetCondition",
  initialState,
  reducers: {
    getAllAssetConditonPending(state, action) {
      return {
        ...state,
        assetConditionList: { loading: true },
      };
    },
    getAllAssetConditonFulfilled(state, action) {
      return {
        ...state,
        assetConditionList: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    getAllAssetConditonRejected(state, action) {
      return {
        ...state,
        assetConditionList: {
          loading: false,
          rejected: action.payload,
        },
      };
    },

    deleteAssetConditonPending(state, action) {
      return {
        ...state,
        deleteAssetCondition: { loading: true },
      };
    },
    deleteAssetConditonFulfilled(state, action) {
      return {
        ...state,
        deleteAssetCondition: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    deleteAssetConditonRejected(state, action) {
      return {
        ...state,
        deleteAssetCondition: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
    clearDeleteAssetCondition(state, action) {
      return {
        ...state,
        deleteAssetCondition: {},
      };
    },

    createAssetConditonPending(state, action) {
      return {
        ...state,
        createAssetCondition: { loading: true },
      };
    },
    createAssetConditonFulfilled(state, action) {
      return {
        ...state,
        createAssetCondition: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    createAssetConditonRejected(state, action) {
      return {
        ...state,
        createAssetCondition: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
    clearCreateAssetCondition(state, action) {
      return {
        ...state,
        createAssetCondition: {},
      };
    },

    updateAssetConditionPending(state, action) {
      return {
        ...state,
        updateAssetCondition: { loading: true },
      };
    },
    updateAssetConditionFulfilled(state, action) {
      return {
        ...state,
        updateAssetCondition: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    updateAssetConditionRejected(state, action) {
      return {
        ...state,
        updateAssetCondition: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
    clearUpdateAssetCondition(state, action) {
      return {
        ...state,
        updateAssetCondition: {},
      };
    },
  },
});

export const {
  getAllAssetConditonFulfilled,
  getAllAssetConditonPending,
  getAllAssetConditonRejected,
  deleteAssetConditonPending,
  deleteAssetConditonFulfilled,
  deleteAssetConditonRejected,
  clearDeleteAssetCondition,
  createAssetConditonPending,
  createAssetConditonFulfilled,
  createAssetConditonRejected,
  clearCreateAssetCondition,
  updateAssetConditionPending,
  updateAssetConditionFulfilled,
  updateAssetConditionRejected,
  clearUpdateAssetCondition,
} = assetConditionSlice.actions;

export default assetConditionSlice.reducer;
