import {
  addShiftTaskRepo,
  deleteShiftTaskRepo,
  getShiftTasksRepo,
  searchShiftTaskRepo,
  updateShiftTaskRepo,
} from "store/repositories/ShiftManagement/ShiftTasks";
import { serachPositionRejected } from "store/slices/ShiftManagement/Positions";
import {
  addShiftTaskFulfilled,
  addShiftTaskPending,
  addShiftTaskRejected,
  deleteShiftTaskFulfilled,
  deleteShiftTaskPending,
  deleteShiftTaskRejected,
  getShiftTasksFulfilled,
  getShiftTasksPending,
  getShiftTasksRejected,
  serachShiftTaskFulfilled,
  serachShiftTaskPending,
  serachShiftTaskRejected,
  updateShiftTaskFulfilled,
  updateShiftTaskPending,
  updateShiftTaskRejected,
} from "store/slices/ShiftManagement/ShiftTasks";

export const getShiftTasks = (page, limit) => {
  return async (dispatch) => {
    dispatch(getShiftTasksPending());
    await getShiftTasksRepo(page, limit)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getShiftTasksFulfilled(response?.data?.data));
        } else {
          dispatch(getShiftTasksRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(getShiftTasksRejected(error.message));
        }
      });
  };
};

export const addShiftTask = (task) => {
  return async (dispatch) => {
    dispatch(addShiftTaskPending());
    await addShiftTaskRepo(task)
      .then((res) => {
        if (res.status === 200) {
          dispatch(addShiftTaskFulfilled(res.data));
          dispatch(getShiftTasks());
        } else {
          dispatch(addShiftTaskRejected(res?.error));
        }
      })
      .catch((err) => {
        if (err) {
          dispatch(addShiftTaskRejected(err.message));
        }
      });
  };
};

export const updateShiftTask = (task) => {
  return async (dispatch) => {
    dispatch(updateShiftTaskPending());
    await updateShiftTaskRepo(task)
      .then((res) => {
        if (res.status === 200) {
          dispatch(updateShiftTaskFulfilled(res.data));
          dispatch(getShiftTasks());
        } else {
          dispatch(updateShiftTaskRejected(res?.error));
        }
      })
      .catch((err) => {
        if (err) {
          dispatch(updateShiftTaskRejected(err.message));
        }
      });
  };
};

export const deleteShiftTask = (id) => {
  return async (dispatch) => {
    dispatch(deleteShiftTaskPending());
    await deleteShiftTaskRepo(id)
      .then((res) => {
        if (res.status === 200) {
          dispatch(deleteShiftTaskFulfilled(res.data));
          dispatch(getShiftTasks());
        } else {
          dispatch(deleteShiftTaskRejected(res?.error));
        }
      })
      .catch((err) => {
        if (err) {
          dispatch(deleteShiftTaskRejected(err.message));
        }
      });
  };
};

export const serachShiftTasks = (obj) => {
  return async (dispatch) => {
    dispatch(serachShiftTaskPending());
    searchShiftTaskRepo(obj)
      .then((response) => {
        if (response.status === 200) {
          dispatch(serachShiftTaskFulfilled(response?.data));
        } else {
          dispatch(serachShiftTaskRejected(response?.data));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(serachPositionRejected(error.message));
        }
      });
  };
};
