import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import BasicInfoPanel from "./BasicInfoPanel";
import EducationInfoPanel from "./EducationPanel";
import BankDetailsPanel from "./BankDetailsPanel";
import DocumentsPanel from "./DocumentsPanel";
import OrganisationInfoPanel from "./OrganisationPanel";
import EmployeeInfoPanel from "./EmployeeInfoPanel";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ padding: "24px 0px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  label: {
    color: "royalblue"
  }
}));

function ScrollableTabsButtonAuto() {
  const classes = useStyles();
  // const myClasses = myStyles();
  const [value, setValue] = React.useState(0);
  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <AppBar
          position="static"
          style={{
            borderRadius: " 5px",
            boxShadow: "lightgrey 0px 0px 2px 1px",
            backgroundColor: "#fff"
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab
              className={classes.label}
              label="Basic Information"
              {...a11yProps(0)}
            />
            <Tab
              className={classes.label}
              label="Employee Details"
              {...a11yProps(1)}
            />
            <Tab
              className={classes.label}
              label="Education"
              {...a11yProps(2)}
            />
            <Tab
              className={classes.label}
              label="Bank Details"
              {...a11yProps(3)}
            />
            <Tab
              className={classes.label}
              label="Documents"
              {...a11yProps(4)}
            />
            <Tab
              className={classes.label}
              label="Organisation"
              {...a11yProps(5)}
            />
          </Tabs>
        </AppBar>

        <TabPanel value={value} index={0}>
          <BasicInfoPanel />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <EmployeeInfoPanel />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <EducationInfoPanel />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <BankDetailsPanel />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <DocumentsPanel />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <OrganisationInfoPanel />
        </TabPanel>
      </div>
    </ThemeProvider>
  );
}

export default ScrollableTabsButtonAuto;
