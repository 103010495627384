import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  allAssetList: {},
  addedAsset: {},
  updatedAsset: {},
  assetById: {},
  allAssetListGroupByUser: {},
  assetByUserId: {},
};

export const allAssetSlice = createSlice({
  name: "AllAsset",
  initialState,
  reducers: {
    getAllAssetPending(state, action) {
      return {
        ...state,
        allAssetList: { loading: true },
      };
    },
    getAllAssetFulfilled(state, action) {
      return {
        ...state,
        allAssetList: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    getAllAssetRejected(state, action) {
      return {
        ...state,
        allAssetList: {
          loading: false,
          rejected: action.payload,
        },
      };
    },

    getAllAssetGroupByUserPending(state, action) {
      return {
        ...state,
        allAssetListGroupByUser: { loading: true },
      };
    },
    getAllAssetGroupByUserFulfilled(state, action) {
      return {
        ...state,
        allAssetListGroupByUser: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    getAllAssetGroupByUserRejected(state, action) {
      return {
        ...state,
        allAssetListGroupByUser: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
    clearAllAssetGroupByUser(state, action) {
      return {
        ...state,
        allAssetListGroupByUser: {},
      };
    },

    createAssetPending(state, action) {
      return {
        ...state,
        addedAsset: { loading: true },
      };
    },
    createAssetFulfilled(state, action) {
      return {
        ...state,
        addedAsset: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    createAssetRejected(state, action) {
      return {
        ...state,
        addedAsset: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
    clearCreateAsset(state, action) {
      return {
        ...state,
        addedAsset: {},
      };
    },

    updateAssetPending(state, action) {
      return {
        ...state,
        updatedAsset: { loading: true },
      };
    },
    updateAssetFulfilled(state, action) {
      return {
        ...state,
        updatedAsset: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    updateAssetRejected(state, action) {
      return {
        ...state,
        updatedAsset: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
    clearUpdateAsset(state, action) {
      return {
        ...state,
        updatedAsset: {},
      };
    },

    getAssetByIdPending(state, action) {
      return {
        ...state,
        assetById: { loading: true },
      };
    },
    getAssetByIdFulfilled(state, action) {
      return {
        ...state,
        assetById: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    getAssetByIdRejected(state, action) {
      return {
        ...state,
        assetById: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
    clearAssetById(state, action) {
      return {
        ...state,
        assetById: {},
      };
    },
    getAssetByUserIdPending(state, action) {
      return {
        ...state,
        assetByUserId: { loading: true },
      };
    },
    getAssetByUserIdFulfilled(state, action) {
      return {
        ...state,
        assetByUserId: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    getAssetByUserIdRejected(state, action) {
      return {
        ...state,
        assetByUserId: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
    clearAssetByUserId(state, action) {
      return {
        ...state,
        assetByUserId: {},
      };
    },
  },
});

export const {
  getAllAssetPending,
  getAllAssetFulfilled,
  getAllAssetRejected,
  getAllAssetGroupByUserFulfilled,
  getAllAssetGroupByUserPending,
  getAllAssetGroupByUserRejected,
  clearAllAssetGroupByUser,
  createAssetPending,
  createAssetFulfilled,
  createAssetRejected,
  clearCreateAsset,
  updateAssetPending,
  updateAssetFulfilled,
  updateAssetRejected,
  clearUpdateAsset,
  getAssetByIdFulfilled,
  getAssetByIdPending,
  getAssetByIdRejected,
  clearAssetById,
  getAssetByUserIdPending,
  getAssetByUserIdFulfilled,
  getAssetByUserIdRejected,
  clearAssetByUserId,
} = allAssetSlice.actions;

export default allAssetSlice.reducer;
