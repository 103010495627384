import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  FormControl,
  Select,
  Box,
  OutlinedInput,
  TextField,
  MenuItem,
  TextareaAutosize,
  IconButton,
  Snackbar,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/styles';
import 'react-phone-input-labelled/dist/style.css';
import { makeStyles } from '@material-ui/core';
// import SketchPicker from "react-color";
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import useAuth from 'hooks/useAuth';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import { Chip } from '@material-ui/core';
// import {OutlinedInput} from '@material-ui/material';
import moment from 'moment';

import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Api from 'hooks/AjaxAction';

// ---- toggle switch ------

import { green } from '@material-ui/core/colors';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useQueriesAllTicketStyles } from 'pages/Queries/style';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateShiftTemplate,
  getAllShiftTags,
  getAllShiftTaskJobs,
  getAllShiftProjects,
  getAllShiftJobSites,
  createShiftTemplate,
} from '../../../store/actions/ShiftManagement/ShiftTemplates';
import {
  clearAllShiftTaskJobs,
  clearAllShiftProjects,
  clearAllShiftJobSites,
} from '../../../store/slices/ShiftManagement/ShiftTemplates';

const GreenSwitch = withStyles({
  switchBase: {
    color: green[300],
    '&$checked': {
      color: green[500],
    },
    '&$checked + $track': {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

//  form element styling --------------------------
const formStyles = makeStyles((theme) => ({
  sectionHeading: {
    color: '#1F299C',
    fontWeight: '700',
    margin: '10px',
    marginBottom: '20px',
  },

  fieldContainer: {
    maxWidth: '250px',
    display: 'inline-block',
    marginRight: '20px',
    // flexDirection: 'column'
  },

  subtitle: {
    marginLeft: '10px',
    color: 'black',
    fontWeight: '600',
  },
  colorBlue: {
    color: 'rgb(31,41,156)',
  },
  colorDarkBlue: {
    color: 'rgb(22,33,91)',
  },
}));

// --------- CssTextFeild ----------------
const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .Mui-disabled': {
      color: 'black',
    },

    '& .MuiInputBase': {
      background: '#F7F8FC',
      backgroundColor: '#F7F8FC',
    },
  },
})(TextField);

const breakHoursDropdown = [];
for (let start = 15; start <= 120; start += 15) {
  breakHoursDropdown.push(`${start} mins`);
}

// ---------  Input for Snackbar -----------------

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}
// -----------------------------------------------

function getStyles(name, selected, theme) {
  // debugger;
  return {
    fontWeight:
      selected.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function AddShiftTemplateDialog(props) {
  const auth = useAuth();
  const [isLoading, setIsLoading] = React.useState(false);
  const classes = useQueriesAllTicketStyles();
  const dispatch = useDispatch();
  const myClasses = formStyles();
  const [formError, setFormError] = useState(null);
  const [formSuccess, setFormSuccess] = useState(null);
  const [templateTitle, setTemplateTitle] = useState('');
  const [templateColor, setTemplateColor] = useState('');
  const [activeStatus, setActiveStatus] = useState(true);
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [breakType, setBreakType] = useState('');
  const [breakHours, setBreakHours] = useState('');
  const [jobSiteSelected, setJobSiteSelected] = useState('');
  const [tagsSelected, setTagsSelected] = React.useState([]);
  const [tasksSelected, setTasksSelected] = React.useState([]);
  const [projects, setProjects] = useState([]);
  const [selectProject, setProjectSelected] = useState('');
  const [notes, setNotes] = useState('');
  // -------- change attachment -----------
  // eslint-disable-next-line
  const [attachment, setAttachment] = useState('');
  const [fileName, setFileName] = useState('');
  const [fileUploadSuccess, setFileUploadedSuccessfull] = useState(false);
  const [allTags, setAllTags] = useState([]);
  const [allShiftTasks, setAllShiftTasks] = useState([]);
  const [allJobSites, setAllJobSites] = useState([]);
  // change this
  // const [allSchedules, setAllSchedules] = useState([]);
  const {
    // updateTemplate,
    tags,
    taskJobs,
    shiftProjects,
    // jobSites,
    postShiftTemplate,
  } = useSelector((state) => state.shiftTemplates);

  const handleToggleSwitch = (event) => {
    if (props.operation !== 'view') {
      setActiveStatus(!activeStatus);
    }
  };

  const onSelectFile = (files) => {
    setIsLoading(true);

    const onSuccess = (data) => {
      setAttachment(data.file_url);
      setFileName(data.filename);
      setIsLoading(false);
      setFileUploadedSuccessfull(true);
    };

    const onError = (error) => {
      console.log('Error: ', error);
      setIsLoading(false);
    };

    // Upload to S3
    Api.fileUpload(files, 'org-glen', auth.token, onSuccess, onError);
  };

  const handleCreateTemplate = async () => {
    setFormError(null);
    setFormSuccess(null);
    if (
      typeof activeStatus === null ||
      typeof activeStatus === undefined ||
      !templateTitle ||
      !startTime ||
      !endTime ||
      !breakType ||
      !breakHours ||
      !jobSiteSelected ||
      tasksSelected.length === 0
    ) {
      setFormError('Values required');
      return;
    } else {
      let duration = moment.duration(
        moment(endTime, 'HH:MM').diff(moment(startTime, 'HH:MM', 'hours'))
      );
      let durationInHr = duration.asHours().toFixed(2);

      if (parseInt(durationInHr) === 0) {
        setFormError("Start time and End time can't be the same");
        return;
      } else if (parseInt(durationInHr) < 0) {
        setFormError('Invalid time duration');
        return;
      }
    }

    let tag_ids_arr = tagsSelected.map((tag) => tag._id);
    let task_ids_arr = tasksSelected.map((task) => task._id);

    const data = {
      active: `${activeStatus}`,
      shift_template_title: templateTitle,
      start_time: moment(startTime).format('h:mm a'),
      end_time: moment(endTime).format('h:mm a'),
      breck: breakType,
      breck_time: breakHours,
      notes: notes,
      attachment: attachment,
      color_code: templateColor,
      tags: [...tag_ids_arr],
      schedule: jobSiteSelected,
      shift_task: [...task_ids_arr],
    };
    props.setIsLoading(true);
    dispatch(createShiftTemplate(data)).then(() => {
      setFormSuccess('Saved');
      props.setState();
      // handleCloseDialog();
    });
  };

  const handleUpdateTemplate = async () => {
    try {
      setFormError(null);
      setFormSuccess(null);
      if (
        typeof activeStatus === null ||
        typeof activeStatus === undefined ||
        !templateTitle ||
        !startTime ||
        !endTime ||
        !breakType ||
        !breakHours ||
        !jobSiteSelected ||
        tasksSelected.length === 0
      ) {
        setFormError('Values required');
        return;
      } else {
        let duration = moment.duration(
          moment(endTime, 'HH:MM').diff(moment(startTime, 'HH:MM', 'hours'))
        );
        let durationInHr = duration.asHours().toFixed(2);

        if (parseInt(durationInHr) === 0) {
          setFormError("Start time and End time can't be the same");
          return;
        } else if (parseInt(durationInHr) < 0) {
          setFormError('Invalid time duration');
          return;
        }
      }

      let tag_ids_arr = tagsSelected.map((tag) => tag._id);
      let task_ids_arr = tasksSelected.map((task) => task._id);
      let data = {
        _id: props.shiftTemplate._id,
        active: `${activeStatus}`,
        shift_template_title: templateTitle,
        breck: breakType,
        breck_time: breakHours,
        notes: notes,
        attachment: attachment,
        color_code: templateColor,
        tags: [...tag_ids_arr],
        schedule: jobSiteSelected,
        shift_task: [...task_ids_arr],
        start_time:moment(startTime).format('hh:mm a'),
        end_time: moment(endTime).format('hh:mm a')
      };
      dispatch(updateShiftTemplate(data)).then(() => {
        setFormSuccess('Updated');
        props.setState();
        // handleCloseDialog();
      });
    } catch (e) {
      if (e.response) {
        console.log('Error >', e.response);
      }
    }
  };

  const setSelectedProject = (projectID, data = projects) => {
    setProjectSelected(projectID);
    let prj = data.find((i) => {
      return i._id === projectID;
    });
    setAllJobSites(prj?.jobsites || []);
  };

  useEffect(() => {
    try {
      dispatch(getAllShiftTags());
      dispatch(getAllShiftTaskJobs());

      const params = {
        id: auth.user.org_id._id,
      };
      dispatch(getAllShiftProjects(params));
      if (props.operation === 'create' || props.operation === 'edit') {
        setIsLoading(true);
        dispatch(getAllShiftJobSites());
      }

      if (props.operation === 'view' || props.operation === 'edit') {
        if (props.shiftTemplate.active === 'true') {
          setActiveStatus(true);
        } else {
          setActiveStatus(false);
        }

        setTemplateColor(props.shiftTemplate.color_code);
        setTemplateTitle(props.shiftTemplate.shift_template_title);
        setStartTime(moment(props.shiftTemplate.start_time, 'hh:mm a'));
        setEndTime(moment(props.shiftTemplate.end_time, 'hh:mm a'));
        setBreakType(props.shiftTemplate.breck);
        setBreakHours(props.shiftTemplate.breck_time);
        if (props.shiftTemplate.schedule)
          setJobSiteSelected(props.shiftTemplate.schedule._id);
        setNotes(props.shiftTemplate.notes);
        setAttachment(props.shiftTemplate.attachment);
      } else {
        setActiveStatus(true);
        setTemplateColor('');
        setTemplateTitle('');
        setStartTime(new Date());
        setEndTime(new Date());
        setBreakType('');
        setBreakHours('');
        setJobSiteSelected('');
        setTagsSelected([]);
        setTasksSelected([]);
        setNotes('');
        setAttachment('');
      }
    } catch (e) {
      console.log(e);
    }

    return () => {
      dispatch(clearAllShiftTaskJobs());
      dispatch(clearAllShiftProjects());
      dispatch(clearAllShiftJobSites());
    };
  }, []);

  const handleCloseDialog = () => {
    setActiveStatus(false);
    setTemplateColor('');
    setTemplateTitle('');
    setStartTime(new Date());
    setEndTime(new Date());
    setBreakType('');
    setBreakHours('');
    setJobSiteSelected('');
    setTagsSelected([]);
    setTasksSelected([]);
    setNotes('');
    setAttachment('');
    setFileName('');
    setFileUploadedSuccessfull(false);
    props.setIsLoading(false);
    props.closeForm();
  };

  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  // const handleChangeTag = (event) => {
  //   // const {
  //   //   target: { value },
  //   // } = event;
  //   // setTagsSelected(value);
  //   // ======OR SIMPLY========
  // };

  // const handleChangeTask = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setTasksSelected(
  //     // On autofill we get a the stringified value.
  //     typeof value === 'string' ? value.split(',') : value,
  //   );
  // };

  useEffect(() => {
    if (tags.fulfilled) {
      const data = tags.fulfilled.data;
      setAllTags(data);
      if (props.operation === 'view' || props.operation === 'edit') {
        var tagsArr = [];
        props.shiftTemplate?.tags?.forEach((eachTag) => {
          data.forEach((dTag) => {
            if (eachTag === dTag._id) {
              tagsArr.push(dTag);
            }
          });
        });
        setTagsSelected([...tagsArr]);
      }
    }

    return () => {
      setAllTags([]);
      setTagsSelected([]);
    };
  }, [tags]);

  useEffect(() => {
    if (taskJobs.fulfilled) {
      const data = taskJobs.fulfilled;
      setAllShiftTasks(data);
      if (props.operation === 'view' || props.operation === 'edit') {
        var tagsArr = [];
        props.shiftTemplate?.shift_task?.forEach((eachTag) => {
          data.forEach((dTag) => {
            if (eachTag === dTag._id) {
              tagsArr.push(dTag);
            }
          });
        });
        setTasksSelected([...tagsArr]);
      }
    }
    return () => {
      setAllShiftTasks([]);
      setTasksSelected([]);
    };
  }, [taskJobs]);

  useEffect(() => {
    setIsLoading(true);
    if (shiftProjects.fulfilled) {
      const data = shiftProjects.fulfilled.data;
      setProjects(data);
      setIsLoading(false);
      if (
        (props.operation === 'view' || props.operation === 'edit') &&
        props.shiftTemplate.schedule
      ) {
        setSelectedProject(props.shiftTemplate.schedule.project_id, data);
      }
    }
    return () => {
      setSelectedProject([]);
      setProjects([]);
    };
  }, [shiftProjects.fulfilled]);
  try {
    return (
      <ThemeProvider theme={theme}>
        <div style={{ margin: '0 20px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: '10px',
            }}
          >
            <Typography
              variant='h5'
              // className = {classes.colorDarkBlue}
              style={{
                fontWeight: '600',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {props.operation === 'create'
                ? 'Add Shift Template'
                : props.operation === 'edit'
                ? 'Edit Shift Template'
                : 'Shift Template Details'}
            </Typography>

            <IconButton
              aria-label='close'
              onClick={handleCloseDialog}
              style={{
                margin: '0',
                padding: '0',
                color: 'black',
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>

          <hr></hr>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: '20px',
              padding: '5px 10px',
              backgroundColor: 'rgb(247,248,252)',
              borderRadius: '5px',
              boxShadow: 'lightgray 0px 0px 5px',
            }}
          >
            <Typography
              variant='h6'
              className={myClasses.colorDarkBlue}
              style={{
                fontWeight: '600',
              }}
            >
              Active
            </Typography>

            <FormControlLabel
              control={
                <GreenSwitch
                  onChange={()=>handleToggleSwitch()}
                  checked={activeStatus}
                />
              }
            />
          </div>

          <Typography
            variant='h6'
            className={myClasses.colorDarkBlue}
            style={{
              fontWeight: '600',
              margin: '10px 20px',
            }}
          >
            Shift Template Title<span style={{ color: 'red' }}>*</span>
          </Typography>

          <CssTextField
            variant='outlined'
            style={{
              backgroundColor: 'rgb(247,248,252)',
              boxShadow: 'lightgray 0px 0px 5px',
              margin: '0px 20px',
              width: '300px',
            }}
            value={templateTitle}
            disabled={props.readOnly}
            onChange={(e) => {
              setTemplateTitle(e.target.value);
              // setFnameError(false);
            }}
          />

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Typography
              variant='h6'
              className={myClasses.colorDarkBlue}
              style={{
                fontWeight: '600',
                margin: '10px 20px',
              }}
            >
              Start time<span style={{ color: 'red' }}>*</span>
            </Typography>
            <KeyboardTimePicker
              // ampm={false}
              variant='inline'
              // label="With keyboard"
              value={startTime}
              style={{
                margin: '0px 20px',
                width: '300px',
              }}
              onChange={(time) => setStartTime(time)}
            />
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Typography
              variant='h6'
              className={myClasses.colorDarkBlue}
              style={{
                fontWeight: '600',
                margin: '10px 20px',
              }}
            >
              End time<span style={{ color: 'red' }}>*</span>
            </Typography>
            <KeyboardTimePicker
              // ampm={false}
              variant='inline'
              // label="With keyboard"
              value={endTime}
              style={{
                margin: '0px 20px',
                width: '300px',
              }}
              onChange={(time) => setEndTime(time)}
            />
          </MuiPickersUtilsProvider>

          <Typography
            variant='h6'
            className={myClasses.colorDarkBlue}
            style={{
              fontWeight: '600',
              margin: '10px 20px',
            }}
          >
            Break<span style={{ color: 'red' }}>*</span>
          </Typography>

          <FormControl
            variant='outlined'
            style={{
              minWidth: '300px',
              margin: '0px 20px',
            }}
          >
            <Select
              style={{ backgroundColor: 'rgb(247,248,252)' }}
              onChange={(e) => setBreakType(e.target.value)}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              value={breakType}
            >
              <MenuItem key='0' value=''>
                <em>Select Break Type*</em>
              </MenuItem>
              <MenuItem key='1' value='Unpaid'>
                Unpaid
              </MenuItem>
              <MenuItem key='2' value='Paid'>
                Paid
              </MenuItem>
            </Select>
          </FormControl>

          <Typography
            variant='h6'
            className={myClasses.colorDarkBlue}
            style={{
              fontWeight: '600',
              margin: '10px 20px',
            }}
          >
            Break time<span style={{ color: 'red' }}>*</span>
          </Typography>

          <FormControl
            variant='outlined'
            style={{
              minWidth: '300px',
              margin: '0px 20px',
            }}
          >
            <Select
              style={{ backgroundColor: 'rgb(247,248,252)' }}
              onChange={(e) => setBreakHours(e.target.value)}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              value={breakHours}
            >
              <MenuItem value=''>
                <em>Select Time*</em>
              </MenuItem>
              {breakHoursDropdown.map((value, index) => (
                <MenuItem value={value} key={index + 'key'}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Typography
            variant='h6'
            className={myClasses.colorDarkBlue}
            style={{
              fontWeight: '600',
              margin: '10px 20px',
            }}
          >
            Project<span style={{ color: 'red' }}>*</span>
          </Typography>

          <FormControl
            variant='outlined'
            style={{
              minWidth: '300px',
              margin: '0px 20px',
            }}
          >
            <Select
              value={selectProject}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              style={{ backgroundColor: 'rgb(247,248,252)' }}
              onChange={(e) => {
                setSelectedProject(e.target.value);
              }}
            >
              <MenuItem value=''>
                <em>Select Project*</em>
              </MenuItem>
              {projects.map((schedule) => (
                <MenuItem
                  key={schedule._id + 'key'}
                  name='schedule.project_name'
                  value={schedule._id}
                >
                  {schedule.project_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Typography
            variant='h6'
            className={myClasses.colorDarkBlue}
            style={{
              fontWeight: '600',
              margin: '10px 20px',
            }}
          >
            JobSite<span style={{ color: 'red' }}>*</span>
          </Typography>

          <FormControl
            variant='outlined'
            style={{
              minWidth: '300px',
              margin: '0px 20px',
            }}
          >
            <Select
              value={jobSiteSelected}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              style={{ backgroundColor: 'rgb(247,248,252)' }}
              onChange={({ target: { value } }) => {
                setJobSiteSelected(value);
                let p = allJobSites.find((jb) => jb._id === value)?.project_id;
                if (p) setSelectedProject(p);
              }}
            >
              <MenuItem value=''>
                <em>Select Job Site*</em>
              </MenuItem>
              {allJobSites.map((schedule) => (
                <MenuItem key={schedule.key} value={schedule._id}>
                  {schedule.jobSite_title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Typography
            variant='h6'
            className={myClasses.colorDarkBlue}
            style={{
              fontWeight: '600',
              margin: '10px 20px',
            }}
          >
            Tags
          </Typography>

          <div>
            <FormControl
              style={{
                minWidth: '300px',
                margin: '0px 20px',
              }}
            >
              <Select
                multiple
                style={{ backgroundColor: 'rgb(247,248,252)' }}
                value={tagsSelected}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                onChange={(e) => setTagsSelected(e.target.value)}
                input={<OutlinedInput />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected && selected.length ? (
                      selected.map((tag) => (
                        <Chip key={tag.key} label={tag.tag_title} />
                      ))
                    ) : (
                      <></>
                    )}
                  </Box>
                )}
                // MenuProps={MenuProps}
              >
                <MenuItem value=''>
                  <em>Select Tags</em>
                </MenuItem>
                {allTags.map((tag) => (
                  <MenuItem
                    key={tag.key}
                    value={tag}
                    style={getStyles(tag, tagsSelected, theme)}
                  >
                    {tag.tag_title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <Typography
            variant='h6'
            className={myClasses.colorDarkBlue}
            style={{
              fontWeight: '600',
              margin: '10px 20px',
            }}
          >
            Shift Task<span style={{ color: 'red' }}>*</span>
          </Typography>

          <div>
            <FormControl
              style={{
                minWidth: '300px',
                margin: '0px 20px',
              }}
            >
              <Select
                multiple
                style={{ backgroundColor: 'rgb(247,248,252)' }}
                value={tasksSelected}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                onChange={(e) => setTasksSelected(e.target.value)}
                input={<OutlinedInput />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected && selected.length ? (
                      selected.map((task) => (
                        <Chip key={task.key} label={task.jobTask_title} />
                      ))
                    ) : (
                      <></>
                    )}
                  </Box>
                )}
                // MenuProps={MenuProps}
              >
                {allShiftTasks.map((task) => (
                  <MenuItem
                    key={task.key}
                    value={task}
                    style={getStyles(task, tasksSelected, theme)}
                  >
                    {task.jobTask_title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          {/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "20px",
            padding: "5px 10px",
            backgroundColor: "rgb(247,248,252)",
            borderRadius: "5px",
            boxShadow: "lightgray 0px 0px 5px",
          }}
        >
          <Typography
            variant='h6'
            className={myClasses.colorDarkBlue}
            style={{
              fontWeight: "600",
            }}
          >
            Require Approval*
          </Typography>

          <FormControlLabel
            control={
              <GreenSwitch
                onChange={handleApprovalSwitch}
                checked={requireApproval}
              />
            }
          />
        </div> */}

          <Typography
            variant='h6'
            className={myClasses.colorDarkBlue}
            style={{
              fontWeight: '600',
              margin: '10px 20px',
            }}
          >
            Notes
          </Typography>

          <TextareaAutosize
            name='leaving_comment'
            onChange={(e) => {
              setNotes(e.target.value);
            }}
            style={{
              width: '90%',
              height: '110px',
              backgroundColor: 'rgb(247,248,252)',
              margin: '0px 20px',
              padding: '10px',
            }}
            // value={resign.leaving_comment}
            aria-label='minimum height'
            minRows={10}
            placeholder='Enter Your Remark'
            value={notes}
          />

          <Typography
            variant='h6'
            className={myClasses.colorDarkBlue}
            style={{
              fontWeight: '600',
              margin: '10px 20px',
            }}
          >
            Attachment
          </Typography>

          <label
            style={{
              width: '90%',
              height: '110px',
              backgroundColor: '#f5f5f5',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '20px',
              margin: '0px 20px 20px 20px',
            }}
          >
            {fileName !== '' ? (
              fileName
            ) : (
              <p
                style={{
                  fontSize: '20px',
                }}
              >
                Drag & Drop Here,{' '}
                <span style={{ color: '#1250ff', marginRight: '10px' }}>
                  Or Browse File
                </span>
                <CloudUploadOutlinedIcon />
              </p>
            )}

            <input
              type='file'
              style={{ display: 'none' }}
              onChange={(e) => onSelectFile(e.target.files)}
            />
          </label>
          {fileUploadSuccess && (
            <p
              style={{
                color: '#00b500',
                fontSize: '16px',
                marginLeft: '20px',
              }}
            >
              Upload successful
            </p>
          )}
        </div>

        {props.operation !== 'view' && (
          <div>
            <hr></hr>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '0px 40px 10px 0px',
              }}
            >
              <Button
                variant='outlined'
                color='primary'
                style={{
                  marginRight: '10px',
                }}
                onClick={handleCloseDialog}
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                color='primary'
                onClick={
                  props.operation === 'create'
                    ? handleCreateTemplate
                    : handleUpdateTemplate
                }
              >
                Save
              </Button>
            </div>
          </div>
        )}

        {/* ---------------------------------------------- */}
        <Snackbar
          open={!!formError}
          onClose={() => setFormError(false)}
          // TransitionComponent={<Slide direction="up" />}
          key={'Form Error'}
          autoHideDuration={3000}
        >
          <Alert severity='error'>{formError}</Alert>
        </Snackbar>
        <Snackbar
          open={!!formSuccess}
          onClose={() => {
            setFormSuccess(false);
            handleCloseDialog();
          }}
          // TransitionComponent={<Slide direction="up" />}
          key={'Form Success'}
          autoHideDuration={700}
        >
          <Alert severity='success'>{formSuccess}</Alert>
        </Snackbar>
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color='inherit' />
        </Backdrop>
      </ThemeProvider>
    );
  } catch (e) {
    console.log(e);
    return <></>;
  }
}

export default AddShiftTemplateDialog;
