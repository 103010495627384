import axios from "axios";
import { Urls } from "store/urls";

export const getAssignAssetRepo = (params) => {
  return axios({
    method: "GET",
    // url: Urls.GET_ALL_ASSET,
    params: params,
  });
};

export const postAssignAssetRepo = (data) => {
  return axios({
    method: "POST",
    url: Urls.ASSIGN_ASSET,
    data,
  });
};

export const postAddAssetRemarkRepo = (data) => {
  return axios({
    method: "POST",
    url: Urls.ADD_ASSET_REMARK,
    data,
  });
};

export const handoverAssetRepo = (data) => {
  return axios({
    method: "POST",
    url: Urls.HANDOVER_ASSET,
    data,
  });
};
