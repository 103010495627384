import axios from 'axios';
import { BASE_URL, Urls } from 'store/urls';
// import { getUsersByOrgIdRepo } from '../HrManagement/Dashboard';
import {
  getAllShiftJobSitesRepo,
  getAllShiftProjectsRepo,
} from './ShiftTemplates';

export const getProjectRepo = async (params) => {
  if (!params.limit) params.limit = 25;
  if (!params.page) params.page = 1;
  return await getAllShiftProjectsRepo(params);
};

export const getJobSiteRepo = async () => {
  return await getAllShiftJobSitesRepo();
};

export const updateJobsiteAssignEmpRepo = (jobsiteData) => {
  return axios({
    baseURL: BASE_URL,
    method: 'put',
    url: Urls.UPDATE_SHIFT_JOBSITE_ASSIGN_EMPLOYEE,
    data: jobsiteData,
  });
};

export const createProjectRepo = (projData) => {
  return axios({
    baseURL: BASE_URL,
    method: 'post',
    url: Urls.CREATE_SHIFT_PROJECT,
    data: projData,
  });
};

export const updateProjectRepo = (projData) => {
  return axios({
    baseURL: BASE_URL,
    method: 'put',
    url: Urls.UPDATE_SHIFT_PROJECT,
    data: projData,
  });
};

export const createJobsiteRepo = (jobsiteData) => {
  return axios({
    baseURL: BASE_URL,
    method: 'post',
    url: Urls.CREATE_SHIFT_JOBSITE,
    data: jobsiteData,
  });
};

export const updateJobsiteRepo = (jobsiteData) => {
  return axios({
    baseURL: BASE_URL,
    method: 'put',
    url: Urls.UPDATE_SHIFT_JOBSITE,
    data: jobsiteData,
  });
};
export const deleteProjectRepo = (id) => {
  return axios({
    baseURL: BASE_URL,
    method: 'delete',
    url: Urls.DELETE_SHIFT_PROJECT + id,
  });
};

export const deleteJobsiteRepo = (id) => {
  return axios({
    baseURL: BASE_URL,
    method: 'delete',
    url: Urls.DELETE_SHIFT_JOBSITE + id,
  });
};

export const searchProjectRepo = (obj) => {
  const data = {
    limit: obj?.limit || 25,
    pageNumber: obj?.page || 1,
    status: obj?.status || '',
    searchKey: obj?.searchKey || '',
  };
  return axios({
    baseURL: BASE_URL,
    method: 'POST',
    url: Urls.SEARCH_SHIFTPROJECT,
    data,
  });
};
