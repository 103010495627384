import React from "react";
import { Typography, Grid, Paper } from "@material-ui/core";
import useAuth from "hooks/useAuth";
import myStyles from "../ProfileStyles";

function EducationInfoPanel(props) {
  const classes = myStyles();
  const auth = useAuth();
  try {
    let degree = [];
    let certification = [];

    if (!auth.user) return;

    const { user } = auth;
    if (user.educational_details) {
      if (user.educational_details.degree_details)
        if (user.educational_details.degree_details[0]?.degree_name)
          degree = user.educational_details.degree_details;

      if (user.educational_details.certificates_and_licenses)
        if (
          user.educational_details.certificates_and_licenses[0]?.certificte_name
        )
          certification = user.educational_details.certificates_and_licenses;
    }

    const Certification = (props) => {
      return (
        <Grid item xs={12} sm={6} lg={4} xl={4} spacing={1}>
          <Paper component='div' className={classes.papersub}>
            <Grid container direction='row' justify='space-evenly'>
              <Grid
                item
                xs={5}
                sm={5}
                md={5}
                lg={5}
                style={{ textAlign: "left" }}
              >
                <Typography variant='subtitle1' className={classes.info}>
                  &nbsp; Name :
                </Typography>
                <Typography variant='subtitle1' className={classes.info}>
                  &nbsp; Issued By :
                </Typography>
                <Typography variant='subtitle1' className={classes.info}>
                  &nbsp; Issued Date :
                </Typography>
                <Typography variant='subtitle1' className={classes.info}>
                  &nbsp; Expiration Date :
                </Typography>
              </Grid>

              <Grid
                item
                xs={7}
                style={{
                  textAlign: "left",
                  fontSize: ".8rem",
                }}
              >
                {" "}
                <Typography
                  variant='body1'
                  display='block'
                  className={classes.infoValues}
                  gutterBottom
                >
                  {" "}
                  {props.certificte_name}{" "}
                </Typography>
                <Typography
                  variant='body1'
                  display='block'
                  className={classes.infoValues}
                  gutterBottom
                >
                  {" "}
                  {props.issusing_orgnization}{" "}
                </Typography>
                <Typography
                  variant='body1'
                  display='block'
                  className={classes.infoValues}
                  gutterBottom
                >
                  {" "}
                  {props.issuing_date}{" "}
                </Typography>
                <Typography
                  variant='body1'
                  display='block'
                  className={classes.infoValues}
                  gutterBottom
                >
                  {" "}
                  {props.does_expire ? "Nill" : props.expiration_date}{" "}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      );
    };
    const Degree = (props) => {
      return (
        <Grid item xs={12} sm={6} lg={4} xl={4} spacing={1}>
          <Paper component='div' className={classes.papersub}>
            <Grid container direction='row' justify='space-evenly'>
              <Grid item xs={4} style={{ textAlign: "left" }}>
                <Typography variant='subtitle1' className={classes.info}>
                  &nbsp; Degree :
                </Typography>
                <Typography variant='subtitle1' className={classes.info}>
                  &nbsp; Start Year :
                </Typography>
                <Typography variant='subtitle1' className={classes.info}>
                  &nbsp; End Year :
                </Typography>
                <Typography variant='subtitle1' className={classes.info}>
                  &nbsp; Remarks :
                </Typography>
              </Grid>

              <Grid
                item
                xs={8}
                style={{
                  textAlign: "left",
                  fontSize: ".8rem",
                }}
              >
                {" "}
                <Typography
                  variant='body1'
                  display='block'
                  className={classes.infoValues}
                  gutterBottom
                >
                  {" "}
                  {props.degree_name}
                </Typography>
                <Typography
                  variant='body1'
                  display='block'
                  className={classes.infoValues}
                  gutterBottom
                >
                  {" "}
                  {props.start_year}
                </Typography>
                <Typography
                  variant='body1'
                  display='block'
                  className={classes.infoValues}
                  gutterBottom
                >
                  {" "}
                  {props.end_year}
                </Typography>
                <Typography
                  variant='body1'
                  display='block'
                  className={classes.infoValues}
                  gutterBottom
                >
                  {" "}
                  {props.remarks}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      );
    };
    return (
      <Grid container direction='row' spacing={4}>
        {/* Graduation------------  */}
        <Grid item xs={12} sm={12} lg={12}>
          <Paper component='div' className={classes.paperMain}>
            <Grid container direction='column' justify='space-between'>
              <Grid item xs={12} className={classes.labelsub}>
                <Typography variant='h5' color='primary'>
                  Degrees
                </Typography>
              </Grid>
              <Grid container direction='row' spacing={3}>
                {degree.length ? (
                  degree.map((d) => {
                    return Degree({ ...d, readOnly: props.readOnly });
                  })
                ) : (
                  <>
                    <p
                      style={{
                        color: "grey",
                        margin: "auto",
                        padding: "50px",
                      }}
                    >
                      No degrees added
                    </p>
                  </>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {/* Post Graduation----------------- */}
        <Grid item xs={12} sm={12} lg={12}>
          <Paper component='div' className={classes.paperMain}>
            <Grid container direction='column' justify='space-between'>
              <Grid item xs={12} className={classes.labelsub}>
                <Typography variant='h5' color='primary'>
                  Liscence/Certifications
                </Typography>
              </Grid>
              <Grid container direction='row' spacing={3}>
                {certification.length ? (
                  certification.map((d) => {
                    return Certification({
                      ...d,
                      readOnly: props.readOnly,
                    });
                  })
                ) : (
                  <>
                    <p
                      style={{
                        color: "grey",
                        margin: "auto",
                        padding: "50px",
                      }}
                    >
                      No certification/liscence added
                    </p>
                  </>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  } catch (e) {
    console.log(e);
  }
}

export default EducationInfoPanel;
