import React from "react";

const SoftwareDeveloperDetails = () => {
  return (
    <div>
      <div className="border-2 px-4 py-3 bg-white flex items-center justify-between gap-2 flex-wrap">
        <p className="font-bold pl-10">Laptop</p>
        <div className="flex items-center justify-between gap-5">
          <label className="flex items-center cursor-pointer relative">
            <input type="checkbox" className="sr-only" />
            <div className="toggle-bg bg-gray-200 border-2 border-gray-200 h-6 w-11 rounded-full"></div>
          </label>
          <p className="text-green-600 font-bold">Active</p>
          <i className="fa-solid fa-trash border border-gray-200 p-2 rounded-md cursor-pointer"></i>
        </div>
      </div>
      <div className="border-2 px-4 py-3 bg-white flex items-center justify-between gap-2 flex-wrap">
        <p className="font-bold pl-10">Laptop</p>
        <div className="flex items-center justify-between gap-5">
          <label className="flex items-center cursor-pointer relative">
            <input type="checkbox" className="sr-only" />
            <div className="toggle-bg bg-gray-200 border-2 border-gray-200 h-6 w-11 rounded-full"></div>
          </label>
          <p className="text-green-600 font-bold">Active</p>
          <i className="fa-solid fa-trash border border-gray-200 p-2 rounded-md cursor-pointer"></i>
        </div>
      </div>
      <div className="border-2 px-4 py-3 bg-white flex items-center justify-between gap-2 flex-wrap">
        <p className="font-bold pl-10">Laptop</p>
        <div className="flex items-center justify-between gap-5">
          <label className="flex items-center cursor-pointer relative">
            <input type="checkbox" className="sr-only" />
            <div className="toggle-bg bg-gray-200 border-2 border-gray-200 h-6 w-11 rounded-full"></div>
          </label>
          <p className="text-green-600 font-bold">Active</p>
          <i className="fa-solid fa-trash border border-gray-200 p-2 rounded-md cursor-pointer"></i>
        </div>
      </div>
    </div>
  );
};

export default SoftwareDeveloperDetails;
