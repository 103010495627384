import { createContext, useState } from "react";

export const TimesheetContext = createContext(null);

const TimesheetProvider = ({ children }) => {
  const [employees, setEmployees] = useState([]);
  return (
    <TimesheetContext.Provider value={{ employees, setEmployees }}>
      {children}
    </TimesheetContext.Provider>
  );
};

export default TimesheetProvider;
