import {
  getBirthdaysNholidaysRepo,
  getMessagesRepo,
  getAttendanceByEmpIdRepo,
  getShiftByUserIdRepo,
  postAttendaceBreakoutRepo,
  postAttendaceBreakinRepo,
  postRaiseQueryRepo,
  postApplyLeaveRepo,
  postAddMissedPunchRepo,
  postAttendancePunchInRepo,
  postAttendancePunchOutRepo,
  putShiftUpdateWorksheetRepo,
} from "../../repositories/EmployeeManagement/HomePage";
import {
  getMessagesPending,
  getMessagesFullfilled,
  getMessagesRejected,
  getBdaysPending,
  getBdaysFullfilled,
  getBdaysRejected,
  getAttendanceByEmpIdPending,
  getAttendanceByEmpIdFullfilled,
  getAttendanceByEmpIdRejected,
  getShiftByUserIdPending,
  getShiftByUserIdFullfilled,
  getShiftByUserIdRejected,
  postAttendaceBreakoutPending,
  postAttendaceBreakoutFullfilled,
  postAttendaceBreakoutRejected,
  postAttendaceBreakinPending,
  postAttendaceBreakinFullfilled,
  postAttendaceBreakinRejected,
  postRaiseQueryPending,
  postRaiseQueryFullfilled,
  postRaiseQueryRejected,
  postApplyLeavePending,
  postApplyLeaveFullfilled,
  postApplyLeaveRejected,
  postAddMissedPunchPending,
  postAddMissedPunchFullfilled,
  postAddMissedPunchRejected,
  postAttendancePunchInPending,
  postAttendancePunchInFullfilled,
  postAttendancePunchInRejected,
  postAttendancePunchOutPending,
  postAttendancePunchOutFullfilled,
  postAttendancePunchOutRejected,
  putShiftUpdateWorksheetPending,
  putShiftUpdateWorksheetFullfilled,
  putShiftUpdateWorksheetRejected,
} from "../../slices/EmployeeManagement/HomePage";

export const getBirthdaysNholidays = (id) => {
  return async (dispatch) => {
    dispatch(getBdaysPending());
    await getBirthdaysNholidaysRepo(id)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getBdaysFullfilled(response?.data?.data));
        } else {
          dispatch(getBdaysRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(getBdaysRejected(error.message));
        }
      });
  };
};

export const getAllMessages = () => {
  return async (dispatch) => {
    dispatch(getMessagesPending());
    await getMessagesRepo()
      .then((response) => {
        if (response.status === 200) {
          dispatch(getMessagesFullfilled(response?.data?.data));
        } else {
          dispatch(getMessagesRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(getMessagesRejected(error.message));
        }
      });
  };
};

export const getAttendanceByEmpId = (id, params) => {
  return async (dispatch) => {
    dispatch(getAttendanceByEmpIdPending());
    await getAttendanceByEmpIdRepo(id, params)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getAttendanceByEmpIdFullfilled(response?.data?.data));
        } else {
          dispatch(getAttendanceByEmpIdRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(getAttendanceByEmpIdRejected(error.message));
        }
      });
  };
};

export const getShiftByUserId = (id, params) => {
  return async (dispatch) => {
    dispatch(getShiftByUserIdPending());
    await getShiftByUserIdRepo(id, params)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getShiftByUserIdFullfilled(response?.data?.data));
        } else {
          dispatch(getShiftByUserIdRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(getShiftByUserIdRejected(error.message));
        }
      });
  };
};

export const postAttendaceBreakout = (data) => {
  return async (dispatch) => {
    dispatch(postAttendaceBreakoutPending());
    await postAttendaceBreakoutRepo(data)
      .then((response) => {
        if (response.status === 200) {
          dispatch(postAttendaceBreakoutFullfilled(response?.data?.data));
        } else {
          dispatch(postAttendaceBreakoutRejected(response?.data?.error));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(postAttendaceBreakoutRejected(error.message));
        }
      });
  };
};

export const postAttendaceBreakin = (data) => {
  return async (dispatch) => {
    dispatch(postAttendaceBreakinPending());
    await postAttendaceBreakinRepo(data)
      .then((response) => {
        if (response.status === 200) {
          dispatch(postAttendaceBreakinFullfilled(response?.data?.data));
        } else {
          dispatch(postAttendaceBreakinRejected(response?.data?.error));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(postAttendaceBreakinRejected(error.message));
        }
      });
  };
};

export const postRaiseQuery = (data) => {
  return async (dispatch) => {
    dispatch(postRaiseQueryPending());
    await postRaiseQueryRepo(data)
      .then((response) => {
        if (response.data.status === true) {
          dispatch(postRaiseQueryFullfilled(response?.data?.data));
        } else {
          dispatch(postRaiseQueryRejected(response?.data?.error));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(postRaiseQueryRejected(error.message));
        }
      });
  };
};
export const postApplyLeave = (data) => {
  return async (dispatch) => {
    dispatch(postApplyLeavePending());
    await postApplyLeaveRepo(data)
      .then((response) => {
        if (response.data.status === true) {
          dispatch(postApplyLeaveFullfilled(response?.data?.data));
        } else {
          dispatch(postApplyLeaveRejected(response?.data?.error));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(postApplyLeaveRejected(error.message));
        }
      });
  };
};

export const postAddMissedPunch = (data) => {
  return async (dispatch) => {
    dispatch(postAddMissedPunchPending());
    await postAddMissedPunchRepo(data)
      .then((response) => {
        if (response.data.status === true) {
          dispatch(postAddMissedPunchFullfilled(response?.data?.data));
        } else {
          dispatch(postAddMissedPunchRejected(response?.data?.error));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(postAddMissedPunchRejected(error.message));
        }
      });
  };
};

export const postAttendancePunchIn = (data) => {
  return async (dispatch) => {
    dispatch(postAttendancePunchInPending());
    await postAttendancePunchInRepo(data)
      .then((response) => {
        if (response.data.status === true) {
          dispatch(postAttendancePunchInFullfilled(response?.data?.data));
        } else {
          dispatch(postAttendancePunchInRejected(response?.data?.error));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(postAttendancePunchInRejected(error.message));
        }
      });
  };
};

export const postAttendancePunchOut = (data) => {
  return async (dispatch) => {
    dispatch(postAttendancePunchOutPending());
    await postAttendancePunchOutRepo(data)
      .then((response) => {
        if (response.data.status === true) {
          dispatch(postAttendancePunchOutFullfilled(response?.data?.data));
        } else {
          dispatch(postAttendancePunchOutRejected(response?.data?.error));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(postAttendancePunchOutRejected(error.message));
        }
      });
  };
};

export const putShiftUpdateWorksheet = (data) => {
  return async (dispatch) => {
    dispatch(putShiftUpdateWorksheetPending());
    await putShiftUpdateWorksheetRepo(data)
      .then((response) => {
        if (response.data.status === true) {
          dispatch(putShiftUpdateWorksheetFullfilled(response?.data?.data));
        } else {
          dispatch(putShiftUpdateWorksheetRejected(response?.data?.error));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(putShiftUpdateWorksheetRejected(error.message));
        }
      });
  };
};
