import React from 'react';
import '@fontsource/roboto';
import { Backdrop, CircularProgress, Typography } from '@material-ui/core';
// import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
// import DateFnsUtils from '@date-io/date-fns';
import useAuth from 'hooks/useAuth';
import clsx from 'clsx';
// import axios from 'axios';
// import Api from "../../../hooks/AjaxAction";
// import { useHistory } from "react-router-dom";
import { useQueriesAllTicketStyles } from './style';
import { AllTickets } from './AllTickets';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getAllRequests } from '../../store/actions/EmployeeManagement/QueriesAndTickets';
import { getUsersByOrgId } from '../../store/actions/HrManagement/Dashboard';
// import { set } from 'lodash';
export const QueriesAllTicketContext = React.createContext(null);

export default function QueriesAllTicket(props) {
  // const history = useHistory();

  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  const All_TICKETS = 'All_TICKETS';
  const INPROGRESS_TICKETS = 'INPROGRESS_TICKETS';
  const CANCELLED_TICKETS = 'CANCELLED_TICKETS';
  const PENDING_TICKETS = 'PENDING_TICKETS';
  const CLOSED_TICKETS = 'CLOSED_TICKETS';
  const REJECTED_TICKETS = 'REJECTED_TICKETS';
  const ACCEPTED_TICKETS = 'ACCEPTED_TICKETS';
  const MY_QUERIES = 'MY_QUERIES';

  const classes = useQueriesAllTicketStyles();
  const auth = useAuth();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const [pageType, setPageType] = React.useState(All_TICKETS);
  const [allTickets, setAllTickets] = React.useState([]);
  const [allEmployees, setAllEmployees] = React.useState([]);
  const [fetchAllTickets, setFetchAllTickets] = React.useState(true);
  const [inprogressTickets, setInprogressTickets] = React.useState([]);
  const [closedTickets, setClosedTickets] = React.useState([]);
  const [pendingTickets, setPendingTickets] = React.useState([]);
  const [cancelledTickets, setCancelledTickets] = React.useState([]);
  const [viewDetails, setViewDetails] = React.useState(false);
  const [rejectedTickets, setRejectedTickets] = React.useState([]);
  const [acceptedTickets, setAcceptedTickets] = React.useState([]);
  const [myQueries, setMyQueries] = React.useState([]);

  const { queries } = useSelector((state) => state.queriesAndTickets);
  const { orgUsers } = useSelector((state) => state.dashboard);

  const getAllTickets = async () => {
    setIsLoading(true);
    let token = localStorage.getItem('token');
    const headers = {
      token: token,
    };
    dispatch(getAllRequests(headers));
    // setIsLoading(false);
    setFetchAllTickets(false);
  };

  const getAllEmployees = async () => {
    const orgId = auth.user.org_id._id;
    const params = {
      page_number: '',
      limit: '',
    };
    dispatch(getUsersByOrgId(orgId, params));
    // .then((myData) => {
    //     const response = myData.payload;
    //     // setAllEmployees(response.data);
    //     console.log("response by org=====================", response.data);
    //     const data = response.data;
    //     setAllEmployees(data);
    // })
    // .catch((error) => {
    //     console.log(error);
    // })
  };

  function pad(num) {
    num = num.toString();
    if (num < 10 && num > 0) {
      num = '0' + num;
    }
    return num;
  }

  React.useEffect(() => {
    // API call goes here
    window.scrollTo(0, 0);
    getAllEmployees();
    getAllTickets();
    if (fetchAllTickets) getAllTickets();
  }, [fetchAllTickets]); //eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (orgUsers?.fulfilled) {
      setAllEmployees(orgUsers?.fulfilled);
    }

    if (queries?.fulfilled) {
      const allData = queries?.fulfilled;
      try {
        const myQuery = allData.filter((ticket) => {
          return ticket.user_id._id === auth.user._id;
        });
        setMyQueries(myQuery);

        const data = allData.filter(
          (ticket) =>
            ticket.assigned_to?._id === auth.user._id ||
            ticket.user_id._id === auth.user._id
        );
        setAllTickets(data);

        const inprogress = data.filter(
          (ticket) => ticket.status === 'In Progress'
        );
        setInprogressTickets(inprogress);

        const accepted = data.filter((ticket) => ticket.status === 'Accepted');
        setAcceptedTickets(accepted);

        const closed = data.filter((ticket) => ticket.status === 'Closed');
        setClosedTickets(closed);

        const pending = data.filter(
          (ticket) => ticket.status === 'Pending' || !ticket.status
        );

        pending.forEach((ticket) => {
          ticket = {
            ...ticket,
            status: 'Pending',
          };
        });
        setPendingTickets(pending);

        const cancelled = data.filter(
          (ticket) => ticket.status === 'Cancelled'
        );
        setCancelledTickets(cancelled);

        const rejected = data.filter((ticket) => ticket.status === 'Rejected');
        setRejectedTickets(rejected);
      } catch (error) {}
      setIsLoading(false);
    }
  }, [queries]);

  const getTabContent = (type) => {
    switch (type) {
      case All_TICKETS:
        if (allTickets.length) {
          return (
            <AllTickets
              key={1}
              all_tickets={allTickets}
              setTicketNumber={setTicketNumber}
            />
          );
        } else {
          return <></>;
        }

      case INPROGRESS_TICKETS:
        if (allTickets.length) {
          return (
            <AllTickets
              key={3}
              all_tickets={inprogressTickets}
              setTicketNumber={setTicketNumber}
              tab={INPROGRESS_TICKETS}
            />
          );
        } else {
          return <></>;
        }

      case CLOSED_TICKETS:
        if (allTickets.length) {
          return (
            <AllTickets
              key={4}
              all_tickets={closedTickets}
              setTicketNumber={setTicketNumber}
              tab={CLOSED_TICKETS}
            />
          );
        } else {
          return <></>;
        }

      case PENDING_TICKETS:
        if (allTickets.length) {
          return (
            <AllTickets
              key={5}
              all_tickets={pendingTickets}
              setTicketNumber={setTicketNumber}
              tab={PENDING_TICKETS}
            />
          );
        } else {
          return <></>;
        }

      case CANCELLED_TICKETS:
        if (allTickets.length) {
          return (
            <AllTickets
              key={6}
              all_tickets={cancelledTickets}
              setTicketNumber={setTicketNumber}
              tab={CANCELLED_TICKETS}
            />
          );
        } else {
          return <></>;
        }

      case ACCEPTED_TICKETS:
        if (allTickets.length) {
          return (
            <AllTickets
              key={7}
              all_tickets={acceptedTickets}
              setTicketNumber={setTicketNumber}
              tab={ACCEPTED_TICKETS}
            />
          );
        } else {
          return <></>;
        }

      case REJECTED_TICKETS:
        if (allTickets.length) {
          return (
            <AllTickets
              key={8}
              all_tickets={rejectedTickets}
              setTicketNumber={setTicketNumber}
              tab={REJECTED_TICKETS}
            />
          );
        } else {
          return <></>;
        }

      case MY_QUERIES:
        if (myQueries.length) {
          return (
            <AllTickets
              key={9}
              all_tickets={myQueries}
              setTicketNumber={setTicketNumber}
              tab={MY_QUERIES}
            />
          );
        } else {
          return <></>;
        }

      default:
        return <div>Nothing to show</div>;
    }
  };

  function funViewDetails(value) {
    setViewDetails(value);
  }

  const buttonClicked = (type) => {
    funViewDetails(false);
    setPageType(type);
  };

  const setTicketNumber = (ticket) => {
    getAllTickets();
    buttonClicked(ticket);
  };

  return (
    <React.Fragment>
      <QueriesAllTicketContext.Provider
        value={{
          allEmployees,
          setFetchAllTickets,
          funViewDetails,
          getAllTickets,
          viewDetails,
        }}
      >
        <div className={classes.root}>
          <ThemeProvider theme={theme}>
            <main
              className={clsx(classes.content, {
                [classes.contentShift]: props.isDrawerOpen,
              })}
              // style={{backgroundColor:"#F7F8FC"}}
            >
              <Typography
                style={{
                  textAlign: 'left',
                  minWidth: '230px',
                }}
              >
                Home {'>'} Employee Management {'>'} Queries
              </Typography>

              <div
                style={{
                  display: 'flex',
                  margin: '10px',
                  marginLeft: '0px',
                  flexWrap: 'wrap',
                }}
              >
                <div
                  onClick={() => buttonClicked(All_TICKETS)}
                  style={{
                    backgroundColor:
                      pageType === All_TICKETS ? '#1F299C' : '#FFFFFF',
                    padding: '15px',
                    cursor: 'pointer',
                    borderRadius: '10px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: '#1F299C',
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '5px 10px 5px 0px',
                  }}
                >
                  <Typography
                    variant='subtitle1'
                    style={{
                      color: pageType === All_TICKETS ? '#FFFFFF' : '#1F299C',
                    }}
                  >
                    All Tickets &nbsp; <b>{pad(allTickets.length)}</b>
                  </Typography>
                </div>

                <div
                  onClick={() => buttonClicked(PENDING_TICKETS)}
                  style={{
                    backgroundColor:
                      pageType === PENDING_TICKETS ? '#1F299C' : '#FFFFFF',
                    padding: '15px',
                    cursor: 'pointer',
                    borderRadius: '10px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: '#1F299C',
                    display: 'flex',
                    margin: '5px 10px 5px 0px',
                    flexDirection: 'column',
                  }}
                >
                  <Typography
                    variant='subtitle1'
                    style={{
                      color:
                        pageType === PENDING_TICKETS ? '#FFFFFF' : '#1F299C',
                    }}
                  >
                    Pending Tickets &nbsp; <b>{pad(pendingTickets.length)}</b>
                  </Typography>
                </div>

                <div
                  onClick={() => buttonClicked(INPROGRESS_TICKETS)}
                  style={{
                    backgroundColor:
                      pageType === INPROGRESS_TICKETS ? '#1F299C' : '#FFFFFF',
                    padding: '15px',
                    cursor: 'pointer',
                    borderRadius: '10px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: '#1F299C',
                    display: 'flex',
                    margin: '5px 10px 5px 0px',
                    flexDirection: 'column',
                  }}
                >
                  <Typography
                    variant='subtitle1'
                    style={{
                      color:
                        pageType === INPROGRESS_TICKETS ? '#FFFFFF' : '#1F299C',
                    }}
                  >
                    In-Progress Tickets &nbsp;{' '}
                    <b>{pad(inprogressTickets.length)}</b>
                  </Typography>
                </div>

                <div
                  onClick={() => buttonClicked(ACCEPTED_TICKETS)}
                  style={{
                    backgroundColor:
                      pageType === ACCEPTED_TICKETS ? '#1F299C' : '#FFFFFF',
                    padding: '15px',
                    cursor: 'pointer',
                    borderRadius: '10px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: '#1F299C',
                    display: 'flex',
                    margin: '5px 10px 5px 0px',
                    flexDirection: 'column',
                  }}
                >
                  <Typography
                    variant='subtitle1'
                    style={{
                      color:
                        pageType === ACCEPTED_TICKETS ? '#FFFFFF' : '#1F299C',
                    }}
                  >
                    Accepted Tickets &nbsp; <b>{pad(acceptedTickets.length)}</b>
                  </Typography>
                </div>

                <div
                  onClick={() => buttonClicked(REJECTED_TICKETS)}
                  style={{
                    backgroundColor:
                      pageType === REJECTED_TICKETS ? '#1F299C' : '#FFFFFF',
                    padding: '15px',
                    cursor: 'pointer',
                    borderRadius: '10px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: '#1F299C',
                    display: 'flex',
                    margin: '5px 10px 5px 0px',
                    flexDirection: 'column',
                  }}
                >
                  <Typography
                    variant='subtitle1'
                    style={{
                      color:
                        pageType === REJECTED_TICKETS ? '#FFFFFF' : '#1F299C',
                    }}
                  >
                    Rejected Tickets &nbsp; <b>{pad(rejectedTickets.length)}</b>
                  </Typography>
                </div>

                <div
                  onClick={() => buttonClicked(CLOSED_TICKETS)}
                  style={{
                    backgroundColor:
                      pageType === CLOSED_TICKETS ? '#1F299C' : '#FFFFFF',
                    padding: '15px',
                    cursor: 'pointer',
                    borderRadius: '10px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: '#1F299C',
                    display: 'flex',
                    margin: '5px 10px 5px 0px',
                    flexDirection: 'column',
                  }}
                >
                  <Typography
                    variant='subtitle1'
                    style={{
                      color:
                        pageType === CLOSED_TICKETS ? '#FFFFFF' : '#1F299C',
                    }}
                  >
                    Closed Tickets &nbsp; <b>{pad(closedTickets.length)}</b>
                  </Typography>
                </div>

                <div
                  onClick={() => buttonClicked(CANCELLED_TICKETS)}
                  style={{
                    backgroundColor:
                      pageType === CANCELLED_TICKETS ? '#1F299C' : '#FFFFFF',
                    padding: '15px',
                    cursor: 'pointer',
                    borderRadius: '10px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: '#1F299C',
                    display: 'flex',
                    margin: '5px 10px 5px 0px',
                    flexDirection: 'column',
                  }}
                >
                  <Typography
                    variant='subtitle1'
                    style={{
                      color:
                        pageType === CANCELLED_TICKETS ? '#FFFFFF' : '#1F299C',
                    }}
                  >
                    Cancelled Tickets &nbsp;{' '}
                    <b>{pad(cancelledTickets.length)}</b>
                  </Typography>
                </div>

                <div
                  onClick={() => buttonClicked(MY_QUERIES)}
                  style={{
                    backgroundColor:
                      pageType === MY_QUERIES ? '#1F299C' : '#FFFFFF',
                    padding: '15px',
                    cursor: 'pointer',
                    borderRadius: '10px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: '#1F299C',
                    display: 'flex',
                    margin: '5px 10px 5px 0px',
                    flexDirection: 'column',
                  }}
                >
                  <Typography
                    variant='subtitle1'
                    style={{
                      color: pageType === MY_QUERIES ? '#FFFFFF' : '#1F299C',
                    }}
                  >
                    My Queries &nbsp; <b>{pad(myQueries.length)}</b>
                  </Typography>
                </div>
              </div>

              {getTabContent(pageType)}
            </main>
          </ThemeProvider>
        </div>
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color='inherit' />
        </Backdrop>
      </QueriesAllTicketContext.Provider>
    </React.Fragment>
  );
}
