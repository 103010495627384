import React, { useState, useEffect } from "react";
import {
  Button,
  Divider,
  Radio,
  TextareaAutosize,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  RadioGroup,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { postApplyLeave } from "../../store/actions/EmployeeManagement/HomePage";

const ApplyLeaveForm = (props) => {
  const {
    auth,
    open,
    setOpen,
    setIsLoading,
    setPunchingError,
    setPunchingSuccess,
  } = props;
  const { postApplyLeaveData } = useSelector((state) => state.homepage);
  const dispatch = useDispatch();
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [selectedStartDate, setStartSelectedDate] = useState(new Date());
  const [selectedEndDate, setEndSelectedDate] = useState(new Date());
  const [description, setDescription] = useState("");
  const [leaveType, setLeaveType] = useState("paid");
  const [selectedRadioValue, setRadioSelectedValue] = useState("full_day");
  const handleStartDateChange = (date) => {
    setStartSelectedDate(new Date(date));
  };
  const handleEndDateChange = (date) => {
    setEndSelectedDate(new Date(date));
  };
  const handleDescriptionChange = (event) => setDescription(event.target.value);
  const handleTypeChange = (event) => {
    setLeaveType(event.target.value);
  };
  const handleRadioChange = (event) => {
    setRadioSelectedValue(event.target.value);
  };

  const handleApplyLeave = async (e) => {
    e.preventDefault();

    const userId = auth.user._id;
    const org_id = auth.user.org_id._id;
    var duration,
      durationInHr = "",
      start_time,
      end_time;
    setPunchingError(null);
    if (selectedRadioValue === "full_day") {
      duration = moment.duration(
        moment(selectedEndDate, "YYYY/MM/DD").diff(
          moment(selectedStartDate, "YYYY/MM/DD", "days")
        )
      );
      durationInHr = (Math.ceil(duration.asHours().toFixed(2)) + 24) / 3;
      start_time = "09:00";
      end_time = "17:00";
    } else {
      duration = moment.duration(
        moment(endTime, "HH:MM").diff(moment(startTime, "HH:MM", "hours"))
      );

      durationInHr = duration.asHours().toFixed(2);
      start_time = moment(startTime).format("HH:mm").toString();
      end_time = moment(endTime).format("HH:mm").toString();
    }
    if (parseInt(durationInHr) === 0) {
      setPunchingError("Start time and End time can't be the same");
      return;
    } else if (parseInt(durationInHr) < 0) {
      setPunchingError("Invalid time duration");
      return;
    }

    let data = {
      user_id: userId,
      type: leaveType,
      org_id: org_id,
      description: description,
      from: selectedStartDate,
      to:
        selectedRadioValue === "full_day" ? selectedEndDate : selectedStartDate,
      duration: durationInHr.toString(),
      status: "Pending",
      from_time: start_time,
      to_time: end_time,
    };
    setIsLoading(true);
    dispatch(postApplyLeave(data)).finally(() => {
      setOpen(false);
      setLeaveType("paid");
      setStartSelectedDate(new Date());
      setEndSelectedDate(new Date());
      setStartTime(new Date());
      setEndTime(new Date());
      setDescription("");
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (postApplyLeaveData?.fullfilled) {
      setIsLoading(false);
      setPunchingSuccess("Leave Applied Succesfully!");

      setTimeout(() => {
        setPunchingSuccess(false);
      }, 2900);
    }
    if (postApplyLeaveData?.rejected) {
      setIsLoading(false);
      setPunchingError("Something went wrong.. Please try again!");
    }
  }, [postApplyLeaveData]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby='form-dialog-title'
      // style={{ padding: "12px" }}
    >
      <DialogTitle style={{ color: "#1F299C" }} id='form-dialog-title'>
        <Typography variant='h4' fontWeight='600'>
          Apply Leave
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          To apply a leave, please fill in the details below.
        </DialogContentText>
        <FormControl component='fieldset'>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid container justifyContent='space-around'>
              <KeyboardDatePicker
                margin='normal'
                minDate={moment().subtract(6, "days")}
                id='date-picker-dialog'
                label={selectedRadioValue === "custom" ? "Date" : "Start Date"}
                format='DD/MM/yyyy'
                value={selectedStartDate}
                onChange={handleStartDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
              {!(selectedRadioValue === "custom") && (
                <KeyboardDatePicker
                  margin='normal'
                  minDate={moment().subtract(6, "days")}
                  id='date-picker-dialog'
                  label='End Date'
                  format='DD/MM/yyyy'
                  value={selectedEndDate}
                  onChange={handleEndDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              )}
            </Grid>

            {selectedRadioValue === "custom" && (
              <div>
                <br></br>
                <Grid container justifyContent='space-around'>
                  <KeyboardTimePicker
                    label='From'
                    placeholder='08:00 AM'
                    value={startTime}
                    onChange={(date) => {
                      setStartTime(new Date(date));
                    }}
                  />
                  <KeyboardTimePicker
                    label='To'
                    placeholder='08:00 PM'
                    value={endTime}
                    onChange={(date) => {
                      setEndTime(new Date(date));
                    }}
                  />
                </Grid>
              </div>
            )}
          </MuiPickersUtilsProvider>
          <br></br>
          <RadioGroup
            row={true}
            aria-label='type'
            name='type'
            value={selectedRadioValue}
            onChange={handleRadioChange}
          >
            <FormControlLabel
              value='full_day'
              control={<Radio style={{ color: "#1F299C" }} />}
              label='Full Day'
            />
            <FormControlLabel
              value='custom'
              control={<Radio style={{ color: "#1F299C" }} />}
              label='Custom'
            />
          </RadioGroup>
          <br></br>
          <br></br>
          <FormControl variant='outlined'>
            <InputLabel id='demo-controlled-open-select-label'>
              Leave Type
            </InputLabel>
            <Select
              labelId='demo-simple-select-outlined-label'
              id='demo-simple-select-outlined'
              value={leaveType}
              onChange={handleTypeChange}
              label='Leave Type'
            >
              <MenuItem value='bank_hours'>Bank Hours</MenuItem>
              <MenuItem value='casual_leave'>Casual Leave</MenuItem>
              <MenuItem value='sick_leave'>Sick Leave</MenuItem>
              <MenuItem value='paid'>Paid Leave</MenuItem>
              <MenuItem value='unpaid'>Unpaid Leave</MenuItem>
            </Select>
          </FormControl>
          <br></br>
          <br></br>

          <h4 style={{ margin: "0px 0px 10px 0px" }}>Remarks (optional)</h4>
          <TextareaAutosize
            name='name'
            onChange={(e) => handleDescriptionChange(e)}
            style={{ width: "90%", height: "110px", padding: "10px" }}
            value={description}
            aria-label='minimum height'
            minRows={10}
            placeholder='Enter reason for leave (300 characters max.)'
          />
        </FormControl>
      </DialogContent>
      {/* <br></br> */}
      <br></br>
      <Divider />
      {/* <br></br> */}
      <br></br>
      <DialogActions style={{ margin: "0px" }}>
        <Button
          variant='contained'
          style={{
            color: "#1F299C",
            border: "1px solid #1F299C",
            backgroundColor: "white",
          }}
          onClick={() => setOpen(false)}
          color='primary'
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          style={{ backgroundColor: "#1F299C", color: "white" }}
          onClick={(e) => handleApplyLeave(e)}
          color='primary'
        >
          Apply
        </Button>
      </DialogActions>
      <br></br>
      <br></br>
    </Dialog>
  );
};
export default ApplyLeaveForm;
