import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bDays: {},
  messages: {},
  attendance: {},
  shifts: {},
  attendanceBreakout: {},
  attendanceBreakin: {},
  postRaiseQueryData: {},
  postApplyLeaveData: {},
  postMissedPunchData: {},
  postPunchInData: {},
  postPunchOutData: {},
  updateWorksheetData: {},
};

export const homepageSlice = createSlice({
  name: "homepage",
  initialState,
  reducers: {
    getBdaysPending(state, action) {
      return {
        ...state,
        bDays: { loading: true },
      };
    },
    getBdaysFullfilled(state, action) {
      return {
        ...state,
        bDays: {
          loading: false,
          fullfilled: action.payload,
        },
      };
    },
    getBdaysRejected(state, action) {
      return {
        ...state,
        bDays: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
    getMessagesPending(state, action) {
      return {
        ...state,
        messages: { loading: true },
      };
    },
    getMessagesFullfilled(state, action) {
      return {
        ...state,
        messages: {
          loading: false,
          fullfilled: action.payload,
        },
      };
    },
    getMessagesRejected(state, action) {
      return {
        ...state,
        messages: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
    getAttendanceByEmpIdPending(state, action) {
      return {
        ...state,
        attendance: { loading: true },
      };
    },
    getAttendanceByEmpIdFullfilled(state, action) {
      return {
        ...state,
        attendance: {
          loading: false,
          fullfilled: action.payload,
        },
      };
    },
    getAttendanceByEmpIdRejected(state, action) {
      return {
        ...state,
        attendance: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
    getShiftByUserIdPending(state, action) {
      return {
        ...state,
        shifts: { loading: true },
      };
    },
    getShiftByUserIdFullfilled(state, action) {
      return {
        ...state,
        shifts: {
          loading: false,
          fullfilled: action.payload,
        },
      };
    },
    getShiftByUserIdRejected(state, action) {
      return {
        ...state,
        shifts: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
    postAttendaceBreakoutPending(state, action) {
      return {
        ...state,
        attendanceBreakout: { loading: true },
      };
    },
    postAttendaceBreakoutFullfilled(state, action) {
      return {
        ...state,
        attendanceBreakout: {
          loading: false,
          fullfilled: action.payload,
        },
      };
    },
    postAttendaceBreakoutRejected(state, action) {
      return {
        ...state,
        attendanceBreakout: {
          loading: false,
          rejected: action.payload,
        },
      };
    },

    postAttendaceBreakinPending(state, action) {
      return {
        ...state,
        attendanceBreakin: { loading: true },
      };
    },
    postAttendaceBreakinFullfilled(state, action) {
      return {
        ...state,
        attendanceBreakin: {
          loading: false,
          fullfilled: action.payload,
        },
      };
    },
    postAttendaceBreakinRejected(state, action) {
      return {
        ...state,
        attendanceBreakin: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
    postRaiseQueryPending(state, action) {
      return {
        ...state,
        postRaiseQueryData: { loading: true },
      };
    },
    postRaiseQueryFullfilled(state, action) {
      return {
        ...state,
        postRaiseQueryData: {
          loading: false,
          fullfilled: action.payload,
        },
      };
    },
    postRaiseQueryRejected(state, action) {
      return {
        ...state,
        postRaiseQueryData: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
    postApplyLeavePending(state, action) {
      return {
        ...state,
        postApplyLeaveData: { loading: true },
      };
    },
    postApplyLeaveFullfilled(state, action) {
      return {
        ...state,
        postApplyLeaveData: {
          loading: false,
          fullfilled: action.payload,
        },
      };
    },
    postApplyLeaveRejected(state, action) {
      return {
        ...state,
        postApplyLeaveData: {
          loading: false,
          rejected: action.payload,
        },
      };
    },

    postAddMissedPunchPending(state, action) {
      return {
        ...state,
        postMissedPunchData: { loading: true },
      };
    },
    postAddMissedPunchFullfilled(state, action) {
      return {
        ...state,
        postMissedPunchData: {
          loading: false,
          fullfilled: action.payload,
        },
      };
    },
    postAddMissedPunchRejected(state, action) {
      return {
        ...state,
        postMissedPunchData: {
          loading: false,
          rejected: action.payload,
        },
      };
    },

    postAttendancePunchInPending(state, action) {
      return {
        ...state,
        postPunchInData: { loading: true },
      };
    },
    postAttendancePunchInFullfilled(state, action) {
      return {
        ...state,
        postPunchInData: {
          loading: false,
          fullfilled: action.payload,
        },
      };
    },
    postAttendancePunchInRejected(state, action) {
      return {
        ...state,
        postPunchInData: {
          loading: false,
          rejected: action.payload,
        },
      };
    },

    postAttendancePunchOutPending(state, action) {
      return {
        ...state,
        postPunchOutData: { loading: true },
      };
    },
    postAttendancePunchOutFullfilled(state, action) {
      return {
        ...state,
        postPunchOutData: {
          loading: false,
          fullfilled: action.payload,
        },
      };
    },
    postAttendancePunchOutRejected(state, action) {
      return {
        ...state,
        postPunchOutData: {
          loading: false,
          rejected: action.payload,
        },
      };
    },

    putShiftUpdateWorksheetPending(state, action) {
      return {
        ...state,
        updateWorksheetData: { loading: true },
      };
    },
    putShiftUpdateWorksheetFullfilled(state, action) {
      return {
        ...state,
        updateWorksheetData: {
          loading: false,
          fullfilled: action.payload,
        },
      };
    },
    putShiftUpdateWorksheetRejected(state, action) {
      return {
        ...state,
        updateWorksheetData: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
  },
});

export const {
  getBdaysPending,
  getBdaysFullfilled,
  getBdaysRejected,
  getMessagesPending,
  getMessagesFullfilled,
  getMessagesRejected,
  getAttendanceByEmpIdPending,
  getAttendanceByEmpIdFullfilled,
  getAttendanceByEmpIdRejected,
  getShiftByUserIdPending,
  getShiftByUserIdFullfilled,
  getShiftByUserIdRejected,

  postAttendaceBreakoutPending,
  postAttendaceBreakoutFullfilled,
  postAttendaceBreakoutRejected,
  postAttendaceBreakinPending,
  postAttendaceBreakinFullfilled,
  postAttendaceBreakinRejected,

  postRaiseQueryPending,
  postRaiseQueryFullfilled,
  postRaiseQueryRejected,

  postApplyLeavePending,
  postApplyLeaveFullfilled,
  postApplyLeaveRejected,
  postAddMissedPunchPending,
  postAddMissedPunchFullfilled,
  postAddMissedPunchRejected,

  postAttendancePunchInPending,
  postAttendancePunchInFullfilled,
  postAttendancePunchInRejected,

  postAttendancePunchOutPending,
  postAttendancePunchOutFullfilled,
  postAttendancePunchOutRejected,

  putShiftUpdateWorksheetPending,
  putShiftUpdateWorksheetFullfilled,
  putShiftUpdateWorksheetRejected,
} = homepageSlice.actions;

export default homepageSlice.reducer;
