import React, { useState } from "react";
// import useAuth from "hooks/useAuth";
import styled from "styled-components/macro";
import axios from "axios";
import { Button, Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: 9999,
    color: "#fff"
  }
}));

const Container = styled.div`
  background-color: white;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MyButton = styled(Button)({
  background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
  border: 0,
  borderRadius: 3,
  boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  color: "white",
  height: 48,
  padding: "0 30px"
});

const ResetPassword = function (props) {
  const classes = useStyles();
  const refId = props.match.params.refId;
  const resetToken = props.match.params.resetToken;
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const handlePasswordReset = async function (e) {
    e.preventDefault();
    if (!newPassword || !confirmPassword) {
      setErrorMessage("Please enter all fields");
      return;
    }
    if (newPassword !== confirmPassword) {
      setErrorMessage("Password not matching");
      return;
    }
    setErrorMessage(null);
    setIsLoading(true);
    // Call API

    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/users/verifyresetpassword`,
        {
          reset_token: resetToken,
          ref_id: refId,
          new_password: newPassword
        }
      );
      console.log("Resp ", resp.data);
      if (resp.status === 200) {
        setIsLoading(false);
        localStorage.removeItem("token");
        props.history.push("/login");
      }
    } catch (e) {
      setIsLoading(false);
      if (e.response.data) {
        if (e.response.data.error) {
          setErrorMessage(e.response.data.error);
        }
      }
    }
    // props.history.push("/");
  };

  return (
    <Container>
      <div id="loginform">
        <h2 id="headerTitle">Reset Your Password</h2>

        {false ? (
          <MyButton>Logout</MyButton>
        ) : (
          <div>
            <div className="row">
              <label>Enter New Password</label>
              <input
                type={"password"}
                value={newPassword}
                placeholder={"Enter new password"}
                onChange={e => setNewPassword(e.target.value)}
              />
            </div>
            <div className="row">
              <label>Confirm Password</label>
              <input
                type={"password"}
                value={confirmPassword}
                placeholder={"Enter confirm password"}
                onChange={e => setConfirmPassword(e.target.value)}
              />
            </div>
            <div id="button" className="row">
              <button onClick={handlePasswordReset}>Submit</button>
            </div>
          </div>
        )}
        {errorMessage && <span style={{ color: "red" }}>{errorMessage}</span>}
      </div>
      <div className="dashboard-image"></div>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};
export default ResetPassword;
