import React, { useState, useEffect, useContext } from "react";
import {
  Typography,
  InputBase,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  IconButton,
  Paper,
  Grid,
  Button,
  Checkbox,
  TextField,
  colors,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import clsx from "clsx";
import "@fontsource/roboto";
import axios from "axios";

import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { useDasboardStyles } from "./Style";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useHistory } from "react-router-dom";
import { TimesheetContext } from "./TimesheetContext";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import moment from "moment";
import { Autocomplete } from "@material-ui/lab";
import useAuth from "hooks/useAuth";

// import { DateRangePicker } from "@material-ui/pickers";

export function STimesheets(props) {
  let theme = createTheme();
  theme = responsiveFontSizes(theme);
  const classes = useDasboardStyles();
  const [timesheetData, setTimesheetData] = useState([]);
  console.log(timesheetData,'timesheet data');
  const [filteredProjectData, setFilteredProjectData] = useState([]);
  const [filteredSiteData, setFilteredSiteData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [originalTimesheetData, setOriginalTimesheetData] = useState([]);
  // const [value, setValue] = React.useState([null, null]);
  const history = useHistory();
  const { setEmployees } = useContext(TimesheetContext);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [uniqueEmp, setUniqueEmp] = useState([]);
  let [isLoading, setisLoading] = useState(true);
  const [jobSiteData, setJobSiteData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  console.log(projectData,'projectdata');
  let [dateChanged, setDateChanged] = useState(false);
  const auth = useAuth();
  const orgId = auth.user.org_id._id;

  const getAllSchedules = async () => {
    try {
      setisLoading(true);

      let start = moment(startDate).format("YYYY-MM-DD");
      let end = moment(endDate).format("YYYY-MM-DD");
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/scheduler?from=${start}&to=${end}`
      );
      if (response.status === 200) {
        const data = response.data.data;

        let filteredData = data.filter((project) => {
          return project.jobsites?.length ? true : false;
        });
        console.log(filteredData,'filetered data');

        let filteredDataArr = [];
        filteredData.forEach((scheduler) => {
          scheduler.jobsites?.forEach((jobsite) => {
            jobsite.assignedEmployees?.map((emp) => {
              emp.shift?.map((shift) => {
                console.log(shift, 'shift for emp');
                let employee = { ...emp, shift };
                if(shift.jobsite === jobsite._id){

                  filteredDataArr.push({
                    ...scheduler,
                    ...employee,
                    jobsite,
                  });
                }
              });
            });
          });
        });
        console.log(filteredDataArr,'timesheet data');
        setTimesheetData(filteredDataArr);
        setOriginalTimesheetData(filteredDataArr);
        setSearchData(filteredDataArr);
      }
      setisLoading(false);
    } catch (e) {
      if (e.response) {
        console.log("Error >", e.response);
      }
    }
  };

  const getAllJobSites = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/jobSite/getAllJobSite`
      );
      if (response.status === 200) {
        const data = response.data.data;
        setJobSiteData(data);
      }
    } catch (e) {
      if (e.response) {
        console.log("Error >", e.response);
      }
    }
  };

  const getAllEmployees = async () => {
    var response;
    try {
      response = await axios.get(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/users/getbyorg/${orgId}?page_number=${""}&limit=${""}`
      );
      if (response.status === 200) {
        const data = response.data.data;
        setUniqueEmp(data.data);
      }
    } catch (e) {
      if (e.response) {
        console.log("Error >", e.response);
      }
    }
  };

  const getAllProjects = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/project/getAllProjects`
      );
      if (response.status === 200) {
        const data = response.data.data.data;
        setProjectData(data);
      }
    } catch (e) {
      if (e.response) {
        console.log("Error >", e.response);
      }
    }
  };

  useEffect(() => {
    const getData = async () => {
      setisLoading(true);
      await getAllSchedules();
      await getAllJobSites();
      await getAllEmployees();
      await getAllProjects();
      setisLoading(false);
    };
    getData();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const onEmployeeTimesheetClick = () => {
    if (!selectedEmployees.length) return;

    const data = [];
    selectedEmployees.forEach((val) => {
      data.push(timesheetData[val]);
    });
    setEmployees(data);
    history.push("/shift-mngmt/timesheets/employees");
  };

  const onSingleEmployeeClick = (employee) => {
    setEmployees([employee]);
    history.push("/shift-mngmt/timesheets/employees");
  };

  const updateList = (e) => {
    const index = Number(e.target.name);
    const value = selectedEmployees.findIndex((val) => val === index);
    if (value !== -1) {
      setSelectedEmployees((prev) => [
        ...prev.slice(0, value),
        ...prev.slice(value + 1),
      ]);
    } else {
      setSelectedEmployees((prev) => [...prev, index]);
    }
  };

  const getArrayViaIndexes = (arr, indexesToKeep) => {
    let newArr = indexesToKeep.map((idx) => {
      return arr[idx];
    });
    return newArr;
  };

  const searchEmployee = (key) => {
    setTimesheetData(originalTimesheetData);
    const regExp = /\(([^)]+)\)/;
    let matches = regExp.exec(key);

    if (matches) {
      const indexes = timesheetData.map((x, idx) =>
        x.user.emp_code === matches[1] ? idx : ""
      );
      const filtered_indexes = indexes.filter((el) => {
        return el !== null && typeof el !== "undefined" && el !== "";
      });
      let arr = getArrayViaIndexes(timesheetData, filtered_indexes);
      setTimesheetData(arr);
      setSearchData(arr);
    }
  };

  const handleFilter = (e, key, component) => {
    e.preventDefault();
    let projects = [];
    if (key === "All") {
      if (component === "site") {
        setFilteredSiteData([]);
        if (filteredProjectData.length === 0) setTimesheetData(searchData);
        return;
      } else if (component === "project") {
        setFilteredProjectData([]);
        if (filteredSiteData.length === 0) setTimesheetData(searchData);
        return;
      }
    }

    if (component === "project") {
      if (filteredSiteData.length) {
        projects = filteredSiteData.filter((prj) => prj._id === key);
      } else if (searchData.length) {
        projects = searchData.filter((prj) => prj._id === key);
      } else {
        projects = originalTimesheetData.filter((prj) => prj._id === key);
      }
      setTimesheetData(projects);
      setFilteredProjectData(projects);
    } else if (component === "site") {
      if (filteredProjectData.length) {
        projects = filteredProjectData.filter((prj) => prj.jobsite._id === key);
      } else if (searchData.length) {
        projects = searchData.filter((prj) => prj.jobsite._id === key);
      } else {
        projects = originalTimesheetData.filter(
          (prj) => prj.jobsite._id === key
        );
      }

      setTimesheetData(projects);
      setFilteredSiteData(projects);
    }
  };

  return (
    <React.Fragment>
      <div className='root'>
        <ThemeProvider theme={theme}>
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: props.isDrawerOpen,
            })}
          >
            <Typography
              style={{
                textAlign: "left",
                marignBottom: "20px",
                minWidth: "230px",
              }}
            >
              Home {">"} Shift Management {">"} Timesheets
            </Typography>

            <Paper
              style={{
                padding: "20px 20px 50px 20px",
                minWidth: "230px",
                marginTop: "20px",
                minHeight: "800px",
              }}
            >
              <Typography
                variant='h5'
                style={{
                  fontWeight: "600",
                  textAlign: "left",
                  marginBottom: "30px",
                  color: "rgb(22,33,91)",
                }}
              >
                Timesheets
              </Typography>

              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  marginBottom: "10px",
                }}
              >
                <Paper
                  style={{
                    width: "275px",
                    minWidth: "225px",
                    marginRight: "20px",
                    marginBottom: "10px",
                    height: "56px",
                    backgroundColor: "rgb(248,248,248)",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {uniqueEmp && (
                    <Autocomplete
                      id='combo-box-demo'
                      options={uniqueEmp}
                      getOptionLabel={(option) =>
                        `${option.first_name} ${option.last_name} (${option.emp_code})`
                      }
                      style={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label='Search Employee'
                          variant='outlined'
                        />
                      )}
                      onChange={(e) => {
                        searchEmployee(e.target.innerText);
                      }}
                    />
                  )}
                </Paper>

                <FormControl
                  variant='outlined'
                  style={{
                    width: "275px",
                    minWidth: "225px",
                    marginRight: "20px",
                    marginBottom: "10px",
                  }}
                >
                  <InputLabel id='demo-simple-select-label-2'>
                    Select Project
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label-2'
                    label='All Sites'
                    id='demo-simple-select'
                    onChange={(event) => {
                      handleFilter(event, event.target.value, "project");
                    }}
                  >
                    <MenuItem value={"All"}>All</MenuItem>
                    {projectData
                      .sort((a, b) =>
                        a.project_name.localeCompare(b.project_name)
                      )
                      .map((project) => (
                        <MenuItem key={project._id} value={project._id}>
                          {project.project_name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <FormControl
                  variant='outlined'
                  style={{
                    width: "275px",
                    minWidth: "225px",
                    marginRight: "20px",
                    marginBottom: "10px",
                  }}
                >
                  <InputLabel id='demo-simple-select-label-2'>
                    Select Site
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label-2'
                    label='All Sites'
                    id='demo-simple-select'
                    onChange={(event) => {
                      handleFilter(event, event.target.value, "site");
                    }}
                  >
                    <MenuItem value={"All"}>All</MenuItem>
                    {jobSiteData
                      .sort((a, b) =>
                        a.jobSite_title.localeCompare(b.jobSite_title)
                      )
                      .map((jobsite) => (
                        <MenuItem key={jobsite._id} value={jobsite._id}>
                          {jobsite.jobSite_title}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <div style={{ display: "flex", marginTop: "20px" }}>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    style={{
                      border: "1px solid rgba(118, 118, 118, .4)",
                      marginBottom: "5px",
                    }}
                  >
                    <KeyboardDatePicker
                      inputVariant='outlined'
                      value={startDate}
                      onChange={(newValue) => {
                        setDateChanged(true);
                        setStartDate(newValue);
                      }}
                      keyboardIcon={
                        <CalendarTodayOutlinedIcon
                          style={{ color: "rgb(31,41,156)" }}
                        />
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{
                            color: "rgb(31,41,156)",
                            fontWeight: "500",
                          }}
                        />
                      )}
                      style={{
                        color: "rgb(31,41,156)",
                        fontWeight: "200",
                        width: "200px",
                      }}
                      InputAdornmentProps={{
                        style: {
                          color: "rgb(31,41,156)",
                          fontWeight: "200",
                        },
                      }}
                    />
                    <p style={{ paddingTop: "19px" }}>
                      &nbsp;&nbsp;to&nbsp;&nbsp;
                    </p>
                    <KeyboardDatePicker
                      inputVariant='outlined'
                      value={endDate}
                      onChange={(newValue) => {
                        setDateChanged(true);
                        setEndDate(newValue);
                      }}
                      keyboardIcon={
                        <CalendarTodayOutlinedIcon
                          style={{ color: "rgb(31,41,156)" }}
                        />
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{
                            color: "rgb(31,41,156)",
                            fontWeight: "500",
                          }}
                        />
                      )}
                      style={{
                        color: "rgb(31,41,156)",
                        fontWeight: "200",
                        width: "200px",
                      }}
                      InputAdornmentProps={{
                        style: {
                          color: "rgb(31,41,156)",
                          fontWeight: "200",
                        },
                      }}
                    />
                  </MuiPickersUtilsProvider>

                  <Button
                    id='add-options-button'
                    variant='contained'
                    aria-controls='basic-menu'
                    aria-haspopup='true'
                    style={{
                      color: "white",
                      backgroundColor: dateChanged
                        ? "rgb(31,41,156)"
                        : colors.grey[500],
                      height: "54px",
                      marginLeft: "10px",
                    }}
                    // disabled={!dateChanged}
                    onClick={getAllSchedules}
                  >
                    Get Data
                  </Button>
                </div>

                <Button
                  id='add-options-button'
                  variant='contained'
                  aria-controls='basic-menu'
                  aria-haspopup='true'
                  style={{
                    color: "white",
                    height: "54px",
                    marginLeft: "auto",
                    backgroundColor: selectedEmployees.length
                      ? colors.green[500]
                      : colors.grey[500],
                  }}
                  disabled={selectedEmployees.length === 0}
                  onClick={onEmployeeTimesheetClick}
                >
                  Check Timesheet
                </Button>
              </div>

              <hr
                style={{
                  margin: "20px -20px",
                }}
              ></hr>

              {/* apply mapping here ------------------ */}
              {timesheetData && timesheetData.length ? (
                timesheetData.map((row, index) => {
                  let project =row.project_name;
                  let jobsite_title = row.jobsite.jobSite_title;
                  return (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div className={classes.nameTagContainer}>
                          <div className={classes.nameTag}>
                            {row.user.first_name[0] + row.user.last_name[0]}
                          </div>
                        </div>

                        <Grid container spacing={2}>
                          <Grid item xs={11} style={{ textAlign: "left" }}>
                            <Typography
                              className={classes.colorBlue}
                              variant='h6'
                              style={{
                                fontWeight: "600",
                                textAlign: "left",
                              }}
                            >
                              {row.user.first_name} {row.user.last_name} (
                              {row.user.emp_code})
                            </Typography>

                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                              }}
                            >
                              <Typography
                                variant='body1'
                                className={classes.colorBlue}
                              >
                                Project:
                                <span style={{ fontWeight: "600" }}>
                                  &nbsp; &nbsp;
                                  {project ? (
                                    project
                                  ) : (
                                    <span
                                      style={{
                                        color: "grey",
                                        fontWeight: "400",
                                      }}
                                    >
                                      Unassigned
                                    </span>
                                  )}
                                  &nbsp;&nbsp; | &nbsp;
                                </span>
                              </Typography>

                              <Typography
                                variant='body1'
                                className={classes.colorBlue}
                              >
                                Jobsite:
                                <span style={{ fontWeight: "600" }}>
                                  &nbsp;
                                  {jobsite_title ? (
                                    jobsite_title
                                  ) : (
                                    <span
                                      style={{
                                        color: "grey",
                                        fontWeight: "400",
                                      }}
                                    >
                                      Unassigned
                                    </span>
                                  )}
                                  &nbsp;
                                </span>
                              </Typography>
                            </div>
                          </Grid>

                          <Grid
                            item
                            xs={1}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <IconButton
                              style={{ padding: "0px" }}
                              aria-label='search'
                              onClick={() => onSingleEmployeeClick(row)}
                            >
                              <NavigateNextIcon
                                style={{ padding: "0px" }}
                                className={classes.colorBlue}
                              />
                            </IconButton>
                            <Checkbox
                              name={index}
                              checked={selectedEmployees.includes(index)}
                              color='primary'
                              onChange={updateList}
                              inputProps={{
                                "aria-label": "secondary checkbox",
                              }}
                            />
                          </Grid>
                        </Grid>
                      </div>
                      <hr
                        style={{
                          marginTop: "20px",
                        }}
                      ></hr>
                    </div>
                  );
                })
              ) : (
                <div
                  style={{ width: "100%", color: "grey", fontStyle: "italic" }}
                >
                  <p>No data found</p>
                </div>
              )}

              {/* map till here ---------------- */}
            </Paper>
          </main>
          <Backdrop style={{ zIndex: 9999, color: "#fff" }} open={isLoading}>
            <CircularProgress color='inherit' />
          </Backdrop>
        </ThemeProvider>
      </div>
    </React.Fragment>
  );
}

// export default SM_Dashboard;
