import React from "react";
import {
  Typography,
  InputBase,
  InputLabel,
  Select,
  Button,
  Menu,
  MenuItem,
  FormControl,
  IconButton,
  Paper,
  Grid
} from "@material-ui/core";
import clsx from "clsx";
import "@fontsource/roboto";
// import useAuth from "hooks/useAuth";
import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { useDasboardStyles } from "./Style";
import SearchIcon from "@material-ui/icons/Search";
// import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOn";
import { MoreHoriz } from "@material-ui/icons";

export function SAllProjects(props) {
  let theme = createTheme();
  theme = responsiveFontSizes(theme);
  const classes = useDasboardStyles();

  // --- menu config -------------
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // ----------------------------

  // const [selectedSchedule, setSelectedSchedule] = React.useState("");

  // const auth = useAuth();

  // const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    // API call goes here
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <div className="root">
        <ThemeProvider theme={theme}>
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: props.isDrawerOpen
            })}
          >
            <Typography
              style={{
                textAlign: "left",
                marignBottom: "20px",
                minWidth: "230px"
              }}
            >
              Home {">"} Shift Management {">"} All Projects
            </Typography>

            <Paper
              style={{
                padding: "20px 20px 50px 20px",
                minWidth: "230px",
                marginTop: "20px",
                minHeight: "800px"
              }}
            >
              {/* <Typography
                  variant =  "h5"
                  style={{
                    fontWeight:"600",
                    textAlign:"left",
                    marginBottom:"30px",
                    color:"rgb(22,33,91)"
                  }}
                >
                  Timesheets
                </Typography> */}

              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  marginBottom: "10px",
                  position: "relative"
                }}
              >
                <FormControl
                  variant="outlined"
                  style={{
                    minWidth: "225px",
                    marginRight: "20px",
                    marginBottom: "10px"
                  }}
                >
                  <InputLabel
                    // style={{
                    //   paddingLeft:"5px"
                    // }}
                    id="demo-simple-select-label-1"
                  >
                    Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label-1"
                    label="Schedules"
                    id="demo-simple-select"
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    <MenuItem value={10}>Active</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>

                <FormControl
                  variant="outlined"
                  style={{
                    width: "275px",
                    minWidth: "225px",
                    marginRight: "20px",
                    marginBottom: "10px"
                  }}
                >
                  <InputLabel
                    // style={{
                    //   paddingLeft:"5px"
                    // }}
                    id="demo-simple-select-label-2"
                  >
                    All Sites
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label-2"
                    label="All Sites"
                    id="demo-simple-select"
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    <MenuItem value={10}>Active</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>

                <Paper
                  style={{
                    width: "275px",
                    minWidth: "225px",
                    height: "56px",
                    backgroundColor: "rgb(248,248,248)",
                    marginRight: "50px",
                    marginBottom: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    style={{
                      paddingLeft: "5px"
                    }}
                    placeholder="  Search..."
                  />
                  <IconButton
                    style={{ paddingleft: "-5px", paddingRight: "0px" }}
                    aria-label="search"
                  >
                    <SearchIcon
                      style={{ paddingleft: "-5px", paddingRight: "0px" }}
                    />
                  </IconButton>
                </Paper>

                <Button
                  variant="contained"
                  style={{
                    color: "white",
                    backgroundColor: "rgb(31, 41, 156)",
                    height: "56px",
                    marginLeft: "auto"
                  }}
                >
                  <span style={{ fontSize: "1.5em" }}>+</span> &nbsp; Add
                  Schedule
                </Button>
              </div>

              <hr
                style={{
                  margin: "20px -20px"
                }}
              ></hr>

              {/* apply mapping here ------------------ */}

              <Grid container spacing={2}>
                <Grid item xs={11} style={{ textAlign: "left" }}>
                  <Grid
                    container
                    justifyContent="space-between"
                    spacing={2}
                    style={{
                      paddingLeft: "10px",
                      justifyContent: "space-between"
                    }}
                  >
                    <Grid item lg={4}>
                      <Typography
                        variant="h6"
                        style={{
                          fontWeight: "600",
                          textAlign: "left"
                        }}
                      >
                        Seaforth Supply Chain Solution
                      </Typography>

                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap"
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          style={{
                            color: "grey"
                          }}
                        >
                          09 Hours | Break time: 30min
                        </Typography>
                      </div>
                    </Grid>

                    <Grid
                      item
                      className={classes.colorBlue}
                      lg={5}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap"
                      }}
                    >
                      <LocationOnOutlinedIcon />
                      <Typography
                        variant="subtitle1"
                        component={"span"}
                        style={{
                          fontWeight: "600"
                        }}
                      >
                        &nbsp; Location:&nbsp;
                      </Typography>

                      <Typography
                        variant="subtitle1"
                        component={"span"}
                        style={{
                          color: "grey"
                        }}
                      >
                        7167 Progress Way, Delta BC V4G 1K8, Canada
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      lg={2}
                      style={{
                        margin: "auto 0px",
                        paddingLeft: "15px",
                        display: "flex"
                      }}
                    >
                      <div
                        style={{
                          width: "20px",
                          height: "20px",
                          borderRadius: "50%",
                          backgroundColor: "lightGreen",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            backgroundColor: "green"
                          }}
                        ></div>
                      </div>
                      &nbsp;&nbsp;
                      <Typography
                        style={{
                          color: "green"
                        }}
                        variant="subtitle2"
                      >
                        Active
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={1}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <IconButton
                    style={{ padding: "0px" }}
                    id="basic-button"
                    aria-controls="basic-menu"
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <MoreHoriz style={{ padding: "0px" }} />
                  </IconButton>

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button"
                    }}
                  >
                    <MenuItem onClick={handleClose}>More Details</MenuItem>
                    <MenuItem onClick={handleClose}>Edit</MenuItem>
                    <MenuItem onClick={handleClose}>Delete</MenuItem>
                  </Menu>
                </Grid>
              </Grid>

              <hr
                style={{
                  marginTop: "20px"
                }}
              ></hr>
              {/* map till here ---------------- */}

              {/* =======================REMOVE THIS========================= */}

              <Grid container spacing={2}>
                <Grid item xs={11} style={{ textAlign: "left" }}>
                  <Grid
                    container
                    justifyContent="space-between"
                    spacing={2}
                    style={{
                      paddingLeft: "10px",
                      justifyContent: "space-between"
                    }}
                  >
                    <Grid item lg={4}>
                      <Typography
                        variant="h6"
                        style={{
                          fontWeight: "600",
                          textAlign: "left"
                        }}
                      >
                        Seaforth Supply Chain Solution
                      </Typography>

                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap"
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          style={{
                            color: "grey"
                          }}
                        >
                          09 Hours | Break time: 30min
                        </Typography>
                      </div>
                    </Grid>

                    <Grid
                      item
                      className={classes.colorBlue}
                      lg={5}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap"
                      }}
                    >
                      <LocationOnOutlinedIcon />
                      <Typography
                        variant="subtitle1"
                        component={"span"}
                        style={{
                          fontWeight: "600"
                        }}
                      >
                        &nbsp; Location:&nbsp;
                      </Typography>

                      <Typography
                        variant="subtitle1"
                        component={"span"}
                        style={{
                          color: "grey"
                        }}
                      >
                        7167 Progress Way, Delta BC V4G 1K8, Canada
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      lg={2}
                      style={{
                        margin: "auto 0px",
                        paddingLeft: "15px",
                        display: "flex"
                      }}
                    >
                      <div
                        style={{
                          width: "20px",
                          height: "20px",
                          borderRadius: "50%",
                          backgroundColor: "lightgrey",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            backgroundColor: "grey"
                          }}
                        ></div>
                      </div>
                      &nbsp;&nbsp;
                      <Typography
                        style={{
                          color: "grey"
                        }}
                        variant="subtitle2"
                      >
                        Deactive
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={1}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <IconButton
                    style={{ padding: "0px" }}
                    id="basic-button"
                    aria-controls="basic-menu"
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <MoreHoriz style={{ padding: "0px" }} />
                  </IconButton>

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button"
                    }}
                  >
                    <MenuItem onClick={handleClose}>More Details</MenuItem>
                    <MenuItem onClick={handleClose}>Edit</MenuItem>
                    <MenuItem onClick={handleClose}>Delete</MenuItem>
                  </Menu>
                </Grid>
              </Grid>

              <hr
                style={{
                  marginTop: "20px"
                }}
              ></hr>
              <Grid container spacing={2}>
                <Grid item xs={11} style={{ textAlign: "left" }}>
                  <Grid
                    container
                    justifyContent="space-between"
                    spacing={2}
                    style={{
                      paddingLeft: "10px",
                      justifyContent: "space-between"
                    }}
                  >
                    <Grid item lg={4}>
                      <Typography
                        variant="h6"
                        style={{
                          fontWeight: "600",
                          textAlign: "left"
                        }}
                      >
                        Seaforth Supply Chain Solution
                      </Typography>

                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap"
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          style={{
                            color: "grey"
                          }}
                        >
                          09 Hours | Break time: 30min
                        </Typography>
                      </div>
                    </Grid>

                    <Grid
                      item
                      className={classes.colorBlue}
                      lg={5}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap"
                      }}
                    >
                      <LocationOnOutlinedIcon />
                      <Typography
                        variant="subtitle1"
                        component={"span"}
                        style={{
                          fontWeight: "600"
                        }}
                      >
                        &nbsp; Location:&nbsp;
                      </Typography>

                      <Typography
                        variant="subtitle1"
                        component={"span"}
                        style={{
                          color: "grey"
                        }}
                      >
                        7167 Progress Way, Delta BC V4G 1K8, Canada
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      lg={2}
                      style={{
                        margin: "auto 0px",
                        paddingLeft: "15px",
                        display: "flex"
                      }}
                    >
                      <div
                        style={{
                          width: "20px",
                          height: "20px",
                          borderRadius: "50%",
                          backgroundColor: "lightGreen",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            backgroundColor: "green"
                          }}
                        ></div>
                      </div>
                      &nbsp;&nbsp;
                      <Typography
                        style={{
                          color: "green"
                        }}
                        variant="subtitle2"
                      >
                        Active
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={1}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <IconButton
                    style={{ padding: "0px" }}
                    id="basic-button"
                    aria-controls="basic-menu"
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <MoreHoriz style={{ padding: "0px" }} />
                  </IconButton>

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button"
                    }}
                  >
                    <MenuItem onClick={handleClose}>More Details</MenuItem>
                    <MenuItem onClick={handleClose}>Edit</MenuItem>
                    <MenuItem onClick={handleClose}>Delete</MenuItem>
                  </Menu>
                </Grid>
              </Grid>

              <hr
                style={{
                  marginTop: "20px"
                }}
              ></hr>
              <Grid container spacing={2}>
                <Grid item xs={11} style={{ textAlign: "left" }}>
                  <Grid
                    container
                    justifyContent="space-between"
                    spacing={2}
                    style={{
                      paddingLeft: "10px",
                      justifyContent: "space-between"
                    }}
                  >
                    <Grid item lg={4}>
                      <Typography
                        variant="h6"
                        style={{
                          fontWeight: "600",
                          textAlign: "left"
                        }}
                      >
                        Seaforth Supply Chain Solution
                      </Typography>

                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap"
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          style={{
                            color: "grey"
                          }}
                        >
                          09 Hours | Break time: 30min
                        </Typography>
                      </div>
                    </Grid>

                    <Grid
                      item
                      className={classes.colorBlue}
                      lg={5}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap"
                      }}
                    >
                      <LocationOnOutlinedIcon />
                      <Typography
                        variant="subtitle1"
                        component={"span"}
                        style={{
                          fontWeight: "600"
                        }}
                      >
                        &nbsp; Location:&nbsp;
                      </Typography>

                      <Typography
                        variant="subtitle1"
                        component={"span"}
                        style={{
                          color: "grey"
                        }}
                      >
                        7167 Progress Way, Delta BC V4G 1K8, Canada
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      lg={2}
                      style={{
                        margin: "auto 0px",
                        paddingLeft: "15px",
                        display: "flex"
                      }}
                    >
                      <div
                        style={{
                          width: "20px",
                          height: "20px",
                          borderRadius: "50%",
                          backgroundColor: "lightGreen",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            backgroundColor: "green"
                          }}
                        ></div>
                      </div>
                      &nbsp;&nbsp;
                      <Typography
                        style={{
                          color: "green"
                        }}
                        variant="subtitle2"
                      >
                        Active
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={1}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <IconButton
                    style={{ padding: "0px" }}
                    id="basic-button"
                    aria-controls="basic-menu"
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <MoreHoriz style={{ padding: "0px" }} />
                  </IconButton>

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button"
                    }}
                  >
                    <MenuItem onClick={handleClose}>More Details</MenuItem>
                    <MenuItem onClick={handleClose}>Edit</MenuItem>
                    <MenuItem onClick={handleClose}>Delete</MenuItem>
                  </Menu>
                </Grid>
              </Grid>

              <hr
                style={{
                  marginTop: "20px"
                }}
              ></hr>

              {/* ======= REMOVE THIS============================= */}
            </Paper>
          </main>
        </ThemeProvider>
      </div>
    </React.Fragment>
  );
}

// export default SM_Dashboard;
