import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  TextField,
  IconButton,
  Snackbar,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import axios from 'axios';
import { withStyles } from '@material-ui/styles';
import 'react-phone-input-labelled/dist/style.css';
import { makeStyles } from '@material-ui/core';

import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

// ---- toggle switch ------

import { green } from '@material-ui/core/colors';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useDispatch, useSelector } from 'react-redux';
import {
  addShiftTag,
  updateShiftTag,
} from 'store/actions/ShiftManagement/ShiftTags';

const GreenSwitch = withStyles({
  switchBase: {
    color: green[300],
    '&$checked': {
      color: green[500],
    },
    '&$checked + $track': {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

//  form element styling --------------------------
const formStyles = makeStyles((theme) => ({
  sectionHeading: {
    color: '#1F299C',
    fontWeight: '700',
    margin: '10px',
    marginBottom: '20px',
  },

  fieldContainer: {
    maxWidth: '250px',
    display: 'inline-block',
    marginRight: '20px',
    // flexDirection: 'column'
  },

  subtitle: {
    marginLeft: '10px',
    color: 'black',
    fontWeight: '600',
  },
  colorBlue: {
    color: 'rgb(31,41,156)',
  },
  colorDarkBlue: {
    color: 'rgb(22,33,91)',
  },
}));

// --------- CssTextFeild ----------------
const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .Mui-disabled': {
      color: 'black',
    },

    '& .MuiInputBase': {
      background: '#F7F8FC',
      backgroundColor: '#F7F8FC',
    },
  },
})(TextField);

// ---------  Input for Snackbar -----------------

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}
// -----------------------------------------------

function AddTagDialog(props) {
  const myClasses = formStyles();
  const [formError, setFormError] = useState(null);
  const [formSuccess, setFormSuccess] = useState(null);
  const [tagTitle, setTagTitle] = useState('');
  // const [titleError, setTitleError] = useState(true);
  const [activeStatus, setActiveStatus] = useState('');

  const dispatch = useDispatch();

  const handleToggleSwitch = (event) => {
    if (props.operation !== 'view') {
      setActiveStatus(!activeStatus);
    }
  };

  const handleCreateTag = async () => {
    try {
      let data = {
        status: `${activeStatus}`,
        tag_title: tagTitle,
      };
      dispatch(addShiftTag(data));
      // props.afterOpn();
    } catch (e) {
      if (e) {
        console.log('Error >');
      }
    }
  };

  const handleUpdateTag = async () => {
    try {
      let data = {
        _id: props.tag._id,
        status: `${activeStatus}`,
        tag_title: tagTitle,
      };

      dispatch(updateShiftTag(data));
      props.afterOpn();
    } catch (e) {
      if (e.response) {
        console.log('Error >', e.response);
      }
    }
  };

  const { shiftTag } = useSelector((state) => state.shiftTags);

  useEffect(() => {
    if (props.operation === 'view' || props.operation === 'edit') {
      if (props.tag.status === 'true') {
        setActiveStatus(true);
      } else {
        setActiveStatus(false);
      }
      setTagTitle(props.tag.tag_title);
    } else if (props.operation === 'create') {
      setActiveStatus(false);
      setTagTitle('');
    }
  }, [props]);
  useEffect(() => {
    if (shiftTag?.status) {
      handleCloseDialog();
    }
  }, [shiftTag]);

  // const handleFormSubmit = async e => {
  //   e.preventDefault();

  //   try {
  //     let response;
  //     if (props.operation === "add") {
  //       // Call Create API
  //       response = await axios.post(
  //         `${process.env.REACT_APP_API_BASE_URL}/users/create`,
  //         body
  //       );
  //     } else {
  //       // Call Update API
  //       body._id = props._id;
  //       response = await axios.put(
  //         `${process.env.REACT_APP_API_BASE_URL}/users/update`,
  //         body
  //       );
  //     }

  //     if (response.status === 200) {
  //       console.log("Response Data >>", response.data.data);
  //       props.closeForm();
  //     }
  //   } catch (e) {
  //     if (e.response.status === 400) {
  //       setFormError("Some form value missing");
  //     }
  //   }
  // };

  const handleCloseDialog = () => {
    setTagTitle('');
    setActiveStatus(false);
    props.closeForm();
  };

  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  return (
    <ThemeProvider theme={theme}>
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '10px',
          }}
        >
          <Typography
            variant='h5'
            // className = {classes.colorDarkBlue}
            style={{
              fontWeight: '600',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {props.operation === 'view'
              ? 'View Tags'
              : props.operation === 'create'
              ? ' Add Tags'
              : ' Edit Tags'}
          </Typography>

          <IconButton
            aria-label='close'
            onClick={handleCloseDialog}
            style={{
              margin: '0',
              padding: '10px',
              color: 'black',
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <hr></hr>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '20px',
            padding: '5px 10px',
            backgroundColor: 'rgb(247,248,252)',
            borderRadius: '5px',
            boxShadow: 'lightgray 0px 0px 5px',
          }}
        >
          <Typography
            variant='h6'
            className={myClasses.colorDarkBlue}
            style={{
              fontWeight: '600',
            }}
          >
            Active
          </Typography>

          <FormControlLabel
            control={
              <GreenSwitch
                onChange={handleToggleSwitch}
                checked={activeStatus}
              />
            }
          />
        </div>

        <Typography
          variant='h6'
          className={myClasses.colorDarkBlue}
          style={{
            fontWeight: '600',
            margin: '10px 20px',
          }}
        >
          Tag Title*
        </Typography>

        <CssTextField
          variant='outlined'
          style={{
            backgroundColor: 'rgb(247,248,252)',
            boxShadow: 'lightgray 0px 0px 5px',
            margin: '0px 20px',
            width: '300px',
          }}
          value={tagTitle}
          disabled={props.readOnly}
          onChange={(e) => {
            setTagTitle(e.target.value);
            // setFnameError(false);
          }}
        />
      </div>

      {props.operation !== 'view' && (
        <div>
          <hr></hr>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '0px 10px 10px 0px',
            }}
          >
            <Button
              variant='outlined'
              color='primary'
              style={{
                marginRight: '10px',
              }}
              onClick={handleCloseDialog}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              color='primary'
              onClick={
                props.operation === 'create' ? handleCreateTag : handleUpdateTag
              }
            >
              Save
            </Button>
          </div>
        </div>
      )}

      {/* ---------------------------------------------- */}
      <Snackbar
        open={!!formError}
        onClose={() => setFormError(false)}
        // TransitionComponent={<Slide direction="up" />}
        key={'Form Error'}
        autoHideDuration={3000}
      >
        <Alert severity='error'>{formError}</Alert>
      </Snackbar>
      <Snackbar
        open={!!formSuccess}
        onClose={() => setFormSuccess(false)}
        // TransitionComponent={<Slide direction="up" />}
        key={'Form Success'}
        autoHideDuration={2000}
      >
        <Alert severity='success'>{formSuccess}</Alert>
      </Snackbar>
    </ThemeProvider>
  );
}

export default AddTagDialog;
