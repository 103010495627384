import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  // FormControl,
  // MenuItem,
  // Select,
  TextField,
  IconButton,
  Snackbar,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/styles';
import 'react-phone-input-labelled/dist/style.css';
import { makeStyles } from '@material-ui/core';
import LocationSelector from './components/LocationSelector';

import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

// ---- toggle switch ------

import { green } from '@material-ui/core/colors';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useDispatch, useSelector } from 'react-redux';
import {
  createShiftJobsite,
  updateShiftJobsite,
} from 'store/actions/ShiftManagement/ShiftProjectNSites';

const parseLocation = (location, address) => {
  try {
    let parts = location.split(',');
    return {
      latitude: Number(parts[0].trim()),
      longitude: Number(parts[1].trim()),
      locality: address,
    };
  } catch (err) {
    return {};
  }
};

const GreenSwitch = withStyles({
  switchBase: {
    color: green[300],
    '&$checked': {
      color: green[500],
    },
    '&$checked + $track': {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

//  form element styling --------------------------
const formStyles = makeStyles((theme) => ({
  sectionHeading: {
    color: '#1F299C',
    fontWeight: '700',
    margin: '10px',
    marginBottom: '20px',
  },

  fieldContainer: {
    maxWidth: '250px',
    display: 'inline-block',
    marginRight: '20px',
    // flexDirection: 'column'
  },

  subtitle: {
    marginLeft: '10px',
    color: 'black',
    fontWeight: '600',
  },
  colorBlue: {
    color: 'rgb(31,41,156)',
  },
  colorDarkBlue: {
    color: 'rgb(22,33,91)',
  },
}));

// --------- CssTextFeild ----------------
const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .Mui-disabled': {
      color: 'black',
    },

    '& .MuiInputBase': {
      background: '#F7F8FC',
      backgroundColor: '#F7F8FC',
    },
  },
})(TextField);

// ---------  Input for Snackbar -----------------

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}
// -----------------------------------------------

function AddSiteDialog(props) {
  const myClasses = formStyles();
  const [formError, setFormError] = useState(null);
  const [formSuccess, setFormSuccess] = useState(null);
  const [jobSiteTitle, setJobSiteTitle] = useState('');
  const [selectedProjectID, setSelectedProjectID] = useState('');
  const [location, setLocation] = useState('');
  const [address, setAddress] = useState('');
  const [activeStatus, setActiveStatus] = useState('');

  const dispatch = useDispatch();

  const handleToggleSwitch = (event) => {
    if (props.operation !== 'view') {
      setActiveStatus(!activeStatus);
    }
  };

  const handleCreateJobSite = async () => {
    let data = {
      active: `${activeStatus}`,
      jobSite_title: jobSiteTitle,
      schedule: 'Seaford Supply Chain',
      dropped_pin: location,
      address: address,
      project_id: selectedProjectID,
    };
    props.setIsLoading(true);
    dispatch(createShiftJobsite(data)).then(() => {
      handleCloseDialog();
      props.afterOpn();
    });
  };

  const handleUpdateJobSite = async () => {
    let data = {
      _id: props.jobSite._id,
      active: `${activeStatus}`,
      jobSite_title: jobSiteTitle,
      schedule: 'Seaford Supply Chain',
      dropped_pin: location,
      address: address,
    };
    dispatch(updateShiftJobsite(data)).then(() => {
      handleCloseDialog();
      props.afterOpn();
    });
  };

  useEffect(() => {
    if (props.operation === 'view' || props.operation === 'edit') {
      if (props.jobSite.active === 'true') {
        setActiveStatus(true);
      } else {
        setActiveStatus(false);
      }

      setJobSiteTitle(props.jobSite.jobSite_title);
      setLocation(props.jobSite.dropped_pin);
      setAddress(props.jobSite.address);
    } else if (props.operation === 'create') {
      setActiveStatus(false);
      setJobSiteTitle('');
      setLocation('');
      setAddress('');
    }
    setSelectedProjectID(props.selectedProject._id);
  }, [props]);

  const handleCloseDialog = () => {
    setJobSiteTitle('');
    setActiveStatus(false);
    props.closeForm();
  };

  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  return (
    <ThemeProvider theme={theme}>
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '10px',
          }}
        >
          <Typography
            variant='h5'
            // className = {classes.colorDarkBlue}
            style={{
              fontWeight: '600',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Add Job Site
          </Typography>

          <IconButton
            aria-label='close'
            onClick={handleCloseDialog}
            style={{
              margin: '0',
              padding: '0',
              color: 'black',
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <hr></hr>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '20px',
            padding: '5px 10px',
            backgroundColor: 'rgb(247,248,252)',
            borderRadius: '5px',
            boxShadow: 'lightgray 0px 0px 5px',
          }}
        >
          <Typography
            variant='h6'
            className={myClasses.colorDarkBlue}
            style={{
              fontWeight: '600',
            }}
          >
            Active
          </Typography>

          <FormControlLabel
            control={
              <GreenSwitch
                onChange={handleToggleSwitch}
                checked={activeStatus}
              />
            }
          />
        </div>

        <Typography
          variant='h6'
          className={myClasses.colorDarkBlue}
          style={{
            fontWeight: '600',
            margin: '10px 20px',
          }}
        >
          Job Site Title*
        </Typography>

        <CssTextField
          variant='outlined'
          style={{
            backgroundColor: 'rgb(247,248,252)',
            boxShadow: 'lightgray 0px 0px 5px',
            margin: '0px 20px',
            width: '300px',
          }}
          value={jobSiteTitle}
          disabled={props.readOnly}
          onChange={(e) => {
            setJobSiteTitle(e.target.value);
            // setFnameError(false);
          }}
        />

        {/* <Typography
          variant="h6"
          className={myClasses.colorDarkBlue}
          style={{
            fontWeight: "600",
            margin: "10px 20px",
          }}
        >
          Schedule*
        </Typography>

        <FormControl
          variant="outlined"
          style={{
            minWidth: "300px",
            margin: "0px 20px",
          }}
        >
          <Select
            labelId="demo-simple-select-label-1"
            // label="Schedules"
            id="demo-simple-select"
          >
            <MenuItem value="All Schedules">
              <em>All Schedules</em>
            </MenuItem>
            <MenuItem value={10}>Active</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </FormControl> */}

        <Typography
          variant='h6'
          className={myClasses.colorDarkBlue}
          style={{
            fontWeight: '600',
            margin: '10px 20px',
          }}
        >
          Dropped Pin
        </Typography>

        {/* <CssTextField
          variant="outlined"
          style={{
            backgroundColor: "rgb(247,248,252)",
            boxShadow: "lightgray 0px 0px 5px",
            margin: "0px 20px",
            width: "300px",
          }}
          value={location}
          disabled={props.readOnly}
          onChange={(e) => {
            setLocation(e.target.value);
            // setFnameError(false);
          }}
        /> */}

        <Typography
          variant='h7'
          className={myClasses.colorDarkBlue}
          style={{
            fontWeight: '600',
            margin: '10px 20px',
          }}
        >
          Latitude: {parseLocation(location).latitude || '--'}
        </Typography>
        <Typography
          variant='h7'
          className={myClasses.colorDarkBlue}
          style={{
            fontWeight: '600',
            margin: '10px 20px',
          }}
        >
          Longitude: {parseLocation(location).longitude || '--'}
        </Typography>

        {/* <Typography
          variant="h6"
          className={myClasses.colorDarkBlue}
          style={{
            fontWeight: "600",
            margin: "10px 20px"
          }}>
          Address
        </Typography>

        <CssTextField
          variant="outlined"
          style={{
            backgroundColor: "rgb(247,248,252)",
            boxShadow: "lightgray 0px 0px 5px",
            margin: "0px 20px",
            width: "300px"
          }}
          value={address}
          disabled={props.readOnly}
          onChange={e => {
            setAddress(e.target.value);
            // setFnameError(false);
          }}
        /> */}

        <LocationSelector
          address={address}
          setAddress={setAddress}
          location={location}
          setLocation={setLocation}
          readOnly={props.readOnly}
        />
      </div>

      {props.operation !== 'view' && (
        <div>
          <hr></hr>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '0px 10px 10px 0px',
            }}
          >
            <Button
              variant='outlined'
              color='primary'
              style={{
                marginRight: '10px',
              }}
              onClick={handleCloseDialog}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              color='primary'
              onClick={
                props.operation === 'create'
                  ? handleCreateJobSite
                  : handleUpdateJobSite
              }
            >
              Save
            </Button>
          </div>
        </div>
      )}

      <Snackbar
        open={!!formError}
        onClose={() => setFormError(false)}
        key={'Form Error'}
        autoHideDuration={3000}
      >
        <Alert severity='error'>{formError}</Alert>
      </Snackbar>
      <Snackbar
        open={!!formSuccess}
        onClose={() => setFormSuccess(false)}
        key={'Form Success'}
        autoHideDuration={2000}
      >
        <Alert severity='success'>{formSuccess}</Alert>
      </Snackbar>
    </ThemeProvider>
  );
}

export default AddSiteDialog;
