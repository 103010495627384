import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  queries: {},
};

export const queriesAndTicketsSlice = createSlice({
  name: "queriesAndTickets",
  initialState,
  reducers: {
    getAllRequestsPending(state, action) {
      return {
        ...state,
        queries: { loading: true },
      };
    },
    getAllRequestsFulfilled(state, action) {
      return {
        ...state,
        queries: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    getAllRequestsRejected(state, action) {
      return {
        ...state,
        queries: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
  },
});

export const {
  getAllRequestsPending,
  getAllRequestsFulfilled,
  getAllRequestsRejected,
} = queriesAndTicketsSlice.actions;

export default queriesAndTicketsSlice.reducer;
