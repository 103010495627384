import React, { useState, useEffect, createContext } from 'react';
import {
  Typography,
  //InputBase,
  InputLabel,
  Select,
  Button,
  Menu,
  MenuItem,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  Paper,
  Snackbar,
  TextField,
  CircularProgress,
  Backdrop,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';

import clsx from 'clsx';
import '@fontsource/roboto';
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { useDasboardStyles } from './Style';
//import SearchIcon from "@material-ui/icons/Search";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddShiftTemplateDialog from '../ShiftTemplates/AddShiftTemplateDialog';
import AddShiftTaskDialog from '../ShiftTask/AddShiftTaskDialog';
import AssignShiftDialog from './AssignShift';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import SProjectCard from './SProjectCard';
import {
  // formatDateddmmyyyy,
  // formatDateyyyymmdd,
  formatDateyyyymmddForToday,
} from '../utils';
// import SidebarSitesContent from "./SidebarSitesContent";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/styles';
import { green } from '@material-ui/core/colors';
import Switch from '@material-ui/core/Switch';
import {
  getAllShiftSchedules,
  getAllJobSite,
} from '../../../store/actions/ShiftManagement/Scheduler';
import { useDispatch, useSelector } from 'react-redux';
import { clearAllShiftSchedules } from 'store/slices/ShiftManagement/Scheduler';
import { endOfDay, parse, startOfDay } from 'date-fns';

moment.locale('en', {
  week: {
    dow: 1,
  },
});
moment.locale('en');

export const ShowUnscheduledEmpsContext = createContext();

const GreenSwitch = withStyles({
  switchBase: {
    color: green[300],
    '&$checked': {
      color: green[500],
    },
    '&$checked + $track': {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
})(Switch);
export function SScheduler(props) {
  let theme = createTheme();
  const dispatch = useDispatch();
  theme = responsiveFontSizes(theme);
  const classes = useDasboardStyles();
  const headerHrs = [
    '12 AM',
    '1 AM',
    '2 AM',
    '3 AM',
    '4 AM',
    '5 AM',
    '6 AM',
    '7 AM',
    '8 AM',
    '9 AM',
    '10 AM',
    '11 AM',
    '12 PM',
    '1 PM',
    '2 PM',
    '3 PM',
    '4 PM',
    '5 PM',
    '6 PM',
    '7 PM',
    '8 PM',
    '9 PM',
    '10 PM',
    '11 PM',
  ];

  const weekHeaderList = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
  const [cellWidth, setCellWidth] = useState(
    (window.screen.availWidth - 208 - 96 - 200 - 102) / 24
  );
  const [scheduleData, setScheduleData] = useState([]);
  const [dialogOperation, setDialogOperation] = useState('');
  const [dateRangeString, setDateRangeString] = useState('');
  const [readOnly, setReadOnly] = useState(false);
  const [selectedScheduleRange, setSelectedScheduleRange] = useState('today');
  const [assignShiftError, setAssignShiftError] = useState(false);
  // const [sidebar, setSidebar] = useState(false);
  const [jobSiteData, setJobSiteData] = useState([]);
  const [filteredBySite, setfilteredBySite] = React.useState([]);
  const [allQueries, setallQueries] = React.useState([]);
  const [activeStatus, setActiveStatus] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [filteredData, setFilteredData] = useState([]);
  const { allSchedules, jobSites, loading } = useSelector(
    (state) => state.scheduler
  );
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const handleLoading = (val) => {
    setIsLoading(val);
  };

  const getDateRange = (range) => {
    let rangeStartDate, rangeEndDate;

    // setIsLoading(true);
    switch (range) {
      case 'week':
        rangeStartDate = moment(selectedDate)
          .startOf('week')
          .toDate()
          .toISOString();
        rangeEndDate = moment(selectedDate)
          .endOf('week')
          .toDate()
          .toISOString();
        break;
      case 'month':
        rangeStartDate = moment(selectedDate)
          .startOf('month')
          .toDate()
          .toISOString();
        rangeEndDate = moment(selectedDate)
          .endOf('month')
          .toDate()
          .toISOString();
        break;
      case 'today':
      default:
        rangeStartDate = new Date().toISOString();
        rangeEndDate = new Date().toISOString();
        break;
    }
    setDateRangeString(
      moment(rangeStartDate).format('YYYY-MM-DD') +
      ' to ' +
      moment(rangeEndDate).format('YYYY-MM-DD')
    );

    getAllSchedules(
      moment(rangeStartDate).format('YYYY-MM-DD'),
      moment(rangeEndDate).format('YYYY-MM-DD')
    );
    setStartDate(rangeStartDate);
    setEndDate(rangeEndDate);
  };
  // ----------------------------

  // ----------- options config -----------------
  const [openShiftTemplateDialog, setOpenShiftTemplateDialog] = useState(false);
  const [openShiftTaskDialog, setOpenShiftTaskDialog] = useState(false);
  const [openAssignShiftDialog, setOpenAssignShiftDialog] = useState(false);
  const [weeks, setWeeks] = useState([]);

  const getAllSchedules = async (dateStart, dateEnd) => {
   let dateS = parse(dateStart, 'yyyy-MM-dd', new Date());
   let dateE = parse(dateEnd, 'yyyy-MM-dd', new Date());
   dateS = startOfDay(dateS);
   dateE = endOfDay(dateE);
   dateS = dateS.toISOString();
   dateE = dateE.toISOString();

    const params = {
      from: dateS,
      to: dateE,
    };
    dispatch(getAllShiftSchedules(params));
  };

  const getAllJobSites = async () => {
    dispatch(getAllJobSite());
  };

  // createShiftTemplate

  const createShiftTemplateDialogOpen = () => {
    handleCloseAddOpt();
    setDialogOperation('create');
    setReadOnly(false);
    setOpenShiftTemplateDialog(true);
  };

  const createAssignShiftDialogOpen = () => {
    handleCloseAddOpt();
    setDialogOperation('create');
    setReadOnly(false);
    setOpenAssignShiftDialog(true);
  };

  const createShiftTaskDialogOpen = () => {
    handleCloseAddOpt();
    setDialogOperation('create');
    setReadOnly(false);
    setOpenShiftTaskDialog(true);
  };

  const handleCloseDialog = () => {
    // handleClose();
    setOpenAssignShiftDialog(false);
    setOpenShiftTemplateDialog(false);
    setOpenShiftTaskDialog(false);
  };

  // -------------------- Transition -------------------------------------
  // const Transition = React.forwardRef(function Transition(props, ref) {
  //   return <Slide direction="left" ref={ref} {...props} />;
  // });

  // ---- ADD +  options ------------------------

  const [anchorAddOpt, setAnchorAddOpt] = useState(null);
  const openAddOpt = Boolean(anchorAddOpt);
  const handleOpenAddOpt = (event) => {
    setAnchorAddOpt(event.currentTarget);
  };
  const handleCloseAddOpt = () => {
    setAnchorAddOpt(null);
  };

  const handleToggleSwitch = (event) => {
    if (props.operation !== 'view') {
      setActiveStatus(!activeStatus);
    }
  };

  const handleFilterBySite = (e, site_nd_prjId) => {
    e.preventDefault();
    let ids = site_nd_prjId.split(' ');
    const siteProject = scheduleData.filter((prj) => prj._id === ids[1])[0];
    if (siteProject) {
      const filteredSite = siteProject.jobsites.filter(
        (site) => site._id === ids[0]
      );
      let newData = [
        {
          _id: filteredSite[0].project_id,
          jobsites: filteredSite,
          project_name: siteProject.project_name,
        },
      ];
      setFilteredData(newData);
      setfilteredBySite(filteredSite);
      setallQueries(filteredSite);
      return;
    }
    setFilteredData(scheduleData);
  };

  let getDaysArray = (s, e) => {
    for (
      var a = [], d = new Date(s);
      d <= new Date(e);
      d.setDate(d.getDate() + 1)
    ) {
      a.push(new Date(d));
    }
    return a;
  };

  const showTodayHeaderList = (data) => {
    return headerHrs.map((hrs, index) => {
      return (
        <TableCell
          key={index}
          style={{
            fontSize: '9px',
            padding: '0px',
            width: cellWidth + 'px',
            textAlign: 'left',
          }}
        >
          {' '}
          {hrs}
        </TableCell>
      );
    });
  };

  useEffect(() => {
    if (selectedScheduleRange === 'week') {
      let weeksArr = getDaysArray(
        moment(startDate).startOf('week').toDate().toISOString(),
        moment(endDate).endOf('week').toDate().toISOString()
      );
      setWeeks(weeksArr);
    }
    return () => {
      setWeeks([]);
    };
  }, [selectedScheduleRange, selectedDate]);

  /// header columns
  const showWeekHeaderList = (start, end, type) => {
    let tdays = getDaysArray(start, end);
    return tdays.map((days, index) => {
      return (
        <TableCell
          key={index}
          props
          style={{
            fontSize: '9px',
            padding: '0px',
            textAlign: 'left',
            width: cellWidth + 'px',
          }}
        >
          {' '}
          {days.getDate()} {weekHeaderList[days.getDay()]}{' '}
        </TableCell>
      );
    });
  };

  const onDateChange = (date, range) => {
    let updatedStartDate, updatedEndData;
    updatedStartDate =
      range !== 'today'
        ? moment(date).startOf(range).toDate().toISOString()
        : new Date(date).toISOString();
    updatedEndData =
      range !== 'today'
        ? moment(date).endOf(range).toDate().toISOString()
        : new Date(date).toISOString();

    setStartDate(updatedStartDate);
    setEndDate(updatedEndData);
    setDateRangeString(
      formatDateyyyymmddForToday(updatedStartDate) +
      ' to ' +
      formatDateyyyymmddForToday(updatedEndData)
    );
    // setIsLoading(true);
    getAllSchedules(
      formatDateyyyymmddForToday(updatedStartDate),
      formatDateyyyymmddForToday(updatedEndData)
    );
  };

  const onSelectedScheduleRange = (key) => {
    setSelectedScheduleRange(key);
    onDateChange(selectedDate, key);
    switch (key) {
      case 'today':
        setCellWidth((window.screen.availWidth - 208 - 96 - 200 - 102) / 24);
        break;
      case 'week':
        setCellWidth((window.screen.availWidth - 208 - 65 - 102) / 7);
        break;
      case 'month':
        setCellWidth(
          (window.screen.availWidth - 208 - 100 - 200 - 102) / 7 + 30
        );
        break;
      default:
        setCellWidth((window.screen.availWidth - 208 - 96 - 200 - 102) / 7);
        break;
    }
  };

  const showScheduleTable = (rows) => {
    return (
      <ShowUnscheduledEmpsContext.Provider value={activeStatus}>
        <TableContainer
          style={{ overflowY: 'scroll', height: '77vh' }}
          component={Paper}
        >
          <Table
            className={classes.table}
            aria-label='scheduler table'
            style={{ tableLayout: 'fixed' }}
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    width: selectedScheduleRange === 'week' ? '220px' : '200px',
                    padding: 0,
                  }}
                >
                  {' '}
                  Total : 50 hrs
                </TableCell>

                {selectedScheduleRange === 'today' ? (
                  showTodayHeaderList()
                ) : (
                  <></>
                )}
                {selectedScheduleRange === 'week' ? (
                  showWeekHeaderList(
                    moment(startDate).startOf('week').toDate().toISOString(),
                    moment(endDate).endOf('week').toDate().toISOString(),
                    'week'
                  )
                ) : (
                  <></>
                )}
                {selectedScheduleRange === 'month' ? (
                  showWeekHeaderList(
                    moment(startDate).startOf('month').toDate().toISOString(),
                    moment(endDate).endOf('month').toDate().toISOString(),
                    'month'
                  )
                ) : (
                  // : selectedScheduleRange === "biweekly" ? (
                  //   showWeekHeaderList(
                  //     moment(startDate).toDate().toISOString(),
                  //     moment(endDate).toDate().toISOString()
                  //   )
                  // )
                  <></>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows && rows.length ? (
                rows.map((row, index) => {
                  return row ? (
                    <SProjectCard
                      row={row}
                      key={index}
                      cellWidth={cellWidth}
                      selectedScheduleRange={selectedScheduleRange}
                      afterOpn={afterOpn}
                      handleLoading={handleLoading}
                      setAssignShiftError={setAssignShiftError}
                      scheduleData={scheduleData}
                      weeks={weeks}
                    />
                  ) : (
                    <></>
                  );
                })
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </ShowUnscheduledEmpsContext.Provider>
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    onSelectedScheduleRange(selectedScheduleRange);
    getAllJobSites();
    return () => {
      dispatch(clearAllShiftSchedules());
    };
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (scheduleData.jobsites) {
      setallQueries(scheduleData);
      setfilteredBySite(scheduleData);
    }
  }, [scheduleData.jobsites]);

  useEffect(() => {
    // setIsLoading(allSchedules.loading);
    if (allSchedules.fulfilled) {
      const data = allSchedules.fulfilled.data;
      setScheduleData(data);
      setFilteredData(data);
      // setIsLoading(allSchedules.loading);
    }
  }, [allSchedules.loading]);

  useEffect(() => {
    if (jobSites.fulfilled) {
      setJobSiteData(jobSites.fulfilled.data);
    }
  }, [jobSites]);
  const afterOpn = () => getDateRange(selectedScheduleRange);

  return (
    <React.Fragment>
      <Backdrop style={{ zIndex: 9999, color: '#fff' }} open={isLoading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <div className='root'>
        <ThemeProvider theme={theme}>
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: props.isDrawerOpen,
            })}
          >
            <Typography
              style={{
                textAlign: 'left',
                marignBottom: '20px',
                minWidth: '230px',
              }}
            >
              Home {'>'} Shift Management {'>'} Scheduler
            </Typography>

            <div
              style={{
                display: 'flex',
                margin: '10px',
                marginLeft: '0px',
                flexWrap: 'wrap',
              }}
            ></div>
            <Paper
              style={{
                padding: '20px',
                minWidth: '230px',
                marginTop: '20px',
                minHeight: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  marginBottom: '10px',
                  position: 'relative',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ display: 'flex' }}>
                  {/* <Paper
                    style={{
                      width: "200px",
                      height: "56px",
                      backgroundColor: "rgb(248,248,248)",
                      marginRight: "50px",
                      marginBottom: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      style={{
                        paddingLeft: "10px",
                      }}
                      placeholder="  Search..."
                    />
                    <IconButton
                      style={{ paddingleft: "-5px", paddingRight: "2px" }}
                      aria-label="search"
                    >
                      <SearchIcon
                        style={{ paddingleft: "-5px", paddingRight: "2px" }}
                      />
                    </IconButton>
                  </Paper> */}

                  <FormControl
                    variant='outlined'
                    style={{
                      width: '200px',
                      marginRight: '20px',
                      marginBottom: '10px',
                    }}
                  >
                    <InputLabel id='demo-simple-select-label-2'>
                      Schedule
                    </InputLabel>
                    <Select
                      labelId='demo-simple-select-label-2'
                      label='All tags'
                      id='demo-simple-select'
                      value={selectedScheduleRange}
                      onChange={(e) => onSelectedScheduleRange(e.target.value)}
                    >
                      <MenuItem value='today'>Daily</MenuItem>
                      <MenuItem value='week'>Weekly</MenuItem>
                      <MenuItem value='month'>Monthly</MenuItem>
                      {/* <MenuItem value='range'>Date Range</MenuItem> */}
                    </Select>
                  </FormControl>
                  {true ? (
                    <FormControl>
                      <div
                        style={{
                          display: 'flex',
                        }}
                      >
                        <MuiPickersUtilsProvider
                          utils={DateFnsUtils}
                          style={{
                            border: '1px solid rgba(118, 118, 118, .4)',
                            marginBottom: '5px',
                          }}
                        >
                          <KeyboardDatePicker
                            inputVariant='outlined'
                            value={selectedDate}
                            format='yyyy-MM-dd'
                            onChange={(date) => {
                              setSelectedDate(date);
                              onDateChange(date, selectedScheduleRange);
                            }}
                            keyboardIcon={
                              <CalendarTodayOutlinedIcon
                                style={{ color: 'rgb(31,41,156)' }}
                              />
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                style={{
                                  color: 'rgb(31,41,156)',
                                  fontWeight: '500',
                                }}
                              />
                            )}
                            style={{
                              color: 'rgb(31,41,156)',
                              fontWeight: '200',
                              width: '200px',
                            }}
                            InputAdornmentProps={{
                              style: {
                                color: 'rgb(31,41,156)',
                                fontWeight: '200',
                              },
                            }}
                          />
                          {/* <p style={{ paddingTop: "19px" }}>
                            &nbsp;&nbsp;to &nbsp;
                          </p>
                          <KeyboardDatePicker
                            inputVariant='outlined'
                            defaultValue={startDate}
                            value={endDate}
                            format='yyyy-MM-dd'
                            minDate={startDate}
                            onChange={(newValue) => {
                              setEndDate(newValue);
                              setDateRangeString(
                                formatDateyyyymmddForToday(startDate) +
                                  " to " +
                                  formatDateyyyymmddForToday(newValue)
                              );
                            }}
                            keyboardIcon={
                              <CalendarTodayOutlinedIcon
                                style={{ color: "rgb(31,41,156)" }}
                              />
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                style={{
                                  color: "rgb(31,41,156)",
                                  fontWeight: "500",
                                }}
                              />
                            )}
                            style={{
                              color: "rgb(31,41,156)",
                              fontWeight: "200",
                              width: "200px",
                            }}
                            InputAdornmentProps={{
                              style: {
                                color: "rgb(31,41,156)",
                                fontWeight: "200",
                              },
                            }}
                          /> */}
                        </MuiPickersUtilsProvider>
                        {/* <Button
                          id='add-options-button'
                          variant='outlined'
                          style={{
                            color: "white",
                            backgroundColor: "rgb(31, 41, 156)",
                            height: "54px",
                            marginLeft: "20px",
                          }}
                          type='submit'
                          onClick={(e) => {
                            e.preventDefault();
                            getShiftsDates();
                          }}
                        >
                          Get Shifts
                        </Button> */}
                      </div>
                    </FormControl>
                  ) : (
                    <></>
                  )}
                </div>
                <div
                  style={{ display: 'flex' }}
                  className='flex gap-2 flex-wrap'
                >
                  <FormControl
                    variant='outlined'
                    style={{
                      width: '200px',
                      marginRight: '20px',
                      marginBottom: '10px',
                    }}
                  >
                    <InputLabel id='demo-simple-select-label-2'>
                      All Sites
                    </InputLabel>
                    <Select
                      labelId='demo-simple-select-label-2'
                      label='All sites'
                      id='demo-simple-select'
                      onChange={(event) => {
                        if (event.target.value === 'All') {
                          setFilteredData(scheduleData);
                          setfilteredBySite(scheduleData.jobsites);
                          setallQueries(scheduleData.jobsites);
                        } else {
                          handleFilterBySite(event, event.target.value);
                        }
                      }}
                    >
                      <MenuItem value={'All'}>
                        <em>All Sites</em>
                      </MenuItem>
                      {jobSiteData.map((jobsite) => (
                        <MenuItem
                          key={jobsite._id}
                          value={jobsite._id + ' ' + jobsite.project_id}
                        >
                          {jobsite.jobSite_title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <Button
                    id='add-options-button'
                    variant='contained'
                    aria-controls='basic-menu'
                    aria-haspopup='true'
                    aria-expanded={openAddOpt ? 'true' : undefined}
                    style={{
                      color: 'white',
                      backgroundColor: 'rgb(31, 41, 156)',
                      height: '56px',
                      marginRight: '20px',
                    }}
                    onMouseOver={handleOpenAddOpt}
                    onClick={createAssignShiftDialogOpen}
                  >
                    + Add
                  </Button>
                  <Menu
                    id='basic-menu'
                    anchorEl={anchorAddOpt}
                    open={openAddOpt}
                    onClose={handleCloseAddOpt}
                    MenuListProps={{
                      'aria-labelledby': 'add-options-button',
                    }}
                  >
                    <MenuItem onClick={createAssignShiftDialogOpen}>
                      Assign Shift
                    </MenuItem>
                    <MenuItem onClick={createShiftTemplateDialogOpen}>
                      Create New Shift
                    </MenuItem>
                    <MenuItem onClick={createShiftTaskDialogOpen}>
                      Add Task
                    </MenuItem>
                    {/* <MenuItem>Add Break</MenuItem>
                    <MenuItem>Update Hours</MenuItem>
                    <MenuItem>Exports</MenuItem> */}
                  </Menu>

                  {/* <Button
                    variant="contained"
                    style={{
                      color: "white",
                      backgroundColor: "rgb(31, 41, 156)",
                      height: "56px"
                    }}
                  >
                    &nbsp; Publish &amp; Notify
                  </Button> */}

                  <div>
                    <IconButton
                      style={{
                        marginLeft: '5px',
                        marginTop: '5px',
                      }}
                      aria-label='more'
                      id='long-button'
                      aria-controls='basic-menu'
                      aria-haspopup='true'
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id='long-menu'
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                      PaperProps={{
                        style: {
                          width: '130px',
                          top: '215px',
                          left: '1500px',
                        },
                      }}
                    ></Menu>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marignBottom: '5px',
                }}
              >
                <div>Date Range: &nbsp;{dateRangeString}</div>
                <div>
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    {/* <div
                      style={{
                        display: 'flex',
                        marginRight: '10px',
                      }}
                    >
                      <FiberManualRecordIcon
                        style={{
                          color: '#eee',
                          borderWidth: '1px',
                          borderColor: '#aaaaaa',
                          borderStyle: 'solid',
                          borderRadius: 1000,
                        }}
                      />
                      &nbsp; Shift in Progress
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        marginRight: '10px',
                      }}
                    >
                      <FiberManualRecordIcon
                        style={{
                          color: '#e0eaf8',
                          borderWidth: '1px',
                          borderColor: '#aaaaaa',
                          borderStyle: 'solid',
                          borderRadius: 1000,
                        }}
                      />
                      &nbsp; Scheduled Shift
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        marginRight: '30px',
                      }}
                    >
                      <FiberManualRecordIcon
                        style={{
                          color: '#e5f5e7',
                          borderWidth: '1px',
                          borderColor: '#aaaaaa',
                          borderStyle: 'solid',
                          borderRadius: 1000,
                        }}
                      />
                      &nbsp; Completed
                    </div> */}

                    <div
                      style={{
                        display: 'flex',
                        marginRight: '10px',
                      }}
                    >
                      <FormControlLabel
                        style={{
                          display: 'flex',
                          marginTop: '-10px',
                        }}
                        control={
                          <GreenSwitch
                            style={{
                              color: '#e5f5e7',
                            }}
                            onChange={handleToggleSwitch}
                            checked={activeStatus}
                          />
                        }
                      />
                      Show Unscheduled
                    </div>
                  </div>
                </div>
              </div>
              <div>{showScheduleTable(filteredData)}</div>

              {openShiftTemplateDialog ? (
                <Dialog
                  open={openShiftTemplateDialog}
                  maxWidth='sm'
                  fullWidth
                  keepMounted
                  aria-describedby='alert-dialog-slide-description'
                  PaperProps={{
                    style: {
                      position: 'absolute',
                      margin: '0px',
                      padding: '0px',
                      right: '0px',
                      minHeight: '111vh',
                      top: '0px',
                      borderRadius: '0px',
                    },
                  }}
                >
                  <DialogContent
                    style={{
                      margin: '0px',
                      padding: '0px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                  >
                    <AddShiftTemplateDialog
                      operation={dialogOperation}
                      closeForm={handleCloseDialog}
                      readOnly={readOnly}
                      setIsLoading={setIsLoading}
                      setState={() => { }}
                    />
                  </DialogContent>
                </Dialog>
              ) : (
                <></>
              )}
              {openShiftTaskDialog ? (
                <Dialog
                  open={openShiftTaskDialog}
                  maxWidth='sm'
                  fullWidth
                  keepMounted
                  aria-describedby='alert-dialog-slide-description'
                  PaperProps={{
                    style: {
                      position: 'absolute',
                      margin: '0px',
                      padding: '0px',
                      right: '0px',
                      minHeight: '111vh',
                      top: '0px',
                      borderRadius: '0px',
                    },
                  }}
                >
                  <DialogContent
                    style={{
                      margin: '0px',
                      padding: '0px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                  >
                    <AddShiftTaskDialog
                      operation={dialogOperation}
                      closeForm={handleCloseDialog}
                      readOnly={readOnly}
                    />
                  </DialogContent>
                </Dialog>
              ) : (
                <></>
              )}

              {openAssignShiftDialog ? (
                <Dialog
                  open={openAssignShiftDialog}
                  maxWidth='false'
                  fullWidth
                  keepMounted
                  aria-describedby='alert-dialog-slide-description'
                  PaperProps={{
                    style: {
                      position: 'absolute',
                      margin: '0px',
                      padding: '0px',
                      right: '0px',
                      minHeight: '111vh',
                      top: '0px',
                      borderRadius: '0px',
                      width: '700px',
                    },
                  }}
                >
                  <DialogContent
                    style={{
                      margin: '0px',
                      padding: '0px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                  >
                    <AssignShiftDialog
                      operation={dialogOperation}
                      closeForm={handleCloseDialog}
                      readOnly={readOnly}
                      scheduleData={scheduleData}
                      afterOpn={afterOpn}
                      setAssignShiftError={setAssignShiftError}
                      handleLoading={handleLoading}
                    />
                  </DialogContent>
                </Dialog>
              ) : (
                <></>
              )}
            </Paper>
            <Snackbar
              open={!!assignShiftError}
              autoHideDuration={6000}
              onClose={() => setAssignShiftError('')}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <Alert
                onClose={() => setAssignShiftError('')}
                severity='error'
                sx={{ width: '100%' }}
              >
                {assignShiftError}
              </Alert>
            </Snackbar>
          </main>
        </ThemeProvider>
      </div>
    </React.Fragment>
  );
}

// export default SM_Dashboard;
