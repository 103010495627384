import {
  getAllSchedulesRepo,
  getAllJobSitesRepo,
} from '../../repositories/ShiftManagement/Scheduler';
import {
  getAllSchedulesPending,
  getAllSchedulesFulfilled,
  getAllSchedulesRejected,
  getAllJobSitesPending,
  getAllJobSitesFulfilled,
  getAllJobSitesRejected,
} from '../../slices/ShiftManagement/Scheduler';

export const getAllShiftSchedules = (params) => {
  return async (dispatch) => {
    dispatch(getAllSchedulesPending());
    await getAllSchedulesRepo(params)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getAllSchedulesFulfilled(response?.data));
        } else {
          dispatch(getAllSchedulesRejected(response?.data));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(getAllSchedulesRejected(error.message));
        }
      });
  };
};

export const getAllJobSite = () => {
  return async (dispatch) => {
    dispatch(getAllJobSitesPending());
    await getAllJobSitesRepo()
      .then((response) => {
        if (response.status === 200) {
          dispatch(getAllJobSitesFulfilled(response?.data));
        } else {
          dispatch(getAllJobSitesRejected(response?.data));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(getAllJobSitesRejected(error.message));
        }
      });
  };
};
