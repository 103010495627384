import axios from "axios";
import { Urls } from "store/urls";

export const getAssetDashboardRepo = (params) => {
  return axios({
    method: "GET",
    url: Urls.GET_ASSET_DASHBOARD,
    params: params,
  });
};

export const getAssetStatusRepo = (params) => {
  return axios({
    method: "GET",
    url: Urls.GET_ALL_ASSET_STATUS,
  });
};

export const getAssetConditionCountRepo = (params) => {
  return axios({
    method: "GET",
    url: Urls.GET_ASSET_CONDITION_COUNT,
    params: params,
  });
};

export const getConditionCountRepo = (params) => {
  return axios({
    method: "GET",
    url: Urls.GET_CONDITION_COUNT,
    params: params,
  });
};
