import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
  Snackbar,
} from '@material-ui/core';
import {
  parse,
  // isEqual,
  // startOfDay,
  // addDays,
  // isSameMonth,
  // startOfToday,
  // isSameYear,
  // isSameWeek,
  // startOfWeek,
  // startOfMonth,
} from 'date-fns';
import { MoreVert } from '@material-ui/icons';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Tooltip from '@material-ui/core/Tooltip';
import ShiftInfo from './ShiftInfo';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import ScheduleIcon from '@material-ui/icons/Schedule';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useDasboardStyles } from './Style';
import axios from 'axios';
import { ShowUnscheduledEmpsContext } from './index';
// import EditShiftDialog from "./EditShift";
import { Alert } from '@material-ui/lab';
import AssignShiftDialog from './AssignShift';
import moment from 'moment';
import LeaveInfo from './LeaveInfo';
// import { useSelector } from 'react-redux';
import { getZoneTimeInLocalTime } from 'utils';

const shiftCardBG = {
  'Shift in Progress': '#eee',
  'Lunch Break': '#006dbc',
  'Scheduled Shift': '#e0eaf8',
  Completed: '#e5f5e7',
};

const headerHrs = [
  '12 AM',
  '1 AM',
  '2 AM',
  '3 AM',
  '4 AM',
  '5 AM',
  '6 AM',
  '7 AM',
  '8 AM',
  '9 AM',
  '10 AM',
  '11 AM',
  '12 PM',
  '1 PM',
  '2 PM',
  '3 PM',
  '4 PM',
  '5 PM',
  '6 PM',
  '7 PM',
  '8 PM',
  '9 PM',
  '10 PM',
  '11 PM',
];

const weekHeaderList = [0, 1, 2, 3, 4, 5, 6];

const totalDaysInMonth = new Date(
  new Date().getFullYear(),
  new Date().getMonth() + 1,
  0
).getDate();
const monthHeaderList = new Array(totalDaysInMonth)
  .fill(1)
  .map((_, index) => index);

function SJobSiteCard(props) {
  const classes = useDasboardStyles();
  const [row, setSiteRow] = useState(props.siteRow);
  const [siteOpen, setSiteOpen] = useState(true);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteShiftId, setDeleteShiftId] = useState('');
  const [openEdit, setOpenEdit] = useState(false);
  const [anchorEditOpt, setAnchorEditOpt] = useState(null);
  const openEditOpt = Boolean(anchorEditOpt);
  const [editData, setEditData] = useState({});
  const [openDeleteShift, setOpenDeleteShift] = useState(false);
  const [shiftDeleteSuccess, setShiftDeleteSuccess] = useState(false);

    const check = (type, index, startTime, timeZone) => {
    const zoned_start_time = getZoneTimeInLocalTime(startTime, timeZone);
    switch (type) {
      case 'month':
        return zoned_start_time.getDate() - 1 === index;
      case 'week': {
        const checkDay =
          zoned_start_time.getDay() - 1 >= 0
            ? zoned_start_time.getDay() - 1
            : 6;

        return checkDay === index;
      }
      default:
        return true;
    }
  };
  const handleOpenEditOpt = (event, row, index) => {
    for (let shift of row?.shift) {
      if (shift) {
        if (check(props.selectedScheduleRange, index, shift.start_time, shift.timezone)) {
          setAnchorEditOpt(event.currentTarget);
          setEditData({ ...shift, pid: props.id, jid: row._id });
        }
      }
    }

  };
  const handleCloseEditOpt = () => {
    setAnchorEditOpt(null);
  };
  const stringToDate = (date) => {
    const currentDate = new Date(date);
    if (currentDate.toString() !== 'Invalid Date') {
      return currentDate;
    }
    return parse(date, 'h:m aaa', new Date());
  };
  // // console.log(props);

  const handleShiftDelete = () => {
    handleCloseEditOpt();
    setOpenDeleteShift(true);
  };

  const handleDeleteDialogClose = () => {
    setOpenDeleteShift(false);
  };

  const calculateDayIndex = (shifts, index) => {
    if (shifts.length > 0) {
      for (let shift of shifts) {
        const start_date = getZoneTimeInLocalTime(
          shift.start_time,
          shift.timezone
        );
        const checkDay =
          start_date.getDay() - 1 >= 0 ? start_date.getDay() - 1 : 6;

        if (checkDay === index) {
          const end_date = getZoneTimeInLocalTime(
            shift.end_time,
            shift.timezone
          );
          const sstart = moment(start_date).format('hh:mm a');
          const send = moment(end_date).format('hh:mm a');
          return {
            time: sstart + ' - ' + send,
            jobsiteId: shift.jobsite,
            shiftId: shift._id,
          };
        }
      }
    }
    return {};
  };

  const calculateDateInMonthIndex = (shifts, index) => {
    if (shifts.length > 0) {
      for (let shift of shifts) {
        const date = getZoneTimeInLocalTime(shift.start_time, shift.timezone);
        const end_date = getZoneTimeInLocalTime(shift.end_time, shift.timezone);
        if (date.getDate() === index) {
          const sstart = moment(date).format('hh:mm a');
          const send = moment(end_date).format('hh:mm a');
          return {
            time: sstart + ' - ' + send,
            jobsiteId: shift.jobsite,
            shiftId: shift._id,
          };
        }
      }
    }
    return {};
  };

  const checkLeaveForMonth = (leaves, index) => {
    let leaveName = null;
    if (leaves) {
      leaveName = leaves.find((leave) => {
        let frmDay = moment(leave.from).utc().format('DD');
        let toDay = moment(leave.to).utc().format('DD');
        if (
          parseInt(frmDay) <= parseInt(index + 1) &&
          parseInt(toDay) >= parseInt(index + 1)
        )
          return true;

        return false;
      });
    }
    return leaveName;
  };

  const checkLeaveForWeek = (leaves, index) => {
    let leaveName = null;
    let weekDay = parseInt(moment(props.weeks[index]).utc().format('DD'));
    let frmDay;
    if (leaves) {
      leaveName = leaves.find((leave) => {
        frmDay = moment(leave.from).utc().format('DD');
        let toDay = moment(leave.to).utc().format('DD');
        if (parseInt(frmDay) <= weekDay && parseInt(toDay) >= weekDay)
          return true;
        return false;
      });
    }
    return leaveName;
  };

  const showTasks = (row, jobsite) => {
    let shiftData = null,
      shiftStart = 0,
      shiftEnd = 0,
      shiftSpan = 0,
      sstart = 0,
      send = 0,
      punchin = null,
      shiftType = null,
      shiftFound = false,
      cols = [],
      status = '',
      require_approval = false;
    if (props.selectedScheduleRange === 'today') {
      cols = headerHrs;
    } else if (props.selectedScheduleRange === 'month') {
      cols = monthHeaderList;
    } else {
      cols = weekHeaderList;
    }
    if (row.shift.length > 0) {
      shiftData = row.shift[row.shift.length - 1];
      shiftFound = true;
      sstart = moment(shiftData.start_time).utc().format('hh:mm A');
      send = moment(shiftData.end_time).utc().format('hh:mm A');

      status = shiftData.status.toLowerCase();
      require_approval = shiftData.require_approval;
      if (props.selectedScheduleRange === 'today') {
        let zoned_start_time = getZoneTimeInLocalTime(
          shiftData.start_time,
          shiftData.timezone
        );
        let zoned_end_time = getZoneTimeInLocalTime(
          shiftData.end_time,
          shiftData.timezone
        );
        shiftStart = parseInt(moment(zoned_start_time).format('HH'));
        shiftEnd = parseInt(moment(zoned_end_time).format('HH'));
        shiftSpan = shiftEnd - shiftStart;
      }
    }

    if (row.attendances.length === 1) {
      punchin = stringToDate(row.attendances[0].punch_details[0].punchin_time);
      // punchin.setMinutes(punchin.getMinutes() + 330);
      punchin.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      });
      shiftType = 'Shift in Progress';
    }

    return cols.map((hrs, index) => {
      if (props.selectedScheduleRange === 'today') {
        let tableCell = <></>;
        // let shiftsNLeaves = dateCompareAndSort(
        //   row.shift[row.shift.length - 1],
        //   row.leaves[row.leaves.length - 1]
        // );
        let shiftsNLeaves = row.leaves[row.leaves.length - 1]
          ? { ...row.leaves[row.leaves.length - 1], objType: 'leave' }
          : row.shift[row.shift.length - 1]
          ? { ...row.shift[row.shift.length - 1], objType: 'shift' }
          : null;
        if (!shiftsNLeaves)
          return (
            <TableCell
              style={{
                fontSize: '11px',
                padding: '20px 0px 20px 0',
                borderWidth: '1px',
                borderColor: '#aaaaaa',
                borderStyle: 'solid',
                borderSpacing: '0',
                borderCollapse: 'collapse',
                width: props.cellWidth + 'px',
                margin: '2px',
                height: '70px',
              }}
            />
          );

        const objType = shiftsNLeaves.objType;
        if (objType === 'leave') {
          let leaveDuration = row.leaves[0].duration.split(':');
          let leaveStart,
            leaveEnd,
            cellWidth = 8,
            duration = Math.round(parseFloat(leaveDuration[0])),
            leaveType = row.leaves[0].type;

          if (shiftsNLeaves.from_time) {
            leaveStart = parseInt(shiftsNLeaves.from_time);
            leaveEnd = parseInt(shiftsNLeaves.to_time);

            if (duration > 8) {
              cellWidth = 8;
            } else cellWidth = duration;
          } else {
            leaveStart = 9;
            leaveEnd = 17;
          }
          let leave = row.leaves[0];
          const leaveStatus = leave.status.toLowerCase();

          if (index >= leaveStart && index < leaveEnd && shiftsNLeaves.status) {
            if (index === leaveStart) {
              tableCell = (
                <Tooltip
                  title={<LeaveInfo leaves={{ ...leave, leaveType }} />}
                  arrow
                  placement='bottom'
                >
                  <TableCell
                    colSpan={cellWidth}
                    style={{
                      padding: 0,
                      width: props.cellWidth * duration + 'px',
                    }}
                  >
                    <div
                      style={{
                        fontSize: '13px',
                        height: '54px',
                        margin: '2px',
                        borderSpacing: '0',
                        borderCollapse: 'collapse',
                        borderWidth: '0 0 0 4px',
                        padding: '2px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        overflow: 'hidden',
                        borderColor: '#F75D59', // work on month and week and showing status
                        borderStyle: 'solid',
                        backgroundColor: '#FFCCCB',
                        color: '#F75D59',
                        height: '70px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-evenly',
                          flexWrap: 'wrap',
                          width: '100%',
                        }}
                      >
                        <div>
                          <p>
                            {leaveStatus === 'pending' ? (
                              <b>Applied for Leave</b>
                            ) : (
                              <b>On Leave</b>
                            )}
                          </p>
                        </div>
                        <div>
                          <p style={{ textTransform: 'capitalize' }}>
                            <b>Leave Type : </b>
                            {leaveType}
                          </p>
                          <p style={{ textTransform: 'capitalize' }}>
                            <b>Status : </b>
                            {leaveStatus}
                          </p>
                        </div>
                      </div>
                    </div>
                  </TableCell>
                </Tooltip>
              );
            } else {
              if (index > leaveStart && index <= leaveEnd) tableCell = <></>;
            }
          } else {
            tableCell = (
              <TableCell
                key={index}
                style={{
                  fontSize: '11px',
                  padding: '20px 0px 20px 0',
                  borderWidth: '1px',
                  borderColor: '#aaaaaa',
                  borderStyle: 'solid',
                  borderSpacing: '0',
                  borderCollapse: 'collapse',
                  width: props.cellWidth + 'px',
                  margin: '2px',
                  height: '70px',
                }}
              >
                {' '}
              </TableCell>
            );
          }
          return tableCell;
        } else if (objType === 'shift') {
          let shiftData = shiftsNLeaves;
          shiftFound = true;
          let zoned_start_time = getZoneTimeInLocalTime(
            shiftData.start_time,
            shiftData.timezone
          );
          let zoned_end_time = getZoneTimeInLocalTime(
            shiftData.end_time,
            shiftData.timezone
          );
          sstart = moment(
            getZoneTimeInLocalTime(shiftData.start_time, shiftData.timezone)
          ).format('hh:mm A');
          send = moment(
            getZoneTimeInLocalTime(shiftData.end_time, shiftData.timezone)
          ).format('hh:mm A');

          status = shiftData.status.toLowerCase();
          require_approval = shiftData.require_approval;

          shiftStart = parseInt(moment(zoned_start_time).format('HH'));
          shiftEnd = parseInt(moment(zoned_end_time).format('HH'));
          shiftSpan = shiftEnd - shiftStart;

          if (index >= shiftStart && index < shiftEnd) {
            if (index === shiftStart && shiftData) {
              tableCell = (
                <Tooltip
                  title={
                    <ShiftInfo
                      shifts={row.shift}
                      index={index}
                      attendances={row.attendances}
                      location={jobsite?.address}
                      shiftStatus={
                        shiftData?.jobsite !== jobsite?._id ? '' : status
                      }
                    />
                  }
                  key={index}
                  arrow
                  placement='bottom'
                >
                  <TableCell
                    key={index}
                    colSpan={shiftSpan}
                    style={{
                      padding: 0,
                      width: props.cellWidth * shiftSpan + 'px',
                    }}
                  >
                    <div
                      style={{
                        fontSize: '13px',
                        background: shiftCardBG[shiftType],

                        margin: '2px',
                        borderSpacing: '0',
                        borderCollapse: 'collapse',
                        borderWidth: '0 0 0 4px',
                        padding: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        overflow: 'hidden',
                        borderColor:
                          shiftData.jobsite !== jobsite._id
                            ? '#000'
                            : require_approval
                            ? status === 'accepted' || status === 'approved'
                              ? '#489E5A'
                              : status === 'rejected'
                              ? '#F75D59'
                              : '#263F9D'
                            : '#489E5A',
                        borderStyle: 'solid',
                        backgroundColor:
                          shiftData.jobsite !== jobsite._id
                            ? 'Gainsboro'
                            : require_approval
                            ? status === 'accepted' || status === 'approved'
                              ? '#E5F5E7'
                              : status === 'rejected'
                              ? '#FFCCCB'
                              : '#E0EAF8'
                            : '#E5F5E7',
                        color:
                          shiftData.jobsite !== jobsite._id
                            ? '#000'
                            : require_approval
                            ? status === 'accepted' || status === 'approved'
                              ? '#489E5A'
                              : status === 'rejected'
                              ? '#F75D59'
                              : '#263F9D'
                            : '#489E5A',
                        height: '70px',
                      }}
                    >
                      <div className='mr-1'>
                        {shiftData.jobsite !== jobsite._id ? (
                          <></>
                        ) : props.cellWidth * shiftSpan > 45 ? (
                          require_approval ? (
                            status === 'pending' ? (
                              <ScheduleIcon />
                            ) : status === 'accepted' ||
                              status === 'approved' ? (
                              <CheckCircleOutlineIcon />
                            ) : (
                              <HighlightOffIcon />
                            )
                          ) : (
                            <></>
                          )
                        ) : (
                          <></>
                        )}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          alignItems: 'center',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <p>
                          <b>
                            {sstart} - {send}
                          </b>
                        </p>
                        {require_approval ? (
                          <p>
                            <span style={{ textTransform: 'capitalize' }}>
                              Status <b>: </b>
                              {status}
                            </span>
                          </p>
                        ) : (
                          <></>
                        )}
                        {punchin ? (
                          <p>
                            In: <b> {punchin} </b>- Out: <b>--:-- </b>
                          </p>
                        ) : (
                          ''
                        )}
                      </div>
                      <div>
                        <IconButton
                          style={{ padding: '3px' }}
                          aria-label='more menu'
                          component='span'
                          onClick={(e) => handleOpenEditOpt(e, row, index)}
                        >
                          <MoreVert />
                        </IconButton>
                      </div>
                    </div>
                  </TableCell>
                </Tooltip>
              );
            } else {
              if (index > shiftStart && index <= shiftEnd) tableCell = <></>;
            }
          } else {
            tableCell = (
              <TableCell
                key={index}
                style={{
                  fontSize: '11px',
                  padding: '20px 0px 20px 0',
                  borderWidth: '1px',
                  borderColor: '#aaaaaa',
                  borderStyle: 'solid',
                  borderSpacing: '0',
                  borderCollapse: 'collapse',
                  width: props.cellWidth + 'px',
                  height: '70px',
                  margin: '2px',
                }}
              >
                {' '}
              </TableCell>
            );
          }
          return tableCell;
        } else return tableCell;
      } else if (props.selectedScheduleRange === 'month') {
        const leaveData = checkLeaveForMonth(row.leaves, index);
        if (leaveData) {
          let cellWidth = 1,
            leaveType = leaveData.type;
          const leaveStatus = leaveData.status.toLowerCase();

          return (
            <Tooltip
              title={<LeaveInfo leaves={{ ...leaveData, leaveType }} />}
              arrow
              placement='bottom'
            >
              <TableCell
                colSpan={cellWidth}
                style={{
                  padding: 0,
                  width: props.cellWidth + 'px',
                }}
              >
                <div
                  style={{
                    fontSize: '13px',
                    height: '70px',
                    padding: '5px',
                    borderWidth: '0 0 0 4px',
                    padding: '5px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden',
                    margin: '2px',
                    borderColor: '#F75D59', // work on month and week and showing status
                    borderStyle: 'solid',
                    backgroundColor: '#FFCCCB',
                    color: '#F75D59',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-evenly',
                      flexWrap: 'wrap',
                      width: '100%',
                    }}
                  >
                    <div>
                      <p>
                        {leaveStatus === 'pending' ? (
                          <b>Applied for Leave</b>
                        ) : (
                          <b>On Leave</b>
                        )}
                      </p>
                    </div>
                    <div>
                      <p style={{ textTransform: 'capitalize' }}>
                        <b>Leave Type : </b>
                        {leaveType}
                      </p>
                      <p style={{ textTransform: 'capitalize' }}>
                        <b>Status : </b>
                        {leaveStatus}
                      </p>
                    </div>
                  </div>
                </div>
              </TableCell>
            </Tooltip>
          );
        }
        const data = calculateDateInMonthIndex(row.shift, index + 1);
        if (!data.time)
          return (
            <TableCell
              style={{
                fontSize: '11px',
                padding: '20px 0px 20px 0',
                borderWidth: '1px',
                borderColor: '#aaaaaa',
                borderStyle: 'solid',
                borderSpacing: '0',
                borderCollapse: 'collapse',
                width: props.cellWidth + 'px',
                margin: '2px',
                height: '70px',
              }}
            />
          );

        return (
          <Tooltip
            title={
              <ShiftInfo
                shifts={row.shift}
                index={index}
                attendances={row.attendances}
                location={jobsite?.address}
                shiftStatus={shiftData?.jobsite !== jobsite?._id ? '' : status}
                type='month'
              />
            }
            key={index}
            arrow
            placement='bottom'
          >
            <TableCell
              style={{
                width: props.cellWidth + 'px',
                borderCollapse: 'collapse',
                fontSize: '11px',
                borderColor: '#aaaaaa',
                padding: '1px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  // flexWrap: "wrap",
                  borderWidth: '0 0 0 4px',
                  borderStyle: 'solid',
                  borderSpacing: '0',
                  height: '70px',
                  borderColor:
                    shiftData.jobsite !== jobsite._id
                      ? 'gray'
                      : require_approval
                      ? status === 'accepted' || status === 'approved'
                        ? '#489E5A'
                        : status === 'rejected'
                        ? '#F75D59'
                        : '#263F9D'
                      : '#489E5A',
                  borderStyle: 'solid',
                  backgroundColor:
                    shiftData.jobsite !== jobsite._id
                      ? 'Gainsboro'
                      : require_approval
                      ? status === 'accepted' || status === 'approved'
                        ? '#E5F5E7'
                        : status === 'rejected'
                        ? '#FFCCCB'
                        : '#E0EAF8'
                      : '#E5F5E7',
                  color:
                    shiftData.jobsite !== jobsite._id
                      ? '#000'
                      : require_approval
                      ? status === 'accepted' || status === 'approved'
                        ? '#489E5A'
                        : status === 'rejected'
                        ? '#F75D59'
                        : '#263F9D'
                      : '#489E5A',
                }}
              >
                <div>
                  {shiftData.jobsite !== jobsite._id ? (
                    <></>
                  ) : require_approval ? (
                    status === 'pending' ? (
                      <ScheduleIcon />
                    ) : status === 'accepted' || status === 'approved' ? (
                      <CheckCircleOutlineIcon />
                    ) : (
                      <HighlightOffIcon />
                    )
                  ) : (
                    <></>
                  )}
                </div>
                <div>
                  <p>
                    <b> {shiftFound && data.time}</b>
                  </p>

                  {require_approval ? (
                    <p>
                      <span style={{ textTransform: 'capitalize' }}>
                        Status <b>: </b>
                        {status}
                      </span>
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
                <div>
                  <IconButton
                    style={{ padding: '3px' }}
                    aria-label='more menu'
                    component='span'
                    onClick={(e) => handleOpenEditOpt(e, row, index)}
                  >
                    <MoreVert />
                  </IconButton>
                </div>
              </div>
            </TableCell>
          </Tooltip>
        );
      } else {
        const leaveData = checkLeaveForWeek(row.leaves, index+1);
        if (leaveData) {
          let shiftsNLeaves = leaveData;
          let cellWidth = 1,
            leaveType = leaveData.type,
            leaveStart = parseInt(
              moment(shiftsNLeaves.from).utc().format('DD')
            ),
            leaveEnd = parseInt(moment(shiftsNLeaves.to).utc().format('DD'));

          const leaveStatus = leaveData.status.toLowerCase();

          return (
            <Tooltip
              title={<LeaveInfo leaves={{ ...leaveData, leaveType }} />}
              arrow
              placement='bottom'
            >
              <TableCell
                colSpan={cellWidth}
                style={{
                  padding: 0,
                  width: props.cellWidth + 'px',
                }}
              >
                <div
                  style={{
                    fontSize: '13px',
                    height: '70px',
                    padding: '5px',
                    borderWidth: '0 0 0 4px',
                    padding: '5px',
                    display: 'flex',
                    alignItems: 'center',
                    margin: '2px',
                    justifyContent: 'center',
                    overflow: 'hidden',
                    borderColor: '#F75D59', // work on month and week and showing status
                    borderStyle: 'solid',
                    backgroundColor: '#FFCCCB',
                    color: '#F75D59',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-evenly',
                      flexWrap: 'wrap',
                      width: '100%',
                    }}
                  >
                    <div>
                      <p>
                        {leaveStatus === 'pending' ? (
                          <b>Applied for Leave</b>
                        ) : (
                          <b>On Leave</b>
                        )}
                      </p>
                    </div>
                    <div>
                      <p style={{ textTransform: 'capitalize' }}>
                        <b>Leave Type : </b>
                        {leaveType}
                      </p>
                      <p style={{ textTransform: 'capitalize' }}>
                        <b>Status : </b>
                        {leaveStatus}
                      </p>
                    </div>
                  </div>
                </div>
              </TableCell>
            </Tooltip>
          );
        }
        const data = calculateDayIndex(row.shift, index);
        if (!data.time)
          return (
            <TableCell
              key={index}
              style={{
                fontSize: '11px',
                height: '70px',
                padding: '20px 0px 20px 0',
                borderWidth: '1px',
                borderColor: '#aaaaaa',
                margin: '2px',
                borderStyle: 'solid',
                borderSpacing: '0',
                borderCollapse: 'collapse',
                width: props.cellWidth - 4 + 'px',
              }}
            />
          );
        return (
          <Tooltip
            title={
              <ShiftInfo
                shifts={row.shift}
                index={index}
                attendances={row.attendances}
                location={jobsite?.address}
                shiftStatus={shiftData?.jobsite !== jobsite?._id ? '' : status}
                type='week'
              />
            }
            key={index}
            arrow
            placement='bottom'
          >
            <TableCell
              style={{
                width: props.cellWidth + 'px',
                borderCollapse: 'collapse',
                fontSize: '11px',
                borderColor: '#aaaaaa',
                padding: '1px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  // flexWrap: "wrap",
                  borderWidth: '0 0 0 4px',
                  borderStyle: 'solid',
                  borderSpacing: '0',
                  height: '70px',
                  borderColor:
                    shiftData.jobsite !== jobsite._id
                      ? 'gray'
                      : require_approval
                      ? status === 'accepted' || status === 'approved'
                        ? '#489E5A'
                        : status === 'rejected'
                        ? '#F75D59'
                        : '#263F9D'
                      : '#489E5A',
                  borderStyle: 'solid',
                  backgroundColor:
                    shiftData.jobsite !== jobsite._id
                      ? 'Gainsboro'
                      : require_approval
                      ? status === 'accepted' || status === 'approved'
                        ? '#E5F5E7'
                        : status === 'rejected'
                        ? '#FFCCCB'
                        : '#E0EAF8'
                      : '#E5F5E7',
                  color:
                    shiftData.jobsite !== jobsite._id
                      ? '#000'
                      : require_approval
                      ? status === 'accepted' || status === 'approved'
                        ? '#489E5A'
                        : status === 'rejected'
                        ? '#F75D59'
                        : '#263F9D'
                      : '#489E5A',
                }}
              >
                <div>
                  {shiftData.jobsite !== jobsite._id ? (
                    <></>
                  ) : require_approval ? (
                    status === 'pending' ? (
                      <ScheduleIcon />
                    ) : status === 'accepted' || status === 'approved' ? (
                      <CheckCircleOutlineIcon />
                    ) : (
                      <HighlightOffIcon />
                    )
                  ) : (
                    <></>
                  )}
                </div>
                <div>
                  <p>
                    <b> {shiftFound && data.time}</b>
                  </p>
                  {require_approval ? (
                    <p>
                      <span style={{ textTransform: 'capitalize' }}>
                        Status <b>: </b>
                        {status}
                      </span>
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
                <div>
                  <IconButton
                    style={{ padding: '3px' }}
                    aria-label='more menu'
                    component='span'
                    onClick={(e) => handleOpenEditOpt(e, row, index)}
                  >
                    <MoreVert />
                  </IconButton>
                </div>
              </div>
            </TableCell>
          </Tooltip>
        );
      }
    });
  };
  const handleShiftEditOpen = () => {
    setOpenEdit(true);
    handleCloseEditOpt();
  };

  const handleShiftEditClose = () => {
    setOpenEdit(false);
  };

  useEffect(() => {
    setSiteRow(props.siteRow);
  }, [props.selectedScheduleRange, props.siteRow]); //eslint-disable-line react-hooks/exhaustive-deps

  const deleteShift = async (deleteShiftId) => {
    props.handleLoading(true);
    const response = await axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/shift/delete/` + deleteShiftId,
      {
        headers: {
          token: localStorage.getItem('token'),
        },
      }
    );
    return response;
  };

  const onShiftDelete = async () => {
    try {
      const response = await deleteShift(deleteShiftId);
      if (response.status === 200) {
        props.afterOpn();
      }
    } catch (e) {
      if (e.response) {
        console.log('Error >', e.response);
      }
    } finally {
      props.handleLoading(false);
      setShowDeleteDialog(false);
      setDeleteShiftId('');
    }
  };

  const deleteSingleShift = async () => {
    setShiftDeleteSuccess(false);
    try {
      const response = await deleteShift(editData._id);
      if (response.status === 200) {
        props.afterOpn();
        setShiftDeleteSuccess(true);
        setEditData({});
      }
    } catch (e) {
      if (e.response) {
        console.log('Error >', e.response);
        props.handleLoading(false);
      }
    }
  };
  try {
    return (
      <React.Fragment>
        <ShowUnscheduledEmpsContext.Consumer>
          {(ShowUnscheduledEmpsContext) => {
            return (
              <>
                <Dialog
                  open={showDeleteDialog}
                  onClose={() => setShowDeleteDialog(false)}
                  aria-labelledby='alert-dialog-title'
                  aria-describedby='alert-dialog-description'
                >
                  <DialogTitle id='alert-dialog-title'>
                    Delete Shift
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                      Are you sure you want to delete the shift?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setShowDeleteDialog(false)}>
                      Cancel
                    </Button>
                    <Button onClick={onShiftDelete} autoFocus>
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
                <TableRow
                  key={row._id}
                  sx={{ '& > *': { borderBottom: 'unset' } }}
                >
                  <TableCell
                    component='th'
                    scope='row'
                    style={{ padding: '0px' }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '0px',
                        alignItems: 'center',
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div
                          style={{
                            width: '210px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Typography
                            style={{
                              textAlign: 'left',
                              marginLeft: '16px',
                            }}
                          >
                            Site Total :
                          </Typography>
                          <Typography
                            style={{
                              fontWeight: '600',
                              textAlign: 'left',
                              marginLeft: '16px',
                            }}
                          >
                            {row.siteTotal}
                          </Typography>
                        </div>
                        <div> Site Name: {row.jobSite_title}</div>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                          aria-label='expand row'
                          size='small'
                          onClick={() => setSiteOpen(!siteOpen)}
                        >
                          {siteOpen ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ padding: 0 }}>
                    <Collapse in={siteOpen} timeout='auto' unmountOnExit>
                      <Box sx={{ margin: 1 }}>
                        <Table
                          className={classes.table}
                          size='small'
                          aria-label='purchases'
                          style={{ tableLayout: 'fixed' }}
                        >
                          <TableBody>
                            {row.assignedEmployees ? (
                              row.assignedEmployees.map((empRow, index) => {
                                let showRow = ShowUnscheduledEmpsContext
                                  ? empRow.shift.length === 0
                                    ? true
                                    : false
                                  : true;
                                return showRow ? (
                                  <TableRow key={index}>
                                    <TableCell
                                      component='th'
                                      scope='row'
                                      style={{
                                        padding: '0px',
                                        width: '200px',
                                        maxWidth: '200px',
                                        minWidth: '200px',
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          padding: '0px',
                                          alignItems: 'center',
                                          width: '200px',
                                        }}
                                      >
                                        <Typography
                                          className={classes.colorBlue}
                                          style={{
                                            fontWeight: '600',
                                            textAlign: 'left',
                                            marginLeft: '36px',
                                          }}
                                        >
                                          {empRow.user.first_name}{' '}
                                          {empRow.user.last_name}
                                        </Typography>
                                      </div>
                                    </TableCell>
                                    {showTasks(empRow, row)}
                                  </TableRow>
                                ) : null;
                              })
                            ) : (
                              <></>
                            )}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            );
          }}
        </ShowUnscheduledEmpsContext.Consumer>
        {openDeleteShift ? (
          <>
            <Dialog
              open={openDeleteShift}
              onClose={handleDeleteDialogClose}
              aria-labelledby='alert-dialog-title'
              aria-describedby='alert-dialog-description'
            >
              <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                  <Typography
                    variant='h5'
                    style={{
                      color: 'black',
                    }}
                  >
                    Are you sure you want to
                    <span style={{ color: 'red' }}> delete</span> this shift ?
                  </Typography>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDeleteDialogClose} color='primary'>
                  No
                </Button>
                <Button
                  onClick={() => {
                    deleteSingleShift();
                    handleDeleteDialogClose();
                  }}
                  color='primary'
                  autoFocus
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </>
        ) : (
          <></>
        )}
        {openEditOpt ? (
          <Menu
            id='basic-menu'
            anchorEl={anchorEditOpt}
            open={openEditOpt}
            onClose={handleCloseEditOpt}
            MenuListProps={{
              'aria-labelledby': 'edit-options-button',
            }}
          >
            <MenuItem onClick={handleShiftEditOpen}>Edit</MenuItem>
            <MenuItem onClick={handleShiftDelete}>Delete</MenuItem>
          </Menu>
        ) : (
          <></>
        )}

        {openEdit ? (
          <Dialog
            open={openEdit}
            maxWidth='false'
            fullWidth
            keepMounted
            aria-describedby='alert-dialog-slide-description'
            PaperProps={{
              style: {
                position: 'absolute',
                margin: '0px',
                padding: '0px',
                right: '0px',
                minHeight: '111vh',
                top: '0px',
                borderRadius: '0px',
                width: '700px',
              },
            }}
          >
            <DialogContent
              style={{
                margin: '0px',
                padding: '0px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <AssignShiftDialog
                closeForm={handleShiftEditClose}
                shiftData={editData}
                key={editData._id}
                handleLoading={props.handleLoading}
                afterOpn={props.afterOpn}
                operation={'edit'}
                readOnly={false}
                setAssignShiftError={props.setAssignShiftError}
                scheduleData={props.scheduleData}
              />
            </DialogContent>
          </Dialog>
        ) : (
          <></>
        )}
        <Snackbar
          open={!!shiftDeleteSuccess}
          autoHideDuration={2000}
          onClose={() => setShiftDeleteSuccess(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setShiftDeleteSuccess(false)}
            severity='success'
            sx={{ width: '100%' }}
          >
            Shift deleted successfully!
          </Alert>
        </Snackbar>
      </React.Fragment>
    );
  } catch (e) {
    // console.log(e);
  }
}

export default SJobSiteCard;
