import * as React from "react";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import "@fontsource/roboto";
import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { useAssetStyles } from "../Style";
import AllAssetContainer from "./index";

const AssetManageContainer = (props) => {
  let theme = createTheme();
  theme = responsiveFontSizes(theme);
  const classes = useAssetStyles();
  const [showUserAssets] = React.useState(true);

  return (
    <React.Fragment>
      <div className="root">
        <ThemeProvider theme={theme}>
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: props.isDrawerOpen,
            })}
          >
            {showUserAssets && (
              <div>
                <Typography
                  style={{
                    textAlign: "left",
                    marignBottom: "20px",
                    minWidth: "230px",
                  }}
                >
                  Home  {">"} Employee Management {">"} Asset Management
                </Typography>

                <AllAssetContainer />
              </div>
            )}
          </main>
        </ThemeProvider>
      </div>
    </React.Fragment>
  );
};

export default AssetManageContainer;
