import React, { useEffect, useState } from "react";
import Sidebar from "pages/sidebar/Sidebar";
import Select from "react-select";
import {
  getAllAssetConditon,
  createAssetConditon,
  updateAssetCondition,
} from "store/actions/AssetManagement/assetCondition";
import {
  clearCreateAssetCondition,
  clearUpdateAssetCondition,
} from "store/slices/AssetManagement/assetCondition";
import { useDispatch, useSelector } from "react-redux";

const AddAsCondiSidebar = ({
  conditionInitialValues,
  setConditionInitialValues,
  setIsOpenForm,
  categoryList,
}) => {
  const dispatch = useDispatch();
  const { assetCondition } = useSelector((state) => state);
  let initialCategory = categoryList.find(
    (item) => item._id == conditionInitialValues?.category_id
  );
  const [selectedCategory, setSelectedCategory] = useState(
    initialCategory ? initialCategory : null
  );
  const [conditionName, setConditionName] = useState(
    conditionInitialValues ? conditionInitialValues?.name : ""
  );
  const onChangeCategory = (val) => {
    setSelectedCategory(val);
  };

  const handleSubmit = () => {
    if (conditionName) {
      let params = {};
      params.name = conditionName;
      // params.category_id = selectedCategory._id;
      if (conditionInitialValues?._id) {
        params._id = conditionInitialValues?._id;
        dispatch(updateAssetCondition(params));
      } else {
        dispatch(createAssetConditon(params));
      }
    } else {
      //validation errors
    }
  };

  useEffect(() => {
    if (assetCondition?.createAssetCondition?.fulfilled) {
      dispatch(getAllAssetConditon());
      setIsOpenForm(false);
      dispatch(clearCreateAssetCondition());
    }
  }, [assetCondition?.createAssetCondition]);
  useEffect(() => {
    if (assetCondition?.updateAssetCondition?.fulfilled) {
      dispatch(getAllAssetConditon());
      setIsOpenForm(false);
      dispatch(clearUpdateAssetCondition());
    }
  }, [assetCondition?.updateAssetCondition]);

  return (
    <Sidebar
      sidebarHandler={() => setIsOpenForm(false)}
      modalTitle={
        conditionInitialValues?._id
          ? "Update Asset Conditions"
          : "Add Asset Conditions"
      }
    >
      <div className="h-screen">
        <div>
          <p className="text-blue-900 font-semibold">Condition</p>
          <input
            type="text"
            className="bg-gray-100 border-2 rounded-md outline-0 px-2 py-3 w-full font-bold mt-1 focus:ring focus:outline-none"
            onChange={(e) => {
              setConditionName(e.target.value);
            }}
            value={conditionName}
          />
        </div>
        {/* <div className="mt-4">
          <p className="text-blue-900 font-semibold">Category</p>
          <Select
            placeholder="SelectCategory"
            className=" rounded-md outline-0 px-2 py-3 w-full font-bold mt-1 focus:ring focus:outline-none"
            classNamePrefix="select"
            value={selectedCategory}
            options={categoryList}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option._id}
            onChange={(val) => onChangeCategory(val)}
            isClearable={true}
          />
        </div> */}
      </div>
      <div className="border-b-2 border-gray-300 my-5"></div>
      <div className="flex justify-end gap-5">
        <p
          className="py-1 px-3 font-bold border cursor-pointer border-blue-700 rounded"
          onClick={() => setIsOpenForm(false)}
        >
          Cancel
        </p>
        <p
          className="py-1 px-3 font-bold border cursor-pointer border-blue-700 rounded bg-indigo-700 text-gray-50"
          onClick={() => {
            handleSubmit();
          }}
        >
          Save
        </p>
      </div>
    </Sidebar>
  );
};

export default AddAsCondiSidebar;
