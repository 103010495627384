import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "pages/sidebar/Sidebar";
import { postAddAssetRemark } from "store/actions/AssetManagement/assetAssign";
import { clearPostAddAssetRemark } from "store/slices/AssetManagement/assetAssign";
const AssetRemarkSidebar = ({
  auth,
  assetRemark,
  assetRemarkHandler,
  data,
}) => {
  const dispatch = useDispatch();
  const { postAddAssetRemarkData } = useSelector((state) => state.assetAssign);

  const { register, handleSubmit, control, setValue } = useForm();
  const onSubmit = (obj) => {
    let paramsData = {};
    paramsData.asset_id = assetRemark;
    paramsData.remark = obj.remark;
    paramsData.user_id = auth.user._id;
    dispatch(postAddAssetRemark(paramsData));
  };

  useEffect(() => {
    if (postAddAssetRemarkData.fulfilled) {
      assetRemarkHandler();
      dispatch(clearPostAddAssetRemark());
    }
  }, [postAddAssetRemarkData]);

  return (
    <Sidebar
      sidebarHandler={() => assetRemarkHandler()}
      modalTitle="Assign Remark to Asset"
    >
      {data?.remark?.map((element) => {
        return (
          <div className="border p-3 rounded">
            <p className="text-lg font-bold mb-1">Neme Lastname (E123456789)</p>
            <p className="leading-5 text-gray-900 font-semibold">
              {element?.msg ? element?.msg : ""}
            </p>
            <p className="text-gray-600 mt-7">29-07-2023 10:00 AM</p>
          </div>
        );
      })}
      <div className="font-bold">
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <div className="mt-4">
            <p className="text-base">Remark</p>
            <textarea
              {...register("remark", { required: true })}
              className="bg-gray-100 border-2 rounded-md outline-0 px-2 py-3 w-full font-semibold mt-1 focus:ring focus:outline-none h-28"
              placeholder="Add a remark ... "
            />
          </div>

          <div className="border-b-2 border-gray-300 my-5"></div>
          <div className="flex justify-end gap-5">
            <p
              className="py-1 px-3 font-semibold border cursor-pointer border-blue-700 rounded"
              onClick={() => assetRemarkHandler()}
            >
              Cancel
            </p>
            <p
              className="py-1 px-3 font-semibold border cursor-pointer border-blue-700 rounded bg-indigo-700 text-gray-50"
              onClick={handleSubmit((data) => onSubmit(data))}
            >
              Save
            </p>
          </div>
        </form>
      </div>
    </Sidebar>
  );
};

export default AssetRemarkSidebar;
