import React, { useState, useEffect } from "react";
import Sidebar from "pages/sidebar/Sidebar";
import Select from "react-select";
import useAuth from "hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { getUsersByOrgId } from "store/actions/HrManagement/Dashboard";
import { postAssignAsset } from "store/actions/AssetManagement/assetAssign";
import { clearPostAssignAsset } from "store/slices/AssetManagement/assetAssign";

const AssignToSidebar = ({ data, assigntoSidebarHandler }) => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const { orgUsers } = useSelector((state) => state.dashboard);
  const { postAssignAssetData } = useSelector((state) => state.assetAssign);
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState();

  useEffect(() => {
    if (auth?.user?.org_id?._id) {
      const params = {
        page_number: 1,
        limit: "",
      };
      dispatch(getUsersByOrgId(auth.user.org_id._id, params));
    }
  }, []);

  useEffect(() => {
    if (orgUsers?.fulfilled) {
      setEmployeeList(orgUsers?.fulfilled);
    }
  }, [orgUsers]);

  const onSubmit = () => {
    let paramsData = {};
    paramsData.asset_id = data._id;
    paramsData.user_id = selectedEmployee._id;
    dispatch(postAssignAsset(paramsData));
  };

  useEffect(() => {
    if (postAssignAssetData?.fulfilled) {
      dispatch(clearPostAssignAsset());
      assigntoSidebarHandler();
    }
  }, [postAssignAssetData]);

  return (
    <Sidebar modalTitle="Replacement" sidebarHandler={assigntoSidebarHandler}>
      <div>
        <Select
          className="bg-gray-100  outline-0  w-full font-semibold"
          placeholder="Select Replacement "
          classNamePrefix="select"
          value={selectedEmployee}
          getOptionLabel={(option) => option.first_name}
          getOptionValue={(option) => option._id}
          options={employeeList?.data}
          onChange={(val) => setSelectedEmployee(val)}
        />
      </div>
      <div className="mt-4">
        <p className="text-base font-semibold mb-1 text-gray-800">
          Asset Remark:
        </p>
        <textarea
          className="bg-gray-100 border-2 rounded-md outline-0 px-2 py-3 w-full focus:ring focus:outline-none text-gray-900 h-32"
          value="Sample Text"
        />
      </div>
      <div className="border-b-2 border-gray-300 my-5"></div>
      <div className="flex justify-end gap-5">
        <p
          className="py-1 px-3 font-bold border cursor-pointer border-blue-700 rounded"
          onClick={assigntoSidebarHandler}
        >
          Cancel
        </p>
        <p
          className="py-1 px-3 font-bold border cursor-pointer border-blue-700 rounded bg-indigo-700 text-gray-50"
          onClick={() => {
            onSubmit();
          }}
        >
          Save
        </p>
      </div>
    </Sidebar>
  );
};

export default AssignToSidebar;
