import {
  getAllShiftTemplatesRepo,
  getAllShiftPositionsRepo,
  deleteShiftTemplatesRepo,
  updateShiftTemplatesRepo,
  getAllTagsRepo,
  getAllShiftTaskJobsRepo,
  getAllShiftProjectsRepo,
  getAllShiftJobSitesRepo,
  createShiftTemplatesRepo,
  searchShiftTemplateRepo,
} from '../../repositories/ShiftManagement/ShiftTemplates';
import {
  getShiftTemplatesPending,
  getShiftTemplatesFulfilled,
  getShiftTemplatesRejected,
  getAllShiftPositionsPending,
  getAllShiftPositionsFulfilled,
  getAllShiftPositionsRejected,
  deleteShiftTemplatePending,
  deleteShiftTemplateFulfilled,
  deleteShiftTemplateRejected,
  updateShiftTemplatesPending,
  updateShiftTemplatesFulfilled,
  updateShiftTemplatesRejected,
  getAllTagsPending,
  getAllTagsFulfilled,
  getAllTagsRejected,
  getAllShiftTaskJobsPending,
  getAllShiftTaskJobsFulfilled,
  getAllShiftTaskJobsRejected,
  getAllShiftProjectsPending,
  getAllShiftProjectsFulfilled,
  getAllShiftProjectsRejected,
  getAllShiftJobSitesPending,
  getAllShiftJobSitesFulfilled,
  getAllShiftJobSitesRejected,
  createShiftTemplatesPending,
  createShiftTemplatesFulfilled,
  createShiftTemplatesRejected,
  searchShiftTemplatesPending,
  searchShiftTemplatesFulfilled,
  searchShiftTemplatesRejected,
} from '../../slices/ShiftManagement/ShiftTemplates';

export const getAllTemplates = (page = 1, limit = 25) => {
  return async (dispatch) => {
    dispatch(getShiftTemplatesPending());
    await getAllShiftTemplatesRepo(page, limit)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getShiftTemplatesFulfilled(response?.data));
        } else {
          dispatch(getShiftTemplatesRejected(response?.data));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(getShiftTemplatesRejected(error.message));
        }
      });
  };
};

export const getAllShiftPostions = (page, limit) => {
  return async (dispatch) => {
    dispatch(getAllShiftPositionsPending());
    await getAllShiftPositionsRepo(page, limit)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getAllShiftPositionsFulfilled(response?.data));
        } else {
          dispatch(getAllShiftPositionsRejected(response?.data));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(getAllShiftPositionsRejected(error.message));
        }
      });
  };
};

export const deleteShiftTemplate = (id) => {
  return async (dispatch) => {
    dispatch(deleteShiftTemplatePending());
    await deleteShiftTemplatesRepo(id)
      .then((response) => {
        if (response.status === 200) {
          dispatch(updateShiftTemplatesFulfilled(response?.data));
        } else {
          dispatch(deleteShiftTemplateRejected(response?.data));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(deleteShiftTemplateRejected(error.message));
        }
      });
  };
};

export const updateShiftTemplate = (id) => {
  return async (dispatch) => {
    dispatch(updateShiftTemplatesPending());
    await updateShiftTemplatesRepo(id)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getAllTemplates());
        } else {
          dispatch(updateShiftTemplatesRejected(response?.data));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(updateShiftTemplatesRejected(error.message));
        }
      });
  };
};

export const getAllShiftTags = () => {
  return async (dispatch) => {
    dispatch(getAllTagsPending());
    await getAllTagsRepo()
      .then((response) => {
        if (response.status === 200) {
          dispatch(getAllTagsFulfilled(response?.data?.data));
        } else {
          dispatch(getAllTagsRejected(response?.data));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(getAllTagsRejected(error.message));
        }
      });
  };
};

export const getAllShiftTaskJobs = () => {
  return async (dispatch) => {
    dispatch(getAllShiftTaskJobsPending());
    await getAllShiftTaskJobsRepo()
      .then((response) => {
        if (response.status === 200) {
          dispatch(getAllShiftTaskJobsFulfilled(response?.data?.data.data));
        } else {
          dispatch(getAllShiftTaskJobsRejected(response?.data?.data));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(getAllShiftTaskJobsRejected(error.message));
        }
      });
  };
};

export const getAllShiftProjects = (params) => {
  return async (dispatch) => {
    dispatch(getAllShiftProjectsPending());
    await getAllShiftProjectsRepo(params)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getAllShiftProjectsFulfilled(response?.data?.data));
        } else {
          dispatch(getAllShiftProjectsRejected(response?.data));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(getAllShiftProjectsRejected(error.message));
        }
      });
  };
};
export const getAllShiftJobSites = () => {
  return async (dispatch) => {
    dispatch(getAllShiftJobSitesPending());
    await getAllShiftJobSitesRepo()
      .then((response) => {
        if (response.status === 200) {
          dispatch(getAllShiftJobSitesFulfilled(response?.data?.data));
        } else {
          dispatch(getAllShiftJobSitesRejected(response?.data));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(getAllShiftJobSitesRejected(error.message));
        }
      });
  };
};

export const createShiftTemplate = (data) => {
  return async (dispatch) => {
    dispatch(createShiftTemplatesPending());
    await createShiftTemplatesRepo(data)
      .then((response) => {
        if (response.status === 200) {
          dispatch(createShiftTemplatesFulfilled(response?.data));
          dispatch(getAllTemplates());
        } else {
          dispatch(createShiftTemplatesRejected(response?.data));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(createShiftTemplatesRejected(error.message));
        }
      });
  };
};

export const serachShiftTemplates = (obj) => {
  return async (dispatch) => {
    dispatch(searchShiftTemplatesPending());
    searchShiftTemplateRepo(obj)
      .then((response) => {
        if (response.status === 200) {
          dispatch(searchShiftTemplatesFulfilled(response?.data.data));
        } else {
          dispatch(searchShiftTemplatesRejected(response?.data));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(searchShiftTemplatesRejected(error.message));
        }
      });
  };
};
