import React, { useState, useEffect } from "react";
import Sidebar from "pages/sidebar/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import {
  createAssetCategory,
  getAllAssetCategory,
  updateAssetCategory,
} from "store/actions/AssetManagement/assetCategory";
import {
  clearCreateAssetCategory,
  clearUpdateAssetCategory,
} from "store/slices/AssetManagement/assetCategory";
const AddCategorySidebar = ({ setIsOpenForm, categoryInitialValues }) => {
  const dispatch = useDispatch();
  const { assetCategory } = useSelector((state) => state);
  const [categoryName, setCategoryName] = useState(
    categoryInitialValues ? categoryInitialValues?.name : ""
  );
  const handleSubmit = () => {
    let params = {};
    params.name = categoryName;
    if (categoryInitialValues?._id) {
      params._id = categoryInitialValues?._id;
      dispatch(updateAssetCategory(params));
    } else {
      dispatch(createAssetCategory(params));
    }
  };

  useEffect(() => {
    if (assetCategory?.createAssetCategory?.fulfilled) {
      dispatch(getAllAssetCategory());
      setIsOpenForm(false);
      dispatch(clearCreateAssetCategory());
    }
  }, [assetCategory?.createAssetCategory]);

  useEffect(() => {
    if (assetCategory?.updateAssetCategory?.fulfilled) {
      dispatch(getAllAssetCategory());
      setIsOpenForm(false);
      dispatch(clearUpdateAssetCategory());
    }
  }, [assetCategory?.updateAssetCategory]);

  return (
    <Sidebar
      sidebarHandler={() => setIsOpenForm(false)}
      modalTitle="Add Category"
    >
      <div className="h-screen">
        <div>
          <p className="text-blue-900 font-semibold">Category</p>
          <input
            type="text"
            className="bg-gray-100 border-2 rounded-md outline-0 px-2 py-3 w-full font-bold mt-1 focus:ring focus:outline-none"
            onChange={(e) => {
              setCategoryName(e.target.value);
            }}
            value={categoryName}
          />
        </div>
      </div>
      <div className="border-b-2 border-gray-300 my-5"></div>
      <div className="flex justify-end gap-5">
        <p
          className="py-1 px-3 font-bold border cursor-pointer border-blue-700 rounded"
          onClick={() => setIsOpenForm(false)}
        >
          Cancel
        </p>
        <p
          className="py-1 px-3 font-bold border cursor-pointer border-blue-700 rounded bg-indigo-700 text-gray-50"
          onClick={() => {
            handleSubmit();
          }}
        >
          Save
        </p>
      </div>
    </Sidebar>
  );
};

export default AddCategorySidebar;
