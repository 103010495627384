import axios from "axios";
import { Urls } from "store/urls";

export const getAllAssetConditionRepo = () => {
  return axios({
    method: "GET",
    url: Urls.GET_ALL_ASSET_CONDITION,
  });
};

export const deleteAssetConditionRepo = (id) => {
  return axios({
    method: "DELETE",
    url: Urls.DELETE_ASSET_CONDITION + id,
  });
};

export const createAssetConditionRepo = (data) => {
  return axios({
    method: "POST",
    url: Urls.CREATE_ASSET_CONDITION,
    data: data,
  });
};

export const updateAssetConditionRepo = (data) => {
  return axios({
    method: "PUT",
    url: Urls.UPDATE_ASSET_CONDITION,
    data: data,
  });
};
