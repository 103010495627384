import React from "react";
import AssetMangement from "./TopFilter";
import AssetMangDetails from "./AssetMangDetails";

const Index = () => {
  return (
    <div className="bg-white text-left px-5 py-1 mt-4 border">
      <AssetMangement />
      <AssetMangDetails />
    </div>
  );
};

export default Index;
