import React, { useState } from "react";
import Select from "react-select";
import AddCategorySidebar from "./AddCategorySidebar";
const CategoryTopFilter = ({ search, onSearch }) => {
  const [isOpenForm, setIsOpenForm] = useState(false);

  return (
    <div className="p-3 shadow">
      {isOpenForm && <AddCategorySidebar setIsOpenForm={setIsOpenForm} />}

      <div className="flex gap-2 flex-wrap justify-between">
        <div className="flex flex-wrap">
          <div className="flex items-center gap-2 font-semibold">
            <p>Asset Category</p>
          </div>
        </div>
        <div className="flex gap-2 flex-wrap">
          <input
            type="text"
            placeholder="Search..."
            className="bg-gray-100 rounded outline-0 px-2  sm:w-52 w-full font-semibold"
            value={search}
            onChange={(e) => {
              onSearch(e.target.value);
            }}
          />
          <button
            className="bg-blue-900 px-3 py-1 rounded text-gray-100"
            onClick={() => setIsOpenForm(true)}
          >
            + Add
          </button>
        </div>
      </div>
    </div>
  );
};

export default CategoryTopFilter;
