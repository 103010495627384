import React, { useState } from "react";
import IAssignDetails from "./IAssignDetails";
import CommentSidebarCompo from "./CommentSidebar";

const AssetAssignDetailsList = () => {
  const [commentSidebar, setcommentSidebar] = useState(false);
  const [iassign, setIassign] = useState(false);

  const commentSidebarHandler = () => {
    setcommentSidebar(!commentSidebar);
  };

  const IAssignDetailsHanlder = () => {
    setIassign(!iassign);
  };

  return (
    <div className="overflow-x-auto">
      {commentSidebar && (
        <CommentSidebarCompo commentSidebarHandler={commentSidebarHandler} />
      )}
      <table className="w-full m-auto mt-5 text-blue-900 text-sm font-semibold">
        <tr className="text-left bg-gray-100 border-2 rounded-md">
          <th className="px-5">Request No</th>
          <th className="px-5">Category</th>
          <th className="px-5">Sub-Category</th>
          <th className="px-5">Qty</th>
          <th className="px-5">Assigned</th>
          <th className="px-5">Status</th>
          <th className="px-5">Requested</th>
          <th className="px-5">Actions</th>
        </tr>
        <tr className="border-2 rounded-md">
          <td className="px-5 p-2 text-green-900">RE001</td>
          <td className="px-5 p-2 underline text-blue-700">Electronics</td>
          <td className="px-5 p-2 underline text-blue-700">Laptop</td>
          <td className="px-5 p-2">1</td>
          <td className="px-5 p-2">Bhupendra Singh</td>
          <td className="px-5 p-2 text-yellow-600">Pending</td>
          <td className="px-5 p-2">18-09-2022</td>
          <td className="px-5 p-2 flex gap-1 justify-end">
            <i
              className="border p-2 mt-2 cursor-pointer fa-solid fa-message"
              onClick={commentSidebarHandler}
            ></i>
            <i className="border p-2 mt-2 cursor-pointer fa-solid fa-clock-rotate-left"></i>
          </td>
        </tr>
        <tr className="border-2 rounded-md">
          <td className="px-5 p-2 text-green-900">RE001</td>
          <td className="px-5 p-2 underline text-blue-700">Electronics</td>
          <td className="px-5 p-2 underline text-blue-700">Laptop</td>
          <td className="px-5 p-2">1</td>
          <td className="px-5 p-2">Bhupendra Singh</td>
          <td className="px-5 p-2 text-red-600">Reject</td>
          <td className="px-5 p-2">18-09-2022</td>
          <td className="px-5 p-2 flex gap-1 justify-end">
            <i
              className="border p-2 mt-2 cursor-pointer fa-solid fa-message"
              onClick={commentSidebarHandler}
            ></i>
            <i className="border p-2 mt-2 cursor-pointer fa-solid fa-clock-rotate-left"></i>
          </td>
        </tr>
        <tr className="border-2 rounded-md">
          <td className="px-5 p-2 text-green-900">RE001</td>
          <td className="px-5 p-2 underline text-blue-700">Electronics</td>
          <td className="px-5 p-2 underline text-blue-700">Laptop</td>
          <td className="px-5 p-2">1</td>
          <td className="px-5 p-2">Bhupendra Singh</td>
          <td className="px-5 p-2 text-green-600 flex gap-2 items-center">
            <p>Assigned</p>
            <p
              className="border-2 cursor-pointer border-blue-900 rounded-full w-4 h-4 flex justify-center items-center"
              onClick={IAssignDetailsHanlder}
            >
              i
            </p>
          </td>
          <td className="px-5 p-2">18-09-2022</td>
          <td className="px-5 p-2 flex gap-1 justify-end">
            <i
              className="border p-2 mt-2 cursor-pointer fa-solid fa-message"
              onClick={commentSidebarHandler}
            ></i>
            <i className="border p-2 mt-2 cursor-pointer fa-solid fa-clock-rotate-left"></i>
          </td>
        </tr>
        {iassign && (
          <IAssignDetails IAssignDetailsHanlder={IAssignDetailsHanlder} />
        )}
        <tr className="border-2 rounded-md">
          <td className="px-5 p-2 text-green-900">RE001</td>
          <td className="px-5 p-2 underline text-blue-700">Electronics</td>
          <td className="px-5 p-2 underline text-blue-700">Laptop</td>
          <td className="px-5 p-2">1</td>
          <td className="px-5 p-2">Bhupendra Singh</td>
          <td className="px-5 p-2 text-green-600">Assigned</td>
          <td className="px-5 p-2">18-09-2022</td>
          <td className="px-5 p-2 flex gap-1 justify-end">
            <i
              className="border p-2 mt-2 cursor-pointer fa-solid fa-message"
              onClick={commentSidebarHandler}
            ></i>
            <i className="border p-2 mt-2 cursor-pointer fa-solid fa-clock-rotate-left"></i>
          </td>
        </tr>
        <tr className="border-2 rounded-md">
          <td className="px-5 p-2 text-green-900">RE001</td>
          <td className="px-5 p-2 underline text-blue-700">Electronics</td>
          <td className="px-5 p-2 underline text-blue-700">Laptop</td>
          <td className="px-5 p-2">1</td>
          <td className="px-5 p-2">Bhupendra Singh</td>
          <td className="px-5 p-2 text-yellow-600">Pending</td>
          <td className="px-5 p-2">18-09-2022</td>
          <td className="px-5 p-2 flex gap-1 justify-end">
            <i
              className="border p-2 mt-2 cursor-pointer fa-solid fa-message"
              onClick={commentSidebarHandler}
            ></i>
            <i className="border p-2 mt-2 cursor-pointer fa-solid fa-clock-rotate-left"></i>
          </td>
        </tr>
        <tr className="border-2 rounded-md">
          <td className="px-5 p-2 text-green-900">RE001</td>
          <td className="px-5 p-2 underline text-blue-700">Electronics</td>
          <td className="px-5 p-2 underline text-blue-700">Laptop</td>
          <td className="px-5 p-2">1</td>
          <td className="px-5 p-2">Bhupendra Singh</td>
          <td className="px-5 p-2 text-red-600">Reject</td>
          <td className="px-5 p-2">18-09-2022</td>
          <td className="px-5 p-2 flex gap-1 justify-end">
            <i
              className="border p-2 mt-2 cursor-pointer fa-solid fa-message"
              onClick={commentSidebarHandler}
            ></i>
            <i className="border p-2 mt-2 cursor-pointer fa-solid fa-clock-rotate-left"></i>
          </td>
        </tr>
      </table>
    </div>
  );
};

export default AssetAssignDetailsList;
