import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
// import axios from "axios";
import Avatar from '@material-ui/core/Avatar';
import { useDasboardStyles } from './Style';
import JobSiteCard from './JobSiteCard';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteShiftProject,
  getShiftProject,
} from 'store/actions/ShiftManagement/ShiftProjectNSites';
import useAuth from 'hooks/useAuth';

function ProjectCard(props) {
  const classes = useDasboardStyles();
  const [row, setProjectRow] = useState(props.row);
  const [projectOpen, setProjectOpen] = useState(true);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteObject, setDeleteObject] = useState(null);
  const dispatch = useDispatch();
  const { shiftProject } = useSelector((state) => state.shiftProjectNSites);

  const handleDeleteProject = async () => {
    props.setIsLoading(true);

    dispatch(deleteShiftProject(deleteObject._id));
    // try {
    //   const response = await axios.delete(
    //     `${process.env.REACT_APP_API_BASE_URL}/project/delete/` +
    //       deleteObject._id
    //   );
    //   if (response.status === 200) {
    //     props.getAllProjects();
    //   }
    // } catch (e) {
    //   if (e.response) {
    //     console.log("Error >", e.response);
    //   }
    // } finally {
    //   setShowDeleteDialog(false);
    //   setDeleteObject(null);
    // }
  };

  const auth = useAuth();
  useEffect(() => {
    setProjectRow(props.row);
  }, [props.row]);

  useEffect(() => {
    if (shiftProject.fulfilled) {
      const org_id = auth.user.org_id._id;
      dispatch(getShiftProject(org_id));
      setShowDeleteDialog(false);
      setDeleteObject(null);
    }
  }, [shiftProject.fulfilled]);

  return (
    <React.Fragment>
      <Dialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Delete Project</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to delete the Project{' '}
            {deleteObject?.project_name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteDialog(false)}>Cancel</Button>
          <Button onClick={handleDeleteProject} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell
          component='th'
          scope='row'
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0px',
            alignItems: 'center',
          }}
        >
          <Typography
            className={classes.colorBlue}
            variant='h6'
            style={{
              fontWeight: '600',
              textAlign: 'left',
              marginLeft: '16px',
            }}
          >
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                style={{
                  marginRight: '10px',
                  backgroundColor: 'blue',
                }}
              >
                {' '}
                {row.project_name
                  ? row.project_name.charAt(0).toUpperCase()
                  : ''}
              </Avatar>{' '}
              {row.project_name}
            </span>
          </Typography>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              style={{
                color: '#0f88a3',
                fontWeight: '600',
                textAlign: 'left',
                marginRight: '16px',
              }}
            >
              Total Sites : {row.jobsites?.length}
            </Typography>
            <div style={{ display: 'flex' }}>
              <div
                style={
                  JSON.parse(row.is_active) === true
                    ? {
                        width: '20px',
                        height: '20px',
                        borderRadius: '50%',
                        backgroundColor: 'lightGreen',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }
                    : {
                        width: '20px',
                        height: '20px',
                        borderRadius: '50%',
                        backgroundColor: 'lightGrey',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }
                }
              >
                <div
                  style={
                    JSON.parse(row.is_active) === true
                      ? {
                          width: '10px',
                          height: '10px',
                          borderRadius: '50%',
                          backgroundColor: 'green',
                        }
                      : {
                          width: '10px',
                          height: '10px',
                          borderRadius: '50%',
                          backgroundColor: 'grey',
                        }
                  }
                ></div>
              </div>
              &nbsp;&nbsp;
              <Typography
                style={
                  JSON.parse(row.is_active) === true
                    ? {
                        color: 'green',
                      }
                    : {
                        color: 'grey',
                      }
                }
                variant='subtitle2'
              >
                {JSON.parse(row.is_active) === true ? 'Active' : 'Inactive'}
              </Typography>
            </div>
            <Button
              variant='contained'
              style={{
                color: 'rgb(31,41,156)',
                backgroundColor: 'white',
                height: '30px',
                margin: '1rem',
              }}
              onClick={(e) => props.handleSiteDialogOpen('create', row)}
            >
              + Add Site
            </Button>

            <IconButton
              onClick={(e) => props.handleProjectDialogOpen('edit', row)}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={(e) => {
                setDeleteObject(row);
                setShowDeleteDialog(true);
              }}
            >
              <DeleteIcon />
            </IconButton>

            <IconButton
              aria-label='expand row'
              size='small'
              onClick={() => setProjectOpen(!projectOpen)}
            >
              {projectOpen ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          </div>
        </TableCell>
      </TableRow>
      {row.jobsites ? (
        <TableRow>
          <TableCell style={{ padding: 0 }}>
            <Collapse in={projectOpen} timeout='auto' unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size='small' aria-label='sites'>
                  <TableBody>
                    {row.jobsites ? (
                      row.jobsites.map((siteRow) => (
                        <JobSiteCard
                          siteRow={siteRow}
                          setIsLoading={props.setIsLoading}
                          getAllProjects={() => {
                            props.getAllProjects();
                          }}
                          handleSiteDialogOpen={(operation, siteRow) => {
                            props.handleSiteDialogOpen(operation, siteRow);
                          }}
                        />
                      ))
                    ) : (
                      <></>
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
}

export default ProjectCard;
