import React from "react";

const Sidebar = ({ children, modalTitle, sidebarHandler }) => {
  return (
    <div className="text-left text-blue-900">
      <div>
        <div className="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none flex justify-end mt-10">
          <div className="my-6 sm:w-1/3 w-full">
            <div className="border-0 shadow-lg flex flex-col bg-white outline-none focus:outline-none">
              <div className="flex items-start justify-between p-5 border-b border-solid border-gray-200 rounded-t">
                <h3 className="text-xl font-semibold text-gray-900">
                  {modalTitle}
                </h3>
                <button
                  className="p-1 ml-auto border-0 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={sidebarHandler}
                >
                  <span className="h-6 w-6 text-2xl block outline-none focus:outline-none">
                    <i className="fas fa-times"></i>
                  </span>
                </button>
              </div>
              <div className="p-6 leading-relaxed">
                {/* Content start */}
                {children}
                {/* Content end */}
              </div>
            </div>
          </div>
        </div>
        <div className="left-0 top-0 bottom-0 right-0 fixed bg-black opacity-50"></div>
      </div>
    </div>
  );
};

export default Sidebar;
