import React from "react";
import { Typography, Grid, Paper } from "@material-ui/core";
import useAuth from "hooks/useAuth";
import moment from "moment";
import myStyles from "../ProfileStyles";

function BasicInfoPanel() {
  const myClasses = myStyles();
  const auth = useAuth();

  let curr_addr = "";
  let permanent_addr = "";
  let mailing_addr = "";

  if (!auth.user) return;

  const { user } = auth;

  if (user.residency) {
    curr_addr = user.residency.curr_addr;
    permanent_addr = user.residency.permanent_addr;
    mailing_addr = user.residency.mailing_addr;
  }

  return (
    <Grid container direction='row' spacing={3}>
      {/* Personal------------  */}
      <Grid item xs={12} sm={6} lg={4} xl={4} spacing={3}>
        <Paper component='div' className={myClasses.paper}>
          <Grid container direction='row' justify='space-evenly'>
            <Grid item xs={12} className={myClasses.label}>
              <Typography variant='h5' color='primary'>
                Personal
              </Typography>
            </Grid>

            <Grid
              item
              xs={5}
              sm={5}
              md={4}
              lg={6}
              style={{ textAlign: "left" }}
            >
              <Typography variant='subtitle1' className={myClasses.info}>
                &nbsp; First Name :{" "}
              </Typography>
              <Typography variant='subtitle1' className={myClasses.info}>
                &nbsp; Last Name :{" "}
              </Typography>
              <Typography variant='subtitle1' className={myClasses.info}>
                &nbsp; Date Of Birth :{" "}
              </Typography>
              <Typography variant='subtitle1' className={myClasses.info}>
                &nbsp; Gender :{" "}
              </Typography>
              <Typography variant='subtitle1' className={myClasses.info}>
                &nbsp; Blood Group :{" "}
              </Typography>
              <Typography variant='subtitle1' className={myClasses.info}>
                &nbsp; Marital Status :{" "}
              </Typography>
            </Grid>

            <Grid item xs={6} sm={6} lg={5} style={{ textAlign: "left" }}>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {auth.user.first_name || ""}{" "}
              </Typography>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {auth.user.last_name || ""}{" "}
              </Typography>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {moment(auth.user.dob).format("MMM Do YYYY") || ""}
              </Typography>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {auth.user.gender || ""}{" "}
              </Typography>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {auth.user.blood_group || ""}{" "}
              </Typography>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {(auth.user.family_details &&
                  auth.user.family_details.marital_status) ||
                  ""}{" "}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {/* Family----------------- */}
      <Grid item xs={12} sm={6} lg={4} xl={4} spacing={3}>
        <Paper component='div' className={myClasses.paper}>
          <Grid container direction='row' justify='space-evenly'>
            <Grid item xs={12} className={myClasses.label}>
              <Typography variant='h5' color='primary'>
                Current Address
              </Typography>
            </Grid>

            <Grid
              item
              xs={5}
              sm={6}
              md={4}
              lg={4}
              style={{ textAlign: "left" }}
            >
              <Typography variant='subtitle1' className={myClasses.info}>
                &nbsp; Street Address:{" "}
              </Typography>
              <Typography variant='subtitle1' className={myClasses.info}>
                &nbsp; Apt/Unit#:{" "}
              </Typography>
              <Typography variant='subtitle1' className={myClasses.info}>
                &nbsp; City:{" "}
              </Typography>
              <Typography variant='subtitle1' className={myClasses.info}>
                &nbsp; Province:{" "}
              </Typography>
              <Typography variant='subtitle1' className={myClasses.info}>
                &nbsp; Postal Code:{" "}
              </Typography>
            </Grid>

            <Grid
              item
              xs={5}
              sm={4}
              md={6}
              lg={7}
              style={{ textAlign: "left" }}
            >
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {curr_addr.street_addr || ""}{" "}
              </Typography>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {curr_addr.apt_unit || ""}{" "}
              </Typography>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {curr_addr.city || ""}{" "}
              </Typography>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {curr_addr.province || ""}{" "}
              </Typography>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {curr_addr.postal_code || ""}{" "}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {/* Address */}
      <Grid item xs={12} sm={6} lg={4} spacing={3}>
        <Paper component='div' className={myClasses.paper}>
          <Grid container direction='row' justify='space-evenly'>
            <Grid item xs={12} className={myClasses.label}>
              <Typography variant='h5' color='primary'>
                Permanent Address
              </Typography>
            </Grid>

            <Grid item xs={6} sm={7} lg={4} style={{ textAlign: "left" }}>
              <Typography variant='subtitle1' className={myClasses.info}>
                &nbsp; Street Address:{" "}
              </Typography>
              <Typography variant='subtitle1' className={myClasses.info}>
                &nbsp; Apt/Unit#:{" "}
              </Typography>
              <Typography variant='subtitle1' className={myClasses.info}>
                &nbsp; City:{" "}
              </Typography>
              <Typography variant='subtitle1' className={myClasses.info}>
                &nbsp; Province:{" "}
              </Typography>
              <Typography variant='subtitle1' className={myClasses.info}>
                &nbsp; Postal Code:{" "}
              </Typography>
            </Grid>

            <Grid item xs={5} sm={4} lg={7} style={{ textAlign: "left" }}>
              {/* <Typography
                variant="body1"
                display="block"
                className={myClasses.addressValue}
                gutterBottom
              >
                {" "}
                {(auth.user.residency &&
                  auth.user.residency.curr_addr) ||
                  ""}
              </Typography>
              <Typography
                variant="body1"
                display="block"
                className={myClasses.addressValue}
                gutterBottom
              >
                {" "}
                {(auth.user.residency &&
                  auth.user.residency.permanent_addr
                ) ||
                  ""}
              </Typography>
              <Typography
                variant="body1"
                display="block"
                className={myClasses.addressValue}
                gutterBottom
              >
                {" "}
                {(auth.user.residency &&
                  auth.user.residency.mailing_addr
                ) ||
                  ""}
              </Typography> */}
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {permanent_addr.street_addr || ""}{" "}
              </Typography>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {permanent_addr.apt_unit || ""}{" "}
              </Typography>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {permanent_addr.city || ""}{" "}
              </Typography>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {permanent_addr.province || ""}{" "}
              </Typography>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {permanent_addr.postal_code || ""}{" "}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6} lg={4} spacing={3}>
        <Paper component='div' className={myClasses.paper}>
          <Grid container direction='row' justify='space-evenly'>
            <Grid item xs={12} className={myClasses.label}>
              <Typography variant='h5' color='primary'>
                Mailing Address
              </Typography>
            </Grid>

            <Grid item xs={6} sm={7} lg={4} style={{ textAlign: "left" }}>
              <Typography variant='subtitle1' className={myClasses.info}>
                &nbsp; Street Address:{" "}
              </Typography>
              <Typography variant='subtitle1' className={myClasses.info}>
                &nbsp; Apt/Unit#:{" "}
              </Typography>
              <Typography variant='subtitle1' className={myClasses.info}>
                &nbsp; City:{" "}
              </Typography>
              <Typography variant='subtitle1' className={myClasses.info}>
                &nbsp; Province:{" "}
              </Typography>
              <Typography variant='subtitle1' className={myClasses.info}>
                &nbsp; Postal Code:{" "}
              </Typography>
            </Grid>

            <Grid item xs={5} sm={4} lg={7} style={{ textAlign: "left" }}>
              {/* <Typography
                variant="body1"
                display="block"
                className={myClasses.addressValue}
                gutterBottom
              >
                {" "}
                {(auth.user.residency &&
                  auth.user.residency.curr_addr) ||
                  ""}
              </Typography>
              <Typography
                variant="body1"
                display="block"
                className={myClasses.addressValue}
                gutterBottom
              >
                {" "}
                {(auth.user.residency &&
                  auth.user.residency.permanent_addr
                ) ||
                  ""}
              </Typography>
              <Typography
                variant="body1"
                display="block"
                className={myClasses.addressValue}
                gutterBottom
              >
                {" "}
                {(auth.user.residency &&
                  auth.user.residency.mailing_addr
                ) ||
                  ""}
              </Typography> */}
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {mailing_addr.street_addr || ""}{" "}
              </Typography>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {mailing_addr.apt_unit || ""}{" "}
              </Typography>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {mailing_addr.city || ""}{" "}
              </Typography>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {mailing_addr.province || ""}{" "}
              </Typography>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {mailing_addr.postal_code || ""}{" "}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default BasicInfoPanel;
