import React, { useState, useEffect, useContext } from "react";
import {
  Typography,
  InputBase,
  InputLabel,
  Select,
  // MenuItem,
  FormControl,
  // IconButton,
  Paper,
  Button,
  TableContainer,
  Table,
  TableBody,
  Menu,
  MenuItem,
  IconButton,
  Backdrop,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import clsx from "clsx";
import "@fontsource/roboto";
import axios from "axios";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { useDasboardStyles } from "./Style";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { TimesheetContext } from "./TimesheetContext";
import { Redirect, useHistory } from "react-router-dom";
import ProjectCard from "./ProjectCard";
import SidebarTimesheetsContent from "./SidebarTimesheetsContent";
import moment from "moment";
import { Autocomplete } from "@material-ui/lab";

const EmployeeTimesheet = (props) => {
  let theme = createTheme();
  theme = responsiveFontSizes(theme);
  const classes = useDasboardStyles();
  const [shiftData, setShiftData] = useState([]);
  let { employees } = useContext(TimesheetContext);
  const history = useHistory();
  const [anchorAddOpt, setAnchorAddOpt] = useState(null);
  const openAddOpt = Boolean(anchorAddOpt);
  const [sidebar, setSidebar] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [originalTimesheetData, setOriginalTimesheetData] = useState([]);
  const [uniqueEmp, setUniqueEmp] = useState([]);
  const [jobSiteData, setJobSiteData] = useState([]);

  const sidebarHandler = () => {
    setSidebar(!sidebar);
  };

  const setLoadingFalse = () => {
    setIsLoading(false);
  };
  console.log(employees,'employees');
  useEffect(() => {
    getAllJobSites();
    const Fetch = async () => {
      if (employees.length) {
        setIsLoading(true);
        let arr = [];
        for (let employee of ['63387a50ebd5ef00169d1972']) {
          let res = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/scheduler/${'63387a50ebd5ef00169d1972'}`
          );
          console.log('response', res);
          res.data.data.forEach((ar) => arr.push(ar));
        }
        console.log(arr,'data here');
        setShiftData(arr);
        setOriginalTimesheetData(arr);
        // let newArr = arr.filter((ar) => ar.assignedEmployees.employee_id);
        // const arrayUniqueByKey = [
        //   ...new Map(
        //     newArr.map((item) => [
        //       item["assignedEmployees"]["employee_id"],
        //       item,
        //     ])
        //   ).values(),
        // ];
        // setUniqueEmp(arrayUniqueByKey);
      }
    };
    Fetch();

    window.scrollTo(0, 0);
  }, [employees]);


  const goBack = () => {
    history.replace("/shift-mngmt/timesheets");
  };

  const handleOpenAddOpt = (event) => {
    setAnchorAddOpt(event.currentTarget);
  };

  const handleCloseAddOpt = () => {
    setAnchorAddOpt(null);
  };

  const getArrayViaIndexes = (arr, indexesToKeep) => {
    let newArr = indexesToKeep.map((idx) => {
      return arr[idx];
    });
    return newArr;
  };

  const getAllJobSites = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/jobSite/getAllJobSite`
      );
      if (response.status === 200) {
        const data = response.data.data;
        setJobSiteData(data);
      }
    } catch (e) {
      if (e.response) {
        console.log("Error >", e.response);
      }
    }
  };

  const searchEmployee = (key) => {
    setShiftData(originalTimesheetData);
    const regExp = /\(([^)]+)\)/;
    let matches = regExp.exec(key);

    if (matches) {
      const indexes = originalTimesheetData.map((x, idx) =>
        x.assignedEmployees.user.emp_code === matches[1] ? idx : ""
      );
      const filtered_indexes = indexes.filter((el) => {
        return el !== null && typeof el !== "undefined" && el !== "";
      });
      setShiftData(getArrayViaIndexes(originalTimesheetData, filtered_indexes));
    }
  };

  const getTotalHours = (cellName) => {
    // let total = 0;
    // shiftData.forEach((row) => {
    //   row.assignedEmployees?.shift?.map((shift) => {
    //     if (shift.shift_template_id) {
    //       const startTime = moment(shift.shift_template_id.start_time);
    //       const endTime = moment(shift.shift_template_id.end_time);
    //       total = parseInt(
    //         total + parseInt(moment.duration(endTime.diff(startTime)).asHours())
    //       );
    //     }
    //   });
    // });
    // const hours = Math.floor(total);
    // const mins = (total - hours) * 0.6;
    // if (cellName === "regular") return `${hours}.${mins} Hrs`;
    // let worked = (hours + mins).toFixed(2) >= 0 ? (hours + mins).toFixed(2) : 0;
    // total = hours * 60 + mins * 100;
    // let difference =
    //   Math.floor(worked) * 60 + (worked - Math.floor(worked)) * 100 - total;
  };

  const handleFilterBySite = (e, site_nd_prjId) => {
    e.preventDefault();
    let ids = site_nd_prjId.split(" ");
    const siteProject = originalTimesheetData.filter(
      (prj) => prj.project_id === ids[1]
    );
    if (siteProject.length) {
      setShiftData(siteProject);
      return;
    }
    setShiftData([]);
  };

  // if (!employees.length) return <Redirect to='/shift-mngmt/timesheets' />;

  return (
    <>
      <div className='root'>
        {sidebar && (
          <SidebarTimesheetsContent sidebarHandler={sidebarHandler} />
        )}
        <ThemeProvider theme={theme}>
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: props.isDrawerOpen,
            })}
          >
            <Typography
              style={{
                textAlign: "left",
                marignBottom: "20px",
                minWidth: "230px",
              }}
            >
              Home {">"} Shift Management {">"} Timesheets {">"}{" "}
              {employees?.length <= 1 ? employees[0].name : "Multipe Employees"}
            </Typography>
            <Paper
              style={{
                paddingBottom: "50px",
                minWidth: "230px",
                marginTop: "20px",
                minHeight: "800px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  marginBottom: "10px",
                  position: "relative",
                  justifyContent: "space-between",
                  borderBottom: "1px solid gray",
                  padding: "20px 20px 15px 20px",
                  paddingLeft: "20px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ArrowBack
                    style={{ marginRight: "20px", cursor: "pointer" }}
                    onClick={goBack}
                  />
                  <div className={classes.nameTagContainer}>
                    <div className={classes.nameTag}>S</div>
                  </div>
                  <Typography
                    className={classes.colorBlue}
                    variant='h6'
                    style={{
                      fontWeight: "600",
                      textAlign: "left",
                    }}
                  >
                    {employees?.length <= 1
                      ? employees[0].name
                      : "Multipe Employees"}
                  </Typography>
                </div>
                <div
                  style={{
                    padding: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <i
                    className='fa-solid fa-filter border p-4 border-blue-700 text-blue-700 rounded cursor-pointer'
                    onClick={sidebarHandler}
                  ></i>
                  <i
                    style={{ marginLeft: "10px" }}
                    className='fa-solid fa-print border p-4 border-blue-700 text-blue-700 rounded cursor-pointer'
                  ></i>

                  <IconButton
                    style={{
                      marginRight: "2px",
                      marginTop: "5px",
                    }}
                    aria-label='more'
                    id='long-button'
                    aria-controls='basic-menu'
                    aria-haspopup='true'
                    aria-expanded={openAddOpt ? "true" : undefined}
                    onMouseOver={handleOpenAddOpt}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id='long-menu'
                    anchorEl={anchorAddOpt}
                    open={openAddOpt}
                    onClose={handleCloseAddOpt}
                    MenuListProps={{
                      "aria-labelledby": "add-options-button",
                    }}
                  >
                    <MenuItem>Share</MenuItem>
                    <MenuItem>Export</MenuItem>
                  </Menu>
                </div>
              </div>
              <div
                style={{
                  padding: "20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Paper
                  style={{
                    width: "275px",
                    height: "56px",
                    backgroundColor: "rgb(248,248,248)",
                    marginRight: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {originalTimesheetData && (
                    <Autocomplete
                      id='combo-box-demo'
                      options={uniqueEmp}
                      getOptionLabel={(option) =>
                        `${option.assignedEmployees.user.first_name} ${option.assignedEmployees.user.last_name} (${option.assignedEmployees.user.emp_code})`
                      }
                      style={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label='Search Employee'
                          variant='outlined'
                        />
                      )}
                      onChange={(e) => {
                        searchEmployee(e.target.innerText);
                      }}
                    />
                  )}
                </Paper>
                <FormControl
                  variant='outlined'
                  style={{
                    minWidth: "225px",
                    width: "275px",
                  }}
                >
                  <InputLabel id='demo-simple-select-label-2'>
                    Select Site
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label-2'
                    label='All Sites'
                    id='demo-simple-select'
                    onChange={(event) => {
                      if (event.target.value !== "All") {
                        handleFilterBySite(event, event.target.value);
                      } else {
                        setShiftData(originalTimesheetData);
                      }
                    }}
                  >
                    <MenuItem value={"All"}>All</MenuItem>
                    {jobSiteData
                      .sort((a, b) =>
                        a.jobSite_title.localeCompare(b.jobSite_title)
                      )
                      .map((jobsite) => (
                        <MenuItem
                          key={jobsite._id}
                          value={jobsite._id + " " + jobsite.project_id}
                        >
                          {jobsite.jobSite_title}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <Button
                  variant='contained'
                  style={{
                    textTransform: "capitalize",
                    backgroundColor: "green",
                    color: "white",
                    marginLeft: "auto",
                    padding: "5px 30px",
                    fontSize: "18px",
                  }}
                >
                  Approve
                </Button>
              </div>
{/* 
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "5px",
                }}
              >
                <Button
                  variant='outlined'
                  color='rgb(128,128,128)'
                  style={{
                    margin: "5px",
                    color: "rgb(128,128,128)",
                    textTransform: "capitalize",
                  }}
                >
                  Regular{"  "}
                  <span style={{ paddingLeft: "2px", color: "black" }}>
                    {getTotalHours("regular")}
                  </span>
                </Button>

                <Button
                  variant='outlined'
                  color='rgb(128,128,128)'
                  style={{
                    margin: "5px",
                    color: "rgb(128,128,128)",
                    textTransform: "capitalize",
                  }}
                >
                  Overtime{" "}
                  <span
                    style={{ paddingLeft: "2px", color: "rgb(32,178,170)" }}
                  >
                    2hrs
                  </span>
                </Button>

                <Button
                  variant='outlined'
                  color='rgb(128,128,128)'
                  style={{
                    margin: "5px",
                    color: "rgb(128,128,128)",
                    textTransform: "capitalize",
                  }}
                >
                  Double OT{" "}
                  <span
                    style={{ paddingLeft: "5px", color: "rgb(32,178,170)" }}
                  >
                    41.77hrs
                  </span>
                </Button>

                <Button
                  variant='outlined'
                  color='rgb(128,128,128)'
                  style={{
                    margin: "5px",
                    color: "rgb(128,128,128)",
                    textTransform: "capitalize",
                  }}
                >
                  Time Off{" "}
                  <span style={{ paddingLeft: "5px", color: "rgb(255,0,0)" }}>
                    0hrs
                  </span>
                </Button>
                <Button
                  variant='outlined'
                  color='rgb(128,128,128)'
                  style={{
                    margin: "5px",
                    color: "rgb(128,128,128)",
                    textTransform: "capitalize",
                  }}
                >
                  Worked{" "}
                  <span style={{ paddingLeft: "5px", color: "black" }}>
                    41.77hrs
                  </span>
                </Button>
                <Button
                  variant='outlined'
                  color='rgb(128,128,128)'
                  style={{
                    margin: "5px",
                    color: "rgb(128,128,128)",
                    textTransform: "capitalize",
                  }}
                >
                  Difference{" "}
                  <span style={{ paddingLeft: "5px", color: "rgb(0,128,0)" }}>
                    + 41.77hrs
                  </span>
                </Button>
                <Button
                  variant='outlined'
                  color='rgb(128,128,128)'
                  style={{
                    margin: "5px",
                    color: "rgb(128,128,128)",
                    textTransform: "capitalize",
                  }}
                >
                  Paid Total{" "}
                  <span style={{ paddingLeft: "5px", color: "black" }}>
                    41.77hrs
                  </span>
                </Button>
              </div> */}

              <TableContainer component={Paper} style={{ marginTop: "16px" }}>
                <Table
                  className={classes.table}
                  aria-label='scheduler table'
                  style={{ tableLayout: "fixed" }}
                >
                  <TableBody>
                    {shiftData.map((row, index) => {
                      return (
                        !!row?.project_name && (
                          <ProjectCard
                            key={index}
                            row={row}
                            showName={employees?.length > 1}
                            loadingFalse={setLoadingFalse}
                          />
                        )
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </main>
          <Backdrop style={{ zIndex: 9999, color: "#fff" }} open={isLoading}>
            <CircularProgress color='inherit' />
          </Backdrop>
        </ThemeProvider>
      </div>
    </>
  );
};

export default EmployeeTimesheet;
