import moment from 'moment';
import React from 'react';

function LeaveInfo({ leaves }) {
  return (
    <div
      style={{
        fontSize: '12px',
        height: 'fit-content',
        padding: '5px',
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
      }}
    >
      <div style={{ textTransform: 'capitalize' }}>
        <span style={{ fontWeight: 'bold' }}>Status: </span> {leaves.status}
      </div>
      <div>
        <p style={{ textTransform: 'capitalize' }}>
          <b>Leave Type : </b>
          {leaves.leaveType}
        </p>
      </div>
      <div>
        <p style={{ textTransform: 'capitalize' }}>
          <b>Duration : </b>
          {leaves.duration} hours
        </p>
      </div>
      <div>
        <p style={{ textTransform: 'capitalize' }}>
          <b>From : </b>
          {moment(leaves.from).format('DD-MM-YYYY')}
        </p>
      </div>
      <div>
        <p style={{ textTransform: 'capitalize' }}>
          <b>To : </b>
          {moment(leaves.to).format('DD-MM-YYYY')}
        </p>
      </div>
      <hr />
      <div>
        <p style={{ textTransform: 'capitalize' }}>
          <b>Description : </b>
          {leaves.description || <i>Nill</i>}
        </p>
      </div>
    </div>
  );
}

export default LeaveInfo;
