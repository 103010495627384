import axios from "axios";
import { Urls, BASE_URL } from "store/urls";

export const getAllRequestsRepo = (headers) => {
  return axios({
    baseURL: BASE_URL,
    method: "GET",
    url: Urls.GET_ALL_QUERY_REQUESTS,
    headers: headers,
  });
};


