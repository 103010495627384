import {
  getAllAssetSubCategoryRepo,
  postAssetSubCategoryRepo,
  deleteAssetSubCategoryRepo,
  updateAssetSubCategoryRepo,
} from "store/repositories/AssetManagement/assetSubCategory";
import {
  getAllAssetSubCategoryFulfilled,
  getAllAssetSubCategoryPending,
  getAllAssetSubCategoryRejected,
  createAssetSubCategoryPending,
  createAssetSubCategoryFulfilled,
  createAssetSubCategoryRejected,
  deleteAssetSubCategoryPending,
  deleteAssetSubCategoryFulfilled,
  deleteAssetSubCategoryRejected,
  updateAssetSubCategoryPending,
  updateAssetSubCategoryFulfilled,
  updateAssetSubCategoryRejected,
} from "store/slices/AssetManagement/assetSubCategory";

export const getAllAssetSubCategory = (params) => {
  return (dispatch) => {
    dispatch(getAllAssetSubCategoryPending());
    getAllAssetSubCategoryRepo(params)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data?.data);
          dispatch(getAllAssetSubCategoryFulfilled(response?.data?.data));
        } else {
          dispatch(getAllAssetSubCategoryRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(getAllAssetSubCategoryRejected(error.message));
        }
      });
  };
};

export const createAssetSubCategory = (data) => {
  return (dispatch) => {
    dispatch(createAssetSubCategoryPending());
    postAssetSubCategoryRepo(data)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data?.data);
          dispatch(createAssetSubCategoryFulfilled(response?.data?.data));
        } else {
          dispatch(createAssetSubCategoryRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(createAssetSubCategoryRejected(error.message));
        }
      });
  };
};

export const deleteAssetSubCategory = (id) => {
  return (dispatch) => {
    dispatch(deleteAssetSubCategoryPending());
    deleteAssetSubCategoryRepo(id)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data?.data);
          dispatch(deleteAssetSubCategoryFulfilled(response?.data?.data));
        } else {
          dispatch(deleteAssetSubCategoryRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(deleteAssetSubCategoryRejected(error.message));
        }
      });
  };
};

export const updateAssetSubCategory = (data) => {
  return (dispatch) => {
    dispatch(updateAssetSubCategoryPending());
    updateAssetSubCategoryRepo(data)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data?.data);
          dispatch(updateAssetSubCategoryFulfilled(response?.data?.data));
        } else {
          dispatch(updateAssetSubCategoryRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(updateAssetSubCategoryRejected(error.message));
        }
      });
  };
};
