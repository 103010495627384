import React, { useState } from "react";
import AddAsCondiSidebar from "./AddAsCondiSidebar";

const AssetConditionList = ({
  conditionsList,
  categoryList,
  conditionDeleteHandler,
}) => {
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [conditionInitialValues, setConditionInitialValues] = useState();

  return (
    <div className="mt-3 h-screen">
      {isOpenForm && conditionInitialValues && (
        <AddAsCondiSidebar
          setIsOpenForm={setIsOpenForm}
          categoryList={categoryList}
          conditionInitialValues={conditionInitialValues}
          setConditionInitialValues={setConditionInitialValues}
        />
      )}
      <table className="w-full mt-5 text-blue-900">
        <tr className="border-2 bg-gray-100">
          <th className="text-left pl-5 py-2">Conditions</th>
          <th className="text-left pl-5 py-2">Action</th>
        </tr>
        {conditionsList.map((el) => {
          return (
            <>
              <tr className="border-2">
                <td className="text-left pl-5 py-2">{el.name}</td>
                <td className="text-left pl-5 py-2">
                  <i
                    class="fa-sharp fa-regular fa-pen-to-square cursor-pointer"
                    onClick={() => {
                      setIsOpenForm(true);
                      setConditionInitialValues(el);
                    }}
                  ></i>
                  &nbsp; &nbsp; &nbsp; &nbsp;
                  <i
                    className="fa-solid fa-trash-can mt-1 cursor-pointer"
                    onClick={() => conditionDeleteHandler(el._id)}
                  ></i>
                </td>
              </tr>
            </>
          );
        })}
      </table>
    </div>
  );
};

export default AssetConditionList;
