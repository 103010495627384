import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  IconButton,
  Paper,
  InputBase,
  CircularProgress,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
// import axios from "axios";
import "react-phone-input-labelled/dist/style.css";
import useAuth from "hooks/useAuth";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Checkbox from "@material-ui/core/Checkbox";
import Avatar from "@material-ui/core/Avatar";
import { getUsersByOrgId } from "store/actions/HrManagement/Dashboard";
import { useDispatch, useSelector } from "react-redux";
import { updateShiftJobsiteAssignEmp } from "store/actions/ShiftManagement/ShiftProjectNSites";

function AssignEmployeesDialog(props) {
  const auth = useAuth();
  const [empDataBackUp, setEmpdataBackUp] = useState([]);
  const [empData, setEmpdata] = useState([]);
  const [assignedEmployees] = useState([]);
  const dispatch = useDispatch();

  // console.log("dialog recieved these props ->", props);

  const onCheckChange = (e, value) => {
    value.checked = e.target.checked;
    let emp = empData.find((empItem) => empItem._id === value._id);
    emp.checked = e.target.checked;
    setEmpdata(empData);
    emp = empDataBackUp.find((empItem) => empItem._id === value._id);
    emp.checked = e.target.checked;
    setEmpdataBackUp(empDataBackUp);
    setEmployeesAssigned(e.target.checked, emp);
  };

  const setEmployeesAssigned = (checked, employee) => {
    let ap = assignedEmployees.findIndex(
      (empItem) => empItem.employee_id === employee._id
    );
    if (ap >= 0) {
      assignedEmployees[ap] = { assign: checked, employee_id: employee._id };
    } else {
      assignedEmployees.push({ assign: checked, employee_id: employee._id });
    }
  };

  const { orgUsers } = useSelector((state) => state.dashboard);

  const getAllEmployees = async () => {
    const orgId = auth.user.org_id._id;
    const params = {
      page_number: 1,
      limit: Number.MAX_SAFE_INTEGER,
    };
    dispatch(getUsersByOrgId(orgId, params));
    // try {
    //   const response = await axios.get(
    //     `${process.env.REACT_APP_API_BASE_URL}/users/getbyorg/${orgId}?page_number=1&limit=20`
    //   );
    //   if (response.status === 200) {
    //     const data = response.data.data;
    //     data.data.map((v) => (v["checked"] = false));
    //     // console.log(props.jobSite?.assignedEmployees);
    //     if (props?.jobSite?.assignedEmployees.length > 0) {
    //       setSelectedAssignedEmployees(
    //         data.data,
    //         props?.jobSite?.assignedEmployees
    //       );
    //     } else {
    //       setEmpdataBackUp(data.data);
    //       setEmpdata(data.data);
    //     }
    //   }
    // } catch (e) {
    //   if (e.response) {
    //     console.log("Error >", e.response);
    //   }
    // }
  };

  const setSelectedAssignedEmployees = (allEmployees, selectedData) => {
    try {
      selectedData.forEach((item) => {
        let emp = allEmployees.find(
          (empItem) => empItem._id === item.employee_id
        );
        if (emp) {
          emp.checked = true;
          setEmployeesAssigned(true, emp);
        }
      });
      setEmpdataBackUp(allEmployees);
      setEmpdata(allEmployees);
    } catch (e) {
      console.log(e);
    }
  };

  const handleAssignEmployees = async () => {
    let payloadData = {
      jobsite_id: props.jobSite._id,
      assignedEmployees: assignedEmployees,
    };

    props.setIsLoading(true);
    handleCloseDialog();
    dispatch(updateShiftJobsiteAssignEmp(payloadData)).then(() => {
      props.afterOpn();
      props.setIsLoading(true);
    });

    // try {
    //   const response = await axios.put(
    //     `${process.env.REACT_APP_API_BASE_URL}/jobSite/assign_employee`,
    //     payloadData
    //   );
    //   if (response.status === 200) {
    //     props.afterOpn();
    //   }
    // } catch (e) {
    //   if (e.response) {
    //     console.log("Error >", e.response);
    //   }
    // }
    // handleCloseDialog();
  };

  useEffect(() => {
    getAllEmployees();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (orgUsers.fulfilled) {
      var data = JSON.parse(JSON.stringify(orgUsers.fulfilled.data));
      data.map((v) => (v["checked"] = false));
      if (props?.jobSite?.assignedEmployees.length > 0) {
        setSelectedAssignedEmployees(data, props?.jobSite?.assignedEmployees);
      } else {
        setEmpdataBackUp(data);
        setEmpdata(data);
      }
    }
    props.setIsLoading(false);
  }, [orgUsers.fulfilled]);

  const handleCloseDialog = () => {
    props.closeForm();
  };

  const onSearch = (e) => {
    const keyword = e.target.value;

    if (keyword !== "") {
      const results = empDataBackUp.filter((user) => {
        return (
          user.first_name.toLowerCase().indexOf(keyword.toLowerCase()) !== -1 ||
          user.last_name.toLowerCase().indexOf(keyword.toLowerCase()) !== -1
        );
        // Use the toLowerCase() method to make it case-insensitive
      });
      setEmpdata(results);
    } else {
      setEmpdata(empDataBackUp);
      // If the text field is empty, show all users
    }
  };

  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  const empList = () => {
    return (
      <div style={{ height: "calc(100vh - 200px)", overflowY: "scroll" }}>
        <List dense>
          {empData.map((value) => {
            // console.log("value", value);
            const labelId = `checkbox-list-secondary-label-${value._id}`;
            return (
              <ListItem key={value._id} button>
                <ListItemAvatar>
                  <Avatar
                    style={{
                      backgroundColor: "purple",
                    }}
                  >
                    {value.first_name.charAt(0).toUpperCase()}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  id={labelId}
                  primary={`${value.first_name} ${value.last_name}`}
                  secondary={`${value.position}`}
                />
                <ListItemSecondaryAction>
                  <Checkbox
                    style={{ color: "blue", marginRight: "10px" }}
                    edge='end'
                    defaultChecked={value.checked}
                    onChange={(e) => onCheckChange(e, value)}
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </div>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "10px",
          }}
        >
          <Typography
            variant='h5'
            style={{
              fontWeight: "600",
              display: "flex",
              alignItems: "center",
            }}
          >
            Assign Employee
          </Typography>

          <IconButton
            aria-label='close'
            onClick={handleCloseDialog}
            style={{
              margin: "0",
              padding: "0",
              color: "black",
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <hr />

        <Paper
          style={{
            backgroundColor: "rgb(248,248,248)",
            margin: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <InputBase
            style={{
              paddingLeft: "10px",
              width: "100%",
            }}
            onChange={onSearch}
            placeholder='  Search...'
          />
          <IconButton
            style={{ paddingleft: "-5px", paddingRight: "2px" }}
            aria-label='search'
          >
            <SearchIcon style={{ paddingleft: "-5px", paddingRight: "2px" }} />
          </IconButton>
        </Paper>

        {empData && empData.length ? (
          empList()
        ) : (
          <div
            style={{
              color: "grey",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "40px",
            }}
          >
            <CircularProgress color='inherit' size={30} />
          </div>
        )}
      </div>

      {props.operation !== "view" && (
        <div>
          <hr></hr>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "0px 10px 10px 0px",
            }}
          >
            <Button
              variant='outlined'
              color='primary'
              style={{
                marginRight: "10px",
              }}
              onClick={handleCloseDialog}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              color='primary'
              onClick={handleAssignEmployees}
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </ThemeProvider>
  );
}

export default AssignEmployeesDialog;
