import React, { useEffect } from "react";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import "@fontsource/roboto";
// import useAuth from "hooks/useAuth";
import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { useAssetStyles } from "../Style";
import TopFilterAssetAsign from "./topFilter";
import AssetAssignList from "./AssetAssignList";
import { getAllAssetGroupByUser } from "store/actions/AssetManagement/allAssets";
import { useDispatch, useSelector } from "react-redux";
import { getAllAssetCategory } from "store/actions/AssetManagement/assetCategory";
import { getAllAssetSubCategory } from "store/actions/AssetManagement/assetSubCategory";
import { getAllAssetConditon } from "store/actions/AssetManagement/assetCondition";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { clearAssetByUserId } from "store/slices/AssetManagement/allAssets";

export const AssetAssignContainer = (props) => {
  let theme = createTheme();
  theme = responsiveFontSizes(theme);
  const dispatch = useDispatch();
  const classes = useAssetStyles();
  const { allAssetListGroupByUser } = useSelector((state) => state.allAsset);
  const { assetCategory } = useSelector((state) => state);
  const [isLoading, setIsLoading] = React.useState(false);
  useEffect(() => {
    setIsLoading(true);
    dispatch(getAllAssetGroupByUser());
    dispatch(getAllAssetCategory());
    dispatch(getAllAssetSubCategory());
    dispatch(getAllAssetConditon());
  }, []);
  useEffect(() => {
    // API call goes here
    if (allAssetListGroupByUser?.fulfilled) {
      dispatch(clearAssetByUserId());
      setIsLoading(false);
    }
  }, [allAssetListGroupByUser]); //eslint-disable-line react-hooks/exhaustive-deps
  return (
    <React.Fragment>
      <div className="root">
        <ThemeProvider theme={theme}>
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: props.isDrawerOpen,
            })}
          >
            <div>
              <Typography
                style={{
                  textAlign: "left",
                  marignBottom: "20px",
                  minWidth: "230px",
                }}
              >
                Home {">"} Asset Management {">"} Asset Assigned List (Users)
              </Typography>
              <div
                style={{
                  margin: "10px",
                  marginLeft: "0px",
                }}
              >
                <div className="bg-white p-5 shadow text-left">
                  <TopFilterAssetAsign />
                  {assetCategory?.allAssetCategory?.fulfilled &&
                    allAssetListGroupByUser?.fulfilled && (
                      <AssetAssignList
                        data={allAssetListGroupByUser}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        categoryList={
                          assetCategory?.allAssetCategory?.fulfilled
                        }
                      />
                    )}
                  <Backdrop
                    style={{ zIndex: 9999, color: "#fff" }}
                    open={isLoading}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </div>
              </div>
            </div>
          </main>
        </ThemeProvider>
      </div>
    </React.Fragment>
  );
};
