import {
  getAllAssetCategoryRepo,
  postAssetCategoryRepo,
  updateAssetCategoryRepo,
  deleteAssetCategoryRepo,
} from "store/repositories/AssetManagement/assetCategory";
import {
  getAllAssetCategoryFulfilled,
  getAllAssetCategoryPending,
  getAllAssetCategoryRejected,
  createAssetCategoryPending,
  createAssetCategoryFulfilled,
  createAssetCategoryRejected,
  updateAssetCategoryPending,
  updateAssetCategoryFulfilled,
  updateAssetCategoryRejected,
  deleteAssetCategoryPending,
  deleteAssetCategoryFulfilled,
  deleteAssetCategoryRejected,
} from "store/slices/AssetManagement/assetCategory";

export const getAllAssetCategory = (params) => {
  return (dispatch) => {
    dispatch(getAllAssetCategoryPending());
    getAllAssetCategoryRepo(params)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data?.data);
          dispatch(getAllAssetCategoryFulfilled(response?.data?.data));
        } else {
          dispatch(getAllAssetCategoryRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(getAllAssetCategoryRejected(error.message));
        }
      });
  };
};

export const createAssetCategory = (data) => {
  return (dispatch) => {
    dispatch(createAssetCategoryPending());
    postAssetCategoryRepo(data)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data?.data);
          dispatch(createAssetCategoryFulfilled(response?.data?.data));
        } else {
          dispatch(createAssetCategoryRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(createAssetCategoryRejected(error.message));
        }
      });
  };
};

export const updateAssetCategory = (data) => {
  return (dispatch) => {
    dispatch(updateAssetCategoryPending());
    updateAssetCategoryRepo(data)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data?.data);
          dispatch(updateAssetCategoryFulfilled(response?.data?.data));
        } else {
          dispatch(updateAssetCategoryRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(updateAssetCategoryRejected(error.message));
        }
      });
  };
};

export const deleteAssetCategory = (data) => {
  return (dispatch) => {
    dispatch(deleteAssetCategoryPending());
    deleteAssetCategoryRepo(data)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data?.data);
          dispatch(deleteAssetCategoryFulfilled(response?.data?.data));
        } else {
          dispatch(deleteAssetCategoryRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(deleteAssetCategoryRejected(error.message));
        }
      });
  };
};
