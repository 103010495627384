import axios from "axios";
import { Urls } from "store/urls";

export const getAttendanceByOrgRepo = (id, params) => {
  return axios({
    method: "GET",
    url: Urls.GET_ATTENDANCE_BY_ORG + `${id}`,
    params: params,
  });
};

export const getLeavesApprovalRepo = (params) => {
  return axios({
    method: "GET",
    url: Urls.GET_ALL_LEAVES,
    params: params,
  });
};

export const putLeavesUpdateRepo = (data) => {
  return axios({
    method: "PUT",
    url: Urls.PUT_LEAVES_UPDATE,
    data: data,
  });
};
