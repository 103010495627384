import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  deletePosition: {},
  positionUpdate: {},
  positionCreate: {},
  positions: {},
  loading: false,
};

export const positionSlice = createSlice({
  name: 'positions',
  initialState,
  reducers: {
    deletePositionPending(state, action) {
      return {
        ...state,
        deletePosition: { loading: true },
        loading: true,
      };
    },
    deletePositionFulfilled(state, action) {
      return {
        ...state,
        deletePosition: {
          loading: false,
          fulfilled: action.payload,
        },
        loading: false,
      };
    },
    deletePositionRejected(state, action) {
      return {
        ...state,
        deletePosition: {
          loading: false,
          rejected: action.payload,
        },
        loading: false,
      };
    },

    updatePositionPending(state, action) {
      return {
        ...state,
        positionUpdate: { loading: true },
        loading: true,
      };
    },
    updatePositionFulfilled(state, action) {
      return {
        ...state,
        positionUpdate: {
          loading: false,
          fulfilled: action.payload,
        },
        loading: false,
      };
    },
    updatePositionRejected(state, action) {
      return {
        ...state,
        positionUpdate: {
          loading: false,
          rejected: action.payload,
        },
        loading: false,
      };
    },

    createPositionPending(state, action) {
      return {
        ...state,
        positionCreate: { loading: true },
        loading: true,
      };
    },
    createPositionFulfilled(state, action) {
      return {
        ...state,
        positionCreate: {
          loading: false,
          fulfilled: action.payload,
        },
        loading: false,
      };
    },
    createPositionRejected(state, action) {
      return {
        ...state,
        positionCreate: {
          loading: false,
          rejected: action.payload,
        },
        loading: false,
      };
    },

    serachPositionPending(state, action) {
      return {
        ...state,
        positions: { loading: true },
        loading: true,
      };
    },
    serachPositionFulfilled(state, action) {
      return {
        ...state,
        positions: {
          fulfilled: action.payload.data.data,
          totalLength: action.payload.data.length,
        },
        loading: false,
      };
    },
    serachPositionRejected(state, action) {
      return {
        ...state,
        positions: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
  },
});

export const {
  serachPositionPending,
  serachPositionFulfilled,
  serachPositionRejected,
  deletePositionPending,
  deletePositionFulfilled,
  deletePositionRejected,
  updatePositionPending,
  updatePositionFulfilled,
  updatePositionRejected,
  createPositionPending,
  createPositionFulfilled,
  createPositionRejected,
} = positionSlice.actions;

export default positionSlice.reducer;
