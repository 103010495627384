import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { getConditionCount } from "store/actions/AssetManagement/dashboard";
const status = [
  { text: "Active", bg: "bg-green-600", value: 0 },
  { text: "Replacemnet", bg: "bg-blue-700", value: 0 },
  { text: "Not Working", bg: "bg-yellow-600", value: 0 },
  { text: "Lost", bg: "bg-red-600", value: 0 },
  { text: "Near Warranty", bg: "bg-red-600", value: 0 },
  { text: "Out of Warranty", bg: "bg-red-600", value: 0 },
];
const AssetByStatus = (props) => {
  const { assetSubCategory } = useSelector((state) => state);
  const { conditionCount } = useSelector((state) => state.assetDashboard);
  const dispatch = useDispatch();

  const [statusCount, setStatusCount] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [filterList, setFilterList] = useState();

  const handleFilterChange = (obj) => {
    let params = {};
    if (obj?._id) {
      params.sub_category = obj._id;
    }
    dispatch(getConditionCount(params));
    setSelectedFilter(obj);
  };
  useEffect(() => {
    dispatch(getConditionCount());
  }, []);

  useEffect(() => {
    if (conditionCount.fulfilled) {
      setStatusCount(conditionCount.fulfilled);
    }
  }, [conditionCount]);

  useEffect(() => {
    if (assetSubCategory?.assetSubCategoryList?.fulfilled) {
      setFilterList(assetSubCategory?.assetSubCategoryList?.fulfilled);
    }
  }, [assetSubCategory?.assetSubCategoryList]);

  if (props)
    return (
      <div className="rounded bg-white shadow-md p-5 mt-5 w-full md:w-2/6">
        <div className="flex justify-between">
          <p className="text-xl font-semibold">Asset By status</p>
          <div className="text-blue-700">
            <Select
              placeholder="All Assets"
              classNamePrefix="select"
              value={selectedFilter}
              onChange={(obj) => handleFilterChange(obj, "sub category")}
              name="Sub Category"
              isClearable="true"
              options={filterList || []}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option._id}
            />
          </div>
        </div>
        {statusCount.map((st, index) => {
          return (
            <div className="hover:bg-gray-100 flex justify-between items-center py-3 flex-wrap cursor-pointer ">
              <div className="flex items-center gap-2 h-fit">
                <p className={`${status[index].bg} w-3 h-3 rounded-full`}></p>
                <p>{st.asset_condition}</p>
              </div>
              <p className="font-bold">{st.count}</p>
            </div>
          );
        })}
      </div>
    );
};

export default AssetByStatus;
