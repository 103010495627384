import React, { useState, useEffect } from "react";
import SidebarContent from "../../dashboard/SidebarContent";
import ShareExport from "../../dashboard/ShareExport";
import TopFilterAllAsset from "./TopFilterAssetResignations";
import { useDispatch, useSelector } from "react-redux";
import FilterSidebarContent from "./FilterResignation";
import {
  getAllAsset,
  getAllAssetGroupByUser,
} from "store/actions/AssetManagement/allAssets";
import { listToCheckbox } from "utils";

const TopFilterAssetAsign = () => {
  const dispatch = useDispatch();
  const { allAsset, assetCategory, assetSubCategory, assetCondition } =
    useSelector((state) => state);
  const [assetId, setAssetId] = useState(null);
  const [addUpdateAsset, setAddUpdateAsset] = useState(false);
  const [sidebarForAssetAssign, setSidebarForAssetAssign] = useState({
    modal: false,
    assetId: "",
  });
  const [allAssets, setAllAssets] = useState([]);

  const assignAssetHandler = (id) => {
    setSidebarForAssetAssign({
      modal: !sidebarForAssetAssign.modal,
      assetId: id,
    });
  };
  const assetAddUpdateHandler = (id) => {
    setAssetId(id);
    setAddUpdateAsset(!addUpdateAsset);
  };
  useEffect(() => {
    if (allAsset?.allAssetList?.fulfilled) {
      setAllAssets(allAsset?.allAssetList?.fulfilled);
    }
  }, [allAsset?.allAssetList]);

  const [filterSidebar, setFilterSidebar] = useState(false);
  const sidebarFilterHandler = () => {
    setFilterSidebar(!filterSidebar);
  };
  const [filterData, setFilterData] = useState([]);
  useEffect(() => {
    if (
      assetCondition?.assetConditionList?.fulfilled &&
      assetSubCategory?.assetSubCategoryList?.fulfilled &&
      assetCategory?.allAssetCategory?.fulfilled
    ) {
      createFilterData();
    }
  }, [
    assetCategory.allAssetCategory,
    assetSubCategory.assetSubCategoryList,
    assetCondition?.assetConditionList,
  ]);

  const createFilterData = () => {
    let filterCreateData = [
      {
        filtername: "Category",
        search: "",
        optionList: [
          ...listToCheckbox(assetCategory?.allAssetCategory?.fulfilled),
        ],
        label: "name",
        value: "id",
        paramKey: "category",
        paramValue: "id",
      },
      {
        filtername: "Sub Category",
        search: "",
        optionList: [
          ...listToCheckbox(assetSubCategory?.assetSubCategoryList?.fulfilled),
        ],
        label: "name",
        value: "id",
        paramKey: "sub_category",
        paramValue: "id",
      },
      {
        filtername: "Condition",
        search: "",
        optionList: [
          ...listToCheckbox(assetCondition?.assetConditionList?.fulfilled),
        ],
        label: "name",
        value: "id",
        paramKey: "condition",
        paramValue: "name",
      },
    ];
    setFilterData(filterCreateData);
  };
  return (
    <div className="flex gap-2 flex-wrap justify-between items-center">
      <TopFilterAllAsset sidebarFilterHandler={sidebarFilterHandler} />
      {/* {filterSidebar && (
        <FilterSidebarContent
          filterOpenCloseHandle={sidebarFilterHandler}
          filterData={filterData}
          setFilterData={setFilterData}
        />
      )} */}
      {/* {sidebar && <SidebarContent sidebarHandler={sidebarHandler} />} */}
      <div className="flex gap-2 flex-wrap"></div>
      <div className="flex items-center gap-4 flex-wrap">
        <div className="flex items-center gap-2 text-sm">
          <p className="w-2 h-2 rounded-full bg-green-600"></p>
          <p>Active</p>
        </div>
        <div className="flex items-center gap-2 text-sm">
          <p className="w-2 h-2 rounded-full bg-blue-700"></p>
          <p>Replacement</p>
        </div>
        <div className="flex items-center gap-2 text-sm">
          <p className="w-2 h-2 rounded-full bg-yellow-600"></p>
          <p>Not Working</p>
        </div>
        <ShareExport
          icon="fa-solid fa-ellipsis-vertical text-blue-700  cursor-pointer"
          item1="Share"
          item2="Export"
        />
      </div>
    </div>
  );
};

export default TopFilterAssetAsign;
