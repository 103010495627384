import React, { useState } from "react";
import Select from "react-select";
import AddSubCategorySidebar from "./AddSubCategorySidebar";
const SubCategoryTopFilter = ({
  categoryList,
  selectedCategory,
  onChangeCategory,
  search,
  onSearch,
}) => {
  const [isOpenForm, setIsOpenForm] = useState(false);

  return (
    <div className="p-3 shadow">
      {isOpenForm && (
        <AddSubCategorySidebar
          setIsOpenForm={setIsOpenForm}
          categoryList={categoryList}
        />
      )}

      <div className="flex gap-2 flex-wrap justify-between">
        <div className="flex flex-wrap">
          <div className="flex items-center gap-2 font-semibold">
            <p>Asset Sub Category</p>
          </div>
        </div>
        <div className="flex gap-2 flex-wrap">
          <Select
            placeholder="SelectCategory"
            className=" rounded outline-0 px-2 py-1 sm:w-52 w-full font-semibold"
            classNamePrefix="select"
            value={selectedCategory}
            options={categoryList}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option._id}
            onChange={(val) => onChangeCategory(val)}
            isClearable={true}
          />
          <input
            type="text"
            placeholder="Search..."
            className="bg-gray-100 rounded outline-0 px-2  sm:w-52 w-full font-semibold"
            value={search}
            onChange={(e) => {
              onSearch(e.target.value);
            }}
          />

          <button
            className="bg-blue-900 px-3 py-1 rounded text-gray-100"
            onClick={() => setIsOpenForm(true)}
          >
            + Add
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubCategoryTopFilter;
