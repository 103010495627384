import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components/macro";
import useAuth from "hooks/useAuth";
import moment from "moment";
import "date-fns";
import {
  Avatar,
  Button,
  makeStyles,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Snackbar,
  withStyles,
  Badge,
  Backdrop,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useRef } from "react";
import useGeoLocation from "./useGeoLocation";
import { useDispatch, useSelector } from "react-redux";
import {
  getBirthdaysNholidays,
  getAllMessages,
  getShiftByUserId,
  getAttendanceByEmpId,
} from "../../store/actions/EmployeeManagement/HomePage";
import HomeView from "./HomeView";
import RaiseQueryForm from "./RaiseQueryForm";
import ApplyLeaveForm from "./ApplyLeaveForm";
import AddMissedPunchForm from "./AddMissedPunchForm";
import PunchInOutForm from "./PunchInOutForm";
import BreakInOut from "./BreakInOutForm";

//testing updated auto master
const Container = styled.div`
  background-color: white;
  width: 100%;
  height: 100vh;

  h2 {
    color: blue;
  }

  hr {
    border: 0.5px solid #00000029;
  }
`;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 9999,
    color: "#fff",
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const Home = function (props) {
  const { shifts, attendance } = useSelector((state) => state.homepage);
  const dispatch = useDispatch();
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [canPunchOut, setCanPunchOut] = useState(false);
  const [confirmPunchIn, isConfirmPunchIn] = useState(false);
  const [confirmPunchOut, isConfirmPunchOut] = useState(false);
  const [openAttResponseModal, setOpenAttResponseModal] = useState(false);
  const [punchInTime, setPunchInTime] = useState("");
  const [punchOutTime, setPunchOutTime] = useState("");
  const [todaysAtt, setTodaysAtt] = useState({});
  const [todaysHrs, setTodaysHrs] = useState(0);
  const [displayMessage, setDisplayMessage] = useState("");
  const [displayError, setDisplayError] = useState(false);
  const [punchingSuccess, setPunchingSuccess] = useState(false);
  const [shiftsToday, setShiftsToday] = useState([]);
  const [punchingError, setPunchingError] = useState(false);
  const [canBreakOut, setCanBreakOut] = useState(false); // change to false
  const [proceedWithPunch, setProceedWithPunch] = useState(true);
  const [proceedWithAdhoc, setProceedWithAdhoc] = useState(true);
  const [choosenAttendanceType, setChoosenAttendanceType] = useState("");
  const [punchStep, setPunchStep] = useState(1);
  const [punchinType, setPunchinType] = useState("normal");
  const [worksheetuploaded, setWorksheetuploaded] = useState(false);
  const [openQuery, setOpenQuery] = useState(false);
  const [openMissedPunchin, setOpenMissedPunchin] = useState(false);
  const [attendanceType, setAttendanceType] = useState();
  const [open, setOpen] = useState(false);
  let location = useGeoLocation();
  const classes = useStyles();
  // punch step 1 -> map screen
  // punch step 2 -> face snap
  // punch step 3 -> Confirmation Screen

  const webRef = useRef(null);
  // const siteWebRef = useRef(null);

  function Alert(props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
  }
  //---------------- FACE ID  &   LOCATION -------------

  useEffect(() => {
    setIsLoading(true);
    let shiftParams = {};
    shiftParams.from = moment().format("YYYY-MM-DD");
    dispatch(getShiftByUserId(auth.user._id, shiftParams));
    dispatch(getBirthdaysNholidays(auth.user.org_id._id));
    dispatch(getAllMessages());
    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    month = month > 9 ? month : '0'+month;
    day = day > 9 ? day : '0' + day;
    let year = date.getFullYear();
    let attendanceParams = { day, month, year };
    if (auth.user?.current_shifts?.length > 0) {
      setAttendanceType("Punch");
    } else {
      setAttendanceType("Adhoc Punch");
    }
    dispatch(getAttendanceByEmpId(auth.user._id, attendanceParams));
  }, []);

  useEffect(() => {
    if (shifts?.fullfilled) {
      setShiftsToday(shifts?.fullfilled);
    }
    if (attendance?.fullfilled) {
      const data = attendance?.fullfilled;
      // setAttendanceData(data[0]);
      if (data && data.length) {
        const attData = data[0];

        setTodaysHrs(attData.duration_in_hrs);

        console.log("....step0", attData.can_punchout, attData.can_breakout);
        if (attData.can_punchout) {
          setCanPunchOut(true);
        } else {
          setCanPunchOut(false);
        }
        if (attData.can_breakout) {
          setCanBreakOut(true);
        } else {
          setCanBreakOut(false);
        }

        setTodaysAtt(attData);
        if (attData.punch_details && attData.punch_details.length) {
          let punchinDetails =
            attData.punch_details[attData.punch_details.length - 1];
          let punch_in_time = moment(punchinDetails.punchin_time).format("hh:mm a");
          setPunchInTime(punch_in_time);
          let punchoutDetails =
            attData.punch_details[attData.punch_details.length - 1];
          if (punchoutDetails.punchout_time) {
            let punch_out_time = moment(punchoutDetails.punchout_time).format("hh:mm a");
            setPunchOutTime(punch_out_time);
          }

          if (attData.can_punchin) {
            setProceedWithPunch(true);
            setProceedWithAdhoc(true);
          } else {
            if (punchinDetails.punch_type === "normal") {
              setProceedWithPunch(true);
              setProceedWithAdhoc(false);
            } else if (punchinDetails.punch_type === "adhoc") {
              setProceedWithPunch(false);
              setProceedWithAdhoc(true);
            }
          }
        }
      }
    }
    if (shifts?.fullfilled && attendance?.fullfilled) {
      setIsLoading(false);
    }
  }, [shifts, attendance]);

  return (
    <Container>
      <HomeView
        auth={auth}
        isConfirmPunchIn={isConfirmPunchIn}
        isConfirmPunchOut={isConfirmPunchOut}
        todaysHrs={todaysHrs}
        proceedWithPunch={proceedWithPunch}
        canPunchOut={canPunchOut}
        setPunchinType={setPunchinType}
        setChoosenAttendanceType={setChoosenAttendanceType}
        setPunchStep={setPunchStep}
        proceedWithAdhoc={proceedWithAdhoc}
        punchInTime={punchInTime}
        punchOutTime={punchOutTime}
        setOpen={setOpen}
        isDrawerOpen={props.isDrawerOpen}
        location={location}
        setOpenQuery={setOpenQuery}
        setOpenMissedPunchin={setOpenMissedPunchin}
      />
      {/* -----------APPLY LEAVE ------------- */}
      <ApplyLeaveForm
        auth={auth}
        setIsLoading={setIsLoading}
        open={open}
        setOpen={setOpen}
        setPunchingError={setPunchingError}
        setPunchingSuccess={setPunchingSuccess}
      />
      {/* -----------RAISE QUERY ------------- */}
      <RaiseQueryForm
        auth={auth}
        setIsLoading={setIsLoading}
        open={open}
        openQuery={openQuery}
        setPunchingSuccess={setPunchingSuccess}
        setPunchingError={setPunchingError}
        setOpenQuery={setOpenQuery}
        setOpen={setOpen}
      />
      {/* -------------- MISSED PUNCHIN ----------- */}
      <AddMissedPunchForm
        auth={auth}
        setIsLoading={setIsLoading}
        setPunchingError={setPunchingError}
        setDisplayMessage={setDisplayMessage}
        setPunchingSuccess={setPunchingSuccess}
        setDisplayError={setDisplayError}
        openMissedPunchin={openMissedPunchin}
        setOpenMissedPunchin={setOpenMissedPunchin}
      />
      {/* --------------  PUNCHIN / PUNCHOUT ----------- */}
      <PunchInOutForm
        auth={auth}
        setIsLoading={setIsLoading}
        canPunchOut={canPunchOut}
        setCanPunchOut={setCanPunchOut}
        isConfirmPunchIn={isConfirmPunchIn}
        isConfirmPunchOut={isConfirmPunchOut}
        setPunchingError={setPunchingError}
        confirmPunchIn={confirmPunchIn}
        confirmPunchOut={confirmPunchOut}
        setChoosenAttendanceType={setChoosenAttendanceType}
        punchStep={punchStep}
        location={location}
        punchInTime={punchInTime}
        punchOutTime={punchOutTime}
        setPunchStep={setPunchStep}
        webRef={webRef}
        displayMessage={displayMessage}
        shiftsToday={shiftsToday}
        setWorksheetuploaded={setWorksheetuploaded}
        setDisplayMessage={setDisplayMessage}
        punchinType={punchinType}
        choosenAttendanceType={choosenAttendanceType}
        setTodaysAtt={setTodaysAtt}
        setTodaysHrs={setTodaysHrs}
        setCanBreakOut={setCanBreakOut}
        setProceedWithAdhoc={setProceedWithAdhoc}
        setProceedWithPunch={setProceedWithPunch}
        setPunchInTime={setPunchInTime}
        setPunchOutTime={setPunchOutTime}
        setOpenAttResponseModal={setOpenAttResponseModal}
        todaysAtt={todaysAtt}
        setDisplayError={setDisplayError}
        attendanceType={attendanceType}
        setAttendanceType={setAttendanceType}
      />
      {/* -------------------Worksheet upload Notifications---------------------- */}
      <Snackbar
        open={!!worksheetuploaded}
        onClose={() => setWorksheetuploaded(false)}
        key={"Form Success"}
        autoHideDuration={3000}
      >
        <Alert severity='success'>{worksheetuploaded}</Alert>
      </Snackbar>
      {/* ------------------Display Notifications------------------------ */}
      <Snackbar
        open={!!punchingError}
        onClose={() => setPunchingError(false)}
        key={"Form Error"}
        autoHideDuration={3000}
      >
        <Alert severity='error'>{punchingError}</Alert>
      </Snackbar>
      <Snackbar
        open={!!punchingSuccess}
        onClose={() => setPunchingSuccess(false)}
        key={"Punch Success"}
        autoHideDuration={3000}
      >
        <Alert severity='success'>{punchingSuccess}</Alert>
      </Snackbar>
      {/* ===========  BREAK IN/OUT ============ */}
      <BreakInOut
        auth={auth}
        setIsLoading={setIsLoading}
        canBreakOut={canBreakOut}
        setCanBreakOut={setCanBreakOut}
        todaysAtt={todaysAtt}
        setTodaysAtt={setTodaysAtt}
        setTodaysHrs={setTodaysHrs}
        setDisplayMessage={setDisplayMessage}
        setDisplayError={setDisplayError}
        setOpenAttResponseModal={setOpenAttResponseModal}
      />
      {/* -----------------  Message Modal  ----------------------------- */}
      <Dialog
        open={openAttResponseModal}
        onClose={() => setOpenAttResponseModal(false)}
        aria-labelledby='form-dialog-title'
        style={{ padding: "12px" }}
      >
        <DialogContent style={{ height: "200px", textAlign: "center" }}>
          <StyledBadge
            overlap='circle'
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            variant='dot'
          >
            <Avatar
              src={
                auth.user.image_url ||
                "https://image.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg"
              }
              sx={{ width: 100, height: 100 }}
            />
          </StyledBadge>
          <br></br>
          <br></br>
          <br></br>
          <DialogContentText style={{ textAlign: "center" }}>
            {displayError
              ? displayError
              : // :"You have successfully logged into your attendance"}
                displayMessage}
            <br></br>
            <br></br>
            {/* <span style={{ color: "#3D9E5A", textAlign: "center" }}>
              <b style={{ color: "#1F299C" }}>Punched In at: </b>

              <b>{punchInTime}</b>
            </span> */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            style={{
              color: "#1F299C",
              border: "1px solid #1F299C",
              backgroundColor: "white",
              display: "flex",
              margin: "auto",
            }}
            onClick={() => {
              setOpenAttResponseModal(false);
              setDisplayError(false);
              setDisplayMessage("");
            }}
            color='primary'
          >
            Okay!
          </Button>
        </DialogActions>
        <br></br>
        <br></br>
      </Dialog>
      {/* ------------------------------------------------- */}
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </Container>
  );
};
export default Home;
