import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  shiftTasks: {},
  shiftTask: {},

  loading: false,
};

export const shiftTasksSlice = createSlice({
  name: 'shiftTasks',
  initialState,
  reducers: {
    getShiftTasksPending(state, action) {
      return {
        ...state,
        shiftTasks: { loading: true },
        loading: true,
      };
    },
    getShiftTasksFulfilled(state, action) {
      return {
        ...state,
        shiftTasks: {
          loading: false,
          fulfilled: action.payload.data,
          totalLength: action.payload.length,
        },
        loading: false,
      };
    },
    getShiftTasksRejected(state, action) {
      return {
        ...state,
        shiftTasks: {
          loading: false,
          rejected: action.payload,
        },
        loading: false,
      };
    },

    addShiftTaskPending(state, action) {
      return {
        ...state,
        loading: true,
        shiftTask: action.payload,
      };
    },
    addShiftTaskFulfilled(state, action) {
      return {
        ...state,
        loading: false,
        shiftTask: action.payload,
        success: true,
      };
    },
    addShiftTaskRejected(state, action) {
      return {
        ...state,
        loading: false,
        rejected: action.payload,
        success: false,
      };
    },

    updateShiftTaskPending(state, action) {
      return {
        ...state,
        loading: true,
        shiftTask: action.payload,
      };
    },
    updateShiftTaskFulfilled(state, action) {
      return {
        ...state,
        loading: false,
        shiftTask: action.payload,
        success: true,
      };
    },
    updateShiftTaskRejected(state, action) {
      return {
        ...state,
        loading: false,
        rejected: action.payload,
        success: false,
      };
    },

    deleteShiftTaskPending(state, action) {
      return {
        ...state,
        loading: true,
        shiftTask: action.payload,
      };
    },
    deleteShiftTaskFulfilled(state, action) {
      return {
        ...state,
        loading: false,
        shiftTask: action.payload,
        success: true,
      };
    },
    deleteShiftTaskRejected(state, action) {
      return {
        ...state,
        loading: false,
        rejected: action.payload,
        success: false,
      };
    },

    serachShiftTaskPending(state, action) {
      return {
        ...state,
        shiftTasks: { loading: true },
        loading: true,
      };
    },
    serachShiftTaskFulfilled(state, action) {
      return {
        ...state,
        shiftTasks: {
          fulfilled: action.payload.data.data,
          totalLength: action.payload.data.length,
        },
        laoding: false,
      };
    },
    serachShiftTaskRejected(state, action) {
      return {
        ...state,
        loading: false,
        shiftTasks: {
          rejected: action.payload,
        },
      };
    },
  },
});

export const {
  serachShiftTaskFulfilled,
  serachShiftTaskPending,
  serachShiftTaskRejected,

  getShiftTasksPending,
  getShiftTasksFulfilled,
  getShiftTasksRejected,

  addShiftTaskFulfilled,
  addShiftTaskRejected,
  addShiftTaskPending,

  updateShiftTaskFulfilled,
  updateShiftTaskRejected,
  updateShiftTaskPending,

  deleteShiftTaskRejected,
  deleteShiftTaskFulfilled,
  deleteShiftTaskPending,
} = shiftTasksSlice.actions;

export default shiftTasksSlice.reducer;
