import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  resign: {},
  resignUpdate: {},
};

export const resignationSlice = createSlice({
  name: "hrResignation",
  initialState,
  reducers: {
    getResignationPending(state, action) {
      return {
        ...state,
        resign: { loading: true },
      };
    },
    getResignationFulfilled(state, action) {
      return {
        ...state,
        resign: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    getResignationRejected(state, action) {
      return {
        ...state,
        resign: {
          loading: false,
          rejected: action.payload,
        },
      };
    },

    updateResignationPending(state, action) {
      return {
        ...state,
        resignUpdate: { loading: true },
      };
    },
    updateResignationFulfilled(state, action) {
      return {
        ...state,
        resignUpdate: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    updateResignationRejected(state, action) {
      return {
        ...state,
        resignUpdate: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
  },
});

export const {
  getResignationPending,
  getResignationFulfilled,
  getResignationRejected,
  updateResignationPending,
  updateResignationFulfilled,
  updateResignationRejected,
} = resignationSlice.actions;

export default resignationSlice.reducer;
