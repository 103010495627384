import React, { useState, useEffect, Fragment, useRef } from "react";
import GoogleMapReact from "google-map-react";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import Webcam from "react-webcam";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Api from "../../hooks/AjaxAction";
import { uuidv4 } from "utils";
import axios from "axios";
import moment from "moment";
import {
  Button,
  Divider,
  TextareaAutosize,
  Typography,
  Grid,
  Dialog,
  Hidden,
  DialogContent,
  FormControl,
  Select,
  IconButton,
  MenuItem,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import {
  postAttendancePunchIn,
  postAttendancePunchOut,
  putShiftUpdateWorksheet,
} from "store/actions/EmployeeManagement/HomePage";
import parse from "date-fns/parse";
import { format } from "date-fns-tz";

const PunchInOutForm = (props) => {
  const {
    auth,
    setPunchingError,
    confirmPunchIn,
    confirmPunchOut,
    isConfirmPunchIn,
    isConfirmPunchOut,
    setChoosenAttendanceType,
    punchStep,
    location,
    punchInTime,
    punchOutTime,
    setPunchStep,
    webRef,
    displayMessage,
    canPunchOut,
    setCanPunchOut,
    setIsLoading,
    shiftsToday,
    setWorksheetuploaded,
    setDisplayMessage,
    punchinType,
    choosenAttendanceType,
    setTodaysAtt,
    setTodaysHrs,
    setCanBreakOut,
    setProceedWithAdhoc,
    setProceedWithPunch,
    setPunchInTime,
    setPunchOutTime,
    setOpenAttResponseModal,
    todaysAtt,
    setDisplayError,
    attendanceType,
    setAttendanceType,
  } = props;
  const { postPunchInData, postPunchOutData, updateWorksheetData } =
    useSelector((state) => state.homepage);
  const dispatch = useDispatch();

  let userSnap = useRef(null);
  const siteSnap = useRef(null);
  const [displayDateTime, setDisplayDateTime] = useState("");
  const [breakCounts, setBreakCounts] = useState(0);
  const [notes, setNotes] = useState("");

  const [address] = useState("");
  const [uploadError, setUploadError] = useState("");
  const [isPunchedOut, setIsPunchedOut] = useState(false);
  const [canCloseDiaglog, setCanCloseDiaglog] = useState(true);

  const handleCloseDialoug = () => {
    isConfirmPunchIn(false);
    isConfirmPunchOut(false);
  };

  function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    var fileOfBlob = new File([blob], uuidv4() + ".jpeg");
    return fileOfBlob;
  }

  const getSiteSnapshot = () => {
    let snapshot = webRef.current.getScreenshot();
    if (snapshot) {
      // let snapshotString = snapshot.substring(23);
      // console.log("Base64 site image ==> ", snapshotString);
      siteSnap.current = snapshot;
    }
  };

  const handleSubmit = async (url, filename) => {
    setUploadError("");
    setCanPunchOut(false);
    setIsLoading(true);
    const { _id } = shiftsToday[0];
    let worksheetArr = shiftsToday[0].worksheets || [];
    worksheetArr = worksheetArr.map((item) => {
      return {
        worksheetAttachmentUrl: item.worksheetAttachmentUrl,
        worksheetFileName: item.worksheetFileName,
      };
    });
    dispatch(
      putShiftUpdateWorksheet({
        worksheets: [
          ...worksheetArr,
          {
            worksheetFileName: filename,
            worksheetAttachmentUrl: url,
          },
        ],
        _id, //shiftId
      })
    );
    setDisplayMessage(state => "Worksheet succesfully saved!");
  };
  
  useEffect(() => {
    if (updateWorksheetData.fullfilled) {
      setWorksheetuploaded("Succesfully saved!");
      setIsPunchedOut(true);
    }
    if (updateWorksheetData.rejected) {
      setUploadError("Something went wrong! Pls unchSteptry again ");
    }
    setCanCloseDiaglog(true);
    setNotes("");
    setBreakCounts(0);
    setPunchStep(6);
    setIsLoading(false);
    setTimeout(() => {
      handleCloseDialoug();
    }, 4000);
  }, [updateWorksheetData]);

  const getFaceSnapshot = function () {
    let snapshot = webRef.current.getScreenshot();
    if (snapshot) {
      userSnap.current = snapshot;
      // let snapshotString = snapshot.substring(23);
      // console.log("Base64 user image ==> ", snapshotString);
    }
  };

  // ================= PUNCH IN==============================

  const savePunchIn = async () => {
    let geolocation;
    //if current shift available attendanceType is "Punch" else "Adhoc Punch"
    // in that case , emp can choose any --> Punch / Adhoc Punch
    // if (shiftsToday.length === 0 && punchinType === "normal") {
    //   setPunchingError("You Currently Have No Shift!");
    //   return;
    // }
    // if (
    //   (choosenAttendanceType === "Punch") &
    //   (attendanceType === "Adhoc Punch")
    // ) {
    //   setPunchingError("You Currently Have No Shift!eueu");
    //   return;
    // }
    if (!userSnap.current) {
      setPunchingError("Face snapshot required");
      return;
    }

    if (!location.loaded || !location.coordinates) {
      setPunchingError("Cannot fetch Location");
      return;
    } else {
      geolocation = {
        type: "Point",
        coordinates: [location.coordinates.lat, location.coordinates.lng],
      };
      // console.log("COORDINATES =>", geolocation);
    }

    setIsLoading(true);

    // e.preventDefault();
    // console.log("todays att ", todaysAtt);
    const userId = auth.user._id;
    const orgId = auth.user.org_id._id;
    const date = new Date();
    const dateString = `${date}`;

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    month = month > 9 ? month : '0'+month;
    day = day > 9 ? day : '0' + day;

    // Split the base64 string in data and contentType
    var block = userSnap.current.split(";");
    // Get the content type of the image
    var contentType = block[0].split(":")[1]; // In this case "image/gif"
    // get the real base64 content of the file
    var realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."

    // Convert it to a blob to upload
    var blob = b64toBlob(realData, contentType);

    const onSuccess = async (data) => {
      dispatch(
        postAttendancePunchIn({
          emp_id: userId,
          org_id: orgId,
          day: `${day}`,
          month: `${month}`,
          year: `${year}`,
          punchin_face_img_url: data.file_url,
          punchin_location: geolocation,
          punchin_type: punchinType,
          current_time: moment().toISOString(),
        })
      );
    };

    const onError = (error) => (userSnap.current = null);
    Api.fileUpload(blob, "org-glen", auth.token, onSuccess, onError);

    userSnap.current = null;
  };

  useEffect(() => {
    if (postPunchInData?.fullfilled) {
      const date = new Date();
      const dateString = `${date}`;
      setIsLoading(false);
      const data = postPunchInData.fullfilled;
      setTodaysAtt(data);
      setTodaysHrs(data.duration_in_hrs);
      // ------------------------------
      if (data.can_punchout) {
        setCanPunchOut(true);
      } else {
        setCanPunchOut(false);
      }
      // -------------------------
      if (data.can_breakout) {
        setCanBreakOut(true);
      } else {
        setCanBreakOut(false);
      }
      //-----------------------------
      let punch_in_time;
      let punch_out_time;
      if (choosenAttendanceType === "Punch") {
        setProceedWithAdhoc(false);
      } else if (choosenAttendanceType === "Adhoc Punch") {
        setProceedWithPunch(false);
      }

      if (data.punch_details && data.punch_details.length) {
        let punchinDetails = data.punch_details[data.punch_details.length - 1];
        punch_in_time = moment(punchinDetails.punchin_time).format(
          "hh:mm a"
        );
        setPunchInTime(punch_in_time);
        setPunchOutTime("");
        let punchOutDetails = data.punch_details[data.punch_details.length - 1];
        if (punchOutDetails.punchout_time) {
          punch_out_time = moment(punchOutDetails.punchout_time).format("hh:mm a");
          setPunchOutTime(punch_out_time);
        }
      }
      setDisplayMessage("Thank You, Punched In Successfully");
      setPunchStep(4);
      setDisplayDateTime(dateString.substring(4, 15) + ", " + punch_in_time);
    }
    if (postPunchInData?.rejected) {
      setIsLoading(false);
      setDisplayError(postPunchInData.rejected);
      setOpenAttResponseModal(true);
      isConfirmPunchIn(false);
    }
  }, [postPunchInData]);
  // =======  Punch Steps =========================

  const handlePunchStep = async function () {
    getFaceSnapshot();
    if (canPunchOut) {
      setPunchStep(3);
      setCanCloseDiaglog(false);
      return;
    } else {
      await savePunchIn();
    }
  };

  // ========================================

  // ==================== PUNCH OUT ========================

  const handlePunchOut = async () => {
    let geolocation;

    if (!userSnap.current) {
      setPunchingError("Face snapshot required");
      return;
    }

    // if (!siteSnap.current) {
    //   setPunchingError("Work Slip required");
    //   return;
    // }

    if (!location.loaded || !location.coordinates) {
      setPunchingError("Cannot fetch Location");
      return;
    } else {
      geolocation = {
        type: "Point",
        coordinates: [location.coordinates.lat, location.coordinates.lng],
      };
      // console.log("COORDINATES =>", geolocation);
    }
    setIsLoading(true);

    // Split the base64 string in data and contentType
    var block = userSnap.current.split(";");
    // Get the content type of the image
    var contentType = block[0].split(":")[1]; // In this case "image/gif"
    // get the real base64 content of the file
    var realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."

    // Convert it to a blob to upload
    var blob = b64toBlob(realData, contentType);


    if (Object.keys(todaysAtt).length > 0) {
      if (todaysAtt.can_punchout) {
        let punchinDetails = todaysAtt.punch_details;
        let lastPunchin = punchinDetails[punchinDetails.length - 1];
        let punchInId = lastPunchin._id;
        const userId = auth.user._id;
        const orgId = auth.user.org_id._id;
        const date = new Date();
        const dateString = `${date}`;
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        month = month > 9 ? month : '0'+month;
        day = day > 9 ? day : '0' + day;
        const onUploadSuccess = async (data) => {
          dispatch(
            postAttendancePunchOut({
              punchin_id: punchInId,
              emp_id: userId,
              org_id: orgId,
              day: `${day}`,
              month: `${month}`,
              year: `${year}`,
              punchout_face_img_url: data.file_url,
              punchout_site_img_url: "",
              punchout_location: geolocation,
              current_time: moment().toISOString(),
              employeeBreakCounts: breakCounts,
              punchin_type: punchinType,
              employee_note: notes,
            })
          );
        };
        const onUploadError = (error) => console.log(error);
        Api.fileUpload(
          blob,
          "org-glen",
          auth.token,
          onUploadSuccess,
          onUploadError
        );
      }
    }
  };

  useEffect(() => {
    if (postPunchOutData.fullfilled) {
      let punch_in_time;
      let punch_out_time;
      const date = new Date();
      const dateString = `${date}`;
      setIsLoading(false);
      const data = postPunchOutData.fullfilled;
      // console.log("RESPONSE ATTENDANCE DATA ---->", data);
      setTodaysAtt(data);
      setTodaysHrs(data.duration_in_hrs);

      if (data.punch_details && data.punch_details.length) {
        let punchinDetails = data.punch_details[data.punch_details.length - 1];
        punch_in_time = moment(punchinDetails.punchin_time).format(
          "hh:mm a"
        );
        setPunchInTime(punch_in_time);
        let punchoutDetails = data.punch_details[data.punch_details.length - 1];
        if (punchoutDetails.punchout_time) {
          punch_out_time = moment(punchoutDetails.punchout_time).format("hh:mm a");
          setPunchOutTime(punch_out_time);
        }
      }

      setProceedWithAdhoc(true);
      setProceedWithPunch(true);
      setChoosenAttendanceType("");
      setDisplayMessage("Thank You, Punched Out Successfully");
      // setPunchStep(4);
      setDisplayDateTime(dateString.substring(4, 15) + ", " + punch_out_time);
      setPunchStep(4);
    }
    if (postPunchOutData.rejected) {
      setIsLoading(false);
      if (postPunchOutData.rejected) {
        if (postPunchOutData.rejected) {
          setDisplayError(postPunchOutData.rejected);
          setOpenAttResponseModal(true);
          isConfirmPunchOut(false);
        }
      }
    }
  }, [postPunchOutData]);

  const uploadWorkSheet = () => {
    const onUploadSuccess = async (data) => {
      setIsLoading(false);
      await handleSubmit(data.file_url, data.filename);
    };
    const onUploadError = (error) => console.log(error);

    // Split the base64 string in data and contentType
    var block = siteSnap.current.split(";");
    // Get the content type of the image
    var contentType = block[0].split(":")[1]; // In this case "image/gif"
    // get the real base64 content of the file
    var realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
    // Convert it to a blob to upload
    var blob = b64toBlob(realData, contentType);
    setIsLoading(true);
    Api.fileUpload(
      blob,
      "org-glen",
      auth.token,
      onUploadSuccess,
      onUploadError
    );
  };

  return (
    <Dialog
      open={confirmPunchIn || confirmPunchOut}
      // beforeClose = {}
      onClose={(event, reason) => {
        if (
          (reason && reason === "backdropClick") ||
          reason === "escapeKeyDown"
        )
          return;
        setNotes("");
        setBreakCounts(0);
        isConfirmPunchIn(false);
        isConfirmPunchOut(false);
        setChoosenAttendanceType("");
      }}
      maxWidth={"md"}
      fullWidth
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0px 5px",
          borderBottom: "2px solid lightgrey",
        }}
      >
        <Typography
          variant="h5"
          color="primary"
          style={{
            fontWeight: "600",
            display: "flex",
            alignItems: "center",
          }}
        >
          Attendance Punching
        </Typography>

        <IconButton
          aria-label="close"
          // onClick={onClose}
          onClick={() => {
            setPunchingError(null);

            if (!canCloseDiaglog && punchinType === 'normal') {
              setPunchingError("Please upload the worksheet before closing");
              return;
            }
            setNotes("");
            setBreakCounts(0);
            isConfirmPunchIn(false);
            isConfirmPunchOut(false);
            setChoosenAttendanceType("");
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>

      <DialogContent
        style={{
          textAlign: "center",
          padding: "0px",
        }}
      >
        {/* --- MAP SCREEN ----- */}
        {punchStep === 1 && location.loaded && (
          <React.Fragment>
            <div style={{ height: "60vh", width: "100%" }}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: process.env.REACT_APP_GOOGLE_API_KEY,
                }}
                defaultCenter={{
                  lat:
                    location && location.loaded ? location.coordinates?.lat : 0,
                  lng:
                    location && location.loaded ? location.coordinates?.lng : 0,
                }}
                defaultZoom={12}
              >
                <ExitToAppIcon
                  // lat={30.995504}
                  // lng={75.485979}
                  lat={location?.coordinates?.lat || 0}
                  lng={location?.coordinates?.lng || 0}
                ></ExitToAppIcon>
              </GoogleMapReact>
            </div>
            <Grid
              container
              style={{
                textAlign: "left",
                padding: "20px",
              }}
              justify-content="space-between"
            >
              <Grid
                item
                xs={12}
                md={6}
                style={{
                  height: "90px",
                }}
              >
                <Typography
                  color="primary"
                  variant="h6"
                  style={{
                    fontWeight: "600",
                  }}
                >
                  Location
                </Typography>
                <div>{address}</div>
              </Grid>

              <Hidden smDown>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                // style={{ backgroundColor: "rgba(255,255,255,0.7)" }}
                ></Divider>
              </Hidden>

              <Hidden mdUp>
                <Grid item xs={11}>
                  <Divider
                    orientation="horizontal"
                    variant="middle"
                    style={{
                      marginTop: "10px",
                    }}
                  />
                </Grid>
              </Hidden>

              <Grid item xs={12} md={5}>
                <div
                  style={{
                    height: "80px",
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{
                        color: "grey",
                      }}
                    >
                      Punched IN at:
                    </Typography>
                    <div
                      style={{
                        color: "#3D9E5A",
                      }}
                    >
                      {punchInTime ? (
                        <Typography
                          variant="subtitle1"
                          style={{
                            fontWeight: "700",
                          }}
                        >
                          {punchInTime}
                        </Typography>
                      ) : (
                        <Typography
                          variant="subtitle1"
                          style={{
                            fontWeight: "700",
                          }}
                        >
                          --:--
                        </Typography>
                      )}
                    </div>
                  </div>

                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{
                        color: "grey",
                      }}
                    >
                      Punched OUT at:
                    </Typography>
                    <div
                      style={{
                        color: "blue",
                      }}
                    >
                      {punchOutTime ? (
                        <Typography
                          variant="subtitle1"
                          style={{
                            fontWeight: "700",
                          }}
                        >
                          {punchOutTime}
                        </Typography>
                      ) : (
                        <Typography
                          variant="subtitle1"
                          style={{
                            fontWeight: "700",
                          }}
                        >
                          --:--
                        </Typography>
                      )}
                    </div>
                  </div>

                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#3D9E5A",
                      color: "#ffffff ",
                    }}
                    onClick={() => {
                      setPunchStep(2);
                    }}
                  >
                    Confirm
                  </Button>
                </div>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
        {/* ----- Face Snap ---------- */}
        {punchStep === 2 && (
          <div>
            <Webcam
              ref={webRef}
              screenshotFormat="image/jpeg"
              style={{
                marginTop: "20px",
                height: "250px",
                border: "3px solid darkblue",
                borderRadius: "50%",
              }}
            />

            <br></br>

            <Typography
              variant="subtitle"
              style={{
                color: "darkBlue",
                margin: "20px 0px",
                display: "block",
              }}
            >
              Keep Face Inside The Circle
            </Typography>

            <br></br>

            <IconButton
              onClick={async () => {
                await handlePunchStep();
              }}
              style={{
                marginBottom: "30px",
              }}
            >
              <CameraAltIcon color="primary" fontSize="large" />
            </IconButton>
          </div>
        )}

        {/* -----breakcount and emp note  ---------- */}
        {punchStep === 3 && (
          <>
            <div>
              <div>
                <Typography
                  variant="h6"
                  style={{
                    fontWeight: "600",
                    margin: "10px 20px",
                    color: "#1F299C",
                    marginTop: "20px",
                    textAlign: "left",
                  }}
                >
                  Break Count
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "20px 0",
                }}
              >
                <div>
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: "normal",
                      margin: "10px 20px",
                      color: "#616161",
                      marginTop: "0",
                      textAlign: "left",
                    }}
                  >
                    <i>
                      Please select number of breaks you took during the shift
                    </i>
                  </Typography>
                </div>
                <div style={{ width: "fit-content" }}>
                  <FormControl
                    variant="outlined"
                    style={{
                      width: "130px",
                      margin: "0px 20px",
                      color: "#090909",
                    }}
                  >
                    <Select
                      style={{
                        backgroundColor: "rgb(247,248,252)",
                        color: "#090909",
                        textAlign: "left",
                      }}
                      value={breakCounts}
                      onChange={(e) => {
                        setBreakCounts(e.target.value);
                      }}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {[0, 1, 2, 3, 4, 5].map((item) => {
                        return (
                          <MenuItem
                            style={{
                              color: "#090909",
                            }}
                            key={item}
                            value={item}
                          >
                            <span
                              style={{
                                fontWeight: "normal",
                                color: "#090909",
                              }}
                            >
                              {item}
                            </span>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                margin: "20px 0",
                minHeight: "200px",
                maxHeight: "70vh",
              }}
            >
              <Typography
                variant="h6"
                style={{
                  fontWeight: "600",
                  margin: "10px 20px",
                  color: "#1F299C",
                  marginTop: "0",
                  textAlign: "left",
                }}
              >
                Notes{" "}
                <span
                  style={{
                    fontWeight: "400",
                    fontStyle: "italic",
                    color: "grey",
                    fontSize: "12px",
                  }}
                >
                  (Optional)
                </span>
              </Typography>

              <TextareaAutosize
                name="leaving_comment"
                onChange={(e) => {
                  setNotes(e.target.value);
                }}
                style={{
                  width: "95%",
                  minHeight: "90px",
                  height: "110px",
                  backgroundColor: "rgb(247,248,252)",
                  margin: "0px 20px",
                  padding: "10px",
                }}
                aria-label="minimum height"
                minRows={10}
                placeholder="Enter Your Remark"
                value={notes}
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  paddingRight: "20px",
                  marginTop: "20px",
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    marginRight: "10px",
                    backgroundColor: "#1F299C",
                    color: "#fff",
                  }}
                  onClick={async () => {
                    await handlePunchOut();
                  }}
                >
                  Save & Next
                </Button>
              </div>
            </div>
          </>
        )}

        {/* ------- Success Screen ----------- */}
        {punchStep === 4 && (
          <div>
            <CheckCircleOutlineIcon
              fontSize="large"
              color="secondary"
              style={{
                margin: "30px 0px 10px 0px",
              }}
            />
            <Typography
              variant="h5"
              color="secondary"
              style={{
                marginBottom: "20px",
              }}
            >
              {displayMessage}
            </Typography>

            <Typography variant="caption" component="div" gutterBottom>
              {/* ({location.coordinates.lat}, {location.coordinates.lng}) */}
            </Typography>

            {!isPunchedOut && (
              <Typography
                variant="h5"
                color="primary"
                style={{
                  marginBottom: "20px",
                }}
              >
                Date and Time: {displayDateTime}
              </Typography>
            )}
            {confirmPunchOut && !isPunchedOut ? (
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#1F299C",
                  color: "#ffffff ",
                  margin: "0 10px",
                  marginBottom: "40px",
                }}
                onClick={() => {
                  punchinType === 'normal' && setPunchStep(5);
                  punchinType === 'adhoc' && handleCloseDialoug()
                }}
              >
                {punchinType === 'normal' ? 'Next' : 'Close'}
              </Button>
            ) : (
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#1F299C",
                  color: "#ffffff ",
                  margin: "0 10px",
                  marginBottom: "40px",
                }}
                onClick={() => {
                  handleCloseDialoug();
                }}
              >
                Close
              </Button>
            )}
          </div>
        )}

        {punchStep === 5 && (
          <div>
            <div>
              <Typography
                variant="h6"
                style={{
                  fontWeight: "600",
                  margin: "10px 20px",
                  color: "#1F299C",
                  marginTop: "0",
                  textAlign: "left",
                }}
              >
                Upload Worksheet <span style={{ color: "red" }}>*</span>
              </Typography>
              <Webcam
                ref={webRef}
                screenshotFormat="image/jpeg"
                style={{
                  marginTop: "20px",
                  height: "250px",
                  border: "3px solid darkblue",
                  borderRadius: "2%",
                }}
              />

              <br></br>

              <Typography
                variant="subtitle"
                style={{
                  color: "darkBlue",
                  margin: "20px 0px 0 0",
                  display: "block",
                }}
              >
                Adjust the Worksheet In The Circle
              </Typography>

              <br></br>
              <IconButton
                onClick={async () => {
                  getSiteSnapshot();
                  uploadWorkSheet();
                }}
                style={{
                  marginBottom: "5px",
                }}
              >
                <CameraAltIcon color="primary" fontSize="large" />
              </IconButton>
            </div>

            <div>
              <p style={{ color: "red" }}>{uploadError ? uploadError : ""}</p>
            </div>
          </div>
        )}

        {/* ------- Success Screen ----------- */}
        {punchStep === 6 && (
          <div>
            <CheckCircleOutlineIcon
              fontSize="large"
              color="secondary"
              style={{
                margin: "30px 0px 10px 0px",
              }}
            />
            <Typography
              variant="h5"
              color="secondary"
              style={{
                marginBottom: "20px",
              }}
            >
              {displayMessage}
            </Typography>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};
export default PunchInOutForm;
