import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import loginReducer from "../features/login/LoginSlice";
import dashboardReducer from "./slices/HrManagement/Dashboard";
import homepageReducer from "./slices/EmployeeManagement/HomePage";
import queriesAndTicketReducer from "./slices/EmployeeManagement/QueriesAndTickets";
import LeavesAttendanceHrReducer from "./slices/HrManagement/LeavesAttendanceHR";
import ResignationReducer from "./slices/EmployeeManagement/Resign";
import CommunicationReducer from "./slices/HrManagement/Communications";
import queriesAndTicketsReducer from "./slices/HrManagement/QueriesAndTickets";
import HRResignationReducer from "./slices/HrManagement/Resignations";
import MyEmployeesReducer from "./slices/HrManagement/MyEmployees";
import ShiftTemplatesReducer from "./slices/ShiftManagement/ShiftTemplates";
import AttendanceAndShiftsReducer from "./slices/EmployeeManagement/AttendanceAndShifts";
import ShiftProjectNSitesReducer from "./slices/ShiftManagement/ShiftProjectNSites";
import PostionsReducer from "./slices/ShiftManagement/Positions";
import ShiftTagsReducer from "./slices/ShiftManagement/ShiftTags";
import thunk from "redux-thunk";
import shiftTasksReducer from "./slices/ShiftManagement/ShiftTasks";
import SchedulerReducer from "./slices/ShiftManagement/Scheduler";
import auth from "./slices/auth";
import AssetDashboardReducer from "./slices/AssetManagement/dashboard";
import AssetAssignListReducer from "./slices/AssetManagement/assetAssign";
import AllAssetReducer from "./slices/AssetManagement/allAssets";
import AssetCategoryReducer from "./slices/AssetManagement/assetCategory";
import AssetSubCategoryReducer from "./slices/AssetManagement/assetSubCategory";
import AssetConditionReducer from "./slices/AssetManagement/assetCondition";
import AssetResignationReducer from "./slices/AssetManagement/assetResignationList";
const middleware = [];
if (process.env.REACT_APP_ENV === "dev") {
  const { logger } = require(`redux-logger`);
  // middleware.push(logger);
}

middleware.push(thunk);
export const store = configureStore({
  reducer: {
    auth: auth,
    counter: counterReducer,
    login: loginReducer,
    dashboard: dashboardReducer,
    homepage: homepageReducer,
    queriesAndTickets: queriesAndTicketReducer,
    leavesAttendanceHr: LeavesAttendanceHrReducer,
    shiftTasks: shiftTasksReducer,
    resignation: ResignationReducer,
    communication: CommunicationReducer,
    shiftTags: ShiftTagsReducer,
    queriesAndTicketsHr: queriesAndTicketsReducer,
    hrResignation: HRResignationReducer,
    employees: MyEmployeesReducer,
    shiftTemplates: ShiftTemplatesReducer,
    attendanceAndShifts: AttendanceAndShiftsReducer,
    shiftProjectNSites: ShiftProjectNSitesReducer,
    positions: PostionsReducer,
    scheduler: SchedulerReducer,
    assetDashboard: AssetDashboardReducer,
    assetAssign: AssetAssignListReducer,
    allAsset: AllAssetReducer,
    assetCategory: AssetCategoryReducer,
    assetSubCategory: AssetSubCategoryReducer,
    assetCondition: AssetConditionReducer,
    assetResignation: AssetResignationReducer,
  },
  middleware,
});
