import React from "react";
import { Typography, Grid, Paper } from "@material-ui/core";
import useAuth from "hooks/useAuth";
import myStyles from "../ProfileStyles";

function OrganisationInfoPanel() {
  const myClasses = myStyles();
  const auth = useAuth();
  return (
    <Grid container direction='row' spacing={4}>
      {/* L1 Manager Details------------  */}
      <Grid item xs={12} sm={6} lg={4}>
        <Paper component='div' className={myClasses.paper}>
          <Grid container direction='row' justify='space-evenly' alignItems="center">
            <Grid
              item
              xs={10}
              style={{ textAlign: "left", margin: "30px 0px 60px 0px" }}
            >
              <Typography variant='h5' color='primary'>
                L1 Manager Details
              </Typography>
            </Grid>

            <Grid item xs={3} style={{ textAlign: "left" }}>
              <img
                alt='manager-avatar'
                // change later
                src={
                  auth.user.manager_id ||
                  "https://image.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg"
                }
                className={myClasses.orgAvatar}
              />
            </Grid>

            <Grid item xs={2} style={{ textAlign: "left" }}>
            <Typography variant="subtitle1" className={myClasses.info}>
                &nbsp; Name :{" "}
              </Typography>
              <Typography variant="subtitle1" className={myClasses.info}>
                &nbsp; Email :{" "}
              </Typography>
              <Typography variant="subtitle1" className={myClasses.info}>
                &nbsp; Contact :{" "}
              </Typography>
            </Grid>
            <Grid item xs={5} style={{ textAlign: "left" }}>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {/* change later */}
                {auth.user.l1_manager_details?.l1_name || ""}{" "}
              </Typography>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {/* change later */}
                {auth.user.l1_manager_details?.l1_email || ""}{" "}
              </Typography>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {/* change later */}
                {auth.user.l1_manager_details?.l1_contact || ""}{" "}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {/* HR Manager ------------  */}
      <Grid item xs={12} sm={6} lg={4}>
        <Paper component='div' className={myClasses.paper}>
          <Grid container direction='row' justify='space-evenly' alignItems="center">
            <Grid
              item
              xs={10}
              style={{ textAlign: "left", margin: "30px 0px 60px 0px" }}
            >
              <Typography variant='h5' color='primary'>
                HR Manager Details
              </Typography>
            </Grid>

            <Grid item xs={3} style={{ textAlign: "left" }}>
              <img
                alt='hr-avatar'
                // change later
                src={
                  auth.user.hr_id ||
                  "https://image.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg"
                }
                className={myClasses.orgAvatar}
              />
            </Grid>
            
            <Grid item xs={2} style={{ textAlign: "left" }}>
            <Typography variant="subtitle1" className={myClasses.info}>
                &nbsp; Name :{" "}
              </Typography>
              <Typography variant="subtitle1" className={myClasses.info}>
                &nbsp; Email :{" "}
              </Typography>
              <Typography variant="subtitle1" className={myClasses.info}>
                &nbsp; Contact :{" "}
              </Typography>
          </Grid>

            <Grid item xs={5} style={{ textAlign: "left" }}>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {/* change later */}
                {auth.user.hr_manager_details?.hr_name || ""}{" "}
              </Typography>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {/* change later */}
                {auth.user.hr_manager_details?.hr_email || ""}{" "}
              </Typography>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {/* change later */}
                {auth.user.hr_manager_details?.hr_contact || ""}{" "}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {/* Supervisor ------------  */}
      <Grid item xs={12} sm={6} lg={4}>
        <Paper component='div' className={myClasses.paper}>
          <Grid container direction='row' justify='space-evenly' alignItems="center">
            <Grid
              item
              xs={10}
              style={{ textAlign: "left", margin: "30px 0px 60px 0px" }}
            >
              <Typography variant='h5' color='primary'>
                Supervisor Details
              </Typography>
            </Grid>

            <Grid item xs={3} style={{ textAlign: "left" }}>
              <img
                alt='supervisor-avatar'
                // change later
                src={
                  auth.user.hr_id ||
                  "https://image.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg"
                }
                className={myClasses.orgAvatar}
              />
            </Grid>
            <Grid item xs={2} style={{ textAlign: "left" }}>
            <Typography variant="subtitle1" className={myClasses.info}>
                &nbsp; Name :{" "}
              </Typography>
              <Typography variant="subtitle1" className={myClasses.info}>
                &nbsp; Email :{" "}
              </Typography>
              <Typography variant="subtitle1" className={myClasses.info}>
                &nbsp; Contact :{" "}
              </Typography>
          </Grid>

            <Grid item xs={5} style={{ textAlign: "left" }}>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {/* change later */}
                {auth.user.supervisor_details?.sup_name || ""}{" "}
              </Typography>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {/* change later */}
                {auth.user.supervisor_details?.sup_email || ""}{" "}
              </Typography>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {/* change later */}
                {auth.user.supervisor_details?.sup_contact || ""}{" "}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default OrganisationInfoPanel;
