import axios from "axios";
import { Urls, BASE_URL } from "store/urls";

export const updateEmployeeStatusRepo = (body) => {
  console.log('body',body);
  return axios({
    baseURL: BASE_URL,
    method: "PUT",
    url: Urls.UPDDATE_EMPLOYEE_STATUS,
    data: body
  });
};

