import React, { useState, useEffect, Fragment } from 'react';
import {
  Typography,
  //InputBase,
  //InputLabel,
  //Select,
  Button,
  Menu,
  Dialog,
  DialogContent,
  MenuItem,
  //FormControl,
  IconButton,
  Paper,
  Grid,
  TablePagination,
  TextField,
  Backdrop,
  CircularProgress,
  InputLabel,
  Select,
  FormControl,
  Snackbar,
} from '@material-ui/core';
import clsx from 'clsx';
import '@fontsource/roboto';
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { useDasboardStyles } from './Style';
//import SearchIcon from "@material-ui/icons/Search";
// import LocationOnOutlinedIcon from "@material-ui/icons/LocationOn";
import { AccessTimeOutlined, MoreHoriz } from '@material-ui/icons';
import AddShiftTemplateDialog from './AddShiftTemplateDialog';
// import axios from "axios";
import Avatar from '@material-ui/core/Avatar';
import moment from 'moment';
import { Alert, Autocomplete } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
// import { getAllPosition, deleteTemplates } from "features/ShiftManagementSlice";
import {
  getAllTemplates,
  getAllShiftPostions,
  deleteShiftTemplate,
  serachShiftTemplates,
} from '../../../store/actions/ShiftManagement/ShiftTemplates';

export function SShiftTemplates(props) {
  let theme = createTheme();
  const dispatch = useDispatch();
  theme = responsiveFontSizes(theme);
  const classes = useDasboardStyles();

  const [shiftTemplateData, setShiftTemplateData] = useState([]);
  const [shiftTemplateSelected, setShiftTemplateSelected] = useState({});
  const [dialogOperation, setDialogOperation] = useState('');
  const [readOnly, setReadOnly] = useState(false);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [shiftTempDeleteErr, setShiftTempDeleteErr] = useState('');
  const [limit, setLimit] = useState(25);
  const [totalLength, setTotalLength] = useState(0);
  const [searchKey, setSearchKey] = useState('');
  const [status, setStatus] = useState('true');
  const [jobsites, setJobsites] = useState('');

  // --- menu config -------------
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { templates } = useSelector((state) => state.shiftTemplates);
  // const { positions } = useSelector((state) => state.shiftTemplates);
  const { deleteTemptate } = useSelector((state) => state.shiftTemplates);

  const handleClick = (event, props) => {
    setAnchorEl(event.currentTarget);
    setShiftTemplateSelected(props);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // ----------------------------

  // ----------- options config -----------------
  const [openDialog, setOpenDialog] = useState(false);
  // const [allPositions, setAllPositions] = useState([]);

  const handleCreateDialogOpen = () => {
    // console.log(" opening dialog to create");
    handleClose();
    setDialogOperation('create');
    setReadOnly(false);
    setOpenDialog(true);
  };

  const handleEditDialogOpen = () => {
    // console.log(" opening dialog for edit");
    handleClose();
    setDialogOperation('edit');
    setReadOnly(false);
    setOpenDialog(true);
  };

  const handleDeleteShiftTemplate = async () => {
    setIsLoading(true);
    dispatch(deleteShiftTemplate(shiftTemplateSelected._id));
    setIsLoading(false);
    handleClose();
  };

  const handleCloseDialog = () => {
    handleClose();
    setOpenDialog(false);
  };

  const getAllShiftTemplates = async () => {
    handleSearch();
  };

  const getTimeBar = (row) => {
    let hrs = Math.abs(
      moment(row.end_time, 'hh:mm a').diff(
        moment(row.start_time, 'hh:mm a'),
        'hours'
      )
    );

    return hrs + ' Hours  | Break Time : ' + row.breck_time;
  };

  const getAllPositions = async () => {
    setIsLoading(true);
    dispatch(getAllShiftPostions());
  };

  useEffect(() => {
    if (templates?.fulfilled) {
      const { data, length } = templates.fulfilled;
      setShiftTemplateData(data);
      setIsLoading(false);
      setTotalLength(length);
    }
  }, [templates]);

  useEffect(() => {
    if (deleteTemptate?.fulfilled) {
      dispatch(getAllTemplates());
    }
  }, [deleteTemptate]);

  // useEffect(() => {
  //   if (positions.fulfilled) {
  //     setAllPositions(positions.fulfilled.data);
  //   }
  // });

  useEffect(() => {
    window.scroll(0, 0);
    const fetch = async () => {
      getAllShiftTemplates();
      await getAllPositions();
    };
    fetch();
  }, []);

  const handleSearch = (obj) => {
    let stat = obj?.status,
      search = obj?.searchKey,
      jobsite = obj?.jobsite;

    if (!jobsite) jobsite = jobsites;
    if (jobsite === 'all') jobsite = '';

    if (!stat) stat = status;
    if (stat === 'all') stat = '';

    if (!search) search = searchKey;
    if (search === 'all') search = '';

    let data = {
      status: stat,
      page: obj?.page || page +1,
      limit: obj?.limit || limit,
      searchKey: search,
      jobsite,
    };
    dispatch(serachShiftTemplates(data));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    handleSearch({
      status,
      limit,
      page: newPage +1,
      searchKey,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    let l = parseInt(event.target.value, 10);
    setLimit(l);
    setPage(0);
    handleSearch({
      status,
      limit: l,
      page: 1,
      searchKey,
    });
  };
  const setState = () => {
    setJobsites('all');
    setSearchKey('');
    setStatus('all');
  };
  return (
    <Fragment>
      <div className='root'>
        <ThemeProvider theme={theme}>
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: props.isDrawerOpen,
            })}
          >
            <Typography
              style={{
                textAlign: 'left',
                marignBottom: '20px',
                minWidth: '230px',
              }}
            >
              Home {'>'} Shift Management {'>'} Shift Templates
            </Typography>

            <Paper
              style={{
                padding: '20px 20px 50px 20px',
                minWidth: '230px',
                marginTop: '20px',
                minHeight: '800px',
              }}
            >
              <Typography
                variant='h4'
                align='left'
                style={{
                  color: '#1F299C',
                  fontWeight: '600',
                  paddingBottom: '15px',
                }}
              >
                Shift Templates
              </Typography>
              <div
                style={{
                  display: 'flex',
                  marginBottom: '20px',
                  position: 'relative',
                }}
              >
                <Grid container justifyContent='flex-start' alignItems='center'>
                  <Grid item>
                    <Autocomplete
                      clearOnEscape
                      id='combo-box-demo'
                      options={shiftTemplateData}
                      getOptionLabel={(row) => `${row.shift_template_title}`}
                      style={{ width: '225px', marginRight: '20px' }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label='Search Template'
                          variant='outlined'
                        />
                      )}
                      onChange={(event, value1, reason) => {
                        const searchTerm = value1?.shift_template_title;
                        if (
                          reason === 'clear' ||
                          value1 === null ||
                          searchTerm.length < 1
                        ) {
                          setSearchKey('');
                          handleSearch({
                            searchKey: 'all',
                            page: page +1
                          });
                          return;
                        }
                        setSearchKey(searchTerm);
                        handleSearch({
                          searchKey: searchTerm,
                          page: page + 1
                        });
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <FormControl
                      variant='outlined'
                      style={{
                        minWidth: '225px',
                        marginRight: '20px',
                      }}
                    >
                      <InputLabel id='demo-simple-select-label-1'>
                        Status
                      </InputLabel>
                      <Select
                        labelId='demo-simple-select-label-1'
                        label='Status'
                        value={status}
                        id='demo-simple-select'
                        onChange={(event) => {
                          setJobsites('all');
                          setStatus(event.target.value);
                          handleSearch({
                            status: event.target.value,
                            jobsite: 'all',
                          });
                        }}
                      >
                        <MenuItem value='all'>All</MenuItem>
                        <MenuItem value={'true'}>Active</MenuItem>
                        <MenuItem value={'false'}>Inactive</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl
                      variant='outlined'
                      style={{
                        minWidth: '225px',
                        marginRight: '20px',
                      }}
                    >
                      <InputLabel id='demo-simple-select-label-1'>
                        Job Site
                      </InputLabel>
                      <Select
                        labelId='demo-simple-select-label-1'
                        label='Job Site'
                        value={jobsites}
                        id='demo-simple-select'
                        onChange={(event) => {
                          setStatus('all');
                          let job = event.target.value;
                          setJobsites(job);
                          handleSearch({ jobsite: job });
                        }}
                      >
                        <MenuItem value='all'>All</MenuItem>
                        {shiftTemplateData &&
                          shiftTemplateData.length &&
                              shiftTemplateData.map((row) => {
                            return (
                              <MenuItem
                                value={row.schedule?._id}
                                key={row.schedule?._id}
                              >
                                {row.schedule?.jobSite_title}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Button
                  variant='contained'
                  style={{
                    color: 'white',
                    backgroundColor: 'rgb(31, 41, 156)',
                    height: '56px',
                    marginLeft: 'auto',
                    minWidth: 'fit-content',
                    maxWidth: '200px',
                  }}
                  onClick={handleCreateDialogOpen}
                >
                  <span style={{ fontSize: '1.5em' }}>+</span>&nbsp;Add Shift
                  Templates
                </Button>
              </div>
              <hr />

              {/* apply mapping here ------------------ */}
              {shiftTemplateData && shiftTemplateData.length ? (
                shiftTemplateData.map((row, index) => (
                  <div key={index + 'key'}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        borderBottomWidth: '2px',
                        padding: '10px 0px',
                      }}
                    >
                      <div className={classes.nameTagContainer}>
                        <Avatar
                          style={{
                            marginRight: '10px',
                            backgroundColor: 'purple',
                          }}
                        >
                          {' '}
                          {row.shift_template_title
                            ? row?.shift_template_title
                                ?.charAt(0)
                                ?.toUpperCase()
                            : ''}
                        </Avatar>
                      </div>

                      <Grid container>
                        <Grid item xs={11} style={{ textAlign: 'left' }}>
                          <Grid
                            container
                            justifyContent='space-between'
                            style={{
                              paddingLeft: '10px',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <Grid item lg={3}>
                              <Typography
                                variant='h6'
                                style={{
                                  fontWeight: '600',
                                  textAlign: 'left',
                                }}
                              >
                                {row.shift_template_title}
                              </Typography>
                              <div
                                style={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                }}
                              >
                                <Typography
                                  variant='subtitle1'
                                  style={{
                                    color: 'grey',
                                  }}
                                >
                                  {getTimeBar(row)}
                                </Typography>
                              </div>
                            </Grid>

                            <Grid
                              item
                              className={classes.colorBlue}
                              lg={3}
                              style={{
                                positon: 'relative',
                                display: 'flex',
                                flexWrap: 'nowrap',
                                alignItems: 'baseline',
                                alignContent: 'center',
                              }}
                            >
                              <Typography
                                variant='subtitle1'
                                component={'span'}
                                style={{
                                  fontWeight: '600',
                                }}
                              >
                                &nbsp; Job Site:&nbsp;
                              </Typography>

                              <Typography
                                variant='subtitle1'
                                component={'span'}
                                style={{
                                  color: 'grey',
                                }}
                              >
                                {row.schedule ? row.schedule.jobSite_title
                                  : 'Not assigned'}
                              </Typography>
                            </Grid>

                            <Grid
                              item
                              className={classes.colorBlue}
                              lg={2}
                              style={{
                                positon: 'relative',
                                display: 'flex',
                                flexWrap: 'wrap',
                                alignItems: 'baseline',
                                alignContent: 'center',
                              }}
                            >
                              <AccessTimeOutlined
                                style={{
                                  position: 'relative',
                                  top: '5px',
                                }}
                              />
                              <Typography
                                variant='subtitle1'
                                component={'span'}
                                style={{
                                  fontWeight: '600',
                                }}
                              >
                                &nbsp; Time:&nbsp;
                              </Typography>

                              <Typography
                                variant='subtitle1'
                                component={'span'}
                                style={{
                                  color: 'grey',
                                }}
                              >
                                {row.start_time}
                                &nbsp;to&nbsp;
                                {row.end_time}
                              </Typography>
                            </Grid>

                            <Grid
                              item
                              lg={1}
                              style={{
                                margin: 'auto 0px',
                                display: 'flex',
                              }}
                            >
                              <div
                                style={
                                  row.active === 'true'
                                    ? {
                                        width: '20px',
                                        height: '20px',
                                        borderRadius: '50%',
                                        backgroundColor: 'lightGreen',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                      }
                                    : {
                                        width: '20px',
                                        height: '20px',
                                        borderRadius: '50%',
                                        backgroundColor: 'lightGrey',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                      }
                                }
                              >
                                <div
                                  style={
                                    row.active === 'true'
                                      ? {
                                          width: '10px',
                                          height: '10px',
                                          borderRadius: '50%',
                                          backgroundColor: 'green',
                                        }
                                      : {
                                          width: '10px',
                                          height: '10px',
                                          borderRadius: '50%',
                                          backgroundColor: 'grey',
                                        }
                                  }
                                ></div>
                              </div>
                              &nbsp;&nbsp;
                              <Typography
                                style={
                                  row.active === 'true'
                                    ? {
                                        color: 'green',
                                      }
                                    : {
                                        color: 'grey',
                                      }
                                }
                                variant='subtitle2'
                              >
                                {row.active === 'true' ? 'Active' : 'Inactive'}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          xs={1}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <IconButton
                            style={{ padding: '0px' }}
                            id='basic-button'
                            aria-controls='basic-menu'
                            aria-haspopup='true'
                            aria-expanded={open ? 'true' : undefined}
                            onClick={(event) => {
                              handleClick(event, row);
                            }}
                          >
                            <MoreHoriz style={{ padding: '0px' }} />
                          </IconButton>

                          <Menu
                            id='basic-menu'
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                              'aria-labelledby': 'basic-button',
                            }}
                          >
                            {/* <MenuItem onClick={handleClose}>
                              More Details
                            </MenuItem> */}
                            <MenuItem onClick={handleEditDialogOpen}>
                              Edit
                            </MenuItem>
                            <MenuItem onClick={handleDeleteShiftTemplate}>
                              Delete
                            </MenuItem>
                          </Menu>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                ))
              ) : (
                <p
                  style={{
                    color: 'grey',
                    margin: '1rem auto',
                  }}
                >
                  <i>No Shift Templates found</i>
                </p>
              )}
              {totalLength ? (
                <TablePagination
                  style={{ backgroundColor: '#F7F8FC' }}
                  rowsPerPageOptions={[25, 50]}
                  component='div'
                  count={totalLength}
                  rowsPerPage={limit}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              ) : (
                <></>
              )}
              {/* map till here ---------------- */}

              <Dialog
                open={openDialog}
                maxWidth='sm'
                fullWidth
                // TransitionComponent={Transition}
                keepMounted
                aria-describedby='alert-dialog-slide-description'
                PaperProps={{
                  style: {
                    position: 'absolute',
                    margin: '0px',
                    padding: '0px',
                    bottom: '0px',
                    right: '0px',
                    minHeight: '111vh',
                    top: '0px',
                    borderRadius: '0px',
                  },
                }}
              >
                <DialogContent
                  style={{
                    margin: '0px',
                    padding: '0px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  {openDialog && (
                    <AddShiftTemplateDialog
                      operation={dialogOperation}
                      closeForm={handleCloseDialog}
                      shiftTemplate={shiftTemplateSelected}
                      readOnly={readOnly}
                      setIsLoading={setIsLoading}
                      afterOpn={getAllShiftTemplates}
                      setState={setState}
                    />
                  )}
                </DialogContent>
              </Dialog>
            </Paper>
          </main>
        </ThemeProvider>
      </div>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <Snackbar
        open={!!shiftTempDeleteErr}
        autoHideDuration={6000}
        onClose={() => setShiftTempDeleteErr('')}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setShiftTempDeleteErr('')}
          severity='error'
          sx={{ width: '100%' }}
        >
          {shiftTempDeleteErr}
        </Alert>
      </Snackbar>
    </Fragment>
  );
}

// export default SM_Dashboard;
