export const PAGE_ROUTE_HOME = "/";
export const PAGE_ROUTE_LOGIN = "/login";
export const PAGE_ROUTE_TNCS = "/tncs";
export const PAGE_ROUTE_PAYROLL = "/payroll";
export const PAGE_ROUTE_EMPLOYEE = "/employees";

export const PAGE_ROUTE_COMMUNICATION = "/communication";

export const PAGE_ROUTE_LEAVES = "/leaves";
export const PAGE_ROUTE_PROJECTS = "/projects";
export const PAGE_ROUTE_PROFILE = "/profile";
export const PAGE_ROUTE_RESIGN = "/resign";
export const PAGE_ROUTE_EDIT_PROFILE = "/edit-profile";
export const PAGE_RESET_PASSWORD = "/resetpassword/:refId/:resetToken";
export const PAGE_FORGOT_PASSWORD = "/forgot";
export const PAGE_SHIFTS = "/shifts";
export const PAGE_ROUTE_DASHBOARD = "/dashboard";
export const PAGE_ROUTE_LEAVES_ATTENDANCE = "/LeavesAttendanceHR";
export const PAGE_ROUTE_SHIFT_SCHEDULE = "/ShiftSchedule";
export const PAGE_ROUTE_QUERIES_ALL_TICKET = "/QueriesAllTicket";
export const PAGE_ROUTE_DOCUMENT = "/Documents";
export const PAGE_ROUTE_RESIGNATION = "/ResignationHR";
export const PAGE_ROUTE_ACCEPT_QUERIES = "/queriesAndTickets";

export const PAGE_ROUTE_ASSETS = "/assets/dashboard";
export const PAGE_ROUTE_All_ASSETS = "/assets/allassets";
export const PAGE_ROUTE_All_ASSETS_DETAILS = "/assets/assetdetails/:id";
export const PAGE_ROUTE_EMPLOYASSETMANAGEMENT = "/employassemanagement";
export const PAGE_ROUTE_EMPLOYPOSITION = "/employposition";
export const PAGE_ROUTE_ASSET_ASSIGN = "/assets/assetassign";
export const PAGE_ROUTE_ASSET_ASSIGN_DETAILS = "/assets/assetassigndetails/:id";
export const PAGE_ROUTE_ASSET_ADD_CATEGORY = "/assets/addcategory";
export const PAGE_ROUTE_ASSET_ADD_SUB_CATEGORY = "/assets/addsubcategory";
export const PAGE_ROUTE_ASSET_ADD_ASSET_CONDITION = "/assets/addassetcondition";
export const PAGE_ROUTE_ALL_ASSIGNED_ASSETS = "/assets/allassignedassets";
export const PAGE_ROUTE_ASSET_NEW_REQUEST = "/assets/newrequests";
export const PAGE_ROUTE_RESIGNATION_TERMINATION = "/assets/resignandterminate";

export const PAGE_ROUTE_SHIFT_MNGMT_DASHBOARD = "/shift-mngmt/dashboard";
export const PAGE_ROUTE_SHIFT_MNGMT_TIMESHEETS = "/shift-mngmt/timesheets";
export const PAGE_ROUTE_SHIFT_MNGMT_EMPLOYEE_TIMESHEET =
  "/shift-mngmt/timesheets/employees";
export const PAGE_ROUTE_SHIFT_MNGMT_ALLEMPLOYEES = "/shift-mngmt/all-employees";
export const PAGE_ROUTE_SHIFT_MNGMT_PROJECTSANDSITES =
  "/shift-mngmt/projectsAndSites";
export const PAGE_ROUTE_SHIFT_MNGMT_ALLPROJECTS = "/shift-mngmt/all-projects";
export const PAGE_ROUTE_SHIFT_MNGMT_POSITIONS = "/shift-mngmt/positions";
export const PAGE_ROUTE_SHIFT_MNGMT_SCHEDULER = "/shift-mngmt/scheduler";
export const PAGE_ROUTE_SHIFT_MNGMT_TAGS = "/shift-mngmt/tags";
export const PAGE_ROUTE_SHIFT_MNGMT_SHIFTTEMPLATES =
  "/shift-mngmt/shift-templates";
export const PAGE_ROUTE_SHIFT_MNGMT_SHIFTTASKS = "/shift-mngmt/shift-tasks";
