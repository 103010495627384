import React from "react";
import {
  Hidden,
  Typography,
  Divider,
  Container,
  CssBaseline,
  Grid,
} from "@material-ui/core";
import moment from "moment";
import clsx from "clsx";
import "@fontsource/roboto";
import myStyles from "./ProfileStyles";
import useAuth from "hooks/useAuth";
import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import ScrollableTabsButtonAuto from "./components/Tabs";

function Profile(props) {
  const classes = myStyles();
  const auth = useAuth();
  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  return (
    <React.Fragment>
      <CssBaseline>
        <Container className={classes.container}>
          <div
            className={clsx(classes.content, {
              [classes.contentShift]: props.isDrawerOpen,
            })}
          >
            {auth.user && (
              <Grid
                container
                justify='space-evenly'
                className={classes.profileBrief}
                style={{ color: "white", marginBottom: "2%" }}
              >
                <Grid item xs={11} sm={5}>
                  <Grid container spacing={6} alignItems='center'>
                    {/* ***custom Avatar*** */}
                    <Grid item xs={5} sm={6} md={4} lg={5}>
                      <img
                        alt='emp-avatar'
                        src={
                          auth.user.image_url ||
                          "https://image.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg"
                        }
                        className={classes.avatar}
                      />
                    </Grid>

                    <Grid item xs={7} sm={6} md={8} lg={7}>
                      <ThemeProvider theme={theme}>
                        <Grid container direction='column' spacing={6}>
                          <Grid item>
                            <Typography align='left' variant='h5'>
                              {" "}
                              {auth.user.first_name} {auth.user.last_name}
                            </Typography>
                            <Typography
                              align='left'
                              variant='subtitle1'
                              className={classes.fadedWhite}
                            >
                              {auth.user.role}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Hidden smDown>
                              <div style={{ textAlign: "left" }}>
                                <Typography
                                  variant='subtitle2'
                                  className={classes.fadedWhite}
                                  display='inline'
                                >
                                  {" "}
                                  Employee Id &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                                </Typography>
                                <Typography
                                  variant='body2'
                                  display='inline'
                                  style={{ fontWeight: "600" }}
                                >
                                  {auth.user.emp_code}
                                </Typography>
                              </div>

                              <div style={{ textAlign: "left" }}>
                                <Typography
                                  align='left'
                                  variant='subtitle2'
                                  className={classes.fadedWhite}
                                  display='inline'
                                >
                                  {" "}
                                  Last punched in &nbsp; &nbsp;
                                </Typography>
                                <Typography
                                  variant='body2'
                                  display='inline'
                                  style={{ fontWeight: "600" }}
                                >
                                  {auth.user.last_punchin_timestamp ? (
                                    <b>
                                      {moment(
                                        auth.user.last_punchin_timestamp
                                      ).format("ddd, MMM Do YY, h:mm a")}
                                    </b>
                                  ) : (
                                    "You have not punched in yet"
                                  )}
                                </Typography>
                              </div>
                            </Hidden>
                          </Grid>
                        </Grid>
                      </ThemeProvider>
                    </Grid>
                  </Grid>

                  <Hidden mdUp>
                    <Grid item xs={12} sm={10}>
                      <ThemeProvider theme={theme}>
                        <div style={{ textAlign: "left" }}>
                          <Typography
                            variant='subtitle2'
                            className={classes.fadedWhite}
                            display='inline'
                          >
                            {" "}
                            Employee Id &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                          </Typography>
                          <Typography
                            variant='body2'
                            display='inline'
                            style={{ fontWeight: "600" }}
                          >
                            {auth.user.emp_code}
                          </Typography>
                        </div>

                        <div style={{ textAlign: "left" }}>
                          <Typography
                            align='left'
                            variant='subtitle2'
                            className={classes.fadedWhite}
                            display='inline'
                          >
                            {" "}
                            Last punched in &nbsp; &nbsp;{" "}
                          </Typography>
                          <Typography
                            variant='body2'
                            display='inline'
                            style={{ fontWeight: "600" }}
                          >
                            {auth.user.last_punchin_timestamp ? (
                              <b>
                                {moment(
                                  auth.user.last_punchin_timestamp
                                ).format("ddd, MMM Do YY, h:mm a")}
                              </b>
                            ) : (
                              "You have not yet punched in yet"
                            )}
                          </Typography>
                        </div>
                      </ThemeProvider>
                    </Grid>
                  </Hidden>
                </Grid>

                {/* ------------------------------------------------------------------------------ */}
                <Hidden xsDown>
                  <Divider
                    orientation='vertical'
                    flexItem
                    style={{ backgroundColor: "rgba(255,255,255,0.7)" }}
                  ></Divider>
                </Hidden>

                <Hidden smUp>
                  <Grid item xs={11}>
                    <Divider
                      orientation='horizontal'
                      style={{ backgroundColor: "rgba(255,255,255,0.7)" }}
                    />
                  </Grid>
                </Hidden>

                {/* {ResponsiveDivider()} */}
                {/* ------------------------------------------------------------------------------ */}

                <Grid item xs={11} sm={5}>
                  <div className={classes.containerPad}>
                    <ThemeProvider theme={theme}>
                      <Typography variant='subtitle1' gutterBottom align='left'>
                        Bio
                      </Typography>

                      <Typography
                        variant='body2'
                        gutterBottom
                        align='left'
                        className={classes.bioContent}
                      >
                        {auth.user.about_user}
                      </Typography>
                    </ThemeProvider>
                  </div>
                </Grid>
              </Grid>
            )}
            {/* ------------------------------------------PROFILE BRIEF DONE--------------------------------------------------- */}
            <ScrollableTabsButtonAuto />
          </div>
        </Container>
      </CssBaseline>
    </React.Fragment>
  );
}

export default Profile;
