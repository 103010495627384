import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { getZoneTimeInLocalTime } from 'utils';

const ShiftInfo = ({ shifts, index, attendances, location, type }) => {
  const [breakTime, setBreakTime] = useState('');
  const [breakType, setBreakType] = useState('');
  const [position, setPosition] = useState('');
  const [shiftTemplate, setShiftTemplate] = useState('');
  const [punchin, setPunchin] = useState('');
  const [status, setStatus] = useState('');
  const [sDate, setSdate] = useState('');
  const [eDate, setEdate] = useState('');
  const [timeZone, setTimeZone] = useState('');
  const [shi, setShi] = useState({});
  const [require_approval, setRequire_approval] = useState(false);

  const getTimeFromDate = (date) => {
    const dt = new Date(date);
    const time = dt.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });

    return time;
  };

  const check = (type, index, startTime, timeZone) => {
    const zoned_start_time = getZoneTimeInLocalTime(startTime, timeZone);
    switch (type) {
      case 'month':
        return zoned_start_time.getDate() - 1 === index;
      case 'week': {
        const checkDay =
          zoned_start_time.getDay() - 1 >= 0
            ? zoned_start_time.getDay() - 1
            : 6;

        return checkDay === index;
      }
      default:
        return true;
    }
  };

  useEffect(() => {
    for (let shift of shifts) {
      if (shift) {
        setRequire_approval(shift.require_approval);
        setStatus(shift.status);
        setShi(shift);
        if (check(type, index, shift.start_time, shift.timezone)) {
          setTimeZone(shift.timezone);
          if (shift.breck_time) setBreakTime(shift.breck_time);
          if (shift.position?.position_title)
            setPosition(shift.position.position_title);
          if (shift.name) setShiftTemplate(shift.name);
          else if (shift.shift_template_title)
            setShiftTemplate(shift.shift_template_title);
          if (shift.breck) setBreakType(shift.breck);
          if (shift.start_time) setSdate(shift.start_time);
          if (shift.end_time) setEdate(shift.end_time);
        }
      }
    }

    if (attendances.length === 1) {
      if (attendances[0]?.punch_details[0]?.punchin_time) {
        setPunchin(
          getTimeFromDate(attendances[0].punch_details[0].punchin_time)
        );
      }
    }
  }, [shifts, index, attendances, type]);

  return (
    <div
      style={{
        fontSize: '12px',
        height: 'fit-content',
        padding: '5px',
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
      }}
    >
      <div>
        <span style={{ fontWeight: 'bold' }}>Jobsite Location: </span>
        {location || '--'}
      </div>
      <div>
        <span style={{ fontWeight: 'bold' }}>Shift Template: </span>
        {shiftTemplate || '--'}
      </div>
      <div>
        <span style={{ fontWeight: 'bold' }}>Break: </span>
        {breakTime || '--'}
      </div>
      <div>
        <span style={{ fontWeight: 'bold' }}>Break Type: </span>
        {breakType || '--'}
      </div>
      <div>
        <span style={{ fontWeight: 'bold' }}>Position: </span>
        {position || '--'}
      </div>
      <div>
        <span style={{ fontWeight: 'bold' }}>Start Date: </span>
        {moment(getZoneTimeInLocalTime(sDate, timeZone)).format(
          'DD-MM-YYYY hh:mm A'
        )}
      </div>
      <div>
        <span style={{ fontWeight: 'bold' }}>End Date: </span>
        {moment(getZoneTimeInLocalTime(eDate, timeZone)).format(
          'DD-MM-YYYY hh:mm A'
        )}
      </div>
      {punchin ? (
        <>
          <div>
            <span style={{ fontWeight: 'bold' }}>Punch In: </span>{' '}
            {punchin || '--'}
          </div>
        </>
      ) : (
        <></>
      )}
      {require_approval ? (
        <div style={{ textTransform: 'capitalize' }}>
          <span style={{ fontWeight: 'bold' }}>Status: </span> {status}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ShiftInfo;
