import React, { useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import HandoverSidebar from "../assetAssignList/assetAssignDetails/HandoverSidebar";
import EditSidebar from "../assetAssignList/assetAssignDetails/EditSidebar";
import AssignToSidebar from "../assetAssignList/assetAssignDetails/AssignToSidebar";

export default function ShareExport({ icon, item1, item2 }) {
  const [assignToSidebar, setAssignToSidebar] = useState(false);
  const [handoverSidebar, setHandoverSidebar] = useState(false);
  const [editSidebar, setEditSidebar] = useState(false);

  const assigntoSidebarHandler = () => {
    setAssignToSidebar(!assignToSidebar);
  };

  const handoverSidebarHandler = () => {
    setHandoverSidebar(!handoverSidebar);
  };

  const editSidebarHandler = () => {
    setEditSidebar(!editSidebar);
  };

  return (
    <div>
      {assignToSidebar && (
        <AssignToSidebar assigntoSidebarHandler={assigntoSidebarHandler} />
      )}
      {handoverSidebar && (
        <HandoverSidebar handoverSidebarHandler={handoverSidebarHandler} />
      )}
      {editSidebar && <EditSidebar editSidebarHandler={editSidebarHandler} />}
      <Menu as="div" className="relative">
        <div>
          <Menu.Button>
            <i className={icon} />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="rounded-md shadow-md border text-left font-semibold text-lg bg-white w-24 absolute right-0 mt-2">
            <Menu.Item>
              {({ active }) => (
                <p
                  className={`${
                    active && "bg-gray-300"
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm cursor-pointer`}
                  onClick={
                    (item1 === "Assign To" && assigntoSidebarHandler) ||
                    (item1 === "Edit" && editSidebarHandler)
                  }
                >
                  {item1}
                </p>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <p
                  className={`${
                    active && "bg-gray-300"
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm cursor-pointer`}
                  onClick={item2 === "Handover" && handoverSidebarHandler}
                >
                  {item2}
                </p>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
