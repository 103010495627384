import {
  createJobsiteRepo,
  createProjectRepo,
  deleteJobsiteRepo,
  deleteProjectRepo,
  getJobSiteRepo,
  getProjectRepo,
  searchProjectRepo,
  updateJobsiteAssignEmpRepo,
  updateJobsiteRepo,
  updateProjectRepo,
} from 'store/repositories/ShiftManagement/ShiftProjectNSites';
import { searchShiftProjectRejected } from 'store/slices/ShiftManagement/ShiftProjectNSites';
import { searchShiftProjectFulfilled } from 'store/slices/ShiftManagement/ShiftProjectNSites';
import {
  getShiftProjectFulfilled,
  getShiftProjectPending,
  getShiftProjectRejected,
  getShiftSiteFulfilled,
  getShiftSitePending,
  getShiftSiteRejected,
  deleteProjectFulfilled,
  deleteProjectRejected,
  deleteProjectPending,
  deleteJobsiteFulfilled,
  deleteJobsiteRejected,
  deleteJobsitePending,
  updateJobsiteAssignEmpPending,
  updateJobsiteAssignEmpFulfilled,
  updateJobsiteAssignEmpRejected,
  updateShiftJobsitePending,
  updateShiftJobsiteFulfilled,
  updateShiftJobsiteRejected,
  createShiftJobsitePending,
  createShiftJobsiteFulfilled,
  createShiftJobsiteRejected,
  updateShiftProjectPending,
  updateShiftProjectFulfilled,
  updateShiftProjectRejected,
  createShiftProjectPending,
  createShiftProjectFulfilled,
  createShiftProjectRejected,
  searchShiftProjectPending,
} from 'store/slices/ShiftManagement/ShiftProjectNSites';

export const getShiftJobSite = () => {
  return async (dispatch) => {
    dispatch(getShiftSitePending());
    await getJobSiteRepo()
      .then((response) => {
        if (response.status === 200) {
          dispatch(getShiftSiteFulfilled(response?.data?.data));
        } else {
          dispatch(getShiftSiteRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(getShiftSiteRejected(error.message));
        }
      });
  };
};

export const updateShiftJobsiteAssignEmp = (payload) => {
  return async (dispatch) => {
    dispatch(updateJobsiteAssignEmpPending());
    await updateJobsiteAssignEmpRepo(payload)
      .then((response) => {
        if (response.status === 200) {
          dispatch(updateJobsiteAssignEmpFulfilled(response?.data?.data));
        } else {
          dispatch(updateJobsiteAssignEmpRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(updateJobsiteAssignEmpRejected(error.message));
        }
      });
  };
};
export const updateShiftProject = (payload) => {
  return async (dispatch) => {
    dispatch(updateShiftProjectPending());
    await updateProjectRepo(payload)
      .then((response) => {
        if (response.status === 200) {
          dispatch(updateShiftProjectFulfilled(response?.data?.data));
        } else {
          dispatch(updateShiftProjectRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(updateShiftProjectRejected(error.message));
        }
      });
  };
};
export const createShiftProject = (payload) => {
  return async (dispatch) => {
    dispatch(createShiftProjectPending());
    await createProjectRepo(payload)
      .then((response) => {
        if (response.status === 200) {
          dispatch(createShiftProjectFulfilled(response?.data?.data));
        } else {
          dispatch(createShiftProjectRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(createShiftProjectRejected(error.message));
        }
      });
  };
};
export const updateShiftJobsite = (payload) => {
  return async (dispatch) => {
    dispatch(updateShiftJobsitePending());
    await updateJobsiteRepo(payload)
      .then((response) => {
        if (response.status === 200) {
          dispatch(updateShiftJobsiteFulfilled(response?.data?.data));
        } else {
          dispatch(updateShiftJobsiteRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(updateShiftJobsiteRejected(error.message));
        }
      });
  };
};
export const createShiftJobsite = (payload) => {
  return async (dispatch) => {
    dispatch(createShiftJobsitePending());
    await createJobsiteRepo(payload)
      .then((response) => {
        if (response.status === 200) {
          dispatch(createShiftJobsiteFulfilled(response?.data?.data));
        } else {
          dispatch(createShiftJobsiteRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(createShiftJobsiteRejected(error.message));
        }
      });
  };
};
export const deleteShiftJobsite = (id) => {
  return async (dispatch) => {
    dispatch(deleteJobsitePending());
    await deleteJobsiteRepo(id)
      .then((response) => {
        if (response.status === 200) {
          dispatch(deleteJobsiteFulfilled(response?.data?.data));
        } else {
          dispatch(deleteJobsiteRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(deleteJobsiteRejected(error.message));
        }
      });
  };
};

export const getShiftProject = (params) => {
  return async (dispatch) => {
    dispatch(getShiftProjectPending());
    await getProjectRepo(params)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getShiftProjectFulfilled(response?.data?.data));
        } else {
          dispatch(getShiftProjectRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(getShiftProjectRejected(error.message));
        }
      });
  };
};

export const deleteShiftProject = (id) => {
  return async (dispatch) => {
    dispatch(deleteProjectPending());
    await deleteProjectRepo(id)
      .then((response) => {
        if (response.status === 200) {
          dispatch(deleteProjectFulfilled(response?.data?.data));
        } else {
          dispatch(deleteProjectRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(deleteProjectRejected(error.message));
        }
      });
  };
};

export const searchShiftProject = (params) => {
  return async (dispatch) => {
    dispatch(searchShiftProjectPending());
    await searchProjectRepo(params)
      .then((response) => {
        if (response.status === 200) {
          dispatch(searchShiftProjectFulfilled(response?.data?.data));
        } else {
          dispatch(searchShiftProjectRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(searchShiftProjectRejected(error.message));
        }
      });
  };
};
