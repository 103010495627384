import * as React from "react";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import "@fontsource/roboto";
// import useAuth from "hooks/useAuth";
import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { useAssetStyles } from "../Style";
import AllAssets from "./index";

export const AllAsset = (props) => {
  let theme = createTheme();
  theme = responsiveFontSizes(theme);
  const classes = useAssetStyles();
  return (
    <React.Fragment>
      <div className="root">
        <ThemeProvider theme={theme}>
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: props.isDrawerOpen,
            })}
          >
            <div>
              <Typography
                style={{
                  textAlign: "left",
                  marignBottom: "20px",
                  minWidth: "230px",
                }}
              >
                Home {">"} Asset Management {">"} All Assets
              </Typography>

              <div
                style={{
                  margin: "10px",
                  marginLeft: "0px",
                }}
              >
                <AllAssets />
              </div>
            </div>
          </main>
        </ThemeProvider>
      </div>
    </React.Fragment>
  );
};
