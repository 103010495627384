import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    punchIn: {},
    leaves: {}
};

export const  attendanceSlice = createSlice({
  name: "attendanceAndShifts",
  initialState,
  reducers: {
    getPunchinPending(state, action) {
      return {
        ...state,
        punchIn: { loading: true },
      };
    },
    getPunchinFullfilled(state, action) {
      return {
        ...state,
        punchIn: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    getPunchinRejected(state, action) {
      return {
        ...state,
        punchIn: {
          loading: false,
          rejected: action.payload,
        },
      };
    },

    getLeavesByUserPending(state, action) {
        return {
          ...state,
          leaves: { loading: true },
        };
      },
      getLeavesByUserFullfilled(state, action) {
        return {
          ...state,
          leaves: {
            loading: false,
            fulfilled: action.payload,
          },
        };
      },
      getLeavesByUserRejected(state, action) {
        return {
          ...state,
          leaves: {
            loading: false,
            rejected: action.payload,
          },
        };
      },
  

    
  },
});

export const {
    getPunchinPending,
    getPunchinFullfilled,
    getPunchinRejected,
    getLeavesByUserPending,
    getLeavesByUserFullfilled,
    getLeavesByUserRejected

} = attendanceSlice.actions;

export default attendanceSlice.reducer;
