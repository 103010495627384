import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Breadcrumbs,
  Container,
  Paper,
  makeStyles,
  Link,
  Typography,
  Grid,
  Divider,
  Tabs,
  Tab,
  Box,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  withStyles,
  CircularProgress,
  Backdrop,
  styled,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import useAuth from "hooks/useAuth";
import moment from "moment";
import { endOfDay, endOfWeek, parse, startOfDay, startOfWeek } from "date-fns";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
// import protection from "../../assets/images/protection-shield-4.png";
import leave from "../../assets/images/leave.png";
import clsx from "clsx";
import { ViewState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  MonthView,
  WeekView,
  Appointments,
  Toolbar,
  DateNavigator,
  TodayButton,
  AppointmentTooltip,
} from "@devexpress/dx-react-scheduler-material-ui";
import { Delete } from "@material-ui/icons";
import "./index.css";
import { useLoadingBar } from "./style";
// import {
//   getShiftsByUserId,
//   getPunchinByUser,
// } from "../../features/EmployeeManagement/HomePageSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  getPunchinByUserId,
  getLeavesByUserId,
} from "../../store/actions/EmployeeManagement/AttendanceAndShifts";
import { getShiftByUserId } from "../../store/actions/EmployeeManagement/HomePage";
import { getZoneTimeInLocalTime } from "utils";

const drawerWidth = 240;

const PREFIX = "Demo",
  ACCEPTED = "#4DBD6B",
  REJECTED = "#ee6b6e",
  PENDING = "#438ffa";

const classes = {
  cell: `${PREFIX}-cell`,
  content: `${PREFIX}-content`,
  text: `${PREFIX}-text`,
  sun: `${PREFIX}-sun`,
  cloud: `${PREFIX}-cloud`,
  rain: `${PREFIX}-rain`,
  sunBack: `${PREFIX}-sunBack`,
  cloudBack: `${PREFIX}-cloudBack`,
  rainBack: `${PREFIX}-rainBack`,
  opacity: `${PREFIX}-opacity`,
  appointment: `${PREFIX}-appointment`,
  apptContent: `${PREFIX}-apptContent`,
  flexibleSpace: `${PREFIX}-flexibleSpace`,
  flexContainer: `${PREFIX}-flexContainer`,
  tooltipContent: `${PREFIX}-tooltipContent`,
  tooltipText: `${PREFIX}-tooltipText`,
  title: `${PREFIX}-title`,
  icon: `${PREFIX}-icon`,
  circle: `${PREFIX}-circle`,
  textCenter: `${PREFIX}-textCenter`,
  dateAndTitle: `${PREFIX}-dateAndTitle`,
  titleContainer: `${PREFIX}-titleContainer`,
  container: `${PREFIX}-container`,
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F7F8FC",
    color: "#16215B",
  },
  body: {
    fontSize: 16,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    color: "#16215B",
  },
}))(TableRow);
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  table: {
    minWidth: 700,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    borderRadius: "6px ",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: "#ffffff",
    color: "#1F299C",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 0,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    border: "2px solid #FFFFFF",
  },
}));

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Appointment = ({ children, style, ...restProps }) => {
  const { data } = restProps;
  let { require_approval, status, startDate, endDate } = data;
  return (
    <Appointments.Appointment
      {...restProps}
      style={{
        ...style,
        backgroundColor: require_approval
          ? status === "pending"
            ? PENDING
            : status === "approved"
              ? ACCEPTED
              : REJECTED
          : ACCEPTED,
        borderRadius: "8px",
      }}
    >
      <div style={{ color: "#fff" }}>
        <p>Start Time : {moment(startDate).format("hh:mm a")}</p>
        <p>End Time : {moment(endDate).format("hh:mm a")}</p>
        {require_approval ? (
          <>
            <p
              style={{
                color: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textTransform: "capitalize",
              }}
            >
              Status : {status}{" "}
              <span style={{ marginLeft: "5px" }}>
                {status === "pending" ? (
                  <HourglassEmptyIcon style={{ transform: "scale(.7)" }} />
                ) : status === "approved" ? (
                  <CheckCircleOutlineIcon style={{ transform: "scale(.7)" }} />
                ) : (
                  <CancelIcon style={{ transform: "scale(.7)" }} />
                )}
              </span>
            </p>
          </>
        ) : (
          <></>
        )}
      </div>
      {/* {children} */}
    </Appointments.Appointment>
  );
};

const AbsentAppointment = ({ children, style, ...restProps }) => {
  const { data } = restProps;
  return (
    <Appointments.Appointment
      {...restProps}
      style={{
        ...style,
        backgroundColor: data.type === "punchin" ? ACCEPTED : REJECTED,
        borderRadius: "8px",
      }}
    >
      {data.type != "punchin" ? (
        <>
          <div
            style={{
              color: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              height: "max-content",
            }}
          >
            <p
              style={{
                color: "#fff",
                margin: "-2px 0",
                padding: 0,
                fontWeight: "bold",
              }}
            >
              Leave
            </p>
            <p
              style={{
                color: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: 0,
                margin: "0",
                textTransform: "capitalize",
              }}
            >
              <span>Status : {data.status}</span>
              <span style={{ marginLeft: "5px" }}>
                {data.status === "pending" ? (
                  <HourglassEmptyIcon
                    style={{ transform: "scale(.8)", margin: 0, padding: 0 }}
                  />
                ) : data.status === "approved" ? (
                  <CheckCircleOutlineIcon
                    style={{ transform: "scale(.8)", margin: 0, padding: 0 }}
                  />
                ) : (
                  <CancelIcon
                    style={{ transform: "scale(.8)", margin: 0, padding: 0 }}
                  />
                )}
              </span>
            </p>
          </div>
          {children}
        </>
      ) : (
        <>
          {children}
          <div
            style={{
              color: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <p
              style={{
                color: "#fff",
                marginTop: "2px",
              }}
            >
              Punch In : {moment(data.startDate).format("hh:mm A")}
            </p>
            <p
              style={{
                color: "#fff",
                marginBottom: "2px",
              }}
            >
              Punch Out : {moment(data.endDate).format("hh:mm A")}
            </p>
          </div>
        </>
      )}
    </Appointments.Appointment>
  );
};

const StyledAppointmentsAppointmentContent = styled(
  Appointments.AppointmentContent
)(() => ({
  [`&.${classes.apptContent}`]: {
    "&>div>div": {
      whiteSpace: "normal !important",
      lineHeight: 1.2,
    },
  },
}));

const AppointmentContent = ({ ...restProps }) => {
  return (
    <div>
      <StyledAppointmentsAppointmentContent
        {...restProps}
        className={classes.apptContent}
      />
    </div>
  );
};

const LeavesNAttendance = (props) => {
  const dispatch = useDispatch();
  const auth = useAuth();
  const classes = useStyles();
  // const history = useHistory();
  const [selectedDate, setSelectedDate] = useState(
    new Date("2021-06-18T21:11:54")
  );
  const mainClasses = useLoadingBar();

  const [isLoading, setIsLoading] = React.useState(true);
  const [month, setMonth] = useState("");
  const [leaveId, setLeaveId] = useState("");
  const [year, setYear] = useState("");
  const [attData, setAttData] = useState([]);
  const [userAtt, setUserAtt] = useState([]);
  let todaysDate = moment().format("YYYY-MM-DD");
  const [currentDate, setCurrentDate] = useState(todaysDate);
  const [tabValue, setTabValue] = useState(0);
  const [tabLeaveValue, setLeaveValue] = useState(0);
  const [currentViewName, setCurrentViewName] = useState("work-week");
  const [currentLeaveViewName, setCurrentLeaveViewName] = useState("leaves");
  const [leaveData, setLeaveData] = useState([]);
  const [punchinData, setPunchinsData] = useState([]);
  const [countData, setCountdata] = useState({
    hoursWorked: 0,
    absentHours: 0,
  });
  const [leavesApplied, setLeavesApplied] = useState(0);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openConfirmLeave, setOpenConfirmLeave] = useState(false);
  const [showLeaves, setshowLeaves] = useState(true);

  const { leaves, punchIn } = useSelector((state) => state.attendanceAndShifts);
  const { shifts } = useSelector((state) => state.homepage);
  const handleLeaveView = (event) => {
    setshowLeaves(false);
    setSelectedDate(new Date()); // to be removed
  };
  const handleClick = (event) => {
    event.preventDefault();
    setshowLeaves(true);
  };

  const getLeavesByUser = async () => {
    const userId = auth.user._id;
    dispatch(getLeavesByUserId(userId));
    // dispatch(getLeavesByUserId(
    //   {
    //     params: {
    //       userId: userId
    //     },

    //   }
    // ))
    //   .then((myData) => {
    //     const response = myData.payload;
    //     console.log("response leaves by id=====================", response);
    //     const data = response;
    //     let sortedData = data.sort(function (a, b) {
    //       return new Date(b.created_at) - new Date(a.created_at);
    //     });
    //     setLeaveData(sortedData);
    //     const currMonth = new Date().getMonth();
    //     var leaveHours = 0;
    //     data.map((leave) => {
    //       if (currMonth === new Date(leave.created_at).getMonth()) {
    //         if (
    //           !isNaN(parseFloat(leave.duration)) &&
    //           isFinite(leave.duration)
    //         ) {
    //           leaveHours = leaveHours + parseFloat(leave.duration);
    //         }
    //       }
    //     });

    //     setLeavesApplied(leaveHours.toFixed(2));

    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   })
    // try {
    // const response = await axios.get(
    //   `${process.env.REACT_APP_API_BASE_URL}/leaves/getbyuserid/${userId}`,
    //   {
    //     headers: {
    //       token: auth.token,
    //     },
    //   }
    // );
    // if (response.status === 200) {
    //   const data = response.data.data;
    //   let sortedData = data.sort(function (a, b) {
    //     return new Date(b.created_at) - new Date(a.created_at);
    //   });
    //   setLeaveData(sortedData);
    //   const currMonth = new Date().getMonth();
    //   var leaveHours = 0;
    //   data.map((leave) => {
    //     if (currMonth === new Date(leave.created_at).getMonth()) {
    //       if (
    //         !isNaN(parseFloat(leave.duration)) &&
    //         isFinite(leave.duration)
    //       ) {
    //         leaveHours = leaveHours + parseFloat(leave.duration);
    //       }
    //     }
    //   });

    //   setLeavesApplied(leaveHours.toFixed(2));
    // }
    // } catch (e) {
    //   console.log("Error ++", e);
    // }
  };

  const getDateFromString = (time, date) => {
    // const dt = new Date(time);

    // let currentTime = time;
    // if (dt.toString() === "Invalid Date") {
    //   // console.log(parse(format(dt, "h:m aaa"), "YYY-MM-DD h:m aaa", new Date()));
    //   currentTime = parse(time, "h:m aaa", new Date()).toISOString();
    // }
    // const part = currentTime.split("T")[1];
    // const newDate = date + "T" + part;
    // return newDate;

    let dt = moment(time);
    if (!dt.isValid()) {
      time = new Date();
    }
    let offsetMilis = new Date().getTimezoneOffset() * 60 * 1000;
    let utcAndLocalMilis = Date.parse(time);

    var theDate = new Date(utcAndLocalMilis + offsetMilis);
    // theDate.setMinutes(theDate.getMinutes() + theDate.getTimezoneOffset() );
    return theDate;
  };

  const getShiftByUser = async (date, tab = tabValue) => {
    date = moment(date).toDate();
    var startWeek, endWeek;
    if (tab === 0) {
      startWeek = startOfWeek(date);
      endWeek = endOfWeek(date);
    } else if (tab === 1) {
      startWeek = moment(date).startOf("month").toDate();
      endWeek = moment(date).endOf("month").toDate();
    }
    const userId = auth.user._id,
      params = {
        from: moment(startWeek).format("YYYY-MM-DD"),
        to: moment(endWeek).format("YYYY-MM-DD"),
      };

      let {from , to } = params;

      let dateS = parse(from, 'yyyy-MM-dd', new Date());
      let dateE = parse(to, 'yyyy-MM-dd', new Date());
      dateS = startOfDay(dateS);
      dateE = endOfDay(dateE);
      console.log(dateS, dateE, 'date for call');
      params.from = dateS.toISOString();
      params.to = dateE.toISOString();

    dispatch(getShiftByUserId(userId, params));
    // dispatch(getShiftsByUserId(
    //   {
    //     params: {
    //       userId: userId
    //     },

    //   }
    // ))
    //   .then((myData) => {
    //     const response = myData.payload;
    //     console.log("response shifts by userId=====================", response);
    //     const data = response;
    //     if (data && data.length) {
    //       const calenderData = data.map((item) => {
    //         return {
    //           startDate: getDateFromString(
    //             item.start_time,
    //             item.start_time
    //           ),
    //           endDate: getDateFromString(
    //             item.end_time,
    //             item.end_time
    //           ),
    //           title: item.name,
    //           location: item.schedule?.address || "--",
    //           breakTime: item.breck_time || "--",
    //           instructions: item.instructions || "--",
    //         };
    //       });
    //       setUserAtt(calenderData);
    //       setAttData(calenderData);
    //       // data.map((item) => {

    //       //  });
    //     }

    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   })
    // try {
    //   const response = await axios.get(
    //     `${process.env.REACT_APP_API_BASE_URL}/shift/getbyuserid/${userId}`
    //   );
    // if (response.status === 200) {
    // const data = response.data.data;

    // if (data && data.length) {
    //   const calenderData = data.map((item) => {
    //     return {
    //       startDate: getDateFromString(
    //         item.start_time,
    //         item.start_time
    //       ),
    //       endDate: getDateFromString(
    //         item.end_time,
    //         item.end_time
    //       ),
    //       title: item.name,
    //       location: item.schedule?.address || "--",
    //       breakTime: item.breck_time || "--",
    //       instructions: item.instructions || "--",
    //     };
    //   });
    //   setUserAtt(calenderData);
    //   setAttData(calenderData);
    //   // data.map((item) => {

    //   //  });
    // }
    // }
    // } catch (e) {
    //   if (e.response) {
    //     console.log("Error >", e.response);
    //   }
    // }
  };

  const getPunchinsByUser = async () => {
    const userId = auth.user._id;
    dispatch(getPunchinByUserId(userId));
    // dispatch(getPunchinByUser(
    //   {
    //     params: {
    //       userId: userId
    //     },

    //   }
    // ))
    //   .then((myData) => {
    //     const response = myData.payload;
    //     console.log("response punchin by id=====================", response);
    //     const data = response;
    //     let sortedData = data.sort(function (a, b) {
    //       return new Date(b.created_at) - new Date(a.created_at);
    //     });
    //     let arrData = [];
    //     if (sortedData && sortedData.length) {
    //       sortedData.forEach((item) => {
    //         let missedPunchDetails = item.punch_details;
    //         const status = item.status ? item.status : "Pending";
    //         const id = item._id;
    //         missedPunchDetails.forEach((punch) => {
    //           arrData.push({
    //             punchin_time: punch.punchin_time,
    //             punchout_time: punch.punchout_time,
    //             missed_punch_note: punch.missed_punch_note,
    //             status,
    //             id,
    //           });
    //         });
    //       });
    //     }
    //     setPunchinsData(arrData);

    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   })
    // try {
    //   const userId = auth.user._id;
    // const response = await axios.get(
    //   `${process.env.REACT_APP_API_BASE_URL}/attendance/getbyemp/${userId}`,
    //   {
    //     headers: {
    //       token: auth.token,
    //     },
    //   }
    // );

    // if (response.status === 200) {
    //   const data = response.data.data;
    //   let sortedData = data.sort(function (a, b) {
    //     return new Date(b.created_at) - new Date(a.created_at);
    //   });
    //   let arrData = [];
    //   if (sortedData && sortedData.length) {
    //     sortedData.forEach((item) => {
    //       let missedPunchDetails = item.punch_details;
    //       const status = item.status ? item.status : "Pending";
    //       const id = item._id;
    //       missedPunchDetails.forEach((punch) => {
    //         arrData.push({
    //           punchin_time: punch.punchin_time,
    //           punchout_time: punch.punchout_time,
    //           missed_punch_note: punch.missed_punch_note,
    //           status,
    //           id,
    //         });
    //       });
    //     });
    //   }
    //   setPunchinsData(arrData);
    // }
    // } catch (e) {
    //   console.log("Error ++", e);
    //   if (e.response) {
    //     console.log("Error >", e.response);
    //   }
    // }
  };

  const confirmDeleteLeave = (id) => {
    setLeaveId("");
    const leaves = [...leaveData];
    const currentLeave = leaves.find((leave) => leave._id === id);

    if (currentLeave.status === "Pending" || currentLeave.status.length === 0) {
      setLeaveId(id);
      setOpenConfirmLeave(true);
    } else return;
  };

  const handleDeleteLeave = async () => {
    const leaves = [...leaveData];
    const currentLeave = leaves.find((leave) => leave._id === leaveId);

    if (currentLeave.status === "Pending" || currentLeave.status.length === 0) {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_BASE_URL}/leaves/delete/${leaveId}`,
          {
            headers: {
              token: auth.token,
            },
          }
        );

        if (response.data.status === true) {
          setOpenConfirmLeave(false);
          await getLeavesByUser();
        }
      } catch (err) {
        console.log(err.message);
      }
    }
    setLeaveId("");
    setOpenConfirmLeave(false);
  };

  const confirmDeletePunchIn = (id) => {
    setLeaveId("");
    const punchins = [...punchinData];
    const currentPunchin = punchins.find((punchin) => punchin.id === id);

    if (
      currentPunchin.status === "Pending" ||
      currentPunchin.status.length === 0
    ) {
      setLeaveId(id);
      setOpenConfirm(true);
    } else return;
  };

  const handleDeletePunchIn = async () => {
    const punchins = [...punchinData];
    const currentPunchin = punchins.find((punchin) => punchin.id === leaveId);

    if (
      currentPunchin.status === "Pending" ||
      currentPunchin.status.length === 0
    ) {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_BASE_URL}/attendance/missed-punchin/delete/${leaveId}`,
          {
            headers: {
              token: auth.token,
            },
          }
        );

        if (response.data.status === true) {
          setOpenConfirm(false);
          await getPunchinsByUser();
        }
      } catch (err) {
        console.log(err.message);
      }
    }
    setLeaveId("");
    setOpenConfirm(false);
  };

  const handleTabChange = async (event, newValue) => {
    event.preventDefault();
    setTabValue(newValue);
    switch (newValue) {
      case 0:
        getShiftByUser(currentDate, newValue);
        setCurrentViewName("work-week");
        if (userAtt.length) break;
        setAttData(userAtt);
        break;
      case 1:
        getShiftByUser(currentDate, newValue);
        setAttData(userAtt);
        setCurrentViewName("Month");
        break;
      case 2:
        await getAttendance(month, year);
        setCurrentViewName("Month");
        break;
      default:
        break;
    }
  };

  const handleLeaveTabChange = (event, newValue) => {
    event.preventDefault();
    setLeaveValue(newValue);
    switch (newValue) {
      case 0:
        setCurrentLeaveViewName("leaves");
        break;
      case 1:
        setCurrentLeaveViewName("regularisation");
        break;
      default:
        break;
    }
  };
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleCloseConfirmLeave = () => {
    setOpenConfirmLeave(false);
  };

  const getAttendance = async (month, year) => {
    setIsLoading(true);
    try {
      let leaves = leaveData.map((date) => {
        let eDate = new Date(date.to);
        let sDate = moment(date.from).format(`YYYY-MM-DD ${date.from_time ? date.from_time : '09:00'}`);
        sDate = new Date(sDate);
        let endOfDay = moment(eDate).format(`YYYY-MM-DD ${date.to_time ? date.to_time :'17:00'}`);
        eDate = new Date(endOfDay);
        return {
          startDate: sDate,
          endDate: eDate,
          title: date.description,
          type: "leave",
          status: date.status.toLowerCase(),
        };
      });
      let punchins = punchinData
        .filter((data) => {
          let sdate = moment(data.punchin_time);
          let edate = moment(data.punchout_time);
          return sdate.isValid() && edate.isValid();
        })
        .map((data) => {
          return {
            startDate: new Date(data.punchin_time),
            endDate: new Date(data.punchout_time),
            title: "Punched In",
            type: "punchin",
            status: data.status.toLowerCase(),
          };
        });
      let sortedData = [...punchins, ...leaves].sort(function (a, b) {
        return new Date(b.startDate) - new Date(a.startDate);
      });
      setAttData(sortedData);
    } catch (e) {
      console.log("Error ++", e);
    }
    setIsLoading(false);
  };

  const getAttendenceByUser = async () => {
    setIsLoading(true);
    // const userId = auth.user._id;
    const currYear = new Date().getFullYear();
    const curMonth = new Date().getMonth();
    var absentHours = 0;
    try {
      if (shifts?.fulfilled) {
        const shiftData = [...shifts.fulfilled];
        if (shiftData && shiftData.length) {
          let shifts = shiftData.map((item) => {
            return {
              startDate: getDateFromString(item.start_time, item.start_time),
              endDate: getDateFromString(item.end_time, item.end_time),
              title: item.name,
              location: item.schedule?.address || "--",
              breakTime: item.breck_time || "--",
              instructions: item.instructions || "--",
            };
          });

          if (punchIn?.fulfilled) {
            const attData = [...punchIn?.fulfilled];
            var total_attended_hrs = 0;
            attData.map((att) => {
              let attdate = new Date(att.created_at);
              let month = attdate.getMonth();
              let year = attdate.getFullYear();
              if (curMonth === month && currYear === year)
                total_attended_hrs += att.total_attended_hrs;
            });

            shifts.forEach((shift) => {
              const shiftStartBegin = moment(
                moment(shift.startDate).add(10, "minutes")
              ).format("DD-MM-YYYY HH:mm");

              const shiftStartEnd = moment(
                moment(shift.endDate).subtract(10, "minutes")
              ).format("DD-MM-YYYY HH:mm");

              const att = attData.find((att) => {
                const time = moment(att.created_at);
                if (
                  time.isSameOrAfter(shiftStartBegin) &&
                  time.isSameOrBefore(shiftStartEnd)
                ) {
                  return true;
                }
              });

              var enddate = moment(shift.endDate);
              var duration = moment.duration(enddate.diff(shift.startDate));
              const hours = parseInt(duration.asHours());

              if (att) {
                let absent =
                  parseFloat(hours) - parseFloat(att.duration_in_hrs);
                if (absent > 0) {
                  absentHours += absent;
                }
              }
            });

            setCountdata({
              ...countData,
              hoursWorked: total_attended_hrs,
              absentHours,
            });
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      setIsLoading(true);
      getShiftByUser(new Date()).then(async () => {
        await getLeavesByUser();
        await getPunchinsByUser();
        await getAttendance();
        const date = moment(currentDate);
        // var currentTime = new Date();

        if (
          (date.month() !== month || date.year() !== year) &&
          tabValue === 2
        ) {
          setMonth(date.month());
          setYear(date.year());
          // getAttendance(date.month(), date.year());
        } else if (tabValue === 0 || tabValue === 1) {
          setMonth(date.month());
          setYear(date.year());
        }
      });
      setIsLoading(false);
    };
    getData();
  }, []);

  useEffect(() => {
    if (leaves?.fulfilled) {
      const data = [...leaves.fulfilled];
      let sortedData = data.sort(function (a, b) {
        return new Date(b.from) - new Date(a.from);
      });
      setLeaveData(sortedData);
      const currMonth = new Date().getMonth();
      var leaveHours = 0;
      data.map((leave) => {
        if (currMonth === new Date(leave.from).getMonth()) {
          if (!isNaN(parseFloat(leave.duration)) && isFinite(leave.duration)) {
            leaveHours = leaveHours + parseFloat(leave.duration);
          }
        }
      });
      setLeavesApplied(leaveHours.toFixed(2));
    }
  }, [leaves]);

  useEffect(() => {
    if (shifts?.fullfilled) {
      const data = [...shifts.fullfilled];
      const calenderData = data.map((item) => {
        return {
          startDate: getZoneTimeInLocalTime(item.start_time, item.timezone),
          endDate: getZoneTimeInLocalTime(item.end_time, item.timezone),
          title: item.name,
          location: item.jobsite?.address || "acb",
          breakTime: item.breck_time || "--",
          instructions: item.instructions || "--",
          status: !item.require_approval ? 'Assigned' : item.status || '--'
        };
      });
      setUserAtt(calenderData);
      setAttData(calenderData);
    }
  }, [shifts]);

  useEffect(() => {
    if (punchIn?.fulfilled) {
      const data = [...punchIn.fulfilled];
      let sortedData = data.sort(function (a, b) {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      let arrData = [];
      if (sortedData && sortedData.length) {
        sortedData.forEach((item) => {
          let missedPunchDetails = item.punch_details;
          const status = item.status ? item.status : "Pending";
          const id = item._id;
          missedPunchDetails.forEach((punch) => {
            arrData.push({
              punchin_time: punch.punchin_time,
              punchout_time: punch.punchout_time,
              missed_punch_note: punch.missed_punch_note,
              status,
              id,
            });
          });
        });
      }
      setPunchinsData(arrData);
    }
  }, [punchIn]);

  useEffect(() => {
    const fetch = async () => {
      if (shifts?.fulfilled && punchIn?.fulfilled) await getAttendenceByUser();
    };
    fetch();
  }, [shifts, punchIn]);

  const TooltipContent = ({ children, appointmentData, ...restProps }) => {
    const types = ["punchin", "leave"];

    return (
      <AppointmentTooltip.Content
        {...restProps}
        appointmentData={appointmentData}
      >
        {!types.includes(appointmentData.type) && (
          <Grid container alignItems='center'>
            <Grid item style={{ paddingLeft: "1.7rem" }}>
              <div>
                <b>Jobsite Location:</b> {appointmentData.location}
              </div>
              <div>
                <b>Break:</b> {appointmentData.breakTime}
              </div>
              <div>
                <b>Instructions:</b> {appointmentData.instructions}
              </div>
              <div style={{ textTransform: "capitalize" }}>
                <b>Status:</b> {appointmentData.status}
              </div>
            </Grid>
          </Grid>
        )}
      </AppointmentTooltip.Content>
    );
  };

  return (
    <>
      <Container>
        <div
          className={clsx(classes.content, {
            [classes.contentShift]: props.isDrawerOpen,
          })}
        >
          <br></br>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize='small' />}
            aria-label='breadcrumb'
          >
            <Link color='inherit' onClick={handleClick}>
              Leaves & Attendance
            </Link>
            <Link color='inherit' onClick={handleClick}>
              {showLeaves ? "Calendar" : "Leave & regularization details"}
            </Link>
          </Breadcrumbs>

          <br></br>
          {showLeaves && (
            <Grid container spacing={3}>
              <Grid item xs={10} md={2} lg={2}>
                <Paper
                  className={classes.paper}
                  style={{ backgroundColor: "#1F299C" }}
                >
                  <div
                    style={{
                      height: "206px",
                      padding: "0.5em",
                      position: "relative",
                    }}
                  >
                    <h2 style={{ color: "white", textAlign: "left" }}>
                      Hours Worked
                    </h2>
                    <div
                      style={{
                        position: "absolute",
                        right: "10px",
                        bottom: "10px",
                        color: "white",
                      }}
                    >
                      <Typography
                        variant='h2'
                        component={"span"}
                        style={{
                          fontWeight: "700",
                        }}
                      >
                        {countData.hoursWorked}
                      </Typography>
                      <br></br>
                      <div>
                        <span
                          style={{
                            float: "right",
                          }}
                        >
                          Hours{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={10} md={2} lg={2}>
                <Paper
                  className={classes.paper}
                  style={{ backgroundColor: "#EE433C" }}
                >
                  <div
                    style={{
                      height: "206px",
                      padding: "0.5em",
                      position: "relative",
                    }}
                  >
                    <h2 style={{ color: "white", textAlign: "left" }}>
                      Absent Hours
                    </h2>
                    <div
                      style={{
                        position: "absolute",
                        right: "10px",
                        bottom: "10px",
                        color: "white",
                      }}
                    >
                      <Typography
                        variant='h2'
                        component={"span"}
                        style={{
                          fontWeight: "700",
                        }}
                      >
                        {countData.absentHours}
                      </Typography>
                      <br></br>
                      <div>
                        <span
                          style={{
                            float: "right",
                          }}
                        >
                          Hours{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={10} md={2} lg={2}>
                <Paper
                  className={classes.paper}
                  style={{ backgroundColor: "#1F299C" }}
                >
                  <div
                    style={{
                      height: "206px",
                      padding: "0.5em",
                      position: "relative",
                    }}
                  >
                    <h2 style={{ color: "white", textAlign: "left" }}>
                      Leaves Applied
                    </h2>
                    <div
                      style={{
                        position: "absolute",
                        right: "10px",
                        bottom: "10px",
                        color: "white",
                      }}
                    >
                      <Typography
                        variant='h2'
                        component={"span"}
                        style={{
                          fontWeight: "700",
                        }}
                      >
                        {leavesApplied}
                      </Typography>
                      <br></br>
                      <div>
                        <span
                          style={{
                            float: "right",
                          }}
                        >
                          Hours{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={10} md={6} lg={2}>
                <Paper
                  className={classes.paper}
                  style={{ backgroundColor: "#1F299C" }}
                >
                  <div
                    style={{
                      height: "206px",
                      padding: "0.5em",
                      position: "relative",
                    }}
                  >
                    <h2 style={{ color: "white", textAlign: "left" }}>
                      Banked Hours
                    </h2>
                    <div
                      style={{
                        position: "absolute",
                        right: "10px",
                        bottom: "10px",
                        color: "white",
                      }}
                    >
                      <Typography
                        variant='h2'
                        component={"span"}
                        style={{
                          fontWeight: "700",
                        }}
                      >
                        0
                      </Typography>
                      <br></br>
                      <span
                        style={{
                          float: "right",
                        }}
                      >
                        Hours{" "}
                      </span>
                    </div>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={10} md={6} lg={2}>
                <Paper
                  className={classes.paper}
                  style={{ backgroundColor: "#1F299C", cursor: "pointer" }}
                  onClick={handleLeaveView}
                >
                  <div
                    style={{
                      height: "206px",
                      padding: "0.5em",
                      position: "relative",
                    }}
                  >
                    <h2 style={{ color: "white", textAlign: "left" }}>
                      Leaves Status
                    </h2>
                    <img
                      src={leave}
                      alt='shield'
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        right: "10px",
                        bottom: "10px",
                      }}
                    ></img>
                  </div>
                </Paper>
              </Grid>
              <br></br>
              <h2
                style={{
                  color: "#1F299C",
                  textAlign: "left",
                  width: "90%",
                  paddingLeft: "10px",
                }}
              >
                Calender
              </h2>
              <Paper
                style={{ padding: "12px", margin: "0px 5px", width: "100%" }}
              >
                <Scheduler data={attData} height={660}>
                  <ViewState
                    currentDate={currentDate}
                    currentViewName={currentViewName}
                    onCurrentDateChange={async (newCurrentName, i) => {
                      setCurrentDate(newCurrentName);
                      setIsLoading(true);
                      await getShiftByUser(newCurrentName);
                      setIsLoading(false);
                    }}
                  />
                  <WeekView name='work-week' startDayHour={0} endDayHour={24} />
                  <MonthView />
                  <Toolbar style={{ color: "#1F299C" }} />
                  <DateNavigator />
                  <TodayButton />
                  {tabValue === 2 ? (
                    <Appointments
                      appointmentComponent={AbsentAppointment}
                      appointmentContentComponent={AppointmentContent}
                    />
                  ) : (
                    <Appointments
                      appointmentComponent={Appointment}
                      appointmentContentComponent={AppointmentContent}
                    />
                  )}
                  <AppointmentTooltip
                    showCloseButton
                    contentComponent={TooltipContent}
                  />
                  <Tabs
                    TabIndicatorProps={{ style: { backgroundColor: "white" } }}
                    value={tabValue}
                    onChange={handleTabChange}
                    indicatorColor='primary'
                    variant='scrollable'
                    scrollButtons='auto'
                    style={{
                      border: "1px solid #1F299C",
                      borderRadius: "52px",
                      color: "#1F299C",
                    }}
                  >
                    <Tab
                      label=' Current Week Shift'
                      {...a11yProps(0)}
                      style={
                        tabValue === 0
                          ? {
                            backgroundColor: "#1F299C",
                            color: "#ffffff",
                            borderRadius: "40px",
                          }
                          : {
                            borderRadius: "40px",
                          }
                      }
                    />
                    <Tab
                      label='Monthly Shifts'
                      {...a11yProps(1)}
                      style={
                        tabValue === 1
                          ? {
                            backgroundColor: "#1F299C",
                            color: "#ffffff",
                            borderRadius: "40px",
                          }
                          : {
                            borderRadius: "40px",
                          }
                      }
                    />
                    <Tab
                      label='Attendance Details'
                      {...a11yProps(2)}
                      style={
                        tabValue === 2
                          ? {
                            backgroundColor: "#1F299C",
                            color: "#ffffff",
                            borderRadius: "40px",
                          }
                          : {
                            borderRadius: "40px",
                          }
                      }
                    />
                  </Tabs>
                </Scheduler>
              </Paper>
            </Grid>
          )}
          {!showLeaves && (
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <Paper>
                  <h2
                    style={{
                      color: "#1F299C",
                      textAlign: "left",
                      padding: "8px",
                      margin: "8px 0",
                    }}
                  >
                    Leaves & Missed Punch
                  </h2>
                  <Divider />
                  <Tabs
                    TabIndicatorProps={{ style: { backgroundColor: "white" } }}
                    value={tabLeaveValue}
                    onChange={handleLeaveTabChange}
                    indicatorColor='primary'
                    textColor='#1F299C'
                    style={{
                      border: "1px solid #1F299C",
                      padding: " 4px",
                      borderRadius: "52px",
                      color: "#1F299C",
                    }}
                  >
                    <Tab
                      label='My Leaves'
                      {...a11yProps(0)}
                      style={
                        tabLeaveValue === 0
                          ? {
                            backgroundColor: "#1F299C",
                            color: "#ffffff",
                            borderRadius: "40px",
                          }
                          : {
                            borderRadius: "40px",
                          }
                      }
                    />
                    <Tab
                      label='Missed Punchins'
                      {...a11yProps(1)}
                      style={
                        tabLeaveValue === 1
                          ? {
                            backgroundColor: "#1F299C",
                            color: "#ffffff",
                            borderRadius: "40px",
                          }
                          : {
                            borderRadius: "40px",
                          }
                      }
                    />
                  </Tabs>
                  <TabPanel value={tabLeaveValue} index={0}>
                    <TableContainer component={Paper}>
                      <Table
                        className={classes.table}
                        aria-label='customized table'
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Type</StyledTableCell>
                            <StyledTableCell align='right'>
                              From
                            </StyledTableCell>
                            <StyledTableCell align='right'>To</StyledTableCell>
                            <StyledTableCell align='right'>
                              Duration
                            </StyledTableCell>
                            <StyledTableCell align='right'>
                              Status
                            </StyledTableCell>
                            <StyledTableCell align='right'>
                              Cancel
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody style={{ color: "#16215B" }}>
                          {leaveData &&
                            leaveData.map((row) => (
                              <StyledTableRow key={row.type}>
                                <StyledTableCell component='th' scope='row'>
                                  {row.type}
                                </StyledTableCell>
                                <StyledTableCell align='right'>
                                  {moment(row.from).format("DD-MM-YYYY ")}
                                </StyledTableCell>
                                <StyledTableCell align='right'>
                                  {moment(row.to).format("DD-MM-YYYY ")}
                                </StyledTableCell>
                                <StyledTableCell align='right'>
                                  {row.duration}
                                </StyledTableCell>
                                <StyledTableCell
                                  align='right'
                                  style={{
                                    fontWeight: 600,
                                    color:
                                      row.status === "Approved"
                                        ? "#22b749"
                                        : row.status === "Rejected"
                                          ? "#e9594a"
                                          : "#1F299C",
                                  }}
                                >
                                  {row.status}
                                </StyledTableCell>
                                <StyledTableCell align='right'>
                                  <Delete
                                    style={{
                                      color:
                                        row.status === "Pending" ||
                                          row.status.length === 0
                                          ? "#EE433C"
                                          : "#ed857e",
                                      cursor:
                                        row.status === "Pending" ||
                                          row.status.length === 0
                                          ? "pointer"
                                          : "default",
                                    }}
                                    onClick={() => confirmDeleteLeave(row._id)}
                                  />
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          {!leaveData && <p>No Leaves Found !</p>}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </TabPanel>
                  <TabPanel value={tabLeaveValue} index={1}>
                    <TableContainer component={Paper}>
                      <Table
                        className={classes.table}
                        aria-label='customized table'
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align='left'>
                              Punchin Time
                            </StyledTableCell>
                            <StyledTableCell align='left'>
                              Punchout Time
                            </StyledTableCell>
                            <StyledTableCell align='left'>
                              Status
                            </StyledTableCell>
                            <StyledTableCell align='left'>
                              Missed Punch Note
                            </StyledTableCell>
                            <StyledTableCell align='right'>
                              Cancel
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody style={{ color: "#16215B" }}>
                          {punchinData &&
                            punchinData.map((row) => (
                              <StyledTableRow key={row.type}>
                                <StyledTableCell component='th' scope='row'>
                                  {moment(row.punchin_time).format(
                                    "DD-MM-YYYY hh:mm a"
                                  )}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {moment(row.punchout_time).format(
                                    "DD-MM-YYYY hh:mm a"
                                  )}
                                </StyledTableCell>
                                <StyledTableCell
                                  style={{
                                    fontWeight: 600,
                                    color:
                                      row.status === "Approved"
                                        ? "#22b749"
                                        : row.status === "Rejected"
                                          ? "#e9594a"
                                          : "#1F299C",
                                  }}
                                >
                                  {row.status}
                                </StyledTableCell>
                                <StyledTableCell
                                  style={{
                                    color: row.missed_punch_note
                                      ? "inherit"
                                      : "#ccc",
                                  }}
                                >
                                  {row.missed_punch_note
                                    ? row.missed_punch_note
                                    : "Empty"}
                                </StyledTableCell>
                                <StyledTableCell align='right'>
                                  <Delete
                                    style={{
                                      color:
                                        row.status === "Pending" ||
                                          row.status.length === 0
                                          ? "#EE433C"
                                          : "#ed857e",
                                      cursor:
                                        row.status === "Pending" ||
                                          row.status.length === 0
                                          ? "pointer"
                                          : "default",
                                    }}
                                    onClick={() => confirmDeletePunchIn(row.id)}
                                  />
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          {!punchinData && <p>No Missed Punchins Found !</p>}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </TabPanel>
                </Paper>
              </Grid>
            </Grid>
          )}
        </div>
      </Container>
      <Dialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <Typography
              variant='h5'
              style={{
                color: "black",
              }}
            >
              Do you want to <span style={{ color: "#e9594a" }}> delete </span>{" "}
              this punch in ?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirm} color='primary'>
            No
          </Button>
          <Button
            onClick={() => {
              handleDeletePunchIn();
            }}
            color='primary'
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfirmLeave}
        onClose={handleCloseConfirmLeave}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <Typography
              variant='h5'
              style={{
                color: "black",
              }}
            >
              Do you want to <span style={{ color: "#e9594a" }}> delete </span>{" "}
              this leave ?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmLeave} color='primary'>
            No
          </Button>
          <Button
            onClick={() => {
              handleDeleteLeave();
            }}
            color='primary'
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop className={mainClasses.backdrop} open={isLoading}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </>
  );
};

export default LeavesNAttendance;
