import React from "react";
import {
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Grid,
  Paper
} from "@material-ui/core";

import Scrollbars from "react-custom-scrollbars";
import FolderIcon from "@material-ui/icons/Folder";
import { SaveAlt } from "@material-ui/icons";
import useAuth from "hooks/useAuth";
import myStyles from "../ProfileStyles";


const getDocumentList = (data, type = '') => {

  return data && data.map((item, key) => {
    return (
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <FolderIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={item.name} />
        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="delete">
            <a target="_blank" rel="noopener noreferrer" href={item.url}><SaveAlt /></a>
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  });
}

function DocumentsPanel(props) {
  const myClasses = myStyles();
  const auth = useAuth();

  return (
    <Grid container direction="row" spacing={4}>
      {/* Personal------------  */}
      <Grid item xs={12} sm={6} lg={4}>
        <Paper component="div" className={myClasses.paper}>
          <Grid container direction="row" justify="space-evenly">
            <Grid item xs={12} className={myClasses.label}>
              <Typography variant="h5" color="primary">
                Personal Docs
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Scrollbars
                style={{ width: "100%", height: "175px" }}
                autoHide
                autoHideTimeout={1000}
              >
                <List dense>
                  {getDocumentList(auth.user.personal_docs ? auth.user.personal_docs : [])}
                </List>
              </Scrollbars>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {/* Company----------------- */}
      <Grid item xs={12} sm={6} lg={4}>
        <Paper component="div" className={myClasses.paper}>
          <Grid container direction="row" justify="space-evenly">
            <Grid item xs={12} className={myClasses.label}>
              <Typography variant="h5" color="primary">
                Company Docs
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Scrollbars
                style={{ width: "100%", height: "175px" }}
                autoHide
                autoHideTimeout={1000}
              >
                <List dense>
                  {getDocumentList(auth.user.company_docs ? auth.user.company_docs : [])}
                </List>
              </Scrollbars>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {/* Extra */}
      <Grid item xs={12} sm={6} lg={4}>
        <Paper component="div" className={myClasses.paper}>
          <Grid container direction="row" justify="space-evenly">
            <Grid item xs={12} className={myClasses.label}>
              <Typography variant="h5" color="primary">
                Extra Docs
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Scrollbars
                style={{ width: "100%", height: "175px" }}
                autoHide
                autoHideTimeout={1000}
              >
                <List dense>
                  {getDocumentList(auth.user.additional_docs ? auth.user.additional_docs : [])}
                </List>
              </Scrollbars>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default DocumentsPanel;
