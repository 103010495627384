import React, { useState, useEffect, Fragment } from "react";
import Sidebar from "../../sidebar/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { getAllAsset } from "store/actions/AssetManagement/allAssets";
import { set } from "lodash";

const SidebarContent = (props) => {
  const {
    filterOpenCloseHandle,
    filterData,
    setFilterData,
    search,
    setSearch,
    setMultipleFilter,
    multipleFilter,
  } = props;
  const dispatch = useDispatch();
  const selectBox = (list, searchItem = "", type = "category") => {
    let toSearch = searchItem != "" ? searchItem : "";
    return list
      .filter((item) => {
        if (item?.name?.toLowerCase()?.startsWith(toSearch)) {
          return item;
        }
      })
      .map((item, index) => {
        return (
          <div key={index} className="mt-2 font-semibold">
            <input
              type="checkbox"
              checked={item.checked}
              onChange={() => {
                onChangeSelectBox(item.id, type);
              }}
              className="rounded cursor-pointer focus:ring-2"
            />
            <label className="ml-2 text-lg">{item.name}</label>
          </div>
        );
      });
  };

  const onChangeSelectBox = (value, type) => {
    setFilterData((prevState) => {
      let filters = [...prevState];
      let filterIndex = filters.findIndex((i) => i.filtername === type);
      let items = [...filters[filterIndex].optionList];
      let itemIndex = items.findIndex((i) => i.id === value);
      items[itemIndex].checked = !items[itemIndex].checked;
      filters[filterIndex].optionList = items;
      return filters;
    });
  };

  const handleSearch = (value, type) => {
    setFilterData((prevState) => {
      let filters = [...prevState];
      let filterIndex = filters.findIndex((i) => i.filtername === type);
      filters[filterIndex].search = value;
      return filters;
    });
  };

  const onSelectAll = (type) => {
    setFilterData((prevState) => {
      let filters = [...prevState];
      let filterIndex = filters.findIndex((i) => i.filtername === type);
      filters[filterIndex].optionList = filters[filterIndex].optionList.map(
        (data) => {
          return { ...data, checked: true };
        }
      );
      return filters;
    });
  };

  const onClearAll = (type) => {
    setFilterData((prevState) => {
      let filters = [...prevState];
      let filterIndex = filters.findIndex((i) => i.filtername === type);
      filters[filterIndex].optionList = filters[filterIndex].optionList.map(
        (data) => {
          return { ...data, checked: false };
        }
      );
      return filters;
    });
  };

  const onClearFilter = () => {
    setFilterData((prevState) => {
      let filters = prevState.map((data) => {
        let optionList = data.optionList.map((data) => {
          return { ...data, checked: false };
        });
        return { ...data, optionList };
      });
      return filters;
    });
    setSearch("");
    dispatch(getAllAsset());
    setMultipleFilter(false);
    filterOpenCloseHandle();
  };

  const onSaveFilter = () => {
    let params = {};
    params.search = search != "" ? search : null;
    filterData.forEach((element) => {
      params[element.paramKey] = [
        ...element.optionList
          .filter((item) => item.checked)
          .map((item) => item[element.paramValue]),
      ].join(",");
    });
    console.log(params);
    dispatch(getAllAsset(params));
    setMultipleFilter(true);
    filterOpenCloseHandle();
  };

  return (
    <Sidebar sidebarHandler={filterOpenCloseHandle} modalTitle="Filter">
      {console.log(filterData)}
      {filterData.map((filter, index) => {
        return (
          <Fragment key={index}>
            {index != 0 ? (
              <div className="border-b-2 border-gray-300 my-5"></div>
            ) : (
              <></>
            )}
            <div>
              <p className="text-xl font-bold">{filter.filtername}</p>
              <input
                type="text"
                className="px-5 py-3 bg-gray-100 w-full mt-3 rounded"
                placeholder="Search..."
                value={filter.search}
                onChange={(e) => {
                  handleSearch(e.target.value.toLowerCase(), filter.filtername);
                }}
              />
              <div>
                <div className="flex justify-between mt-5">
                  <p
                    className="cursor-pointer"
                    onClick={() => {
                      onSelectAll(filter.filtername);
                    }}
                  >
                    Select All
                  </p>
                  <p
                    className="cursor-pointer"
                    onClick={() => {
                      onClearAll(filter.filtername);
                    }}
                  >
                    Clear All
                  </p>
                </div>
                {filter.optionList ? (
                  selectBox(filter.optionList, filter.search, filter.filtername)
                ) : (
                  <></>
                )}
              </div>
            </div>
          </Fragment>
        );
      })}

      {/* ------------------------------------- */}

      <div className="border-b-2 border-gray-300 my-5"></div>
      <div className="flex justify-end gap-5 flex-wrap">
        <p
          className="py-1 px-3 font-semibold border cursor-pointer border-blue-700 rounded"
          onClick={() => {
            onClearFilter();
          }}
        >
          Clear Filter
        </p>
        <p
          className="py-1 px-3 font-semibold border cursor-pointer border-blue-700 rounded"
          onClick={filterOpenCloseHandle}
        >
          Cancel
        </p>
        <p
          className="py-1 px-3 font-semibold border cursor-pointer border-blue-700 rounded bg-indigo-700 text-gray-50"
          onClick={() => {
            onSaveFilter();
          }}
        >
          Save
        </p>
      </div>
    </Sidebar>
  );
};

export default SidebarContent;
