import { updateEmployeeStatusRepo } from "../../repositories/HrManagement/MyEmployees";
import {
    updateEmployeeStatusPending,
    updateEmployeeStatusFulfilled,
    updateEmployeeStatusRejected
} from "../../slices/HrManagement/MyEmployees";

export const updateEmployeeStatus = (data) => {
    return async (dispatch) => {
        dispatch(updateEmployeeStatusPending());
        await updateEmployeeStatusRepo(data)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(updateEmployeeStatusFulfilled(response?.data));
                } else {
                    dispatch(updateEmployeeStatusRejected(response?.data));
                }
            })
            .catch((error) => {
                if (error && error.response) {

                    dispatch(updateEmployeeStatusRejected(error.message));
                }
            });
    };
};



