import React from "react";
import Sidebar from "pages/sidebar/Sidebar";
import { handoverAsset } from "store/actions/AssetManagement/assetAssign";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Select from "react-select";
import useAuth from "hooks/useAuth";

const HandoverSidebar = ({ handoverSidebarHandler }) => {
  return (
    <Sidebar sidebarHandler={handoverSidebarHandler} modalTitle="Handover">
      <div className="border px-4 py-3">
        <p className="text-blue-900 text-lg font-bold">Laptop</p>
        <p>Type: MacBook Pro</p>
        <p>Model: ABCD123456789</p>
      </div>
      <div className="mt-4">
        <p className="text-base font-bold">Condition*</p>
        <select className="bg-gray-100 border-2 rounded-md outline-0 px-2 py-3 w-full font-bold mt-1 focus:ring focus:outline-none">
          <option>Working Condition</option>
          <option>New</option>
          <option>New</option>
          <option>Old</option>
        </select>
      </div>
      <div className="mt-4">
        <p className="text-base font-bold mb-1">Handover*</p>
        <input
          type="date"
          className="bg-gray-100 border-2 rounded-md outline-0 px-2 py-3 w-full font-bold focus:ring focus:outline-none"
        />
      </div>
      <div className="mt-4">
        <p className="text-base font-bold">Handover To</p>
        <select className="bg-gray-100 border-2 rounded-md outline-0 px-2 py-3 w-full font-bold mt-1 focus:ring focus:outline-none">
          <option>Name Lastname (E12345678)</option>
        </select>
      </div>
      <div className="mt-4">
        <p className="text-base font-bold">Remark</p>
        <select className="bg-gray-100 border-2 rounded-md outline-0 px-2 py-3 w-full font-bold mt-1 focus:ring focus:outline-none">
          <option>Sample text remark abcd</option>
        </select>
      </div>
      <div className="mt-4">
        <p className="text-base font-bold">Attach Asset Photo *</p>
        <div className="bg-gray-100 border-2 rounded-md outline-0 px-2 py-5 w-full font-bold mt-1 focus:ring focus:outline-none flex gap-2 items-center cursor-pointer">
          <p className="text-gray-500 text-base"> Drag & Drop Here,</p>{" "}
          <p>Or Browser File</p>
          <i className="fa-solid fa-link ml-3"></i>
        </div>
      </div>
      <div className="border-b-2 border-gray-300 my-5"></div>
      <div className="flex justify-end gap-5">
        <p
          className="py-1 px-3 font-bold border cursor-pointer border-blue-700 rounded"
          onClick={handoverSidebarHandler}
        >
          Cancel
        </p>
        <p className="py-1 px-3 font-bold border cursor-pointer border-blue-700 rounded bg-indigo-700 text-gray-50">
          Save
        </p>
      </div>
    </Sidebar>
  );
};

export default HandoverSidebar;
