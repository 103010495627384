import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  TextField,
  IconButton,
  Snackbar,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import axios from 'axios';
import { withStyles } from '@material-ui/styles';
import 'react-phone-input-labelled/dist/style.css';
import { makeStyles } from '@material-ui/core';
import SketchPicker from 'react-color';

import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

// ---- toggle switch ------

import { green } from '@material-ui/core/colors';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useDispatch, useSelector } from 'react-redux';
import {
  updatePosition,
  createPosition,
} from '../../../store/actions/ShiftManagement/Positions';

const GreenSwitch = withStyles({
  switchBase: {
    color: green[300],
    '&$checked': {
      color: green[500],
    },
    '&$checked + $track': {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

//  form element styling --------------------------
const formStyles = makeStyles((theme) => ({
  sectionHeading: {
    color: '#1F299C',
    fontWeight: '700',
    margin: '10px',
    marginBottom: '20px',
  },

  fieldContainer: {
    maxWidth: '250px',
    display: 'inline-block',
    marginRight: '20px',
    // flexDirection: 'column'
  },

  subtitle: {
    marginLeft: '10px',
    color: 'black',
    fontWeight: '600',
  },
  colorBlue: {
    color: 'rgb(31,41,156)',
  },
  colorDarkBlue: {
    color: 'rgb(22,33,91)',
  },
}));

// --------- CssTextFeild ----------------
const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .Mui-disabled': {
      color: 'black',
    },

    '& .MuiInputBase': {
      background: '#F7F8FC',
      backgroundColor: '#F7F8FC',
    },
  },
})(TextField);

// ---------  Input for Snackbar -----------------

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}
// -----------------------------------------------

function AddPositionDialog(props) {
  const myClasses = formStyles();
  const dispatch = useDispatch();
  const [formError, setFormError] = useState(null);
  const [formSuccess, setFormSuccess] = useState(null);
  const [positionTitle, setPositionTitle] = useState('');
  // const [titleError, setTitleError] = useState(true);
  const [positionColor, setPositionColor] = useState('');
  const [activeStatus, setActiveStatus] = useState('');

  const handleToggleSwitch = (event) => {
    if (props.operation !== 'view') {
      setActiveStatus(!activeStatus);
    }
  };

  const handleCreatePosition = async () => {
    let data = {
      active: `${activeStatus}`,
      position_title: positionTitle,
      position_color: positionColor,
    };
    dispatch(createPosition(data));
    handleCloseDialog();
  };

  const handleUpdatePosition = async () => {
    let data = {
      _id: props.position._id,
      active: `${activeStatus}`,
      position_title: positionTitle,
      position_color: positionColor,
    };
    dispatch(updatePosition(data));
    handleCloseDialog();
  };

  useEffect(() => {
    if (props.operation === 'view' || props.operation === 'edit') {
      if (props.position.active === 'true') {
        setActiveStatus(true);
      } else {
        setActiveStatus(false);
      }
      setPositionColor(props.position.position_color);
      setPositionTitle(props.position.position_title);
    } else if (props.operation === 'create') {
      setActiveStatus(false);
      setPositionColor('');
      setPositionTitle('');
    }
  }, [props]);

  const handleChangeComplete = (color, e) => {
    if (!props.readOnly) {
      setPositionColor(color.hex);
    }
  };

  const handleCloseDialog = () => {
    setPositionTitle('');
    setPositionColor('');
    setActiveStatus(false);
    props.closeForm();
  };

  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  return (
    <ThemeProvider theme={theme}>
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '10px',
          }}
        >
          <Typography
            variant='h5'
            // className = {classes.colorDarkBlue}
            style={{
              fontWeight: '600',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {props.operation === 'view'
              ? 'Position Details'
              : props.operation === 'edit'
              ? 'Edit Position'
              : 'Add Position'}
          </Typography>

          <IconButton
            aria-label='close'
            onClick={handleCloseDialog}
            style={{
              margin: '0',
              padding: '0',
              color: 'black',
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <hr></hr>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '20px',
            padding: '5px 10px',
            backgroundColor: 'rgb(247,248,252)',
            borderRadius: '5px',
            boxShadow: 'lightgray 0px 0px 5px',
          }}
        >
          <Typography
            variant='h6'
            className={myClasses.colorDarkBlue}
            style={{
              fontWeight: '600',
            }}
          >
            Active
          </Typography>

          <FormControlLabel
            control={
              <GreenSwitch
                onChange={handleToggleSwitch}
                checked={activeStatus}
              />
            }
          />
        </div>

        <Typography
          variant='h6'
          className={myClasses.colorDarkBlue}
          style={{
            fontWeight: '600',
            margin: '10px 20px',
          }}
        >
          Position Title<span style={{ color: 'red' }}>*</span>
        </Typography>

        <CssTextField
          variant='outlined'
          style={{
            backgroundColor: 'rgb(247,248,252)',
            boxShadow: 'lightgray 0px 0px 5px',
            margin: '0px 20px',
            width: '300px',
          }}
          value={positionTitle}
          disabled={props.readOnly}
          onChange={(e) => {
            setPositionTitle(e.target.value);
            // setFnameError(false);
          }}
        />

        <Typography
          variant='h6'
          className={myClasses.colorDarkBlue}
          style={{
            fontWeight: '600',
            margin: '10px 20px',
          }}
        >
          Position Color
        </Typography>

        <div
          style={{
            marginLeft: '20px',
            zoom: '1.1',
          }}
        >
          <SketchPicker
            onChange={handleChangeComplete}
            color={positionColor}
            width='350px'
          />
        </div>
      </div>

      {props.operation !== 'view' && (
        <div>
          <hr></hr>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '0px 10px 10px 0px',
            }}
          >
            <Button
              variant='outlined'
              color='primary'
              style={{
                marginRight: '10px',
              }}
              onClick={handleCloseDialog}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              color='primary'
              onClick={
                props.operation === 'create'
                  ? handleCreatePosition
                  : handleUpdatePosition
              }
            >
              Save
            </Button>
          </div>
        </div>
      )}

      {/* ---------------------------------------------- */}
      <Snackbar
        open={!!formError}
        onClose={() => setFormError(false)}
        // TransitionComponent={<Slide direction="up" />}
        key={'Form Error'}
        autoHideDuration={3000}
      >
        <Alert severity='error'>{formError}</Alert>
      </Snackbar>
      <Snackbar
        open={!!formSuccess}
        onClose={() => setFormSuccess(false)}
        // TransitionComponent={<Slide direction="up" />}
        key={'Form Success'}
        autoHideDuration={2000}
      >
        <Alert severity='success'>{formSuccess}</Alert>
      </Snackbar>
    </ThemeProvider>
  );
}

export default AddPositionDialog;
