import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  templates: {},
  positions: {},
  deleteTemptate: {},
  updateTemplate: {},
  tags: {},
  taskJobs: {},
  shiftProjects: {},
  jobSites: {},
  postShiftTemplate: {},
};

export const shiftTemplateSlice = createSlice({
  name: "shiftTemplates",
  initialState,
  reducers: {
    getShiftTemplatesPending(state, action) {
      return {
        ...state,
        templates: { loading: true },
      };
    },
    getShiftTemplatesFulfilled(state, action) {
      return {
        ...state,
        templates: {
          loading: false,
          fulfilled: action.payload.data,
          length: action.payload.length,
        },
      };
    },
    getShiftTemplatesRejected(state, action) {
      return {
        ...state,
        templates: {
          loading: false,
          rejected: action.payload,
        },
      };
    },

    getAllShiftPositionsPending(state, action) {
      return {
        ...state,
        positions: { loading: true },
      };
    },
    getAllShiftPositionsFulfilled(state, action) {
      return {
        ...state,
        positions: {
          loading: false,
          fulfilled: action.payload.data,
          totalLength: action.payload.length,
        },
      };
    },
    getAllShiftPositionsRejected(state, action) {
      return {
        ...state,
        positions: {
          loading: false,
          rejected: action.payload,
        },
      };
    },

    deleteShiftTemplatePending(state, action) {
      return {
        ...state,
        deleteTemptate: { loading: true },
      };
    },
    deleteShiftTemplateFulfilled(state, action) {
      return {
        ...state,
        deleteTemptate: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    deleteShiftTemplateRejected(state, action) {
      return {
        ...state,
        deleteTemptate: {
          loading: false,
          rejected: action.payload,
        },
      };
    },

    updateShiftTemplatesPending(state, action) {
      return {
        ...state,
        updateTemplate: { loading: true },
      };
    },
    updateShiftTemplatesFulfilled(state, action) {
      return {
        ...state,
        updateTemplate: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    updateShiftTemplatesRejected(state, action) {
      return {
        ...state,
        updateTemplate: {
          loading: false,
          rejected: action.payload,
        },
      };
    },

    getAllTagsPending(state, action) {
      return {
        ...state,
        tags: { loading: true },
      };
    },
    getAllTagsFulfilled(state, action) {
      return {
        ...state,
        tags: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    getAllTagsRejected(state, action) {
      return {
        ...state,
        tags: {
          loading: false,
          rejected: action.payload,
        },
      };
    },

    getAllShiftTaskJobsPending(state, action) {
      return {
        ...state,
        taskJobs: { loading: true },
      };
    },
    getAllShiftTaskJobsFulfilled(state, action) {
      return {
        ...state,
        taskJobs: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    getAllShiftTaskJobsRejected(state, action) {
      return {
        ...state,
        taskJobs: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
    clearAllShiftTaskJobs(state, action) {
      return {
        ...state,
        taskJobs: {},
      };
    },

    getAllShiftProjectsPending(state, action) {
      return {
        ...state,
        shiftProjects: { loading: true },
      };
    },
    getAllShiftProjectsFulfilled(state, action) {
      return {
        ...state,
        shiftProjects: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    getAllShiftProjectsRejected(state, action) {
      return {
        ...state,
        shiftProjects: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
    clearAllShiftProjects(state, action) {
      return {
        ...state,
        shiftProjects: {},
      };
    },

    getAllShiftJobSitesPending(state, action) {
      return {
        ...state,
        jobSites: { loading: true },
      };
    },
    getAllShiftJobSitesFulfilled(state, action) {
      return {
        ...state,
        jobSites: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    getAllShiftJobSitesRejected(state, action) {
      return {
        ...state,
        jobSites: {
          loading: false,
          rejected: action.payload,
        },
      };
    },

    createShiftTemplatesPending(state, action) {
      return {
        ...state,
        postShiftTemplate: { loading: true },
      };
    },
    createShiftTemplatesFulfilled(state, action) {
      return {
        ...state,
        postShiftTemplate: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    createShiftTemplatesRejected(state, action) {
      return {
        ...state,
        postShiftTemplate: {
          loading: false,
          rejected: action.payload,
        },
      };
    },

    searchShiftTemplatesPending(state, action) {
      return {
        ...state,
        templates: { loading: true },
      };
    },

    searchShiftTemplatesFulfilled(state, action) {
      return {
        ...state,
        templates: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    searchShiftTemplatesRejected(state, action) {
      return {
        ...state,
        templates: {
          loading: false,
          rejected: action.payload,
        },
      };
    },

    clearAllShiftJobSites(state, action) {
      return {
        ...state,
        jobSites: {},
      };
    },
  },
});

export const {
  getShiftTemplatesPending,
  getShiftTemplatesFulfilled,
  getShiftTemplatesRejected,
  getAllShiftPositionsPending,
  getAllShiftPositionsFulfilled,
  getAllShiftPositionsRejected,
  deleteShiftTemplatePending,
  deleteShiftTemplateFulfilled,
  deleteShiftTemplateRejected,
  updateShiftTemplatesPending,
  updateShiftTemplatesFulfilled,
  updateShiftTemplatesRejected,
  getAllTagsPending,
  getAllTagsFulfilled,
  getAllTagsRejected,
  getAllShiftTaskJobsPending,
  getAllShiftTaskJobsFulfilled,
  getAllShiftTaskJobsRejected,
  getAllShiftProjectsPending,
  getAllShiftProjectsFulfilled,
  getAllShiftProjectsRejected,
  getAllShiftJobSitesPending,
  getAllShiftJobSitesFulfilled,
  getAllShiftJobSitesRejected,
  clearAllShiftTaskJobs,
  clearAllShiftProjects,
  clearAllShiftJobSites,
  createShiftTemplatesPending,
  createShiftTemplatesFulfilled,
  createShiftTemplatesRejected,
  searchShiftTemplatesPending,
  searchShiftTemplatesFulfilled,
  searchShiftTemplatesRejected,
} = shiftTemplateSlice.actions;

export default shiftTemplateSlice.reducer;
