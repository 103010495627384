import React from "react";
import Sidebar from "pages/sidebar/Sidebar";

const NewRequestSidebar = ({ newRequestHanlder }) => {
  return (
    <Sidebar sidebarHandler={newRequestHanlder} modalTitle="New Request">
      <div className="h-screen">
        <div>
          <p className="text-blue-900 font-semibold">Category*</p>
          <select className="bg-gray-100 border-2 rounded-md outline-0 px-2 py-3 w-full font-bold mt-1 focus:ring focus:outline-none">
            <option>Electronic</option>
            <option>Clotha</option>
            <option>Vehicle</option>
          </select>
        </div>
        <div className="mt-4">
          <p className="text-blue-900 font-semibold">Sub - Category*</p>
          <select className="bg-gray-100 border-2 rounded-md outline-0 px-2 py-3 w-full font-bold mt-1 focus:ring focus:outline-none">
            <option>Laptop</option>
          </select>
        </div>
      </div>
      <div className="border-b-2 border-gray-300 my-5"></div>
      <div className="flex justify-end gap-5">
        <p
          className="py-1 px-3 font-bold border cursor-pointer border-blue-700 rounded"
          onClick={newRequestHanlder}
        >
          Cancel
        </p>
        <p className="py-1 px-3 font-bold border cursor-pointer border-blue-700 rounded bg-indigo-700 text-gray-50">
          Save
        </p>
      </div>
    </Sidebar>
  );
};

export default NewRequestSidebar;
