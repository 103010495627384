import React from "react";
import Sidebar from "pages/sidebar/Sidebar";

const CommentSidebar = ({ commentSidebarHandler }) => {
  return (
    <Sidebar modalTitle="Comment" sidebarHandler={commentSidebarHandler}>
      <div className="h-screen">
        <div className="border p-3 rounded">
          <p className="text-lg font-bold mb-1">Neme Lastname (HR)</p>
          <p className="leading-5 text-gray-900 font-semibold">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type.
          </p>
          <p className="text-gray-600 mt-7">29-07-2023 10:00 AM</p>
        </div>
      </div>
      <div className="justify-between flex gap-6 items-center">
        <input
          type="text"
          placeholder="Type a remark..."
          className="bg-gray-100 border-2 rounded outline-0 px-2 py-1 w-full font-semibold focus:ring focus:outline-none"
        />
        <i className="fa-solid fa-paper-plane bg-blue-900 rounded-full text-white cursor-pointer w-12 h-10 flex justify-center items-center"></i>
      </div>
    </Sidebar>
  );
};

export default CommentSidebar;
