import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  messages: {},
  deleteMessages: {},
  createdMessages: {},
  editMessages: {},
};

export const communicationSlice = createSlice({
  name: "communication",
  initialState,
  reducers: {
    getCommunicationPending(state, action) {
      return {
        ...state,
        messages: { loading: true },
      };
    },
    getCommunicationFulfilled(state, action) {
      return {
        ...state,
        messages: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    getCommunicationRejected(state, action) {
      return {
        ...state,
        messages: {
          loading: false,
          rejected: action.payload,
        },
      };
    },

    deleteCommunicationPending(state, action) {
      return {
        ...state,
        deleteMessages: { loading: true },
      };
    },
    deleteCommunicationFulfilled(state, action) {
      return {
        ...state,
        deleteMessages: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    deleteCommunicationRejected(state, action) {
      return {
        ...state,
        deleteMessages: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
    clearDeleteCommunication(state, action) {
      return {
        ...state,
        deleteMessages: {},
      };
    },
    createCommunicationPending(state, action) {
      return {
        ...state,
        createdMessages: { loading: true },
      };
    },
    createCommunicationFulfilled(state, action) {
      return {
        ...state,
        createdMessages: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    createCommunicationRejected(state, action) {
      return {
        ...state,
        createdMessages: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
    clearCreateCommunication(state, action) {
      return {
        ...state,
        createdMessages: {},
      };
    },
    editCommunicationPending(state, action) {
      return {
        ...state,
        editMessages: { loading: true },
      };
    },
    editCommunicationFulfilled(state, action) {
      return {
        ...state,
        editMessages: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    editCommunicationRejected(state, action) {
      return {
        ...state,
        editMessages: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
    clearEditCommunication(state, action) {
      return {
        ...state,
        editMessages: {},
      };
    },
  },
});

export const {
  getCommunicationPending,
  getCommunicationFulfilled,
  getCommunicationRejected,
  deleteCommunicationPending,
  deleteCommunicationFulfilled,
  deleteCommunicationRejected,
  clearDeleteCommunication,
  createCommunicationPending,
  createCommunicationFulfilled,
  createCommunicationRejected,
  clearCreateCommunication,
  editCommunicationPending,
  editCommunicationFulfilled,
  editCommunicationRejected,
  clearEditCommunication,
} = communicationSlice.actions;

export default communicationSlice.reducer;
