import { makeStyles } from "@material-ui/core";

const drawerWidth = 240;

export const useResignStyles = makeStyles((theme) => ({
    root: {
        display: "flex"
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    margin: {
        margin: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        width: '90%',
    },
      selectEmpty: {
        marginTop: theme.spacing(2),
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }),
        marginLeft: 0
      },
      contentShift: {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: drawerWidth
      },
      backdrop: {
        zIndex: 9999,
        color: "#fff",
      }
}));
