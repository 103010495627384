import { useState, useEffect, useRef, useCallback } from "react";
import {
  useLoadScript,
  GoogleMap,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  //   getZipCode,
} from "use-places-autocomplete";
import { LocationOn } from "@material-ui/icons";
import { makeStyles, Snackbar, TextField, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { withStyles } from "@material-ui/styles";
import css from "./LocationSelector.module.css";

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "black",
    },
    "& .Mui-disabled": {
      color: "black",
    },

    "& .MuiInputBase": {
      background: "#F7F8FC",
      backgroundColor: "#F7F8FC",
    },
  },
})(TextField);

const formStyles = makeStyles(() => ({
  colorDarkBlue: {
    color: "rgb(22,33,91)",
  },
}));

const mapContainerStyle = {
  height: "100%",
  width: "100%",
};

const center = {
  lat: 56.1304,
  lng: -106.3468,
};

const libraries = ["places"];

// const getCityAndState = (result) => {
//   let city = "",
//     state = "";
//   for (let i = 0; i < result.address_components.length; i++) {
//     for (let b = 0; b < result.address_components[i].types.length; b++) {
//       switch (result.address_components[i].types[b]) {
//         case "locality":
//           city = result.address_components[i].long_name;
//           break;
//         case "administrative_area_level_1":
//           state = result.address_components[i].long_name;
//           break;
//         default: // do nothing
//       }
//     }
//   }
//   return { city, state };
// };

const parseLocation = (location, address) => {
  try {
    let parts = location.split(",");
    return {
      latitude: Number(parts[0].trim()),
      longitude: Number(parts[1].trim()),
      locality: address,
    };
  } catch (err) {
    return {};
  }
};

const LocationSelector = (props) => {
  const [location, setLocation] = useState({});
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  });
  const [error, setError] = useState("");
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const myClasses = formStyles();

  const mapRef = useRef();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const panTo = useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(12);
  }, []);

  useEffect(() => {
    if (location.locality) {
      props.setLocation(
        `${location.latitude?.toFixed(2)}, ${location.longitude?.toFixed(2)}`
      );
      props.setAddress(location.locality);
    } else {
      props.setLocation("");
    }
  }, [location]); //eslint-disable-line

  useEffect(() => {
    setLocation(parseLocation(props.location, props.address));
  }, [props.address]); //eslint-disable-line

  const handleLocationClick = async (event) => {
    if (props.readOnly) return;

    try {
      const lat = event.latLng.lat(),
        lng = event.latLng.lng();
      const results = await getGeocode({ location: { lat, lng } });

      if (!results?.[0]) throw new Error("No results found");

      //   const { city, state } = getCityAndState(results[0]);

      setLocation((prev) => ({
        ...prev,
        latitude: lat,
        longitude: lng,
        locality: results[0].formatted_address,
      }));
    } catch (err) {
      console.log(err);
      setError(err?.message || "Something went wrong");
    }
  };

  if (!isLoaded) return null;

  return (
    <div className={css.mainContainer}>
      <Snackbar
        open={!!error}
        autoHideDuration={4000}
        onClose={() => setError("")}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>

      <div className={css.container}>
        <Typography
          variant="h6"
          className={myClasses.colorDarkBlue}
          style={{
            fontWeight: "600",
            margin: "10px 0",
          }}
        >
          Address
        </Typography>

        <Search
          location={location}
          setLocation={setLocation}
          panTo={panTo}
          setError={setError}
          readOnly={props.readOnly}
        />

        <div className={css.mapContainer}>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={{
              lat: location.latitude || center.lat,
              lng: location.longitude || center.lng,
            }}
            zoom={8}
            onClick={handleLocationClick}
            onLoad={onMapLoad}
          >
            {!!location?.latitude && !!location?.longitude && (
              <Marker
                position={{ lat: location?.latitude, lng: location?.longitude }}
                onClick={() => setShowInfoWindow(true)}
              >
                {showInfoWindow && (
                  <InfoWindow onCloseClick={() => setShowInfoWindow(false)}>
                    <>
                      <div>{location.locality}</div>
                    </>
                  </InfoWindow>
                )}
              </Marker>
            )}
          </GoogleMap>
        </div>
      </div>
    </div>
  );
};

const Search = ({ location, setLocation, panTo, setError, readOnly }) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
    requestOptions: {
      componentRestrictions: {
        country: "CA",
      },
    },
  });

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (location?.locality) setValue(location.locality, false);
  }, [location?.locality]); //eslint-disable-line

  useEffect(() => {
    const handleClear = () => {
      clearSuggestions();
    };
    document.addEventListener("click", handleClear);
    return () => {
      document.removeEventListener("click", handleClear);
    };
  }, []); //eslint-disable-line

  const handleAddressSelect = async (address) => {
    try {
      const results = await getGeocode({ address });
      if (!results?.[0]) throw new Error("No results found");

      const { lat, lng } = getLatLng(results[0]);
      //   const { city, state } = getCityAndState(results[0]);

      setLocation((prev) => ({
        ...prev,
        latitude: lat,
        longitude: lng,
        locality: address,
        // pincode: getZipCode(results[0]),
        // city,
        // state,
      }));

      panTo({ lat, lng });
    } catch (err) {
      console.log(err);
      setError(err.message || "Something went wrong");
    }
  };

  return (
    ready && (
      <div className={css.inputContainer}>
        <CssTextField
          variant="outlined"
          style={{
            backgroundColor: "rgb(247,248,252)",
            boxShadow: "lightgray 0px 0px 5px",
            margin: 0,
            width: "100%",
          }}
          value={value}
          disabled={readOnly}
          onChange={handleChange}
          placeholder="Search location"
        />

        {status === "OK" && (
          <div className={css.suggestions}>
            {data.map((value, index) => (
              <div
                key={index}
                className={css.suggestion}
                onClick={() => handleAddressSelect(value.description)}
              >
                <div>
                  <LocationOn />
                </div>
                <div>{value.description}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    )
  );
};

export default LocationSelector;
