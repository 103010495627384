import * as React from "react";
import { Switch, Route } from "react-router-dom";
import AppProvider from "providers/AppProvider";
import AuthProvider from "providers/AuthProvider";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { QueryClient, QueryClientProvider } from "react-query";
import { onMessageListener } from "./firebase/index";
import * as pageRoutes from "auth/constants";
import Home from "pages/home";
import PrivateRoute from "auth/PrivateRoute";
import Login from "pages/login/Login";
import Profile from "pages/profile/Profile";
import LeaveNAttendance from "pages/LeavesNAttendance";
// import Tnc from "pages/tncs/Tnc";
import "./App.css";
import Payroll from "pages/payroll/Payroll";

// import Employees from "pages/employees/Employees";
import Projects from "pages/EmployeeManagement/Projects";
import EditProfile from "pages/editProfile/EditProfile";
import ResetPassword from "pages/ResetPassword";
import ForgotPassword from "pages/ForgotPassword";
import Shifts from "pages/shifts";
import { Resign } from "pages/Resign/Resign";
import Queries from "pages/Queries";

import { Dashboard } from "pages/EmployeeManagement/Dashboard/index";
import { LeavesAttendanceHR } from "pages/EmployeeManagement/LeavesAttendanceHR/index";
import { ShiftSchedule } from "pages/EmployeeManagement/ShiftSchedule/index";
import { QueriesAllTicket } from "pages/EmployeeManagement/QueriesAllTicket/index";
import { Documents } from "pages/EmployeeManagement/Documents/index";
import AssetMangement from "pages/EmployeeManagement/AssetMangement/AssetManagContainer";
import EmployPositionContainer from "pages/EmployeeManagement/Position/EmployPositionContainer";
import { ResignationHR } from "pages/EmployeeManagement/Resignation/index";

import { SDashboard } from "pages/ShiftManagement/Dashboard/index";
// import { PAGE_ROUTE_SHIFT_MNGMT_TIMESHEETS } from "./auth/constants";
import { STimesheets } from "./pages/ShiftManagement/Timesheets/index";
import EmployeeTimesheet from "./pages/ShiftManagement/Timesheets/EmployeeTimesheet";
import { SAllEmployees } from "./pages/ShiftManagement/AllEmployees/index";
import { SAllProjects } from "./pages/ShiftManagement/AllProjects/index";
import { SPositions } from "./pages/ShiftManagement/Positions/index";
import { SProjectsAndSites } from "./pages/ShiftManagement/ProjectsAndSites/index";
import AllAssignedAssets from "./pages/Assets/allAssignedAsset";
import AssetRequests from "./pages/Assets/newAssetRequest";
import RegistrationAndTermination from "./pages/Assets/registrationAndTermination";
import { STags } from "pages/ShiftManagement/Tags";
import { SShiftTemplates } from "./pages/ShiftManagement/ShiftTemplates/index";
import { SShiftTasks } from "./pages/ShiftManagement/ShiftTask/index";
import { SScheduler } from "./pages/ShiftManagement/Scheduler/index";


import { Asset } from "pages/Assets/dashboard/DashboardContainer";
import { AllAsset } from "pages/Assets/allAssets/AllAssetContainer";
import { AllAssetDetailsContainer } from "pages/Assets/allAssets/assetDetails/AllAssetDetailsContainer";
import { AssetAssignContainer } from "pages/Assets/assetAssignList/index";
import { AssetAssignDetailsContainer } from "pages/Assets/assetAssignList/assetAssignDetails";

import { AddCategoryContainer } from "pages/Assets/addCategory/AddCategoryContainer";
import { AddSubCategoryContainer } from "pages/Assets/addSubCategory/AddSubCategoryContainer";

import { AddConditionContainer } from "pages/Assets/addAssetCondition/AddConditionContainer";

import TimesheetProvider from "./pages/ShiftManagement/Timesheets/TimesheetContext";
import ListMessages from "pages/EmployeeCommunication/ListMessages";
import axios from "axios";
const queryClient = new QueryClient();

function App() {
  axios.defaults.headers.common["token"] = localStorage.getItem("token");
  axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

  React.useEffect(() => {
    onMessageListener()
      .then((payload) => {
        new Notification(payload.notification.title, {
          body: payload.notification.body,
        });
        console.log(payload);
      })
      .catch((err) => console.log("failed: ", err));
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <AppProvider>
        <AuthProvider>
          <TimesheetProvider>
            <div className="App">
              <Route
                render={({ location }) => (
                  <TransitionGroup>
                    <CSSTransition
                      key={location.key}
                      classNames="fade"
                      timeout={500}
                    >
                      <Switch location={location}>
                        <PrivateRoute exact path={pageRoutes.PAGE_ROUTE_HOME}>
                          <Home />
                        </PrivateRoute>
                        <PrivateRoute
                          exact
                          path={pageRoutes.PAGE_ROUTE_PAYROLL}
                        >
                          <Payroll />
                        </PrivateRoute>
                        <PrivateRoute
                          exact
                          path={pageRoutes.PAGE_ROUTE_EMPLOYEE}
                        >
                          {/* <Employees /> */}
                          <EditProfile />
                        </PrivateRoute>
                        <PrivateRoute
                          exact
                          path={
                            pageRoutes.PAGE_ROUTE_SHIFT_MNGMT_PROJECTSANDSITES
                          }
                        >
                          {/* <Employees /> */}
                          <SProjectsAndSites />
                        </PrivateRoute>

                        <PrivateRoute
                          exact
                          path={pageRoutes.PAGE_ROUTE_ALL_ASSIGNED_ASSETS}
                        >
                          <AllAssignedAssets />
                        </PrivateRoute>
                        <PrivateRoute
                          exact
                          path={pageRoutes.PAGE_ROUTE_ASSET_NEW_REQUEST}
                        >
                          <AssetRequests />
                        </PrivateRoute>
                        <PrivateRoute
                          exact
                          path={pageRoutes.PAGE_ROUTE_RESIGNATION_TERMINATION}
                        >
                          <RegistrationAndTermination />
                        </PrivateRoute>
                        <PrivateRoute exact path={pageRoutes.PAGE_ROUTE_LEAVES}>
                          <LeaveNAttendance />
                        </PrivateRoute>
                        <PrivateRoute
                          exact
                          path={pageRoutes.PAGE_ROUTE_PROJECTS}
                        >
                          <Projects />
                        </PrivateRoute>
                        <PrivateRoute
                          exact
                          path={pageRoutes.PAGE_ROUTE_PROFILE}
                        >
                          <Profile />
                        </PrivateRoute>
                        <PrivateRoute
                          exact
                          path={pageRoutes.PAGE_ROUTE_ACCEPT_QUERIES}
                        >
                          <Queries />
                        </PrivateRoute>
                        <PrivateRoute exact path={pageRoutes.PAGE_SHIFTS}>
                          <Shifts />
                        </PrivateRoute>
                        <PrivateRoute
                          exact
                          path={pageRoutes.PAGE_ROUTE_EDIT_PROFILE}
                        >
                          <EditProfile />
                        </PrivateRoute>
                        <PrivateRoute path={pageRoutes.PAGE_ROUTE_RESIGN}>
                          <Resign />
                        </PrivateRoute>
                        <PrivateRoute path={pageRoutes.PAGE_ROUTE_DASHBOARD}>
                          <Dashboard />
                        </PrivateRoute>
                        <PrivateRoute
                          path={pageRoutes.PAGE_ROUTE_LEAVES_ATTENDANCE}
                        >
                          <LeavesAttendanceHR />
                        </PrivateRoute>
                        <PrivateRoute
                          path={pageRoutes.PAGE_ROUTE_COMMUNICATION}
                        >
                          <ListMessages />
                        </PrivateRoute>
                        <PrivateRoute
                          path={pageRoutes.PAGE_ROUTE_SHIFT_SCHEDULE}
                        >
                          <ShiftSchedule />
                        </PrivateRoute>
                        <PrivateRoute
                          path={pageRoutes.PAGE_ROUTE_QUERIES_ALL_TICKET}
                        >
                          <QueriesAllTicket />
                        </PrivateRoute>
                        <PrivateRoute path={pageRoutes.PAGE_ROUTE_DOCUMENT}>
                          <Documents />
                        </PrivateRoute>
                        <PrivateRoute
                          path={pageRoutes.PAGE_ROUTE_EMPLOYASSETMANAGEMENT}
                        >
                          <AssetMangement />
                        </PrivateRoute>
                        <PrivateRoute
                          path={pageRoutes.PAGE_ROUTE_EMPLOYPOSITION}
                        >
                          <EmployPositionContainer />
                        </PrivateRoute>

                        <PrivateRoute path={pageRoutes.PAGE_ROUTE_RESIGNATION}>
                          <ResignationHR />
                        </PrivateRoute>

                        {/* Asset Management */}
                        <PrivateRoute path={pageRoutes.PAGE_ROUTE_ASSETS}>
                          <Asset />
                        </PrivateRoute>
                        <PrivateRoute path={pageRoutes.PAGE_ROUTE_All_ASSETS}>
                          <AllAsset />
                        </PrivateRoute>
                        <PrivateRoute
                          path={pageRoutes.PAGE_ROUTE_All_ASSETS_DETAILS}
                        >
                          <AllAssetDetailsContainer />
                        </PrivateRoute>
                        <PrivateRoute path={pageRoutes.PAGE_ROUTE_ASSET_ASSIGN}>
                          <AssetAssignContainer />
                        </PrivateRoute>
                        <PrivateRoute
                          path={pageRoutes.PAGE_ROUTE_ASSET_ASSIGN_DETAILS}
                        >
                          <AssetAssignDetailsContainer />
                        </PrivateRoute>
                        <PrivateRoute
                          path={pageRoutes.PAGE_ROUTE_ASSET_ADD_CATEGORY}
                        >
                          <AddCategoryContainer />
                        </PrivateRoute>
                        <PrivateRoute
                          path={pageRoutes.PAGE_ROUTE_ASSET_ADD_SUB_CATEGORY}
                        >
                          <AddSubCategoryContainer />
                        </PrivateRoute>
                        <PrivateRoute
                          path={pageRoutes.PAGE_ROUTE_ASSET_ADD_ASSET_CONDITION}
                        >
                          <AddConditionContainer />
                        </PrivateRoute>
                        {/* Asset Management */}

                        <PrivateRoute
                          path={pageRoutes.PAGE_ROUTE_SHIFT_MNGMT_DASHBOARD}
                        >
                          <SDashboard />
                        </PrivateRoute>
                        <PrivateRoute
                          path={
                            pageRoutes.PAGE_ROUTE_SHIFT_MNGMT_EMPLOYEE_TIMESHEET
                          }
                          exact
                        >
                          <EmployeeTimesheet />
                        </PrivateRoute>
                        <PrivateRoute
                          path={pageRoutes.PAGE_ROUTE_SHIFT_MNGMT_TIMESHEETS}
                        >
                          <STimesheets />
                        </PrivateRoute>
                        <PrivateRoute
                          path={pageRoutes.PAGE_ROUTE_SHIFT_MNGMT_ALLEMPLOYEES}
                        >
                          <SAllEmployees />
                        </PrivateRoute>
                        <PrivateRoute
                          path={pageRoutes.PAGE_ROUTE_SHIFT_MNGMT_ALLPROJECTS}
                        >
                          <SAllProjects />
                        </PrivateRoute>
                        <PrivateRoute
                          path={pageRoutes.PAGE_ROUTE_SHIFT_MNGMT_POSITIONS}
                        >
                          <SPositions />
                        </PrivateRoute>
                        <PrivateRoute
                          path={pageRoutes.PAGE_ROUTE_SHIFT_MNGMT_SCHEDULER}
                        >
                          <SScheduler />
                        </PrivateRoute>
                        <PrivateRoute
                          path={pageRoutes.PAGE_ROUTE_SHIFT_MNGMT_TAGS}
                        >
                          <STags />
                        </PrivateRoute>
                        <PrivateRoute
                          path={
                            pageRoutes.PAGE_ROUTE_SHIFT_MNGMT_SHIFTTEMPLATES
                          }
                        >
                          <SShiftTemplates />
                        </PrivateRoute>
                        <PrivateRoute
                          path={pageRoutes.PAGE_ROUTE_SHIFT_MNGMT_SHIFTTASKS}
                        >
                          <SShiftTasks />
                        </PrivateRoute>
                        {/* <Route path={"/leaves"} component={LeaveNAttendance} /> */}

                        {/* <Route
                      exact
                      path={pageRoutes.PAGE_ROUTE_HOME}
                      component={Home}
                    /> */}
                        {/* <Route path={pageRoutes.PAGE_ROUTE_TNCS} component={Tnc} /> */}
                        <Route
                          path={pageRoutes.PAGE_ROUTE_LOGIN}
                          component={Login}
                        />
                        <Route
                          path={pageRoutes.PAGE_RESET_PASSWORD}
                          component={ResetPassword}
                        />
                        <Route
                          path={pageRoutes.PAGE_FORGOT_PASSWORD}
                          component={ForgotPassword}
                        />
                      </Switch>
                    </CSSTransition>
                  </TransitionGroup>
                )}
              />
            </div>
          </TimesheetProvider>
        </AuthProvider>
      </AppProvider>
    </QueryClientProvider>
  );
}

export default App;
