import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllAssetGroupByUser } from "store/actions/AssetManagement/allAssets";
import { getAllAssetCategory } from "store/actions/AssetManagement/assetCategory";
import Select from "react-select";

const TopFilter = ({ sidebarFilterHandler }) => {
  const [inputSearchBar, setInputSearchBar] = useState("");
  const [categoryList, setCatgoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const dispatch = useDispatch();
  const { assetCategory } = useSelector((state) => state);

  useEffect(() => {
    dispatch(getAllAssetCategory());
  }, []);

  useEffect(() => {
    if (assetCategory?.allAssetCategory?.fulfilled) {
      setCatgoryList(assetCategory.allAssetCategory?.fulfilled);
    }
  }, [assetCategory.allAssetCategory]);

  const onChangeCategory = (obj) => {
    let params = {};
    params.search = inputSearchBar != "" ? inputSearchBar : undefined;
    params.category = obj?._id;
    dispatch(getAllAssetGroupByUser(params));
    setSelectedCategory(obj);
  };

  const onHandleSearch = (value) => {
    setInputSearchBar(value);
    let params = {};
    params.search = value;
    params.category = selectedCategory?.value;
    dispatch(getAllAssetGroupByUser(params));
  };

  return (
    <div className="flex gap-2 flex-wrap justify-between">
      <div className="flex gap-2 flex-wrap">
        <input
          type="text"
          placeholder="Search..."
          className="bg-gray-100 border-2 rounded outline-0 px-2 py-1 sm:w-72 w-full font-semibold"
          onChange={(e) => onHandleSearch(e.target.value)}
          value={inputSearchBar}
        />
        <Select
          placeholder="Select Category"
          className="bg-gray-100  rounded outline-0  sm:w-72 w-full font-semibold"
          classNamePrefix="select"
          value={selectedCategory}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option._id}
          options={categoryList}
          onChange={(obj) => onChangeCategory(obj)}
          isClearable={true}
        />
      </div>
      <div></div>
    </div>
  );
};

export default TopFilter;
