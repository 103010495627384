import React, { useState, useEffect } from "react";
import {
  Avatar,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Button,
  TextField,
  Divider,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
  InputLabel,
  IconButton,
  Grid,
  Snackbar,
  StepButton,
  Checkbox,
} from "@material-ui/core";
import moment from "moment";
import MuiAlert from "@material-ui/lab/Alert";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import FolderIcon from "@material-ui/icons/Folder";
import DeleteIcon from "@material-ui/icons/Delete";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import axios from "axios";
import useAuth from "hooks/useAuth";
import { withStyles } from "@material-ui/styles";
import PhoneInput from "react-phone-input-labelled";
import "react-phone-input-labelled/dist/style.css";
import { makeStyles } from "@material-ui/core";
import Api from "../../../hooks/AjaxAction";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { updateUsers } from '../../../features/EmployeeManagement/Dashboard/DashboardSlice';
import { useDispatch, useSelector } from 'react-redux';

//  form element styling --------------------------
const formStyles = makeStyles((theme) => ({
  sectionHeading: {
    color: "#1F299C",
    fontWeight: "700",
    margin: "10px",
    marginBottom: "10px",
  },

  fieldContainer: {
    maxWidth: "250px",
    display: "inline-block",
    marginRight: "20px",
    // flexDirection: 'column'
  },

  subtitle: {
    marginLeft: "10px",
    color: "black",
    fontWeight: "600",
  },
}));

// -----------------------------------------------------
function getSteps() {
  return [
    "Basic Information",
    "Employee Details",
    "Education Details",
    "Bank Details",
    "Documents",
    "Organisation",
  ];
}

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "black",
    },
    "& .Mui-disabled": {
      color: "black",
    },

    "& .MuiInputBase": {
      background: "#F7F8FC",
      backgroundColor: "#F7F8FC",
    },
    // backgroundColor: '#F7F8FC',
    margin: "10px",
    width: "250px",
  },
})(TextField);

function FormStepperNew(props) {
  const auth = useAuth();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const [formError, setFormError] = useState(null);
  const [formSuccess, setFormSuccess] = useState(null);
  const currentDay = moment().format("YYYY-MM-DD");
  const classes = formStyles();

  //   Personal
  const [fname, setFname] = useState("");
  const [fnameError, setFnameError] = useState(false);
  const [lname, setLname] = useState("");
  const [lnameError, setLnameError] = useState(false);
  const [dob, setDob] = useState("");
  const [dobError, setDobError] = useState(null);
  const [gender, setGender] = useState("");
  const [genderError, setGenderError] = useState(null);
  const [bloodGroup, setBloodGroup] = useState("");
  // const [bloodGroupError, setBloodGroupError] = useState(null);
  const [bio, setBio] = useState("");
  // const [bioError, setBioError] = useState("");
  //   Family
  // const [father, setFather] = useState("");
  // const [fatherError, setFatherError] = useState(null);
  // const [mother, setMother] = useState("");
  // const [motherError, setMotherError] = useState(null);
  // const [siblings, setSiblings] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [currentAddress, setCurrentAddress] = useState("");
  const [permanentAddress, setPermanentAddress] = useState("");
  const [mailingAddress, setMailingAddress] = useState("");

  // Personal
  const [empId, setEmpId] = useState("");
  const [empIdError, setEmpIdError] = useState(null);
  const [empPosition, setEmpPosition] = useState("");
  const [empPositionError, setEmpPositionError] = useState(null);
  const [role, setRole] = useState("");
  const [roleError, setRoleError] = useState(null);
  const [joiningDate, setJoiningDate] = useState("");
  const [joiningDateError, setJoiningDateEror] = useState(null);
  const [email, setEmail] = useState("");
  const [officialEmail, setOfficialEmail] = useState("");

  const [emailError, setEmailError] = useState(null);
  const [officialEmailError, setOfficialEmailError] = useState(null);
  const [drivingLicence, setDrivingLicence] = useState("");
  // const [drivingLicenceError, setDrivingLicenceError] = useState(null);
  const [contact, setContact] = useState("");
  const [contactOfficial, setContactOfficial] = useState("");
  const [contactAlternate, setContactAlternate] = useState("");
  const [contactError, setContactError] = useState(null);
  const [emergencyContactError, setEmergencyContactError] = useState(null);
  const [contactOfficialErr, setContactOfficialErr] = useState(null);
  const [contactAltErr, setContactAltErr] = useState(null);
  // Medical
  const [healthIssues, setHealthIssues] = useState("");
  const [healthCard, setHealthCard] = useState("");
  const [provinceOfIssue, setProvinceOfIssue] = useState("");
  // Emergency
  const [emergencyContactName, setEmergencyContactName] = useState("");
  const [emergencyContactRelation, setEmergencyContactRelation] = useState("");
  const [emergencyContact, setEmergencyContact] = useState("");
  const [emergencyContactEmail, setEmergencyContactEmail] = useState("");
  const [emergencyContactEmailError, setEmergencyContactEmailError] =
    useState(null);

  // Graduation
  const [degree, setDegree] = useState([]);
  const [certification, setCertification] = useState([]);
  const [degreeErr, setDegreeErr] = useState([
    { degree_name: null, start_year: null, end_year: null },
  ]);
  const [certificationErr, setCertificationErr] = useState([
    {
      certificte_name: null,
      issusing_orgnization: null,
      issuing_date: null,
      expiration_date: null,
      does_expire: null,
    },
  ]);

  // Account Details
  // const [accountHolderName, setAccountHolderName] = useState("");
  // const [accHolderNameError, setAccHolderNameError] = useState(null);
  const [sinNo, setSinNo] = useState("");
  const [sinNoError, setSinNoError] = useState(null);
  const [accountNo, setAccountNo] = useState("");
  const [bankName, setBankName] = useState("");
  const [institutionNo, setInstitutionNo] = useState("");
  const [transitNo, setTransitNo] = useState("");
  // const [accountNoError, setAccountNoError] = useState(null);
  // const [ifscCode, setIfscCode] = useState("");
  // const [ifscCodeError, setIfscCodeError] = useState(null);
  // const [accountType, setAccountType] = useState("");
  // const [accountTypeError, setAccountTypeError] = useState(null);
  // L1 Manager Details
  const [l1Name, setL1Name] = useState("");
  const [l1Email, setL1Email] = useState("");
  const [l1EmailError, setL1EmailError] = useState(null);
  const [l1Contact, setL1Contact] = useState("");
  const [l1ContactError, setL1ContactError] = useState(null);
  // HR Manager Details
  const [hrName, setHrName] = useState("");
  const [hrEmail, setHrEmail] = useState("");
  const [hrEmailError, setHrEmailError] = useState(null);
  const [hrContact, setHrContact] = useState("");
  const [hrContactError, setHrContactError] = useState(null);
  // Suoervisor details
  const [supervisorName, setSupervisorName] = useState("");
  const [supervisorEmail, setSupervisorEmail] = useState("");
  const [supervisorEmailError, setSupervisorEmailError] = useState(null);
  const [supervisorContact, setSupervisorContact] = useState("");
  const [superviserContactError, setSupervisorContactError] = useState(null);
  const [isEditOperation, setEditOperation] = useState(false);
  let validationError = false;

  function isObjEmpty(obj) {
    let isEmpty = true;
    // Object.keys(obj).map((key)=>{
    //   if(obj[key])
    // })
    for (const key of Object.keys(obj)) {
      if (obj[key]) {
        console.log(obj[key], "data");
        isEmpty = false;
        break;
      }
    }
    return isEmpty;
  }

  const handleNext = async (progress = true, editVerify = false) => {
    const postal_code_err = "6 digits number is required";
    if (
      props.operation === "add" ||
      (props.operation === "edit" && editVerify)
    ) {
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (activeStep === 0) {
        let dateOfBirth = dob.split("-");
        let dateOfYear = parseInt(dateOfBirth[0]);
        let year = new Date().getFullYear();
        year = parseInt(year);
        let min = year - 18,
          max = year - 80;

        if (dateOfYear < max || dateOfYear > min) {
          setDobError("Inavlid date");
          validationError = true;
        }

        if (!fname) {
          setFnameError("Value Required");
          validationError = true;
        }
        if (!lname) {
          setLnameError("Value Required");
          validationError = true;
        }
        if (!dob) {
          setDobError("Value Required");
          validationError = true;
        }
        if (!gender) {
          setGenderError("Value Required");
          validationError = true;
        }
        if (
          !isObjEmpty(mailing_addr) &&
          mailing_addr.postal_code.length !== 6
        ) {
          validationError = true;
          setPostalCodeMailingErr(postal_code_err);
        }
        if (
          !isObjEmpty(current_addr) &&
          current_addr.postal_code.length !== 6
        ) {
          validationError = true;
          setPostalCodeCurrentErr(postal_code_err);
        }
        if (
          !isObjEmpty(permanent_addr) &&
          permanent_addr.postal_code.length !== 6
        ) {
          validationError = true;
          setPostalCodePermanentErr(postal_code_err);
        }
        // if (!bloodGroup) {
        //   setBloodGroupError("Value Required");
        //   validationError = true;
        // }
        // if (!bio) {
        //   setBioError("Value Required");
        //   validationError = true;
        // }
        // if (!father) {
        //   setFatherError("Value Required");
        //   validationError = false;
        // }
        // if (!mother) {
        //   setMotherError("Value Required");
        //   validationError = true;
        // }
        if (validationError) {
          setFormError("Values Required");
          return true;
        } else {
          setFnameError(false);
          setLnameError(false);
          setDobError(false);
          setGenderError(false);
          setPostalCodeMailingErr(false);
          setPostalCodeCurrentErr(false);
          setPostalCodePermanentErr(false);
          // setBioError(false);
          // setFatherError(false);
          // setMotherError(false);
          setFormError(false);
        }
      } else if (activeStep === 1) {
        if (!email) {
          setEmailError("Value Required");
          setFormError("Value Required");
        } else {
          if (props.email !== email) {
            try {
              const resp = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/users/check/email/${email}`
              );
              // console.log("Response >>", resp);
              if (resp.status === 200) {
                if (resp.data.status) {
                  // No Coflict
                  setEmailError(false);
                  setFormError(false);
                }
              }
            } catch (e) {
              if (e.response) {
                console.log("Response >>", e.response);
                if (e.response.status === 409) {
                  // Email Conflict is there
                  setEmailError("Email already exists");
                }
              }
            }
          }
        }
        if (officialEmail) {
          if (!emailRegex.test(String(officialEmail).toLowerCase())) {
            setOfficialEmailError("Invalid Email Address");
            validationError = true;
          } else if (props.official_email !== officialEmail) {
            try {
              const resp = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/users/check/email/${officialEmail}`
              );

              if (resp.status === 200) {
                if (resp.data.status) {
                  // No Coflict
                  setOfficialEmailError(false);
                  setFormError(false);
                }
              }
            } catch (e) {
              if (e.response) {
                if (e.response.status === 409) {
                  // Email Conflict is there
                  setOfficialEmailError("Email already exists");
                }
              }
            }
          }
        }

        if (!role) {
          setRoleError("Value Required");
          validationError = true;
        }
        // if (!drivingLicence) {
        //   setDrivingLicenceError("Value Required");
        //   validationError = true;
        // }
        if (!emailRegex.test(String(email).toLowerCase())) {
          setEmailError("Invalid Email Address");
          validationError = true;
        }

        // if (!empId) {
        //  setEmpIdError("Value Required");
        //  validationError = true;
        // }
        if (!empPosition) {
          setEmpPositionError("Value Required");
          validationError = true;
        }
        if (!joiningDate) {
          setJoiningDateEror("Value Required");
          validationError = true;
        }
        if (!sinNo) {
          validationError = true;
          setSinNoError("Value Required.");
        }
        if (sinNo && sinNo < 100000000) {
          validationError = true;
          setSinNoError("9 digits number required.");
        }
        if (!contact) {
          setContactError("Value Required");
          validationError = true;
        }
        if (contact.length !== 17) {
          validationError = true;
          setContactError("10 digits number is required.");
          console.log("hi1111");
        }

        // if (emergencyContact.length !== 17) {
        //   validationError = true;
        //   setEmergencyContactError("10 digits number is required.")
        //   console.log(emergencyContact.length)
        // }

        if (emergencyContact && emergencyContact.length !== 17) {
          if (emergencyContact.length > 2) {
            validationError = true;
            setEmergencyContactError("10 digits number is required.");
          } else {
            setEmergencyContact(null);
            setEmergencyContactError("");
          }
        }

        if (contactOfficial && contactOfficial.length < 16) {
          if (contactOfficial.length > 2) {
            validationError = true;
            setContactOfficialErr("10 digits number is required.");
          } else {
            setContactOfficial(null);
            setContactOfficialErr("");
          }
        }
        if (contactAlternate && contactAlternate.length !== 17) {
          if (contactAlternate.length > 2) {
            validationError = true;
            setContactAltErr("10 digits number is required.");
          } else {
            setContactAlternate(null);
            setContactAltErr("");
          }
        }
        if (emergencyContactEmail) {
          if (!emailRegex.test(String(emergencyContactEmail).toLowerCase())) {
            setEmergencyContactEmailError("Invalid Email Address");
            validationError = true;
          }
        }
        if (validationError) {
          setFormError("Values Required");
          return true;
        } else {
          setEmpIdError(false);
          setEmpPositionError(false);
          setRoleError(false);
          setJoiningDateEror(false);
          // setDrivingLicenceError(false);
          setEmergencyContactEmailError(false);
          setContactError(false);
          setFormError(false);
        }
      } else if (activeStep === 2) {
        validationError = false;
        let j = 0;
        if (degree.length) {
          degree.map((d, i) => {
            var err = [...degreeErr];
            if (!d.degree_name || !d.degree_name.length) {
              validationError = true;
              err[i] = { ...err[i], degree_name: "Value Required" };
            }
            if (!d.end_year) {
              validationError = true;

              err[i] = { ...err[i], end_year: "Value Required" };
            }
            if (!d.start_year) {
              validationError = true;
              err[i] = { ...err[i], start_year: "Value Required" };
            }

            if (d.start_year && d.end_year) {
              let start = parseInt(d.start_year);
              let end = parseInt(d.end_year);
              if (start > end) {
                validationError = true;

                err[i] = {
                  ...err[i],
                  start_year: "Start year must be smaller than End year",
                };
                err[i] = {
                  ...err[i],
                  end_year: "End year must be greater than Start year",
                };
              }
            }
            setDegreeErr([...err]);
          });

          if (certification.length) {
            certification.map((d, i) => {
              var err = [...certificationErr];
              if (!d.certificte_name || !d.certificte_name.length) {
                validationError = true;
                err[i] = { ...err[i], certificte_name: "Value Required" };
              }
              if (!d.issuing_date) {
                validationError = true;
                err[i] = { ...err[i], issuing_date: "Value Required" };
              }
              if (!d.does_expire && !d.expiration_date) {
                validationError = true;
                err[i] = { ...err[i], expiration_date: "Value Required" };
              }
              if (!d.issusing_orgnization || !d.issusing_orgnization.length) {
                validationError = true;
                err[i] = { ...err[i], issusing_orgnization: "Value Required" };
              }

              if (d.issuing_date && d.expiration_date) {
                let start = parseInt(d.issuing_date);
                let end = parseInt(d.expiration_date);
                if (start > end) {
                  validationError = true;
                  err[i] = {
                    ...err[i],
                    expiration_date: "Must be greater than Issuing date",
                  };
                }
              }
              setCertificationErr([...err]);
            });

            if (validationError) {
              setFormError("Values Required");
              return true;
            } else {
              validationError = false;
              var de = [...certificationErr];
              certificationErr.map((d, i) => {
                de[i] = {
                  certificte_name: null,
                  issusing_orgnization: null,
                  issuing_date: null,
                  expiration_date: null,
                };
              });
              setCertificationErr([...de]);
              var de = [...degreeErr];
              degreeErr.map((d, i) => {
                de[i] = {
                  degree_name: null,
                  end_year: null,
                  start_year: null,
                };
              });
              setDegreeErr([...de]);
            }
          }
        } else {
          validationError = false;
          var de = [...certificationErr];
          certificationErr.map((d, i) => {
            de[i] = {
              certificte_name: null,
              issusing_orgnization: null,
              issuing_date: null,
              expiration_date: null,
            };
          });
          setCertificationErr([...de]);
          var de = [...degreeErr];
          degreeErr.map((d, i) => {
            de[i] = {
              degree_name: null,
              end_year: null,
              start_year: null,
            };
          });
          setDegreeErr([...de]);
        }
      }
      // else if (activeStep === 3) {
      //     if (!accountHolderName) {
      //       setAccHolderNameError("Value Required");
      //       validationError = true;
      //     }
      //     if (!sinNo) {
      //       setSinNoError("Value Required");
      //       validationError = true;
      //     }
      //     if (!accountNo) {
      //       setAccountNoError("Value Required");
      //       validationError = false;
      //     }
      //     if (!ifscCode) {
      //       setIfscCodeError("Value Required");
      //       validationError = true;
      //     }
      //     if (!accountType) {
      //       setAccountTypeError("Value Required");
      //       validationError = true;
      //     }
      //     if (validationError) {
      //       setFormError("Values Required");
      //       return;
      //     } else {
      //       setAccHolderNameError(false);
      //       setSinNoError(false);
      //       setAccountNoError(false);
      //       setIfscCodeError(false);
      //       setAccountTypeError(false);
      //       setFormError(false);
      //     }
      //   }
      else if (activeStep === 5) {
        if (l1Contact && l1Contact.length !== 17) {
          if (l1Contact.length > 2) {
            validationError = true;
            console.log(l1Contact.length);

            setL1ContactError("10 digits number is required.");
          } else {
            // setL1Contact(null);

            setL1ContactError("");
          }
        }

        if (hrContact && hrContact.length !== 17) {
          if (hrContact.length > 2) {
            validationError = true;
            console.log(hrContact.length);

            setHrContactError("10 digits number is required.");
          } else {
            // setL1Contact(null);

            setHrContactError("");
          }
        }

        if (supervisorContact && supervisorContact.length !== 17) {
          if (supervisorContact.length > 2) {
            validationError = true;
            console.log(supervisorContact.length);

            setSupervisorContactError("10 digits number is required.");
          } else {
            // setL1Contact(null);

            setSupervisorContactError("");
          }
        }

        if (l1Email) {
          if (!emailRegex.test(String(l1Email).toLowerCase())) {
            setL1EmailError("Invalid Email Address");
            validationError = true;
          }
        }
        if (hrEmail) {
          if (!emailRegex.test(String(hrEmail).toLowerCase())) {
            setHrEmailError("Invalid Email Address");
            validationError = true;
          }
        }
        if (supervisorEmail) {
          if (!emailRegex.test(String(hrEmail).toLowerCase())) {
            setSupervisorEmailError("Invalid Email Address");
            validationError = true;
          }
        }
        if (validationError) {
          setFormError("Values Required");
          return true;
        } else {
          setL1EmailError(false);
          setHrEmailError(false);
          setSupervisorEmailError(false);
          setFormError(false);
        }
      }
    }
    if (progress) setActiveStep(activeStep === 5 ? activeStep : activeStep + 1);
    return validationError;
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  // non linear -> for edit profile
  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  useEffect(() => {
    if (props.readOnly || props.operation === "edit") {
      setFname(props.first_name);
      setLname(props.last_name);
      setDob(moment(props.dob).utc().format("YYYY-MM-DD"));
      setProfileImage(
        props.image_url !== ""
          ? [
              {
                url: props.image_url,
                name:
                  props.image_url &&
                  props.image_url.split("/")[
                    props.image_url.split("/").length - 1
                  ],
              },
            ]
          : []
      );
      setGender(props.gender);
      setBloodGroup(props.blood_group);
      setBio(props.about_user);
      setEditOperation(true);
      setPeronalDoc(props.personal_docs);
      setCompanyDoc(props.company_docs);
      setAdditionalDoc(props.additional_docs);
      setOfficialDoc(props.official_docs ? props.official_docs : []);
      if (props.family_details) {
        // setFather(props.family_details.father);
        // setMother(props.family_details.mother);
        // setSiblings(props.family_details.siblings);
        setMaritalStatus(props.family_details.marital_status);
      }
      if (props.residency) {
        setCurrentAddr(props.residency.curr_addr);
        setMailingAddr(props.residency.mailing_addr);
        setPermanentAddr(props.residency.permanent_addr);
      }
      setEmpId(props.emp_code);
      setEmpPosition(props.position);
      setRole(props.role);
      setJoiningDate(moment(props.doj).utc().format("YYYY-MM-DD"));
      setEmail(props.email);
      setOfficialEmail(props.official_email);
      setDrivingLicence(props.driving_licence_no);
      setContact(props.phone);
      setContactAlternate(props.alternate_contact_no);
      setContactOfficial(props.official_contact_no);
      if (props.medical_details) {
        setHealthIssues(props.medical_details.health_issues);
        setHealthCard(props.medical_details.health_id);
        setProvinceOfIssue(props.medical_details.province_of_issue);
      }
      if (props.emergency_contacts) {
        setEmergencyContactName(props.emergency_contacts[0]?.name);
        setEmergencyContactRelation(props.emergency_contacts[0]?.relationship);
        setEmergencyContact(props.emergency_contacts[0]?.mobile_no.mobile);
        setEmergencyContactEmail(props.emergency_contacts[0]?.landline_no);
      }
      if (props.account_details) {
        // setAccountHolderName(props.account_details.account_holder_name);
        setAccountNo(props.account_details.account_no);
        // setAccountType(props.account_details.account_type);
        // setIfscCode(props.account_details.ifsc_code);
        setSinNo(props.account_details.sin);
        setBankName(props.account_details.bank_name);
        setInstitutionNo(props.account_details.institution_no);
        setTransitNo(props.account_details.transit_no);
      }
      if (props.educational_details) {
        if (props.educational_details?.degree_details)
          if (props.educational_details?.degree_details[0]?.degree_name) {
            setDegree(props.educational_details.degree_details);

            setDegreeErr([
              ...props.educational_details.degree_details.map((d) => {
                return { degree_name: null, start_year: null, end_year: null };
              }),
            ]);
          } else setDegree([]);
        if (props.educational_details?.certificates_and_licenses)
          if (
            props.educational_details?.certificates_and_licenses[0]
              ?.certificte_name
          ) {
            setCertification(
              props.educational_details.certificates_and_licenses
            );
            setCertificationErr([
              ...props.educational_details.certificates_and_licenses.map(
                (d) => {
                  return {
                    certificte_name: null,
                    issusing_orgnization: null,
                    issuing_date: null,
                    expiration_date: null,
                  };
                }
              ),
            ]);
          } else setCertification([]);
      }

      if (props.l1_manager_details) {
        setL1Name(props.l1_manager_details.l1_name);
        setL1Contact(props.l1_manager_details.l1_contact);
        setL1Email(props.l1_manager_details.l1_email);
      }
      if (props.hr_manager_details) {
        setHrName(props.hr_manager_details.hr_name);
        setHrContact(props.hr_manager_details.hr_contact);
        setHrEmail(props.hr_manager_details.hr_email);
      }
      if (props.supervisor_details) {
        setSupervisorName(props.supervisor_details.sup_name);
        setSupervisorContact(props.supervisor_details.sup_contact);
        setSupervisorEmail(props.supervisor_details.sup_email);
      }
    } else if (!props.readOnly && props.operation === "add") {
      setFname("");
      setLname("");
      setDob("");
      setGender("");
      setBloodGroup("");
      setBio("");
      // setFather("");
      // setMother("");
      // setSiblings("");
      setMaritalStatus("");
      setCurrentAddress("");
      setPermanentAddress("");
      setMailingAddress("");
      setEmpId("");
      setEmpPosition("");
      setRole("");
      setJoiningDate("");
      setEmail("");
      setDrivingLicence("");
      setContact("");
      setContactAlternate("");
      setContactOfficial("");
      setHealthIssues("");
      setHealthCard("");
      setProvinceOfIssue("");
      setEmergencyContactName("");
      setEmergencyContactRelation("");
      setEmergencyContact("");
      setEmergencyContactEmail("");
      // setAccountHolderName("");
      setAccountNo("");
      // setAccountType("");
      // setIfscCode("");
      setBankName("");
      setInstitutionNo("");
      setTransitNo("");
      setSinNo("");
      setL1Name("");
      setL1Email("");
      setL1Contact("");
      setHrName("");
      setHrEmail("");
      setHrContact("");
      setSupervisorName("");
      setSupervisorContact("");
      setSupervisorEmail("");
      setDegree([]);
      setCertification([]);
    }
  }, [
    props.readOnly,
    props,
    props.about_user,
    props.account_details,
    props.blood_group,
    props.dob,
    props.doj,
    props.driving_licence_no,
    props.email,
    props.emergency_contacts,
    props.emp_code,
    props.emp_position,
    props.about_user,
    props.account_details,
    props.blood_group,
    props.dob,
    props.doj,
    props.driving_licence_no,
    props.email,
    props.emergency_contacts,
    props.emp_code,
    props.family_details,
    props.first_name,
    props.gender,
    props.health_id,
    props.health_issues,
    props.last_name,
    props.medical_details,
    props.operation,
    props.province_of_issue,
    props.role,
    props.supervisor_details,
    props.hr_manager_details,
    props.l1_manager_details,
  ]);

  const getCurrentFormState = () => {
    return {
      first_name: fname,
      last_name: lname,
      gender,
      image_url: ProfileImage.length > 0 ? ProfileImage[0].url : "",
      country_code: "",
      phone: contact,
      official_contact_no: contactOfficial,
      alternate_contact_no: contactAlternate,
      about_user: bio,
      email,
      official_email: officialEmail,
      blood_group: bloodGroup,
      dob: dob, //`${dobSplit[2]}-${dobSplit[1]}-${dobSplit[0]}`,
      doj: joiningDate, //`${dojsplit[2]}-${dojsplit[1]}-${dojsplit[0]}`,
      known_languages: ["English"],
      family_details: {
        // father,
        // mother,
        // siblings,
        marital_status: maritalStatus,
      },
      current_shifts: [],
      company: auth.user.company,
      city: "",
      country: "",
      department: "",
      emp_code: empId,
      emp_position: empPosition,
      org_id: auth.user.org_id._id,
      role,
      licence_no: drivingLicence,
      // designation: "",
      medical_details: {
        health_issues: healthIssues,
        health_id: healthCard,
        province_of_issue: provinceOfIssue,
      },
      residency: {
        curr_addr: current_addr,
        permanent_addr: permanent_addr,
        mailing_addr: mailing_addr,
      },
      emergency_contacts: [
        {
          name: emergencyContactName,
          relationship: emergencyContactRelation,
          mobile_no: {
            country_code: "",
            mobile: emergencyContact,
          },
          landline_no: emergencyContactEmail,
        },
      ],
      personal_docs: PersonalDoc,
      position: empPosition,
      additional_docs: AdditionalDoc,
      company_docs: CompanyDoc,
      official_docs: OfficialDoc,
      educational_details: {
        degree_details: degree,
        certificates_and_licenses: certification,
      },
      account_details: {
        // account_holder_name: accountHolderName,
        sin: sinNo,
        account_no: accountNo,
        // account_type: accountType,
        // ifsc_code: ifscCode
        bank_name: bankName,
        institution_no: institutionNo,
        transit_no: transitNo,
      },
      l1_manager_details: {
        l1_name: l1Name,
        l1_contact: l1Contact,
        l1_email: l1Email,
      },
      hr_manager_details: {
        hr_name: hrName,
        hr_contact: hrContact,
        hr_email: hrEmail,
      },
      supervisor_details: {
        sup_name: supervisorName,
        sup_contact: supervisorContact,
        sup_email: supervisorEmail,
      },
      user: auth.user,
    };
  };

  const handleSaveTabData = async (event) => {
    event.preventDefault();

    const validationError = await handleNext(false, true);
    if (validationError) return;

    var body = getCurrentFormState();
    try {
      let response;
      if (props.operation === "add") {
        // Call Create API
        response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/users/create`,
          body
        );
      } else {
        body._id = props._id;
        dispatch(updateUsers(
          {        
            body
          }
        ))
        .then((data) => {
            const response = data.payload;
            props.updateData();
            // setAllEmployees(response.data);
            console.log("update users response=====================", response);
  
        })
        .catch((error) => {
            console.log(error);
        })
        // Call Update API
        // body._id = props._id;
        // response = await axios.put(
        //   `${process.env.REACT_APP_API_BASE_URL}/users/update`,
        //   body
        // );
        console.log("update response")
      }

      if (response.status === 200) {
        setFormSuccess("Saved");
      }
    } catch (e) {
      if (e.response && e.response.status === 400) {
        setFormError("Some form value missing");
      }
    }
  };

  const handleFormSubmit = async (e) => {
    console.log("hh");
    e.preventDefault();
    handleNext();
    if (validationError) return;

    const body = getCurrentFormState();

    // var datOfBirth = dob || "";
    // var dateOfJoin = joiningDate || "";
    // let dobSplit = datOfBirth.split("-");
    // let dojsplit = dateOfJoin.split("-");

    try {
      let response;
      if (props.operation === "add") {
        // Call Create API
        response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/users/create`,
          body
        );
      } else {
        // Call Update API
        body._id = props._id;
        response = await axios.put(
          `${process.env.REACT_APP_API_BASE_URL}/users/update`,
          body
        );
      }

      if (response.status === 200) {
        setFormSuccess("Saved");
        props.closeForm();
      }
    } catch (e) {
      if (e.response.status === 400) {
        setFormError("Some form values are missing");
      }
    }
  };

  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  const handleFileDelete = (event) => {
    event.preventDefault();
    if (event.target.id === "") {
      return;
    }
    const array = event.target.id.split("_");
    const type = array[0];
    const index = parseInt(array[1]);
    if (type === "ProfileImage") {
      setProfileImage([]);
    } else if (type === "PersonalDoc") {
      const UpdatedPersonalDoc = PersonalDoc.filter(
        (f, indx) => indx !== index
      );
      setPeronalDoc(UpdatedPersonalDoc);
    } else if (type === "AdditionalDoc") {
      const UpdatedAdditionalDoc = AdditionalDoc.filter(
        (f, indx) => indx !== index
      );
      setAdditionalDoc(UpdatedAdditionalDoc);
    } else if (type === "CompanyDoc") {
      const UpdatedAdditionalDoc = CompanyDoc.filter(
        (f, indx) => indx !== index
      );
      setCompanyDoc(UpdatedAdditionalDoc);
    } else if (type === "OfficialDoc") {
      const UpdatedAdditionalDoc = OfficialDoc.filter(
        (f, indx) => indx !== index
      );
      setOfficialDoc(UpdatedAdditionalDoc);
    }
  };
  const terriotories = [
    "Alberta",
    "British Columbia",
    "Manitoba",
    "New Brunswick",
    "Newfoundland and Labrador",
    "Northwest Territories",
    "Nova Scotia",
    "Nunavut",
    "Ontario",
    "Prince Edward Island",
    "Quebec",
    "Saskatchewan",
    "Yukon",
  ];

  const getDocumentList = (data, type = "") => {
    return (
      data &&
      data.map((item, key) => {
        return (
          <ListItem key={key}>
            <ListItemAvatar>
              <Avatar>
                <FolderIcon style={{ color: "#7c8bc4" }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              style={{ color: "#5A5A5A", wordBreak: "break-all" }}
              primary={item.name}
            />
            <ListItemSecondaryAction>
              <IconButton
                edge='end'
                aria-label='delete'
                disabled={props.readOnly}
                style={{ color: "red" }}
              >
                <DeleteIcon
                  id={type + "_" + key}
                  onClick={(e) => handleFileDelete(e)}
                />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })
    );
  };

  const setEducationDetails = (data, field = null, idx) => {
    let degreesErr = [...degreeErr];
    let err = false;
    degreesErr[idx][field] = null;
    setDegreeErr([...degreesErr]);
    let date = parseInt(data);
    if (field === "start_year" || field === "end_year") {
      if (data.length > 4) {
        err = true;
      }
      if (date < 1960 || date > 2100) {
        err = true;
      }
    }

    if (err) {
      let degreesErr = [...degreeErr];
      degreesErr[idx][field] = "Enter a valid date";
      setDegreeErr([...degreesErr]);
    }

    let degrees = [...degree];
    degrees[idx][field] = data;
    setDegree([...degrees]);
  };

  const handleDeleteDegree = (i) => {
    let degrees = [...degree];
    setDegree([...degrees.filter((d, idx) => idx !== i)]);
    let degreesErr = [...degreeErr];
    setDegreeErr([...degreesErr.filter((d, idx) => idx !== i)]);
  };

  const handleAddDegree = () => {
    let degrees = [...degree];
    let year = new Date().getFullYear();
    degrees.push({
      degree_name: "",
      start_year: year,
      end_year: year,
      remarks: "",
    });
    setDegree([...degrees]);
    let degreesErr = [...degreeErr];
    degreesErr.push({ degree_name: null, start_year: null, end_year: null });
    setDegreeErr([...degreesErr]);
  };
  const degreeDetails = (i) => {
    return (
      <>
        <div className={classes.fieldContainer}>
          <InputLabel id='grad_institute' className={classes.subtitle}>
            <Typography variant='subtitle2'>Degree</Typography>
          </InputLabel>
          <CssTextField
            placeholder='Enter degree'
            name='grad_institute'
            labelId='grad_institute'
            variant='outlined'
            disabled={props.readOnly}
            value={degree[i].degree_name}
            onChange={
              (e) => setEducationDetails(e.target.value, "degree_name", i)
              // setDegreeErr([degreeErr, ])
            }
            helperText={degreeErr[i].degree_name || ""}
            error={!!degreeErr[i].degree_name}
          />
        </div>
        <div className={classes.fieldContainer}>
          <InputLabel id='grad_begin' className={classes.subtitle}>
            <Typography variant='subtitle2'>Start Year</Typography>
          </InputLabel>
          <CssTextField
            // helperText="Begin Date"
            name='grad_begin'
            labelId='grad_begin'
            type='number'
            min='1960'
            max={new Date().getFullYear()}
            step='1'
            variant='outlined'
            disabled={props.readOnly}
            value={degree[i].start_year}
            onChange={(e) => {
              setEducationDetails(e.target.value, "start_year", i);
              let date = e.target.value;
              let dateYear = parseInt(date);
              let year = new Date().getFullYear();
              if (
                date.length > 4 ||
                (date.length === 4 && (dateYear < 1960 || dateYear > year))
              )
                return;
            }}
            helperText={degreeErr[i].start_year || ""}
            error={!!degreeErr[i].start_year}
          />
        </div>
        <div className={classes.fieldContainer}>
          <InputLabel id='grad_completion' className={classes.subtitle}>
            <Typography variant='subtitle2'>End Year</Typography>
          </InputLabel>

          <CssTextField
            // helperText="Completion Date"
            name='grad_completion'
            labelId='grad_completion'
            type='number'
            min={degree[i].start_year}
            max='2099'
            step='1'
            variant='outlined'
            disabled={props.readOnly}
            value={degree[i].end_year}
            onChange={(e) => {
              setEducationDetails(e.target.value, "end_year", i);
              let date = e.target.value;
              let dateYear = parseInt(date);
              if (
                date.length > 4 ||
                (date.length === 4 &&
                  (dateYear < 1960 || dateYear > degree[i].start_year))
              ) {
                return;
              }
            }}
            helperText={degreeErr[i].end_year || ""}
            error={!!degreeErr[i].end_year}
          />
        </div>
        <div className={classes.fieldContainer}>
          <InputLabel id='grad_institute' className={classes.subtitle}>
            <Typography variant='subtitle2'>Remarks</Typography>
          </InputLabel>
          <CssTextField
            placeholder='Enter institution name'
            name='grad_institute'
            labelId='grad_institute'
            variant='outlined'
            disabled={props.readOnly}
            value={degree[i].remarks}
            onChange={(e) => setEducationDetails(e.target.value, "remarks", i)}
          />
        </div>
      </>
    );
  };

  const handleAddCertificate = () => {
    let certificates = [...certification];
    certificates.push({
      certificte_name: "",
      issusing_orgnization: "",
      issuing_date: moment().format("YYYY-MM-DD"),
      expiration_date: moment().format("YYYY-MM-DD"),
      credential_id: "",
      credential_url: "",
      does_expire: false,
    });
    setCertification([...certificates]);
    let certificateErr = [...certificationErr];
    certificateErr.push({
      certificte_name: null,
      issusing_orgnization: null,
      issuing_date: null,
      expiration_date: null,
    });
    setCertificationErr([...certificateErr]);
  };

  const setCertificateDetails = (data, field = null, idx) => {
    let certificteErr = [...certificationErr];
    let err = false;
    certificteErr[idx][field] = null;
    setCertificationErr([...certificteErr]);
    let errText = "Enter a valid date";

    if (field === "issuing_date" || field === "expiration_date") {
      let date = data.split("-");
      if (parseInt(date[0]) < 1960 || parseInt(date[0]) > 2100) {
        err = true;
      }
    }
    if (field === "issuing_date") {
      let date = moment(data).format("YYYY-MM-DD");
      let currentDate = moment().format("YYYY-MM-DD");
      if (date > currentDate) {
        err = true;
        errText = "Can't select a date in the future";
      }
    }

    if (field === "does_expire" && data === true) {
      let certificates = [...certification];
      delete certificates[idx].expiration_date;
      setCertification([...certificates]);
      let certificateErr = [...certificationErr];
      delete certificateErr[idx].expiration_date;
      setCertificationErr([...certificateErr]);
    }

    if (err) {
      let certificateErr = [...certificationErr];
      certificateErr[idx][field] = errText;
      setCertificationErr([...certificateErr]);
    }

    let certificates = [...certification];
    certificates[idx][field] = data;
    setCertification([...certificates]);
  };

  const handleDeleteCertificate = (i) => {
    let certificates = [...certification];
    setCertification([...certificates.filter((d, idx) => idx !== i)]);
    let certificatesErr = [...certificationErr];
    setCertificationErr([...certificatesErr.filter((d, idx) => idx !== i)]);
  };

  const getCertificates = (idx) => {
    console.log(certification[idx]);
    return (
      <>
        <div className={classes.fieldContainer}>
          <InputLabel id='pg_begin' className={classes.subtitle}>
            <Typography variant='subtitle2'>Name</Typography>
          </InputLabel>
          <CssTextField
            placeholder='Enter institution name'
            name='pg_institute'
            labelId='pg_institute'
            variant='outlined'
            disabled={props.readOnly}
            value={certification[idx].certificte_name}
            onChange={(e) =>
              setCertificateDetails(e.target.value, "certificte_name", idx)
            }
            helperText={certificationErr[idx].certificte_name || ""}
            error={!!certificationErr[idx].certificte_name}
          />
        </div>
        <div className={classes.fieldContainer}>
          <InputLabel id='pg_begin' className={classes.subtitle}>
            <Typography variant='subtitle2'>Issuing Organization</Typography>
          </InputLabel>
          <CssTextField
            placeholder='Enter issuing organization'
            name='pg_institute'
            labelId='pg_institute'
            variant='outlined'
            disabled={props.readOnly}
            value={certification[idx].issusing_orgnization}
            onChange={(e) =>
              setCertificateDetails(e.target.value, "issusing_orgnization", idx)
            }
            helperText={certificationErr[idx].issusing_orgnization || ""}
            error={!!certificationErr[idx].issusing_orgnization}
          />
        </div>
        <div>
          <input
            style={{
              height: "15px",
              width: "15px",
              margin: "10px",
              marginBottom: "15px",
            }}
            type='checkbox'
            id='expire'
            name='expire'
            checked={certification[idx].does_expire}
            onChange={(e) => {
              setCertificateDetails(
                !certification[idx].does_expire,
                "does_expire",
                idx
              );
            }}
          />
          <label for='expire'>This credential doesn’t expire</label>
        </div>
        <div className={classes.fieldContainer}>
          <InputLabel id='pg_completion' className={classes.subtitle}>
            <Typography variant='subtitle2'>Issue date</Typography>
          </InputLabel>
          <CssTextField
            // helperText="Completion Date"
            name='pg_completion'
            labelId='pg_completion'
            variant='outlined'
            type='date'
            min='1960-01-01'
            max={currentDay}
            disabled={props.readOnly}
            value={certification[idx].issuing_date}
            onChange={(e) => {
              setCertificateDetails(e.target.value, "issuing_date", idx);
            }}
            helperText={certificationErr[idx].issuing_date || ""}
            error={!!certificationErr[idx].issuing_date}
          />
        </div>
        <div className={classes.fieldContainer}>
          <div
            style={{
              visibility: certification[idx].does_expire ? "hidden" : "visible",
            }}
          >
            <InputLabel id='pg_completion' className={classes.subtitle}>
              <Typography variant='subtitle2'>Expiration date</Typography>
            </InputLabel>
            <CssTextField
              // helperText="Completion Date"
              name='pg_completion'
              labelId='pg_completion'
              type='date'
              min={certification[idx].issuing_date}
              max='2100-01-01'
              variant='outlined'
              disabled={props.readOnly}
              value={certification[idx].expiration_date}
              onChange={(e) => {
                setCertificateDetails(e.target.value, "expiration_date", idx);
              }}
              helperText={certificationErr[idx].expiration_date || ""}
              error={!!certificationErr[idx].expiration_date}
            />
          </div>
        </div>
        <div className={classes.fieldContainer}>
          <InputLabel id='pg_institute' className={classes.subtitle}>
            <Typography variant='subtitle2'>Credential ID</Typography>
          </InputLabel>
          <CssTextField
            placeholder='Enter credential ID'
            name='pg_institute'
            labelId='pg_institute'
            variant='outlined'
            disabled={props.readOnly}
            value={certification[idx].credential_id}
            onChange={(e) =>
              setCertificateDetails(e.target.value, "credential_id", idx)
            }
          />
        </div>
        <div className={classes.fieldContainer}>
          <InputLabel id='pg_specialisation' className={classes.subtitle}>
            <Typography variant='subtitle2'>Credential URL</Typography>
          </InputLabel>
          <CssTextField
            placeholder='Enter credential URL'
            name='pg_specialisation'
            labelId='pg_specialisation'
            variant='outlined'
            disabled={props.readOnly}
            value={certification[idx].credential_url}
            onChange={(e) =>
              setCertificateDetails(e.target.value, "credential_url", idx)
            }
          />
        </div>
      </>
    );
  };
  let initialAddr = {
    street_addr: "",
    apt_unit: "",
    city: "",
    province: "",
    postal_code: "",
  };
  const [PersonalDoc, setPeronalDoc] = useState([]);
  const [AdditionalDoc, setAdditionalDoc] = useState([]);
  const [ProfileImage, setProfileImage] = useState([]);
  const [CompanyDoc, setCompanyDoc] = useState([]);
  const [OfficialDoc, setOfficialDoc] = useState([]);
  const [current_addr, setCurrentAddr] = useState(initialAddr);
  const [permanent_addr, setPermanentAddr] = useState(initialAddr);
  const [mailing_addr, setMailingAddr] = useState(initialAddr);
  const [sameAs, setSameAs] = useState("none");
  const [sameAsCurrentAddr, setSameAsCurrentAddr] = useState(false);
  const [postalCodeCurrentErr, setPostalCodeCurrentErr] = useState();
  const [postalCodePermanentErr, setPostalCodePermanentErr] = useState();
  const [postalCodeMailingErr, setPostalCodeMailingErr] = useState();

  function setAddr(e, addr_type) {
    e.preventDefault();
    const { name, value } = e.target;
    if (name === "postal_code" && value.length > 6) return;
    if (name === "sameAs") {
      switch (value) {
        case "permanent":
          setMailingAddr(permanent_addr);
          setSameAs(value);
          break;

        case "current":
          setMailingAddr(current_addr);
          setSameAs(value);
          break;

        default:
          setMailingAddr({ ...initialAddr });
          setSameAs(value);
      }
      return;
    }
    if (name === "sameAsCurrent") {
      if (!sameAsCurrentAddr) {
        setPermanentAddr({ ...permanent_addr, ...current_addr });
        setSameAsCurrentAddr(!sameAsCurrentAddr);
      } else {
        setPermanentAddr(initialAddr);
        setSameAsCurrentAddr(!sameAsCurrentAddr);
      }
      return;
    }
    if (addr_type === "current_addr") {
      setCurrentAddr({ ...current_addr, [e.target.name]: e.target.value });
    }
    if (addr_type === "permanent_addr") {
      setPermanentAddr({ ...permanent_addr, [e.target.name]: e.target.value });
    }
    if (addr_type === "mailing_addr") {
      setMailingAddr({ ...mailing_addr, [e.target.name]: e.target.value });
    }
  }

  const renderCurrentAddr = (
    <Grid container>
      <Typography variant='h5' className={classes.sectionHeading}>
        Current Address
      </Typography>
      <Grid container>
        <Grid item xs={6}>
          <InputLabel id='currStreetAddr' className={classes.subtitle}>
            <Typography variant='subtitle2'>Street Address</Typography>
          </InputLabel>
          <CssTextField
            placeholder='Street Address'
            labelid='currStreetAddr'
            name='street_addr'
            variant='outlined'
            margin='normal'
            disabled={props.readOnly}
            value={current_addr.street_addr}
            onChange={(e) => setAddr(e, "current_addr")}
          />
        </Grid>

        <Grid item xs={6}>
          <InputLabel id='currAptUnit' className={classes.subtitle}>
            <Typography variant='subtitle2'>Apt/Unit #</Typography>
          </InputLabel>
          <CssTextField
            placeholder='Apt/Unit #'
            labelid='currAptUnit'
            name='apt_unit'
            variant='outlined'
            margin='normal'
            disabled={props.readOnly}
            value={current_addr.apt_unit}
            onChange={(e) => setAddr(e, "current_addr")}
          />
        </Grid>

        <Grid item xs={6}>
          <InputLabel id='currCity' className={classes.subtitle}>
            <Typography variant='subtitle2'>City</Typography>
          </InputLabel>
          <CssTextField
            placeholder='City'
            labelid='currCity'
            name='city'
            variant='outlined'
            margin='normal'
            disabled={props.readOnly}
            value={current_addr.city}
            onChange={(e) => setAddr(e, "current_addr")}
          />
        </Grid>

        <Grid item xs={6}>
          <InputLabel id='currTerritory' className={classes.subtitle}>
            <Typography variant='subtitle2'>Province/Territory</Typography>
          </InputLabel>
          <FormControl
            style={{ margin: "10px", width: "250px" }}
            variant='outlined'
          >
            <Select
              labelId='currTerritory'
              placeholder='Province/Territory'
              name='province'
              disabled={props.readOnly}
              value={current_addr.province}
              onChange={(e) => setAddr(e, "current_addr")}
            >
              {terriotories.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <InputLabel id='currPostal' className={classes.subtitle}>
            <Typography variant='subtitle2'>
              Postal Code
              <span className='text-red-500 font-bold'> *</span>
            </Typography>
          </InputLabel>
          <CssTextField
            placeholder='Postal Code'
            labelid='currPostal'
            maxLength='6'
            name='postal_code'
            variant='outlined'
            margin='normal'
            disabled={props.readOnly}
            value={current_addr.postal_code}
            onChange={(e) => setAddr(e, "current_addr")}
            helperText={postalCodeCurrentErr || ""}
            error={!!postalCodeCurrentErr}
          />
        </Grid>
      </Grid>
    </Grid>
  );
  const renderPermanentAddr = (
    <Grid container>
      <Grid container direction='row' alignItems='center'>
        <Typography variant='h5' className={classes.sectionHeading}>
          Permanent Address
        </Typography>
        <Checkbox
          name='sameAsCurrent'
          checked={sameAsCurrentAddr}
          onChange={(e) => setAddr(e, "permanent_addr")}
          color='primary'
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
        Same as Current Address
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <InputLabel id='permaStreetAddr' className={classes.subtitle}>
            <Typography variant='subtitle2'>Street Address</Typography>
          </InputLabel>
          <CssTextField
            placeholder='Street Address'
            labelid='permaStreetAddr'
            name='street_addr'
            variant='outlined'
            margin='normal'
            disabled={props.readOnly}
            value={permanent_addr.street_addr}
            onChange={(e) => setAddr(e, "permanent_addr")}
          />
        </Grid>

        <Grid item xs={6}>
          <InputLabel id='permaAptUnit' className={classes.subtitle}>
            <Typography variant='subtitle2'>Apt/Unit #</Typography>
          </InputLabel>
          <CssTextField
            placeholder='Apt/Unit #'
            labelid='permaAptUnit'
            name='apt_unit'
            variant='outlined'
            margin='normal'
            disabled={props.readOnly}
            value={permanent_addr.apt_unit}
            onChange={(e) => setAddr(e, "permanent_addr")}
          />
        </Grid>

        <Grid item xs={6}>
          <InputLabel id='permaCity' className={classes.subtitle}>
            <Typography variant='subtitle2'>City</Typography>
          </InputLabel>
          <CssTextField
            placeholder='City'
            labelid='permaCity'
            name='city'
            variant='outlined'
            margin='normal'
            disabled={props.readOnly}
            value={permanent_addr.city}
            onChange={(e) => setAddr(e, "permanent_addr")}
          />
        </Grid>

        <Grid item xs={6}>
          <InputLabel id='permaTerritory' className={classes.subtitle}>
            <Typography variant='subtitle2'>Province/Territory</Typography>
          </InputLabel>
          <FormControl
            style={{ margin: "10px", width: "250px" }}
            variant='outlined'
          >
            <Select
              labelId='permaTerritory'
              placeholder='Province/Territory'
              name='province'
              disabled={props.readOnly}
              value={permanent_addr.province}
              onChange={(e) => setAddr(e, "permanent_addr")}
            >
              {terriotories.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <InputLabel id='permaPostal' className={classes.subtitle}>
            <Typography variant='subtitle2'>
              Postal Code
              <span className='text-red-500 font-bold'> *</span>
            </Typography>
          </InputLabel>
          <CssTextField
            placeholder='Postal Code'
            labelid='permaPostal'
            maxLength='6'
            name='postal_code'
            variant='outlined'
            margin='normal'
            disabled={props.readOnly}
            value={permanent_addr.postal_code}
            onChange={(e) => setAddr(e, "permanent_addr")}
            helperText={postalCodePermanentErr || ""}
            error={!!postalCodePermanentErr}
          />
        </Grid>
      </Grid>
    </Grid>
  );
  const renderMailingAddr = (
    <Grid container style={{ marginBottom: "50px" }}>
      <Typography variant='h5' className={classes.sectionHeading}>
        Mailing Address
      </Typography>

      <Grid container>
        <Grid item xs={12}>
          <InputLabel id='sameAs' className={classes.subtitle}>
            <Typography variant='subtitle2'>Same As</Typography>
          </InputLabel>
          <FormControl
            style={{ margin: "10px", width: "250px" }}
            variant='outlined'
          >
            <Select
              labelId='sameAs'
              name='sameAs'
              disabled={props.readOnly}
              value={sameAs}
              onChange={(e) => setAddr(e, "mailing_addr")}
            >
              {[
                { name: "Custom", value: "none" },
                { value: "current", name: "Current Address" },
                { value: "permanent", name: "Permanent Address" },
              ].map((item) => (
                <MenuItem value={item.value}>{item.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <InputLabel id='mailStreetAddr' className={classes.subtitle}>
            <Typography variant='subtitle2'>Street Address</Typography>
          </InputLabel>
          <CssTextField
            placeholder='Street Address'
            labelid='mailStreetAddr'
            name='street_addr'
            variant='outlined'
            margin='normal'
            disabled={props.readOnly}
            value={mailing_addr.street_addr}
            onChange={(e) => setAddr(e, "mailing_addr")}
          />
        </Grid>

        <Grid item xs={6}>
          <InputLabel id='mailAptUnit' className={classes.subtitle}>
            <Typography variant='subtitle2'>Apt/Unit #</Typography>
          </InputLabel>
          <CssTextField
            placeholder='Apt/Unit #'
            labelid='mailAptUnit'
            name='apt_unit'
            variant='outlined'
            margin='normal'
            disabled={props.readOnly}
            value={mailing_addr.apt_unit}
            onChange={(e) => setAddr(e, "mailing_addr")}
          />
        </Grid>

        <Grid item xs={6}>
          <InputLabel id='mailCity' className={classes.subtitle}>
            <Typography variant='subtitle2'>City</Typography>
          </InputLabel>
          <CssTextField
            placeholder='City'
            labelid='mailCity'
            name='city'
            variant='outlined'
            margin='normal'
            disabled={props.readOnly}
            value={mailing_addr.city}
            onChange={(e) => setAddr(e, "mailing_addr")}
          />
        </Grid>

        <Grid item xs={6}>
          <InputLabel id='mailTerritory' className={classes.subtitle}>
            <Typography variant='subtitle2'>Province/Territory</Typography>
          </InputLabel>
          <FormControl
            style={{ margin: "10px", width: "250px" }}
            variant='outlined'
          >
            <Select
              labelId='mailTerritory'
              placeholder='Province/Territory'
              name='province'
              disabled={props.readOnly}
              value={mailing_addr.province}
              onChange={(e) => setAddr(e, "mailing_addr")}
            >
              {terriotories.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <InputLabel id='mailPostal' className={classes.subtitle}>
            <Typography variant='subtitle2'>
              Postal Code
              <span className='text-red-500 font-bold'> *</span>
            </Typography>
          </InputLabel>
          <CssTextField
            placeholder='Postal Code'
            labelid='mailPostal'
            maxLength='6'
            name='postal_code'
            variant='outlined'
            margin='normal'
            disabled={props.readOnly}
            value={mailing_addr.postal_code}
            onChange={(e) => setAddr(e, "mailing_addr")}
            helperText={postalCodeMailingErr || ""}
            error={!!postalCodeMailingErr}
          />
        </Grid>
      </Grid>
    </Grid>
  );

  const onSelectDoc = (files, docType) => {
    const onSuccess = (data) => {
      switch (docType) {
        case "PersonalDoc":
          setPeronalDoc([
            ...PersonalDoc,
            { url: data.file_url, name: data.filename },
          ]);
          break;

        case "ProfileImage":
          setProfileImage([{ url: data.file_url, name: data.filename }]);
          break;

        case "AdditionalDoc":
          setAdditionalDoc([
            ...AdditionalDoc,
            { url: data.file_url, name: data.filename },
          ]);
          break;

        case "CompanyDoc":
          setCompanyDoc([
            ...CompanyDoc,
            { url: data.file_url, name: data.filename },
          ]);
          break;

        case "OfficialDoc":
          setOfficialDoc([
            ...OfficialDoc,
            { url: data.file_url, name: data.filename },
          ]);
          break;

        default:
          break;
      }
    };

    // Upload to S3
    Api.fileUpload(files, "org-glen", auth.token, onSuccess);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container direction='row'>
        <Grid item xs={5} sm={4} md={4}>
          <Grid container direction='column'>
            <Grid
              item
              xs={12}
              style={{
                height: "100px",
                borderBottom: "2px solid lightgrey",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px",
                paddingLeft: "10px",
                width: "100%",
              }}
            >
              <Typography
                variant='h5'
                style={{
                  color: "#1F299C",
                  fontWeight: "800",
                }}
              >
                {props.operation === "edit" ? "Edit Employee" : " Add Employee"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Stepper
                nonLinear={isEditOperation}
                orientation='vertical'
                activeStep={activeStep}
                // style={{ padding: "30px", height: "50px" }}
              >
                {steps.map((step, index) => {
                  return (
                    <Step key={step}>
                      <StepButton onClick={handleStep(index)}>
                        <StepLabel>
                          <Typography
                            variant='subtitle1'
                            style={{ color: "#19238f", fontWeight: "800" }}
                          >
                            {step}
                          </Typography>
                        </StepLabel>
                      </StepButton>
                    </Step>
                  );
                })}
              </Stepper>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={7}
          sm={8}
          md={8}
          style={{
            position: "relative",
            borderLeft: "2px solid lightgrey",
            minHeight: "100vh",
            padding: "0px 20px",
            width: "670px",
            maxWidth: "100%",
          }}
        >
          <div style={{ marginTop: "20px", right: "10px", float: "right" }}>
            <Button
              variant='outlined'
              color='primary'
              onClick={() => {
                props.close();
                props.saveAndReload();
              }}
              style={{ margin: "5px" }}
            >
              Close
            </Button>

            <Button
              variant='contained'
              color='primary'
              onClick={handleBack}
              disabled={activeStep === 0}
              style={{ margin: "5px" }}
            >
              Back
            </Button>

            {activeStep !== 5 && (
              <Button
                variant='contained'
                color='primary'
                onClick={() => handleNext()}
                style={{ margin: "5px" }}
              >
                Next
              </Button>
            )}
            {activeStep !== 5 && props.operation !== "add" && (
              <Button
                variant='contained'
                color='primary'
                onClick={handleSaveTabData}
                style={{ margin: "5px" }}
              >
                Save
              </Button>
            )}

            {activeStep === 5 && (
              <Button
                variant='contained'
                color='primary'
                form='profile-form'
                style={{ margin: "5px" }}
                onClick={(e) => handleFormSubmit(e)}
                disabled={props.operation === "view"}
              >
                Submit
              </Button>
            )}
          </div>
          <form id='profile-form' encType='multipart/form-data'>
            {/*  ===================== BASIC INFO ============================================ */}
            {activeStep === 0 && (
              <div>
                <Typography
                  variant='h5'
                  className={classes.sectionHeading}
                  style={{ marginTop: "35px" }}
                >
                  Personal Details
                </Typography>

                <div className={classes.fieldContainer}>
                  <InputLabel id='first_name' className={classes.subtitle}>
                    <Typography variant='subtitle2'>
                      First Name
                      <span className='text-red-500 font-bold'>*</span>
                    </Typography>
                  </InputLabel>

                  <CssTextField
                    required
                    placeholder='Enter First Name'
                    labelId='first_name'
                    name='first_name'
                    variant='outlined'
                    disabled={props.readOnly}
                    value={fname}
                    onChange={(e) => {
                      setFname(e.target.value);
                      setFnameError(false);
                    }}
                    helperText={fnameError || ""}
                    error={!!fnameError}
                  />
                </div>

                <div className={classes.fieldContainer}>
                  <InputLabel id='last_name' className={classes.subtitle}>
                    <Typography variant='subtitle2'>
                      Last Name{" "}
                      <span className='text-red-500 font-bold'>*</span>
                    </Typography>
                  </InputLabel>

                  <CssTextField
                    required
                    placeholder='Enter Last Name'
                    labelId='last_name'
                    name='last_name'
                    variant='outlined'
                    disabled={props.readOnly}
                    value={lname}
                    onChange={(e) => {
                      setLname(e.target.value);
                      setLnameError(false);
                    }}
                    helperText={lnameError || ""}
                    error={!!lnameError}
                  />
                </div>

                <div className={classes.fieldContainer}>
                  <InputLabel id='date_of_birth' className={classes.subtitle}>
                    <Typography variant='subtitle2'>
                      Date Of Birth{" "}
                      <span className='text-red-500 font-bold'>*</span>
                    </Typography>
                  </InputLabel>

                  <CssTextField
                    required
                    name='date_of_birth'
                    labelId='date_of_birth'
                    type='date'
                    InputProps={{
                      inputProps: {
                        min: moment().subtract(80, "y").format("YYYY-MM-DD"),
                        max: moment().subtract(18, "y").format("YYYY-MM-DD"),
                      },
                    }}
                    variant='outlined'
                    disabled={props.readOnly}
                    // min={'02-01-2020'}
                    value={dob}
                    onChange={(e) => {
                      setDob(e.target.value);
                      setDobError(false);
                    }}
                    helperText={dobError || ""}
                    // placeholder = "Date of Birth"
                    error={!!dobError}
                  />
                </div>

                <div className={classes.fieldContainer}>
                  <InputLabel id='gender-selector' className={classes.subtitle}>
                    <Typography variant='subtitle2'>
                      Gender <span className='text-red-500 font-bold'>*</span>
                    </Typography>
                  </InputLabel>

                  <FormControl
                    variant='outlined'
                    style={{ margin: "10px", width: "250px" }}
                  >
                    {/* <InputLabel id="gender-selector">Gender</InputLabel> */}
                    <Select
                      labelId='gender-selector'
                      placeholder='Select Gender'
                      name='gender'
                      disabled={props.readOnly}
                      value={gender}
                      onChange={(e) => {
                        setGender(e.target.value);
                      }}
                      helperText={genderError || ""}
                      error={!!genderError}
                    >
                      {/* <MenuItem value=''>
                        <em>None</em>
                      </MenuItem> */}
                      <MenuItem value={"Male"}>Male</MenuItem>
                      <MenuItem value={"Female"}>Female</MenuItem>
                      <MenuItem value={"Other"}>Other/Unknown</MenuItem>
                    </Select>
                    <FormHelperText>
                      <span className='text-red-500'>{genderError}</span>
                    </FormHelperText>
                  </FormControl>
                </div>

                <div className={classes.fieldContainer}>
                  <InputLabel id='blood_group' className={classes.subtitle}>
                    <Typography variant='subtitle2'>Blood Group</Typography>
                  </InputLabel>
                  <CssTextField
                    placeholder='Enter blood group'
                    labelId='blood_group'
                    name='blood_group'
                    variant='outlined'
                    disabled={props.readOnly}
                    value={bloodGroup}
                    onChange={(e) => {
                      setBloodGroup(e.target.value);
                      // setBloodGroupError(false);
                    }}
                    // helperText={bloodGroupError || ""}
                    // error={!!bloodGroupError}
                  />
                </div>

                <div className={classes.fieldContainer}>
                  <InputLabel id='bio' className={classes.subtitle}>
                    <Typography variant='subtitle2'>Bio</Typography>
                  </InputLabel>
                  <CssTextField
                    placeholder='Enter your bio'
                    name='bio'
                    labelId='bio'
                    variant='outlined'
                    disabled={props.readOnly}
                    value={bio}
                    onChange={(e) => {
                      setBio(e.target.value);
                      // setBioError(false);
                    }}
                    // helperText={bioError || ""}
                    // error={!!bioError}
                  />
                </div>
                <div className={classes.fieldContainer}>
                  <InputLabel
                    id='marital-selector'
                    className={classes.subtitle}
                  >
                    <Typography variant='subtitle2'>Marital Status</Typography>
                  </InputLabel>
                  <FormControl
                    variant='outlined'
                    style={{ margin: "10px", width: "250px" }}
                  >
                    <Select
                      labelId='marital-selector'
                      // placeholder="Marital Status"
                      name='maritalStatus'
                      disabled={props.readOnly}
                      value={maritalStatus}
                      onChange={(e) => setMaritalStatus(e.target.value)}
                    >
                      <MenuItem value=''>
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={"Married"}>Married</MenuItem>
                      <MenuItem value={"Single"}>Single</MenuItem>
                      <MenuItem value={"Single"}>Unknown</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                {/* ------------------------------------------------------------- */}
                <Divider orientation='horizontal' />
                {/* ------------------------------------------------------------- */}

                <Typography variant='h4' className={classes.sectionHeading}>
                  Residency
                </Typography>

                {renderCurrentAddr}
                {renderPermanentAddr}
                {renderMailingAddr}
                {/* 
                <div className={classes.fieldContainer}>
                  <InputLabel id="per_addr" className={classes.subtitle}>
                    <Typography variant="subtitle2">
                      Permanent Address
                    </Typography>
                  </InputLabel>
                  <CssTextField
                    placeholder="Enter your permanent address"
                    name="per_addr"
                    labelId="per_addr"
                    variant="outlined"
                    margin="normal"
                    disabled={props.readOnly}
                    value={permanentAddress}
                    onChange={(e) => setPermanentAddress(e.target.value)}
                  />
                </div>

                <div className={classes.fieldContainer}>
                  <InputLabel id="mailing_addr" className={classes.subtitle}>
                    <Typography variant="subtitle2">Mailing Address</Typography>
                  </InputLabel>
                  <CssTextField
                    placeholder="Enter your mailing address"
                    name="mailing_addr"
                    labelId="mailing_addr"
                    variant="outlined"
                    margin="normal"
                    disabled={props.readOnly}
                    value={mailingAddress}
                    onChange={(e) => setMailingAddress(e.target.value)}
                  />
                </div> */}
              </div>
            )}
            {/* ===================== EMPLOYEE DETAILS ============================================ */}
            {activeStep === 1 && (
              <div>
                <Typography
                  variant='h5'
                  className={classes.sectionHeading}
                  style={{ marginTop: "35px" }}
                >
                  Company
                </Typography>

                <div className={classes.fieldContainer}>
                  <InputLabel id='role-selector' className={classes.subtitle}>
                    <Typography variant='subtitle2'>
                      Role <span className='text-red-500 font-bold'>*</span>
                    </Typography>
                  </InputLabel>
                  <FormControl
                    variant='outlined'
                    style={{ margin: "10px", width: "250px" }}
                  >
                    {/* <InputLabel id="role-selector"> Select Role*</InputLabel> */}
                    <Select
                      labelId='role-selector'
                      // placeholder="Select Role"
                      name='role'
                      disabled={props.readOnly}
                      value={role}
                      onChange={(e) => {
                        setRole(e.target.value);
                        setRoleError(false);
                      }}
                      // helperText={roleError || ""}
                      error={!!roleError}
                    >
                      <MenuItem value=''>
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={"ADMIN"}>Admin</MenuItem>
                      <MenuItem value={"HR"}>HR</MenuItem>
                      <MenuItem value={"EMPLOYEE"}>Employee</MenuItem>
                    </Select>
                    <FormHelperText style={{ color: "#d32f2f" }}>
                      {roleError || ""}
                    </FormHelperText>
                  </FormControl>
                </div>

                <div className={classes.fieldContainer}>
                  <InputLabel id='emp_position' className={classes.subtitle}>
                    <Typography variant='subtitle2'>
                      Position <span className='text-red-500 font-bold'>*</span>
                    </Typography>
                  </InputLabel>
                  <CssTextField
                    required
                    placeholder='Enter your employee position'
                    name='emp_position'
                    labelId='emp_position'
                    variant='outlined'
                    disabled={props.readOnly}
                    value={empPosition}
                    onChange={(e) => {
                      setEmpPosition(e.target.value);
                      setEmpPositionError(false);
                    }}
                    helperText={empPositionError || ""}
                    error={!!empPositionError}
                  />
                </div>

                {/*<div className={classes.fieldContainer}>
                  <InputLabel id="emp_id" className={classes.subtitle}>
                    <Typography variant="subtitle2">Employee Code</Typography>
                  </InputLabel>
                  <CssTextField
                    required
                    placeholder="Enter your employee id"
                    name="emp_id"
                    labelId="emp_id"
                    variant="outlined"
                    disabled={props.readOnly}
                    value={empId}
                    onChange={(e) => {
                      setEmpId(e.target.value);
                      setEmpIdError(false);
                    }}
                    helperText={empIdError || ""}
                    error={!!empIdError}
                  />
                </div>*/}

                <div className={classes.fieldContainer}>
                  <InputLabel id='joining_date' className={classes.subtitle}>
                    <Typography variant='subtitle2'>
                      Joining Date{" "}
                      <span className='text-red-500 font-bold'>*</span>
                    </Typography>
                  </InputLabel>
                  <CssTextField
                    name='joining_date'
                    labelId='joining_date'
                    type='date'
                    required
                    variant='outlined'
                    disabled={props.readOnly}
                    value={joiningDate}
                    InputProps={{
                      inputProps: {
                        max: moment().add(14, "d").format("YYYY-MM-DD"),
                      },
                    }}
                    onChange={(e) => {
                      let date = e.target.value.split("-");
                      let dateYear = parseInt(date[0]);
                      let year = new Date().getFullYear();
                      if (
                        date[0].length > 4 ||
                        (dateYear.length === 4 && dateYear < 1960) ||
                        (dateYear.length === 4 && dateYear > parseInt(year))
                      )
                        return;

                      setJoiningDate(e.target.value);
                      setJoiningDateEror(false);
                    }}
                    // helperText={"Joining Date"}
                    helperText={joiningDateError || ""}
                    error={!!joiningDateError}
                  />
                </div>

                <div className={classes.fieldContainer}>
                  <InputLabel id='sin' className={classes.subtitle}>
                    <Typography variant='subtitle2'>
                      SIN
                      <span className='text-red-500 font-bold'> *</span>
                    </Typography>
                  </InputLabel>
                  <CssTextField
                    placeholder='Enter SIN'
                    type='number'
                    max='999999999'
                    name='sin'
                    variant='outlined'
                    disabled={props.readOnly}
                    value={sinNo}
                    onChange={({ target: { value } }) => {
                      console.log(value, value.length, "value");
                      if (value < 1000000000) setSinNo(value);
                      if (sinNo.length === 9) setSinNoError("");
                    }}
                    helperText={sinNoError || ""}
                    error={!!sinNoError}
                  />
                </div>

                <div className={classes.fieldContainer}>
                  <InputLabel id='email' className={classes.subtitle}>
                    <Typography variant='subtitle2'>
                      Official Email Address{" "}
                    </Typography>
                  </InputLabel>
                  <CssTextField
                    required
                    id='outlined-required'
                    type='email'
                    placeholder='Enter your email'
                    name='Official email'
                    labelId='Official email'
                    variant='outlined'
                    disabled={props.readOnly}
                    value={officialEmail}
                    onChange={(e) => {
                      setOfficialEmail(e.target.value);
                      setOfficialEmailError(false);
                    }}
                    helperText={officialEmailError || ""}
                    error={!!officialEmailError}
                  />
                </div>

                <div className={classes.fieldContainer}>
                  <InputLabel id='email' className={classes.subtitle}>
                    <Typography variant='subtitle2'>
                      Personal Email Address{" "}
                      <span className='text-red-500 font-bold'>*</span>
                    </Typography>
                  </InputLabel>
                  <CssTextField
                    required
                    id='outlined-required'
                    type='email'
                    placeholder='Enter your email'
                    name='email'
                    labelId='email'
                    variant='outlined'
                    disabled={props.readOnly}
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setEmailError(false);
                    }}
                    helperText={emailError || ""}
                    error={!!emailError}
                  />
                </div>

                <div className={classes.fieldContainer}>
                  <InputLabel id='dl_license' className={classes.subtitle}>
                    <Typography variant='subtitle2'>Driving License</Typography>
                  </InputLabel>
                  <CssTextField
                    placeholder='Enter your license number'
                    name='dl_license'
                    labelId='dl_license'
                    variant='outlined'
                    disabled={props.readOnly}
                    value={drivingLicence}
                    onChange={(e) => {
                      setDrivingLicence(e.target.value);
                    }}
                  />
                </div>

                <div className={classes.fieldContainer}>
                  <InputLabel id='phone' className={classes.subtitle}>
                    <Typography variant='subtitle2'>Official No.</Typography>
                  </InputLabel>
                  <FormControl>
                    <PhoneInput
                      defaultCountry='ca'
                      regions={["north-america"]}
                      labelId='phone'
                      // vancouver , BC
                      value={contactOfficial || "1"}
                      containerStyle={{
                        border: "1px solid rgba(118, 118, 118, .4)",
                        width: "250px",
                        height: "56px",
                        borderRadius: "5px",
                        margin: "10px 10px 0px 10px",
                        paddingLeft: "10px",
                        display: "inline-block",
                      }}
                      inputStyle={{
                        borderBottom: "none",
                        width: "188px",
                      }}
                      labelStyle={{
                        marginLeft: "5px",
                      }}
                      disabled={props.readOnly}
                      placeholder={""}
                      onChange={(phone) => {
                        if (phone.length < 18) setContactOfficial(phone);
                        if (phone.length === 17) setContactOfficialErr("");
                      }}
                      // placeholder={contactError || "Contact"}
                      error={!!contactError}
                      inputProps={{
                        name: "Official No",
                        required: true,
                        autoFocus: true,
                      }}
                    />
                    <FormHelperText
                      style={{
                        color: "#d32f2f",
                        marginLeft: "25px",
                        marginBottom: "10px",
                      }}
                    >
                      {contactOfficialErr || ""}
                    </FormHelperText>
                  </FormControl>
                </div>

                <div className={classes.fieldContainer}>
                  <InputLabel id='phone' className={classes.subtitle}>
                    <Typography variant='subtitle2'>
                      Personal No.{" "}
                      <span className='text-red-500 font-bold'>*</span>
                    </Typography>
                  </InputLabel>
                  <FormControl>
                    <PhoneInput
                      defaultCountry='ca'
                      regions={["north-america"]}
                      labelId='phone'
                      // vancouver , BC
                      value={contact || "1"}
                      containerStyle={{
                        border: "1px solid rgba(118, 118, 118, .4)",
                        width: "250px",
                        height: "56px",
                        borderRadius: "5px",
                        margin: "10px 10px 0px 10px",
                        paddingLeft: "10px",
                        display: "inline-block",
                      }}
                      inputStyle={{
                        borderBottom: "none",
                        width: "188px",
                      }}
                      labelStyle={{
                        marginLeft: "5px",
                      }}
                      disabled={props.readOnly}
                      placeholder={""}
                      onChange={(phone) => {
                        if (phone.length < 18) setContact(phone);
                        if (phone.length === 17) setContactError("");
                      }}
                      // placeholder={contactError || "Contact"}
                      error={!!contactError}
                      inputProps={{
                        name: "contact",
                        required: true,
                        autoFocus: true,
                      }}
                    />
                    <FormHelperText
                      style={{
                        color: "#d32f2f",
                        marginLeft: "25px",
                        marginBottom: "10px",
                      }}
                    >
                      {contactError || ""}
                    </FormHelperText>
                  </FormControl>
                </div>

                <div className={classes.fieldContainer}>
                  <InputLabel id='phone' className={classes.subtitle}>
                    <Typography variant='subtitle2'>Alternate No.</Typography>
                  </InputLabel>
                  <FormControl>
                    <PhoneInput
                      defaultCountry='ca'
                      regions={["north-america"]}
                      labelId='phone'
                      // vancouver , BC
                      value={contactAlternate || "1"}
                      containerStyle={{
                        border: "1px solid rgba(118, 118, 118, .4)",
                        width: "250px",
                        height: "56px",
                        borderRadius: "5px",
                        margin: "10px 10px 0px 10px",
                        paddingLeft: "10px",
                        display: "inline-block",
                      }}
                      inputStyle={{
                        borderBottom: "none",
                        width: "188px",
                      }}
                      labelStyle={{
                        marginLeft: "5px",
                      }}
                      disabled={props.readOnly}
                      placeholder={""}
                      onChange={(phone) => {
                        if (phone.length < 18) setContactAlternate(phone);
                        if (phone.length === 17) setContactAltErr("");
                      }}
                      // placeholder={contactError || "Contact"}
                      error={!!contactError}
                      inputProps={{
                        name: "Alternate No",
                        required: true,
                        autoFocus: true,
                      }}
                    />
                    <FormHelperText
                      style={{
                        color: "#d32f2f",
                        marginLeft: "25px",
                        marginBottom: "10px",
                      }}
                    >
                      {contactAltErr || ""}
                    </FormHelperText>
                  </FormControl>
                </div>
                {/* ------------------------------------------------------------- */}
                <Divider orientation='horizontal' />
                {/* ------------------------------------------------------------- */}

                <Typography variant='h5' className={classes.sectionHeading}>
                  Medical Details
                </Typography>

                <div className={classes.fieldContainer}>
                  <InputLabel id='health_issues' className={classes.subtitle}>
                    <Typography variant='subtitle2'>Health Issues</Typography>
                  </InputLabel>
                  <CssTextField
                    placeholder='Any health issues'
                    name='health_issues'
                    labelId='health_issues'
                    variant='outlined'
                    disabled={props.readOnly}
                    value={healthIssues}
                    onChange={(e) => setHealthIssues(e.target.value)}
                  />
                </div>

                <div className={classes.fieldContainer}>
                  <InputLabel id='health_card_id' className={classes.subtitle}>
                    <Typography variant='subtitle2'>Health Card</Typography>
                  </InputLabel>
                  <CssTextField
                    placeholder='Enter health card id'
                    name='health_card_id'
                    labelId='health_card_id'
                    variant='outlined'
                    disabled={props.readOnly}
                    value={healthCard}
                    onChange={(e) => setHealthCard(e.target.value)}
                  />
                </div>

                <div className={classes.fieldContainer}>
                  <InputLabel
                    id='health_card_province'
                    className={classes.subtitle}
                  >
                    <Typography variant='subtitle2'>
                      Province Of Issue
                    </Typography>
                  </InputLabel>
                  <CssTextField
                    placeholder='Enter province Of issue'
                    name='health_card_province'
                    labelId='health_card_province'
                    variant='outlined'
                    disabled={props.readOnly}
                    value={provinceOfIssue}
                    onChange={(e) => setProvinceOfIssue(e.target.value)}
                  />
                </div>
                {/* ------------------------------------------------------------- */}
                <Divider orientation='horizontal' />
                {/* ------------------------------------------------------------- */}

                <Typography variant='h5' className={classes.sectionHeading}>
                  In Case Of Emergency
                </Typography>

                <div className={classes.fieldContainer}>
                  <InputLabel
                    id='preferred_contact'
                    className={classes.subtitle}
                  >
                    <Typography variant='subtitle2'>
                      Preferred Contact
                    </Typography>
                  </InputLabel>
                  <CssTextField
                    placeholder="Enter preferred person's name"
                    name='preferred_contact'
                    labelId='preferred_contact'
                    variant='outlined'
                    disabled={props.readOnly}
                    value={emergencyContactName}
                    onChange={(e) => setEmergencyContactName(e.target.value)}
                  />
                </div>

                <div className={classes.fieldContainer}>
                  <InputLabel id='relation' className={classes.subtitle}>
                    <Typography variant='subtitle2'>Relation</Typography>
                  </InputLabel>
                  <CssTextField
                    placeholder='Enter relation'
                    name='relation'
                    labelId='relation'
                    variant='outlined'
                    disabled={props.readOnly}
                    value={emergencyContactRelation}
                    onChange={(e) =>
                      setEmergencyContactRelation(e.target.value)
                    }
                  />
                </div>

                <div className={classes.fieldContainer}>
                  <InputLabel
                    id='emergency_contact'
                    className={classes.subtitle}
                  >
                    <Typography variant='subtitle2'>Phone No</Typography>
                  </InputLabel>
                  <PhoneInput
                    defaultCountry='ca'
                    regions={["north-america"]}
                    value={emergencyContact || "1"}
                    containerStyle={{
                      border: "1px solid rgba(118, 118, 118, .4)",
                      width: "250px",
                      height: "56px",
                      borderRadius: "5px",
                      margin: "10px",
                      paddingLeft: "10px",
                      display: "inline-block",
                    }}
                    inputStyle={{
                      borderBottom: "none",
                      width: "188px",
                    }}
                    labelStyle={{
                      marginLeft: "5px",
                    }}
                    disabled={props.readOnly}
                    placeholder={""}
                    // onChange={(phone) => {
                    //   setEmergencyContact(phone);
                    // }}
                    onChange={(phone) => {
                      if (phone.length < 18) setEmergencyContact(phone);
                      if (phone.length === 17) setEmergencyContactError("");
                    }}
                    // placeholder={"Contact"}
                    labelId='emergency_contact'
                    inputProps={{
                      name: "emergency_contact",
                      required: false,
                      autoFocus: true,
                    }}
                  />
                  <FormHelperText
                    style={{
                      color: "#d32f2f",
                      marginLeft: "25px",
                      marginBottom: "10px",
                    }}
                  >
                    {emergencyContactError || ""}
                  </FormHelperText>
                </div>

                <div
                  className={classes.fieldContainer}
                  style={{ display: "block", marginBottom: "50px" }}
                >
                  <InputLabel id='emergency_email' className={classes.subtitle}>
                    <Typography variant='subtitle2'>Email</Typography>
                  </InputLabel>
                  <CssTextField
                    type='email'
                    placeholder='Enter email id'
                    name='emergency_email'
                    labelId='emergency_email'
                    variant='outlined'
                    disabled={props.readOnly}
                    value={emergencyContactEmail}
                    onChange={(e) => {
                      setEmergencyContactEmail(e.target.value);
                      setEmergencyContactEmailError(false);
                    }}
                    helperText={emergencyContactEmailError || ""}
                    error={!!emergencyContactEmailError}
                  />
                </div>
              </div>
            )}
            {/* ===================== EDUCATION ============================================ */}
            {activeStep === 2 && (
              <div>
                <Typography
                  variant='h5'
                  className={classes.sectionHeading}
                  style={{ marginTop: "35px" }}
                >
                  Education Details
                </Typography>
                {/* Degree ,Start Year, End year, Remarks */}
                {degree.map((d, i) => {
                  return (
                    <div key={i}>
                      <div
                        style={{
                          position: "relative",
                        }}
                      >
                        <Divider
                          orientation='horizontal'
                          style={{
                            margin: "20px 15px 30px 12px",
                            color: i === 0 ? "transparent" : "auto",
                          }}
                        />
                        <IconButton
                          edge='end'
                          aria-label='delete'
                          disabled={props.readOnly}
                          style={{
                            position: "absolute",
                            right: "25px",
                            top: "0px",
                          }}
                        >
                          <CloseIcon onClick={() => handleDeleteDegree(i)} />
                        </IconButton>
                      </div>
                      {degreeDetails(i)}
                    </div>
                  );
                })}
                <div
                  style={{
                    width: "100%",
                    position: "relative",
                    height: "50px",
                  }}
                >
                  <IconButton
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                    aria-label='more'
                    id='long-button'
                    onClick={handleAddDegree}
                    disabled={props.readOnly}
                  >
                    <Typography
                      variant='h6'
                      display='inline'
                      style={{ marginLeft: "10px" }}
                    >
                      Add Degree
                    </Typography>

                    <AddCircleOutlineIcon
                      style={{ fontSize: "30px", color: "#19238f" }}
                    />
                  </IconButton>
                </div>
                {/* <div className={classes.fieldContainer}>
                  <InputLabel
                    id="grad_specialisation"
                    className={classes.subtitle}
                  >
                    <Typography variant="subtitle2">Specialisation</Typography>
                  </InputLabel>
                  <CssTextField
                    placeholder="Enter specialisation"
                    name="grad_specialisation"
                    labelId="grad_specialisation"
                    variant="outlined"
                    disabled={props.readOnly}
                    value={graduationSpec}
                    onChange={(e) => setGraduationSpec(e.target.value)}
                  />
                </div> */}
                {/* ------------------------------------------------------------- */}
                <Divider
                  orientation='horizontal'
                  style={{ marginBottom: "20px" }}
                />
                {/* ------------------------------------------------------------- */}
                <Typography variant='h5' className={classes.sectionHeading}>
                  License/Certification
                </Typography>

                {certification.map((c, i) => {
                  return (
                    <div key={i}>
                      <div
                        style={{
                          position: "relative",
                        }}
                        key={i}
                      >
                        <Divider
                          orientation='horizontal'
                          style={{
                            margin: "20px 15px 30px 12px",
                            color: i === 0 ? "transparent" : "auto",
                          }}
                        />
                        <IconButton
                          edge='end'
                          aria-label='delete'
                          disabled={props.readOnly}
                          style={{
                            position: "absolute",
                            right: "25px",
                            top: "0px",
                          }}
                        >
                          <CloseIcon
                            onClick={() => handleDeleteCertificate(i)}
                          />
                        </IconButton>
                      </div>
                      {getCertificates(i)}
                    </div>
                  );
                })}
                <div
                  style={{
                    width: "100%",
                    position: "relative",
                    height: "50px",
                    marginBottom: "50px",
                  }}
                >
                  <IconButton
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                    aria-label='more'
                    id='long-button'
                    onClick={handleAddCertificate}
                    disabled={props.readOnly}
                  >
                    <Typography
                      variant='h6'
                      display='inline'
                      style={{ marginLeft: "10px" }}
                    >
                      Add License/Certification
                    </Typography>

                    <AddCircleOutlineIcon
                      style={{ fontSize: "30px", color: "#19238f" }}
                    />
                  </IconButton>
                </div>
              </div>
            )}
            {/* ===================== BANK DETAILS ============================================ */}
            {activeStep === 3 && (
              <div>
                <Typography
                  variant='h5'
                  className={classes.sectionHeading}
                  style={{ marginTop: "35px" }}
                >
                  Account Details
                </Typography>

                <div className={classes.fieldContainer}>
                  <InputLabel id='acc_no' className={classes.subtitle}>
                    <Typography variant='subtitle2'>Account No.</Typography>
                  </InputLabel>
                  <CssTextField
                    placeholder='Enter your account No.'
                    name='acc_no'
                    labelId='acc_no'
                    variant='outlined'
                    disabled={props.readOnly}
                    value={accountNo}
                    onChange={({ target: { value } }) => {
                      if (value < 10000000) setAccountNo(value);
                      // setAccountNoError(false);
                    }}
                    // helperText={accountNoError || ""}
                    // error={!!accountNoError}
                  />
                </div>

                <div className={classes.fieldContainer}>
                  <InputLabel id='bank_name' className={classes.subtitle}>
                    <Typography variant='subtitle2'>Bank Name</Typography>
                  </InputLabel>
                  <CssTextField
                    placeholder='Enter bank name'
                    name='bank_name'
                    labelId='bank_name'
                    variant='outlined'
                    disabled={props.readOnly}
                    value={bankName}
                    onChange={(e) => {
                      setBankName(e.target.value);
                    }}
                  />
                </div>

                <div className={classes.fieldContainer}>
                  <InputLabel id='institution_no' className={classes.subtitle}>
                    <Typography variant='subtitle2'>Institution No</Typography>
                  </InputLabel>
                  <CssTextField
                    placeholder='Enter institution No'
                    name='institution_no'
                    labelId='institution_no'
                    variant='outlined'
                    disabled={props.readOnly}
                    value={institutionNo}
                    onChange={({ target: { value } }) => {
                      if (value < 1000) setInstitutionNo(value);
                    }}
                  />
                </div>

                <div className={classes.fieldContainer}>
                  <InputLabel id='transit_no' className={classes.subtitle}>
                    <Typography variant='subtitle2'>Transit No</Typography>
                  </InputLabel>
                  <CssTextField
                    placeholder='Enter transit No'
                    name='transit_no'
                    labelId='transit_no'
                    variant='outlined'
                    disabled={props.readOnly}
                    value={transitNo}
                    onChange={({ target: { value } }) => {
                      if (value < 100000) setTransitNo(value);
                    }}
                  />
                </div>
              </div>
            )}
            {/* ==================== DOCUMENTS ================================= */}
            {activeStep === 4 && (
              <div>
                <Typography
                  variant='h5'
                  className={classes.sectionHeading}
                  style={{ marginTop: "35px" }}
                >
                  Personal Details
                </Typography>

                <label htmlFor='personal-file'>
                  <Typography
                    variant='h6'
                    display='inline'
                    className={classes.subtitle}
                    style={{ marginLeft: "20px" }}
                  >
                    Add Profile Image &nbsp; &nbsp; &nbsp;
                  </Typography>
                </label>
                <input
                  id='profile-image'
                  type='file'
                  multiple
                  onChange={(e) => onSelectDoc(e.target.files, "ProfileImage")}
                  disabled={props.readOnly}
                ></input>

                <List style={{ maxWidth: "700px", marginBottom: "10px" }}>
                  {getDocumentList(ProfileImage, "ProfileImage")}
                </List>

                <label htmlFor='personal-file'>
                  <Typography
                    variant='h6'
                    display='inline'
                    className={classes.subtitle}
                    style={{ marginLeft: "20px" }}
                  >
                    Add Personal Documents &nbsp;
                  </Typography>
                </label>
                <input
                  id='personal-file'
                  type='file'
                  multiple
                  onChange={(e) => onSelectDoc(e.target.files, "PersonalDoc")}
                  disabled={props.readOnly}
                ></input>

                <List style={{ maxWidth: "700px", marginBottom: "10px" }}>
                  {getDocumentList(PersonalDoc, "PersonalDoc")}
                </List>

                <Divider orientation='horizontal' />

                <Typography variant='h5' className={classes.sectionHeading}>
                  Company Documents
                </Typography>

                <label htmlFor='extra-file' className={classes.subtitle}>
                  <Typography
                    variant='h6'
                    display='inline'
                    style={{ marginLeft: "10px" }}
                  >
                    Add Company Documents &nbsp;
                  </Typography>
                </label>
                <input
                  id='extra-file'
                  type='file'
                  multiple
                  onChange={(e) => onSelectDoc(e.target.files, "CompanyDoc")}
                  disabled={props.readOnly}
                ></input>

                <List style={{ maxWidth: "700px", marginBottom: "10px" }}>
                  {getDocumentList(CompanyDoc, "CompanyDoc")}
                </List>

                {/* ------------------------------------------------------------- */}
                <Divider orientation='horizontal' />
                {/* ------------------------------------------------------------- */}

                <Typography variant='h5' className={classes.sectionHeading}>
                  Official Documents
                </Typography>

                <label htmlFor='official-file' className={classes.subtitle}>
                  <Typography
                    variant='h6'
                    display='inline'
                    style={{ marginLeft: "10px" }}
                  >
                    Add Official Documents &nbsp;
                  </Typography>
                </label>
                <input
                  id='official-file'
                  type='file'
                  multiple
                  onChange={(e) => onSelectDoc(e.target.files, "OfficialDoc")}
                  disabled={props.readOnly}
                ></input>

                <List style={{ maxWidth: "700px", marginBottom: "10px" }}>
                  {getDocumentList(OfficialDoc, "OfficialDoc")}
                </List>
                {/* ------------------------------------------------------------- */}
                <Divider orientation='horizontal' />
                {/* ------------------------------------------------------------- */}

                <Typography variant='h5' className={classes.sectionHeading}>
                  Additional Documents
                </Typography>

                <label htmlFor='extra-file' className={classes.subtitle}>
                  <Typography
                    variant='h6'
                    display='inline'
                    style={{ marginLeft: "10px" }}
                  >
                    Add Extra Documents &nbsp;
                  </Typography>
                </label>
                <input
                  id='extra-file'
                  type='file'
                  multiple
                  onChange={(e) => onSelectDoc(e.target.files, "AdditionalDoc")}
                  disabled={props.readOnly}
                ></input>

                <List style={{ maxWidth: "700px", marginBottom: "10px" }}>
                  {getDocumentList(AdditionalDoc, "AdditionalDoc")}
                </List>
              </div>
            )}
            {/* ======================= ORGANISATION DETAILS ======================= */}
            {activeStep === 5 && (
              <div style={{ minHeight: "496px" }}>
                <Typography
                  variant='h5'
                  className={classes.sectionHeading}
                  style={{ marginTop: "35px" }}
                >
                  L1 Manager Details
                </Typography>

                <div className={classes.fieldContainer}>
                  <InputLabel id='l1_name' className={classes.subtitle}>
                    <Typography variant='subtitle2'>Name</Typography>
                  </InputLabel>
                  <CssTextField
                    placeholder="Enter L1 manager's name"
                    name='l1_name'
                    labelId='l1_name'
                    variant='outlined'
                    disabled={props.readOnly}
                    value={l1Name}
                    onChange={(e) => setL1Name(e.target.value)}
                  />
                </div>

                <div className={classes.fieldContainer}>
                  <InputLabel id='l1_name' className={classes.subtitle}>
                    <Typography variant='subtitle2'>Email</Typography>
                  </InputLabel>
                  <CssTextField
                    type='email'
                    placeholder="Enter manager's email"
                    name='l1_email'
                    labelId='l1_email'
                    variant='outlined'
                    disabled={props.readOnly}
                    value={l1Email}
                    onChange={(e) => {
                      setL1Email(e.target.value);
                      setL1EmailError(false);
                    }}
                    helperText={l1EmailError || ""}
                    error={!!l1EmailError}
                  />
                </div>

                <div className={classes.fieldContainer}>
                  <InputLabel id='l1_contact' className={classes.subtitle}>
                    <Typography variant='subtitle2'>Phone No.</Typography>
                  </InputLabel>
                  <PhoneInput
                    defaultCountry='ca'
                    regions={["north-america"]}
                    value={l1Contact || "1"}
                    labelId='l1_contact'
                    containerStyle={{
                      border: "1px solid rgba(118, 118, 118, .4)",
                      width: "200px",
                      height: "56px",
                      borderRadius: "5px",
                      margin: "10px",
                      paddingLeft: "10px",
                      display: "inline-block",
                    }}
                    inputStyle={{
                      borderBottom: "none",
                      width: "188px",
                    }}
                    labelStyle={{
                      marginLeft: "5px",
                    }}
                    disabled={props.readOnly}
                    placeholder={""}
                    onChange={(phone) => {
                      if (phone.length < 18) setL1Contact(phone);
                      if (phone.length === 17) setL1ContactError("");
                    }}
                    inputProps={{
                      name: "l1_contact",
                      required: false,
                      autoFocus: true,
                    }}
                  />

                  <FormHelperText
                    style={{
                      color: "#d32f2f",
                      marginLeft: "25px",
                      marginBottom: "10px",
                    }}
                  >
                    {console.log(l1ContactError)}
                    {l1ContactError || ""}
                  </FormHelperText>
                </div>
                {/* ------------------------------------------------------------- */}
                <Divider
                  orientation='horizontal'
                  style={{ margin: "20px 0px" }}
                />
                {/* ------------------------------------------------------------- */}

                <Typography variant='h5' className={classes.sectionHeading}>
                  HR Manager Details
                </Typography>

                <div className={classes.fieldContainer}>
                  <InputLabel id='hr_name' className={classes.subtitle}>
                    <Typography variant='subtitle2'>Name</Typography>
                  </InputLabel>
                  <CssTextField
                    placeholder="Enter HR manage's name"
                    name='hr_name'
                    labelId='hr_name'
                    variant='outlined'
                    disabled={props.readOnly}
                    value={hrName}
                    onChange={(e) => setHrName(e.target.value)}
                  />
                </div>

                <div className={classes.fieldContainer}>
                  <InputLabel id='hr_email' className={classes.subtitle}>
                    <Typography variant='subtitle2'>Email</Typography>
                  </InputLabel>
                  <CssTextField
                    type='email'
                    placeholder="Enter HR's email"
                    name='hr_email'
                    labelId='hr_email'
                    variant='outlined'
                    disabled={props.readOnly}
                    value={hrEmail}
                    onChange={(e) => {
                      setHrEmail(e.target.value);
                      setHrEmailError(false);
                    }}
                    helperText={hrEmailError || ""}
                    error={!!hrEmailError}
                  />
                </div>

                <div className={classes.fieldContainer}>
                  <InputLabel id='hr_contact' className={classes.subtitle}>
                    <Typography variant='subtitle2'>Phone No.</Typography>
                  </InputLabel>
                  <PhoneInput
                    defaultCountry='ca'
                    regions={["north-america"]}
                    value={hrContact || "1"}
                    labelId='hr_contact'
                    containerStyle={{
                      border: "1px solid rgba(118, 118, 118, .4)",
                      width: "200px",
                      height: "56px",
                      borderRadius: "5px",
                      margin: "10px",
                      paddingLeft: "10px",
                      display: "inline-block",
                    }}
                    inputStyle={{
                      borderBottom: "none",
                      width: "188px",
                    }}
                    labelStyle={{
                      marginLeft: "5px",
                      color: "black",
                    }}
                    disabled={props.readOnly}
                    placeholder={""}
                    onChange={(phone) => {
                      if (phone.length < 18) setHrContact(phone);
                      if (phone.length === 17) setHrContactError("");
                    }}
                    inputProps={{
                      name: "hr_contact",
                      required: false,
                      autoFocus: true,
                    }}
                  />

                  <FormHelperText
                    style={{
                      color: "#d32f2f",
                      marginLeft: "25px",
                      marginBottom: "10px",
                    }}
                  >
                    {hrContactError || ""}
                  </FormHelperText>
                </div>
                {/* ------------------------------------------------------------- */}
                <Divider
                  orientation='horizontal'
                  style={{ margin: "20px 0px" }}
                />

                {/* ------------------------------------------------------------- */}

                <Typography variant='h5' className={classes.sectionHeading}>
                  Supervisor Details
                </Typography>

                <div className={classes.fieldContainer}>
                  <InputLabel id='sup_name' className={classes.subtitle}>
                    <Typography variant='subtitle2'>Name</Typography>
                  </InputLabel>
                  <CssTextField
                    placeholder="Enter supervisor's name"
                    name='sup_name'
                    labelId='sup_name'
                    variant='outlined'
                    disabled={props.readOnly}
                    value={supervisorName}
                    onChange={(e) => setSupervisorName(e.target.value)}
                  />
                </div>

                <div className={classes.fieldContainer}>
                  <InputLabel id='sup_email' className={classes.subtitle}>
                    <Typography variant='subtitle2'>Email</Typography>
                  </InputLabel>
                  <CssTextField
                    type='email'
                    placeholder="Enter supervisor's email"
                    name='sup_email'
                    labelId='sup_email'
                    variant='outlined'
                    disabled={props.readOnly}
                    value={supervisorEmail}
                    onChange={(e) => {
                      setSupervisorEmail(e.target.value);
                      setSupervisorEmailError(false);
                    }}
                    helperText={supervisorEmailError || ""}
                    error={!!supervisorEmailError}
                  />
                </div>

                <div
                  className={classes.fieldContainer}
                  style={{ marginBottom: "100px" }}
                >
                  <InputLabel id='sup_contact' className={classes.subtitle}>
                    <Typography variant='subtitle2'>Phone No.</Typography>
                  </InputLabel>
                  <PhoneInput
                    defaultCountry='ca'
                    regions={["north-america"]}
                    value={supervisorContact || "1"}
                    labelId='sup_contact'
                    containerStyle={{
                      border: "1px solid rgba(118, 118, 118, .4)",
                      width: "200px",
                      height: "56px",
                      borderRadius: "5px",
                      margin: "10px",
                      paddingLeft: "10px",
                      display: "inline-block",
                    }}
                    inputStyle={{
                      borderBottom: "none",
                      width: "188px",
                    }}
                    labelStyle={{
                      marginLeft: "5px",
                      color: "black",
                    }}
                    dropdownStyle={{
                      maxHeight: "100px",
                    }}
                    disabled={props.readOnly}
                    placeholder={""}
                    // onChange={(phone) => {
                    //   setSupervisorContact(phone);
                    // }}
                    onChange={(phone) => {
                      if (phone.length < 18) setSupervisorContact(phone);
                      if (phone.length === 17) setSupervisorContactError("");
                    }}
                    inputProps={{
                      name: "sup_contact",
                      required: false,
                      autoFocus: true,
                    }}
                  />

                  <FormHelperText
                    style={{
                      color: "#d32f2f",
                      marginLeft: "25px",
                      marginBottom: "10px",
                    }}
                  >
                    {superviserContactError || ""}
                  </FormHelperText>
                </div>
              </div>
            )}
          </form>
        </Grid>
        <Snackbar
          open={!!formError}
          onClose={() => setFormError(false)}
          // TransitionComponent={<Slide direction="up" />}
          key={"Form Error"}
          autoHideDuration={3000}
        >
          <Alert severity='error'>{formError}</Alert>
        </Snackbar>
        <Snackbar
          open={!!formSuccess}
          onClose={() => setFormSuccess(false)}
          // TransitionComponent={<Slide direction="up" />}
          key={"Form Success"}
          autoHideDuration={2000}
        >
          <Alert severity='success'>{formSuccess}</Alert>
        </Snackbar>
      </Grid>
    </ThemeProvider>
  );
}

export default FormStepperNew;
