import React, { useState } from "react";
import PaperClipSidebar from "./PaperClipSidebar";

const IAssignDetails = ({ IAssignDetailsHanlder }) => {
  const [paperclip, setPaperclip] = useState(false);

  const paperClipHandler = () => {
    setPaperclip(!paperclip);
  };

  return (
    <>
      {paperclip && <PaperClipSidebar paperClipHandler={paperClipHandler} />}
      <tr className="border-2 rounded-md bg-gray-100 w-full text-blue-900">
        <th className="px-5 p-2">Item Name</th>
        <th className="px-5 p-2">Type</th>
        <th className="px-5 p-2">Brand</th>
        <th className="px-5 p-2">Model</th>
        <th className="px-5 p-2">Serial No</th>
        <th className="px-5 p-2">Status</th>
        <th className="px-5 p-2 bg-gray-100"></th>
        <th className="px-5 p-2 cursor-pointer">
          <i
            className="fa-solid fa-xmark text-lg"
            onClick={IAssignDetailsHanlder}
          ></i>
        </th>
      </tr>
      <tr className="border-2 rounded-md bg-gray-100 w-full text-blue-700">
        <th className="px-5 py-5 font-semibold">Laptop</th>
        <th className="px-5 py-5 font-semibold">Macbook Pro</th>
        <th className="px-5 py-5 font-semibold">Apple</th>
        <th className="px-5 py-5 font-semibold">ABCD123456789</th>
        <th className="px-5 py-5 font-semibold">SN123456789712</th>
        <th className="px-5 py-5 font-semibold">Active</th>
        <th className="px-5 py-5 font-semibold bg-gray-100"></th>
        <th className="px-5 py-5 font-semibold cursor-pointer">
          <i
            className="fa-solid fa-paperclip border p-2 bg-gray-50"
            onClick={paperClipHandler}
          ></i>
        </th>
      </tr>
    </>
  );
};

export default IAssignDetails;
