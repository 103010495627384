import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  DialogActions,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import axios from "axios";
import Avatar from "@material-ui/core/Avatar";
import { useDasboardStyles } from "./Style";
import AssignEmployeesDialog from "./AssignEmployeesDialog";
import {
  deleteShiftJobsite,
  getShiftProject,
  updateShiftJobsiteAssignEmp,
} from "store/actions/ShiftManagement/ShiftProjectNSites";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "hooks/useAuth";

function JobSiteCard(props) {
  const classes = useDasboardStyles();
  const [row, setSiteRow] = useState(props.siteRow);
  const [siteOpen, setSiteOpen] = useState(true);
  const [openAssignEmpDialog, setOpenAssignEmpDialog] = useState(false);
  const [jobSiteSelected, setJobSiteSelected] = useState({});
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteObject, setDeleteObject] = useState(null);
  const [deleteMode, setDeleteMode] = useState("");
  const dispatch = useDispatch();
  const { shiftJobsite, shiftJobsites } = useSelector(
    (state) => state.shiftProjectNSites
  );
  const handleDeleteSite = async (e) => {
    props.setIsLoading(true);
    dispatch(deleteShiftJobsite(deleteObject._id)).then(() => {
      props.getAllProjects();
    });

    // try {
    //   const response = await axios.delete(
    //     `${process.env.REACT_APP_API_BASE_URL}/jobSite/delete/` +
    //       deleteObject._id
    //   );
    //   if (response.status === 200) {
    //     props.getAllProjects();
    //   }
    // } catch (e) {
    //   if (e.response) {
    //     console.log("Error >", e.response);
    //   }
    // } finally {
    //   setShowDeleteDialog(false);
    //   setDeleteObject(null);
    //   setDeleteMode("");
    // }
  };

  const handleUnAssignEmployee = async (e) => {
    props.setIsLoading(true);
    let assignedEmployee = [
      { assign: false, employee_id: deleteObject.employee.employee_id },
    ];
    let payloadData = {
      jobsite_id: deleteObject.site._id,
      assignedEmployees: assignedEmployee,
    };
    dispatch(updateShiftJobsiteAssignEmp(payloadData)).then(() => {
      props.getAllProjects();
    });

    // try {
    //   const response = await axios.put(
    //     `${process.env.REACT_APP_API_BASE_URL}`,
    //     payloadData
    //   );
    //   if (response.status === 200) {
    //     props.getAllProjects();
    //   }
    // } catch (e) {
    //   if (e.response) {
    //     console.log("Error >", e.response);
    //   }
    // } finally {
    //   setShowDeleteDialog(false);
    //   setDeleteObject(null);
    //   setDeleteMode("");
    // }
  };

  const handleEmployeeDialogOpen = (e, row) => {
    setJobSiteSelected(row);
    setOpenAssignEmpDialog(true);
  };
  const handleAssignEmpCloseDialog = () => {
    setOpenAssignEmpDialog(false);
  };
  const auth = useAuth();

  useEffect(() => {
    setSiteRow(props.siteRow);
  }, [props.siteRow]);

  useEffect(() => {
    if (shiftJobsite.fulfilled) {
      const org_id = auth.user.org_id._id;
      setShowDeleteDialog(false);
      setDeleteObject(null);
      setDeleteMode("");
      props.setIsLoading(true);
      dispatch(getShiftProject(org_id));
      props.setIsLoading(!!shiftJobsites.loading);
    }
  }, [shiftJobsite]);

  return (
    <React.Fragment>
      <Dialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {deleteMode === "site" ? "Delete" : "Unassign"}{" "}
          {deleteMode === "site" ? "Site" : "Employee"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {deleteMode === "site"
              ? `Are you sure you want to delete the Site ${deleteObject?.jobSite_title}`
              : `Are you sure you want to unassign the Employee ${
                  deleteObject?.employee?.user?.first_name +
                  " " +
                  deleteObject?.employee?.user?.last_name
                }`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteDialog(false)}>Cancel</Button>
          <Button
            onClick={
              deleteMode === "site" ? handleDeleteSite : handleUnAssignEmployee
            }
            autoFocus
          >
            {deleteMode === "site" ? "Delete" : "Unassign"}
          </Button>
        </DialogActions>
      </Dialog>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell
          component='th'
          scope='row'
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0px",
            alignItems: "center",
          }}
        >
          <Typography
            className={classes.colorBlue}
            variant='h6'
            style={{
              fontWeight: "600",
              textAlign: "left",
              marginLeft: "48px",
            }}
          >
            <span style={{ display: "flex", alignItems: "center" }}>
              <Avatar
                style={{
                  marginRight: "10px",
                  backgroundColor: "orange",
                }}
              >
                {" "}
                {row.jobSite_title
                  ? row.jobSite_title.charAt(0).toUpperCase()
                  : ""}
              </Avatar>
              {row.jobSite_title ? row.jobSite_title : ""}
            </span>
          </Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography
              style={{
                color: "#0f88a3",
                fontWeight: "600",
                textAlign: "left",
                marginRight: "16px",
              }}
            >
              Total Employees :{" "}
              {row.assignedEmployees ? row.assignedEmployees.length : ""}
            </Typography>
            <div style={{ display: "flex" }}>
              <div
                style={
                  row.active === "true"
                    ? {
                        width: "20px",
                        height: "20px",
                        borderRadius: "50%",
                        backgroundColor: "lightGreen",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }
                    : {
                        width: "20px",
                        height: "20px",
                        borderRadius: "50%",
                        backgroundColor: "lightGrey",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }
                }
              >
                <div
                  style={
                    row.active === "true"
                      ? {
                          width: "10px",
                          height: "10px",
                          borderRadius: "50%",
                          backgroundColor: "green",
                        }
                      : {
                          width: "10px",
                          height: "10px",
                          borderRadius: "50%",
                          backgroundColor: "grey",
                        }
                  }
                ></div>
              </div>
              &nbsp;&nbsp;
              <Typography
                style={
                  row.active === "true"
                    ? {
                        color: "green",
                      }
                    : {
                        color: "grey",
                      }
                }
                variant='subtitle2'
              >
                {row.active === "true" ? "Active" : "Deactive"}
              </Typography>
            </div>
            <Button
              variant='contained'
              style={{
                color: "rgb(31,41,156)",
                backgroundColor: "white",
                height: "30px",
                margin: "1rem",
              }}
              onClick={(e) => handleEmployeeDialogOpen(e, row)}
            >
              + Assign Employees
            </Button>
            <IconButton
              onClick={(e) => props.handleSiteDialogOpen("edit", row)}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={(e) => {
                setShowDeleteDialog(true);
                setDeleteObject(row);
                setDeleteMode("site");
              }}
            >
              <DeleteIcon />
            </IconButton>

            <IconButton
              aria-label='expand row'
              size='small'
              onClick={() => setSiteOpen(!siteOpen)}
            >
              {siteOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }}>
          <Collapse in={siteOpen} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size='small' aria-label='purchases'>
                <TableBody>
                  {row.assignedEmployees ? (
                    row.assignedEmployees.map((empRow) => {
                      if (empRow.user)
                        return (
                          <TableRow key={empRow.empName}>
                            <TableCell
                              component='th'
                              scope='row'
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "0px",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                className={classes.colorBlue}
                                style={{
                                  fontWeight: "600",
                                  textAlign: "left",
                                  marginLeft: "96px",
                                }}
                              >
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Avatar
                                    style={{
                                      marginRight: "10px",
                                      backgroundColor: "purple",
                                    }}
                                  >
                                    {" "}
                                    {empRow.user.first_name
                                      .charAt(0)
                                      .toUpperCase()}
                                  </Avatar>{" "}
                                  {empRow.user.first_name}{" "}
                                  {empRow.user.last_name}
                                </span>
                              </Typography>
                              <div>
                                <IconButton
                                  onClick={(e) => {
                                    setShowDeleteDialog(true);
                                    setDeleteObject({
                                      site: row,
                                      employee: empRow,
                                    });
                                    setDeleteMode("employee");
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                    })
                  ) : (
                    <></>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      {openAssignEmpDialog ? (
        <Dialog
          open={openAssignEmpDialog}
          maxWidth='sm'
          fullWidth
          keepMounted
          aria-describedby='alert-dialog-slide-description'
          PaperProps={{
            style: {
              position: "absolute",
              margin: "0px",
              padding: "0px",
              right: "0px",
              minHeight: "111vh",
              top: "0px",
              borderRadius: "0px",
            },
          }}
        >
          <DialogContent
            style={{
              margin: "0px",
              padding: "0px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <AssignEmployeesDialog
              closeForm={handleAssignEmpCloseDialog}
              jobSite={jobSiteSelected}
              afterOpn={props.getAllProjects}
              setIsLoading={props.setIsLoading}
            />
          </DialogContent>
        </Dialog>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
}

export default JobSiteCard;
