import React from "react";
import Sidebar from "pages/sidebar/Sidebar";
import Images from "../../../assets/images/laptop.jpg";

const PaperClipSidebar = ({ paperClipHandler }) => {
  return (
    <Sidebar modalTitle="Filter" sidebarHandler={paperClipHandler}>
      <div>
        <p className="text-base">Attachment *</p>
        <div className="bg-gray-100 border-2 rounded-md outline-0 px-2 py-5 w-full font-semibold mt-1 focus:ring focus:outline-none flex gap-2 items-center cursor-pointer">
          <p className="text-gray-500 text-base"> Drag & Drop Here,</p>
          <p>Or Browser File</p>
          <i className="fa-solid fa-link ml-3"></i>
        </div>
      </div>
      <div className="flex justify-center sm:justify-between flex-wrap gap-2 mb-52">
        <div className="mt-4">
          <div>
            <img src={Images} alt="" className="w-52 border p-2" />
            <div className="w-52 border px-2 py-1 text-sm text-gray-700 flex justify-between items-center font-normal">
              <p>Asset Image.JPG</p>
              <i className="fa-solid fa-trash cursor-pointer"></i>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <div>
            <img src={Images} alt="" className="w-52 border p-2" />
            <div className="w-52 border px-2 py-1 text-sm text-gray-700 flex justify-between items-center font-normal">
              <p>Asset Image.JPG</p>
              <i className="fa-solid fa-trash cursor-pointer"></i>
            </div>
          </div>
        </div>
      </div>
      <div className="border-b-2 border-gray-300 my-5"></div>
      <div className="flex justify-end gap-5">
        <p
          className="py-1 px-3 font-semibold border cursor-pointer border-blue-700 rounded"
          onClick={paperClipHandler}
        >
          Cancel
        </p>
        <p className="py-1 px-3 font-semibold border cursor-pointer border-blue-700 rounded bg-indigo-700 text-gray-50">
          Save
        </p>
      </div>
    </Sidebar>
  );
};

export default PaperClipSidebar;
