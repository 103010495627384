import { getAllRequestsRepo } from "../../repositories/EmployeeManagement/QueriesAndTickets";
import {
  getAllRequestsPending,
  getAllRequestsFulfilled,
  getAllRequestsRejected,
} from "../../slices/EmployeeManagement/QueriesAndTickets";

export const getAllRequests = (id,headers) => {
  return async (dispatch) => {
    dispatch(getAllRequestsPending());
    await getAllRequestsRepo(id, headers)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getAllRequestsFulfilled(response?.data?.data));
        } else {
          dispatch(getAllRequestsRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(getAllRequestsRejected(error.message));
        }
      });
  };
};
