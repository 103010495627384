import { zonedTimeToUtc, utcToZonedTime } from "date-fns-tz";

export const timezones = [
  "Asia/Kolkata",
  "America/Vancouver",
  "America/Toronto",
];
export const uuidv4 = () => {
  const a = crypto.getRandomValues(new Uint16Array(8));
  let i = 0;
  return "00-0-4-1-000".replace(/[^-]/g, (s) =>
    ((a[i++] + s * 0x10000) >> s).toString(16).padStart(4, "0")
  );
};

export const listToCheckbox = (items = [], label = "_id", value = "name") => {
  return items.map((item) => {
    return { id: item[label], name: item[value], checked: false };
  });
};
export const getUtcDateWithZone = (
  date = new Date(),
  tz = "Vancouver/Canada"
) => {
  const utcDate = zonedTimeToUtc(date, tz); // In June 10am in Los Angeles is 5pm UTC
  return utcDate.toISOString();
};

export const getZoneTimeInLocalTime = (
  dateInIso = new Date(),
  tz = "Vancouver/Canada"
) => {
  return utcToZonedTime(dateInIso, tz);
};
