import { getAllResignationsRepo, updateResignationByIdRepo } from "../../repositories/HrManagement/Resignations";
import {
    getResignationPending,
    getResignationFulfilled,
    getResignationRejected,
    updateResignationPending,
    updateResignationFulfilled,
    updateResignationRejected
} from "../../slices/HrManagement/Resignations";

export const getAllResignations = () => {
    return async (dispatch) => {
        dispatch(getResignationPending());
        await getAllResignationsRepo()
            .then((response) => {
                if (response.status === 200) {
                    dispatch(getResignationFulfilled(response?.data));
                } else {
                    dispatch(getResignationRejected(response?.data));
                }
            })
            .catch((error) => {
                if (error && error.response) {

                    dispatch(getResignationRejected(error.message));
                }
            });
    };
};

export const updateResignationById = (data) => {
    return async (dispatch) => {
        dispatch(updateResignationPending());
        await updateResignationByIdRepo(data)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(updateResignationFulfilled(response?.data));
                } else {
                    dispatch(updateResignationRejected(response?.data));
                }
            })
            .catch((error) => {
                if (error && error.response) {

                    dispatch(updateResignationRejected(error.message));
                }
            });
    };
};


