import { makeStyles } from "@material-ui/core";

const drawerWidth = 240;
const myStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    padding: "0px 20px",
    textAlign: "left",
    borderRadius: "6px ",
    height: "350px",
  },
  educationPaper: {
    padding: theme.spacing(0),
    textAlign: "left",
    borderRadius: "6px",
    minHeight: "340px",
    marginBottom: "5px",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: "#ffffff",
    color: "#1F299C",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 0,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  papersub: {
    padding: "10px 20px",
    textAlign: "left",
    borderRadius: "6px ",
    height: "fit-content",
    minHeight: "190px",
  },
  paperMain: {
    padding: "0px 20px",
    textAlign: "left",
    borderRadius: "6px ",
    height: "fit-content",
  },

  labelsub: {
    textAlign: "left",
    margin: "20px 0px 20px 0px",
    paddingLeft: "0",
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    border: "2px solid #FFFFFF",
  },
  container: {
    paddingTop: "50px",
  },
  profileBrief: {
    // background: `url(${back})`,
    backgroundColor: "#1F299C",
    backgroundSize: "cover",
    borderRadius: "5px",
    padding: "2% 0%",
  },
  containerPad: {
    padding: "3% 0%",
  },
  bioContent: {
    fontWeight: "100",
  },
  avatar: {
    width: theme.spacing(25),
    height: theme.spacing(25),
    borderRadius: "50%",
    border: "3px solid white",
  },
  orgAvatar: {
    width: "115px",
    height: "115px",
    borderRadius: "50%",
  },
  fadedWhite: {
    color: "rgba(255,255,255,0.7)",
    fontWeight: "100",
  },
  info: {
    height: "30px",
    marginBottom: "10px",
    lineHeight: "1",
    wordWrap: "break-word",
  },
  infoValues: {
    fontWeight: "600",
    height: "30px",
    marginBottom: "10px",
    lineHeight: "1",
    wordWrap: "break-word",
  },

  label: {
    textAlign: "left",
    margin: "30px 0px 40px 0px",
    paddingLeft: "5%",
  },
  address: {
    height: "60px",
    marginBottom: "20px",
    lineHeight: "1",
    "&:last-child": {
      marginBottom: "10px",
    },
  },
  addressValue: {
    fontWeight: "600",
    height: "60px",
    marginBottom: "20px",
    lineHeight: "1",
    "&:last-child": {
      marginBottom: "10px",
    },
  },
}));

export default myStyles;
