import {
  getAllAssetConditionRepo,
  deleteAssetConditionRepo,
  createAssetConditionRepo,
  updateAssetConditionRepo,
} from "store/repositories/AssetManagement/assetCondition";
import {
  getAllAssetConditonFulfilled,
  getAllAssetConditonPending,
  getAllAssetConditonRejected,
  deleteAssetConditonPending,
  deleteAssetConditonFulfilled,
  deleteAssetConditonRejected,
  createAssetConditonPending,
  createAssetConditonFulfilled,
  createAssetConditonRejected,
  updateAssetConditionPending,
  updateAssetConditionFulfilled,
  updateAssetConditionRejected,
} from "store/slices/AssetManagement/assetCondition";

export const getAllAssetConditon = () => {
  return (dispatch) => {
    dispatch(getAllAssetConditonPending());
    getAllAssetConditionRepo()
      .then((response) => {
        if (response.status === 200) {
          dispatch(getAllAssetConditonFulfilled(response?.data?.data));
        } else {
          dispatch(getAllAssetConditonRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(getAllAssetConditonRejected(error.message));
        }
      });
  };
};

export const deleteAssetConditon = (id) => {
  return (dispatch) => {
    dispatch(deleteAssetConditonPending());
    deleteAssetConditionRepo(id)
      .then((response) => {
        if (response.status === 200) {
          dispatch(deleteAssetConditonFulfilled(response?.data?.data));
        } else {
          dispatch(deleteAssetConditonRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(deleteAssetConditonRejected(error.message));
        }
      });
  };
};

export const createAssetConditon = (data) => {
  return (dispatch) => {
    dispatch(createAssetConditonPending());
    createAssetConditionRepo(data)
      .then((response) => {
        if (response.status === 200) {
          dispatch(createAssetConditonFulfilled(response?.data?.data));
        } else {
          dispatch(createAssetConditonRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(createAssetConditonRejected(error.message));
        }
      });
  };
};

export const updateAssetCondition = (data) => {
  return (dispatch) => {
    dispatch(updateAssetConditionPending());
    updateAssetConditionRepo(data)
      .then((response) => {
        if (response.status === 200) {
          dispatch(updateAssetConditionFulfilled(response?.data?.data));
        } else {
          dispatch(updateAssetConditionRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(updateAssetConditionRejected(error.message));
        }
      });
  };
};
