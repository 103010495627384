import { makeStyles } from "@material-ui/core";

const drawerWidth = 240;

export const useLoadingBar = makeStyles((theme) => ({
  backdrop: {
    zIndex: 9999,
    color: "#fff",
  },
}));
