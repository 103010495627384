import axios from "axios";
import { Urls, BASE_URL } from "store/urls";

export const getAllTicketsandQueriesRepo = () => {
  return axios({
    baseURL: BASE_URL,
    method: "GET",
    url: Urls.GET_ALL_QUERIES_AND_TICKETS,
  });
};



