import React, { useState} from "react";
import Sidebar from "../../sidebar/Sidebar";
import {
  Typography,
  //InputBase,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  TextField,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import axios from "axios";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider
} from "@material-ui/core/styles";
import { useDasboardStyles } from "./Style";

const SidebarTimesheetsContent = ({ sidebarHandler }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());


  function getStyles(name, selected, theme) {
    return {
      fontWeight:
        selected.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  let theme = createTheme();
  theme = responsiveFontSizes(theme);
  const classes = useDasboardStyles();

  React.useEffect(() => {
    
  }, []);

  return (
    <Sidebar sidebarHandler={sidebarHandler} modalTitle="Filter">
     <ThemeProvider theme={theme}>
        <div>
          <div style={{ display: "flex", paddingLeft: "25px", marginBottom: "10px", border: "1px solid rgba(163,163,163,0.5)"}}>
            <div className={classes.nameTagContainer}>
              <div className={classes.nameTag}>S</div>
            </div>
            <Typography
              className="classes.colorBlue"
              variant="h6"
              style={{
                fontWeight: "600",
                textAlign: "left",
                padding: "5px"
              }}
            >
              Bhupinder Singh(ID12345678)
            </Typography>
          </div>
          <div style={{ paddingLeft: "0px"}}>

            <p className="text-xl" style={{ paddingLeft: "25px", marginBottom: "10px", }}>Pay Period Date Range</p>

            <div
              style={{
                display: "flex",
                margin: "10px",
                paddingLeft: "22px"
              }}
            >
              <Typography
                variant="p"
                className="cursor-pointer"
              >
                Start date*
              </Typography>
              <Typography
                variant="p"
                className="cursor-pointer"
                style={{
                paddingLeft: "125px",
              }}
              >
                End date*
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                margin: "10px",
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                
                <KeyboardDatePicker
                  fullWidth
                  inputVariant="outlined"
                  value={startDate}
                  onChange={(newValue) => {
                    setStartDate(newValue);
                  }}
                  keyboardIcon={
                    <CalendarTodayOutlinedIcon  />
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{
                        color: "rgb(31,41,156)",
                        fontWeight: "200",
                      }}
                    />
                  )}
                  style={{
                    color: "rgb(31,41,156)",
                    fontWeight: "400",
                    width: "300px",
                    marginLeft: "20px",
                  }}
                  InputAdornmentProps={{
                    style: {
                      color: "rgb(31,41,156)",
                      fontWeight: "500",
                    },
                  }}
                />
              </MuiPickersUtilsProvider>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                
                <KeyboardDatePicker
                  fullWidth
                  inputVariant="outlined"
                  value={endDate}
                  onChange={(newValue) => {
                    setEndDate(newValue);
                  }}
                  keyboardIcon={
                    <CalendarTodayOutlinedIcon />
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{
                        color: "rgb(31,41,156)",
                        fontWeight: "500",
                      }}
                    />
                  )}
                  style={{
                    color: "rgb(31,41,156)",
                    fontWeight: "500",
                    width: "300px",
                    marginLeft: "20px",
                  }}
                  InputAdornmentProps={{
                    style: {
                      color: "rgb(31,41,156)",
                      fontWeight: "500",
                    },
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div
              style={{
                display: "flex",
                paddingLeft: "27px",
                marginBottom: "10px"
              }}
            >
              <Typography
                  variant="p"
                  className="cursor-pointer"
                >
                Schedule*
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                paddingLeft: "10px"
              }}
            >

              <FormControl
                variant="outlined"
                style={{
                  width: "325px",
                  minWidth: "225px",
                  marginRight: "20px",
                  marginBottom: "10px",
                  paddingLeft: "20px"
                }}
              >
                <InputLabel
                  id="demo-simple-select-label-2"
                  style={{
                  paddingLeft: "20px"
                }}
                >
                  All schedules
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label-2"
                  label="All Schedules"
                  id="demo-simple-select"
                  style={{
                  width: "400px"
                }}
                >
                  <MenuItem value="All">
                    <em>All</em>
                  </MenuItem>
                  <MenuItem value={10}>Active</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
          
        <div className="border-b-2 border-gray-300 my-5"></div>
        <div className="flex justify-end gap-5">
          <p className="py-1 px-3 font-semibold border cursor-pointer border-blue-700 rounded">
            Clear Filter
          </p>
          <p
            className="py-1 px-3 font-semibold border cursor-pointer border-blue-700 rounded"
            onClick={sidebarHandler}
          >
            Cancel
          </p>
          <p className="py-1 px-3 font-semibold border cursor-pointer border-blue-700 rounded bg-indigo-700 text-gray-50">
            Apply
          </p>
        </div>  

      </ThemeProvider>

    </Sidebar>
  );
};

export default SidebarTimesheetsContent;