import React from "react";
import moment from "moment";
const AssetDetails = ({ assetDetails }) => {
  return (
    <div>
      <div className="flex p-5 justify-center lg:justify-between gap-5 flex-wrap lg:flex-nowrap">
        <img src={assetDetails.image} alt="" className="border p-5 w-2/5" />
        <div className="border p-5 w-full">
          <p className="text-xl font-medium">{assetDetails.about}</p>
          <div className="mt-4">
            <div className="flex gap-12 mt-3">
              <div>
                <p className="py-3">Asset No</p>
                <p className="py-3">Available Qty</p>
                <p className="py-3">Asset Condition</p>
                <p className="py-3">Amount</p>
                <p className="py-3">Brand</p>
                <p className="py-3">Serial No</p>
                <p className="py-3">Warranty</p>
                <p className="py-3">Purchase Date</p>
              </div>
              <div>
                <p className="font-bold py-3">{assetDetails.asset_no}</p>
                <p className="font-bold py-3">
                  {assetDetails.available_quantity}
                </p>
                <p className="font-bold py-3">{assetDetails.condition}</p>
                <p className="font-bold py-3">{assetDetails.amount}</p>
                <p className="font-bold py-3">{assetDetails.brand}</p>
                <p className="font-bold py-3">{assetDetails.serial}</p>
                <p className="font-bold py-3">
                  {moment(assetDetails.warranty_to).format("YYYY-MM-DD")}
                </p>
                <p className="font-bold py-3">
                  {moment(assetDetails.purchase_date).format("YYYY-MM-DD")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="flex justify-between gap-5 px-5">
        <div className="w-2/5"></div>
        <div className="border p-5">
          <p className="text-lg font-bold">About this item</p>
          <ul className="list-disc px-5">
            <li>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </li>
            <li>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </li>
          </ul>
        </div>
      </div> */}
    </div>
  );
};

export default AssetDetails;
