import { useState, useEffect } from "react";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import { TableCell, Checkbox } from "@material-ui/core";

const formatAMPM = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes.toString().padStart(2, "0");
  let strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

const Shift = ({ template, jobsiteTitle }) => {
  console.log(template,'template here')
  const startTime = moment(template.start_time);
  const endTime = moment(template.end_time);
  const [actualSTime, setActualSTime] = useState(new Date(template.start_time));
  const [actualETime, setActualETime] = useState(new Date(template.end_time));
  const [worked, setWorked] = useState(
    Number(moment.duration(endTime.diff(startTime)).asHours())
  );
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    const sTime = moment(actualSTime);
    const eTime = moment(actualETime);
    const dif = Number(moment.duration(eTime.diff(sTime)).asHours()).toFixed(2);
    const hours = Math.floor(dif);
    const mins = (dif - hours) * 0.6;
    setWorked((hours + mins).toFixed(2) >= 0 ? (hours + mins).toFixed(2) : 0);
  }, [actualSTime, actualETime]);

  const cells = [];
  // cells.push(
  //   <Checkbox
  //     value={selected}
  //     style={{
  //       color: "rgb(31,41,156)",
  //       "&.Mui-checked": {
  //         color: "rgb(31,41,156)",
  //       },
  //     }}
  //     onChange={(e) => setSelected(e.target.checked)}
  //   />
  // );
  console.log(template,'template here')
  cells.push(template.start_date);
  cells.push(template.jobsite);
  cells.push(template.employee_name);
  cells.push(template.employee_id);
  cells.push(template.shift_position);
  cells.push(
    '09:00 am' +
      " to " +
    '05:00 pm'
  );
  // const dif = Number(
  //   moment.duration(endTime.diff(startTime)).asHours()
  // ).toFixed(2);
  const hours = 8;
  const mins = 30;
  cells.push(`${hours + mins} Hrs`);
  cells.push(template.first_punchin);
  cells.push(template.last_punchout);
  // cells.push(
  //   <MuiPickersUtilsProvider utils={DateFnsUtils}>
  //     <KeyboardTimePicker
  //       // ampm={false}
  //       variant="inline"
  //       style={{
  //         width: "100%",
  //       }}
  //       value={actualSTime}
  //       onChange={(value) => {
  //         setActualSTime(value);
  //       }}
  //     />
  //   </MuiPickersUtilsProvider>
  // );
  // cells.push(
  //   <MuiPickersUtilsProvider utils={DateFnsUtils}>
  //     <KeyboardTimePicker
  //       // ampm={false}
  //       variant="inline"
  //       style={{
  //         width: "100%",
  //       }}
  //       value={actualETime}
  //       onChange={(value) => {
  //         setActualETime(value);
  //       }}
  //     />
  //   </MuiPickersUtilsProvider>
  // );
  const total = hours * 60 + mins * 100;
  let difference =
    Math.floor(worked) * 60 + (worked - Math.floor(worked)) * 100 - total;
  cells.push(
    <span style={{ color: difference < 0 ? "red" : "green" }}>
      {worked} Hrs
    </span>
  );
  cells.push(template.break || "0 Min");

  cells.push(
    <span style={{ color: difference < 0 ? "red" : "green" }}>
      {difference > 0 && "+"}
      {Math.ceil(difference) + " Min"}
    </span>
  );
  // cells.push(<span style={{ color: total < 0 ? "red" : "green" }}>Total</span>);

  return cells.map((cell, index) => (
    <TableCell
      key={index}
      style={{
        fontSize: "16px",
        padding: "5px",
        fontWeight: "bold",
        color: "rgb(31,41,156)",
        //width: (window.screen.availWidth - 240 - 50) / headersLength + "px",
      }}
    >
      {" "}
      {cell}
    </TableCell>
  ));
};

export default Shift;
