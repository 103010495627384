import React, { Fragment, useEffect, useState } from "react";
import PaperClipSidebar from "./PaperClipSidebar";
import CommentSidebarCompo from "./CommentSidebar";
import ActivitySidebar from "./ActivitySidebar";
import ShareExport from "./ShareExport";
import AddUpdateAsset from "../../allAssets/AddUpdateAsset";
import { useDispatch, useSelector } from "react-redux";

const AssignAssetDetail = (props) => {
  const { index, item, category, sub_category, auth } = props;
  const [sidebar, setSidebar] = useState(false);
  const [commentSidebar, setcommentSidebar] = useState(false);
  const [activitySidebar, setActivitySidebar] = useState(false);
  const [addUpdateAsset, setAddUpdateAsset] = useState(false);
  const [viewAsset, setViewAsset] = useState(false);
  const { allAsset, assetCategory, assetSubCategory, assetCondition } =
    useSelector((state) => state);
  const sidebarHandler = () => {
    setSidebar(!sidebar);
  };

  const commentSidebarHandler = () => {
    setcommentSidebar(!commentSidebar);
  };

  const activitySidebarHandler = () => {
    setActivitySidebar(!activitySidebar);
  };

  const assetAddUpdateHandler = () => {
    setAddUpdateAsset(!addUpdateAsset);
  };

  const assetViewHandler = () => {
    setViewAsset(!viewAsset);
  };

  useEffect(() => {}, [item?.remark?.length]);
  return (
    <Fragment key={index}>
      {sidebar && (
        <PaperClipSidebar data={item} sidebarHandler={sidebarHandler} />
      )}
      {commentSidebar && (
        <CommentSidebarCompo
          auth={auth}
          data={item}
          commentSidebarHandler={commentSidebarHandler}
        />
      )}
      {activitySidebar && (
        <ActivitySidebar
          data={item}
          activitySidebarHandler={activitySidebarHandler}
        />
      )}

      {addUpdateAsset && (
        <AddUpdateAsset
          id={item._id}
          addUpdateAsset={addUpdateAsset}
          assetAddUpdateHandler={assetAddUpdateHandler}
          categoryList={assetCategory?.allAssetCategory?.fulfilled}
          subCategoryList={assetSubCategory?.assetSubCategoryList?.fulfilled}
          conditionList={assetCondition?.assetConditionList?.fulfilled}
          assetInitialValues={item}
        />
      )}

      {viewAsset && (
        <AddUpdateAsset
          id={item._id}
          addUpdateAsset={viewAsset}
          assetAddUpdateHandler={assetViewHandler}
          categoryList={assetCategory?.allAssetCategory?.fulfilled}
          subCategoryList={assetSubCategory?.assetSubCategoryList?.fulfilled}
          conditionList={assetCondition?.assetConditionList?.fulfilled}
          assetInitialValues={item}
          readOnly={true}
        />
      )}

      <tr className="border-2 rounded-md">
        <td className="px-5 p-2">{category?.name}</td>
        <td className="px-5 p-2">{sub_category?.name}</td>
        <td className="px-5 p-2">{item.brand}</td>
        <td className="px-5 p-2">{item.asset_no}</td>
        <td className="px-5 p-2">{item.serial}</td>
        <td className="px-5 p-2 text-green-600">{item?.status}</td>
        <td className="px-5 p-2 flex gap-1 justify-end">
          <i
            className="border p-2 mt-2 cursor-pointer fa-solid fa-paperclip"
            onClick={sidebarHandler}
          ></i>
          <i
            className="border p-2 mt-2 cursor-pointer fa-solid fa-message"
            onClick={commentSidebarHandler}
          ></i>
          <i
            className="border p-2 mt-2 cursor-pointer fa-solid fa-clock-rotate-left"
            onClick={activitySidebarHandler}
          ></i>
          <i
            className="border p-2 mt-2 cursor-pointer fa-solid fa-eye"
            onClick={() => {
              assetViewHandler();
            }}
          ></i>
          <i
            className="border p-2 mt-2 fa-solid fa-pen cursor-pointer"
            onClick={() => {
              assetAddUpdateHandler();
            }}
          ></i>
          <ShareExport
            data={item}
            icon="border p-2 mt-2 cursor-pointer fa-solid fa-ellipsis-vertical"
            item1="Assign To"
            item2="Handover"
          />
        </td>
      </tr>
    </Fragment>
  );
};

export default AssignAssetDetail;
