import React, { useState, useEffect } from "react";
import "@fontsource/roboto";
import {
  Button,
  Grid,
  Paper,
  Backdrop,
  Checkbox,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  TextareaAutosize,
  Container,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import useAuth from "hooks/useAuth";
import clsx from "clsx";
import Api from "../../hooks/AjaxAction";
import { useResignStyles } from "./Style";
import moment from "moment";
import { Alert } from "@material-ui/lab";
import {
  getResignationByUserId,
  postResignationByUserId,
} from "../../store/actions/EmployeeManagement/Resign";
import { useSelector, useDispatch } from "react-redux";

export const Resign = (props) => {
  const classes = useResignStyles();
  const auth = useAuth();
  const dispatch = useDispatch();
  const { resignation, postResignation } = useSelector(
    (state) => state.resignation
  );
  const initialFormState = {
    date_of_resign: moment(new Date()).format(),
    reason_of_leave: "Personal",
    expected_relieving_date: moment(
      moment(new Date()).add(60, "days")
    ).format(),
    attachment_url: "",
    leaving_comment: "",
    terms_n_conditions: false,
    resignationStatus: "",
    notice_period: "",
  };
  const [minRelievingDate, setMinRelievingDate] = useState(new Date());
  const [resign, setResign] = React.useState(initialFormState);
  const [isResigned, setIsResigned] = useState(false);
  const [isDeclare, setIsDeclare] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileUploadedSuccessfull, setFileUploadedSuccessfull] =
    React.useState(false);

  const getNotice = () => {
    return resign.notice_period ? resign.notice_period + " days" : null;
  };

  const getResignByUser = async (userId) => {
    setIsLoading(true);
    dispatch(getResignationByUserId(userId));
  };
  React.useEffect(() => {
    getResignByUser(auth.user._id);
  }, []);
  useEffect(() => {
    if (resignation?.fulfilled) {
      if (resignation?.fulfilled[0]) {
        setIsResigned(true);
        const resign = resignation?.fulfilled[0];
        setResign({
          date_of_resign: resign.expected_relieving_date,
          reason_of_leave: resign.reason_of_leave,
          expected_relieving_date: resign.expected_relieving_date,
          attachment_url: resign.attachment_url,
          leaving_comment: resign.reason_of_leave,
          terms_n_conditions: resign.terms_n_conditions,
          resignationStatus: resign.resignationStatus,
          notice_period: resign.notice_period,
        });
        setFileName(resign.attachment_file_name);
      }
      setIsLoading(false);
    }
  }, [resignation]);

  const handleInputChange = (event, dateField = null) => {
    const newState = { ...resign };
    if (dateField) {
      let dataConvertedObj = new Date(event);
      if (dateField === "date_of_resign") {
        let resignDate = dataConvertedObj;
        let min_relieving_date = moment(
          moment(dataConvertedObj).add(60, "days")
        ).format();
        newState[dateField] = resignDate;
        newState.expected_relieving_date = min_relieving_date;
        setMinRelievingDate(min_relieving_date);
      } else {
        newState[dateField] = moment(dataConvertedObj).format();
      }
    } else {
      const key = event.target["name"];
      if (key === "terms_n_conditions") {
        newState[key] = !resign.terms_n_conditions;
      } else {
        const value = event.target.value;
        newState[key] = value;
      }
    }
    setResign(newState);
  };

  const handleSubmitQuery = async (e) => {
    if (!isResigned && isDeclare) {
      setIsLoading(true);
      e.preventDefault();
      setSuccess(false);
      setError(false);
      const data = {
        date_of_resign: moment(resign.date_of_resign).format(),
        reason_of_leave: resign.reason_of_leave,
        expected_relieving_date: moment(
          resign.expected_relieving_date
        ).format(),
        attachment_url: resign.attachment_url,
        leaving_comment: resign.reason_of_leave,
        terms_n_conditions: resign.terms_n_conditions,
        attachment_file_name: fileName,
        user_id: auth.user._id,
      };
      dispatch(postResignationByUserId(data));
    }
  };

  const onSelectFile = (files) => {
    setIsLoading(true);
    const onSuccess = (data) => {
      setResign({
        ...resign,
        attachment_url: data.file_url,
      });
      setFileName(data.filename);
      setIsLoading(false);
      setFileUploadedSuccessfull(true);
    };
    const onError = (error) => setIsLoading(false);
    // Upload to S3
    Api.fileUpload(files, "org-glen", auth.token, onSuccess, onError);
  };

  const resetData = () => {
    setFileName("");
    setFileUploadedSuccessfull(false);
    setResign(initialFormState);
  };

  const onSuccess = (data) => {
    setIsLoading(false);
    setSuccess(true);
  };
  const onError = (error) => {
    setIsLoading(false);
    setSuccess(false);
    setError(true);
  };

  useEffect(() => {
    if (postResignation.fulfilled) {
      onSuccess();
      getResignByUser(auth.user._id);
    }
    if (postResignation.rejected) {
      onError();
    }
  }, [postResignation]);

  return (
    <Container>
      <div className={classes.root}>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: props.isDrawerOpen,
          })}
        >
          <Grid
            direction="row"
            style={{ textAlign: "left", padding: "40px 10px 20px 10px" }}
          >
            <Grid direction="row">
              <Grid direction="row">
                <Grid item>
                  <Paper
                    style={{ textAlign: "left" }}
                    className={classes.paper}
                  >
                    <h2 style={{ color: "#1F299C", marginBottom: "30px" }}>
                      Employee profile
                    </h2>
                    <Grid container spacing={1} xs={12} md={12} lg={12}>
                      <Grid item xs={12} md={6} lg={2}>
                        <h3 style={{ marginBottom: "6px", color: "#161b5a" }}>
                          Employee Code
                        </h3>
                        <p style={{ marginTop: "0", fontSize: "18px" }}>
                          {auth.user?.emp_code}
                        </p>
                      </Grid>
                      <Grid item xs={12} md={6} lg={2}>
                        <h3 style={{ marginBottom: "6px", color: "#161b5a" }}>
                          Employee Name
                        </h3>
                        <p style={{ marginTop: "0", fontSize: "18px" }}>
                          {auth.user?.first_name + " " + auth.user?.last_name}
                        </p>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <h3 style={{ marginBottom: "6px", color: "#161b5a" }}>
                          Date Of joining
                        </h3>
                        <p style={{ marginTop: "0", fontSize: "18px" }}>
                          {moment(auth.user?.doj).format("DD-MM-YYYY")}
                        </p>
                      </Grid>
                      <Grid item xs={12} md={6} lg={2}>
                        <h3 style={{ marginBottom: "6px", color: "#161b5a" }}>
                          Notice Period
                        </h3>
                        <p style={{ marginTop: "0", fontSize: "18px" }}>
                          {getNotice() || "60 days"}
                        </p>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <h3 style={{ marginBottom: "6px", color: "#161b5a" }}>
                          Location
                        </h3>
                        <p
                          style={{
                            marginTop: "0",
                            fontSize: "18px",
                            overflowWrap: "break-word",
                            marginBottom: "20px",
                          }}
                        >
                          {auth.user?.permanent_address || "Not provided"}
                        </p>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} xs={12} md={12} lg={12}>
                      <Grid item xs={12} md={6} lg={2}>
                        <h3 style={{ marginBottom: "6px", color: "#161b5a" }}>
                          HR Manager
                        </h3>
                        <p style={{ marginTop: "0", fontSize: "18px" }}>
                          {auth.user?.hr_manager_details?.hr_name ||
                            "Not provided"}
                        </p>
                      </Grid>
                      <Grid item xs={12} md={6} lg={2}>
                        <h3 style={{ marginBottom: "6px", color: "#161b5a" }}>
                          Supervisor
                        </h3>
                        <p style={{ marginTop: "0", fontSize: "18px" }}>
                          {auth.user?.supervisor_details?.sup_name ||
                            "Not provided"}
                        </p>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <h3 style={{ marginBottom: "6px", color: "#161b5a" }}>
                          Official Email ID
                        </h3>
                        <p
                          style={{
                            marginTop: "0",
                            fontSize: "18px",
                            overflowWrap: "break-word",
                          }}
                        >
                          {auth.user?.email}
                        </p>
                      </Grid>
                      <Grid item xs={12} md={6} lg={2}>
                        <h3 style={{ marginBottom: "6px", color: "#161b5a" }}>
                          Last Working Day
                        </h3>
                        <p style={{ marginTop: "0", fontSize: "18px" }}>
                          {resign?.expected_relieving_date || "Not available"}
                        </p>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <h3 style={{ marginBottom: "6px", color: "#161b5a" }}>
                          Confirmation Status
                        </h3>
                        <p
                          style={{
                            marginTop: "0",
                            fontSize: "18px",
                            color:
                              resign?.resignationStatus === "Accepted"
                                ? "#00b500"
                                : resign?.resignationStatus === "Rejected"
                                ? "red"
                                : resign?.resignationStatus === "Pending"
                                ? "	#FFA500"
                                : "grey",
                          }}
                        >
                          {resign?.resignationStatus || "Not availble"}
                        </p>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

                <Grid item style={{ marginTop: "20px" }} disabled={isResigned}>
                  <Paper
                    style={{ textAlign: "left" }}
                    className={classes.paper}
                  >
                    <h2 style={{ color: "#1F299C", marginBottom: "30px" }}>
                      Relieving Information
                    </h2>
                    <Grid xs={12} md={12} lg={12} container spacing={2}>
                      <Grid item xs={12} md={12} lg={4}>
                        <h3 style={{ marginBottom: "6px", color: "#161b5a" }}>
                          Date Of Resign *
                        </h3>
                        <div style={{ marginTop: "0", fontSize: "20px" }}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              disabled={isResigned ? true : false}
                              disableToolbar
                              variant="inline"
                              format="dd/MM/yyyy"
                              margin="normal"
                              id="date-picker-inline"
                              label=""
                              name="date_of_resign"
                              value={resign?.date_of_resign}
                              onChange={(e) =>
                                handleInputChange(e, "date_of_resign")
                              }
                              minDateMessage=""
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={12} lg={4}>
                        <h3 style={{ marginBottom: "5px", color: "#161b5a" }}>
                          Reason For Leaving *
                        </h3>
                        <div style={{ marginTop: "0", fontSize: "20px" }}>
                          <FormControl
                            className={classes.formControl}
                            disabled={isResigned ? true : false}
                          >
                            <InputLabel id="demo-simple-select-label">
                              Select Reason For Leaving
                            </InputLabel>
                            <Select
                              disabled={isResigned ? true : false}
                              labelId=""
                              id="demo-simple-select"
                              name="reason_of_leave"
                              value={resign.reason_of_leave}
                              onChange={(e) => handleInputChange(e)}
                            >
                              <MenuItem value={"personal"}>Personal</MenuItem>
                              <MenuItem value={"study"}>Study</MenuItem>
                              <MenuItem value={"other"}>Other</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={12} lg={4}>
                        <h3 style={{ marginBottom: "6px", color: "#161b5a" }}>
                          Expected Relieving date *
                        </h3>
                        <div style={{ marginTop: "0", fontSize: "20px" }}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              disabled={isResigned ? true : false}
                              disableToolbar
                              variant="inline"
                              format="dd/MM/yyyy"
                              minDate={minRelievingDate}
                              margin="normal"
                              id="date-picker-inline"
                              label=""
                              name="expected_relieving_date"
                              value={resign?.expected_relieving_date}
                              minDateMessage=""
                              onChange={(e) =>
                                handleInputChange(e, "expected_relieving_date")
                              }
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid xs={12} md={12} lg={12} container spacing={2}>
                      <Grid item xs={12} md={6} lg={8}>
                        <h3 style={{ marginBottom: "6px", color: "#161b5a" }}>
                          Leaving Comment *
                        </h3>
                        <TextareaAutosize
                          name="leaving_comment"
                          disabled={isResigned ? true : false}
                          onChange={(e) => handleInputChange(e)}
                          style={{
                            width: "96%",
                            height: "110px",
                            padding: "10px",
                            border: "#161b5a 1px solid",
                          }}
                          value={resign.leaving_comment}
                          aria-label="minimum height"
                          minRows={10}
                          placeholder="Enter Your Remark"
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <h3 style={{ color: "#161b5a", marginBottom: "6px" }}>
                          Attachment *
                        </h3>
                        <label
                          style={{
                            width: "70%",
                            height: "110px",
                            backgroundColor: "#f1f1f1",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "20px",
                          }}
                        >
                          {fileName !== "" ? (
                            <a href={resign.attachment_url}>{fileName} </a>
                          ) : (
                            <p>
                              Drag & Drop Here,{" "}
                              <span style={{ color: "#1250ff" }}>
                                Or Browse File
                              </span>
                            </p>
                          )}
                          <input
                            disabled={isResigned ? true : false}
                            onChange={(e) => onSelectFile(e.target.files)}
                            style={{ display: "none" }}
                            type="file"
                          />
                        </label>
                        {fileUploadedSuccessfull && (
                          <p style={{ color: "#00b500", fontSize: "16px" }}>
                            Successful
                          </p>
                        )}
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

                <Grid item style={{ marginTop: "20px" }}>
                  <Paper
                    style={{ textAlign: "left" }}
                    className={classes.paper}
                  >
                    <h2 style={{ color: "#1F299C", marginBottom: "30px" }}>
                      Declaration
                    </h2>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={8} lg={10}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignContent: "baseline",
                            alignItems: "center",
                          }}
                        >
                          <Checkbox
                            name="terms_n_conditions"
                            checked={resign.terms_n_conditions}
                            onChange={(e) => {
                              handleInputChange(e);
                              setIsDeclare(!isDeclare);
                            }}
                            disabled={isResigned}
                            color="primary"
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                          />
                          <h3 style={{ color: "#161b5a" }}>
                            I Hereby Declare That I Am Submitting My Resignation
                            On My Behalf
                          </h3>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={4} lg={2}>
                        <Grid container>
                          <Grid item xs={12} md={6} lg={6}>
                            <Button
                              onClick={(e) => resetData(e)}
                              disabled={isResigned}
                              color="primary"
                              variant="outlined"
                            >
                              Cancel
                            </Button>
                          </Grid>
                          <Grid item xs={12} md={6} lg={6}>
                            <Button
                              variant="contained"
                              onClick={(e) => handleSubmitQuery(e)}
                              color="primary"
                              disabled={isDeclare && !isResigned ? false : true}
                            >
                              Submit
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
              <Grid direction="row"></Grid>
            </Grid>
            <Grid direction="row" style={{ marginTop: "20px" }}>
              <p style={{ color: "#1F299C" }}>
                <strong>Terms & Conditions</strong> : Lorem Ipsun Dolor Sit
                Amet, Consectetur Adipiscing Elit. Sed Do Elusmod Tempor
                Incididunt Ut Labore Et Dolore
              </p>
            </Grid>
          </Grid>
        </main>
      </div>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar
        open={!!error}
        onClose={() => setError(false)}
        key={"Form Error"}
        autoHideDuration={3000}
      >
        <Alert severity="error">{error}</Alert>
      </Snackbar>

      <Snackbar
        open={!!success}
        onClose={() => setSuccess(false)}
        key={"Form Success"}
        autoHideDuration={3000}
      >
        <Alert severity="success">Submitted</Alert>
      </Snackbar>
    </Container>
  );
};
