import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import useAuth from "hooks/useAuth";
import moment from "moment";

const initialState = {
    isLoading: false,
    message: "",
    access_token: "",
    orgUsers:[],
    employees: [],
    users: [],
    resignation: [],
    communication: [],
    leaves: [],
    shifts: [],
    assignedTo: []
};

export const getUsersByOrgId = createAsyncThunk(
    "dashboard/getUsers",
    async (payload) => {
        // const auth = useAuth();
        // const orgId = auth.user.org_id._id;
        // console.log("orgid=====",orgId);
        
        const response = await axios.get(`${
            process.env.REACT_APP_API_BASE_URL
          }/users/getbyorg/${payload.params.orgId}?page_number=${""}&limit=${""}`,
            );
            console.log(response);
            return response.data.data;
        
    }
)


export const getAllResignation = createAsyncThunk(
    "resignation",
    async (payload) => {
        console.log(payload,"employees");

        let token = localStorage.getItem("token");
        const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/resignation/getAllResignations`,
            {
              headers: {
                token: token,
              },
            }
          );
            console.log(response);
            return response.data.data;
        
    }
)

export const updateResignationDetails = createAsyncThunk(
    "resignation/update",
    async (payload) => {

        let token = localStorage.getItem("token");
        const response = await axios.put(
            `${process.env.REACT_APP_API_BASE_URL}/resignation/update`,
            payload,
            {
              headers: {
                token: token,
              },
            }
          );
            console.log(response);
            return response.data.data;
        
    }
)



export const updateEmployees = createAsyncThunk(
    "myEmployees/update",
    async (payload) => {
        
        const response = await axios.put(`${
            process.env.REACT_APP_API_BASE_URL
          }/users/updateStatus`,
          payload
            );
            console.log("my response",response);
            return response.data.data;
        
    }
)


export const updateUsers = createAsyncThunk(
    "users/update",
    async (payload) => {
        console.log(payload);
        const response = await axios.put(`${
            process.env.REACT_APP_API_BASE_URL
          }/users/update`,
          payload.body
            );
            console.log("my response",response);
            return response.data.data;
        
    }
)

export const getAllQueryRequests = createAsyncThunk(
    "query/getRequests",
    async () => {

        let token = localStorage.getItem("token");
        const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/requests/getAllRequests`,
            {
            headers: {
                token:token,
            },
            }
        );
            // console.log("query requests=====",response);
            return response.data.data;
        
    }
)


export const getAllCommunication = createAsyncThunk(
    "messages/getAll",
    async () => {

            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/communication/getAllMessages`);

            console.log("message requests=====",response);
            return response;
        
    }
)

export const createCommunication = createAsyncThunk(
    "messages/create",
    async (payload) => {

        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/communication/create`, payload);

            console.log("create message =====",response);
            return response;
        
    }
)

export const editCommunication = createAsyncThunk(
    "messages/edit",
    async (payload) => {

        const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/communication/update/${payload.params.message_id}`, payload.messageData);

            console.log("edit message =====",response);
            return response;
        
    }
)

export const deleteCommunication = createAsyncThunk(
    "messages/delete",
    async (payload) => {

        const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/communication/deleteMessage/${payload.params.message_id}`);


            console.log("delete message =====",response);
            return response;
        
    }
)

export const getLeavesApproval = createAsyncThunk(
    "leaves/approve",
    async (payload) => {
        let token = localStorage.getItem("token");

        const response = await axios.get(
              `${
                process.env.REACT_APP_API_BASE_URL
              }/leaves/getAllLeaves?page_number=${payload.params.page_number + 1}&limit=${payload.params.limit}`,
              {
                headers: {
                  token: token,
                },
              }
            );

            console.log("get all leaves =====",response);
            return response;
        
    }
)

export const getAttendenceByOrg = createAsyncThunk(
    "attendence/org",
    async (payload) => {
        let token = localStorage.getItem("token");

        const response = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/attendance/getbyorg/${
                //   auth.user.org_id._id,
                payload.params.org_id
                }?year=${""}&page_number=${payload.params.page_number + 1}&limit=${payload.params.limit}`,
                {
                  headers: {
                    token:token,
                  },
                }
              );
            console.log("new get all leaves =====",response);
            return response;
        
    }
)

export const getUsersByShift = createAsyncThunk(
    "shift/users",
    async (payload) => {
        const response = await axios
          .get(
            `${process.env.REACT_APP_API_BASE_URL}/shift/getbyuserid/${
              payload.params.user_id
            }?from=${moment().format("YYYY-MM-DD")}`
          );
          
            console.log("new get all shifts =====",response);
            return response;
        
    }
)

export const assignedToUpdate = createAsyncThunk(
    "assignedTo/update",
    async (payload) => {
        let token = localStorage.getItem("token");

        const response = await axios.put(
            `${process.env.REACT_APP_API_BASE_URL}/requests/update`,
            payload,
            {
              headers: {
                token: token,
              },
            }
          );
          
            return response;
        
    }
)




export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(getUsersByOrgId.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(getUsersByOrgId.fulfilled, (state, {payload}) => {
            state.isLoading = false;
            state.orgUsers= payload.data;
            // state.access_token = payload.access_token;
            console.log("Payload====",payload);
        })
        .addCase(getUsersByOrgId.rejected, (state, {payload}) => {
            state.isLoading = false;
            // state.message = payload.message;
            console.log("rejected payload======",payload)
            // state.access_token = payload.access_token;
        })
        .addCase(updateEmployees.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(updateEmployees.fulfilled, (state, {payload}) => {
            state.isLoading = false;
            state.employees= payload.data;
            // state.access_token = payload.access_token;
            console.log("Payload====",payload);
        })
        .addCase(updateEmployees.rejected, (state, {payload}) => {
            state.isLoading = false;
            // state.message = payload.message;
            console.log("rejected payload======",payload)
            // state.access_token = payload.access_token;
        })
        .addCase(updateUsers.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(updateUsers.fulfilled, (state, {payload}) => {
            state.isLoading = false;
            state.updateUsers= payload.data;
            // state.access_token = payload.access_token;
            console.log("Payload update users====",payload);
        })
        .addCase(updateUsers.rejected, (state, {payload}) => {
            state.isLoading = false;
            // state.message = payload.message;
            console.log("rejected payload uswersss======",payload)
            // state.access_token = payload.access_token;
        })

        .addCase(getAllResignation.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(getAllResignation.fulfilled, (state, {payload}) => {
            state.isLoading = false;
            state.resignation= payload.data;
            // state.access_token = payload.access_token;
            console.log("Payload update resign====",payload);
        })
        .addCase(getAllResignation.rejected, (state, {payload}) => {
            state.isLoading = false;
            // state.message = payload.message;
            console.log("rejected payload resig======",payload)
            // state.access_token = payload.access_token;
        })
        .addCase(updateResignationDetails.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(updateResignationDetails.fulfilled, (state, {payload}) => {
            state.isLoading = false;
            state.resignation= payload.data;
            // state.access_token = payload.access_token;
            console.log("Payload update resign====",payload);
        })
        .addCase(updateResignationDetails.rejected, (state, {payload}) => {
            state.isLoading = false;
            // state.message = payload.message;
            console.log("rejected payload resign======",payload)
            // state.access_token = payload.access_token;
        })
        .addCase(getAllCommunication.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(getAllCommunication.fulfilled, (state, {payload}) => {
            state.isLoading = false;
            state.resignation= payload.data;
            // state.access_token = payload.access_token;
            console.log("Payload getall messages====",payload);
        })
        .addCase(getAllCommunication.rejected, (state, {payload}) => {
            state.isLoading = false;
            // state.message = payload.message;
            console.log("rejected payload messages======",payload)
            // state.access_token = payload.access_token;
        })
        .addCase(createCommunication.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(createCommunication.fulfilled, (state, {payload}) => {
            state.isLoading = false;
            state.communication= payload.data;
            // state.access_token = payload.access_token;
            console.log("Payload create messages====",payload);
        })
        .addCase(createCommunication.rejected, (state, {payload}) => {
            state.isLoading = false;
            // state.message = payload.message;
            console.log("rejected payload messages======",payload)
            // state.access_token = payload.access_token;
        })
        .addCase(editCommunication.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(editCommunication.fulfilled, (state, {payload}) => {
            state.isLoading = false;
            state.communication= payload.data;
            // state.access_token = payload.access_token;
            console.log("Payload update messages====",payload);
        })
        .addCase(editCommunication.rejected, (state, {payload}) => {
            state.isLoading = false;
            // state.message = payload.message;
            console.log("rejected payload messages======",payload)
            // state.access_token = payload.access_token;
        })
        .addCase(deleteCommunication.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(deleteCommunication.fulfilled, (state, {payload}) => {
            state.isLoading = false;
            state.communication= payload.data;
            // state.access_token = payload.access_token;
            console.log("Payload delete messages====",payload);
        })
        .addCase(deleteCommunication.rejected, (state, {payload}) => {
            state.isLoading = false;
            // state.message = payload.message;
            console.log("rejected payload messages======",payload)
            // state.access_token = payload.access_token;
        })

       
        .addCase(getAttendenceByOrg.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(getAttendenceByOrg.fulfilled, (state, {payload}) => {
            state.isLoading = false;
            state.leaves= payload.data;
            // state.access_token = payload.access_token;
            console.log("Payload attendance by org====",payload);
        })
        .addCase(getAttendenceByOrg.rejected, (state, {payload}) => {
            state.isLoading = false;
            // state.message = payload.message;
            console.log("rejected payload attendance======",payload)
            // state.access_token = payload.access_token;
        })
        .addCase(getUsersByShift.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(getUsersByShift.fulfilled, (state, {payload}) => {
            state.isLoading = false;
            state.shifts= payload.data;
            // state.access_token = payload.access_token;
            console.log("Payload shifts by org====",payload);
        })
        .addCase(getUsersByShift.rejected, (state, {payload}) => {
            state.isLoading = false;
            // state.message = payload.message;
            console.log("rejected payload shifts======",payload)
            // state.access_token = payload.access_token;
        })

        .addCase(assignedToUpdate.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(assignedToUpdate.fulfilled, (state, {payload}) => {
            state.isLoading = false;
            state.assignedToUpdate= payload.data;
            // state.access_token = payload.access_token;
            console.log("Payload assigned to fullfiled====",payload);
        })
        .addCase(assignedToUpdate.rejected, (state, {payload}) => {
            state.isLoading = false;
            // state.message = payload.message;
            console.log("rejected payload assigned to======",payload)
            // state.access_token = payload.access_token;
        })
    }
})

export const { isGetUsersPending, isGetUsersSuccess, isGetUsersFailed} = 
dashboardSlice.actions;

export default dashboardSlice.reducer