import axios from "axios";
import { Urls } from "store/urls";

export const getAllAssetResignationsRepo = () => {
  return axios({
    method: "GET",
    url: Urls.GET_ALL_ASSET_RESIGNATIONS,
  });
};

