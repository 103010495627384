import React, { useEffect, useState } from 'react';
import {
  Typography,
  InputBase,
  InputLabel,
  Select,
  Button,
  Menu,
  Dialog,
  DialogContent,
  MenuItem,
  FormControl,
  IconButton,
  Paper,
  Grid,
  TablePagination,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import clsx from 'clsx';
import '@fontsource/roboto';
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { useDasboardStyles } from './Style';
import SearchIcon from '@material-ui/icons/Search';
import { MoreHoriz } from '@material-ui/icons';
import AddTagDialog from './AddTagDialog';
// import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteShiftTag,
  serachShiftTags,
} from 'store/actions/ShiftManagement/ShiftTags';

export function STags(props) {
  let theme = createTheme();
  theme = responsiveFontSizes(theme);
  const classes = useDasboardStyles();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [tagsData, setTagsData] = useState([]);
  // const [filteredTasks, setFilteredTasksData] = useState([]);
  const [tagSelected, setTagSelected] = React.useState({});
  const [dialogOperation, setDialogOperation] = useState('');
  const [readOnly, setReadOnly] = useState(false);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);
  const [searchKey, setSearchKey] = useState('');
  const [status, setStatus] = useState('true');
  const [totalLength, setTotalLength] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { shiftTags, loading } = useSelector((state) => state.shiftTags);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    handleSearch({
      status,
      limit,
      page: newPage,
      searchKey,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    let l = parseInt(event.target.value, 10);
    setLimit(l);
    setPage(0);
    handleSearch({
      status,
      limit: l,
      page: 0,
      searchKey,
    });
  };

  // --- menu config -------------

  const handleClick = (event, props) => {
    setAnchorEl(event.currentTarget);
    setTagSelected(props);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // ----------------------------

  // ----------- options config -----------------

  const handleCreateDialogOpen = () => {
    handleClose();
    setDialogOperation('create');
    setReadOnly(false);
    setOpenDialog(true);
  };

  const handleViewDialogOpen = () => {
    handleClose();
    setDialogOperation('view');
    setReadOnly(true);
    setOpenDialog(true);
  };
  const handleEditDialogOpen = () => {
    handleClose();
    setDialogOperation('edit');
    setReadOnly(false);
    setOpenDialog(true);
  };

  const handleDeleteTag = async () => {
    dispatch(deleteShiftTag(tagSelected._id));
    handleClose();
  };

  const handleCloseDialog = () => {
    handleClose();
    setOpenDialog(false);
  };

  const handleSearch = (obj) => {
    let stat = obj?.status,
      search = obj?.searchKey;
    if (!stat) stat = status;
    else if (stat === 'all') stat = '';

    if (!search) search = searchKey;
    else if (search === 'all') search = '';

    let data = {
      status: stat,
      page: obj?.page || page,
      limit: obj?.limit || limit,
      searchKey: search,
    };
    dispatch(serachShiftTags(data));
  };

  useEffect(() => {
    window.scroll(0, 0);
    handleSearch();
  }, []);

  useEffect(() => {
    if (shiftTags?.fulfilled) {
      setTotalLength(shiftTags.totalLength);
      setTagsData(shiftTags.fulfilled);
      setIsLoading(false);
    }
  }, [shiftTags]);

  useEffect(() => {
    // setIsLoading(loading);
  }, [loading]);

  return (
    <React.Fragment>
      <div className='root'>
        <ThemeProvider theme={theme}>
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: props.isDrawerOpen,
            })}
          >
            <Typography
              style={{
                textAlign: 'left',
                marignBottom: '20px',
                minWidth: '230px',
              }}
            >
              Home {'>'} Shift Management {'>'} Tags
            </Typography>

            <Paper
              style={{
                padding: '20px 20px 50px 20px',
                minWidth: '230px',
                marginTop: '20px',
                minHeight: '800px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  marginBottom: '10px',
                  position: 'relative',
                }}
              >
                <FormControl
                  variant='outlined'
                  style={{
                    minWidth: '225px',
                    marginRight: '20px',
                    marginBottom: '10px',
                  }}
                >
                  <InputLabel id='demo-simple-select-label-1'>
                    Select Status
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label-1'
                    label='Schedules'
                    id='demo-simple-select'
                    defaultValue={'true'}
                    onChange={(event) => {
                      if (event.target.value === 'All') {
                        setStatus('');
                        handleSearch({
                          status: 'all',
                        });
                      } else {
                        setStatus(event.target.value);
                        handleSearch({
                          status: event.target.value,
                        });
                      }
                    }}
                  >
                    <MenuItem value='All'>All</MenuItem>
                    <MenuItem value={'true'}>Active</MenuItem>
                    <MenuItem value={'false'}>Inactive</MenuItem>
                  </Select>
                </FormControl>

                <Paper
                  style={{
                    width: '275px',
                    minWidth: '225px',
                    height: '56px',
                    backgroundColor: 'rgb(248,248,248)',
                    marginRight: '50px',
                    marginBottom: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <InputBase
                    sx={{ flex: 1 }}
                    placeholder='  Search...'
                    onChange={(event) => {
                      const searchTerm = event.target.value.toString();
                      if (searchTerm.length < 1) {
                        setSearchKey('');
                        handleSearch({
                          searchKey: 'all',
                        });

                        return;
                      }
                      setSearchKey(searchTerm);
                      handleSearch({
                        searchKey: searchTerm,
                      });
                    }}
                  />
                  <IconButton
                    style={{ paddingleft: '-5px', paddingRight: '0px' }}
                    aria-label='search'
                  >
                    <SearchIcon
                      style={{ paddingleft: '-5px', paddingRight: '0px' }}
                    />
                  </IconButton>
                </Paper>

                <Button
                  variant='contained'
                  style={{
                    color: 'white',
                    backgroundColor: 'rgb(31, 41, 156)',
                    height: '56px',
                    marginLeft: 'auto',
                  }}
                  onClick={handleCreateDialogOpen}
                >
                  <span style={{ fontSize: '1.5em' }}>+</span> &nbsp; Add Tags
                </Button>
              </div>

              <hr
                style={{
                  margin: '20px -20px',
                }}
              ></hr>

              {/* apply mapping here ------------------ */}
              {tagsData && tagsData.length ? (
                tagsData.map((tag, idx) => {
                  return (
                    <React.Fragment key={tag._id}>
                      <Grid container spacing={2}>
                        <Grid item xs={11} style={{ textAlign: 'left' }}>
                          <Grid
                            container
                            justifyContent='space-between'
                            spacing={2}
                            style={{
                              paddingLeft: '10px',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Grid item xs={12} lg={10}>
                              <Typography
                                variant='h6'
                                style={{
                                  fontWeight: '600',
                                  textAlign: 'left',
                                }}
                              >
                                {tag.tag_title}
                              </Typography>
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              lg={2}
                              style={{
                                margin: 'auto 0px',
                                display: 'flex',
                              }}
                            >
                              <div
                                style={
                                  tag.status === 'true'
                                    ? {
                                        width: '20px',
                                        height: '20px',
                                        borderRadius: '50%',
                                        backgroundColor: 'lightGreen',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                      }
                                    : {
                                        width: '20px',
                                        height: '20px',
                                        borderRadius: '50%',
                                        backgroundColor: 'lightGrey',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                      }
                                }
                              >
                                <div
                                  style={
                                    tag.status === 'true'
                                      ? {
                                          width: '10px',
                                          height: '10px',
                                          borderRadius: '50%',
                                          backgroundColor: 'green',
                                        }
                                      : {
                                          width: '10px',
                                          height: '10px',
                                          borderRadius: '50%',
                                          backgroundColor: 'grey',
                                        }
                                  }
                                ></div>
                              </div>
                              &nbsp;&nbsp;
                              <Typography
                                style={
                                  tag.status === 'true'
                                    ? {
                                        color: 'green',
                                      }
                                    : {
                                        color: 'grey',
                                      }
                                }
                                variant='subtitle2'
                              >
                                {tag.status === 'true' ? 'Active' : 'Inactive'}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          xs={1}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <IconButton
                            style={{ padding: '0px' }}
                            id='basic-button'
                            aria-controls='basic-menu'
                            aria-haspopup='true'
                            aria-expanded={open ? 'true' : undefined}
                            onClick={(event) => handleClick(event, tag)}
                          >
                            <MoreHoriz style={{ padding: '0px' }} />
                          </IconButton>

                          <Menu
                            id='basic-menu'
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                              'aria-labelledby': 'basic-button',
                            }}
                          >
                            <MenuItem onClick={handleViewDialogOpen}>
                              More Details
                            </MenuItem>
                            <MenuItem onClick={handleEditDialogOpen}>
                              Edit
                            </MenuItem>
                            <MenuItem onClick={handleDeleteTag}>
                              Delete
                            </MenuItem>
                          </Menu>
                        </Grid>
                      </Grid>

                      <hr
                        style={{
                          marginTop: '20px',
                        }}
                      ></hr>
                    </React.Fragment>
                  );
                })
              ) : (
                <div style={{ width: '100%' }}>
                  <p style={{ color: 'grey', marign: '2rem auto' }}>
                    <i>No Tags found!</i>
                  </p>
                </div>
              )}
              {/* map till here ---------------- */}
              {totalLength ? (
                <TablePagination
                  style={{ backgroundColor: '#F7F8FC' }}
                  rowsPerPageOptions={[25, 50]}
                  component='div'
                  count={totalLength}
                  rowsPerPage={limit}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              ) : (
                <></>
              )}
              <Dialog
                open={openDialog}
                maxWidth='sm'
                fullWidth
                // TransitionComponent={Transition}
                keepMounted
                aria-describedby='alert-dialog-slide-description'
                PaperProps={{
                  style: {
                    position: 'absolute',
                    margin: '0px',
                    padding: '0px',
                    right: '0px',
                    minHeight: '111vh',
                    top: '0px',
                    borderRadius: '0px',
                  },
                }}
              >
                <DialogContent
                  style={{
                    margin: '0px',
                    padding: '0px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <AddTagDialog
                    operation={dialogOperation}
                    closeForm={handleCloseDialog}
                    tag={tagSelected}
                    readOnly={readOnly}
                    afterOpn={handleSearch}
                  />
                </DialogContent>
              </Dialog>
            </Paper>
          </main>
        </ThemeProvider>
      </div>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </React.Fragment>
  );
}

// export default SM_Dashboard;
