import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  postAttendaceBreakout,
  postAttendaceBreakin,
} from "store/actions/EmployeeManagement/HomePage";

const BreakInOut = (props) => {
  const {
    auth,
    canBreakOut,
    setCanBreakOut,
    setIsLoading,
    todaysAtt,
    setTodaysAtt,
    setTodaysHrs,
    setDisplayMessage,
    setDisplayError,
    setOpenAttResponseModal,
  } = props;
  const dispatch = useDispatch();
  const { attendanceBreakout, attendanceBreakin } = useSelector(
    (state) => state.homepage
  );

  const [confirmBreakIn, isConfirmBreakIn] = useState(false);
  const [confirmBreakOut, isConfirmBreakOut] = useState(false);
  const handleBreakStart = async () => {
    setIsLoading(true);
    if (Object.keys(todaysAtt).length > 0) {
      if (todaysAtt.can_breakout) {
        let punchinDetails = todaysAtt.punch_details;
        let lastPunchin = punchinDetails[punchinDetails.length - 1];
        let punchInId = lastPunchin._id;
        const userId = auth.user._id;
        const date = new Date();
        let day = date.getDate();
        let month = date.getMonth();
        let year = date.getFullYear();
        let params = {
          punchin_id: punchInId,
          emp_id: userId,
          day: `${day}`,
          month: `${month}`,
          year: `${year}`,
        };
        dispatch(postAttendaceBreakout(params));
      }
    }
    setOpenAttResponseModal(true);
    isConfirmBreakOut(false);
  };

  const handleBreakEnd = async () => {
    setIsLoading(true);
    if (Object.keys(todaysAtt).length > 0) {
      if (todaysAtt.can_breakin) {
        let punchinDetails = todaysAtt.punch_details;
        let lastPunchin = punchinDetails[punchinDetails.length - 1];
        let punchInId = lastPunchin._id;
        let lastbreakOut =
          todaysAtt.break_details[todaysAtt.break_details.length - 1];
        let breakOutId = lastbreakOut._id;
        const userId = auth.user._id;
        const date = new Date();
        let day = date.getDate();
        let month = date.getMonth();
        let year = date.getFullYear();
        dispatch(postAttendaceBreakin());

        setOpenAttResponseModal(true);
        isConfirmBreakIn(false);
      }
    }
  };

  useEffect(() => {
    if (attendanceBreakout.fullfilled) {
      setIsLoading(false);
      const data = attendanceBreakout.fullfilled;
      setTodaysAtt(data);
      setTodaysHrs(data.duration_in_hrs);
      setCanBreakOut(false);
      setDisplayMessage("Break Start Successful!");
      setDisplayError("");
    }
    if (attendanceBreakout.rejected) {
      setIsLoading(false);
      setDisplayError(attendanceBreakout.rejected);
    }
  }, [attendanceBreakout]);
  useEffect(() => {
    if (attendanceBreakin.fullfilled) {
      setIsLoading(false);
      const data = attendanceBreakin.fullfilled;
      setTodaysAtt(data);
      setTodaysHrs(data.duration_in_hrs);
      setCanBreakOut(true);
      setDisplayMessage("Break Start Successful!");
      setDisplayError("");
    }
    if (attendanceBreakin.rejected) {
      setIsLoading(false);
      setDisplayError(attendanceBreakin.rejected);
    }
  }, [attendanceBreakin]);
  return (
    <Dialog
      open={confirmBreakOut || confirmBreakIn}
      onClose={() => {
        isConfirmBreakIn(false);
        isConfirmBreakOut(false);
      }}
      aria-labelledby="form-dialog-title"
      style={{ padding: "24px", justifyContent: "center" }}
    >
      <DialogContent>
        <DialogContentText style={{ padding: "12px" }}>
          Do You Want To <b> {canBreakOut ? "Break Start" : "Break End"}</b>{" "}
          Your Attendance ?
        </DialogContentText>
      </DialogContent>
      <br></br>
      <DialogActions>
        <Button
          variant="contained"
          style={{
            color: "#1F299C",
            border: "1px solid #1F299C",
            backgroundColor: "white",
          }}
          onClick={() => {
            isConfirmBreakIn(false);
            isConfirmBreakOut(false);
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          style={{ backgroundColor: "#1F299C", color: "white" }}
          color="primary"
          onClick={confirmBreakOut ? handleBreakStart : handleBreakEnd}
        >
          Yes
        </Button>
      </DialogActions>
      <br></br>
      <br></br>
    </Dialog>
  );
};
export default BreakInOut;
