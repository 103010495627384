import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  TextField,
  // FormControlLabel,
  OutlinedInput,
  Box,
  Chip,
  TextareaAutosize,
  FormControlLabel,
  Switch,
  Snackbar,
} from '@material-ui/core';
import axios from 'axios';
import useAuth from 'hooks/useAuth';
import 'react-phone-input-labelled/dist/style.css';
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import { differenceInHours, parse } from 'date-fns';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import { withStyles } from '@material-ui/styles';
// import { async } from "@firebase/util";
import Api from 'hooks/AjaxAction';
import { green } from '@material-ui/core/colors';
import { Alert } from '@material-ui/lab';
import moment from 'moment/moment';
import { CloudDownload, CloudUpload, ContactSupportOutlined, GetApp } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllTemplates,
  getAllShiftPostions,
  getAllShiftTaskJobs,
  getAllShiftTags,
  getAllShiftJobSites,
} from '../../../store/actions/ShiftManagement/ShiftTemplates';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { getUtcDateWithZone, getZoneTimeInLocalTime, timezones } from 'utils';

const breakHoursDropdown = [];
for (let start = 15; start <= 120; start += 15) {
  breakHoursDropdown.push(`${start} mins`);
}

// --------- CssTextFeild ----------------
const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .Mui-disabled': {
      color: 'black',
    },

    '& .MuiInputBase': {
      background: '#F7F8FC',
      backgroundColor: '#F7F8FC',
    },
  },
})(TextField);

const GreenSwitch = withStyles({
  switchBase: {
    color: green[300],
    '&$checked': {
      color: green[500],
    },
    '&$checked + $track': {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

function AssignShiftDialog(props) {
  const auth = useAuth();
  const dispatch = useDispatch();
  const [scheduleData, setScheduledata] = useState([]);
  const [jobSites, setJobSites] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [allshiftTemplates, setAllShiftTemplates] = useState([]);
  const [shiftTemplatesByJobSite, setAllShiftTemplatesByJobSite] = useState([]);
  const [projectSelected, setProjectSelected] = useState('');
  const [jobSiteSelected, setJobSiteSelected] = useState('');
  const [employeeSelected, setEmployeeSelected] = useState('');
  const [shiftTemplateSelected, setShiftTemplateSelected] = useState('default');
  const [shiftTemplateSelectedObject, setShiftTemplateSelectedObject] =
    useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [customeTemplate, setCustomTemplate] = useState(false);
  const [formError, setFormError] = useState('');
  const [customeTemplateData, setCustomTemplateData] = useState({
    name: '',
    startTime: new Date(),
    endTime: new Date(),
    breakType: '',
    breakTime: '',
    tags: [],
    shiftTask: [],
    notes: '',
    instructions: '',
    attachment: '',
  });

  const [fileUploadSuccess, setFileUploadedSuccessfull] = useState(false);
  const [allPositions, setAllPositions] = useState([]);
  const [allTimezones, setAllTimeZone] = useState([...timezones]);

  const [timezone, setTimeZone] = useState('Vancouver/Canada');
  const [position, setPosition] = useState([]);
  const [requireApproval, setRequiredApproval] = useState(false);
  const [allTags, setAllTags] = useState([]);
  const [allShiftTasks, setAllShiftTasks] = useState([]);
  const [fileName, setFileName] = useState('');
  const [inputTitle, setInputTitle] = useState('Shift Name');
  const [approvedHours, setApprovedHours] = useState(0);

  const { templates, positions, taskJobs, tags } = useSelector(
    (state) => state.shiftTemplates
  );

  const onSelectFile = (files) => {
    props.handleLoading(true);

    const onSuccess = (data) => {
      setCustomTemplateData({
        ...customeTemplateData,
        attachment: data.file_url,
      });
      setFileName(data.filename);
      props.handleLoading(false);
      setFileUploadedSuccessfull(true);
    };

    const onError = (error) => {
      console.log('Error: ', error);
      props.handleLoading(false);
    };
    // Upload to S3
    Api.fileUpload(files, 'org-glen', auth.token, onSuccess, onError);
  };

  const getAllShiftTemplates = async () => {
    dispatch(getAllTemplates());
    getAllPositions();
    getAllTags();
    getAllShiftTasks();
  };

  const getAllShiftTasks = async () => {
    await dispatch(getAllShiftTaskJobs());
  };

  const handleCreateTemplate = async () => {
    let tag_ids_arr = customeTemplateData.tags.map((tag) => tag._id);
    let task_ids_arr = customeTemplateData.shiftTask.map((task) => task._id);
    const data = {
      active: 'true',
      shift_template_title: customeTemplateData.name,
      start_time: moment(customeTemplateData.startTime)
        .format('h:mm a')
        .toString(),
      end_time: moment(customeTemplateData.endTime).format('h:mm a').toString(),
      breck: customeTemplateData.breakType,
      breck_time: customeTemplateData.breakTime,
      notes: customeTemplateData.notes,
      attachment: customeTemplateData.attachment,
      color_code: '',
      tags: [...tag_ids_arr],
      schedule: jobSiteSelected,
      shift_task: [...task_ids_arr],
      isCustom: true,
    };
    // try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/shiftTemplate/create`,
      data,
      {
        headers: {
          token: auth.token,
        },
      }
      // }
      // } catch (e) {
      //   if (e.response) {
      //     console.log("Error >", e.response);
      //   }
      // }
    );
  };

  const handleAssignShift = async () => {
    try {
      setFormError('');
      props.handleLoading(true);
      if (
        // shiftTemplateSelected === "default" ||
        !employeeSelected ||
        !projectSelected ||
        !jobSiteSelected ||
        !position ||
        !startDate ||
        !endDate
        // !customeTemplateData.name ||
        // !customeTemplateData.breakTime ||
        // !customeTemplateData.breakType ||
        // !customeTemplateData.startTime ||
        // !customeTemplateData.endTime
        // !customeTemplateData.shiftTask.length
      ) {
        setFormError('Values required');
        props.handleLoading(false);
        return;
      } else {
        let durationInHr = differenceInHours(endDate, startDate);
        if (parseInt(durationInHr) === 0) {
          setFormError("Start time and End time can't be the same");
          props.handleLoading(false);
          return;
        } else if (parseInt(durationInHr) < 0) {
          setFormError('Invalid time duration');
          props.handleLoading(false);
          return;
        }
      }

      let tag_ids_arr = customeTemplateData.tags.map(
        (tag) => tag.key || tag._id
      );
      let task_ids_arr = customeTemplateData.shiftTask.map(
        (task) => task._id || task.key
      );

      const orgId = auth.user.org_id._id;

      let startDateTime = getUtcDateWithZone(
        customeTemplateData.startTime,
        timezone
      );
      let endDateTime = getUtcDateWithZone(
        customeTemplateData.endTime,
        timezone
      );

      const isshiftTemplateSelectedObject =
        shiftTemplateSelectedObject && shiftTemplateSelectedObject.length;

      let payloadData = {
        employee_id: [employeeSelected],
        org_id: orgId,
        name: customeTemplateData.name
          ? customeTemplateData.name
          : isshiftTemplateSelectedObject
            ? shiftTemplateSelectedObject[0]?.shift_template_title
              ? shiftTemplateSelectedObject[0]?.shift_template_title
              : ''
            : '',
        project_id: projectSelected,
        start_time: startDateTime,
        end_time: endDateTime,
        type: 'test',
        instructions: customeTemplateData.instructions
          ? customeTemplateData.instructions
          : isshiftTemplateSelectedObject
            ? shiftTemplateSelectedObject[0]?.notes
              ? shiftTemplateSelectedObject[0].notes
              : ''
            : '',
        require_approval: !!requireApproval,
        position: position,
        shift_template_title: customeTemplateData.name,
        breck: customeTemplateData.breakType,
        breck_time: customeTemplateData.breakTime,
        color_code: '',
        tags: tag_ids_arr && tag_ids_arr.length ? [...tag_ids_arr] : [],
        jobsite: jobSiteSelected,
        shift_task:
          task_ids_arr && task_ids_arr.length ? [...task_ids_arr] : [],
        admin_notes: customeTemplateData.notes,
        attachment: customeTemplateData.attachment,
        timezone,
        approved_hours: String(approvedHours),

      };
      try {
        let response;
        if (props.operation === 'edit') {
          payloadData._id = props.shiftData._id;
          response = await axios.put(
            `${process.env.REACT_APP_API_BASE_URL}/shift/update`,
            payloadData,
            {
              headers: {
                token: auth.token,
              },
            }
          );
        } else {
          payloadData.new_shift_template = false;
          if (shiftTemplateSelected === 'custom') {
            payloadData.new_shift_template = true;
          }
          payloadData.start_to_end_time = moment(customeTemplateData.startTime).format('hh:mm a') + '-' + moment(customeTemplateData.endTime).format('hh:mm a');
          response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/shift/assign`,
            payloadData,
            {
              headers: {
                token: auth.token,
              },
            }
          );

        }
        if (response.status === 200) {
          props.handleLoading(false);
          props.afterOpn();
        }
      } catch (e) {
        if (e.response) {
          console.log('Error >', e.response);
          setFormError(
            'Something went wrong while assigning shift. Pls try again'
          );
          props.setAssignShiftError(
            e.response?.data?.error ||
            'Something went wrong while assigning shift. Pls try again'
          );
        }
        props.handleLoading(false);
      }
      handleCloseDialog();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getAllShiftTemplates();
    setScheduledata(props.scheduleData);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (templates.fulfilled) {
      if (props.operation === 'edit') {
        let shifts = templates.fulfilled.data.filter(
          (i) => i.schedule?._id === props.shiftData.jobsite
        );
        setAllShiftTemplatesByJobSite(shifts);
      }
      setAllShiftTemplates(templates.fulfilled.data);
    }
  }, [templates]);

  useEffect(() => {
    if (positions.fulfilled) {
      setAllPositions(positions.fulfilled);
    }
  }, [positions]);

  useEffect(() => {
    if (taskJobs.fulfilled) {
      setAllShiftTasks(taskJobs.fulfilled);
    }
    if (tags.fulfilled) {
      setAllTags(tags.fulfilled.data);
    }
    if (taskJobs.loading === false && tags.loading === false) {
      props.handleLoading(false)
    }
  }, [taskJobs, tags]);


  const handleCloseDialog = () => {
    props.closeForm();
  };

  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  const selectProject = (projectID) => {
    setProjectSelected(projectID);
    let prj = scheduleData.find((i) => i._id === projectID);
    setJobSites(prj?.jobsites || []);
  };

  const selectJobSite = (jobsiteID) => {
    setJobSiteSelected(jobsiteID);
    let jbs = jobSites.find((i) => i._id === jobsiteID);
    let shifts = allshiftTemplates.filter((i) => i.schedule?._id === jobsiteID);
    setEmployees(jbs?.assignedEmployees || []);
    setAllShiftTemplatesByJobSite(shifts);
    setShiftTemplateSelected('');
    setShiftTemplateSelectedObject([]);
  };

  const selectEmployee = (empId) => {
    setEmployeeSelected(empId);
  };

  const getFileNameFromAwsUrl = (fileUrl) => {
    let str = String(fileUrl).substring(String(fileUrl).lastIndexOf('/') + 1);
    return str;
  }


  const selectShiftTemplate = async (shiftTemplateID) => {
    try {
      setShiftTemplateSelected(shiftTemplateID);
      let shift = allshiftTemplates.find((i) => i._id === shiftTemplateID);
      setShiftTemplateSelectedObject(shift);
      setFileName(getFileNameFromAwsUrl(shift?.attachment));

      if (props.operation === 'edit') {

        let shiftData = props.shiftData;
        // let shiftTaskArr = getAllShiftTasks();
        setCustomTemplateData({
          name: shift.shift_template_title
            ? shift.shift_template_title
            : shiftData.name,
          startTime: parse(shift.start_time, 'hh:mm a', startDate),
          endTime: parse(shift.end_time, 'hh:mm a', endDate),
          breakType: shiftData.breck,
          breakTime: shiftData.breck_time,
          tags: [...allTags.filter(tag => shift.tags.includes(tag._id))],
          notes: shiftData.notes,
          attachment: shiftData.attachment,
          instructions: shiftData.instructions,
          shiftTask: [...allShiftTasks.filter(task => shift.shift_task.includes(task._id))],
        });
        props.handleLoading(false);
        return;
      }

      if (shift) {
        let tags = getShiftTags(shift.tags);
        let task = getShiftTask(shift.shift_task);

        let currentDate = moment().format('DD/MM/YYYY');
        // end = moment(currentDate + ' ' + shift.end_time, 'DD/MM/YYYY HH:mm'),
        // start = moment(
        //   currentDate + ' ' + shift.start_time,
        //   'DD/MM/YYYY HH:mm'
        // );
        setCustomTemplateData({
          name: shift.shift_template_title,
          startTime: parse(shift.start_time, 'hh:mm a', startDate),
          endTime: parse(shift.end_time, 'hh:mm a', endDate),
          breakType: shift.breck,
          breakTime: shift.breck_time,
          tags,
          shiftTask: [...task],
          notes: shift.notes,
          attachment: shift.attachment,
        });
      } else
        setCustomTemplateData({
          name: '',
          startTime: new Date(),
          endTime: new Date(),
          breakType: '',
          breakTime: '',
          tags: [],
          shiftTask: [],
          notes: '',
          attachment: '',
        });
      props.handleLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const getAllPositions = async () => {
    dispatch(getAllShiftPostions());
    // try {
    //   const response = await axios.get(
    //     `${process.env.REACT_APP_API_BASE_URL}/position/getAllPosition`
    //   );
    //   if (response.status === 200) {
    //     const data = response.data.data;
    //     setAllPositions(data);
    //   }
    // } catch (e) {
    //   if (e.response) {
    //     console.log("Error >", e.response);
    //   }
    // }
  };

  const getShiftTask = (shiftIds) => {
    let task = [];
    if (shiftIds) {
      shiftIds.forEach((id) => {
        let shift = allShiftTasks.find((shift) => shift._id === id);
        if (shift)
          task.push({ jobTask_title: shift.jobTask_title, key: shift._id });
      });
    }
    // console.log(allShiftTasks);
    return task;
  };

  const getShiftTags = (shiftTagIds) => {
    let tags = [];
    if (shiftTagIds && shiftTagIds.length) {
      shiftTagIds.forEach((id) => {
        let shift = allTags.find((tag) => tag._id === id);
        if (shift) tags.push({ tag_title: shift.tag_title, key: shift._id });
      });
    }
    return tags;
  };

  const getAllTags = async (props) => {
    dispatch(getAllShiftTags());
  };

  function getStyles(name, selected, theme) {
    // debugger;
    return {
      fontWeight:
        selected?.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const handleShiftTemplateChage = async (value) => {
    setCustomTemplate(false);
    setInputTitle('Shift Template Name');

    if (value === 'default') {
      setCustomTemplateData({
        name: '',
        startTime: new Date(),
        endTime: new Date(),
        breakType: '',
        breakTime: '',
        tags: [],
        shiftTask: [],
        notes: '',
        attachment: '',
        instructions: '',
      });
      setInputTitle('Shift Name');
      return;
    }
    if (value === 'custom') {
      setInputTitle('Shift Name');
    }
    props.handleLoading(true);
    getAllShiftTasks();
    getAllTags();
    selectShiftTemplate(value);
  };

  const editData = async (shiftData) => {
    try {
      let startDate = getZoneTimeInLocalTime(shiftData.start_time, shiftData.timezone);
      let endDate = getZoneTimeInLocalTime(shiftData.end_time, shiftData.timezone);
      await getAllShiftTasks();
      getAllTags();
      getAllShiftTasks();
      setInputTitle('Shift Name');
      // setShiftTemplateSelected("current");
      setApprovedHours(shiftData.approved_hours);
      setProjectSelected(shiftData.pid);
      setJobSiteSelected(shiftData.jobsite);
      setEmployeeSelected(shiftData.employee_id);
      setStartDate(startDate);
      setEndDate(endDate);
      setPosition(shiftData.position?._id);
      setRequiredApproval(!!shiftData.require_approval);
      setFileName(getFileNameFromAwsUrl(shiftData.attachment ?? ''));
      setCustomTemplateData({
        name: shiftData.name ? shiftData.name : shiftData.shift_template_title,
        startTime: startDate,
        endTime: endDate,
        breakType: shiftData.breck,
        breakTime: shiftData.breck_time,
        tags: shiftData.tags && shiftData.tags.length ? shiftData.tags : [],
        notes: shiftData.notes,
        attachment: shiftData.attachment,
        instructions: shiftData.instructions,
        shiftTask: shiftData.shift_task,
        timezone: shiftData.timezone,
      });
      setTimeZone(shiftData.timezone);
      let prj = props.scheduleData.find((i) => i._id === shiftData.pid);
      setJobSites(prj?.jobsites || []);
      let jbs = prj?.jobsites?.find((i) => i._id === shiftData.jobsite);
      setEmployees(jbs?.assignedEmployees || []);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      props.handleLoading(true);
      await getAllShiftTemplates();
      setScheduledata(props.scheduleData);
      if (props.shiftData) {
        await editData(props.shiftData);
      }
      props.handleLoading(false);
    };
    fetch();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const empList = () => {
    return (
      <div
        style={{
          height: 'calc(100vh - 50px)',
          width: 'fit-content',
          overflowY: 'scroll',
          overflowX: 'hidden',
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'flex-end',
          rowGap: '20px',
          alignContent: 'flex-start',
        }}
      >
        <div>
          <Typography
            variant='h6'
            // className={myClasses.colorDarkBlue}
            style={{
              fontWeight: '600',
              margin: '10px 20px',
            }}
          >
            Project<span style={{ color: 'red' }}>*</span>
          </Typography>
          <FormControl
            variant='outlined'
            style={{
              minWidth: '300px',
              margin: '0px 20px',
            }}
          >
            <Select
              value={projectSelected}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              style={{ backgroundColor: 'rgb(247,248,252)' }}
              onChange={(e) => {
                selectProject(e.target.value);
              }}
            >
              <MenuItem key='default' value=''>
                <em>Select Project</em>
              </MenuItem>
              {scheduleData?.map((schedule) => (
                <MenuItem
                  key={schedule._id}
                  name='schedule.project_name'
                  value={schedule._id}
                >
                  {schedule.project_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div>
          <Typography
            variant='h6'
            // className={myClasses.colorDarkBlue}
            style={{
              fontWeight: '600',
              margin: '10px 20px',
            }}
          >
            JobSite<span style={{ color: 'red' }}>*</span>
          </Typography>

          <FormControl
            variant='outlined'
            style={{
              minWidth: '300px',
              margin: '0px 20px',
            }}
          >
            <Select
              value={jobSiteSelected}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              style={{ backgroundColor: 'rgb(247,248,252)' }}
              onChange={(e) => {
                selectJobSite(e.target.value);
              }}
            >
              <MenuItem key='default' value=''>
                <em>Select JobSite</em>
              </MenuItem>
              {jobSites.map((jobsite) => (
                <MenuItem key={jobsite._id} value={jobsite._id}>
                  {jobsite.jobSite_title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Typography
              variant='h6'
              style={{
                fontWeight: '600',
                margin: '10px 20px',
              }}
            >
              Start date<span style={{ color: 'red' }}>*</span>
            </Typography>
            <KeyboardDatePicker
              fullWidth
              inputVariant='outlined'
              value={startDate}
              onChange={(newValue) => {
                setStartDate(newValue);
              }}
              keyboardIcon={
                <CalendarTodayOutlinedIcon
                  style={{ color: 'rgb(31,41,156)' }}
                />
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{
                    color: 'rgb(31,41,156)',
                    fontWeight: '500',
                  }}
                />
              )}
              style={{
                color: 'rgb(31,41,156)',
                fontWeight: '500',
                width: '300px',
                marginLeft: '20px',
                marginRight: '20px',
              }}
              InputAdornmentProps={{
                style: {
                  color: 'rgb(31,41,156)',
                  fontWeight: '500',
                },
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Typography
              variant='h6'
              style={{
                fontWeight: '600',
                margin: '10px 20px',
              }}
            >
              End date<span style={{ color: 'red' }}>*</span>
            </Typography>
            <KeyboardDatePicker
              fullWidth
              inputVariant='outlined'
              value={endDate}
              onChange={(newValue) => {
                setEndDate(newValue);
              }}
              keyboardIcon={
                <CalendarTodayOutlinedIcon
                  style={{ color: 'rgb(31,41,156)' }}
                />
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{
                    color: 'rgb(31,41,156)',
                    fontWeight: '500',
                  }}
                />
              )}
              style={{
                color: 'rgb(31,41,156)',
                fontWeight: '500',
                width: '300px',
                marginLeft: '20px',
              }}
              InputAdornmentProps={{
                style: {
                  color: 'rgb(31,41,156)',
                  fontWeight: '500',
                },
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div>
          <Typography
            variant='h6'
            style={{
              fontWeight: '600',
              margin: '10px 20px',
            }}
          >
            Employees<span style={{ color: 'red' }}>*</span>
          </Typography>

          <FormControl
            variant='outlined'
            style={{
              minWidth: '300px',
              margin: '0px 20px',
            }}
          >
            <Select
              value={employeeSelected}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              style={{ backgroundColor: 'rgb(247,248,252)' }}
              onChange={(e) => {
                selectEmployee(e.target.value);
              }}
            >
              <MenuItem key='default' value=''>
                <em>Select Employees</em>
              </MenuItem>
              {employees.map((employee) => (
                <MenuItem key={employee.user._id} value={employee.user._id}>
                  {employee.user.first_name}&nbsp;{employee.user.last_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div>
          <Typography
            variant='h6'
            style={{
              fontWeight: '600',
              margin: '10px 20px',
            }}
          >
            Position<span style={{ color: 'red' }}>*</span>
          </Typography>

          <FormControl
            variant='outlined'
            style={{
              minWidth: '300px',
              margin: '0px 20px',
            }}
          >
            <Select
              value={position}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              style={{ backgroundColor: 'rgb(247,248,252)' }}
              onChange={(e) => setPosition(e.target.value)}
            >
              <MenuItem key='default' value=''>
                <em>Select Position</em>
              </MenuItem>
              {allPositions && allPositions.length > 0 ? (
                allPositions.data.map((position) => (
                  <MenuItem key={position.key} value={position._id}>
                    {position.position_title}
                  </MenuItem>
                ))
              ) : (
                <></>
              )}
            </Select>
          </FormControl>
        </div>
        <div>
          <Typography
            variant='h6'
            style={{
              fontWeight: '600',
              margin: '10px 20px',
            }}
          >
            Timezone<span style={{ color: 'red' }}>*</span>
          </Typography>

          <FormControl
            variant='outlined'
            style={{
              minWidth: '300px',
              margin: '0px 20px',
            }}
          >
            <Select
              value={timezone}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              style={{ backgroundColor: 'rgb(247,248,252)' }}
              onChange={(e) => setTimeZone(e.target.value)}
            >
              {allTimezones && allTimezones.length > 0 ? (
                allTimezones.map((tz) => (
                  <MenuItem key={tz} value={tz}>
                    {tz}
                  </MenuItem>
                ))
              ) : (
                <></>
              )}
            </Select>
          </FormControl>
        </div>
        <div>
          <Typography
            variant='h6'
            style={{
              fontWeight: '600',
              margin: '10px 20px',
            }}
          >
            Require Approval
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: '0 20px',
              padding: '10px 10px',
              backgroundColor: 'rgb(247,248,252)',
              borderRadius: '5px',
              boxShadow: 'lightgray 0px 0px 5px',
              minWidth: '300px',
            }}
          >
            <Typography
              variant='h6'
              style={{
                fontWeight: '400',
              }}
            >
              <i>{requireApproval ? 'Yes' : 'No'} </i>
            </Typography>

            <FormControlLabel
              control={
                <GreenSwitch
                  onChange={() => setRequiredApproval(!requireApproval)}
                  checked={requireApproval}
                />
              }
            />
          </div>
        </div>
        <div>
          <Typography
            variant='h6'
            style={{
              fontWeight: '600',
              margin: '10px 20px',
            }}
          >
            Shift Templates<span style={{ color: 'red' }}>*</span>
          </Typography>

          <FormControl
            variant='outlined'
            style={{
              minWidth: '300px',
              margin: '0px 20px',
            }}
          >
            <Select
              value={shiftTemplateSelected}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              style={{ backgroundColor: 'rgb(247,248,252)' }}
              onChange={(e) => {
                handleShiftTemplateChage(e.target.value);
              }}
            >
              <MenuItem value='default'>
                <em>Select Shift Template</em>
              </MenuItem>
              {
                props.operation !== 'edit' &&
                <MenuItem value='custom'>
                  <em>New Template</em>
                </MenuItem>
              }

              {shiftTemplatesByJobSite.map((employee) => (
                <MenuItem key={employee._id} value={employee._id}>
                  {employee.shift_template_title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div
          style={{
            overflowX: 'hidden',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            rowGap: '20px',
            alignContent: 'flex-start',
          }}
        >
          <div>
            <Typography
              variant='h6'
              style={{
                fontWeight: '600',
                margin: '10px 20px',
              }}
            >
              {inputTitle}
              <span style={{ color: 'red' }}>*</span>
            </Typography>

            <CssTextField
              variant='outlined'
              style={{
                backgroundColor: 'rgb(247,248,252)',
                boxShadow: 'lightgray 0px 0px 5px',
                margin: '0px 20px',
                width: '300px',
              }}
              value={customeTemplateData.name}
              onChange={(e) => {
                setCustomTemplateData({
                  ...customeTemplateData,
                  name: e.target.value,
                });
              }}
            />
          </div>
          <div>
            <Typography
              variant='h6'
              style={{
                fontWeight: '600',
                margin: '8px 20px',
              }}
            >
              Approved Hours
            </Typography>

            <CssTextField
              variant='outlined'
              style={{
                backgroundColor: 'rgb(247,248,252)',
                boxShadow: 'lightgray 0px 0px 5px',
                margin: '0px 20px',
                width: '300px',
              }}
              placeholder='Approved Hours'
              type='number'
              name='approvedHours'
              disabled={props.readOnly}
              value={approvedHours}
              onChange={({ target: { value } }) => {
                setApprovedHours(value);
              }}
            />
          </div>

          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Typography
                variant='h6'
                style={{
                  fontWeight: '600',
                  margin: '10px 20px',
                }}
              >
                Start time<span style={{ color: 'red' }}>*</span>
              </Typography>
              <KeyboardTimePicker
                // ampm={false}
                variant='inline'
                // label="With keyboard"
                style={{
                  margin: '0px 20px',
                  width: '300px',
                }}
                value={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  setCustomTemplateData({
                    ...customeTemplateData,
                    startTime: new Date(date),
                  });
                }}
                keyboardIcon={
                  <AccessTimeIcon style={{ color: 'rgb(31,41,156)' }} />
                }
              />
            </MuiPickersUtilsProvider>
          </div>
          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Typography
                variant='h6'
                style={{
                  fontWeight: '600',
                  margin: '10px 20px',
                }}
              >
                End time<span style={{ color: 'red' }}>*</span>
              </Typography>
              <KeyboardTimePicker
                // ampm={false}
                variant='inline'
                // label="With keyboard"
                style={{
                  margin: '0px 20px',
                  width: '300px',
                }}
                value={endDate}
                onChange={(date) => {
                  setEndDate(date);
                  setCustomTemplateData({
                    ...customeTemplateData,
                    endTime: new Date(date),
                  });
                }}
                keyboardIcon={
                  <AccessTimeIcon style={{ color: 'rgb(31,41,156)' }} />
                }
              />
            </MuiPickersUtilsProvider>
          </div>
          <div>
            <Typography
              variant='h6'
              style={{
                fontWeight: '600',
                margin: '10px 20px',
              }}
            >
              Break<span style={{ color: 'red' }}>*</span>
            </Typography>

            <FormControl
              variant='outlined'
              style={{
                minWidth: '300px',
                margin: '0px 20px',
              }}
            >
              <Select
                style={{ backgroundColor: 'rgb(247,248,252)' }}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                value={customeTemplateData.breakType}
                onChange={(e) => {
                  setCustomTemplateData({
                    ...customeTemplateData,
                    breakType: e.target.value,
                  });
                }}
              >
                <MenuItem value='' key='0'>
                  <em>Select Break Type</em>
                </MenuItem>
                <MenuItem key='Unpaid' value='Unpaid'>
                  Unpaid
                </MenuItem>
                <MenuItem key='Paid' value='Paid'>
                  Paid
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div>
            <Typography
              variant='h6'
              style={{
                fontWeight: '600',
                margin: '10px 20px',
              }}
            >
              Break time<span style={{ color: 'red' }}>*</span>
            </Typography>

            <FormControl
              variant='outlined'
              style={{
                minWidth: '300px',
                margin: '0px 20px',
              }}
            >
              <Select
                style={{ backgroundColor: 'rgb(247,248,252)' }}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                value={customeTemplateData.breakTime}
                onChange={(e) => {
                  setCustomTemplateData({
                    ...customeTemplateData,
                    breakTime: e.target.value,
                  });
                }}
              >
                <MenuItem key='default' value=''>
                  <em>Select Time</em>
                </MenuItem>
                {breakHoursDropdown.map((value, index) => (
                  <MenuItem value={value} key={index}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          {/* <div>
            <Typography
              variant='h6'
              style={{
                fontWeight: "600",
                margin: "10px 20px",
              }}
            >
              Break Counts<span style={{ color: "red" }}>*</span>
            </Typography>

            <div>
              <FormControl
                variant='outlined'
                style={{
                  minWidth: "300px",
                  margin: "0px 20px",
                }}
              >
                <Select
                  style={{ backgroundColor: "rgb(247,248,252)" }}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  value={customeTemplateData.breakCount}
                  onChange={(e) => {
                    setCustomTemplateData({
                      ...customeTemplateData,
                      breakCount: e.target.value,
                    });
                  }}
                >
                  <MenuItem value='' key='default'>
                    <em>Select break count</em>
                  </MenuItem>
                  {[0, 1, 2, 3, 4, 5].map((value, index) => (
                    <MenuItem value={value} key={index}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div> */}

          <div>
            <Typography
              variant='h6'
              style={{
                fontWeight: '600',
                margin: '10px 20px',
              }}
            >
              Tags
            </Typography>

            <div>
              <FormControl
                style={{
                  minWidth: '300px',
                  margin: '0px 20px',
                }}
              >
                <Select
                  multiple
                  style={{ backgroundColor: 'rgb(247,248,252)' }}
                  value={customeTemplateData.tags}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  onChange={(e) => {
                    let tag = [...customeTemplateData.tags];
                    let newTag = e.target.value[e.target.value.length - 1];
                    let isPresent = tag.findIndex(
                      (tag) => tag._id === newTag._id
                    );
                    if (isPresent === -1) {
                      tag.push({ ...newTag, key: newTag._id });
                    } else {
                      tag = tag.filter((tag) => tag._id !== newTag._id);
                    }
                    setCustomTemplateData({
                      ...customeTemplateData,
                      tags: [...tag],
                    });
                  }}
                  input={<OutlinedInput />}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((tag) => (
                        <Chip key={tag.key} label={tag.tag_title} />
                      ))}
                    </Box>
                  )}
                >
                  {allTags.map((tag) => (
                    <MenuItem
                      key={tag.key}
                      value={tag}
                      style={getStyles(tag, customeTemplateData.tags, theme)}
                    >
                      {tag.tag_title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div>
            <Typography
              variant='h6'
              style={{
                fontWeight: '600',
                margin: '10px 20px',
              }}
            >
              Shift Task<span style={{ color: 'red' }}>*</span>
            </Typography>

            <div>
              <FormControl
                style={{
                  minWidth: '300px',
                  margin: '0px 20px',
                }}
              >
                <Select
                  multiple
                  style={{ backgroundColor: 'rgb(247,248,252)' }}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  value={customeTemplateData.shiftTask || []}
                  onChange={(e) => {
                    let task = [...customeTemplateData.shiftTask];
                    let newTask = e.target.value[e.target.value.length - 1];
                    let isPresent = task.findIndex(
                      (task) => task._id === newTask._id
                    );
                    if (isPresent === -1) {
                      task.push({ ...newTask, key: newTask._id });
                    } else {
                      task = task.filter((task) => task._id !== newTask._id);
                    }
                    setCustomTemplateData({
                      ...customeTemplateData,
                      shiftTask: [...task],
                    });
                  }}
                  input={<OutlinedInput />}
                  renderValue={(selected = []) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected?.map((task) => {
                        return (
                          <Chip key={task.key} label={task.jobTask_title} />
                        );
                      })}
                    </Box>
                  )}
                // MenuProps={MenuProps}
                >
                  {allShiftTasks?.map((task) => (
                    <MenuItem
                      key={task.key}
                      value={task}
                    // style={getStyles(
                    //   task,
                    //   customeTemplateData.shiftTask,
                    //   theme
                    // )}
                    >
                      {task.jobTask_title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          {props.operation === 'edit' ? (
            <div style={{ width: '100%' }}>
              <Typography
                variant='h6'
                style={{
                  fontWeight: '600',
                  margin: '10px 20px',
                }}
              >
                Instructions
              </Typography>

              <TextareaAutosize
                name='leaving_comment'
                value={customeTemplateData.instructions}
                onChange={(e) => {
                  setCustomTemplateData({
                    ...customeTemplateData,
                    instructions: e.target.value,
                  });
                }}
                style={{
                  height: '110px',
                  backgroundColor: 'rgb(247,248,252)',
                  margin: '0px 20px',
                  padding: '10px',
                  minWidth: '300px',
                  width: '90%',
                }}
                aria-label='minimum height'
                minRows={10}
                placeholder='Enter instructions to the employee'
              />
            </div>
          ) : (
            <div style={{ width: '100%' }}>
              <Typography
                variant='h6'
                style={{
                  fontWeight: '600',
                  margin: '10px 20px',
                }}
              >
                Notes
              </Typography>

              <TextareaAutosize
                name='leaving_comment'
                value={customeTemplateData.notes}
                onChange={(e) => {
                  setCustomTemplateData({
                    ...customeTemplateData,
                    notes: e.target.value,
                  });
                }}
                style={{
                  height: '110px',
                  backgroundColor: 'rgb(247,248,252)',
                  margin: '0px 20px',
                  padding: '10px',
                  minWidth: '300px',
                  width: '90%',
                }}
                aria-label='minimum height'
                minRows={10}
                placeholder='Enter notes'
              />
            </div>
          )}
          <div style={{ width: '100%' }}>
            <Typography
              variant='h6'
              style={{
                fontWeight: '600',
                margin: '10px 20px',
                marginTop: '0',
              }}
            >
              Attachment
            </Typography>
            <a
              href={customeTemplateData.attachment}
              target='_blank'
              rel='noopener noreferrer'
            >
              <div
                style={{
                  display: "flex",
                  height: "60px",
                  width: "300px",
                  backgroundColor: "#F7F8FC",
                  alignItems: "center",
                  paddingLeft: "25px",
                  justifyContent: "space-between",
                  alignItems: "center",
                  justifyItems: "flex-start",
                  paddingRight: "25px",
                  cursor: "pointer",
                }}
              >
                <p>
                  {fileName}
                </p>

                <CloudDownload />
              </div>
            </a>

            <label
              style={{
                height: '110px',
                backgroundColor: '#f5f5f5',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '20px',
                width: '90%',
                margin: '0px 20px',
                minWidth: '300px',
              }}
            >
              {false ? (<>


              </>
              ) : (
                <p
                  style={{
                    fontSize: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  Drag & Drop Here,{' '}
                  <span style={{ color: '#1250ff', marginRight: '10px' }}>
                    Or Browse File
                  </span>
                  <CloudUploadOutlinedIcon />
                </p>
              )}

              <input
                type='file'
                style={{ display: 'none' }}
                onChange={(e) => onSelectFile(e.target.files)}
              />
            </label>
            {fileUploadSuccess && (
              <p
                style={{
                  color: '#00b500',
                  fontSize: '16px',
                  marginLeft: '20px',
                }}
              >
                Upload successful
              </p>
            )}
          </div>
        </div>
        {customeTemplate === true ? (
          <></>
        ) : (
          <>
            {/* {console.log(shiftTemplateSelectedObject[0])} */}
            {/* {!!shiftTemplateSelectedObject.length && (
              <>
                <div>
                  <Typography
                    variant='h6'
                    style={{
                      fontWeight: "600",
                      margin: "5px 20px",
                      maxWidth: "300px",
                      width: "300px",
                    }}
                  >
                    Position :{" "}
                    <span style={{ fontWeight: "normal", fontSize: "16px" }}>
                      {getPosition(shiftTemplateSelectedObject[0].position)}
                    </span>
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant='h6'
                    style={{
                      fontWeight: "600",
                      maxWidth: "300px",
                      width: "300px",
                      margin: "0 20px",
                    }}
                  >
                    Shift Task :{" "}
                    {getShiftTask(shiftTemplateSelectedObject[0].shift_task)}
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant='h6'
                    style={{
                      fontWeight: "600",
                      margin: "0 20px",
                      maxWidth: "300px",
                      width: "300px",
                    }}
                  >
                    Start Time :{" "}
                    <span
                      style={{
                        fontWeight: "normal",
                        textTransform: "uppercase",
                        fontSize: "16px",
                      }}
                    >
                      {shiftTemplateSelectedObject[0].start_time}
                    </span>
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant='h6'
                    style={{
                      fontWeight: "600",
                      margin: "0 20px",
                      maxWidth: "300px",
                      width: "300px",
                    }}
                  >
                    End Time :{" "}
                    <span
                      style={{
                        fontWeight: "normal",
                        textTransform: "uppercase",
                        fontSize: "16px",
                      }}
                    >
                      {shiftTemplateSelectedObject[0].end_time}
                    </span>
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant='h6'
                    style={{
                      fontWeight: "600",
                      maxWidth: "300px",
                      width: "300px",
                      margin: "0 20px",
                    }}
                  >
                    Break Type :{" "}
                    <span style={{ fontWeight: "normal", fontSize: "16px" }}>
                      {shiftTemplateSelectedObject[0].breck}
                    </span>
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant='h6'
                    style={{
                      fontWeight: "600",
                      maxWidth: "300px",
                      width: "300px",
                      margin: "0 20px",
                    }}
                  >
                    Break Time :{" "}
                    <span style={{ fontWeight: "normal", fontSize: "16px" }}>
                      {shiftTemplateSelectedObject[0].breck_time}
                    </span>
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant='h6'
                    style={{
                      fontWeight: "600",
                      maxWidth: "300px",
                      width: "300px",
                      margin: "0 20px",
                    }}
                  >
                    Schedule :{" "}
                    <span style={{ fontWeight: "normal", fontSize: "16px" }}>
                      {shiftTemplateSelectedObject[0].schedule.schedule}
                    </span>
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant='h6'
                    style={{
                      fontWeight: "600",
                      maxWidth: "300px",
                      width: "300px",
                      margin: "0 20px",
                    }}
                  >
                    Scheduled Address:{" "}
                    <span style={{ fontWeight: "normal", fontSize: "16px" }}>
                      {shiftTemplateSelectedObject[0].schedule.address}
                    </span>
                  </Typography>
                </div>

                {shiftTemplateSelectedObject[0].notes ? (
                  <div>
                    <Typography
                      variant='h6'
                      style={{
                        fontWeight: "600",
                        maxWidth: "300px",
                        width: "300px",
                        margin: "10px 20px",
                      }}
                    >
                      Notes :{" "}
                      <span style={{ fontWeight: "normal", fontSize: "16px" }}>
                        {shiftTemplateSelectedObject[0].notes}
                      </span>
                    </Typography>
                  </div>
                ) : (
                  <></>
                )}
                {shiftTemplateSelectedObject[0].attachment ? (
                  <div
                    style={{
                      display: "flex",
                      maxWidth: "300px",
                      width: "300px",
                      margin: "0 20px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant='h6'
                      style={{
                        fontWeight: "600",
                      }}
                    >
                      Attachment :{" "}
                    </Typography>

                    <div
                      style={{
                        padding: "5px",
                        backgroundColor: "#F1F2F6",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontSize: "16px",
                      }}
                    >
                      <Typography component={"span"}>
                        {shiftTemplateSelectedObject[0].attachment &&
                          shiftTemplateSelectedObject[0].attachment.substring(
                            shiftTemplateSelectedObject[0].attachment.lastIndexOf(
                              "/"
                            ) + 1
                          )}
                        {!shiftTemplateSelectedObject[0].attachment && (
                          <p style={{ color: "grey" }}>
                            <i>No attachements</i>
                          </p>
                        )}
                      </Typography>

                      {shiftTemplateSelectedObject[0].attachment ? (
                        <a
                          href={shiftTemplateSelectedObject[0].attachment}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <IconButton>
                            <GetApp />
                          </IconButton>
                        </a>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            )} */}
          </>
        )}
      </div>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '15px',
          }}
        >
          <Typography
            variant='h5'
            style={{
              fontWeight: '600',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {props.operation === 'edit' ? 'Edit Shift' : 'Assign Shift'}
          </Typography>

          <IconButton
            aria-label='close'
            onClick={handleCloseDialog}
            style={{
              margin: '0',
              padding: '0',
              color: 'black',
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <hr />
        {empList()}
      </div>

      {props.operation !== 'view' && (
        <div>
          <hr style={{ marginTop: '20px' }}></hr>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '0px 10px 10px 0px',
            }}
          >
            <Button
              variant='outlined'
              color='primary'
              style={{
                marginRight: '10px',
              }}
              onClick={handleCloseDialog}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              color='primary'
              onClick={handleAssignShift}
            >
              Save
            </Button>
          </div>
        </div>
      )}

      <Snackbar
        open={!!formError}
        autoHideDuration={2000}
        onClose={() => setFormError('')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setFormError('')}
          severity='error'
          sx={{ width: '100%' }}
        >
          {formError}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}

export default AssignShiftDialog;
