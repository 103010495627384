import axios from "axios";
import { Urls } from "store/urls";

export const getAllAssetCategoryRepo = (params) => {
  return axios({
    method: "GET",
    url: Urls.GET_ALL_ASSET_CATEGORY,
    params: params,
  });
};

export const getAssetCategoryByIdRepo = (params) => {
  return axios({
    method: "GET",
    url: Urls.GET_ASSET_CATEGORY_BY_ID,
    params: params,
  });
};

export const postAssetCategoryRepo = (data) => {
  return axios({
    method: "POST",
    url: Urls.POST_ASSET_CATEGORY,
    data: data,
  });
};
export const updateAssetCategoryRepo = (data) => {
  return axios({
    method: "PUT",
    url: Urls.UPDATE_ASSET_CATEGORY,
    data: data,
  });
};
export const deleteAssetCategoryRepo = (id) => {
  return axios({
    method: "DELETE",
    url: Urls.DELETE_ASSET_CATEGORY + id,
  });
};
