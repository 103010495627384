import React, { useState, useEffect } from 'react';
import {
  Typography,
  // InputBase,
  // InputLabel,
  // Select,
  Button,
  Menu,
  //MenuItem,
  Dialog,
  DialogContent,
  //FormControl,
  IconButton,
  Paper,
  Backdrop,
  CircularProgress,
  TablePagination,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  InputBase,
} from '@material-ui/core';
import clsx from 'clsx';
import '@fontsource/roboto';
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { useDasboardStyles } from './Style';
import SearchIcon from '@material-ui/icons/Search';
// import axios from "axios";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import AddProjectDialog from './AddProjectDialog';
import AddSiteDialog from './AddSiteDialog';
import ProjectCard from './ProjectCard';
import useAuth from 'hooks/useAuth';
import { useDispatch, useSelector } from 'react-redux';
import {
  getShiftProject,
  searchShiftProject,
} from 'store/actions/ShiftManagement/ShiftProjectNSites';

export function SProjectsAndSites(props) {
  let theme = createTheme();
  theme = responsiveFontSizes(theme);
  const dispatch = useDispatch();
  const classes = useDasboardStyles();
  const [allProjectsData, setAllProjectsData] = useState([]);
  const [dialogOperation, setDialogOperation] = useState('');
  const [openProjectDialog, setProjectOpenDialog] = useState(false);
  const [projectSelected, setProjectSelected] = useState({});
  const [openSiteDialog, setSiteOpenDialog] = useState(false);
  const [jobSiteSelected, setJobSiteSelected] = useState({});
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);
  const [totalLength, setTotalLength] = useState(0);
  const [searchKey, setSearchKey] = useState('');
  const [status, setStatus] = useState('true');
  const auth = useAuth();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getAllProjects(newPage, limit);
  };

  const handleChangeRowsPerPage = (event) => {
    let l = parseInt(event.target.value, 10);
    setLimit(l);
    setPage(0);
    getAllProjects(0, l);
  };

  const { shiftProjects } = useSelector((state) => state.shiftProjectNSites);
  const [isLoading, setIsLoading] = useState(true);
  const getAllProjects = async (page = 0, l = limit) => {
    const org_id = auth.user.org_id._id;
    const params = {
      id: org_id,
      limit: l,
      page: page + 1,
    };
    setIsLoading(true);
    dispatch(getShiftProject(params)).finally(() => {
      setIsLoading(false);
    });
  };

  const handleSearch = (obj) => {
    let stat = obj?.status,
      search = obj?.searchKey;
    if (!stat) stat = status;
    else if (stat === 'all') stat = '';

    if (!search) search = searchKey;
    else if (search === 'all') search = '';

    let data = {
      status: stat,
      page: obj?.page || page,
      limit: obj?.limit || limit,
      searchKey: search,
    };
    dispatch(searchShiftProject(data));
  };

  const handleProjectDialogOpen = (operation, row = {}) => {
    setProjectOpenDialog(true);
    if (operation === 'create') {
      setDialogOperation('create');
    } else {
      setProjectSelected(row);
      setDialogOperation('edit');
    }
  };
  const handleSiteDialogOpen = (operation, row = {}) => {
    setSiteOpenDialog(true);
    if (operation === 'create') {
      setProjectSelected(row);
      setDialogOperation('create');
    } else {
      setSiteOpenDialog(true);
      setJobSiteSelected(row);
      setDialogOperation('edit');
    }
  };

  const handleProjectCloseDialog = () => {
    setProjectOpenDialog(false);
  };
  const handleSiteCloseDialog = () => {
    setSiteOpenDialog(false);
  };

  const showProjectsTable = (rows) => {
    return (
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label='scheduler table'>
          <TableBody>
            {rows && rows.length ? (
              rows.map((row) => (
                <ProjectCard
                  row={row}
                  getAllProjects={getAllProjects}
                  handleProjectDialogOpen={(operation, row) => {
                    handleProjectDialogOpen(operation, row);
                  }}
                  handleSiteDialogOpen={(operation, siteRow) => {
                    handleSiteDialogOpen(operation, siteRow);
                  }}
                  setIsLoading={setIsLoading}
                />
              ))
            ) : (
              <p style={{ color: 'grey', padding: '20px' }}>
                <i>No projects added</i>
              </p>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  useEffect(() => {
    window.scroll(0, 0);
    handleSearch();
  }, []);

  useEffect(() => {
    if (shiftProjects?.fulfilled) {
      setIsLoading(true);
      setAllProjectsData(shiftProjects.fulfilled);
      setTotalLength(shiftProjects.length);
      setIsLoading(false);
    }
  }, [shiftProjects.length]);

  return (
    <React.Fragment>
      <div className='root'>
        <ThemeProvider theme={theme}>
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: props.isDrawerOpen,
            })}
          >
            <Typography
              style={{
                textAlign: 'left',
                marignBottom: '20px',
                minWidth: '230px',
              }}
            >
              Home {'>'} Shift Management {'>'} Projects and Sites
            </Typography>

            <Paper
              style={{
                padding: '20px 20px 50px 20px',
                minWidth: '230px',
                marginTop: '20px',
                minHeight: '800px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  marginBottom: '10px',
                  position: 'relative',
                }}
              >
                <FormControl
                  variant='outlined'
                  style={{
                    minWidth: '225px',
                    marginRight: '20px',
                    marginBottom: '10px',
                  }}
                >
                  <InputLabel
                    // style={{
                    //   paddingLeft:"5px"
                    // }}
                    id='demo-simple-select-label-1'
                  >
                    Status
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label-1'
                    label='Schedules'
                    id='demo-simple-select'
                    value={status}
                    onChange={(event) => {
                      if (event.target.value === 'All') {
                        setStatus('');
                        handleSearch({
                          status: 'all',
                        });
                      } else {
                        setStatus(event.target.value);
                        handleSearch({
                          status: event.target.value,
                        });
                      }
                    }}
                  >
                    <MenuItem value='All'>All</MenuItem>
                    <MenuItem value={'true'}>Active</MenuItem>
                    <MenuItem value={'false'}>Inactive</MenuItem>
                  </Select>
                </FormControl>

                <Paper
                  style={{
                    width: '275px',
                    minWidth: '225px',
                    height: '56px',
                    backgroundColor: 'rgb(248,248,248)',
                    marginRight: '50px',
                    marginBottom: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    style={{
                      paddingLeft: '5px',
                    }}
                    // value={searchKey}
                    placeholder='Search Projects & Jobsites'
                    onChange={(event) => {
                      const searchTerm = event.target.value.toString();
                      if (searchTerm.length < 1) {
                        setSearchKey('');
                        handleSearch({
                          searchKey: 'all',
                        });
                        return;
                      }
                      setSearchKey(searchTerm);
                      handleSearch({
                        searchKey: searchTerm,
                      });
                    }}
                  />
                  <IconButton
                    style={{ paddingleft: '-5px', paddingRight: '0px' }}
                    aria-label='search'
                  >
                    <SearchIcon
                      style={{ paddingleft: '-5px', paddingRight: '0px' }}
                    />
                  </IconButton>
                </Paper>

                <Button
                  variant='contained'
                  style={{
                    color: 'white',
                    backgroundColor: 'rgb(31, 41, 156)',
                    height: '56px',
                    marginLeft: 'auto',
                  }}
                  onClick={(e) => handleProjectDialogOpen('create')}
                >
                  +&nbsp;Add Project
                </Button>
                <div>
                  <IconButton
                    style={{
                      marginLeft: '5px',
                      marginTop: '5px',
                    }}
                    aria-label='more'
                    id='long-button'
                    aria-controls='basic-menu'
                    aria-haspopup='true'
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    open={false}
                    id='long-menu'
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    PaperProps={{
                      style: {
                        width: '130px',
                        top: '215px',
                        left: '1500px',
                      },
                    }}
                  ></Menu>
                </div>
              </div>

              <hr
                style={{
                  margin: '20px -20px',
                }}
              ></hr>

              {showProjectsTable(allProjectsData)}
              {totalLength ? (
                <TablePagination
                  style={{ backgroundColor: '#F7F8FC' }}
                  rowsPerPageOptions={[25, 50]}
                  component='div'
                  count={totalLength}
                  rowsPerPage={limit}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              ) : (
                <></>
              )}
              {openProjectDialog ? (
                <Dialog
                  open={openProjectDialog}
                  maxWidth='sm'
                  fullWidth
                  keepMounted
                  aria-describedby='alert-dialog-slide-description'
                  PaperProps={{
                    style: {
                      position: 'absolute',
                      margin: '0px',
                      padding: '0px',
                      right: '0px',
                      minHeight: '111vh',
                      top: '0px',
                      borderRadius: '0px',
                    },
                  }}
                >
                  <DialogContent
                    style={{
                      margin: '0px',
                      padding: '0px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                  >
                    <AddProjectDialog
                      operation={dialogOperation}
                      closeForm={handleProjectCloseDialog}
                      project={projectSelected}
                      afterOpn={(params) => {
                        setIsLoading(true);
                        if (dialogOperation === 'create') {
                          setStatus('');
                          setSearchKey('');
                          handleSearch(params);
                        } else handleSearch();
                      }}
                      setIsLoading={setIsLoading}
                    />
                  </DialogContent>
                </Dialog>
              ) : (
                <></>
              )}
              {openSiteDialog ? (
                <Dialog
                  open={openSiteDialog}
                  maxWidth='sm'
                  fullWidth
                  keepMounted
                  aria-describedby='alert-dialog-slide-description'
                  PaperProps={{
                    style: {
                      position: 'absolute',
                      margin: '0px',
                      padding: '0px',
                      right: '0px',
                      minHeight: '111vh',
                      top: '0px',
                      borderRadius: '0px',
                    },
                  }}
                >
                  <DialogContent
                    style={{
                      margin: '0px',
                      padding: '0px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                  >
                    <AddSiteDialog
                      operation={dialogOperation}
                      closeForm={handleSiteCloseDialog}
                      jobSite={jobSiteSelected}
                      selectedProject={projectSelected}
                      setIsLoading={setIsLoading}
                      afterOpn={handleSearch}
                    />
                  </DialogContent>
                </Dialog>
              ) : (
                <></>
              )}
            </Paper>
          </main>
        </ThemeProvider>
      </div>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </React.Fragment>
  );
}
