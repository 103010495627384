import React, { useState, useEffect } from "react";
import TopFilterAllAsset from "./TopFilterAllAsset";
import AllAssetsList from "./AllAssetsList";
import FilterSidebarContent from "../dashboard/SidebarContent";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllAsset,
  createAsset,
  updateAsset,
  getAssetById,
} from "store/actions/AssetManagement/allAssets";
import { getAllAssetCategory } from "store/actions/AssetManagement/assetCategory";
import { getAllAssetSubCategory } from "store/actions/AssetManagement/assetSubCategory";
import { getAllAssetConditon } from "store/actions/AssetManagement/assetCondition";
import { listToCheckbox } from "utils";
import AssetAssignSidebar from "./AssetAssignSidebar";
import AddUpdateAsset from "./AddUpdateAsset";
import AssetRemarkSidebar from "./AssetRemarkSidebar";
import useAuth from "hooks/useAuth";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import { clearAssetById } from "store/slices/AssetManagement/allAssets";

const AllAssets = () => {
  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: 9999,
      color: "#fff",
    },
  }));
  const classes = useStyles();
  const auth = useAuth();
  const dispatch = useDispatch();
  const { allAsset, assetCategory, assetSubCategory, assetCondition } =
    useSelector((state) => state);
  const [search, setSearch] = useState("");
  const [multipleFilter, setMultipleFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [assetId, setAssetId] = useState(null);
  const [addUpdateAsset, setAddUpdateAsset] = useState(false);
  const [assignAsset, setAssignAsset] = useState(false);
  const [assetRemark, setAssetRemark] = useState(false);
  const [allAssets, setAllAssets] = useState([]);
  const [assetInitialValues, setAssetInitialValues] = useState(null);
  const [filterSidebar, setFilterSidebar] = useState(false);
  const [filterData, setFilterData] = useState([]);

  const sidebarFilterHandler = () => {
    setFilterSidebar(!filterSidebar);
  };
  const assetRemarkHandler = (id) => {
    if (id) {
      dispatch(getAssetById(id));
      setIsLoading(true);
      setAssetRemark(id);
    } else {
      setAssetRemark(!assetRemark);
      dispatch(getAllAsset());
      clearAssetById();
    }
  };
  const assignAssetHandler = (id) => {
    if (id) {
      dispatch(getAssetById(id));
      setIsLoading(true);
    } else {
      setAssetInitialValues(null);
      dispatch(getAllAsset());
      clearAssetById();
    }

    setAssignAsset(id);
  };
  const assetAddUpdateHandler = (id) => {
    if (id) {
      dispatch(getAssetById(id));
      setIsLoading(true);
    } else {
      setAssetInitialValues(null);
      dispatch(getAllAsset());
      clearAssetById();
    }
    setAssetId(id);
    setAddUpdateAsset(!addUpdateAsset);
  };

  const createFilterData = () => {
    let filterCreateData = [
      {
        filtername: "Category",
        search: "",
        optionList: [
          ...listToCheckbox(assetCategory?.allAssetCategory?.fulfilled),
        ],
        label: "name",
        value: "id",
        paramKey: "category",
        paramValue: "id",
      },
      {
        filtername: "Sub Category",
        search: "",
        optionList: [
          ...listToCheckbox(assetSubCategory?.assetSubCategoryList?.fulfilled),
        ],
        label: "name",
        value: "id",
        paramKey: "sub_category",
        paramValue: "id",
      },
      {
        filtername: "Condition",
        search: "",
        optionList: [
          ...listToCheckbox(assetCondition?.assetConditionList?.fulfilled),
        ],
        label: "name",
        value: "id",
        paramKey: "condition",
        paramValue: "id",
      },
    ];
    setFilterData(filterCreateData);
  };

  useEffect(() => {
    dispatch(getAllAssetCategory());
    dispatch(getAllAssetSubCategory());
    dispatch(getAllAsset());
    dispatch(getAllAssetConditon());
  }, []);

  useEffect(() => {
    if (allAsset?.allAssetList?.fulfilled) {
      setAllAssets(allAsset?.allAssetList?.fulfilled);
      setIsLoading(false);
    }
  }, [allAsset?.allAssetList]);

  useEffect(() => {
    if (allAsset?.assetById?.fulfilled) {
      setAssetInitialValues(allAsset?.assetById?.fulfilled);
      setIsLoading(false);
    }
  }, [allAsset?.assetById]);

  useEffect(() => {
    if (
      assetCondition?.assetConditionList?.fulfilled &&
      assetSubCategory?.assetSubCategoryList?.fulfilled &&
      assetCategory?.allAssetCategory?.fulfilled
    ) {
      createFilterData();
    }
  }, [
    assetCategory.allAssetCategory,
    assetSubCategory.assetSubCategoryList,
    assetCondition?.assetConditionList,
  ]);

  return (
    <div className="bg-white p-5 shadow text-left">
      <TopFilterAllAsset
        sidebarFilterHandler={sidebarFilterHandler}
        assetAddUpdateHandler={assetAddUpdateHandler}
        categoryList={assetCategory?.allAssetCategory?.fulfilled}
        subCategoryList={assetSubCategory?.assetSubCategoryList?.fulfilled}
        search={search}
        setSearch={setSearch}
        setMultipleFilter={setMultipleFilter}
        multipleFilter={multipleFilter}
        createFilterData={createFilterData}
      />
      {multipleFilter ? (
        <div className="mt-5">
          <p>Result shown is based on multiple filters selected ...</p>
        </div>
      ) : (
        <></>
      )}
      <AllAssetsList
        allAssets={allAssets}
        assetAddUpdateHandler={assetAddUpdateHandler}
        assignAssetHandler={assignAssetHandler}
        assetRemarkHandler={assetRemarkHandler}
      />
      {filterSidebar && (
        <FilterSidebarContent
          filterOpenCloseHandle={sidebarFilterHandler}
          filterData={filterData}
          setFilterData={setFilterData}
          setSearch={setSearch}
          setMultipleFilter={setMultipleFilter}
          multipleFilter={multipleFilter}
        />
      )}

      {addUpdateAsset && !assetId && (
        <AddUpdateAsset
          id={assetId}
          addUpdateAsset={addUpdateAsset}
          assetAddUpdateHandler={assetAddUpdateHandler}
          categoryList={assetCategory?.allAssetCategory?.fulfilled}
          subCategoryList={assetSubCategory?.assetSubCategoryList?.fulfilled}
          conditionList={assetCondition?.assetConditionList?.fulfilled}
        />
      )}

      {addUpdateAsset && assetId && assetInitialValues && (
        <AddUpdateAsset
          id={assetId}
          addUpdateAsset={addUpdateAsset}
          assetAddUpdateHandler={assetAddUpdateHandler}
          categoryList={assetCategory?.allAssetCategory?.fulfilled}
          subCategoryList={assetSubCategory?.assetSubCategoryList?.fulfilled}
          conditionList={assetCondition?.assetConditionList?.fulfilled}
          assetInitialValues={assetInitialValues}
        />
      )}

      {assignAsset && assetInitialValues ? (
        <AssetAssignSidebar
          auth={auth}
          assignAsset={assignAsset}
          assignAssetHandler={assignAssetHandler}
          categoryList={assetCategory?.allAssetCategory?.fulfilled}
          subCategoryList={assetSubCategory?.assetSubCategoryList?.fulfilled}
          conditionList={assetCondition?.assetConditionList?.fulfilled}
          assetInitialValues={assetInitialValues}
        />
      ) : (
        <></>
      )}

      {assetRemark && assetInitialValues ? (
        <AssetRemarkSidebar
          auth={auth}
          assetRemark={assetRemark}
          assetRemarkHandler={assetRemarkHandler}
          data={assetInitialValues}
        />
      ) : (
        <></>
      )}

      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default AllAssets;
