import axios from "axios";
import { Urls, BASE_URL } from "store/urls";

export const getUsersByOrgIdRepo = (id, params) => {
  return axios({
    baseURL: BASE_URL,
    method: "GET",
    url: Urls.GET_USERS_BY_ORG_ID + `${id}`,
    params: params,
  });
};

export const getOrgCountsRepo = (id) => {
  return axios({
    baseURL: BASE_URL,
    method: "GET",
    url: Urls.GET_ORG_COUNTS + `${id}`,
  });
};


