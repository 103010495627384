import {
  getAttendanceByOrgRepo,
  getLeavesApprovalRepo,
  putLeavesUpdateRepo,
} from "../../repositories/HrManagement/LeavesAttendanceHR";
import {
  getAttendanceByOrgPending,
  getAttendanceByOrgFulfilled,
  getAttendanceByOrgRejected,
  getLeavesApprovalPending,
  getLeavesApprovalFulfilled,
  getLeavesApprovalRejected,
  putLeavesUpdatePending,
  putLeavesUpdateFulfilled,
  putLeavesUpdateRejected,
} from "../../slices/HrManagement/LeavesAttendanceHR";

export const getAttendanceByOrg = (id, params) => {
  return async (dispatch) => {
    dispatch(getAttendanceByOrgPending());
    await getAttendanceByOrgRepo(id, params)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getAttendanceByOrgFulfilled(response?.data?.data));
        } else {
          dispatch(getAttendanceByOrgRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(getAttendanceByOrgRejected(error.message));
        }
      });
  };
};

export const getLeavesApproval = (id, params) => {
  return async (dispatch) => {
    dispatch(getLeavesApprovalPending());
    await getLeavesApprovalRepo(id, params)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getLeavesApprovalFulfilled(response?.data?.data));
        } else {
          dispatch(getLeavesApprovalRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(getLeavesApprovalRejected(error.message));
        }
      });
  };
};

export const putLeavesUpdate = (id, params) => {
  return async (dispatch) => {
    dispatch(putLeavesUpdatePending());
    await putLeavesUpdateRepo(id, params)
      .then((response) => {
        if (response.status === 200) {
          dispatch(putLeavesUpdateFulfilled(response?.data?.data));
        } else {
          dispatch(putLeavesUpdateRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(putLeavesUpdateRejected(error.message));
        }
      });
  };
};
