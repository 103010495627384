import { createSlice } from "@reduxjs/toolkit";

export const initialState = { 
    assetResignationList: {} 
};

export const assetResignationSlice = createSlice({
  name: "AssetResignation",
  initialState,
  reducers: {
    getAllAssetResignationsPending(state, action) {
      return {
        ...state,
        assetResignationList: { loading: true },
      };
    },
    getAllAssetResignationsFulfilled(state, action) {
      return {
        ...state,
        assetResignationList: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    getAllAssetResignationRejected(state, action) {
      return {
        ...state,
        assetResignationList: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
  },
});

export const {
  getAllAssetResignationsPending,
  getAllAssetResignationsFulfilled,
  getAllAssetResignationRejected,
} = assetResignationSlice.actions;

export default assetResignationSlice.reducer;
