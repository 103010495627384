import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    updateEmployeeStatus: {}
    
};

export const  myEmployeesSlice = createSlice({
  name: "employees",
  initialState,
  reducers: {
    updateEmployeeStatusPending(state, action) {
      return {
        ...state,
        updateEmployeeStatus: { loading: true },
      };
    },
    updateEmployeeStatusFulfilled(state, action) {
      return {
        ...state,
        updateEmployeeStatus: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    updateEmployeeStatusRejected(state, action) {
      return {
        ...state,
        updateEmployeeStatus: {
          loading: false,
          rejected: action.payload,
        },
      };
    },  


      
  },
});

export const {
  updateEmployeeStatusPending,
  updateEmployeeStatusFulfilled,
  updateEmployeeStatusRejected

} = myEmployeesSlice.actions;

export default myEmployeesSlice.reducer;
