import React from "react";
import Sidebar from "pages/sidebar/Sidebar";

const NewRequestSidebar = ({ sidebarHandler }) => {
  return (
    <Sidebar sidebarHandler={sidebarHandler} modalTitle="New Request">
      <div className="font-bold text-gray-800">
        <div>
          <p className="">Assign To</p>
          <div className="flex gap-2 items-center">
            <input
              type="text"
              className="bg-gray-100 text-gray-900 border-2 rounded-md outline-0 px-2 py-2 w-full font-bold mt-1 focus:ring focus:outline-none"
              value="Name Lastname"
            />
            <div>
              <p className="py-3 px-3 font-semibold border cursor-pointer border-blue-700 rounded bg-indigo-700 text-gray-50">
                Add
              </p>
            </div>
          </div>

          <div className="flex items-center gap-2 justify-between border p-2 mt-3">
            <div className="flex items-center gap-2">
              <p className="bg-purple-700 w-6 h-6 rounded-full text-white flex items-center justify-center">
                B
              </p>
              <div>
                <p className="text-lg font-semibold text-gray-900">
                  Bhupinder Singh (ID12345678)
                </p>
              </div>
            </div>
            <i className="fa-regular fa-circle-xmark font-medium cursor-pointer"></i>
          </div>
        </div>
        <div className="mt-4">
          <p className="">Category*</p>
          <select className="bg-gray-100 border-2 rounded-md outline-0 px-2 py-2 w-full font-bold mt-1 focus:ring focus:outline-none">
            <option>Electronic</option>
            <option>Cloths</option>
            <option>Vehicles</option>
          </select>
        </div>
        <div className="mt-4">
          <p className="">Sub-Category*</p>
          <select className="bg-gray-100 border-2 rounded-md outline-0 px-2 py-2 w-full font-bold mt-1 focus:ring focus:outline-none">
            <option>Laptop</option>
            <option>Computer</option>
            <option>T-Shirt</option>
          </select>
        </div>
        <div className="mt-4">
          <p className="py-2 font-semibold border cursor-pointer border-blue-700 rounded bg-indigo-700 text-gray-50 text-center">
            Add
          </p>
        </div>
        <div className="mt-5">
          <div className="bg-gray-100 border-2 rounded-md outline-0 px-2 py-2 w-full font-bold focus:ring focus:outline-none">
            <div className="flex justify-between flex-wrap gap-2 items-center">
              <p>Laptop</p>
              <div className="flex items-center gap-3 flex-wrap">
                <p>Qty.</p>
                <div className="flex items-center gap-8 border-2">
                  <p className="bg-blue-900 text-white w-6 h-7 flex justify-center items-center cursor-pointer rounded-l-lg">
                    -
                  </p>
                  <p className="">1</p>
                  <p className="bg-blue-900 text-white w-6 h-7 flex justify-center items-center cursor-pointer rounded-r-lg">
                    +
                  </p>
                </div>
                <i className="fa-regular fa-circle-xmark font-medium cursor-pointer"></i>
              </div>
            </div>
          </div>
          <div className="mt-2 bg-gray-100 border-2 rounded-md outline-0 px-2 py-2 w-full font-bold focus:ring focus:outline-none">
            <div className="flex justify-between flex-wrap gap-2 items-center">
              <p>Laptop</p>
              <div className="flex items-center gap-3 flex-wrap">
                <p>Qty.</p>
                <div className="flex items-center gap-8 border-2">
                  <p className="bg-blue-900 text-white w-6 h-7 flex justify-center items-center cursor-pointer rounded-l-lg">
                    -
                  </p>
                  <p className="">1</p>
                  <p className="bg-blue-900 text-white w-6 h-7 flex justify-center items-center cursor-pointer rounded-r-lg">
                    +
                  </p>
                </div>
                <i className="fa-regular fa-circle-xmark font-medium cursor-pointer"></i>
              </div>
            </div>
          </div>
          <div className="mt-2 bg-gray-100 border-2 rounded-md outline-0 px-2 py-2 w-full font-bold focus:ring focus:outline-none">
            <div className="flex justify-between flex-wrap gap-2 items-center">
              <p>Laptop</p>
              <div className="flex items-center gap-3 flex-wrap">
                <p>Qty.</p>
                <div className="flex items-center gap-8 border-2">
                  <p className="bg-blue-900 text-white w-6 h-7 flex justify-center items-center cursor-pointer rounded-l-lg">
                    -
                  </p>
                  <p className="">1</p>
                  <p className="bg-blue-900 text-white w-6 h-7 flex justify-center items-center cursor-pointer rounded-r-lg">
                    +
                  </p>
                </div>
                <i className="fa-regular fa-circle-xmark font-medium cursor-pointer"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <p className="">Remark</p>
          <p className="bg-gray-100 border-2 rounded-md outline-0 px-2 py-2 w-full font-bold mt-1 focus:ring focus:outline-none">
            HP 15s-du1115TU Intel CDC N4020. HP 15s-du1115TU Intel CDC N4020. HP
            15s-du1115TU Intel CDC N4020. HP 15s-du1115TU Intel CDC N4020
          </p>
        </div>
        <div className="border-b-2 border-gray-300 my-5"></div>
        <div className="flex justify-end gap-5">
          <p
            className="py-1 px-3 font-semibold border cursor-pointer border-blue-700 rounded"
            onClick={sidebarHandler}
          >
            Cancel
          </p>
          <p className="py-1 px-3 font-semibold border cursor-pointer border-blue-700 rounded bg-indigo-900 text-gray-50">
            Send
          </p>
        </div>
      </div>
    </Sidebar>
  );
};

export default NewRequestSidebar;
