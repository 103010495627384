import {
  deletePositionsRepo,
  updatePositionsRepo,
  createPositionsRepo,
  searchPositionsRepo,
} from '../../repositories/ShiftManagement/Positions';
import {
  deletePositionPending,
  deletePositionFulfilled,
  deletePositionRejected,
  updatePositionPending,
  updatePositionFulfilled,
  updatePositionRejected,
  createPositionPending,
  createPositionFulfilled,
  createPositionRejected,
  serachPositionPending,
  serachPositionFulfilled,
  serachPositionRejected,
} from '../../slices/ShiftManagement/Positions';

export const deletePositions = (id) => {
  return async (dispatch) => {
    dispatch(deletePositionPending());
    await deletePositionsRepo(id)
      .then((response) => {
        if (response.status === 200) {
          dispatch(deletePositionFulfilled(response?.data));
          dispatch(serachPosition());
        } else {
          dispatch(deletePositionRejected(response?.data));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(deletePositionRejected(error.message));
        }
      });
  };
};

export const updatePosition = (data) => {
  return async (dispatch) => {
    dispatch(updatePositionPending());
    await updatePositionsRepo(data)
      .then((response) => {
        if (response.status === 200) {
          dispatch(updatePositionFulfilled(response?.data));
          dispatch(serachPosition());
        } else {
          dispatch(updatePositionRejected(response?.data));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(updatePositionRejected(error.message));
        }
      });
  };
};

export const createPosition = (data) => {
  return async (dispatch) => {
    dispatch(createPositionPending());
    await createPositionsRepo(data)
      .then((response) => {
        if (response.status === 200) {
          dispatch(createPositionFulfilled(response?.data));
          dispatch(serachPosition());
        } else {
          dispatch(createPositionRejected(response?.data));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(createPositionRejected(error.message));
        }
      });
  };
};

export const serachPosition = (obj) => {
  return async (dispatch) => {
    dispatch(serachPositionPending());
    searchPositionsRepo(obj)
      .then((response) => {
        if (response.status === 200) {
          dispatch(serachPositionFulfilled(response?.data));
        } else {
          dispatch(serachPositionRejected(response?.data));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(serachPositionRejected(error.message));
        }
      });
  };
};
