import React, { useState, useEffect } from "react";
import AssetConditionList from "./AssetConditionList";
import ConditionTopFilter from "./ConditionTopFilter";
import {
  getAllAssetConditon,
  deleteAssetConditon,
} from "store/actions/AssetManagement/assetCondition";
import { getAllAssetCategory } from "store/actions/AssetManagement/assetCategory";
import { useDispatch, useSelector } from "react-redux";
import {
  DialogTitle,
  DialogContentText,
  DialogActions,
  Dialog,
  Button,
  DialogContent,
} from "@material-ui/core";
import { clearDeleteAssetCondition } from "store/slices/AssetManagement/assetCondition";

const AddAssetCondition = () => {
  const dispatch = useDispatch();
  const [conditionsList, setConditionsList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [search, setSearch] = useState("");
  const [deleteId, setDeleteId] = useState();
  const [showDialogToDelete, setShowDialogToDelete] = useState(false);
  const { assetCategory, assetCondition } = useSelector((state) => state);

  const onChangeCategory = (obj) => {
    setSelectedCategory(obj);
    let newConditionList = [...assetCondition?.assetConditionList?.fulfilled];
    if (search != "") {
      newConditionList = [...newConditionList].filter((item) =>
        item.name
          .toLowerCase()
          .split(" ")
          .join("")
          .startsWith(search?.toLowerCase().split(" ").join(""))
      );
    }
    if (obj) {
      newConditionList = [...newConditionList].filter(
        (item) => item.category_id == obj._id
      );
      setConditionsList(newConditionList);
    } else {
      setConditionsList(newConditionList);
    }
  };

  const onSearch = (obj) => {
    setSearch(obj);
    let newConditionList = [...assetCondition?.assetConditionList?.fulfilled];
    if (selectedCategory?._id) {
      newConditionList = [...newConditionList].filter(
        (item) => item.category_id == selectedCategory._id
      );
    }
    if (obj != "") {
      newConditionList = [...newConditionList]?.filter((item) =>
        item.name
          .toLowerCase()
          .split(" ")
          .join("")
          .startsWith(obj?.toLowerCase().split(" ").join(""))
      );
      setConditionsList(newConditionList);
    } else {
      setConditionsList(newConditionList);
    }
  };

  const hideDialogToDelete = (id) => {
    setShowDialogToDelete(false);
  };
  const conditionDeleteHandler = (id) => {
    setShowDialogToDelete(true);
    setDeleteId(id);
  };

  const deleteMessage = (id) => {
    dispatch(deleteAssetConditon(id));
  };

  useEffect(() => {
    dispatch(getAllAssetConditon());
    dispatch(getAllAssetCategory());
  }, []);

  useEffect(() => {
    if (assetCondition?.assetConditionList?.fulfilled) {
      setConditionsList(assetCondition?.assetConditionList?.fulfilled);
    }
  }, [assetCondition?.assetConditionList]);

  useEffect(() => {
    if (assetCondition?.deleteAssetCondition?.fulfilled) {
      dispatch(getAllAssetConditon());
      dispatch(clearDeleteAssetCondition());
      setDeleteId(null);
    }
  }, [assetCondition?.deleteAssetCondition]);

  useEffect(() => {
    if (assetCategory?.allAssetCategory?.fulfilled) {
      setCategoryList(assetCategory?.allAssetCategory?.fulfilled);
    }
  }, [assetCategory?.allAssetCategory]);

  return (
    <div className="bg-white">
      <ConditionTopFilter
        categoryList={categoryList}
        onChangeCategory={onChangeCategory}
        selectedCategory={selectedCategory}
        onSearch={onSearch}
        search={search}
      />

      <AssetConditionList
        conditionsList={conditionsList}
        categoryList={categoryList}
        conditionDeleteHandler={conditionDeleteHandler}
      />
      <Dialog
        open={showDialogToDelete}
        onClick={() => hideDialogToDelete()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to delete this message?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => hideDialogToDelete()}>Cancel</Button>
          <Button onClick={(e) => deleteMessage(deleteId)} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddAssetCondition;
