import {
  postAssignAssetRepo,
  postAddAssetRemarkRepo,
  handoverAssetRepo,
} from "store/repositories/AssetManagement/assetAssign";
import {
  postAssignAssetFulfilled,
  postAssignAssetPending,
  postAssignAssetRejected,
  postAddAssetRemarkFulfilled,
  postAddAssetRemarkPending,
  postAddAssetRemarkRejected,
  handoverAssetFulfilled,
  handoverAssetPending,
  handoverAssetRejected,
} from "store/slices/AssetManagement/assetAssign";

export const postAssignAsset = (params) => {
  return (dispatch) => {
    dispatch(postAssignAssetPending());
    postAssignAssetRepo(params)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data?.data);
          dispatch(postAssignAssetFulfilled(response?.data?.data));
        } else {
          dispatch(postAssignAssetRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(postAssignAssetRejected(error.message));
        }
      });
  };
};
export const postAddAssetRemark = (params) => {
  return (dispatch) => {
    dispatch(postAddAssetRemarkPending());
    postAddAssetRemarkRepo(params)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data?.data);
          dispatch(postAddAssetRemarkFulfilled(response?.data?.data));
        } else {
          dispatch(postAddAssetRemarkRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(postAddAssetRemarkRejected(error.message));
        }
      });
  };
};

export const handoverAsset = (params) => {
  return (dispatch) => {
    dispatch(handoverAssetPending());
    handoverAssetRepo(params)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data?.data);
          dispatch(handoverAssetFulfilled(response?.data?.data));
        } else {
          dispatch(handoverAssetRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(handoverAssetRejected(error.message));
        }
      });
  };
};
