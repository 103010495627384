import React from "react";
import { Typography, Grid, Paper } from "@material-ui/core";
import useAuth from "hooks/useAuth";
import moment from "moment";
import myStyles from "../ProfileStyles";

function EmployeeInfoPanel() {
  const myClasses = myStyles();
  const auth = useAuth();
  return (
    <Grid container direction='row' spacing={4}>
      {/* Graduation------------  */}
      <Grid item xs={12} sm={6} lg={4}>
        <Paper
          component='div'
          className={myClasses.paper}
          style={{ height: "400px" }}
        >
          <Grid container direction='row' justify='space-evenly'>
            <Grid item xs={12} className={myClasses.label}>
              <Typography variant='h5' color='primary'>
                Personal
              </Typography>
            </Grid>

            <Grid item xs={6} lg={5} style={{ textAlign: "left" }}>
              <Typography variant='subtitle1' className={myClasses.info}>
                &nbsp; SIN :{" "}
              </Typography>
              <Typography variant='subtitle1' className={myClasses.info}>
                &nbsp; Start Date :{" "}
              </Typography>
              <Typography variant='subtitle1' className={myClasses.info}>
                &nbsp; Position :{" "}
              </Typography>
              <Typography variant='subtitle1' className={myClasses.info}>
                &nbsp; Contact :{" "}
              </Typography>
              <Typography variant='subtitle1' className={myClasses.info}>
                &nbsp; Email :{" "}
              </Typography>
              <Typography variant='subtitle1' className={myClasses.info}>
                &nbsp; Driving License :{" "}
              </Typography>
            </Grid>

            <Grid item xs={6} lg={6} style={{ textAlign: "left" }}>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {(auth.user.account_details && auth.user.account_details.sin) ||
                  ""}{" "}
              </Typography>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {moment(auth.user.doj).format("MMM Do YYYY") || ""}{" "}
              </Typography>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {auth.user.position || ""}{" "}
              </Typography>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {auth.user.phone || ""}{" "}
              </Typography>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {auth.user.email || ""}{" "}
              </Typography>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {auth.user.driving_licence_no || ""}{" "}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      {/* --------------------------Medical Details------------------ */}
      <Grid item xs={12} sm={6} lg={4}>
        <Paper
          component='div'
          className={myClasses.paper}
          style={{ height: "400px" }}
        >
          <Grid container direction='row' justify='space-evenly'>
            <Grid item xs={12} className={myClasses.label}>
              <Typography variant='h5' color='primary'>
                Medical Details
              </Typography>
            </Grid>

            <Grid item xs={6} lg={6} style={{ textAlign: "left" }}>
              <Typography variant='subtitle1' className={myClasses.info}>
                &nbsp; Health Issues :{" "}
              </Typography>
              <Typography variant='subtitle1' className={myClasses.info}>
                &nbsp; Health Card :{" "}
              </Typography>
              <Typography variant='subtitle1' className={myClasses.info}>
                &nbsp; Province Of Issue:{" "}
              </Typography>
            </Grid>

            <Grid item xs={5} lg={5} style={{ textAlign: "left" }}>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {(auth.user.medical_details &&
                  auth.user.medical_details.health_issues) ||
                  ""}{" "}
              </Typography>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {(auth.user.medical_details &&
                  auth.user.medical_details.health_id) ||
                  ""}{" "}
              </Typography>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {(auth.user.medical_details &&
                  auth.user.medical_details.province_of_issue) ||
                  ""}{" "}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      {/* emergency----------------- */}
      <Grid item xs={12} sm={6} lg={4}>
        <Paper
          component='div'
          className={myClasses.paper}
          style={{ height: "400px" }}
        >
          <Grid container direction='row' justify='space-evenly'>
            <Grid item xs={12} className={myClasses.label}>
              <Typography variant='h5' color='primary'>
                In Case of Emergency
              </Typography>
            </Grid>

            <Grid item xs={6} lg={6} style={{ textAlign: "left" }}>
              <Typography variant='subtitle1' className={myClasses.info}>
                &nbsp; Preferred Contact :{" "}
              </Typography>
              <Typography variant='subtitle1' className={myClasses.info}>
                &nbsp; Relation :{" "}
              </Typography>
              <Typography variant='subtitle1' className={myClasses.info}>
                &nbsp; Contact :{" "}
              </Typography>
              <Typography variant='subtitle1' className={myClasses.info}>
                &nbsp; Email :{" "}
              </Typography>
            </Grid>

            <Grid item xs={5} lg={5} style={{ textAlign: "left" }}>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {(auth.user.emergency_contacts[0] &&
                  auth.user.emergency_contacts[0].name) ||
                  ""}{" "}
              </Typography>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {(auth.user.emergency_contacts[0] &&
                  auth.user.emergency_contacts[0].relationship) ||
                  ""}{" "}
              </Typography>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {(auth.user.emergency_contacts[0] &&
                  auth.user.emergency_contacts[0].mobile_no.mobile) ||
                  ""}{" "}
              </Typography>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {/* landline_no is email */}
                {(auth.user.emergency_contacts[0] &&
                  auth.user.emergency_contacts[0].landline_no) ||
                  ""}{" "}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default EmployeeInfoPanel;
