import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Backdrop, CircularProgress } from "@material-ui/core";

const AssetAssignList = (props) => {
  const { data, categoryList } = props;
  return (
    <div className="mt-5">
      {data?.fulfilled?.map((el, index) => {
        let status = {};
        let category = {};
        el.assets?.forEach((element, index) => {
          if (status[element.status]) {
            status[element.status] = status[element.status] + 1;
          } else {
            status[element.status] = 1;
          }
          if (!category[element.category]) {
            category[element.category] = categoryList?.find(
              (obj) => obj._id == element.category
            );
          }
        });
        let userDetails = el.users.length > 0 && el.users[0];
        return (
          <Link to={`/assets/assetassigndetails/${el._id}`}>
            <div className="flex flex-wrap gap-3 justify-between items-center border-b-2 py-6">
              <div className="flex items-center gap-5">
                <p className="bg-purple-700 w-6 h-6 rounded-full text-white flex items-center justify-center">
                  {userDetails?.first_name
                    ? userDetails?.first_name.slice(0, 1).toUpperCase()
                    : ""}
                </p>
                <div>
                  <p className="text-lg font-semibold">
                    {userDetails?.first_name
                      ? userDetails?.first_name
                      : "" + " " + userDetails?.last_name
                      ? userDetails?.last_name
                      : ""}{" "}
                    ({el._id})
                  </p>
                  <p className="text-sm">
                    {userDetails?.position ? userDetails?.position : ""}
                  </p>
                </div>
              </div>
              <div>
                <p className="text-sm">Asset</p>
                <p className="font-semibold">
                  {Object.keys(category)?.map((obj, index) => {
                    if (index == 0) {
                      return category[obj]?.name;
                    } else {
                      return ", " + category[obj]?.name;
                    }
                  })}
                </p>
              </div>
              <div>
                <p className="text-sm">Asset Condition</p>
                <div className="flex">
                  {status?.Active ? (
                    <>
                      <p className="font-medium text-green-600">
                        {status.Active}
                      </p>
                      <p className="font-medium mx-1">|</p>
                    </>
                  ) : (
                    <></>
                  )}
                  {status.undefined ? (
                    <>
                      <p className="font-medium text-blue-700">
                        {status.undefined}
                      </p>
                      <p className="font-medium mx-1">|</p>
                    </>
                  ) : (
                    <></>
                  )}
                  {status.Replacement ? (
                    <p className="font-medium text-yellow-600">0</p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div>
                <p className="text-sm">Status</p>
                <p className="font-semibold text-green-600">
                  {el.resignationStatus}
                </p>
              </div>
              <i className="fa-solid fa-angle-right"></i>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default AssetAssignList;
