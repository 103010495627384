import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
  authData: {},
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    getAuthTokenPending(state, action) {
      return {
        ...state,
        authData: { loading: true },
      };
    },
    getAuthTokenFulfilled(state, action) {
      return {
        ...state,
        authData: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    getAuthTokenRejected(state, action) {
      return {
        ...state,
        authData: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
    getUserByIdPending(state, action) {
      return {
        ...state,
        user: { loading: true },
      };
    },
    getUserByIdFulfilled(state, action) {
      return {
        ...state,
        user: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    getUserByIdRejected(state, action) {
      return {
        ...state,
        user: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
  },
});

export const {
  getAuthTokenPending,
  getAuthTokenFulfilled,
  getAuthTokenRejected,
  getUserByIdPending,
  getUserByIdFulfilled,
  getUserByIdRejected,
} = authSlice.actions;

export default authSlice.reducer;
