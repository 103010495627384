export function formatDateddmmyyyy(datestring) {
  let date = new Date(
    new Date(datestring).getTime() - new Date().getTimezoneOffset() * 60 * 1000
  );
  if (typeof datestring == "object") {
    date = new Date(datestring);
  }
  let day = date.getDate();
  day = day < 10 ? "0" + day.toString() : day.toString();
  let mon = date.getMonth() + 1;
  mon = mon < 10 ? "0" + mon.toString() : mon.toString();
  return day + "-" + mon + "-" + date.getFullYear();
}

export function formatDateyyyymmdd(datestring) {
  let date = new Date(
    new Date(datestring).getTime() - new Date().getTimezoneOffset() * 60 * 1000
  );
  if (typeof datestring == "object") {
    date = new Date(datestring);
  }
  let day = date.getDate();
  day = day < 10 ? "0" + day.toString() : day.toString();
  let mon = date.getMonth() + 1;
  mon = mon < 10 ? "0" + mon.toString() : mon.toString();
  return date.getFullYear() + "-" + mon + "-" + day;
}

export function formatDateyyyymmddForToday(datestring) {
  let date = new Date(datestring);

  let day = date.getDate();
  day = day < 10 ? "0" + day.toString() : day.toString();
  let mon = date.getMonth() + 1;
  mon = mon < 10 ? "0" + mon.toString() : mon.toString();
  return date.getFullYear() + "-" + mon + "-" + day;
}
