import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allSchedules: {},
  jobSites: {},
  loading: false,
};

export const schedulerSlice = createSlice({
  name: 'scheduler',
  initialState,
  reducers: {
    getAllSchedulesPending(state, action) {
      return {
        ...state,
        allSchedules: { loading: true },
        loading: true,
      };
    },
    getAllSchedulesFulfilled(state, action) {
      return {
        ...state,
        allSchedules: {
          loading: false,
          fulfilled: action.payload,
        },
        loading: false,
      };
    },
    getAllSchedulesRejected(state, action) {
      return {
        ...state,
        allSchedules: {
          loading: false,
          rejected: action.payload,
        },
        loading: false,
      };
    },

    getAllJobSitesPending(state, action) {
      return {
        ...state,
        jobSites: { loading: true },
        loading: true,
      };
    },
    getAllJobSitesFulfilled(state, action) {
      return {
        ...state,
        jobSites: {
          loading: false,
          fulfilled: action.payload,
          loading: false,
        },
      };
    },
    getAllJobSitesRejected(state, action) {
      return {
        ...state,
        jobSites: {
          loading: false,
          rejected: action.payload,
          loading: false,
        },
      };
    },

    clearAllShiftSchedules(state, action) {
      return {
        ...state,
        allSchedules: {},
        loading: false,
      };
    },
  },
});

export const {
  getAllSchedulesPending,
  clearAllShiftSchedules,
  getAllSchedulesFulfilled,
  getAllSchedulesRejected,
  getAllJobSitesPending,
  getAllJobSitesFulfilled,
  getAllJobSitesRejected,
} = schedulerSlice.actions;

export default schedulerSlice.reducer;
