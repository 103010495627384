import React, { useEffect, useState } from "react";
import CategoryList from "./CategoryList";
import {
  getAllAssetCategory,
  deleteAssetCategory,
} from "store/actions/AssetManagement/assetCategory";
import { useDispatch, useSelector } from "react-redux";
import CategoryTopFilter from "./CategoryTopFilter";
import {
  DialogTitle,
  DialogContentText,
  DialogActions,
  Dialog,
  Button,
  DialogContent,
} from "@material-ui/core";
import { clearDeleteAssetCategory } from "store/slices/AssetManagement/assetCategory";

const AddCategory = () => {
  const dispatch = useDispatch();
  const [categoryList, setCategoryList] = useState([]);
  const [search, setSearch] = useState("");
  const [deleteId, setDeleteId] = useState();
  const [showDialogToDelete, setShowDialogToDelete] = useState(false);
  const { assetCategory } = useSelector((state) => state);

  const onSearch = (obj) => {
    setSearch(obj);
    let newCategoryList = [...assetCategory?.allAssetCategory?.fulfilled];
    if (obj != "") {
      newCategoryList = [...newCategoryList]?.filter((item) =>
        item.name
          .toLowerCase()
          .split(" ")
          .join("")
          .startsWith(obj?.toLowerCase().split(" ").join(""))
      );
      setCategoryList(newCategoryList);
    } else {
      setCategoryList(newCategoryList);
    }
  };

  useEffect(() => {
    dispatch(getAllAssetCategory());
  }, []);

  useEffect(() => {
    if (assetCategory?.allAssetCategory?.fulfilled) {
      setCategoryList(assetCategory?.allAssetCategory?.fulfilled);
    }
  }, [assetCategory?.allAssetCategory]);

  useEffect(() => {
    if (assetCategory?.deleteAssetCategory?.fulfilled) {
      dispatch(getAllAssetCategory());
      dispatch(clearDeleteAssetCategory());
      setDeleteId(null);
    }
  }, [assetCategory?.deleteAssetCategory]);

  const hideDialogToDelete = (id) => {
    setShowDialogToDelete(false);
  };
  const categoryDeleteHandler = (id) => {
    setShowDialogToDelete(true);
    setDeleteId(id);
  };

  const handleDelete = (id) => {
    dispatch(deleteAssetCategory(id));
  };

  return (
    <div className="bg-white">
      <CategoryTopFilter search={search} onSearch={onSearch} />
      <CategoryList
        categoryList={categoryList}
        categoryDeleteHandler={categoryDeleteHandler}
      />
      <Dialog
        open={showDialogToDelete}
        onClick={() => hideDialogToDelete()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to delete this message?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => hideDialogToDelete()}>Cancel</Button>
          <Button onClick={(e) => handleDelete(deleteId)} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddCategory;
