import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import axios from "axios";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { PAGE_ROUTE_LOGIN } from "auth/constants";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: 9999,
    color: "#fff"
  }
}));

const Container = styled.div`
  background-color: white;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ResetPassword = function (props) {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [infoType, setInfoType] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleForgotPassword = async function (e) {
    e.preventDefault();
    if (!email) {
      setErrorMessage("Please enter email");
      return;
    }

    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    console.log("test >", emailRegex.test(String(email).toLowerCase()));
    if (!emailRegex.test(String(email).toLowerCase())) {
      setInfoType("error");
      setErrorMessage("Please enter a valid email");
      return;
    }
    setErrorMessage(null);
    setIsLoading(true);
    // Call API

    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/users/initiatepasswordreset`,
        {
          email: email
        }
      );
      console.log("Resp ", resp.data);
      if (resp.status === 200) {
        setIsLoading(false);
        localStorage.removeItem("token");
        setInfoType("info");
        setErrorMessage("Check your mail for Reset Password Link");
      }
    } catch (e) {
      setIsLoading(false);
      if (e.response.data) {
        if (e.response.data.error) {
          setInfoType("error");
          setErrorMessage(e.response.data.error);
        }
      }
    }
    // props.history.push("/");
  };

  return (
    <Container>
      <div id="loginform">
        <h2 id="headerTitle">Find your account</h2>
        <div>
          <div className="row">
            <label>Enter Email</label>
            <input
              type={"text"}
              value={email}
              placeholder={"Enter Your Email"}
              onChange={e => setEmail(e.target.value)}
            />
          </div>

          <div id="button" className="row">
            <button onClick={e => handleForgotPassword(e)}>Submit</button>
          </div>
        </div>
        {errorMessage && (
          <span style={infoType === "error" ? { color: "red" } : {}}>
            {errorMessage}
          </span>
        )}
        <br></br>
        <br></br>
        <Link
          style={{ color: "#083864", textAlign: "center" }}
          to={PAGE_ROUTE_LOGIN}
        >
          Have Credentials ? Go to Login
        </Link>
      </div>
      <div className="dashboard-image"></div>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};
export default ResetPassword;
