import React, { useState, useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "pages/sidebar/Sidebar";
import Select from "react-select";
import useAuth from "hooks/useAuth";
import { getUsersByOrgId } from "store/actions/HrManagement/Dashboard";
import { postAssignAsset } from "store/actions/AssetManagement/assetAssign";
import { clearPostAssignAsset } from "store/slices/AssetManagement/assetAssign";
const AssetAssignSidebar = ({
  assignAsset,
  assignAssetHandler,
  conditionList,
  assetInitialValues,
}) => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const { orgUsers } = useSelector((state) => state.dashboard);
  const { postAssignAssetData } = useSelector((state) => state.assetAssign);
  const { register, handleSubmit, control, setValue } = useForm({
    defaultValues: assetInitialValues,
  });
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "remark", // unique name for your Field Array
    }
  );
  const [employeeList, setEmployeeList] = useState([]);

  useEffect(() => {
    if (auth?.user?.org_id?._id) {
      const params = {
        page_number: 1,
        limit: "",
      };
      dispatch(getUsersByOrgId(auth.user.org_id._id, params));
    }
  }, []);

  useEffect(() => {
    if (orgUsers?.fulfilled) {
      setEmployeeList(orgUsers?.fulfilled);
    }
  }, [orgUsers]);

  const onSubmit = (data) => {
    let paramsData = {};
    paramsData.asset_id = assignAsset;
    paramsData.user_id = data.assignTo;
    dispatch(postAssignAsset(paramsData));
  };

  useEffect(() => {
    if (postAssignAssetData?.fulfilled) {
      assignAssetHandler();
      dispatch(clearPostAssignAsset());
    }
  }, [postAssignAssetData]);

  return (
    <Sidebar
      sidebarHandler={() => assignAssetHandler()}
      modalTitle="Assign Asset"
    >
      <div className="font-bold">
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <div>
            <p className="text-base mb-1">Assign to</p>
            <Controller
              control={control}
              name="assignTo"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Select
                  inputRef={ref}
                  placeholder="Select Assign To "
                  className="basic-single"
                  classNamePrefix="select"
                  value={employeeList?.data?.find((c) => c?._id === value)}
                  getOptionLabel={(option) => option.first_name}
                  getOptionValue={(option) => option._id}
                  options={employeeList?.data}
                  onChange={(val) => onChange(val._id)}
                />
              )}
            />
          </div>
          <div className="mt-4">
            <p className="text-base mb-1">Item Details</p>
            <textarea
              {...register(
                "about"
                //  { required: true }
              )}
              className="text-base font-medium border-2 p-3 w-full rounded shadow"
              placeholder="Item Description .. "
              disabled
            />
          </div>
          <div className="mt-4">
            <p className="text-base">Serial No</p>
            <div className="flex gap-2 items-center">
              <input
                {...register("serial", { required: true })}
                placeholder="Serial No. "
                className="bg-gray-100 border-2 rounded-md outline-0 px-2 py-3 w-full font-semibold mt-1 focus:ring focus:outline-none"
                disabled
              />
            </div>
          </div>
          <div className="mt-4">
            <p className="text-base">Asset Condition</p>
            <Controller
              control={control}
              name="condition"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Select
                  inputRef={ref}
                  placeholder="Select Asset Condition"
                  className="basic-single"
                  classNamePrefix="select"
                  value={conditionList?.find((c) => c?._id === value)}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option._id}
                  options={conditionList}
                  onChange={(val) => onChange(val.name)}
                  isDisabled={true}
                />
              )}
            />
          </div>
          <div className="mt-4">
            <p className="text-base">Asset Remark</p>
            {fields.map((field, index) => (
              <input
                key={field.index} // important to include key with field's id
                {...register(`remark.${index}.msg`)}
                disabled
                className="bg-gray-100 border-2 rounded-md outline-0 px-2 py-3 w-full font-semibold mt-1 focus:ring focus:outline-none"
              />
            ))}
          </div>
          <div className="mt-4">
            <p className="text-base mb-1">Attach Asset Photo *</p>
            <input disabled type="file" />
            <div className="flex justify-between items-center gap-2  mt-1">
              <input
                className="bg-gray-100 border-2 rounded-md outline-0 px-2 py-3 w-full font-semibold focus:ring focus:outline-none"
                {...register("image")}
                disabled
                placeholder="Image address"
              />
            </div>
          </div>
          <div className="border-b-2 border-gray-300 my-5"></div>
          <div className="flex justify-end gap-5">
            <p
              className="py-1 px-3 font-semibold border cursor-pointer border-blue-700 rounded"
              onClick={() => assignAssetHandler()}
            >
              Cancel
            </p>
            <p
              className="py-1 px-3 font-semibold border cursor-pointer border-blue-700 rounded bg-indigo-700 text-gray-50"
              onClick={handleSubmit((data) => onSubmit(data))}
            >
              Save
            </p>
          </div>
        </form>
      </div>
    </Sidebar>
  );
};

export default AssetAssignSidebar;
