import axios from 'axios';
import { BASE_URL, Urls } from 'store/urls';

export const getAllShiftTemplatesRepo = (page, limit) => {
  return axios({
    method: 'GET',
    url: `${Urls.GET_ALL_SHIFT_TEMPLATES}?page_number=${page}&limit=${limit}`,
  });
};

export const getAllShiftPositionsRepo = (page, limit) => {
  return axios({
    method: 'GET',
    url: `${Urls.GET_ALL_SHIFT_POSITONS}?page_number=${page}&limit=${limit}`,
  });
};

export const deleteShiftTemplatesRepo = (id) => {
  return axios({
    method: 'DELETE',
    url: Urls.DELETE_SHIFT_TEMPLATES + `${id}`,
  });
};

export const updateShiftTemplatesRepo = (data) => {
  return axios({
    method: 'PUT',
    url: Urls.UPDATE_SHIFT_TEMPLATES,
    data: data,
  });
};

export const getAllTagsRepo = () => {
  return axios({
    method: 'GET',
    url: Urls.GET_ALL_TAGS,
  });
};

export const getAllShiftTaskJobsRepo = () => {
  return axios({
    method: 'GET',
    url: Urls.GET_ALL_SHIFT_TASK_JOBS,
  });
};
export const getAllShiftProjectsRepo = (params) => {
  let id = params.id,
    page = params.page,
    limit = params.limit;
  return axios({
    method: 'GET',
    url: `${
      Urls.GET_ALL_SHIFT_PROJECTS + id
    }?page_number=${page}&limit=${limit}`,
    // url: Urls.GET_ALL_SHIFT_PROJECTS + id,
  });
};
export const getAllShiftJobSitesRepo = () => {
  return axios({
    method: 'GET',
    url: Urls.GET_ALL_SHIFT_JOBS_SITES,
  });
};

export const createShiftTemplatesRepo = (data) => {
  return axios({
    method: 'POST',
    url: Urls.POST_SHIFT_TEMPLATE,
    data: data,
  });
};

export const searchShiftTemplateRepo = (obj) => {
  console.log('inside api',obj);
  const data = {
    limit: obj?.limit || 25,
    pageNumber: obj?.page || 1,
    status: obj?.status || '',
    searchKey: obj?.searchKey || '',
    jobsite: obj?.jobsite || '',
  };
  return axios({
    baseURL: BASE_URL,
    method: 'POST',
    url: Urls.SEARCH_SHIFTTEMPLATE,
    data,
  });
};
