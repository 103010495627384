import React, { useEffect } from "react";
import Sidebar from "pages/sidebar/Sidebar";
import { useParams, useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { postAddAssetRemark } from "store/actions/AssetManagement/assetAssign";
import { clearPostAddAssetRemark } from "store/slices/AssetManagement/assetAssign";
import { getAssetByUserId } from "store/actions/AssetManagement/allAssets";

import moment from "moment";
const CommentSidebar = ({ data, commentSidebarHandler, auth }) => {
  const dispatch = useDispatch();
  const { postAddAssetRemarkData } = useSelector((state) => state.assetAssign);
  const { register, handleSubmit, control, setValue } = useForm();
  const params = useParams();
  const onSubmit = (obj) => {
    let paramsData = {};
    paramsData.asset_id = data._id;
    paramsData.remark = obj.remark;
    paramsData.user_id = auth.user._id;
    dispatch(postAddAssetRemark(paramsData));
  };
  useEffect(() => {}, [data?.remark?.length]);
  useEffect(() => {
    if (postAddAssetRemarkData.fulfilled) {
      dispatch(clearPostAddAssetRemark());
      dispatch(getAssetByUserId(params.id));
      commentSidebarHandler();
    }
  }, [postAddAssetRemarkData]);

  return (
    <Sidebar modalTitle="Comment" sidebarHandler={commentSidebarHandler}>
      {data?.remark?.map((element) => {
        return (
          <div className="border p-3 rounded">
            <p className="text-lg font-bold mb-1">{element?.user}</p>
            <p className="leading-5 text-gray-900 font-semibold">
              {element?.msg ? element?.msg : ""}
            </p>
            <p className="text-gray-600 mt-7">
              {moment(element.updated_at).format("MMMM Do YYYY, h:mm:ss A")}
            </p>
          </div>
        );
      })}
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <div className="mt-80 justify-between flex gap-6 items-center">
          <input
            type="text"
            {...register("remark", { required: true })}
            placeholder="Type a remark..."
            className="bg-gray-100 border-2 rounded outline-0 px-2 py-1 w-full font-semibold focus:ring focus:outline-none"
          />
          <i
            className="fa-solid fa-paper-plane bg-blue-900 rounded-full text-white cursor-pointer w-12 h-10 flex justify-center items-center"
            onClick={handleSubmit((data) => onSubmit(data))}
          ></i>
        </div>
      </form>
    </Sidebar>
  );
};

export default CommentSidebar;
