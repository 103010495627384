import axios from "axios";
import { Urls } from "store/urls";

export const getBirthdaysNholidaysRepo = (id) => {
  return axios({
    method: "GET",
    url: Urls.GET_BDAYS_AND_HOLIDAYS + id,
  });
};

export const getMessagesRepo = () => {
  return axios({
    method: "GET",
    url: Urls.GET_ALL_MESSAGES,
  });
};

export const getShiftByUserIdRepo = (id, params) => {
  return axios({
    method: "GET",
    url: Urls.GET_SHIFT_BY_USER_ID + id,
    params,
  });
};

export const getAttendanceByEmpIdRepo = (id, params) => {
  return axios({
    method: "GET",
    url: Urls.GET_ATTENDANCE_BY_EMPLOYEE + id,
    params,
  });
};

export const postAttendaceBreakoutRepo = (data) => {
  return axios({
    method: "POST",
    url: Urls.POST_ATTENDANCE_BREAKOUT,
    data,
  });
};
export const postAttendaceBreakinRepo = (data) => {
  return axios({
    method: "POST",
    url: Urls.POST_ATTENDANCE_BREAKIN,
    data,
  });
};

export const postRaiseQueryRepo = (data) => {
  return axios({
    method: "POST",
    url: Urls.POST_RAISE_QUERRY,
    data,
  });
};
export const postApplyLeaveRepo = (data) => {
  return axios({
    method: "POST",
    url: Urls.POST_APPLY_LEAVE,
    data,
  });
};

export const postAddMissedPunchRepo = (data) => {
  return axios({
    method: "POST",
    url: Urls.POST_ADD_MISSED_PUNCH,
    data,
  });
};

export const postAttendancePunchInRepo = (data) => {
  return axios({
    method: "POST",
    url: Urls.POST_ATTENDANCE_PUNCHIN,
    data,
  });
};
export const postAttendancePunchOutRepo = (data) => {
  return axios({
    method: "POST",
    url: Urls.POST_ATTENDANCE_PUNCHOUT,
    data,
  });
};

export const putShiftUpdateWorksheetRepo = (data) => {
  return axios({
    method: "PUT",
    url: Urls.PUT_SHIFT_UPDATE_WORKSHEET,
    data,
  });
};
