import { useState, useContext, useEffect } from "react";
// import axios from "axios";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
//import { withStyles } from "@material-ui/styles";
import {
  makeStyles,
  Typography,
  IconButton,
  TextareaAutosize,
  Button,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { QueriesAllTicketContext } from "./index";
import useAuth from "hooks/useAuth";
import moment from "moment";
import { useDispatch } from "react-redux";
import { assignedToUpdate } from "../../../features/EmployeeManagement/Dashboard/DashboardSlice";

const formStyles = makeStyles((theme) => ({
  sectionHeading: {
    color: "#1F299C",
    fontWeight: "700",
    margin: "10px",
    marginBottom: "20px",
  },

  fieldContainer: {
    maxWidth: "250px",
    display: "inline-block",
    marginRight: "20px",
    // flexDirection: 'column'
  },

  subtitle: {
    marginLeft: "10px",
    color: "black",
    fontWeight: "600",
  },
  colorBlue: {
    color: "rgb(31,41,156)",
  },
  colorDarkBlue: {
    color: "rgb(22,33,91)",
  },
}));

const AssignToDialog = (props) => {
  let theme = createTheme();
  theme = responsiveFontSizes(theme);
  const myClasses = formStyles();
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [comments, setComments] = useState("");
  const { allEmployees, setFetchAllTickets } = useContext(
    QueriesAllTicketContext
  );
  const auth = useAuth();

  const handleCloseDialog = () => {
    props.closeForm();
  };

  function removeUserData(commentsArr) {
    let comments = commentsArr.map((comment) => {
      if (comment.user_id)
        if (comment.user_id._id._id)
          return {
            user_id: comment.user_id._id._id,
            comment: comment.comment,
            date: comment.date,
          };
        else if (comment.user_id._id)
          return {
            user_id: comment.user_id._id,
            comment: comment.comment,
            date: comment.date,
          };
        else return comment;
    });
    return comments;
  }

  const handleSave = async () => {
    var data = {
      ...props.ticketData,
      assigned_to: name,
      approver_id: auth.user._id,
      comments: removeUserData([
        ...props.ticketData.comments,
        {
          comment: comments,
          date: moment().format(),
          user_id: { _id: auth.user },
        },
      ]),
      user_id: props.ticketData.user_id._id,
      action: "assignUpdate",
    };

    for (const property in data) {
      if (data[property] === null) data[property] = "";
    }

    dispatch(assignedToUpdate(data))
      .then((data) => {
        // const response = data.payload;
        handleCloseDialog();
        setFetchAllTickets(true);
      })
      .catch((error) => {
        console.log(error);
      });

    // try {
    //   const test = await axios.put(
    //     `${process.env.REACT_APP_API_BASE_URL}/requests/update`,
    //     data,
    //     {
    //       headers: {
    //         token: auth.token,
    //       },
    //     }
    //   );
    //   console.log("assigned to",test);
    //   console.log("-------------------");

    //   console.log(data);
    //   handleCloseDialog();
    //   setFetchAllTickets(true);
    // } catch (err) {
    //   console.log("Error -> ", err);
    // }
  };

  useEffect(() => {
    if (props.ticketData?.assigned_to?._id) {
      setName(props.ticketData?.assigned_to?._id);
    }
  }, [props.ticketData]);

  return (
    <ThemeProvider theme={theme}>
      <div style={{ position: "relative", height: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "10px",
          }}
        >
          <Typography
            variant="h5"
            // className = {classes.colorDarkBlue}
            style={{
              fontWeight: "600",
              display: "flex",
              alignItems: "center",
            }}
          >
            Assign To
          </Typography>

          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            style={{
              margin: "0",
              padding: "0",
              color: "black",
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <hr></hr>

        <Typography
          variant="h4"
          className={myClasses.colorDarkBlue}
          style={{
            fontWeight: "600",
            margin: "30px 20px",
          }}
        >
          Requester No: {props.requester}
        </Typography>

        <Typography
          variant="h6"
          className={myClasses.colorDarkBlue}
          style={{
            fontWeight: "600",
            margin: "20px 20px 10px 20px",
          }}
        >
          Name*
        </Typography>

        <FormControl
          variant="outlined"
          style={{
            minWidth: "300px",
            margin: "0px 20px",
          }}
        >
          <Select
            value={name}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            style={{ backgroundColor: "rgb(247,248,252)" }}
            onChange={(e) => setName(e.target.value)}
          >
            {allEmployees.map((emp, index) => (
              <MenuItem key={index} value={emp._id}>
                {emp.first_name + " " + emp.last_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Typography
          variant="h6"
          className={myClasses.colorDarkBlue}
          style={{
            fontWeight: "600",
            margin: "20px 20px",
          }}
        >
          Comments*
        </Typography>

        <TextareaAutosize
          onChange={(e) => {
            setComments(e.target.value);
          }}
          style={{
            width: "90%",
            height: "110px",
            backgroundColor: "rgb(247,248,252)",
            margin: "0px 20px",
            padding: "10px",
          }}
          // value={resign.leaving_comment}
          aria-label="minimum height"
          minRows={10}
          placeholder="Enter Comments"
        />

        <Typography
          variant="h6"
          className={myClasses.colorDarkBlue}
          style={{
            fontWeight: "600",
            margin: "20px 20px 10px 20px",
          }}
        >
          Status*
        </Typography>

        <FormControl
          variant="outlined"
          style={{
            minWidth: "300px",
            margin: "0px 20px",
          }}
        >
          <Select
            // value={positionSelected}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            style={{ backgroundColor: "rgb(247,248,252)" }}
            // onChange={(e) => setStatus(e.target.value)}
          >
            <MenuItem value="Cancelled">Cancelled</MenuItem>
            <MenuItem value="Pending">Pending</MenuItem>
            <MenuItem value="Accepted">Accepted</MenuItem>
            <MenuItem value="In Progress">In Progress</MenuItem>
            <MenuItem value="Closed">Closed</MenuItem>
          </Select>
        </FormControl>

        <div
          style={{
            position: "absolute",
            bottom: "0",
            left: "0",
            width: "100%",
          }}
        >
          <hr></hr>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "10px 10px 10px 0px",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              style={{
                marginRight: "10px",
              }}
              onClick={handleCloseDialog}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSave()}
              disabled={!name || !comments}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default AssignToDialog;
