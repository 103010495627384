import React from "react";
import { Typography, Grid, Paper } from "@material-ui/core";
import useAuth from "hooks/useAuth";
import myStyles from "../ProfileStyles";

function BankDetailsPanel() {
  const myClasses = myStyles();
  const auth = useAuth();
  return (
    <Grid container direction='row' spacing={4}>
      <Grid item xs={12} sm={6} lg={4}>
        <Paper component='div' className={myClasses.paper}>
          <Grid container direction='row' justify='space-evenly'>
            <Grid item xs={12} className={myClasses.label}>
              <Typography variant='h5' color='primary'>
                Account details
              </Typography>
            </Grid>

            <Grid item xs={6} style={{ textAlign: "left" }}>
              <Typography variant='subtitle1' className={myClasses.info}>
                &nbsp; Account No. :{" "}
              </Typography>
              <Typography variant='subtitle1' className={myClasses.info}>
                &nbsp; Bank Name :{" "}
              </Typography>
              <Typography variant='subtitle1' className={myClasses.info}>
                &nbsp; Institution No. :{" "}
              </Typography>
              <Typography variant='subtitle1' className={myClasses.info}>
                &nbsp; Transit No. :{" "}
              </Typography>
            </Grid>

            <Grid item xs={5} style={{ textAlign: "left" }}>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {(auth.user.account_details &&
                  auth.user.account_details.account_no) ||
                  ""}{" "}
              </Typography>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {(auth.user.account_details &&
                  auth.user.account_details.bank_name) ||
                  ""}{" "}
              </Typography>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {(auth.user.account_details &&
                  auth.user.account_details.institution_no) ||
                  ""}{" "}
              </Typography>
              <Typography
                variant='body1'
                display='block'
                className={myClasses.infoValues}
                gutterBottom
              >
                {" "}
                {(auth.user.account_details &&
                  auth.user.account_details.transit_no) ||
                  ""}{" "}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default BankDetailsPanel;
