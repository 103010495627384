import {
  getAllCommunicationMessagesRepo,
  deleteCommunicationRepo,
  createCommunicationRepo,
  editCommunicationRepo,
} from '../../repositories/HrManagement/Communications';
import {
  getCommunicationPending,
  getCommunicationFulfilled,
  getCommunicationRejected,
  deleteCommunicationPending,
  deleteCommunicationFulfilled,
  deleteCommunicationRejected,
  createCommunicationPending,
  createCommunicationFulfilled,
  createCommunicationRejected,
  editCommunicationPending,
  editCommunicationFulfilled,
  editCommunicationRejected,
} from '../../slices/HrManagement/Communications';

export const getAllCommunications = (params) => {
  return async (dispatch) => {
    dispatch(getCommunicationPending());
    await getAllCommunicationMessagesRepo(params)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getCommunicationFulfilled(response?.data.data.data));
        } else {
          dispatch(getCommunicationRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(getCommunicationRejected(error.message));
        }
      });
  };
};

export const deleteCommunicationByID = (id) => {
  return async (dispatch) => {
    console.log('step 1');
    dispatch(deleteCommunicationPending());

    await deleteCommunicationRepo(id)
      .then((response) => {
        if (response.status === 200) {
          console.log('response', response);
          dispatch(deleteCommunicationFulfilled(response?.data));
        } else {
          dispatch(deleteCommunicationRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(deleteCommunicationRejected(error.message));
        }
      });
  };
};

export const editCommunicationByID = (id, data) => {
  return async (dispatch) => {
    dispatch(editCommunicationPending());
    await editCommunicationRepo(id, data)
      .then((response) => {
        if (response.status === 200) {
          dispatch(editCommunicationFulfilled(response?.data));
        } else {
          dispatch(editCommunicationRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(editCommunicationRejected(error.message));
        }
      });
  };
};

export const createCommunication = (data) => {
  return async (dispatch) => {
    dispatch(createCommunicationPending());

    await createCommunicationRepo(data)
      .then((response) => {
        if (response.status === 200) {
          dispatch(createCommunicationFulfilled(response?.data));
        } else {
          dispatch(createCommunicationRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(createCommunicationRejected(error.message));
        }
      });
  };
};
