import React, { useEffect, useState } from "react";
import { Typography, IconButton } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { useDasboardStyles } from "./Style";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Shift from "./Shift";

export const headers = [
  "DATE",
  "SITE",
  "EMPLOYEE",
  "EMPLOYEE-ID",
  "POSITION",
  "SCHED",
  "SHIFT",
  "IN TIME",
  "OUT TIME",
  "WORKED",
  "BREAK",
  "DIFFERENCE",
];

const ProjectCard = (props) => {
  let shifts = props.row.shifts;

  const [projectOpen, setProjectOpen] = useState(true);
  const classes = useDasboardStyles();
  // console.log(props);
  useEffect(() => {
    if (props.loadingFalse) {
      props.loadingFalse();
    }
  }, []);

  const showHeaders = () =>
    headers.map((header, index) => (
      <TableCell
        key={index}
        style={{
          fontSize: "16px",
          padding: "5px",
          fontWeight: "bold",
          color: "rgb(31,41,156)",
          width: (window.screen.availWidth - 150) / 10 + "px"
        }}
      >
        {" "}
        {header}
      </TableCell>
    ));

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell
          component='th'
          scope='row'
          style={{
            padding: "0px",
            backgroundColor: "rgb(0,100,156)",
            color: "white",
          }}
          colSpan={25}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0px",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "10px 0",
                }}
              >
                <Typography
                  style={{
                    textAlign: "left",
                    marginLeft: "16px",
                  }}
                >
                  Project:
                </Typography>

                <Typography
                  style={{
                    fontWeight: "600",
                    textAlign: "left",
                    marginLeft: "16px",
                  }}
                >
                  {console.log(props.row,'abc')}
                  {props.row.project_name}{" "}
                </Typography>

                <Typography
                  style={{
                    fontWeight: "600",
                    textAlign: "left",
                    marginLeft: "50px",
                  }}
                >
                  {
                    //some changes here
                  /* {props.showName
                    ? `Employee: ${user?.first_name} ${user?.last_name} (${user?.emp_code})`
                    : ""} */}
                </Typography>
              </div>
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                aria-label='expand row'
                size='small'
                onClick={() => setProjectOpen(!projectOpen)}
              >
                {projectOpen ? (
                  <KeyboardArrowUpIcon style={{ color: "white" }} />
                ) : (
                  <KeyboardArrowDownIcon style={{ color: "white" }} />
                )}
              </IconButton>
            </div>
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={25}>
          <Collapse in={projectOpen} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table
                className={classes.table}
                size='small'
                aria-label='purchases'
                style={{ tableLayout: "fixed", width: "100%" }}
              >
                <TableBody style={{ overflowX: "auto", width: "100%" }}>
                  {!!shifts.length && (
                    <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                      {showHeaders()}
                    </TableRow>
                  )}

                  {shifts.map((row, index) =>
                      !!row._id && (
                        <TableRow
                          key={index}
                          sx={{ "& > *": { borderBottom: "unset" } }}
                        >
                          <Shift
                            template={row}
                            jobsiteTitle={row.jobsite}
                          />
                        </TableRow>
                      )
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ProjectCard;
