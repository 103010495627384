import React from "react";
import { Link } from "react-router-dom";
import AssetAssignSidebar from "../AssetAssignSidebar";
import { Menu, MenuItem, IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const TopAssetDetails = ({
  assetAddUpdateHandler,
  assignAssetHandler,
  assetRemarkHandler,
}) => {
  return (
    <div className="border-b-2">
      <div className="p-5">
        <div className="flex justify-between text-blue-900 flex-wrap">
          <Link to="/assets/allassets" className="flex items-center gap-5">
            <i class="fa-solid fa-arrow-left"></i>
            <p>Asset Details</p>
          </Link>
          <div className="flex items-center gap-5">
            <div
              className="flex gap-2 items-center cursor-pointer"
              onClick={() => assetRemarkHandler()}
            >
              <i className="fa-solid fa-message text-sm mt-1"></i>
              <p>Remark</p>
            </div>
            <div>
              <button
                className="bg-blue-900 px-3 py-1 rounded text-gray-100"
                onClick={() => assignAssetHandler()}
              >
                + Assign Asset
              </button>
            </div>
            <div className="flex items-center gap-5">
              <button
                className="bg-blue-900 px-3 py-1 rounded text-gray-100"
                onClick={() => assetAddUpdateHandler()}
              >
                Edit Asset
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopAssetDetails;
