import axios from 'axios';
import { Urls, BASE_URL } from 'store/urls';

export const getAllCommunicationMessagesRepo = (params) => {
  return axios({
    baseURL: BASE_URL,
    method: 'GET',
    url: Urls.GET_ALL_COMMUNICATION_MESSAGES,
    params: {
      pageNumber: params.page,
      limit: params.rowsPerPage,
    },
  });
};

export const deleteCommunicationRepo = (id) => {
  return axios({
    baseURL: BASE_URL,
    method: 'DELETE',
    url: Urls.DELETE_COMMUNICATION_BY_ID + `${id}`,
  });
};

export const editCommunicationRepo = (id, data) => {
  return axios({
    baseURL: BASE_URL,
    method: 'PUT',
    url: Urls.EDIT_COMMUNICATION_BY_ID + `${id}`,
    data: data,
  });
};

export const createCommunicationRepo = (data) => {
  return axios({
    baseURL: BASE_URL,
    method: 'POST',
    url: Urls.CREATE_COMMUNICATION,
    data: data,
  });
};
