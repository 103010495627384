import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import myStyles from './ProfileStyles';

import {
  DialogTitle,
  DialogContentText,
  DialogActions,
  Typography,
  Container,
  CssBaseline,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  IconButton,
  Dialog,
  Button,
  Backdrop,
  CircularProgress,
  InputLabel,
} from '@material-ui/core';
import { TextareaAutosize } from '@mui/base';

import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import useAuth from 'hooks/useAuth';
import axios from 'axios';
import moment from 'moment';
import { DialogContent } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllCommunications,
  deleteCommunicationByID,
  editCommunicationByID,
  createCommunication,
} from '../../store/actions/HrManagement/Communications';
import {
  clearDeleteCommunication,
  clearEditCommunication,
  clearCreateCommunication,
} from '../../store/slices/HrManagement/Communications';

const headCells = [
  // { id: "id", numeric: false, disablePadding: true, label: "Message_ID" },
  {
    id: 'message-content',
    numeric: false,
    disablePadding: false,
    label: 'Message_Content',
  },
  {
    id: 'message-date',
    numeric: false,
    disablePadding: false,
    label: 'Message_Date',
  },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ backgroundColor: '#F7F8FC' }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={
              headCell.numeric
                ? 'right'
                : headCell.id === 'message-date'
                ? 'right'
                : 'left'
            }
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ minWidth: '150px' }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell></TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f7f8fc',
    height: '100vh',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function ListMessages(props) {
  const [allMessages, setAllMessages] = useState([]);
  const dispatch = useDispatch();
  const { messages } = useSelector((state) => state.communication);
  const { deleteMessages } = useSelector((state) => state.communication);
  const { editMessages } = useSelector((state) => state.communication);
  const { createdMessages } = useSelector((state) => state.communication);

  // const auth = useAuth();
  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  //headers for csv
  const classes = useStyles();
  const [rows, setRows] = React.useState([]);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [editOpen, setEditOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [originalRows, SetOriginalRows] = React.useState([]);

  const [openAddMessageNew, setOpenAddMessageNew] = React.useState(false);
  const [message_content, setMessageContent] = useState('');
  const [message_id, setMessageId] = React.useState('');
  const [viewOpen, setViewOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [selected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [totalCount, setTotalCount] = React.useState(0);
  const [rowSelected, setRowSelected] = React.useState({});

  const getMessages = async () => {
    setIsLoading(true);
    dispatch(getAllCommunications({ page: page + 1, rowsPerPage }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getAllCommunications({ page: newPage + 1, rowsPerPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    let l = parseInt(event.target.value, 10);
    setRowsPerPage(l);
    setPage(0);

    dispatch(getAllCommunications({ page: page + 1, rowsPerPage: l }));
  };

  // const handleClickNewOpen = () => {
  //   setOpenAddMessageNew(true);
  // };

  // const handleNewClose = () => {
  //   setOpenAddMessageNew(false);
  // };

  // const handleClose = () => {
  //   setOpenAddMessageNew(false);
  //   setEditOpen(false);
  //   setRowSelected({});
  // };

  const handleClickViewOpen = (e, selectedRow) => {
    setModalOpen(true);
    setRowSelected(selectedRow);
    setIsEdit(false);
  };
  const handleClickEditMessageOpen = (e, message) => {
    setMessageContent(message.message_content);
    setMessageId(message._id);
    // alert(message.message_content);
    setModalOpen(true);
    setIsEdit(true);
    // setRowSelected(selectedRow);
  };

  const handleClickDeleteMessageOpen = async (e, message) => {
    setShowDeleteDialog(true);
    setMessageId(message._id);
  };

  const deleteMessage = async (e) => {
    dispatch(deleteCommunicationByID(message_id));
    setShowDeleteDialog(false);

    // await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/communication/deleteMessage/${message_id}`)
    //   .then(res => {
    //     setShowDeleteDialog(false);
    //     getMessages();
    //   })
    //   .catch(err => console.log(err, 'err'));
  };

  const handleViewClose = () => {
    setModalOpen(false);
    setRowSelected({});
  };

  const handleSubmit = async () => {
    const messageData = {
      message_content: message_content,
    };
    if (isEdit) {
      dispatch(editCommunicationByID(message_id, messageData));
      // response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/communication/update/${message_id}`, messageData);
    } else {
      dispatch(createCommunication(messageData));
      // response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/communication/create`, data);
    }
    setModalOpen(!modalOpen);
    setMessageContent('');
  };

  useEffect(() => {
    getMessages();
  }, []);

  useEffect(() => {
    if (messages?.fulfilled) {
      setAllMessages(messages?.fulfilled.data);
      setTotalCount(messages.fulfilled.length);
      setIsLoading(false);
    }
  }, [messages?.fulfilled, messages?.fulfilled?.length]);

  useEffect(() => {
    if (editMessages.fulfilled) {
      dispatch(getAllCommunications({ page: page + 1, rowsPerPage }));
      dispatch(clearEditCommunication());
    }
    if (createdMessages.fulfilled) {
      dispatch(getAllCommunications({ page: page + 1, rowsPerPage }));
      dispatch(clearCreateCommunication());
    }
    if (deleteMessages?.fulfilled) {
      dispatch(getAllCommunications({ page: page + 1, rowsPerPage }));
      dispatch(clearDeleteCommunication());
    }
  }, [editMessages, createdMessages, deleteMessages]);

  return (
    <Container className={classes.root}>
      <ThemeProvider theme={theme}>
        <div
          style={{ padding: '28px 0px' }}
          className={clsx(classes.content, {
            [classes.contentShift]: props.isDrawerOpen,
          })}
        >
          <CssBaseline>
            <ThemeProvider theme={theme}>
              <Container
                maxWidth='xl'
                // align="center"
                style={{ padding: '0px', margin: '0px' }}
              >
                <Paper>
                  <Grid container spacing={1} justify='center'>
                    <Grid item xs={12}>
                      <Typography
                        variant='h4'
                        align='left'
                        style={{
                          color: '#1F299C',
                          fontWeight: '600',
                          paddingLeft: '0.5em',
                          paddingTop: '0.5em',
                        }}
                      >
                        Communications
                      </Typography>
                    </Grid>

                    <Grid item xs={11}>
                      <Grid
                        container
                        justify='space-between'
                        alignItems='flex-end'
                      >
                        <Button
                          variant='contained'
                          style={{
                            color: 'white',
                            backgroundColor: 'rgb(31, 41, 156)',
                            margin: '2px',
                          }}
                          onClick={(e) => handleClickViewOpen(e, rows)}
                        >
                          Create new Broadcast
                        </Button>
                      </Grid>
                    </Grid>

                    {/* -----------------TABLE------------------------------ */}
                    <Grid item xs={11} style={{ padding: '30px 0px' }}>
                      <Paper className={classes.paper}>
                        <TableContainer>
                          <Table
                            className={classes.table}
                            aria-labelledby='tableTitle'
                            size={dense ? 'small' : 'medium'}
                            aria-label='enhanced table'
                          >
                            <EnhancedTableHead
                              classes={classes}
                              numSelected={selected.length}
                              order={order}
                              orderBy={orderBy}
                              rowCount={rows.length}
                            />

                            <TableBody>
                              {allMessages &&
                                allMessages.length &&
                                allMessages.map((message) => (
                                  <TableRow>
                                    <TableCell>
                                      {message.message_content}
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'right' }}>
                                      {message.message_date}
                                    </TableCell>
                                    {/* <TableCell style={{ padding: "0px" }}>
                                          <IconButton
                                            onClick={(e) =>
                                              handleClickViewOpen(e, rows)
                                            }
                                          >
                                            <VisibilityIcon />
                                          </IconButton>
                                        </TableCell> */}
                                    <TableCell
                                      style={{
                                        padding: '0px',
                                        textAlign: 'right',
                                      }}
                                    >
                                      <IconButton
                                        onClick={(e) =>
                                          handleClickEditMessageOpen(e, message)
                                        }
                                      >
                                        <EditIcon />
                                      </IconButton>
                                      <IconButton
                                        style={{ marginLeft: '15px' }}
                                        onClick={(e) =>
                                          handleClickDeleteMessageOpen(
                                            e,
                                            message
                                          )
                                        }
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          style={{ backgroundColor: '#F7F8FC' }}
                          rowsPerPageOptions={[15, 25, 50]}
                          component='div'
                          count={totalCount}
                          rowsPerPage={rowsPerPage}
                          page={page}
                        />
                      </Paper>
                    </Grid>
                  </Grid>
                </Paper>
              </Container>

              {/*=========================================== the form dialog=============================================================== */}

              {/* text fields have input enabled */}
              {/* ---------NEW------------------------------- */}
              {/* <Dialog
                open={openAddMessageNew}
                aria-labelledby="form-dialog-title"
                maxWidth="md"
                PaperProps={{
                  style: {
                    position: "absolute",
                    margin: "0px",
                    padding: "0px",
                    right: "0px",
                    minHeight: "115vh",
                    bottom: "0px",
                    top: "0px",
                    borderRadius: "0px",
                  },
                }}
              >
                <DialogContent style={{ margin: "0px", padding: "0px" }}>
                  <FormStepperNew
                    readOnly={false}
                    operation={"add"}
                    closeForm={handleNewClose}
                    close={handleClose}
                  />
                </DialogContent>
              </Dialog> */}
              {/* ---------NEW------------------------------- */}

              {/* text fields have input disabled --- ReadOnly*/}
              {/* <Dialog
                open={viewOpen}
                onClose={handleViewClose}
                aria-labelledby="form-dialog-title"
                maxWidth="lg"
                PaperProps={{
                  style: {
                    width: "550px",
                  },
                }}
              >

                <ScrollableTabsButtonAuto
                  operation={"view"}
                  readOnly={true}
                  // closeForm = {handleViewClose}
                  {...rowSelected}
                />
              </Dialog> */}
              <Dialog
                open={modalOpen}
                onClose={handleViewClose}
                aria-labelledby='form-dialog-title'
                maxWidth='lg'
                PaperProps={{
                  style: {
                    width: '550px',
                  },
                }}
              >
                {/* <FormStepper
                  readOnly={true}
                  operation={"view"}
                  closeForm={handleViewClose}
                  {...rowSelected}
                /> */}
                <CssBaseline>
                  <Container className={classes.container}>
                    <Grid
                      container
                      alignItems='left'
                      className={classes.profileBrief}
                      style={{
                        marginBottom: '2%',
                        marginTop: '2%',
                        width: '500px',
                      }}
                    >
                      <h1
                        style={{
                          marginLeft: '10px',
                          backgroundColor: '#ffffff',
                        }}
                      >
                        {isEdit ? 'Edit' : 'Add'} Broadcast Message
                      </h1>
                    </Grid>
                    <Grid container direction='row'>
                      <Grid md={12}>
                        <div className={classes.fieldContainer}>
                          <InputLabel
                            id='first_name'
                            className={classes.subtitle}
                          >
                            <Typography variant='subtitle2'>
                              Enter Message Content
                              <span className='text-red-500 font-bold'>*</span>
                            </Typography>
                          </InputLabel>
                          <TextareaAutosize
                            aria-label='minimum height'
                            minRows={8}
                            value={message_content}
                            onChange={(e) => {
                              setMessageContent(e.target.value);
                            }}
                            placeholder='Enter message content'
                            style={{
                              width: 500,
                              border: '1px solid black',
                              marginTop: '20px',
                              marginBottom: '20px',
                            }}
                          />
                        </div>
                        <Button
                          variant='contained'
                          color='primary'
                          onClick={handleSubmit}
                          style={{ margin: '5px', marginBottom: '30px' }}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Container>
                </CssBaseline>
                {/* <ScrollableTabsButtonAuto
                  operation={"view"}
                  readOnly={true}
                  // closeForm = {handleViewClose}
                  {...rowSelected}
                /> */}
              </Dialog>
              <Dialog
                open={showDeleteDialog}
                onClose={() => setShowDeleteDialog(false)}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
              >
                <DialogTitle id='alert-dialog-title'>
                  Are you sure you want to delete this message?
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id='alert-dialog-description'></DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setShowDeleteDialog(false)}>
                    Cancel
                  </Button>
                  <Button onClick={(e) => deleteMessage()} autoFocus>
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
            </ThemeProvider>
          </CssBaseline>
        </div>
        <Backdrop style={{ zIndex: 9999, color: '#fff' }} open={isLoading}>
          <CircularProgress color='inherit' />
        </Backdrop>
      </ThemeProvider>
    </Container>
  );
}

export default ListMessages;
