import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "pages/sidebar/Sidebar";
import Api from "hooks/AjaxAction";
import useAuth from "hooks/useAuth";
import { updateAsset } from "store/actions/AssetManagement/allAssets";
import { clearUpdateAsset } from "store/slices/AssetManagement/allAssets";
const PaperClipSidebar = ({ data, sidebarHandler }) => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const [fileName, setFileName] = useState("");
  const { updatedAsset } = useSelector((state) => state.allAsset);

  const onSelectFile = (files) => {
    const onSuccess = (data) => {
      console.log("....on uploading image", data);
      setFileName(data.filename);
    };
    // Upload to S3
    Api.fileUpload(files, "org-glen", auth.token, onSuccess);
  };

  const onSubmit = (data) => {
    console.log("...data", data);
    let params = { ...data };
    if (data._id) {
      dispatch(updateAsset(data?._id, params));
    }
  };

  useEffect(() => {
    if (updatedAsset.fulfilled) {
      dispatch(clearUpdateAsset());
      sidebarHandler();
    }
  }, [updatedAsset]);

  return (
    <Sidebar modalTitle="Filter" sidebarHandler={sidebarHandler}>
      <div>
        <div className="mt-4">
          <p className="text-base mb-1">Attach Asset Photo *</p>
          <input onChange={(e) => onSelectFile(e.target.files)} type="file" />
          <div className="flex justify-between items-center gap-2  mt-2 mb-2">
            <input
              className="bg-gray-100 border-2 rounded-md outline-0 px-2 py-3 w-full font-semibold focus:ring focus:outline-none"
              placeholder="Image address"
            />
          </div>
        </div>
      </div>
      <div className="flex justify-center sm:justify-between flex-wrap gap-2 mb-52">
        <div className="mt-4">
          <div>
            <img src={data.image} alt="" className="w-52 border p-2" />
            <div className="w-52 border px-2 py-1 text-sm text-gray-700 flex justify-between items-center font-normal">
              <p>Asset Image.JPG</p>
              <i className="fa-solid fa-trash cursor-pointer"></i>
            </div>
          </div>
        </div>
      </div>
      <div className="border-b-2 border-gray-300 my-5"></div>
      <div className="flex justify-end gap-5">
        <p
          className="py-1 px-3 font-semibold border cursor-pointer border-blue-700 rounded"
          onClick={sidebarHandler}
        >
          Cancel
        </p>
        <p
          className="py-1 px-3 font-semibold border cursor-pointer border-blue-700 rounded bg-indigo-700 text-gray-50"
          onClick={() => {
            onSubmit();
          }}
        >
          Save
        </p>
      </div>
    </Sidebar>
  );
};

export default PaperClipSidebar;
