import React, { useEffect, Fragment } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import "@fontsource/roboto";
// import useAuth from "hooks/useAuth";
import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { useAssetStyles } from "../../Style";
import TopAssetAsignDetails from "./TopAssetAsignDetails";
import AssetAssignDetailsList from "./AssetAssignDetailsList";
import { getAssetByUserId } from "store/actions/AssetManagement/allAssets";
import { useDispatch, useSelector } from "react-redux";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { getAllAssetCategory } from "store/actions/AssetManagement/assetCategory";
import { getAllAssetSubCategory } from "store/actions/AssetManagement/assetSubCategory";
import { getAllAssetConditon } from "store/actions/AssetManagement/assetCondition";
import useAuth from "hooks/useAuth";

export const AssetAssignDetailsContainer = (props) => {
  let theme = createTheme();
  theme = responsiveFontSizes(theme);
  const classes = useAssetStyles();
  const params = useParams();
  const dispatch = useDispatch();
  const auth = useAuth();
  const { assetByUserId, allAssetListGroupByUser } = useSelector(
    (state) => state.allAsset
  );

  const { assetCategory, assetSubCategory, assetCondition } = useSelector(
    (state) => state
  );
  const [isLoading, setIsLoading] = React.useState(false);
  useEffect(() => {
    setIsLoading(true);
    dispatch(getAssetByUserId(params.id));
    dispatch(getAllAssetCategory());
    dispatch(getAllAssetSubCategory());
    dispatch(getAllAssetConditon());
  }, []);

  useEffect(() => {
    if (assetByUserId?.fulfilled) {
      setIsLoading(false);
      console.log(".....asset by");
    }
  }, [assetByUserId]);

  return (
    <Fragment>
      <div className="root">
        <ThemeProvider theme={theme}>
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: props.isDrawerOpen,
            })}
          >
            <div>
              <Typography
                style={{
                  textAlign: "left",
                  marignBottom: "20px",
                  minWidth: "230px",
                }}
              >
                Home {">"} Asset Management
              </Typography>

              <div className="bg-white pb-10 text-left">
                <TopAssetAsignDetails data={assetByUserId?.fulfilled} />
                {assetByUserId?.fulfilled &&
                  assetCategory?.allAssetCategory?.fulfilled &&
                  assetSubCategory?.assetSubCategoryList?.fulfilled && (
                    <AssetAssignDetailsList
                      auth={auth}
                      data={assetByUserId?.fulfilled}
                      categoryList={assetCategory?.allAssetCategory?.fulfilled}
                      subCategoryList={
                        assetSubCategory?.assetSubCategoryList?.fulfilled
                      }
                    />
                  )}
              </div>
              <Backdrop
                style={{ zIndex: 9999, color: "#fff" }}
                open={isLoading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
          </main>
        </ThemeProvider>
      </div>
    </Fragment>
  );
};
