import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Button,
  Divider,
  TextareaAutosize,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Select,
  MenuItem,
  withStyles,
  TextField,
} from "@material-ui/core";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import { useDispatch, useSelector } from "react-redux";
import { postRaiseQuery } from "../../store/actions/EmployeeManagement/HomePage";
import Api from "../../hooks/AjaxAction";

const formStyles = makeStyles((theme) => ({
  sectionHeading: {
    color: "#1F299C",
    fontWeight: "700",
    margin: "10px",
    marginBottom: "20px",
  },

  fieldContainer: {
    maxWidth: "250px",
    display: "inline-block",
    marginRight: "20px",
    // flexDirection: 'column'
  },

  subtitle: {
    marginLeft: "10px",
    color: "black",
    fontWeight: "600",
  },
  colorBlue: {
    color: "rgb(31,41,156)",
  },
  colorDarkBlue: {
    color: "rgb(22,33,91)",
  },
  formControl1: {
    width: "370px",
    position: "relative",
    margin: "10px 20px",
  },
}));

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "black",
    },
    "& .Mui-disabled": {
      color: "black",
    },

    "& .MuiInputBase": {
      background: "#F7F8FC",
      backgroundColor: "#F7F8FC",
      height: "20px",
    },
  },
})(TextField);

const RaiseQueryForm = (props) => {
  const {
    auth,
    openQuery,

    setIsLoading,
    open,
    setPunchingSuccess,
    setPunchingError,
    setOpenQuery,
    setOpen,
  } = props;

  const [raiserName] = useState("");
  const [raiseQuery, setRaiseQuery] = useState({
    user_id: auth.user._id,
    category: "",
    sub_category: "",
    priority: "",
    query: "",
    attachment: "",
    cycle_time: "05 Days",
    acceptance_time: "03 Days",
    resolution_time: "02 Days",
    status: "Pending",
    name: "",
    comments: [],
    approver_id: null,
  });
  const [fileUploadedSuccessfull, setFileUploadedSuccessfull] = useState(false);
  const [fileName, setFileName] = useState("");
  const { postRaiseQueryData } = useSelector((state) => state.homepage);
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const myClasses = formStyles();

  const handleInputChange = (event) => {
    const key = event.target["name"];
    const value = event.target.value;
    const newState = { ...raiseQuery };
    newState[key] = value;
    setRaiseQuery(newState);
  };

  const handleQueryClose = () => {
    setOpenQuery(false);
    setRaiseQuery({
      user_id: auth.user._id,
      category: "",
      sub_category: "",
      priority: "",
      query: "",
      attachment: "",
      cycle_time: "05 Days",
      acceptance_time: "03 Days",
      resolution_time: "02 Days",
      status: "Pending",
      name: "",
      approver_id: null,
    });
    setOpen(false);
  };

  const handleSubmitQuery = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    if (
      raiseQuery.category === "" &&
      raiseQuery.sub_category === "" &&
      raiseQuery.priority === "" &&
      raiseQuery.query === ""
    ) {
      setIsLoading(false);
      handleQueryClose();
      return;
    }
    const data = {
      ...raiseQuery,
      name: raiserName,
    };
    dispatch(postRaiseQuery(data));
  };

  const onSelectFile = (files) => {
    setIsLoading(true);

    const onSuccess = (data) => {
      setRaiseQuery({ ...raiseQuery, attachment: data.file_url });
      setFileName(data.filename);
      setIsLoading(false);
      setFileUploadedSuccessfull(true);
    };

    const onError = (error) => {
      setIsLoading(false);
    };

    // Upload to S3
    Api.fileUpload(files, "org-glen", auth.token, onSuccess, onError);
  };

  useEffect(() => {
    if (postRaiseQueryData?.fullfilled) {
      setIsLoading(false);
      setPunchingSuccess("Query Raised Succesfully!");
    }
    if (postRaiseQueryData?.rejected) {
      setIsLoading(false);
      setPunchingError("Something went wrong.. Please try again!");
    }
    handleQueryClose();
  }, [postRaiseQueryData]);

  return (
    <Dialog
      open={openQuery}
      onClose={handleQueryClose}
      aria-labelledby='form-dialog-title'
    >
      <DialogTitle
        style={{ color: "#1F299C", padding: "20px 24px 0px 24px" }}
        id='form-dialog-title'
      >
        <Typography variant='h4' fontWeight='600'>
          Raise Query
        </Typography>
      </DialogTitle>
      <br></br>
      <Divider />
      <DialogContent>
        {/* <Typography
        variant='h6'
        className={myClasses.colorDarkBlue}
        style={{
          fontWeight: "600",
          margin: "10px 20px",
        }}
      >
        Name*
      </Typography> */}

        {/* {allEmployees && (
        <Autocomplete
          id='combo-box-demo'
          name='assigned_to'
          options={allEmployees}
          getOptionLabel={(option) =>
            `${option.first_name} (${option.emp_code})`
          }
          style={{
            backgroundColor: "rgb(247,248,252)",
            boxShadow: "lightgray 0px 0px 5px",
            margin: "0px 20px 10px 20px",
            width: "370px",
            color: "#090909",
            fontWeight: 400,
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Search Employee'
              variant='outlined'
            />
          )}
          onChange={(e) => {
            const regExp = /\(([^)]+)\)/;
            let matches = regExp.exec(e.target.innerText);

            if (matches) {
              const user = allEmployees.find((user) => {
                return user.emp_code === matches[1];
              });

              setRaiserName(user.first_name + " " + user.last_name);
              handleInputChange({
                ...e,
                target: { value: user._id, name: "assigned_to" },
              });
            }
          }}
        />
      )} */}

        <Typography
          variant='h6'
          className={myClasses.colorDarkBlue}
          style={{
            fontWeight: "600",
            margin: "10px 20px",
          }}
        >
          Category*
        </Typography>

        <FormControl variant='outlined' className={myClasses.formControl1}>
          <Select
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            style={{ backgroundColor: "rgb(247,248,252)", color: "#090909" }}
            labelId='demo-simple-select-outlined-label1'
            name='category'
            id='category'
            value={raiseQuery.category}
            label='Category'
            onChange={(event) => handleInputChange(event)}
          >
            <MenuItem value='Admin'>Admin</MenuItem>
            <MenuItem value='HR'>HR</MenuItem>
            <MenuItem value='Payroll'>Payroll</MenuItem>
            <MenuItem value='Dispatch'>Dispatch</MenuItem>
            <MenuItem value='Accounts'>Accounts</MenuItem>
            <MenuItem value='QHSE'>QHSE</MenuItem>
            <MenuItem value='Inventory'>Inventory</MenuItem>
          </Select>
        </FormControl>

        <Typography
          variant='h6'
          className={myClasses.colorDarkBlue}
          style={{
            fontWeight: "600",
            margin: "0px 20px 10px 20px",
          }}
        >
          Sub Category*
        </Typography>

        <CssTextField
          variant='outlined'
          style={{
            backgroundColor: "rgb(247,248,252)",
            boxShadow: "lightgray 0px 0px 5px",
            margin: "0px 20px 10px 20px",
            width: "370px",
            color: "#090909",
          }}
          name='sub_category'
          id='sub_category'
          value={raiseQuery.sub_category}
          label='Sub Category'
          onChange={(event) => handleInputChange(event)}
        />

        <Typography
          variant='h6'
          className={myClasses.colorDarkBlue}
          style={{
            fontWeight: "600",
            margin: "0px 20px 10px 20px",
          }}
        >
          Priority
        </Typography>

        <FormControl variant='outlined' className={myClasses.formControl1}>
          <Select
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            style={{ backgroundColor: "rgb(247,248,252)", color: "#090909" }}
            name='priority'
            id='priority'
            value={raiseQuery.priority}
            label='Priority'
            onChange={(event) => handleInputChange(event)}
          >
            <MenuItem value='Highest'>Highest</MenuItem>
            <MenuItem value='Medium'>Medium</MenuItem>
            <MenuItem value='Low'>Low</MenuItem>
          </Select>
        </FormControl>

        <Typography
          variant='h6'
          className={myClasses.colorDarkBlue}
          style={{
            fontWeight: "600",
            margin: "10px 20px",
          }}
        >
          Description:*
        </Typography>

        <TextareaAutosize
          style={{
            height: "110px",
            backgroundColor: "#f5f5f5",
            margin: "0px 20px",
            width: "90%",
            padding: "8px",
            color: "#090909",
          }}
          aria-label='minimum height'
          minRows={10}
          placeholder='Enter Your Query'
          name='query'
          onChange={(e) => handleInputChange(e)}
          value={raiseQuery.query}
        />

        <Typography
          variant='h6'
          className={myClasses.colorDarkBlue}
          style={{
            fontWeight: "600",
            margin: "10px 20px",
          }}
        >
          Attachment*
        </Typography>

        <label
          style={{
            width: "90%",
            height: "110px",
            backgroundColor: "#f5f5f5",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "20px",
            margin: "0px 20px 20px 20px",
          }}
        >
          {fileName !== "" ? (
            fileName
          ) : (
            <p
              style={{
                fontSize: "20px",
              }}
            >
              Drag & Drop Here,{" "}
              <span style={{ color: "#1250ff", marginRight: "10px" }}>
                Or Browse File
              </span>
              <CloudUploadOutlinedIcon />
            </p>
          )}
          <input
            type='file'
            style={{ display: "none" }}
            onChange={(e) => onSelectFile(e.target.files)}
          />
        </label>
        {fileUploadedSuccessfull && (
          <p
            style={{
              color: "#00b500",
              fontSize: "16px",
              marginLeft: "20px",
            }}
          >
            Upload successful
          </p>
        )}
      </DialogContent>
      <Divider />
      <br></br>
      <DialogActions>
        <Button
          onClick={handleQueryClose}
          variant='contained'
          style={{
            color: "#1F299C",
            border: "1px solid #1F299C",
            backgroundColor: "white",
          }}
          color='primary'
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => handleSubmitQuery(e)}
          variant='contained'
          style={{ backgroundColor: "#1F299C", color: "white" }}
          color='primary'
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default RaiseQueryForm;
