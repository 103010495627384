import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import {
  makeStyles,
  Grid,
  Paper,
  Avatar,
  Typography,
  Box,
  CircularProgress,
  Button,
} from "@material-ui/core";
import Loader from "react-loader-spinner";
import back from "../../assets/images/back.jpg";
import protection from "../../assets/images/protection-shield-4.png";
import leave from "../../assets/images/leave.png";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import fileEdit from "../../assets/images/common-file-edit.png";
import moment from "moment";
import checkShield from "../../assets/images/check-shield.png";
import payStubIcon from "../../assets/images/Pay_stub.svg";
import missedPunchIcon from "../../assets/images/add_missed_punchin.svg";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    color: "#1F299C",
  },
  "&$checked": {
    color: "#1F299C",
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    borderRadius: "6px ",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: "#ffffff",
    color: "#1F299C",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 0,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    maxWidth: theme.spacing(10),
    maxHeight: theme.spacing(10),
    border: "2px solid #FFFFFF",
  },
  backdrop: {
    zIndex: 9999,
    color: "#fff",
  },
}));

const drawerWidth = 240;

const HomeView = (props) => {
  const {
    auth,
    todaysHrs,
    proceedWithPunch,
    canPunchOut,
    setPunchinType,
    setChoosenAttendanceType,
    setPunchStep,
    isConfirmPunchOut,
    isConfirmPunchIn,
    proceedWithAdhoc,
    punchInTime,
    punchOutTime,
    setOpenQuery,
    setOpen,
    location,
    setOpenMissedPunchin,
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const { bDays, messages } = useSelector((state) => state.homepage);
  const [lastPunchin, setLastPunchin] = useState("io");
  const [showMoreHolidays, setShowMoreHolidays] = useState(false);
  const [showMoreBirthdays, setShowMoreBirthdays] = useState(false);
  const [seeMoreBtn, setSeeMoreBtn] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleNavigateToPayslip = () => {
    history.push(`/payroll`);
  };

  const handleNavigateToQueries = () => {
    history.push(`/queriesAndTickets`);
  };
  const handleQueryOpen = async () => {
    setOpenQuery(true);
  };

  const handleMissedPunchOpen = () => {
    setOpenMissedPunchin(true);
  };

  const getHolidays = (holidays) => {
    const holidayArrs = holidays.map((item) => {
      return (
        <Fragment key={item._id}>
          <hr></hr>
          <Grid container spacing={3}>
            <Grid
              item
              xs
              style={{
                textAlign: "left",
                margin: "0px 12px",
                padding: "20px",
                fontSize: "14px",
              }}
            >
              <p style={{ color: "#1F299C", fontWeight: "bold" }}>
                {moment(item.date).format("dddd, MMM Do YYYY")}
              </p>
              <p
                style={{
                  marginTop: "10px",
                }}
              >
                {item.nameEn}
              </p>
            </Grid>
          </Grid>
        </Fragment>
      );
    });
    return holidayArrs.length ? (
      <>
        {holidayArrs.slice(0, showMoreHolidays ? holidayArrs.length : 3)}
        {holidayArrs.length > 3 ? (
          <h4
            style={{
              color: "#1F299C",
              padding: "10px",
              textAlign: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowMoreHolidays(!showMoreHolidays);
            }}
          >
            {showMoreHolidays ? "See Less" : "See More"}
          </h4>
        ) : (
          ""
        )}
      </>
    ) : (
      <>
        <hr></hr>
        <p style={{ color: "#9e9e9e", padding: "20px" }}>
          No holidays this month
        </p>
      </>
    );
  };

  const getBirthdays = (birthday) => {
    const bday = birthday.map((item) => {
      return (
        <Fragment key={item._id}>
          <hr></hr>

          <Grid
            container
            spacing={3}
            style={{
              padding: "20px 10px",
            }}
          >
            <Grid
              item
              xs={3}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px",
              }}
            >
              <Avatar alt="Image" src={item.image_url} />
            </Grid>
            <Grid item xs={9} style={{ textAlign: "left", fontSize: "14px" }}>
              <p>
                It’s
                <span style={{ color: "green", fontWeight: "bold" }}>
                  {" "}
                  {item.first_name} {item.last_name}
                </span>
                's birthday today! Don’t forget to wish!
              </p>
              <p style={{ color: "grey", marginTop: "5px" }}>
                Marketing Team | LS Media
              </p>
            </Grid>
          </Grid>
          <hr></hr>
        </Fragment>
      );
    });
    return bday.length ? (
      <>
        {bday.slice(0, showMoreBirthdays ? bday.length : 3)}
        {bday.length > 3 ? (
          <h4
            style={{
              color: "#1F299C",
              padding: "10px",
              textAlign: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowMoreBirthdays(!showMoreBirthdays);
            }}
          >
            {showMoreBirthdays ? "See Less" : "See More"}
          </h4>
        ) : (
          ""
        )}
      </>
    ) : (
      <>
        <hr></hr>
        <p style={{ color: "#9e9e9e", padding: "20px" }}>No birthdays today</p>
      </>
    );
  };

  useEffect(() => {
    let lastPunch = auth.user.last_punchin_timestamp
      ? moment(auth.user.last_punchin_timestamp).format(
          "ddd, MMM Do YYYY, hh:mm a"
        )
      : "You have not yet punched in yet";
    setLastPunchin(lastPunch);
  }, [location, auth.user.last_punchin_timestamp]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={clsx(classes.content, {
        [classes.contentShift]: props.isDrawerOpen,
      })}
    >
      <div className={classes.drawerHeader} />
      <Grid container spacing={1}>
        <Grid container item spacing={3}>
          {!auth.user && (
            <Loader
              type="Circles"
              color="#00BFFF"
              height={200}
              width={200}
              style={{ marginTop: "200px" }}
              // timeout={3000} //3 secs
            />
          )}

          {auth.user && (
            <React.Fragment>
              <Grid item xs={12} sm={12} lg={5} md={6}>
                <Paper
                  className={classes.paper}
                  style={{
                    backgroundImage: `url(${back})`,
                    backgroundSize: "cover",
                  }}
                >
                  <div
                    style={{
                      height: "206px",
                      padding: "1.5em",
                      backgroundColor: "rgba(31, 41, 156, 0.5)",
                    }}
                  >
                    <Avatar
                      alt="Image"
                      src={
                        auth.user.image_url ||
                        "https://image.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg"
                      }
                      className={classes.large}
                    />
                    <Typography
                      style={{
                        margin: "-4em 8em",
                        float: "left",
                        color: "white",
                        textAlign: "left",
                      }}
                    >
                      <span>
                        <b>
                          {auth.user.first_name} {auth.user.last_name}
                        </b>
                      </span>
                      <br></br>
                      <span style={{ color: "#F5F5F5" }}>{auth.user.role}</span>
                    </Typography>
                    <img
                      alt="file edit"
                      style={{ float: "right", margin: "-4em 0" }}
                      src={fileEdit}
                    />

                    <br></br>
                    <Typography
                      style={{
                        margin: "0em 0em",
                        float: "left",
                        color: "white",
                        textAlign: "left",
                      }}
                    >
                      <span style={{ color: "#F5F5F5" }}>
                        Employee ID :{" "}
                        <b style={{ margin: "0px 35px" }}>
                          {" "}
                          {auth.user.emp_code}
                        </b>
                      </span>
                      <br></br>
                      <span style={{ color: "#F5F5F5" }}>
                        Last punched in :{" "}
                        {lastPunchin && (
                          <b style={{ margin: "0px 9px" }}>{lastPunchin}</b>
                        )}
                      </span>
                    </Typography>
                  </div>
                </Paper>
                <br></br>
                <br></br>
                <Paper
                  className={classes.paper}
                  // style={{
                  //   height: "415px",
                  // }}
                >
                  <div style={{ padding: "4px" }}>
                    <h2
                      style={{
                        color: "#1F299C",
                        textAlign: "left",
                        margin: "10px 0",
                      }}
                    >
                      Attendance punching
                    </h2>
                    <hr></hr>
                    <div
                      style={{
                        textAlign: "left",
                        margin: "12px",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "800",
                          color: "#1F299C",
                        }}
                      >
                        {" "}
                        {new Date().toLocaleString("en-US", {
                          day: "2-digit",
                        })}{" "}
                        {new Date().toLocaleString("en-US", {
                          month: "long",
                        })}
                      </span>
                      <br></br>
                      <span
                        style={{
                          color: "#1F299C",
                        }}
                      >
                        {" "}
                        {new Date().getFullYear()}
                      </span>
                    </div>
                  </div>
                  <br></br>
                  <Box
                    position="relative"
                    display="inline-flex"
                    style={{
                      border: "1px solid lightgray",
                      borderRadius: "50%",
                    }}
                  >
                    <CircularProgress
                      variant="determinate"
                      value={(todaysHrs / 8) * 100}
                      style={{
                        color: "#3D9E5A",
                        height: "120px",
                        width: "120px",
                      }}
                    />
                    <Box
                      top={0}
                      left={0}
                      bottom={0}
                      right={0}
                      position="absolute"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography
                        variant="caption"
                        component="div"
                        color="textSecondary"
                      >
                        {todaysHrs}
                        <br></br>
                        Hrs Complete
                      </Typography>
                    </Box>
                  </Box>

                  <br></br>
                  <br></br>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      margin: "10px",
                    }}
                  >
                    {/* =============== Punch In/Out Button ================ */}
                    {(proceedWithPunch) && (
                      <Button
                        variant="contained"
                        style={
                          canPunchOut
                            ? {
                                backgroundColor: "#1F299C",
                                color: "#ffffff ",
                              }
                            : {
                                backgroundColor: "#3D9E5A",
                                color: "#ffffff ",
                              }
                        }
                        onClick={() => {
                          setPunchinType("normal");
                          setChoosenAttendanceType("Punch");
                          setPunchStep(1); //change
                          canPunchOut
                            ? isConfirmPunchOut(true)
                            : isConfirmPunchIn(true);
                        }}
                      >
                        <ExitToAppIcon />
                        {canPunchOut ? "Punch Out" : "Punch In"}
                      </Button>
                    )}

                    {/* =============== Break In/Out Button ================ */}

                    {/* {canPunchOut && (
                        <Button
                          variant='contained'
                          style={
                            !canBreakOut
                              ? {
                                  backgroundColor: "#1F299C",
                                  color: "#ffffff ",
                                }
                              : {
                                  backgroundColor: "#3D9E5A",
                                  color: "#ffffff ",
                                }
                          }
                          onClick={() => {
                            canBreakOut
                              ? isConfirmBreakOut(true)
                        setAddresssetAddress      : isConfirmBreakIn(true);
                          }}
                        >
                          <ExitToAppIcon />
                          {canBreakOut ? "Break Start" : "Break End"}
                        </Button>
                      )} */}

                    {/* =============== Adhoc Punch In/Out Button ================ */}

                    {proceedWithAdhoc &&  (
                      // {(!canPunchOut) && (
                      <Button
                        variant="contained"
                        style={
                          canPunchOut
                            ? {
                                backgroundColor: "#1F299C",
                                color: "#ffffff ",
                              }
                            : {
                                backgroundColor: "#3D9E5A",
                                color: "#ffffff ",
                              }
                        }
                        onClick={() => {
                          setPunchinType("adhoc");
                          setChoosenAttendanceType("Adhoc Punch");
                          setPunchStep(1);
                          canPunchOut
                            ? isConfirmPunchOut(true)
                            : isConfirmPunchIn(true);
                        }}
                      >
                        <ExitToAppIcon />
                        {canPunchOut ? "Adhoc Punch Out" : "Adhoc Punch In"}
                      </Button>
                    )}
                  </div>

                  <hr></hr>

                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      color: "#45545E",
                      padding: "12px",
                    }}
                  >
                    <span>
                      <Typography
                        component={"span"}
                        variant="subtitle1"
                        style={{
                          color: "grey",
                        }}
                      >
                        Punched IN :{" "}
                      </Typography>

                      {punchInTime ? (
                        <Typography
                          variant="subtitle1"
                          component={"span"}
                          style={{
                            fontWeight: "700",
                            color: "#3D9E5A",
                          }}
                        >
                          {punchInTime}
                        </Typography>
                      ) : (
                        <span
                          style={{
                            fontWeight: "700",
                            color: "#3D9E5A",
                          }}
                        >
                          --:--
                        </span>
                      )}
                    </span>

                    <span>
                      <Typography
                        component={"span"}
                        variant="subtitle1"
                        style={{
                          color: "grey",
                        }}
                      >
                        Punched OUT :{" "}
                      </Typography>

                      {punchOutTime ? (
                        <Typography
                          variant="subtitle1"
                          component={"span"}
                          style={{
                            fontWeight: "700",
                            color: "blue",
                          }}
                        >
                          {punchOutTime}
                        </Typography>
                      ) : (
                        <span
                          style={{
                            fontWeight: "700",
                            color: "blue",
                          }}
                        >
                          --:--
                        </span>
                      )}
                    </span>
                  </div>

                  {/* <hr></hr>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                        color: "#45545E",
                        padding: "12px",
                      }}
                    >
                      <span>
                        <Typography
                          component={"span"}
                          variant='subtitle1'
                          style={{
                            color: "grey",
                          }}
                        >
                          Break Start :{" "}
                        </Typography>
                        {attendanceData &&
                        attendanceData?.break_details.length !== 0 &&
                        attendanceData.break_details[
                          attendanceData.break_details.length - 1
                        ].breakout_time ? (
                          <Typography
                            variant='subtitle1'
                            component={"span"}
                            style={{
                              fontWeight: "700",
                              color: "#3D9E5A",
                            }}
                          >
                            {moment(
                              attendanceData.break_details[
                                attendanceData.break_details.length - 1
                              ].breakout_time
                            ).format("hh : mm A")}
                          </Typography>
                        ) : (
                          <span
                            style={{
                              fontWeight: "700",
                              color: "#3D9E5A",
                            }}
                          >
                            --:--
                          </span>
                        )}
                      </span>
                      <span>
                        <Typography
                          component={"span"}
                          variant='subtitle1'
                          style={{
                            color: "grey",
                          }}
                        >
                          Break End :{" "}
                        </Typography>
                        {attendanceData &&
                        attendanceData?.break_details.length !== 0 &&
                        attendanceData.break_details[
                          attendanceData.break_details.length - 1
                        ].breakin_time ? (
                          <Typography
                            variant='subtitle1'
                            component={"span"}
                            style={{
                              fontWeight: "700",
                              color: "blue",
                            }}
                          >
                            {moment(
                              attendanceData.break_details[
                                attendanceData.break_details.length - 1
                              ].breakin_time
                            ).format("hh : mm A")}
                          </Typography>
                        ) : (
                          <span
                            style={{
                              fontWeight: "700",
                              color: "blue",
                            }}
                          >
                            --:--
                          </span>
                        )}
                      </span>
                    </div> */}
                </Paper>
                <br></br>
                <br></br>
                <Paper className={classes.paper}>
                  <div style={{ padding: "4px" }}>
                    <h2
                      style={{
                        color: "#1F299C",
                        textAlign: "left",
                        margin: "10px",
                      }}
                    >
                      Employee Communication
                    </h2>
                    <hr></hr>
                    {/* <Grid container spacing={3}> */}
                    {messages?.fullfilled?.length < 1 ? (
                      <>
                        <hr></hr>
                        <p style={{ color: "#9e9e9e", padding: "20px" }}>
                          No messages
                        </p>
                      </>
                    ) : (
                      <>
                        {seeMoreBtn ? (
                          <>
                            {messages?.fullfilled?.map((message, i) => (
                              <>
                                <Grid
                                  container
                                  spacing={3}
                                  key={message.message_date + i}
                                >
                                  <Grid
                                    item
                                    xs
                                    style={{
                                      textAlign: "left",
                                      margin: "0px",
                                      padding: "20px",
                                      fontSize: "14px",
                                    }}
                                  >
                                    <p
                                      style={{
                                        color: "#5A5A5A",
                                      }}
                                    >
                                      {moment(message.message_date).format(
                                        "dddd, MMM Do YYYY"
                                      )}
                                    </p>
                                    <p
                                      style={{
                                        marginTop: "10px",
                                        overflowWrap: "break-word",
                                        wordWrap: "break-word",
                                        hyphens: "auto",
                                      }}
                                    >
                                      {message.message_content}
                                    </p>
                                  </Grid>
                                </Grid>
                                <hr></hr>
                              </>
                            ))}
                          </>
                        ) : (
                          <>
                            {messages?.fullfilled
                              ?.slice(0, 3)
                              .map((message, i) => (
                                <>
                                  <Grid
                                    container
                                    spacing={3}
                                    key={message.message_date + i}
                                  >
                                    <Grid
                                      item
                                      xs
                                      style={{
                                        textAlign: "left",
                                        margin: "0px",
                                        padding: "20px",
                                        fontSize: "14px",
                                      }}
                                    >
                                      <p
                                        style={{
                                          color: "#5A5A5A",
                                        }}
                                      >
                                        {moment(message.message_date).format(
                                          "dddd, MMM Do YYYY"
                                        )}
                                      </p>
                                      <p
                                        style={{
                                          marginTop: "10px",
                                          overflowWrap: "break-word",
                                          wordWrap: "break-word",
                                          hyphens: "auto",
                                        }}
                                      >
                                        {message.message_content}
                                      </p>
                                    </Grid>
                                  </Grid>
                                  <hr></hr>
                                </>
                              ))}
                          </>
                        )}
                        <div>
                          <h4
                            style={{
                              color: "#1F299C",
                              padding: "10px",
                              textAlign: "center",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setSeeMoreBtn(!seeMoreBtn);
                            }}
                          >
                            {seeMoreBtn ? "Show Less" : "Show More"}
                          </h4>
                        </div>
                      </>
                    )}

                    {/* </Grid> */}
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} lg={4} md={6}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Paper
                      className={classes.paper}
                      style={{ backgroundColor: "#1F299C" }}
                      onClick={handleQueryOpen}
                    >
                      <div
                        style={{
                          height: "206px",
                          padding: "0.5em",
                          cursor: "pointer",
                        }}
                      >
                        <h2 style={{ color: "white", textAlign: "left" }}>
                          Raise Query
                        </h2>
                        <img
                          src={checkShield}
                          alt="shield"
                          style={{
                            float: "right",
                            margin: "3em 0em",
                            cursor: "pointer",
                          }}
                        ></img>
                      </div>
                    </Paper>
                  </Grid>

                  <Grid item xs={6}>
                    <Paper
                      className={classes.paper}
                      style={{ backgroundColor: "#1F299C" }}
                      onClick={handleClickOpen}
                    >
                      <div
                        style={{
                          height: "206px",
                          padding: "0.5em",
                          cursor: "pointer",
                        }}
                      >
                        <h2 style={{ color: "white", textAlign: "left" }}>
                          Apply Leave
                        </h2>
                        <img
                          src={leave}
                          alt="shield"
                          style={{
                            float: "right",
                            margin: "3em 0em",
                            cursor: "pointer",
                          }}
                        ></img>
                      </div>
                    </Paper>
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Paper
                      className={classes.paper}
                      style={{ backgroundColor: "#1F299C" }}
                      onClick={handleNavigateToPayslip}
                    >
                      <div
                        style={{
                          height: "206px",
                          padding: "0.5em",
                          cursor: "pointer",
                        }}
                      >
                        <h2 style={{ color: "white", textAlign: "left" }}>
                          Pay Stub
                        </h2>
                        <img
                          src={payStubIcon}
                          alt="shield"
                          style={{
                            float: "right",
                            margin: "3em 0em",
                            cursor: "pointer",
                          }}
                        ></img>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={6} spacing={3}>
                    <Paper
                      className={classes.paper}
                      style={{ backgroundColor: "#1F299C" }}
                      onClick={handleMissedPunchOpen}
                    >
                      <div
                        style={{
                          height: "206px",
                          padding: "0.5em",
                          cursor: "pointer",
                          position: "relative",
                        }}
                      >
                        <h2 style={{ color: "white", textAlign: "left" }}>
                          Add Missed Punch
                        </h2>
                        <img
                          src={missedPunchIcon}
                          alt="shield"
                          style={{
                            position: "absolute",
                            bottom: "20px",
                            right: "15px",
                            // float: "right",
                            // margin: "2em 0em",
                            cursor: "pointer",
                          }}
                        ></img>
                      </div>
                    </Paper>
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Paper
                      className={classes.paper}
                      style={{ backgroundColor: "#1F299C" }}
                      onClick={handleNavigateToQueries}
                    >
                      <div
                        style={{
                          height: "206px",
                          padding: "0.5em",
                          cursor: "pointer",
                        }}
                      >
                        <h2 style={{ color: "white", textAlign: "left" }}>
                          Accept Query
                        </h2>
                        <img
                          src={checkShield}
                          alt="shield"
                          style={{
                            float: "right",
                            margin: "3em 0em",
                            cursor: "pointer",
                          }}
                        ></img>
                      </div>
                    </Paper>
                  </Grid>
                  {/* <Grid item xs={6} spacing={3}>
                      <Paper
                        className={classes.paper}
                        style={{ backgroundColor: "#1F299C" }}
                        onClick={handleMissedPunchOpen}
                      >
                        <div
                          style={{
                            height: "206px",
                            padding: "0.5em",
                            cursor: "pointer",
                            position: "relative",
                          }}
                        >
                          <h2 style={{ color: "white", textAlign: "left" }}>
                            Add Missed Punchi
                          </h2>
                          <img
                            src={missedPunchIcon}
                            alt='shield'
                            style={{
                              position: "absolute",
                              bottom: "20px",
                              right: "15px",
                              // float: "right",
                              // margin: "2em 0em",
                              cursor: "pointer",
                            }}
                          ></img>
                        </div>
                      </Paper>
                    </Grid> */}
                </Grid>

                {/* <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Paper
                        className={classes.paper}
                        style={{ backgroundColor: "#1F299C" }}
                      >
                        <div
                          style={{
                            height: "206px",
                            padding: "0.5em",
                          }}
                        >
                          <h2 style={{ color: "white", textAlign: "left" }}>
                            Organisation Directory
                          </h2>
                          <img
                            src={directory}
                            alt="shield"
                            style={{
                              float: "right",
                              margin: "2em 1.5em",
                            }}
                          ></img>
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={6} spacing={3}>
                      <Paper
                        className={classes.paper}
                        style={{ backgroundColor: "#1F299C" }}
                      >
                        <div
                          style={{
                            height: "206px",
                            padding: "0.5em",
                          }}
                        >
                          <h2 style={{ color: "white", textAlign: "left" }}>
                            Action Card
                          </h2>
                          <img
                            src={checkShield}
                            alt="shield"
                            style={{
                              float: "right",
                              margin: "4em 1.5em",
                            }}
                          ></img>
                        </div>
                      </Paper>
                    </Grid>
                  </Grid> */}
                <Paper
                  className={classes.paper}
                  style={{
                    backgroundImage: `url(${back})`,
                    backgroundSize: "cover",
                  }}
                >
                  <div
                    style={{
                      height: "136px",
                      padding: "1em",
                      backgroundColor: "rgba(108, 99, 255, 0.7)",
                      marginTop: "20px",
                      display: "flex",
                      justifyContent: "space-between",
                      // height: "100%",
                      alignContent: "baseline",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ color: "white", textAlign: "left" }}>
                      <h4>Emergency Company Contacts</h4>

                      <h3>Glen Traffic Solutions Inc</h3>
                      <p>
                        In case of emergency please contact
                        <br></br>
                        <b>604-991-8000</b>
                      </p>
                    </div>
                    <img
                      src={protection}
                      alt="shield"
                      style={{
                        width: "auto",
                        height: "70px",
                      }}
                    ></img>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} lg={3} md={6}>
                <Paper className={classes.paper}>
                  <div style={{ padding: "4px", paddingBottom: "0" }}>
                    <h2
                      style={{
                        color: "#1F299C",
                        padding: "15px",
                        textAlign: "center",
                      }}
                    >
                      Birthdays
                    </h2>

                    {bDays?.fullfilled?.birthdays?.length ? (
                      getBirthdays(bDays?.fullfilled?.birthdays)
                    ) : (
                      <>
                        <hr></hr>
                        <p style={{ color: "#9e9e9e", padding: "20px" }}>
                          No birthdays today
                        </p>
                      </>
                    )}
                  </div>
                </Paper>
                <br></br>
                <br></br>
                <Paper className={classes.paper}>
                  <div style={{ padding: "4px" }}>
                    <h2
                      style={{
                        color: "#1F299C",
                        textAlign: "center",
                        padding: "15px",
                        paddingTop: 0,
                      }}
                    >
                      Holidays
                    </h2>

                    {bDays?.fullfilled?.holidays?.length ? (
                      getHolidays(bDays?.fullfilled?.holidays)
                    ) : (
                      <>
                        <hr></hr>
                        <p style={{ color: "#9e9e9e", padding: "20px" }}>
                          No holidays this month
                        </p>
                      </>
                    )}
                    {/* <Grid container spacing={3}>
                        <Grid
                          item
                          xs
                          style={{ textAlign: "left", margin: "0px 12px" }}
                        >
                          <p style={{ color: "#60A058" }}>25 May | tuesday</p>
                          <span>Kazi Nazrul Islam Jayanti</span>
                        </Grid>
                      </Grid>
                      <hr></hr>
                      <Grid container spacing={3}>
                        <Grid
                          item
                          xs
                          style={{ textAlign: "left", margin: "0px 12px" }}
                        >
                          <p style={{ color: "#60A058" }}>25 May | tuesday</p>
                          <span>Kazi Nazrul Islam Jayanti</span>
                        </Grid>
                      </Grid>
                      <hr></hr>
                      <Grid container spacing={3}>
                        <Grid
                          item
                          xs
                          style={{ textAlign: "left", margin: "0px 12px" }}
                        >
                          <p style={{ color: "#60A058" }}>25 May | tuesday</p>
                          <span>Kazi Nazrul Islam Jayanti</span>
                        </Grid>
                      </Grid>
                      <hr></hr> */}
                    {/* {auth.user.org_id &&
                      auth.user.org_id.list_of_holidays.length ? (
                        <h4
                          style={{
                            color: "#1F299C",
                            padding: "10px",
                            textAlign: "center",
                            cursor: "pointer",
                          }}
                        >
                          See More
                        </h4>
                      ) : (
                        ""
                      )} */}
                  </div>
                </Paper>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </Grid>
    </div>
  );
};
export default HomeView;
