import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    allQueries: {}
};

export const  queriesAndTicketsSlice = createSlice({
  name: "queriesAndTickets",
  initialState,
  reducers: {
    getTicketsPending(state, action) {
      return {
        ...state,
        allQueries: { loading: true },
      };
    },
    getTicketsFulfilled(state, action) {
      return {
        ...state,
        allQueries: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    getTicketsRejected(state, action) {
      return {
        ...state,
        allQueries: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
  },
});

export const {
  getTicketsPending,
  getTicketsFulfilled,
  getTicketsRejected

} = queriesAndTicketsSlice.actions;

export default queriesAndTicketsSlice.reducer;
