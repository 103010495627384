import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import TopAssetAsignDetails from "./TopAssetDetails";
import AssetAssignDetailsList from "./AssetDetails";
import AssetAssignSidebar from "../AssetAssignSidebar";
import AddUpdateAsset from "../AddUpdateAsset";
import AssetRemarkSidebar from "../AssetRemarkSidebar";
import useAuth from "hooks/useAuth";
import { getAssetById } from "store/actions/AssetManagement/allAssets";
import { getAllAssetCategory } from "store/actions/AssetManagement/assetCategory";
import { getAllAssetSubCategory } from "store/actions/AssetManagement/assetSubCategory";
import { getAllAssetConditon } from "store/actions/AssetManagement/assetCondition";

const AllAssetsDetails = () => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const { allAsset, assetCategory, assetSubCategory, assetCondition } =
    useSelector((state) => state);
  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: 9999,
      color: "#fff",
    },
  }));
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [assetDetails, setAssetDetails] = useState({});
  const [sidebar, setSidebar] = useState(false);

  const [addUpdateAsset, setAddUpdateAsset] = useState(false);
  const [assetInitialValues, setAssetInitialValues] = useState(null);

  const [assignAsset, setAssignAsset] = useState(false);
  const [assetRemark, setAssetRemark] = useState(false);

  const params = useParams();
  const history = useHistory();

  const assetRemarkHandler = () => {
    let newAssetRemark = assetRemark ? !assetRemark : params.id;
    setAssetRemark(newAssetRemark);
  };

  const assignAssetHandler = () => {
    let newAssignAsset = assignAsset ? !assignAsset : params.id;
    setAssignAsset(newAssignAsset);
  };

  const assetAddUpdateHandler = () => {
    setAddUpdateAsset(!addUpdateAsset);
  };

  const sidebarHandler = () => {
    setSidebar(!sidebar);
  };
  useEffect(() => {
    dispatch(getAllAssetCategory());
    dispatch(getAllAssetSubCategory());
    dispatch(getAllAssetConditon());
  }, []);

  useEffect(() => {
    dispatch(getAssetById(params.id));
  }, [params.id]);

  useEffect(() => {
    if (allAsset?.assetById?.fulfilled) {
      setAssetDetails(allAsset?.assetById?.fulfilled);
      setAssetInitialValues(allAsset?.assetById?.fulfilled);
      setIsLoading(false);
    }
  }, [allAsset?.assetById]);

  const deleteHandler = () => {
    axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/asset/delete/${params.id}`)
      .then(() => {
        history.push("/assets/allassets");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="bg-white pb-10 text-left">
      <TopAssetAsignDetails
        sidebarHandler={sidebarHandler}
        sidebar={sidebar}
        deleteHandler={deleteHandler}
        assetAddUpdateHandler={assetAddUpdateHandler}
        assignAssetHandler={assignAssetHandler}
        assetRemarkHandler={assetRemarkHandler}
      />
      <AssetAssignDetailsList assetDetails={assetDetails} />

      {addUpdateAsset && assetInitialValues && (
        <AddUpdateAsset
          auth={auth}
          id={params.id}
          addUpdateAsset={addUpdateAsset}
          assetAddUpdateHandler={assetAddUpdateHandler}
          categoryList={assetCategory?.allAssetCategory?.fulfilled}
          subCategoryList={assetSubCategory?.assetSubCategoryList?.fulfilled}
          conditionList={assetCondition?.assetConditionList?.fulfilled}
          assetInitialValues={assetInitialValues}
        />
      )}

      {assignAsset ? (
        <AssetAssignSidebar
          auth={auth}
          assignAsset={assignAsset}
          assignAssetHandler={assignAssetHandler}
          categoryList={assetCategory?.allAssetCategory?.fulfilled}
          subCategoryList={assetSubCategory?.assetSubCategoryList?.fulfilled}
          conditionList={assetCondition?.assetConditionList?.fulfilled}
          assetInitialValues={assetInitialValues}
        />
      ) : (
        <></>
      )}

      {assetRemark ? (
        <AssetRemarkSidebar
          auth={auth}
          assetRemark={assetRemark}
          assetRemarkHandler={assetRemarkHandler}
        />
      ) : (
        <></>
      )}
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default AllAssetsDetails;
