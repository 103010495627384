import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Container,
  CssBaseline,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  IconButton,
  Dialog,
  Button,
  Backdrop,
  CircularProgress,
  TextField,
  Switch,
  FormControlLabel,
  DialogContentText,
  DialogActions,
  Snackbar,
  Menu,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
// import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import FormStepperNew from "../../editProfile/components/FormStepperNew";
import useAuth from "hooks/useAuth";
import axios from "axios";
import moment from "moment";
import ScrollableTabsButtonAuto from "../../editProfile/components/Tabs";
import { CSVLink } from "react-csv";
import { DialogContent } from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";
import { withStyles } from "@material-ui/styles";
import { green } from "@material-ui/core/colors";

import { useDispatch, useSelector } from "react-redux";
import { getUsersByOrgId } from "../../../store/actions/HrManagement/Dashboard";
import { updateEmployeeStatus } from "../../../store/actions/HrManagement/MyEmployees";
// const styles = {
//   dialogPaper : {
//     minHeight: '100vh',
//     maxHeight: '100vh'
//   }
// };

// const customDialog = ({classes}) => (
//   <Dialog
// )
const GreenSwitch = withStyles({
  switchBase: {
    color: green[300],
    "&$checked": {
      color: green[500],
    },
    "&$checked + $track": {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

function createData(rowData) {
  return {
    name: `${rowData.first_name} ${rowData.last_name}`,
    id: rowData.emp_code,
    role: rowData.role,
    dob: moment(rowData.dob).utc().format("ddd, MMM Do YYYY"),
    startDate: moment(rowData.doj).utc().format("ddd, MMM Do YYYY"),
    ...rowData,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "name", numeric: false, disablePadding: true, label: "Asset No." },
  { id: "id", numeric: true, disablePadding: false, label: "Category" },
  { id: "role", numeric: true, disablePadding: false, label: "Sub-Category" },
  { id: "dob", numeric: true, disablePadding: false, label: "Assigned" },
  { id: "joined", numeric: true, disablePadding: false, label: "Condition" },
  { id: "joined", numeric: true, disablePadding: false, label: "Status" },
  { id: "joined", numeric: true, disablePadding: false, label: "Actions" },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ backgroundColor: "#F7F8FC" }}>
      <TableRow>
        <TableCell></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ minWidth: "150px" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell
          key={"enabled"}
          align={"center"}
          padding={"none"}
          style={{ maxWidth: "50px", wordWrap: "break-word" }}
        >
          <span>Enabled/ Disabled</span>
        </TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#f7f8fc",
    height: "100vh",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

function AssetRequests(props) {
  const auth = useAuth();
  const dispatch = useDispatch();
  let theme = createTheme();
  theme = responsiveFontSizes(theme);
  const { orgUsers } = useSelector((state) => state.dashboard);
  const { updateEmployeeStatus } = useSelector((state) => state.employees);
  const [allEmployees, setAllEmployees] = React.useState([]);

  const headersCsv = [
    { label: "ID", key: "_id" },
    { label: "Username", key: "username" },
    { label: "First Name", key: "first_name" },
    { label: "Last Name", key: "last_name" },
    { label: "Gender", key: "gender" },
    { label: "Date of Birth", key: "dob" },
    { label: "Email", key: "email" },
    { label: "Country Code", key: "country_code" },
    { label: "Phone", key: "phone" },
    { label: "Alternate Contact No", key: "alternate_contact_no" },
    { label: "City", key: "city" },
    { label: "Communicaton Address", key: "communication_address" },
    { label: "Permanent Address", key: "permanent_address" },
    { label: "Company", key: "company" },
    { label: "Organisation Id", key: "org_id" },
    { label: "Date of Joining", key: "doj" },
    { label: "Educational Details", key: "educational_details" },
    { label: "Employee Code", key: "emp_code" },
    // family details just have marital status
    { label: "Marital Status", key: "marital_status" },
    { label: "Image Url", key: "img_url" },
    { label: "Emergency", key: "emergency_contact" },
    { label: "Known Languages", key: "known_languages" },
    // medical details - health_id , health_issues , province_of_issue
    { label: "Medical Details", key: "medical details" },
    { label: "Role", key: "role" },
    { label: "Department", key: "department" },
    { label: "Total CTC", key: "total_ctc" },
    { label: "Work Experience", key: "work_experience" },
    { label: "Manager Id", key: "lastname" },
    { label: "Hr Id", key: "lastname" },
    { label: "Personal Documents", key: "personal_documents" },
    { label: "Company Documents", key: "company_documents" },
  ];

  const classes = useStyles();
  const [rows, setRows] = React.useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [selected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalCount, setTotalCount] = React.useState(0);
  const [rowSelected, setRowSelected] = React.useState({});
  const [editOpen, setEditOpen] = React.useState(false);
  const [dataForCsv, setDataForCsv] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [originalRows, SetOriginalRows] = React.useState([]);
  const [activeStatus, setActiveStatus] = useState("");
  const [statusEmpId, setStatusEmpId] = useState("");

  // const getEmployees = async () => {
  //   // setIsLoading(true);
  //   const orgId = auth.user.org_id._id;
  //   const params = {
  //     page_number: "",
  //     limit: "",
  //   };
  //   dispatch(getUsersByOrgId(orgId, params));
  //   // dispatch(
  //   //   getUsersByOrgId({
  //   //     params: { orgId: orgId },
  //   //   })
  //   // )
  //   //   .then((data) => {
  //   //     const response = data.payload;
  //   //     // setAllEmployees(response.data);
  //   //     console.log("response=====================", response);
  //   //     setDataForCsv(response.data);
  //   //     setTotalCount(response.total_count);

  //   //     let newData = [
  //   //       ...response.data.map((item) => {
  //   //         return createData(item);
  //   //       }),
  //   //     ];

  //   //     SetOriginalRows(newData);
  //   //     setIsLoading(false);
  //   //   })
  //   //   .catch((error) => {
  //   //     console.log(error);
  //   //   });

  //   // const response = await axios.get(
  //   //   `${
  //   //     process.env.REACT_APP_API_BASE_URL
  //   //   }/users/getbyorg/${orgId}?page_number=${""}&limit=${""}`
  //   // );
  //   // if (response.status === 200) {
  //   //   const data = response.data.data;
  //   //   setDataForCsv(data.data);
  //   //   setTotalCount(data.total_count);

  //   //   let newData = [
  //   //     ...data.data.map((item) => {
  //   //       return createData(item);
  //   //     }),
  //   //   ];

  //   //   SetOriginalRows(newData);
  //   //   setIsLoading(false);
  //   // }
  // };

  const getAllEmployees = async (pageNumber, limit) => {
    setIsLoading(true);
    const orgId = auth.user.org_id._id;
    const params = {
      page_number: pageNumber + 1,
      limit: limit,
    };
    dispatch(getUsersByOrgId(orgId, params));
    // dispatch(
    //   getUsersByOrgId({
    //     params: { orgId: orgId },
    //   })
    // )
    //   .then((data) => {
    //     const response = data.payload;
    //     // setAllEmployees(response.data);
    //     console.log("response22=====================", response);

    //     let newData = [
    //       ...response.data.map((item) => {
    //         return createData(item);
    //       }),
    //     ];

    //     setRows(newData);
    //     const row = newData.find(({ _id }) => _id === rowSelected._id);
    //     if (row) setRowSelected(row);
    //     setIsLoading(false);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    // const response = await axios.get(
    //   `${
    //     process.env.REACT_APP_API_BASE_URL
    //   }/users/getbyorg/${orgId}?page_number=${pageNumber + 1}&limit=${limit}`
    // );

    // if (response.status === 200) {
    //   const data = response.data.data;
    //   let newData = [
    //     ...data.data.map((item) => {
    //       return createData(item);
    //     }),
    //   ];

    // setRows(newData);
    // const row = newData.find(({ _id }) => _id === rowSelected._id);
    // if (row) setRowSelected(row);
    // setIsLoading(false);
    // }
  };

  useEffect(() => {
    // getEmployees();
    getAllEmployees(page, rowsPerPage);

    window.scrollTo(0, 0);
  }, [updateEmployeeStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  // React.useEffect(() => {
  //   // if (updateEmployeeStatus?.fulfilled) {
  //   //   // console.log("employee fulfilled");
  //   // }
  // }, [updateEmployeeStatus]);
  React.useEffect(() => {
    if (orgUsers?.fulfilled) {
      const response = orgUsers?.fulfilled;
      setAllEmployees(response.data);
      setDataForCsv(response.data);
      setTotalCount(response.total_count);

      let newData = [...response.data].map((item) => {
        return createData(item);
      });

      SetOriginalRows(newData);
      setRows(newData);
      setIsLoading(false);
    }
  }, [orgUsers]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getAllEmployees(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getAllEmployees(0, parseInt(event.target.value, 10));
  };

  // ---------for form dialog--------------
  // const [openAddEmployee] = React.useState(false);
  // console.log(openAddEmployee);
  // const handleClickOpen = () => {
  //   setOpenAddEmployee(true);
  // };
  // const handleClose = () => {
  //   setOpenAddEmployee(false);
  //   getAllEmployees(page, rowsPerPage);
  // };

  const [updateError, setUpdateError] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openAddEmployeeNew, setOpenAddEmployeeNew] = React.useState(false);

  const handleToggleSwitch = async (event, id, status) => {
    setActiveStatus(status);
    setStatusEmpId(id);
    setOpenConfirm(true);
  };

  const handleUpdateEmployee = async () => {
    try {
      // Call Update API
      setUpdateError(false);
      let body = {
        _id: statusEmpId,
        enabled: !activeStatus,
      };
      dispatch(updateEmployeeStatus(body));
      // dispatch(updateEmployees(body))
      //   .then((data) => {
      //     const response = data.payload;
      //     // setAllEmployees(response.data);
      //     console.log("response22=====================", response);

      //     // let newData = [
      //     //   ...response.data.map((item) => {
      //     //     return createData(item);
      //     //   }),
      //     // ];

      //     // setRows(newData);
      //     // const row = newData.find(({ _id }) => _id === rowSelected._id);
      //     // if (row) setRowSelected(row);
      //     // setIsLoading(false);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
      // const response = await axios.put(
      //   `${process.env.REACT_APP_API_BASE_URL}/users/updateStatus`,
      //   body
      // );

      // if (response.status === 200) {
      //   saveAndReload();
      // }
    } catch (e) {
      if (e.response.status === 400) {
        setUpdateError(true);
      }
    }
    handleCloseConfirm();
  };
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleClickNewOpen = () => {
    setOpenAddEmployeeNew(true);
  };

  const handleNewClose = () => {
    setOpenAddEmployeeNew(false);
    getAllEmployees(page, rowsPerPage);
  };

  const saveAndReload = async () => {
    // await getAllEmployees(page, rowsPerPage);
  };

  const handleClose = () => {
    setOpenAddEmployeeNew(false);
    setEditOpen(false);
    setRowSelected({});
  };
  // ------for read only------
  const [viewOpen, setViewOpen] = React.useState(false);

  const handleClickViewOpen = (e, selectedRow) => {
    setViewOpen(true);
    setRowSelected(selectedRow);
  };
  const handleViewClose = () => {
    setViewOpen(false);
    setRowSelected({});
  };

  const handleEditOpen = (e, selectedRow) => {
    setEditOpen(true);
    setRowSelected(selectedRow);
  };

  const handleEditClose = (e) => {
    setEditOpen(false);
    setRowSelected({});
    getAllEmployees(page, rowsPerPage);
    auth.fetchUser(localStorage.getItem("token"), auth.user._id);
  };

  function pad(num) {
    num = num.toString();
    if (num < 10 && num > 0) {
      num = "0" + num;
    }
    return num;
  }

  // --------------------------------------------------

  return (
    <Container className={classes.root}>
      <ThemeProvider theme={theme}>
        <div
          style={{ padding: "28px 0px" }}
          className={clsx(classes.content, {
            [classes.contentShift]: props.isDrawerOpen,
          })}
        >
          <CssBaseline>
            <ThemeProvider theme={theme}>
              <Container
                maxWidth="xl"
                // align="center"
                style={{ padding: "0px", margin: "0px" }}
              >
                <Paper>
                  <Typography
                    variant="h4"
                    align="left"
                    style={{
                      color: "#1F299C",
                      fontWeight: "600",
                      paddingLeft: "1.5em",
                      paddingTop: "0.5em",
                    }}
                  >
                    Asset New Requests
                  </Typography>
                  <Grid
                    container
                    spacing={1}
                    justifyContent="center"
                    style={{ paddingTop: "2em" }}
                  >
                    <Grid item xs={3} style={{ paddingLeft: "4em" }}>
                      <Autocomplete
                        // value={autocompleteValue}
                        id="combo-box-demo"
                        // options={empOptions}
                        // getOptionLabel={(option) =>
                        //     `${option.first_name} (${option.emp_code})`
                        // }
                        style={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Search"
                            variant="outlined"
                          />
                        )}
                        onChange={(e) => {}}
                      />
                    </Grid>
                    <Grid item xs={3} style={{ paddingLeft: "4em" }}>
                      <FormControl
                        variant="outlined"
                        style={{
                          width: "300px",
                          marginRight: "20px",
                          marginBottom: "10px",
                        }}
                      >
                        <InputLabel id="demo-simple-select-label-2">
                          All Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label-2"
                          label="All tags"
                          id="demo-simple-select"

                          //   value={selectedScheduleRange}
                          //   onChange={(e) => onSelectedScheduleRange(e.target.value)}
                        >
                          <MenuItem value="today">Daily</MenuItem>
                          <MenuItem value="week">Weekly</MenuItem>
                          <MenuItem value="month">Monthly</MenuItem>
                          {/* <MenuItem value='range'>Date Range</MenuItem> */}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3} style={{ paddingLeft: "4em" }}>
                      <FormControl
                        variant="outlined"
                        style={{
                          width: "300px",
                          marginRight: "20px",
                          marginBottom: "10px",
                        }}
                      >
                        <InputLabel id="demo-simple-select-label-2">
                          All Sub-Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label-2"
                          label="All tags"
                          id="demo-simple-select"

                          //   value={selectedScheduleRange}
                          //   onChange={(e) => onSelectedScheduleRange(e.target.value)}
                        >
                          <MenuItem value="today">Daily</MenuItem>
                          <MenuItem value="week">Weekly</MenuItem>
                          <MenuItem value="month">Monthly</MenuItem>
                          {/* <MenuItem value='range'>Date Range</MenuItem> */}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3} style={{ paddingLeft: "4em" }}>
                      <div style={{ display: "flex", marginLeft: "auto" }}>
                        <IconButton
                          style={{
                            marginLeft: "5px",
                            marginTop: "5px",
                            float: "right",
                          }}
                          aria-label="more"
                          id="long-button"
                          aria-controls="basic-menu"
                          aria-haspopup="true"
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          open={false}
                          id="long-menu"
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                          PaperProps={{
                            style: {
                              width: "130px",
                              top: "215px",
                              left: "1500px",
                            },
                          }}
                        ></Menu>
                      </div>
                    </Grid>

                    {/* -----------------TABLE------------------------------ */}
                    <Grid item xs={11} style={{ padding: "30px 0px" }}>
                      <Paper className={classes.paper}>
                        <TableContainer>
                          <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={dense ? "small" : "medium"}
                            aria-label="enhanced table"
                          >
                            <EnhancedTableHead
                              classes={classes}
                              numSelected={selected.length}
                              order={order}
                              orderBy={orderBy}
                              onRequestSort={handleRequestSort}
                              rowCount={rows.length}
                            />
                            <TableBody>
                              {rows.length
                                ? stableSort(
                                    rows,
                                    getComparator(order, orderBy)
                                  ).map((row) => {
                                    return (
                                      <TableRow
                                        hover
                                        role="checkbox"
                                        key={row.name}
                                      >
                                        <TableCell></TableCell>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          padding="none"
                                        >
                                          {row.name}
                                        </TableCell>
                                        <TableCell align="right">
                                          {row.id}
                                        </TableCell>
                                        <TableCell align="right">
                                          {row.role ? row.role : "Not Assigned"}
                                        </TableCell>
                                        <TableCell align="right">
                                          {moment(row.dob)
                                            .utc()
                                            .format("YYYY-MM-DD")}
                                        </TableCell>
                                        <TableCell align="right">
                                          {row.startDate}
                                        </TableCell>{" "}
                                        <TableCell
                                          align="right"
                                          style={{ padding: "0px" }}
                                        >
                                          <FormControlLabel
                                            control={
                                              <GreenSwitch
                                                onChange={(e) =>
                                                  handleToggleSwitch(
                                                    e,
                                                    row._id,
                                                    row.enabled
                                                  )
                                                }
                                                checked={row.enabled}
                                              />
                                            }
                                          />
                                        </TableCell>
                                        <TableCell
                                          align="right"
                                          style={{ padding: "0px" }}
                                        >
                                          <IconButton
                                            onClick={(e) =>
                                              handleClickViewOpen(e, row)
                                            }
                                          >
                                            <VisibilityIcon />
                                          </IconButton>
                                        </TableCell>
                                        <TableCell
                                          align="right"
                                          style={{ padding: "0px" }}
                                        >
                                          <IconButton
                                            onClick={(e) =>
                                              handleEditOpen(e, row)
                                            }
                                          >
                                            <EditIcon />
                                          </IconButton>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })
                                : ""}
                              {/* {emptyRows > 0 && (
                          <TableRow
                            style={{ height: (dense ? 33 : 53) * emptyRows }}
                          >
                            <TableCell colSpan={6} />
                          </TableRow>
                        )} */}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          style={{ backgroundColor: "#F7F8FC" }}
                          rowsPerPageOptions={[5, 10, 25]}
                          component="div"
                          count={totalCount}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Paper>
                    </Grid>
                  </Grid>
                </Paper>
              </Container>

              {/*=========================================== the form dialog=============================================================== */}

              {/* text fields have input enabled */}
              {/* ---------NEW------------------------------- */}
              <Dialog
                open={openAddEmployeeNew}
                aria-labelledby="form-dialog-title"
                maxWidth="md"
                PaperProps={{
                  style: {
                    position: "absolute",
                    margin: "0px",
                    padding: "0px",
                    right: "0px",
                    minHeight: "115vh",
                    bottom: "0px",
                    top: "0px",
                    borderRadius: "0px",
                  },
                }}
              >
                <DialogContent style={{ margin: "0px", padding: "0px" }}>
                  <FormStepperNew
                    readOnly={false}
                    operation={"add"}
                    closeForm={handleNewClose}
                    close={handleClose}
                    saveAndReload={saveAndReload}
                  />
                </DialogContent>
              </Dialog>
              {/* ---------NEW------------------------------- */}

              {/* text fields have input disabled --- ReadOnly*/}
              <Dialog
                open={viewOpen}
                onClose={handleViewClose}
                aria-labelledby="form-dialog-title"
                maxWidth="lg"
                PaperProps={{
                  style: {
                    width: "1280px",
                  },
                }}
              >
                <ScrollableTabsButtonAuto
                  operation={"view"}
                  readOnly={true}
                  // closeForm = {handleViewClose}
                  {...rowSelected}
                />
              </Dialog>
              <Dialog
                open={editOpen}
                // onClose={handleEditClose}
                aria-labelledby="form-dialog-title"
                maxWidth="md"
                PaperProps={{
                  style: {
                    position: "absolute",
                    margin: "0px",
                    padding: "0px",
                    right: "0px",
                    minHeight: "115vh",
                    top: "0px",
                    bottom: "0px",
                    borderRadius: "0px",
                  },
                }}
              >
                {/* <FormStepper
                  readOnly={false}
                  operation={"edit"}
                  closeForm={handleEditClose}
                  {...rowSelected}
                /> */}

                <DialogContent style={{ margin: "0px", padding: "0px" }}>
                  <FormStepperNew
                    readOnly={false}
                    operation={"edit"}
                    closeForm={handleEditClose}
                    close={handleClose}
                    saveAndReload={saveAndReload}
                    {...rowSelected}
                  />
                </DialogContent>

                {/* <ScrollableTabsButtonAuto
                  operation = {"edit"}
                  readOnly = {false}
                  closeForm = {handleEditClose}
                  {...rowSelected}
                /> */}
              </Dialog>
            </ThemeProvider>
          </CssBaseline>
        </div>
        <Dialog
          open={openConfirm}
          onClose={handleCloseConfirm}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography
                variant="h5"
                style={{
                  color: "black",
                }}
              >
                Do you want to{" "}
                <span style={{ color: !activeStatus ? green[500] : "#e9594a" }}>
                  {activeStatus ? "disable" : "enable"}
                </span>{" "}
                this employee ?
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseConfirm} color="primary">
              No
            </Button>
            <Button
              onClick={() => {
                handleUpdateEmployee();
              }}
              color="primary"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={updateError}
          onClose={() => setUpdateError(false)}
          key={"Form Error"}
          autoHideDuration={3000}
        >
          <Alert severity="error">Something went wrog! Please try again!</Alert>
        </Snackbar>
        <Backdrop style={{ zIndex: 9999, color: "#fff" }} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </ThemeProvider>
    </Container>
  );
}

export default AssetRequests;
