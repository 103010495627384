import React from "react";
import Sidebar from "pages/sidebar/Sidebar";
import moment from "moment";
const ActivitySidebar = ({ data, activitySidebarHandler }) => {
  console.log("....activity", data);

  let activityData = {};
  data.activity.forEach((element) => {
    let date = new Date(element.activity_date);
    let day = date.getDate();
    let month = date.getMonth();
    let year = date.getFullYear();
    let formattedDate = `${day}-${month}-${year}`;
    if (!activityData?.[formattedDate]) {
      Object.assign(activityData, { [formattedDate]: [element] });
      // activityData.[`${formattedDate}`]=[element]
    } else {
      activityData?.[formattedDate].push(element);
    }
  });
  console.log(activityData);

  return (
    <Sidebar sidebarHandler={activitySidebarHandler} modalTitle="Activity">
      <div className="h-screen">
        {Object.keys(activityData).length > 0 &&
          Object.keys(activityData).map((elementKey, index) => {
            return (
              <>
                <p className="text-xl font-bold mb-3"> {elementKey}</p>

                {activityData[elementKey].length > 0 &&
                  activityData[elementKey].map((obj) => {
                    let time = moment(obj.activity_date).format(" hh:mm A");
                    let condition = "new";
                    return (
                      <div className="border p-4 rounded">
                        <p>{time} | Modify by: Name Lastname</p>
                        <p className="font-bold">Request for {condition}</p>
                      </div>
                    );
                  })}
              </>
            );
          })}
      </div>
    </Sidebar>
  );
};

export default ActivitySidebar;
