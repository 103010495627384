import axios from "axios";
import { Urls } from "store/urls";

export const getAllAssetRepo = (params) => {
  return axios({
    method: "GET",
    url: Urls.GET_ALL_ASSET,
    params: params,
  });
};
export const createAssetRepo = (data) => {
  return axios({
    method: "POST",
    url: Urls.CREATE_NEW_ASSET,
    data: data,
  });
};
export const updateAssetRepo = (id, data) => {
  return axios({
    method: "PUT",
    url: Urls.UPDATE_NEW_ASSET + id,
    data: data,
  });
};

export const getAssetByIdRepo = (id) => {
  return axios({
    method: "GET",
    url: Urls.GET_ASSET_BY_ID + id,
  });
};

export const getAllAssetGroupByUserRepo = (params) => {
  return axios({
    method: "GET",
    url: Urls.GET_ALL_ASSET_GROUP_BY_USER,
    params: params,
  });
};

export const getAssetByUserIdRepo = (id, params) => {
  return axios({
    method: "GET",
    url: Urls.GET_ASSETS_BY_USER_ID + id,
    params: params,
  });
};
