import React, { useEffect } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "react-step-progress-bar/styles.css";
import { ProgressBar } from "react-step-progress-bar";

const AssetsMangement = ({ state }) => {
  const BLUE = "#1F299C",
    GREEN = "#3D9E5A",
    ORANGE = "#E9594A";

  return (
    <div className='rounded bg-white shadow-md p-5 mt-5 w-full md:w-3/4'>
      <p className='text-xl font-semibold mb-5 text-center md:text-left'>
        Asset Management
      </p>
      <div className='flex md:justify-between gap-10 flex-wrap justify-center md:flex-nowrap'>
        <div className='w-48 md:w-72'>
          <CircularProgressbar
            value={state.all_assets ? state.all_assets : 0}
            text={`${
              state.all_assets ? (state.assigned / state.all_assets) * 100 : 0
            }%`}
            styles={{
              path: { stroke: GREEN },
              text: {
                fill: BLUE,
                fontSize: "20px",
                fontWeight: "bold",
              },
            }}
            strokeWidth='5'
          />
          <p>Planned Assets Utilization</p>
        </div>
        <div className='w-full'>
          <div className='mb-8'>
            <div className='flex justify-between mb-2'>
              <p>Assigned Assets</p>
              <p>{state?.assigned || 0}</p>
            </div>
            <ProgressBar
              percent={state && (state.assigned / state.all_assets) * 100}
              filledBackground={BLUE}
            />
          </div>
          <div className='mb-8'>
            <div className='flex justify-between mb-2'>
              <p>Available</p>
              <p>{state?.available || 0}</p>
            </div>
            <ProgressBar
              percent={state && (state.available / state.all_assets) * 100}
              filledBackground={GREEN}
            />
          </div>
          <div className='mb-8'>
            <div className='flex justify-between mb-2'>
              <p>Unavailable</p>
              <p>{state?.unAvailable || 0}</p>
            </div>
            <ProgressBar
              percent={state && (state.unAvailable / state.all_assets) * 100}
              filledBackground={ORANGE}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetsMangement;
