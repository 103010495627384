import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  assetSubCategoryList: {},
  createSubCategory: {},
  deleteSubCategory: {},
  updateSubCategory: {},
};

export const assetSubCategorySlice = createSlice({
  name: "AssetSubCategory",
  initialState,
  reducers: {
    getAllAssetSubCategoryPending(state, action) {
      return {
        ...state,
        assetSubCategoryList: { loading: true },
      };
    },
    getAllAssetSubCategoryFulfilled(state, action) {
      return {
        ...state,
        assetSubCategoryList: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    getAllAssetSubCategoryRejected(state, action) {
      return {
        ...state,
        assetSubCategoryList: {
          loading: false,
          rejected: action.payload,
        },
      };
    },

    createAssetSubCategoryPending(state, action) {
      return {
        ...state,
        createSubCategory: { loading: true },
      };
    },
    createAssetSubCategoryFulfilled(state, action) {
      return {
        ...state,
        createSubCategory: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    createAssetSubCategoryRejected(state, action) {
      return {
        ...state,
        createSubCategory: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
    clearCreateAssetSubCategory(state, action) {
      return {
        ...state,
        createSubCategory: {},
      };
    },

    updateAssetSubCategoryPending(state, action) {
      return {
        ...state,
        updateSubCategory: { loading: true },
      };
    },
    updateAssetSubCategoryFulfilled(state, action) {
      return {
        ...state,
        updateSubCategory: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    updateAssetSubCategoryRejected(state, action) {
      return {
        ...state,
        updateSubCategory: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
    clearUpdateAssetSubCategory(state, action) {
      return {
        ...state,
        updateSubCategory: {},
      };
    },

    deleteAssetSubCategoryPending(state, action) {
      return {
        ...state,
        deleteSubCategory: { loading: true },
      };
    },
    deleteAssetSubCategoryFulfilled(state, action) {
      return {
        ...state,
        deleteSubCategory: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    deleteAssetSubCategoryRejected(state, action) {
      return {
        ...state,
        deleteSubCategory: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
    clearDeleteAssetSubCategory(state, action) {
      return {
        ...state,
        deleteSubCategory: {},
      };
    },
  },
});

export const {
  getAllAssetSubCategoryFulfilled,
  getAllAssetSubCategoryPending,
  getAllAssetSubCategoryRejected,
  createAssetSubCategoryPending,
  createAssetSubCategoryFulfilled,
  createAssetSubCategoryRejected,
  clearCreateAssetSubCategory,

  updateAssetSubCategoryPending,
  updateAssetSubCategoryFulfilled,
  updateAssetSubCategoryRejected,
  clearUpdateAssetSubCategory,

  deleteAssetSubCategoryPending,
  deleteAssetSubCategoryFulfilled,
  deleteAssetSubCategoryRejected,
  clearDeleteAssetSubCategory,
} = assetSubCategorySlice.actions;

export default assetSubCategorySlice.reducer;
