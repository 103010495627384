import React, { useState, useEffect } from "react";
import AddSubCategorySidebar from "./AddSubCategorySidebar";
const AddSubCategoryList = ({
  subCategoryList,
  subCategoryDeleteHandler,
  categoryList,
}) => {
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [subCategoryInitialValues, setSubCategoryInitialValues] = useState();

  return (
    <div className="mt-3 h-screen">
      {isOpenForm && subCategoryInitialValues && (
        <AddSubCategorySidebar
          setIsOpenForm={setIsOpenForm}
          categoryList={categoryList}
          subCategoryInitialValues={subCategoryInitialValues}
          setSubCategoryInitialValues={setSubCategoryInitialValues}
        />
      )}
      <table className="w-full mt-5 text-blue-900">
        <tr className="border-2 bg-gray-100">
          <th className="text-left pl-5 py-2">Sub-Category</th>
          <th className="text-left pl-5 py-2">Category</th>
          <th className="text-left pl-5 py-2">Action</th>
        </tr>
        {subCategoryList &&
          subCategoryList.map((el) => {
            let category = categoryList.find(
              (obj) => obj._id === el?.category_id
            );
            return (
              <>
                <tr className="border-2">
                  <td className="text-left pl-5 py-2">{el.name}</td>
                  <td className="text-left pl-5 py-2">{category?.name}</td>
                  <td className="text-left pl-5 py-2">
                    <i
                      class="fa-sharp fa-regular fa-pen-to-square cursor-pointer"
                      onClick={() => {
                        setIsOpenForm(true);
                        setSubCategoryInitialValues(el);
                      }}
                    ></i>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <i
                      className="fa-solid fa-trash-can mt-1 cursor-pointer"
                      onClick={() => subCategoryDeleteHandler(el._id)}
                    ></i>
                  </td>
                </tr>
              </>
            );
          })}
      </table>
    </div>
  );
};

export default AddSubCategoryList;
