import axios from "axios";
import { Urls } from "store/urls";

export const getAllAssetSubCategoryRepo = (params) => {
  return axios({
    method: "GET",
    url: Urls.GET_ALL_ASSET_SUB_CATEGORY,
    params: params,
  });
};

export const getAssetSubCategoryByIdRepo = (params) => {
  return axios({
    method: "GET",
    url: Urls.GET_ASSET_SUB_CATEGORY_BY_ID,
    params: params,
  });
};

export const postAssetSubCategoryRepo = (data) => {
  return axios({
    method: "POST",
    url: Urls.POST_ASSET_SUB_CATEGORY,
    data: data,
  });
};
export const updateAssetSubCategoryRepo = (data) => {
  return axios({
    method: "PUT",
    url: Urls.UPDATE_ASSET_SUB_CATEGORY,
    data: data,
  });
};
export const deleteAssetSubCategoryRepo = (id) => {
  return axios({
    method: "DELETE",
    url: Urls.DELETE_ASSET_SUB_CATEGORY + id,
  });
};
