import React, { useState } from "react";
import AssignAssetDetail from "./AssignAssetDetail";

const AssetAssignDetailsList = (props) => {
  const { data, categoryList, subCategoryList, auth } = props;

  return (
    <div className="overflow-x-auto">
      <table className="w-11/12 m-auto mt-5 text-blue-900 text-sm">
        <thead>
          <tr className="text-left bg-gray-100 border-2 rounded-md">
            <th className="px-5">Item center</th>
            <th className="px-5">Type</th>
            <th className="px-5">Brand</th>
            <th className="px-5">Model</th>
            <th className="px-5">Serial No</th>
            <th className="px-5">Status</th>
            <th className="px-5">Action</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => {
            let category = categoryList?.find(
              (obj) => obj._id == item.category
            );

            let sub_category = subCategoryList?.find(
              (obj) => obj._id == item.sub_category
            );
            return (
              <AssignAssetDetail
                auth={auth}
                index={index}
                item={item}
                category={category}
                sub_category={sub_category}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AssetAssignDetailsList;
