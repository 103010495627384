import React, { useState, useEffect } from "react";
import AddCategorySidebar from "./AddCategorySidebar";

const CategoryList = ({ categoryList, categoryDeleteHandler }) => {
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [categoryInitialValues, setCategoryInitialValues] = useState();

  return (
    <div className="mt-3 h-screen">
      {isOpenForm && categoryInitialValues && (
        <AddCategorySidebar
          setIsOpenForm={setIsOpenForm}
          categoryList={categoryList}
          categoryInitialValues={categoryInitialValues}
          setCategoryInitialValues={setCategoryInitialValues}
        />
      )}
      <table className="w-full mt-5 text-blue-900">
        <tr className="border-2 bg-gray-100">
          <th className="text-left pl-8 py-2">Category</th>
          <th className="text-left pl-4 py-2">Action</th>
        </tr>
        {categoryList &&
          categoryList.map((el) => {
            return (
              <>
                <tr className="border-2">
                  <td className="text-left pl-6 py-2">{el.name}</td>
                  <td className="text-left pl-6 py-2">
                    <i
                      class="fa-sharp fa-regular fa-pen-to-square cursor-pointer"
                      onClick={() => {
                        setIsOpenForm(true);
                        setCategoryInitialValues(el);
                      }}
                    ></i>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <i
                      className="fa-solid fa-trash-can mt-1 cursor-pointer"
                      onClick={() => categoryDeleteHandler(el._id)}
                    ></i>
                  </td>
                </tr>
              </>
            );
          })}
      </table>
    </div>
  );
};

export default CategoryList;
