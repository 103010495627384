import "date-fns";
import React, { useState } from "react";
import clsx from "clsx";
// import useAuth from "hooks/useAuth";
import styled from "styled-components/macro";

import {
  Button,
  makeStyles,
  Typography,
  Grid,
  Paper,
  LinearProgress
} from "@material-ui/core";

// import accounting from "../../assets/images/accounting-invoice-hand.png";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import currency from "../../assets/images/currency.svg";
import leave from "../../assets/images/leave.png";
import "./Payroll.css";
import {
  Announcement,
  ArrowBackIos,
  GetApp,
  Visibility
} from "@material-ui/icons";
// import LocalAtmIcon from "@material-ui/icons/LocalAtm";
const Container = styled.div`
  background-color: white;
  padding: 12px;
`;
const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    borderRadius: "6px "
  },
  datepicker: {
    color: "#1F299C",
    textColor: "#ffffff",
    calendarTextColor: "#1F299C",
    selectColor: "#1F299C",
    selectTextColor: "#1F299C",
    calendarYearBackgroundColor: "#1F299C"
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    background: "#ffffff",
    color: "#1F299C"
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 0
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: 0
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: drawerWidth
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    border: "2px solid #FFFFFF"
  }
}));

const iframe =
  '<iframe height="720" style="width: 100%;" src="http://www.africau.edu/images/default/sample.pdf" frameborder="no" allowtransparency="true" allowfullscreen="true"></iframe>';

const Payroll = function (props) {
  // const auth = useAuth();
  const classes = useStyles();

  const [selectedDate, setSelectedDate] = React.useState(
    new Date("2021-06-18T21:11:54")
  );

  const [progress] = React.useState(76);
  const Iframe = props => {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
      />
    );
  };
  const handleDateChange = date => {
    setSelectedDate(date);
  };

  const [showPdf, setshowPdf] = useState(false);
  const downloadFile = () => {
    window.open(
      "https://drive.google.com/file/d/1uNFwZreZWiZIqCuxnJlfP8KgrzE1QPNo/view",
      "_blank"
    );
  };
  return (
    <Container>
      <div className={classes.root}>
        {/* <CssBaseline /> */}
        {!showPdf && (
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: props.isDrawerOpen
            })}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} lg={2}>
                <Paper
                  className={classes.paper}
                  style={{ backgroundColor: "#1F299C" }}
                >
                  <div
                    style={{
                      height: "206px",
                      padding: "0.5em"
                    }}
                  >
                    <h2 style={{ color: "white", textAlign: "left" }}>
                      Pay Stub
                    </h2>
                    <img
                      src="https://img.icons8.com/ios-filled/50/ffffff/receipt-dollar.png"
                      alt="shield"
                      style={{
                        float: "right",
                        margin: "4em 1.5em",
                        height: "66px"
                      }}
                    ></img>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4} lg={2} spacing={3}>
                <Paper
                  className={classes.paper}
                  style={{ backgroundColor: "#1F299C", cursor: "pointer" }}
                  onClick={downloadFile}
                >
                  <div
                    style={{
                      height: "206px",
                      padding: "0.5em"
                    }}
                  >
                    <h2 style={{ color: "white", textAlign: "left" }}>T4</h2>
                    <img
                      src={leave}
                      alt="shield"
                      style={{
                        float: "right",
                        margin: "4em 1.5em"
                      }}
                    ></img>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4} lg={2} spacing={3}>
                <Paper
                  className={classes.paper}
                  style={{ backgroundColor: "#1F299C" }}
                >
                  <div
                    style={{
                      height: "206px",
                      padding: "0.5em"
                    }}
                  >
                    <h2 style={{ color: "white", textAlign: "left" }}>
                      My earning
                    </h2>
                    <img
                      src={currency}
                      alt="shield"
                      style={{
                        float: "right",
                        margin: "4em 1.5em"
                      }}
                    ></img>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6} lg={6} spacing={3}>
                <Paper
                  className={classes.paper}
                  style={{ backgroundColor: "#0373FF" }}
                >
                  <div
                    style={{
                      height: "206px",
                      padding: "0.5em",
                      display: "flex",
                      justifyContent: "space-between"
                    }}
                  >
                    <Announcement
                      style={{
                        float: "right",
                        margin: "2em 0.5em",
                        color: "white",
                        fontSize: "40px"
                      }}
                    />
                    <div>
                      <h2 style={{ color: "white", textAlign: "left" }}>
                        Announcement
                      </h2>
                      <h5 style={{ color: "white", textAlign: "left" }}>
                        Title of payroll announcement
                      </h5>
                      <p style={{ color: "white", textAlign: "left" }}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et
                      </p>
                      <div
                        style={{
                          display: "flex",
                          float: "right",

                          color: "white"
                        }}
                      >
                        <span>View More</span>
                        &nbsp; &nbsp; &nbsp; &nbsp;
                        <span>Close</span>
                      </div>
                    </div>
                  </div>
                </Paper>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} lg={4}>
                <Paper className={classes.paper}>
                  <div
                    style={{
                      height: "530px",
                      padding: "0.5em"
                    }}
                  >
                    <h2 style={{ color: "#1F299C", textAlign: "left" }}>
                      Pay Stub
                    </h2>
                    <hr></hr>
                    <div
                      style={{
                        color: "#1F299C",
                        textAlign: "left",
                        padding: "12px",
                        display: "flex"
                      }}
                    >
                      {/* <ArrowBackIos /> <ArrowForwardIos />{" "} */}
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <Grid
                          container
                          justify="left"
                          style={{ margin: "0px 12px" }}
                        >
                          <DatePicker
                            variant="inline"
                            openTo="year"
                            views={["year", "month"]}
                            value={selectedDate}
                            autoOk={true}
                            className={classes.datepicker}
                            onChange={handleDateChange}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </div>
                    <br></br>
                    <Grid
                      container
                      style={{
                        fontSize: "20px"
                      }}
                    >
                      <Grid item xs={2}>
                        <span style={{ borderLeft: "5px solid #3D9E5A" }}>
                          {" "}
                        </span>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "left" }}>
                        <span>
                          <b>In hand</b>
                        </span>
                      </Grid>
                      <Grid item xs={4}>
                        <span style={{ color: "#0373FF" }}>$ 6000</span>
                      </Grid>
                    </Grid>
                    <br></br>
                    <br></br>
                    <Grid
                      container
                      style={{
                        fontSize: "20px"
                      }}
                    >
                      <Grid item xs={2}>
                        <span style={{ borderLeft: "5px solid #F25929" }}>
                          {" "}
                        </span>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "left" }}>
                        <span>
                          <b>Total Deductions</b>
                        </span>
                      </Grid>
                      <Grid item xs={4}>
                        <span style={{ color: "#0373FF" }}>$ 6000</span>
                      </Grid>
                    </Grid>
                    <br></br>
                    <br></br>
                    <Grid
                      container
                      style={{
                        fontSize: "20px"
                      }}
                    >
                      <Grid item xs={2}>
                        <span style={{ borderLeft: "5px solid #0373FF" }}>
                          {" "}
                        </span>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "left" }}>
                        <span>
                          <b>Net pay</b>
                        </span>
                      </Grid>
                      <Grid item xs={4}>
                        <span style={{ color: "#0373FF" }}>$ 6000</span>
                      </Grid>
                    </Grid>
                    <br></br>
                    <br></br>
                    <LinearProgress variant="determinate" value={progress} />
                    <Typography
                      style={{
                        color: "white",
                        transform: "translate(-30%,-120%)"
                      }}
                    >
                      {progress}
                      {" %"}
                    </Typography>
                    <Typography
                      style={{
                        color: "white",
                        transform: "translate(35%, -220%)"
                      }}
                    >
                      {100 - progress}
                      {" %"}
                    </Typography>
                    <hr></hr>
                    <div
                      style={{
                        display: "flex",
                        float: "right"
                      }}
                    >
                      <Button
                        variant="contained"
                        style={{
                          color: "#1F299C",
                          border: "1px solid #1F299C",
                          backgroundColor: "white"
                        }}
                      >
                        <GetApp /> Download
                      </Button>
                      &nbsp; &nbsp; &nbsp; &nbsp;
                      <Button
                        variant="contained"
                        style={{ backgroundColor: "#1F299C", color: "white" }}
                        onClick={() => setshowPdf(true)}
                      >
                        <Visibility /> &nbsp; View Pay Stub
                      </Button>
                    </div>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Paper className={classes.paper}>
                  <div
                    style={{
                      height: "460px",
                      padding: "0.5em"
                    }}
                  >
                    <h2 style={{ color: "#1F299C", textAlign: "left" }}>
                      Total Pay details
                    </h2>
                    <hr></hr>
                    <br></br>
                    <br></br>
                    <Grid
                      container
                      style={{
                        fontSize: "20px",
                        textAlign: "left",
                        color: "#16215B",
                        padding: "12px"
                      }}
                    >
                      <Grid item xs={5}>
                        <span>(A) Basic</span>
                        <h3>
                          <b>$ 100 / hr</b>
                        </h3>
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{ borderLeft: "1px solid lightgray" }}
                      >
                        <span> </span>
                      </Grid>
                      <Grid item xs={5} style={{ textAlign: "left" }}>
                        <span>(B) Base Pay</span>
                        <h3>
                          <b>$ 60 / hr</b>
                        </h3>
                      </Grid>
                      <br></br>
                      <br></br>
                      <Grid item xs={5}>
                        <span>(C) Variable Pay</span>
                        <h3>
                          <b>$ 10 / hr</b>
                        </h3>
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{
                          borderLeft: "1px solid lightgray",
                          color: "#0373FF"
                        }}
                      >
                        <span> </span>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        style={{ textAlign: "left", color: "#0373FF" }}
                      >
                        <span>Total Pay (A+B+C)</span>
                        <h3>
                          <b>$ 200 / hr</b>
                        </h3>
                      </Grid>
                    </Grid>
                    <hr></hr>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#1F299C",
                        color: "white",
                        float: "right"
                      }}
                    >
                      Total Pay Statement
                    </Button>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </main>
        )}
        {showPdf}
        {showPdf && (
          <div
            className={clsx(classes.content, {
              [classes.contentShift]: props.isDrawerOpen
            })}
          >
            <Grid item xs={12} md={12} lg={12}>
              <Paper className={classes.paper}>
                <div
                  style={{
                    padding: "0.5em"
                  }}
                >
                  <h2 style={{ color: "#1F299C", textAlign: "left" }}>
                    Payslip
                  </h2>

                  <hr></hr>
                  <div
                    style={{
                      color: "#1F299C",
                      textAlign: "left",
                      padding: "12px",
                      display: "flex"
                    }}
                  >
                    <ArrowBackIos
                      style={{ cursor: "pointer" }}
                      onClick={() => setshowPdf(false)}
                    />
                    {/* <ArrowBackIos /> <ArrowForwardIos />{" "} */}
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <Grid
                        container
                        justify="space-around"
                        style={{ margin: "-6px" }}
                      >
                        <DatePicker
                          variant="inline"
                          openTo="year"
                          views={["year", "month"]}
                          value={selectedDate}
                          autoOk={true}
                          className={classes.datepicker}
                          onChange={handleDateChange}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>

                    {/* <Grid
                      container
                      xs={12}
                      lg={6}
                      style={{
                        fontSize: "12px"
                      }}
                    >
                      <Grid item xs={2}>
                        <span style={{ borderLeft: "5px solid #3D9E5A" }}>
                          {" "}
                        </span>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "left" }}>
                        <span>
                          <b>In hand</b>
                        </span>
                      </Grid>
                      <Grid item xs={4}>
                        <span style={{ color: "#0373FF" }}>$ 6000</span>
                      </Grid>
                    </Grid> */}

                    {/* <Grid
                      container
                      xs={12}
                      lg={6}
                      style={{
                        fontSize: "12px"
                      }}
                    >
                      <Grid item xs={2}>
                        <span style={{ borderLeft: "5px solid #F25929" }}>
                          {" "}
                        </span>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "left" }}>
                        <span>
                          <b>Total Deductions</b>
                        </span>
                      </Grid>
                      <Grid item xs={4}>
                        <span style={{ color: "#0373FF" }}>$ 6000</span>
                      </Grid>
                    </Grid> */}

                    {/* <Grid
                      container
                      xs={12}
                      lg={6}
                      style={{
                        fontSize: "12px"
                      }}
                    >
                      <Grid item xs={2}>
                        <span style={{ borderLeft: "5px solid #0373FF" }}>
                          {" "}
                        </span>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "left" }}>
                        <span>
                          <b>Net pay</b>
                        </span>
                      </Grid>
                      <Grid item xs={4}>
                        <span style={{ color: "#0373FF" }}>$ 6000</span>
                      </Grid>
                    </Grid> */}
                  </div>
                </div>
              </Paper>
            </Grid>
            <Iframe iframe={iframe} />
            <p></p>
          </div>
        )}
      </div>
    </Container>
  );
};
export default Payroll;
