import React, { useState, useEffect, useRef } from "react";
import ReactToPrint from "react-to-print";
import { useDispatch, useSelector } from "react-redux";
import { getAssetConditionCount } from "store/actions/AssetManagement/dashboard";
import Select from "react-select";
import { getAllAssetCategory } from "store/actions/AssetManagement/assetCategory";
import { getAllAssetSubCategory } from "store/actions/AssetManagement/assetSubCategory";

const AssetCategory = ({ allAssets, setAllAssets }) => {
  const dispatch = useDispatch();
  const { assetConditionCount, conditionCount } = useSelector(
    (state) => state.assetDashboard
  );
  const { assetCategory, assetSubCategory } = useSelector((state) => state);
  const [category, setCategory] = useState();
  const [subCategory, setSubCategory] = useState();
  const [totalConditions, setTotalConditions] = useState([]);

  const handleFilterChange = (obj, type) => {
    let params = {};
    if (type == "category") {
      params.category = obj?._id;
      params.sub_category = subCategory?._id;
      setCategory(obj);
    } else {
      params.category = category?._id;
      params.sub_category = obj?._id;
      setSubCategory(obj);
    }
    dispatch(getAssetConditionCount(params));
  };

  useEffect(() => {
    dispatch(getAssetConditionCount());
    dispatch(getAllAssetCategory());
    dispatch(getAllAssetSubCategory());
  }, []);

  useEffect(() => {
    if (conditionCount.fulfilled) {
      setTotalConditions(conditionCount.fulfilled);
    }
  }, [conditionCount]);

  const componentRef = useRef();
  const pageStyle = `{ size: 2.5in 4in}`;

  const dataFormatter = (data, totalConditions) => {
    let result = {};
    data.forEach((item) => {
      let conditionData = {};
      if (result[`${item.category}-${item.sub_category}`]) {
        conditionData = {
          ...result[`${item.category}-${item.sub_category}`],
        };
        totalConditions?.forEach((el) => {
          if (el.asset_condition == item.condition) {
            conditionData[item.condition] =
              conditionData[item.condition] + item.count;
            conditionData.total = conditionData.total + item.count;
          }
        });
      } else {
        conditionData = { ...item, total: 0 };
        totalConditions.forEach((el) => {
          if (el.asset_condition == item.condition) {
            conditionData[item.condition] = item.count;
            conditionData.total = item.count;
          } else {
            conditionData[el.asset_condition] = 0;
          }
        });
      }
      result[`${item.category}-${item.sub_category}`] = { ...conditionData };
      if (result[`${item.category}-${item.sub_category}`].count) {
        delete result[`${item.category}-${item.sub_category}`].count;
      }
      if (result[`${item.category}-${item.sub_category}`].condition) {
        delete result[`${item.category}-${item.sub_category}`].condition;
      }
    });
    return result;
  };

  return (
    <div className="rounded bg-white shadow-md p-5 my-5">
      <div className="flex gap-10 items-center flex-wrap justify-between">
        <p className="text-xl font-semibold">Asset Category</p>
        <div className="flex gap-2 flex-wrap">
          <Select
            placeholder="All Category"
            classNamePrefix="select"
            className="rounded outline-0 sm:w-72 w-full font-semibold"
            value={category}
            onChange={(obj) => handleFilterChange(obj, "category")}
            name="Category"
            isClearable="true"
            options={assetCategory?.allAssetCategory?.fulfilled || []}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option._id}
          />
          <Select
            placeholder="All Sub Category"
            classNamePrefix="select"
            className="rounded outline-0 sm:w-72 w-full font-semibold"
            value={subCategory}
            onChange={(obj) => handleFilterChange(obj, "sub category")}
            name="Sub Category"
            isClearable="true"
            options={assetSubCategory?.assetSubCategoryList?.fulfilled || []}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option._id}
          />
          <ReactToPrint
            pageStyle={pageStyle}
            trigger={() => {
              return (
                <i className="fa-solid fa-print border p-2 border-blue-700 text-blue-700 rounded cursor-pointer"></i>
              );
            }}
            content={() => componentRef.current}
          />
        </div>
      </div>
      <div className="overflow-x-auto" ref={componentRef}>
        <table className="w-full mt-5 text-blue-900">
          <tr className="border-2 bg-gray-100">
            <th className="text-left pl-5 py-2">Category</th>
            <th className="text-left pl-5 py-2">Sub-Category</th>
            {totalConditions?.length > 0 &&
              totalConditions.map((obj) => (
                <th className="text-left pl-5 py-2">{obj.asset_condition}</th>
              ))}
            <th className="text-left pl-5 py-2">Total</th>
          </tr>

          {assetConditionCount?.fulfilled &&
            totalConditions?.length > 0 &&
            Object.values(
              dataFormatter(assetConditionCount?.fulfilled, totalConditions)
            )?.map((item) => {
              return (
                <tr className="border-2">
                  <td className="text-left pl-5 py-2">{item?.category}</td>
                  <td className="text-left pl-5 py-2">{item?.sub_category}</td>
                  {totalConditions?.map((obj) => (
                    <td className="text-left pl-5 py-2">
                      {item[obj.asset_condition]}
                    </td>
                  ))}

                  <td className="text-left pl-5 py-2">{item?.total}</td>
                </tr>
              );
            })}
        </table>
      </div>
    </div>
  );
};

export default AssetCategory;
