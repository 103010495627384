import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  dashboardData: {},
  assetStatus: [],
  assetConditionCount: {},
  conditionCount: {},
};

export const dashboardSlice = createSlice({
  name: "AssetDashboard",
  initialState,
  reducers: {
    getAssetDashboardPending(state, action) {
      return {
        ...state,
        dashboardData: { loading: true },
      };
    },
    getAssetDashboardFulfilled(state, action) {
      return {
        ...state,
        dashboardData: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    getAssetDashboardRejected(state, action) {
      return {
        ...state,
        dashboardData: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
    getAssetStatusPending(state, action) {
      return {
        ...state,
        assetStatus: { loading: true },
      };
    },
    getAssetStatusFulfilled(state, action) {
      return {
        ...state,
        assetStatus: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    getAssetStatusRejected(state, action) {
      return {
        ...state,
        assetStatus: {
          loading: false,
          rejected: action.payload,
        },
      };
    },

    getAssetConditionCountPending(state, action) {
      return {
        ...state,
        assetConditionCount: { loading: true },
      };
    },
    getAssetConditionCountFulfilled(state, action) {
      return {
        ...state,
        assetConditionCount: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    getAssetConditionCountRejected(state, action) {
      return {
        ...state,
        assetConditionCount: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
    clearAssetConditionCount(state, action) {
      return {
        ...state,
        assetConditionCount: {},
      };
    },

    getConditionCountPending(state, action) {
      return {
        ...state,
        conditionCount: { loading: true },
      };
    },
    getConditionCountFulfilled(state, action) {
      return {
        ...state,
        conditionCount: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    getConditionCountRejected(state, action) {
      return {
        ...state,
        conditionCount: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
    clearConditionCount(state, action) {
      return {
        ...state,
        conditionCount: {},
      };
    },
  },
});

export const {
  getAssetDashboardPending,
  getAssetDashboardFulfilled,
  getAssetDashboardRejected,
  getAssetStatusPending,
  getAssetStatusFulfilled,
  getAssetStatusRejected,
  getAssetConditionCountFulfilled,
  getAssetConditionCountPending,
  getAssetConditionCountRejected,
  clearAssetConditionCount,
  getConditionCountFulfilled,
  getConditionCountPending,
  getConditionCountRejected,
  clearConditionCount,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
