import React from "react";
import { Link } from "react-router-dom";

const AllAssetsList = ({
  allAssets,
  assetAddUpdateHandler,
  assignAssetHandler,
  assetRemarkHandler,
}) => {
  return (
    <div className="mt-5">
      {allAssets.length > 0
        ? allAssets.map((el) => (
            <div
              className="flex border md:justify-between justify-center gap-5 rounded-lg p-3 mt-2 md:flex-nowrap flex-wrap"
              key={el._id}
            >
              <img src={el.image} alt={el.image} className="w-28 border p-1" />
              <div className="w-full">
                <div className="flex justify-between">
                  <Link to={`/assets/assetdetails/${el._id}`}>
                    <p className="text-lg font-bold">{el.about}</p>
                  </Link>
                  <i
                    className="fa-solid fa-pen cursor-pointer"
                    onClick={() => {
                      assetAddUpdateHandler(el._id);
                    }}
                  ></i>
                </div>
                <div className="flex justify-between flex-wrap gap-2">
                  <div className="mt-2">
                    <p className=" text-sm opacity-80">Asset No.</p>
                    <p className="font-bold text-blue-900">{el.asset_no}</p>
                  </div>
                  <div className="mt-2">
                    <p className=" text-sm opacity-80">Asset Type</p>
                    <p className="font-bold text-blue-900">{el.brand}</p>
                  </div>
                  <div className="mt-2">
                    <p className=" text-sm opacity-80">Avaliable Qty</p>
                    <p className="font-bold text-blue-900">
                      {el.available_quantity}
                    </p>
                  </div>
                  <div className="mt-2">
                    <p className=" text-sm opacity-80">Conditions</p>
                    <p className="font-bold text-blue-900">{el.condition}</p>
                  </div>
                  <div className="flex items-center mt-7 gap-2">
                    <i
                      className="fa-solid fa-message text-sm  cursor-pointer"
                      onClick={() => assetRemarkHandler(el._id)}
                    ></i>
                    <p
                      className="font-bold text-blue-900 cursor-pointer"
                      onClick={() => assetRemarkHandler(el._id)}
                    >
                      Remark
                    </p>
                  </div>
                  <div className="mt-2">
                    <p
                      className="font-bold text-blue-900 mt-5 cursor-pointer"
                      onClick={() => assignAssetHandler(el._id)}
                    >
                      + Assign Asset
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))
        : "No Result Found"}
    </div>
  );
};

export default AllAssetsList;
