import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  postAssignAssetData: {},
  postAddAssetRemarkData: {},
};

export const assetAssignSlice = createSlice({
  name: "AssetAssign",
  initialState,
  reducers: {
    postAssignAssetPending(state, action) {
      return {
        ...state,
        postAssignAssetData: { loading: true },
      };
    },
    postAssignAssetFulfilled(state, action) {
      return {
        ...state,
        postAssignAssetData: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    postAssignAssetRejected(state, action) {
      return {
        ...state,
        postAssignAssetData: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
    clearPostAssignAsset(state, action) {
      return {
        ...state,
        postAssignAssetData: {},
      };
    },

    postAddAssetRemarkPending(state, action) {
      return {
        ...state,
        postAddAssetRemarkData: { loading: true },
      };
    },
    postAddAssetRemarkFulfilled(state, action) {
      return {
        ...state,
        postAddAssetRemarkData: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    postAddAssetRemarkRejected(state, action) {
      return {
        ...state,
        postAddAssetRemarkData: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
    clearPostAddAssetRemark(state, action) {
      return {
        ...state,
        postAddAssetRemarkData: {},
      };
    },

    handoverAssetPending(state, action) {
      return {
        ...state,
        handoverAssetData: { loading: true },
      };
    },
    handoverAssetFulfilled(state, action) {
      return {
        ...state,
        handoverAssetData: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    handoverAssetRejected(state, action) {
      return {
        ...state,
        handoverAssetData: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
  },
});

export const {
  postAssignAssetFulfilled,
  postAssignAssetPending,
  postAssignAssetRejected,
  clearPostAssignAsset,
  postAddAssetRemarkFulfilled,
  postAddAssetRemarkPending,
  postAddAssetRemarkRejected,
  clearPostAddAssetRemark,
  handoverAssetFulfilled,
  handoverAssetPending,
  handoverAssetRejected,
} = assetAssignSlice.actions;

export default assetAssignSlice.reducer;
