import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  shiftTags: {},
  shiftTag: {},
  loading: false,
};

export const ShiftTagsSlice = createSlice({
  name: 'ShiftTags',
  initialState,
  reducers: {
    getShiftTagsPending(state, action) {
      return {
        ...state,
        shiftTags: { loading: true },
        loading: true,
      };
    },
    getShiftTagsFulfilled(state, action) {
      return {
        ...state,
        shiftTags: {
          fulfilled: action.payload.data,
          totalLength: action.payload.length,
        },
        loading: false,
      };
    },
    getShiftTagsRejected(state, action) {
      return {
        ...state,
        shiftTags: {
          rejected: action.payload,
        },
        loading: false,
      };
    },

    addShiftTagPending(state, action) {
      return {
        ...state,
        shiftTag: action.payload,
        loading: true,
      };
    },
    addShiftTagFulfilled(state, action) {
      return {
        ...state,
        shiftTag: action.payload,
        success: true,
        loading: false,
      };
    },
    addShiftTagRejected(state, action) {
      return {
        ...state,
        rejected: action.payload,
        success: false,
      };
    },

    updateShiftTagPending(state, action) {
      return {
        ...state,
        loading: true,
        shiftTag: action.payload,
      };
    },
    updateShiftTagFulfilled(state, action) {
      return {
        ...state,
        loading: false,
        shiftTag: action.payload,
        success: true,
      };
    },
    updateShiftTagRejected(state, action) {
      return {
        ...state,
        loading: false,
        rejected: action.payload,
        success: false,
      };
    },

    deleteShiftTagPending(state, action) {
      return {
        ...state,
        loading: true,
        shiftTag: action.payload,
      };
    },
    deleteShiftTagFulfilled(state, action) {
      return {
        ...state,
        loading: false,
        shiftTag: action.payload,
        success: true,
      };
    },
    deleteShiftTagRejected(state, action) {
      return {
        ...state,
        loading: false,
        rejected: action.payload,
        success: false,
      };
    },

    serachShiftTagsPending(state, action) {
      return {
        ...state,
        shiftTags: { loading: true },
        loading: true,
      };
    },
    serachShiftTagsFulfilled(state, action) {
      return {
        ...state,
        shiftTags: {
          fulfilled: action.payload.data.data,
          totalLength: action.payload.data.length,
        },
        loading: false,
      };
    },
    serachShiftTagsRejected(state, action) {
      return {
        ...state,
        shiftTags: {
          rejected: action.payload,
        },
        loading: false,
      };
    },
  },
});

export const {
  serachShiftTagsPending,
  serachShiftTagsFulfilled,
  serachShiftTagsRejected,

  getShiftTagsPending,
  getShiftTagsFulfilled,
  getShiftTagsRejected,

  addShiftTagFulfilled,
  addShiftTagRejected,
  addShiftTagPending,

  updateShiftTagFulfilled,
  updateShiftTagRejected,
  updateShiftTagPending,

  deleteShiftTagRejected,
  deleteShiftTagFulfilled,
  deleteShiftTagPending,
} = ShiftTagsSlice.actions;

export default ShiftTagsSlice.reducer;
