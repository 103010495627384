import React, { useState, useEffect } from "react";
import CategorySubConditonTop from "../CategorySubConditonTop";
import AddSubCategoryList from "./SubCategoryList";
import Axios from "axios";
import {
  getAllAssetSubCategory,
  deleteAssetSubCategory,
} from "store/actions/AssetManagement/assetSubCategory";
import { clearDeleteAssetSubCategory } from "store/slices/AssetManagement/assetSubCategory";
import { useDispatch, useSelector } from "react-redux";
import { getAllAssetCategory } from "store/actions/AssetManagement/assetCategory";

import {
  DialogTitle,
  DialogContentText,
  DialogActions,
  Dialog,
  Button,
  Backdrop,
  CircularProgress,
  DialogContent,
} from "@material-ui/core";
import SubCategoryTopFilter from "./SubCategoryTopFilter";

const AddSubCategory = () => {
  const dispatch = useDispatch();
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [search, setSearch] = useState("");
  const [deleteId, setDeleteId] = useState();
  const [showDialogToDelete, setShowDialogToDelete] = useState(false);
  const { assetCategory, assetSubCategory } = useSelector((state) => state);

  const onChangeCategory = (obj) => {
    setSelectedCategory(obj);
    let newSubCategoryList = [
      ...assetSubCategory?.assetSubCategoryList?.fulfilled,
    ];
    if (search != "") {
      newSubCategoryList = [...newSubCategoryList].filter((item) =>
        item.name
          .toLowerCase()
          .split(" ")
          .join("")
          .startsWith(search?.toLowerCase().split(" ").join(""))
      );
    }
    if (obj) {
      newSubCategoryList = [...newSubCategoryList].filter(
        (item) => item.category_id == obj._id
      );
      setSubCategoryList(newSubCategoryList);
    } else {
      setSubCategoryList(newSubCategoryList);
    }
  };

  const onSearch = (obj) => {
    setSearch(obj);
    let newSubCategoryList = [
      ...assetSubCategory?.assetSubCategoryList?.fulfilled,
    ];
    if (selectedCategory?._id) {
      newSubCategoryList = [...newSubCategoryList].filter(
        (item) => item.category_id == selectedCategory._id
      );
    }
    if (obj != "") {
      newSubCategoryList = [...newSubCategoryList]?.filter((item) =>
        item.name
          .toLowerCase()
          .split(" ")
          .join("")
          .startsWith(obj?.toLowerCase().split(" ").join(""))
      );
      setSubCategoryList(newSubCategoryList);
    } else {
      setSubCategoryList(newSubCategoryList);
    }
  };

  useEffect(() => {
    dispatch(getAllAssetSubCategory());
    dispatch(getAllAssetCategory());
  }, []);

  const hideDialogToDelete = (id) => {
    setShowDialogToDelete(false);
  };
  const subCategoryDeleteHandler = (id) => {
    setShowDialogToDelete(true);
    setDeleteId(id);
  };

  const handleDelete = (id) => {
    dispatch(deleteAssetSubCategory(id));
  };

  useEffect(() => {
    if (assetSubCategory?.deleteSubCategory?.fulfilled) {
      dispatch(getAllAssetSubCategory());
      dispatch(clearDeleteAssetSubCategory());
      setDeleteId(null);
    }
  }, [assetSubCategory?.deleteSubCategory]);

  useEffect(() => {
    if (assetSubCategory?.assetSubCategoryList?.fulfilled) {
      setSubCategoryList(assetSubCategory?.assetSubCategoryList?.fulfilled);
    }
  }, [assetSubCategory?.assetSubCategoryList]);
  useEffect(() => {
    if (assetCategory?.allAssetCategory?.fulfilled) {
      setCategoryList(assetCategory?.allAssetCategory?.fulfilled);
    }
  }, [assetCategory?.allAssetCategory]);

  return (
    <div className="bg-white">
      <SubCategoryTopFilter
        categoryList={categoryList}
        onChangeCategory={onChangeCategory}
        selectedCategory={selectedCategory}
        onSearch={onSearch}
        search={search}
      />
      <AddSubCategoryList
        subCategoryList={subCategoryList}
        subCategoryDeleteHandler={subCategoryDeleteHandler}
        categoryList={categoryList}
      />
      <Dialog
        open={showDialogToDelete}
        onClick={() => hideDialogToDelete()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to delete this message?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => hideDialogToDelete()}>Cancel</Button>
          <Button onClick={(e) => handleDelete(deleteId)} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddSubCategory;
