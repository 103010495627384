import React, { useEffect, useState } from "react";
import TotalCount from "./TotalCount";
import AssetsMangement from "./AssetsMangement";
import AssetByStatus from "./AssetByStatus";
import AssetCategory from "./AssetCategory";
import "../index.css";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { useDasboardStyles } from "pages/EmployeeManagement/Dashboard/Style";
import { useDispatch, useSelector } from "react-redux";
import { getAssetDashboard } from "store/actions/AssetManagement/dashboard";
import { getAllAsset } from "store/actions/AssetManagement/allAssets";

const Dashboard = () => {
  const [state, setState] = useState({});
  const [allCategory, setAllCategory] = useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const classes = useDasboardStyles();
  const dispatch = useDispatch();
  const { dashboardData } = useSelector((state) => state.assetDashboard);
  const { allAsset, assetCategory, assetSubCategory, assetCondition } =
    useSelector((state) => state);

  const [allAssets, setAllAssets] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(true);
    dispatch(getAssetDashboard());
    dispatch(getAllAsset());
  }, []);

  useEffect(() => {
    if (dashboardData.fulfilled) {
      setState(dashboardData.fulfilled.model[0]);
      let category = dashboardData.fulfilled.catergory.map((cat) => {
        return {
          value: cat._id,
          key: cat.name,
        };
      });
      setAllCategory(category);
      setIsLoading(false);
    }
  }, [dashboardData.fulfilled]);
  useEffect(() => {
    if (allAsset?.allAssetList?.fulfilled) {
      setAllAssets(allAsset?.allAssetList?.fulfilled);
      setIsLoading(false);
    }
  }, [allAsset?.allAssetList]);

  try {
    return (
      <div>
        {state ? (
          <>
            <TotalCount state={state} />
            <div className="flex gap-5 flex-wrap md:flex-nowrap">
              <AssetsMangement
                state={{
                  all_assets: state.all_assets,
                  assigned: state.assigned,
                  available: state.available,
                  unAvailable: state.unAvailable,
                }}
              />
              <AssetByStatus allCategory={allCategory} status={state.status} />
            </div>
            <AssetCategory
              allCategory={allCategory}
              allAssets={allAssets}
              setAllAssets={setAllAssets}
            />
          </>
        ) : (
          <></>
        )}
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  } catch (e) {
    console.log(e);
  }
};

export default Dashboard;
