import React from "react";
import { useHistory } from "react-router-dom";

const TotalCount = ({ state }) => {
  const history = useHistory();
  return (
    <div className="flex justify-between gap-5 flex-wrap lg:flex-nowrap">
      <div className="bg-blue-700 w-40 pt-5 px-5 rounded">
        <p className="text-lg text-white font-medium">All Assets</p>
        <div className="text-white mt-10 text-right">
          <p className="text-4xl font-bold">{state && state.all_assets}</p>
          <p>Total</p>
        </div>
      </div>

      <div className="bg-green-500 w-40 pt-5 px-5 rounded">
        <p className="text-lg text-white font-medium">Available</p>
        <div className="text-white mt-10 text-right">
          <p className="text-4xl font-bold">{state && state.available}</p>
          <p>Total</p>
        </div>
      </div>
      <div
        className="bg-blue-700 w-40 pt-5 px-5 rounded"
        onClick={() => {
          history.push("/assets/allassignedassets");
        }}
      >
        <p className="text-lg text-white font-medium">Assigned</p>
        <div className="text-white mt-10 text-right">
          <p className="text-4xl font-bold">{state && state.assigned}</p>
          <p>Total</p>
        </div>
      </div>
      <div className="bg-red-500 w-40 pt-5 px-5 rounded">
        <p className="text-lg text-white font-medium">Unavailable</p>
        <div className="text-white mt-10 text-right">
          <p className="text-4xl font-bold">{state && state.unAvailable}</p>
          <p>Total</p>
        </div>
      </div>
      <div
        className="bg-white w-40 p-5 rounded shadow-md"
        onClick={() => history.push("/assets/resignandterminate")}
      >
        <p className="text-lg font-medium text-blue-700">
          Registration/ Termination
        </p>
        <div className="text-white text-right">
          <p className="text-4xl font-bold text-red-500">
            {state && state.resignations_terminations}
          </p>
          <p className="text-blue-700">Total</p>
        </div>
      </div>
      <div
        className="bg-white w-40 p-5 rounded shadow-md"
        onClick={() => history.push("/assets/newrequests")}
      >
        <p className="text-lg font-medium text-blue-700">New Request</p>
        <div className="text-white mt-4 text-right">
          <p className="text-4xl font-bold text-red-500">
            {state && state.new_request}
          </p>
          <p className="text-blue-700">Total</p>
        </div>
      </div>
    </div>
  );
};

export default TotalCount;
