import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  shiftProjects: [],
  shiftJobsites: [],
  shiftProject: [],
  shiftJobsite: [],
};

export const ShiftProjectNSitesSlice = createSlice({
  name: 'ShiftProjectNSites',
  initialState,
  reducers: {
    getShiftProjectPending(state, action) {
      return {
        ...state,
        shiftProjects: { loading: true },
      };
    },
    getShiftProjectFulfilled(state, action) {
      return {
        ...state,
        shiftProjects: {
          loading: false,
          fulfilled: action.payload.data,
          length: action.payload.length,
        },
      };
    },
    getShiftProjectRejected(state, action) {
      return {
        ...state,
        shiftProjects: {
          loading: false,
          rejected: action.payload.data,
        },
      };
    },
    createShiftProjectPending(state, action) {
      return {
        ...state,
        shiftProject: { loading: true },
      };
    },
    createShiftProjectFulfilled(state, action) {
      return {
        ...state,
        shiftProject: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    createShiftProjectRejected(state, action) {
      return {
        ...state,
        shiftProject: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
    updateShiftProjectPending(state, action) {
      return {
        ...state,
        shiftProject: { loading: true },
      };
    },
    updateShiftProjectFulfilled(state, action) {
      return {
        ...state,
        shiftProject: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    updateShiftProjectRejected(state, action) {
      return {
        ...state,
        shiftProject: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
    deleteProjectPending(state, action) {
      return {
        ...state,
        shiftProject: { loading: true },
      };
    },
    deleteProjectFulfilled(state, action) {
      return {
        ...state,
        shiftProject: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    deleteProjectRejected(state, action) {
      return {
        ...state,
        shiftProject: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
    getShiftSitePending(state, action) {
      return {
        ...state,
        shiftJobsites: { loading: true },
      };
    },
    getShiftSiteFulfilled(state, action) {
      return {
        ...state,
        shiftJobsites: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    getShiftSiteRejected(state, action) {
      return {
        ...state,
        shiftJobsites: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
    updateJobsiteAssignEmpPending(state, action) {
      return {
        ...state,
        shiftJobsite: { loading: true },
      };
    },
    updateJobsiteAssignEmpFulfilled(state, action) {
      return {
        ...state,
        shiftJobsite: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    updateJobsiteAssignEmpRejected(state, action) {
      return {
        ...state,
        shiftJobsite: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
    createShiftJobsitePending(state, action) {
      return {
        ...state,
        shiftJobsites: { loading: true },
      };
    },
    createShiftJobsiteFulfilled(state, action) {
      return {
        ...state,
        shiftJobsites: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    createShiftJobsiteRejected(state, action) {
      return {
        ...state,
        shiftJobsites: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
    updateShiftJobsitePending(state, action) {
      return {
        ...state,
        shiftJobsite: { loading: true },
      };
    },
    updateShiftJobsiteFulfilled(state, action) {
      return {
        ...state,
        shiftJobsite: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    updateShiftJobsiteRejected(state, action) {
      return {
        ...state,
        shiftJobsite: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
    deleteJobsitePending(state, action) {
      return {
        ...state,
        shiftJobsite: { loading: true },
      };
    },
    deleteJobsiteFulfilled(state, action) {
      return {
        ...state,
        shiftJobsite: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    deleteJobsiteRejected(state, action) {
      return {
        ...state,
        shiftJobsite: {
          loading: false,
          rejected: action.payload,
        },
      };
    },

    searchShiftProjectPending(state, action) {
      return {
        ...state,
        shiftProjects: { loading: true },
      };
    },
    searchShiftProjectFulfilled(state, action) {
      console.log(action);
      return {
        ...state,
        shiftProjects: {
          loading: false,
          fulfilled: action.payload.data,
          length: action.payload.length,
        },
      };
    },
    searchShiftProjectRejected(state, action) {
      return {
        ...state,
        shiftProjects: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
  },
});

export const {
  searchShiftProjectPending,
  searchShiftProjectFulfilled,
  searchShiftProjectRejected,
  getShiftProjectPending,
  getShiftProjectFulfilled,
  getShiftProjectRejected,
  getShiftSitePending,
  getShiftSiteFulfilled,
  getShiftSiteRejected,
  deleteProjectFulfilled,
  deleteProjectPending,
  deleteProjectRejected,
  deleteJobsitePending,
  deleteJobsiteFulfilled,
  deleteJobsiteRejected,
  updateJobsiteAssignEmpPending,
  updateJobsiteAssignEmpFulfilled,
  updateJobsiteAssignEmpRejected,
  createShiftJobsiteFulfilled,
  createShiftJobsitePending,
  createShiftJobsiteRejected,
  updateShiftJobsiteFulfilled,
  updateShiftJobsitePending,
  updateShiftJobsiteRejected,
  createShiftProjectFulfilled,
  createShiftProjectPending,
  createShiftProjectRejected,
  updateShiftProjectFulfilled,
  updateShiftProjectPending,
  updateShiftProjectRejected,
} = ShiftProjectNSitesSlice.actions;

export default ShiftProjectNSitesSlice.reducer;
