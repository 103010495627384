import {
  getAssetDashboardRepo,
  getAssetStatusRepo,
  getAssetConditionCountRepo,
  getConditionCountRepo,
} from "store/repositories/AssetManagement/dashboard";
import {
  getAssetDashboardFulfilled,
  getAssetDashboardPending,
  getAssetDashboardRejected,
  getAssetStatusFulfilled,
  getAssetStatusPending,
  getAssetStatusRejected,
  getAssetConditionCountFulfilled,
  getAssetConditionCountPending,
  getAssetConditionCountRejected,
  getConditionCountFulfilled,
  getConditionCountPending,
  getConditionCountRejected,
} from "store/slices/AssetManagement/dashboard";

export const getAssetDashboard = (params) => {
  return (dispatch) => {
    dispatch(getAssetDashboardPending());
    getAssetDashboardRepo(params)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data?.data);
          dispatch(getAssetDashboardFulfilled(response?.data?.data));
        } else {
          dispatch(getAssetDashboardRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(getAssetDashboardRejected(error.message));
        }
      });
  };
};

export const getAssetStatus = (params) => {
  return (dispatch) => {
    dispatch(getAssetStatusPending());
    getAssetStatusRepo(params)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getAssetStatusFulfilled(response?.data?.data));
        } else {
          dispatch(getAssetStatusRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(getAssetStatusRejected(error.message));
        }
      });
  };
};

export const getAssetConditionCount = (params) => {
  return (dispatch) => {
    dispatch(getAssetConditionCountPending());
    getAssetConditionCountRepo(params)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getAssetConditionCountFulfilled(response?.data?.data));
        } else {
          dispatch(getAssetConditionCountRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(getAssetConditionCountRejected(error.message));
        }
      });
  };
};

export const getConditionCount = (params) => {
  return (dispatch) => {
    dispatch(getConditionCountPending());
    getConditionCountRepo(params)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getConditionCountFulfilled(response?.data?.data));
        } else {
          dispatch(getConditionCountRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(getConditionCountRejected(error.message));
        }
      });
  };
};
