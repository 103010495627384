import { getAllAssetResignationsRepo } from "store/repositories/AssetManagement/allAssetReignationList";
import {
  getAllAssetResignationsPending,
  getAllAssetResignationsFulfilled,
  getAllAssetResignationRejected,
} from "store/slices/AssetManagement/assetResignationList";

export const getAllAssetResignations = () => {
  return (dispatch) => {
    dispatch(getAllAssetResignationsPending());
    getAllAssetResignationsRepo()
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data?.data);
          dispatch(getAllAssetResignationsFulfilled(response?.data?.data));
        } else {
          dispatch(getAllAssetResignationRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(getAllAssetResignationRejected(error.message));
        }
      });
  };
};
