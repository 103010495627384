import React from "react";
import Sidebar from "pages/sidebar/Sidebar";

const EditSidebar = ({ editSidebarHandler }) => {
  return (
    <Sidebar sidebarHandler={editSidebarHandler} modalTitle="Assign Asset">
      <div className="font-bold">
        <div>
          <p className="text-gray-800">Item Name*</p>
          <select className="bg-gray-100 border-2 rounded-md outline-0 px-2 py-3 w-full font-bold mt-1 focus:ring focus:outline-none">
            <option>Laptop</option>
            <option>Computer</option>
            <option>T-Shirt</option>
            <option>Highway Truck</option>
          </select>
        </div>
        <div className="mt-4">
          <p className="text-gray-800">Asset Type*</p>
          <select className="bg-gray-100 border-2 rounded-md outline-0 px-2 py-3 w-full font-bold mt-1 focus:ring focus:outline-none">
            <option>MacBook Pro</option>
          </select>
        </div>
        <div className="mt-4">
          <p className="text-gray-800">Brand*</p>
          <select className="bg-gray-100 border-2 rounded-md outline-0 px-2 py-3 w-full font-bold mt-1 focus:ring focus:outline-none">
            <option>MacBook Pro</option>
          </select>
        </div>
        <div className="mt-4">
          <p className="text-gray-800">Model No</p>
          <input
            type="text"
            className="bg-gray-100 border-2 rounded-md outline-0 px-2 py-3 w-full font-bold mt-1 focus:ring focus:outline-none"
            value="ABCD123456789"
          />
        </div>
        <div className="mt-4">
          <p className="text-gray-800">Serial No</p>
          <input
            type="text"
            className="bg-gray-100 border-2 rounded-md outline-0 px-2 py-3 w-full font-bold mt-1 focus:ring focus:outline-none"
            value="ABCD123456789"
          />
        </div>
        <div className="mt-4">
          <p className="text-gray-800">Asset Condition</p>
          <select className="bg-gray-100 border-2 rounded-md outline-0 px-2 py-3 w-full font-bold mt-1 focus:ring focus:outline-none">
            <option>New</option>
            <option>Not-Working</option>
            <option>New</option>
            <option>Old</option>
          </select>
        </div>
        <div className="mt-4">
          <p className="text-gray-800">Hostname</p>
          <input
            type="text"
            className="bg-gray-100 text-gray-800 border-2 rounded-md outline-0 px-2 py-3 w-full mt-1 focus:ring focus:outline-none"
            value="Type"
          />
        </div>
        <div className="mt-4">
          <p className="text-gray-800">Used By</p>
          <input
            type="text"
            className="bg-gray-100 border-2 rounded-md outline-0 px-2 py-3 w-full font-bold mt-1 focus:ring focus:outline-none"
            value="Name Lastname"
          />
        </div>
        <div className="mt-4">
          <p className="text-gray-800">Asset Remark</p>
          <input
            type="text"
            className="bg-gray-100 border-2 rounded-md outline-0 px-2 py-3 w-full font-bold mt-1 focus:ring focus:outline-none"
            value="Simple task remark abcd"
          />
        </div>
        <div className="mt-4">
          <p className="text-gray-800">Attachment *</p>
          <div className="bg-gray-100 border-2 rounded-md outline-0 px-2 py-5 w-full font-bold mt-1 focus:ring focus:outline-none flex gap-2 items-center cursor-pointer">
            <p className="text-gray-500 text-base"> Drag & Drop Here,</p>{" "}
            <p>Or Browser File</p>
            <i className="fa-solid fa-link ml-3"></i>
          </div>
        </div>
        <div className="border-b-2 border-gray-300 my-5"></div>
        <div className="flex justify-end gap-5">
          <p
            className="py-1 px-3 font-semibold border cursor-pointer border-blue-700 rounded"
            onClick={editSidebarHandler}
          >
            Cancel
          </p>
          <p className="py-1 px-3 font-semibold border cursor-pointer border-blue-700 rounded bg-indigo-700 text-gray-50">
            Save
          </p>
        </div>
      </div>
    </Sidebar>
  );
};

export default EditSidebar;
