import axios from "axios";
import { Urls } from "store/urls";

export const getResignationByUserIdRepo = (id) => {
  return axios({
    method: "GET",
    url: Urls.GET_RESIGNATION_BY_USER + `${id}`,
  });
};

export const postResignationByUserIdRepo = (data) => {
  return axios({
    method: "POST",
    url: Urls.POST_RESIGNATION_BY_USER,
    data: data,
  });
};
