import axios from 'axios';
import { Urls, BASE_URL } from 'store/urls';

export const deletePositionsRepo = (id) => {
  return axios({
    baseURL: BASE_URL,
    method: 'DELETE',
    url: Urls.DELETE_POSITION + `${id}`,
  });
};

export const updatePositionsRepo = (data) => {
  return axios({
    baseURL: BASE_URL,
    method: 'PUT',
    url: Urls.UPDATE_POSITION,
    data: data,
  });
};

export const createPositionsRepo = (data) => {
  return axios({
    baseURL: BASE_URL,
    method: 'POST',
    url: Urls.CREATE_POSITION,
    data: data,
  });
};

export const searchPositionsRepo = (obj) => {
  const data = {
    limit: obj?.limit || 25,
    pageNumber: obj?.page || 1,
    status: obj?.status || '',
    searchKey: obj?.searchKey || '',
  };
  return axios({
    baseURL: BASE_URL,
    method: 'POST',
    url: Urls.SEARCH_POSITIONS,
    data,
  });
};
