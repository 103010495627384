import axios from "axios";
import { Urls } from "store/urls";

export const getPunchinByUserRepo = (id) => {
  return axios({
    method: "GET",
    url: Urls.GET_PUNCHIN_BY_USER + `${id}`,
  });
};

export const getLeavesByUserIdRepo = (id) => {
  return axios({
    method: "GET",
    url: Urls.GET_LEAVES_BY_USER + `${id}`,
  });
};
