import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  allAssetCategory: {},
  createAssetCategory: {},
  deleteAssetCategory: {},
};

export const assetCategorySlice = createSlice({
  name: "AssetCategory",
  initialState,
  reducers: {
    getAllAssetCategoryPending(state, action) {
      return {
        ...state,
        allAssetCategory: { loading: true },
      };
    },
    getAllAssetCategoryFulfilled(state, action) {
      return {
        ...state,
        allAssetCategory: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    getAllAssetCategoryRejected(state, action) {
      return {
        ...state,
        allAssetCategory: {
          loading: false,
          rejected: action.payload,
        },
      };
    },

    createAssetCategoryPending(state, action) {
      return {
        ...state,
        createAssetCategory: { loading: true },
      };
    },
    createAssetCategoryFulfilled(state, action) {
      return {
        ...state,
        createAssetCategory: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    createAssetCategoryRejected(state, action) {
      return {
        ...state,
        createAssetCategory: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
    clearCreateAssetCategory(state, action) {
      return {
        ...state,
        createAssetCategory: {},
      };
    },

    updateAssetCategoryPending(state, action) {
      return {
        ...state,
        updateAssetCategory: { loading: true },
      };
    },
    updateAssetCategoryFulfilled(state, action) {
      return {
        ...state,
        updateAssetCategory: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    updateAssetCategoryRejected(state, action) {
      return {
        ...state,
        updateAssetCategory: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
    clearUpdateAssetCategory(state, action) {
      return {
        ...state,
        updateAssetCategory: {},
      };
    },

    deleteAssetCategoryPending(state, action) {
      return {
        ...state,
        deleteAssetCategory: { loading: true },
      };
    },
    deleteAssetCategoryFulfilled(state, action) {
      return {
        ...state,
        deleteAssetCategory: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    deleteAssetCategoryRejected(state, action) {
      return {
        ...state,
        deleteAssetCategory: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
    clearDeleteAssetCategory(state, action) {
      return {
        ...state,
        deleteAssetCategory: {},
      };
    },
  },
});

export const {
  getAllAssetCategoryFulfilled,
  getAllAssetCategoryPending,
  getAllAssetCategoryRejected,
  createAssetCategoryPending,
  createAssetCategoryFulfilled,
  createAssetCategoryRejected,
  clearCreateAssetCategory,
  updateAssetCategoryPending,
  updateAssetCategoryFulfilled,
  updateAssetCategoryRejected,
  clearUpdateAssetCategory,
  deleteAssetCategoryPending,
  deleteAssetCategoryFulfilled,
  deleteAssetCategoryRejected,
  clearDeleteAssetCategory,
} = assetCategorySlice.actions;

export default assetCategorySlice.reducer;
