import React from "react";
import { SwatchesPicker } from "react-color";

class ReactColor extends React.Component {
  render() {
    return (
      <div className="mt-5">
        <SwatchesPicker />
      </div>
    );
  }
}

export default ReactColor;
