export const Urls = {
  GET_AUTH_TOKEN: "/users/validatetoken",
  GET_USER_BY_ID: "/users/getbyid/",
  GET_USERS_BY_ORG_ID: "/users/getbyorg/",
  GET_ORG_COUNTS: "/org/getCounts/",
  GET_SHIFT_BY_USER_ID: "/shift/getbyuserid/",
  GET_BDAYS_AND_HOLIDAYS: "/org/getBdaysNholidays/",
  GET_ATTENDANCE_BY_EMPLOYEE: "/attendance/getbyemp/",
  POST_ATTENDANCE_BREAKOUT: "/attendance/breakout",
  POST_ATTENDANCE_BREAKIN: "/attendance/breakin",
  POST_RAISE_QUERRY: "/requests/create",
  POST_APPLY_LEAVE: "/leaves/create",
  POST_ADD_MISSED_PUNCH: "attendance/missed-punchin",
  GET_ALL_MESSAGES: "/communication/getAllMessages",
  GET_ALL_QUERY_REQUESTS: "/requests/getAllRequests",
  GET_ATTENDANCE_BY_ORG: "/attendance/getbyorg/",
  GET_ALL_LEAVES: "/leaves/getAllLeaves",
  PUT_LEAVES_UPDATE: "/leaves/update",
  PUT_SHIFT_UPDATE_WORKSHEET: "/shift/update-worksheet",
  POST_ATTENDANCE_PUNCHIN: "/attendance/punchin",
  POST_ATTENDANCE_PUNCHOUT: "/attendance/punchout",

  //-----------------shift Management ----------------
  GET_SHIFT_TASKS: "/shiftTask/getAllJobTask",
  POST_SHIFT_TASK: "/shiftTask/create",
  PUT_SHIFT_TASK: "/shiftTask/update",
  DELETE_SHIFT_TASK: "/shiftTask/delete/",

  GET_SHIFT_TAGS: "/tag/getAllTags/",
  POST_SHIFT_TAG: "/tag/create",
  PUT_SHIFT_TAG: "/tag/update",
  DELETE_SHIFT_TAG: "/tag/delete/",

  GET_SHIFT_POSITIONS: "/position/getAllPosition/",
  POST_SHIFT_POSITION: "/position/create",
  PUT_SHIFT_POSITION: "/position/update",
  DELETE_SHIFT_POSITION: "/position/delete/",

  GET_SHIFT_TEMPLATE: "/shiftTemplate/getAllShiftTemplate",
  POST_SHIFT_TEMPLATE: "/shiftTemplate/create",
  PUT_SHIFT_TEMPLATE: "/shiftTemplate/update",
  DELETE_SHIFT_TEMPLATE: "/shiftTemplate/delete/",

  GET_RESIGNATION_BY_USER: "/resignation/getbyuserid/",
  POST_RESIGNATION_BY_USER: "resignation/create",
  GET_ALL_COMMUNICATION_MESSAGES: "/communication/getAllMessages",
  DELETE_COMMUNICATION_BY_ID: "/communication/deleteMessage/",
  EDIT_COMMUNICATION_BY_ID: "/communication/update/",
  CREATE_COMMUNICATION: "/communication/create",
  GET_ALL_QUERIES_AND_TICKETS: "/requests/getAllRequests",
  GET_ALL_HR_RESIGNATIONS: "/resignation/getAllResignations",
  UPDATE_HR_RESIGNATION: "/resignation/update",
  UPDDATE_EMPLOYEE_STATUS: "/users/updateStatus",
  GET_ALL_SHIFT_TEMPLATES: "/shiftTemplate/getAllShiftTemplate",
  GET_ALL_SHIFT_POSITONS: "/position/getAllPosition",
  DELETE_SHIFT_TEMPLATES: "/shiftTemplate/delete/",
  UPDATE_SHIFT_TEMPLATES: "/shiftTemplate/update",
  GET_PUNCHIN_BY_USER: "/attendance/getbyemp/",
  GET_LEAVES_BY_USER: "/leaves/getbyuserid/",
  GET_ALL_TAGS: "/tag/getAllTags",
  DELETE_POSITION: "/position/delete/",
  UPDATE_POSITION: "/position/update/",
  CREATE_POSITION: "/position/create",
  GET_ALL_SHIFT_TASK_JOBS: "/shiftTask/getAllJobTask",
  GET_ALL_SHIFT_PROJECTS: "/project/getAllProjects/",
  GET_ALL_SHIFT_JOBS_SITES: "/jobSite/getAllJobSite",
  DELETE_SHIFT_PROJECT: "/project/delete/",
  DELETE_SHIFT_JOBSITE: "/jobSite/delete/",
  UPDATE_SHIFT_JOBSITE_ASSIGN_EMPLOYEE: "/jobSite/assign_employee",
  CREATE_SHIFT_JOBSITE: "/jobSite/create",
  UPDATE_SHIFT_JOBSITE: "/jobSite/update",
  CREATE_SHIFT_PROJECT: "/project/create",
  UPDATE_SHIFT_PROJECT: "/project/update",
  GET_ALL_SCHEDULES: "/scheduler",
  GET_ALL_JOB_SITES: "/jobSite/getAllJobSite",
  //-----------------------Asset Management--------------
  GET_ASSET_DASHBOARD: "/asset/dashboard",
  GET_ASSET_CONDITION_COUNT: "/asset/getAssetConditionCount",
  GET_CONDITION_COUNT: "/asset/getConditionCount",
  GET_ALL_ASSET: "/asset/getAll",
  GET_ALL_ASSET_GROUP_BY_USER: "/asset/getAllGroupByUser",
  GET_ASSETS_BY_USER_ID: "/asset/getbyuserid/",
  GET_ASSET_BY_ID: "/asset/getbyid/",

  CREATE_NEW_ASSET: "/asset/create",
  UPDATE_NEW_ASSET: "/asset/update/",
  ADD_ASSET_REMARK: "/asset/add/remark",
  ASSIGN_ASSET: "/asset/assign",
  HANDOVER_ASSET: "/asset/assignment/create",

  GET_ALL_ASSET_CATEGORY: "/asset/category/getAllAssetCategory",
  GET_ASSET_CATEGORY_BY_ID: "/asset/category/getbyid/",
  POST_ASSET_CATEGORY: "/asset/category/create",
  UPDATE_ASSET_CATEGORY: "/asset/category/update",
  DELETE_ASSET_CATEGORY: "/asset/category/delete/",

  GET_ALL_ASSET_SUB_CATEGORY: "/asset/sub/category/getAllAssetSubCategory",
  GET_ASSET_SUB_CATEGORY_BY_ID: "/asset/sub/category/getbyid/",
  POST_ASSET_SUB_CATEGORY: "/asset/sub/category/create",
  UPDATE_ASSET_SUB_CATEGORY: "/asset/sub/category/update",
  DELETE_ASSET_SUB_CATEGORY: "/asset/sub/category/delete/",

  GET_ALL_ASSET_CONDITION: "/asset/condition/getAll",
  CREATE_ASSET_CONDITION: "/asset/condition/create",
  UPDATE_ASSET_CONDITION: "/asset/condition/update",
  DELETE_ASSET_CONDITION: "/asset/condition/delete/",

  GET_ALL_ASSET_STATUS: "/asset/status/getAll",
  GET_ALL_ASSET_RESIGNATIONS: "/resignation/getAllResignations",
  SEARCH_POSITIONS: "/position/search",
  SEARCH_SHIFTTTAG: "/tag/search",
  SEARCH_SHIFTTASK: "/shiftTask/search",
  SEARCH_SHIFTTEMPLATE: "/shiftTemplate/search",
  SEARCH_SHIFTPROJECT: "project/search",
};

export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
