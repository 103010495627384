import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllAsset } from "store/actions/AssetManagement/allAssets";
import Select from "react-select";
const TopFilter = ({
  assetAddUpdateHandler,
  sidebarFilterHandler,
  categoryList,
  subCategoryList,
  search,
  setSearch,
  setMultipleFilter,
  multipleFilter,
  createFilterData,
}) => {
  const dispatch = useDispatch();
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedSubCategory, setSelectedSubCatgory] = useState();

  const onChangeSelect = (obj, type) => {
    console.log("...onCHangeselect", obj);
    let params = {};
    params.search_term = search != "" ? search : null;
    if (type == "category") {
      params.sub_category = selectedSubCategory?._id;
      params.category = obj?._id;
      setSelectedCategory(obj);
    } else {
      params.category = selectedCategory?._id;
      params.sub_category = obj?._id;
      setSelectedSubCatgory(obj);
    }
    dispatch(getAllAsset(params));
  };

  const onSearch = (value) => {
    console.log(value);
    setSearch(value);
    let params = {};
    params.search_term = value != "" ? value : null;
    params.sub_category = selectedSubCategory?._id;
    params.category = selectedCategory?._id;
    dispatch(getAllAsset(params));
  };

  const onClear = () => {
    setSearch("");
    setSelectedCategory(null);
    setSelectedSubCatgory(null);
    setMultipleFilter(false);
    createFilterData();
    dispatch(getAllAsset());
  };

  return (
    <div className="flex gap-2 flex-wrap justify-between">
      <div className="flex gap-2 flex-wrap">
        <input
          type="text"
          placeholder="Search..."
          className="bg-white-100 border border-grey-700 rounded outline-1 px-2  sm:w-72 w-full font-semibold"
          onChange={(e) => onSearch(e.target.value)}
          value={search}
        />
        <Select
          placeholder="Select Category"
          className="bg-gray-100  rounded outline-0  sm:w-72 w-full font-semibold"
          classNamePrefix="select"
          value={selectedCategory}
          onChange={(obj) => onChangeSelect(obj, "category")}
          name="category"
          options={categoryList || []}
          isClearable={true}
          isDisabled={multipleFilter}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option._id}
        />
        <Select
          placeholder="Select Sub Category"
          className="bg-gray-100  rounded outline-0  sm:w-72 w-full font-semibold"
          classNamePrefix="select"
          value={selectedSubCategory}
          onChange={(obj) => onChangeSelect(obj, "sub_category")}
          name="sub_category"
          options={subCategoryList || []}
          isClearable={true}
          isDisabled={multipleFilter}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option._id}
        />
        <button
          className="bg-blue-900 px-3 py-1 rounded text-gray-100"
          onClick={() => onClear()}
        >
          Clear Filter
        </button>
        <i
          className="fa-solid fa-filter border p-2 border-blue-700 text-blue-700 rounded cursor-pointer"
          onClick={sidebarFilterHandler}
        ></i>
      </div>

      <div>
        <button
          className="bg-blue-900 px-3 py-2.5 rounded text-gray-100"
          onClick={() => assetAddUpdateHandler()}
        >
          + Add Asset
        </button>
      </div>
    </div>
  );
};

export default TopFilter;
