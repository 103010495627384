import axios from 'axios';
import { Urls, BASE_URL } from 'store/urls';

export const getShiftTagsRepo = (page, limit) => {
  return axios({
    baseURL: BASE_URL,
    method: 'GET',
    url: `${Urls.GET_SHIFT_TAGS}?page_number=${page}&limit=${limit}`,
  });
};

export const addShiftTagRepo = (tag) => {
  return axios({
    baseURL: BASE_URL,
    method: 'post',
    url: Urls.POST_SHIFT_TAG,
    data: tag,
  });
};

export const updateShiftTagRepo = (tag) => {
  return axios({
    baseURL: BASE_URL,
    method: 'put',
    url: Urls.PUT_SHIFT_TAG,
    data: tag,
  });
};

export const deleteShiftTagRepo = (id) => {
  return axios({
    baseURL: BASE_URL,
    method: 'delete',
    url: Urls.DELETE_SHIFT_TAG + id,
  });
};

export const searchShiftTagRepo = (obj) => {
  const data = {
    limit: obj?.limit || 25,
    pageNumber: obj?.page || 1,
    status: obj?.status || '',
    searchKey: obj?.searchKey || '',
  };

  return axios({
    baseURL: BASE_URL,
    method: 'POST',
    url: Urls.SEARCH_SHIFTTTAG,
    data,
  });
};
