import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useAuth from "hooks/useAuth";
import styled from "styled-components/macro";
import axios from "axios";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { PAGE_FORGOT_PASSWORD } from "auth/constants";
import "./Login.css";
import { sendLoginRequest } from "../../features/login/LoginSlice";
import { useDispatch } from "react-redux";

const Container = styled.div`
  background-color: white;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 9999,
    color: "#fff",
  },
}));

// const MyButton = styled(Button)({
//   background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
//   border: 0,
//   borderRadius: 3,
//   boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
//   color: "white",
//   height: 48,
//   padding: "0 30px"
// });

const Login = function (props) {
  const auth = useAuth();
  const classes = useStyles();
  const dispatch = useDispatch();

  // console.log("Auth >>", auth);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [infoType, setInfoType] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [logginIn, setLogginIn] = useState(false);
  useEffect(() => {
    if (!logginIn) {
      setIsLoading(true);
      const checkLogin = async () => {
        let token = localStorage.getItem("token");
        if (token) {
          const result = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/users/validatetoken`
          );
          // console.log("Result ", result);
          if (result.status === 200) {
            await auth.fetchUser(token, result.data.data._id);
            setIsLoading(false);
            props.history.push("/");
          }
          setIsLoading(false);
        }
        setIsLoading(false);
      };

      checkLogin();
      setIsLoading(false);
    }
    setIsLoading(false);
  }, [auth, props.history]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleLogin = async function (e) {
    e.preventDefault();

    if (!username) {
      setInfoType("error");
      setErrorMessage("Please enter username");
      return;
    }
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(String(username).toLowerCase())) {
      setInfoType("error");
      setErrorMessage("Please enter a valid username");
      return;
    }
    setErrorMessage(null);
    setLogginIn(true);
    setIsLoading(true);
    e.preventDefault();
    // console.log("State ", username, password);
    const onLogin = async (payload) => {
      dispatch(sendLoginRequest(payload))
        .then((data) => {
          const response = data.payload;
          console.log("response", response);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    setTimeout(async () => {
      await auth.signin(username.toLowerCase(), password);

      let payload = {
        username: username.toLocaleLowerCase(),
        password: password,
        device_type: "web",
        device_token: "",
      };
      setIsLoading(false);
      onLogin(payload);

      // console.log("login successfull");
      // console.log("Props ", props);
      props.history.push("/");
      // setUsername("");
      // setPassword("");
    }, 2000);
  };

  // const handleLogout = function () {
  //   setUsername("");
  //   setPassword("");
  //   auth.signout(function () {
  //     // console.log("Logout successfull");
  //   });
  // };

  return (
    <Container style={{ zoom: "110%" }}>
      <div id="loginform">
        <img
          alt="logo"
          src="https://glentraffic.ca/wp-content/uploads/2021/08/GlenTraffic-logo.png"
          style={{ width: "200px", height: "50px" }}
        />
        <h2 id="headerTitle">Welcome</h2>
        <p style={{ color: "#083864", margin: "12px" }}>
          Continue with your company domain ID & password
        </p>

        <div>
          <div className="row">
            <label>User ID</label>
            <input
              type={"text"}
              value={username}
              placeholder={"Enter your company domain ID"}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="row">
            <label>Password</label>
            <input
              type={"password"}
              value={password}
              placeholder={"Enter password"}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div id="button" className="row">
            <button onClick={handleLogin}>Log In</button>
          </div>
        </div>

        {auth.isError &&
          auth.error &&
          auth.error.response.data &&
          auth.error.response.data.error && (
            <span>
              {" "}
              <span style={{ color: "#EE433C", textTransform: "capitalize" }}>
                {auth.error.response.data.error}
              </span>
            </span>
          )}
        {errorMessage && (
          <span style={infoType === "error" ? { color: "red" } : {}}>
            {errorMessage}
          </span>
        )}
        <br></br>
        <Link
          style={{ color: "#083864", textAlign: "center" }}
          to={PAGE_FORGOT_PASSWORD}
        >
          Forget Password ?
        </Link>
        <br></br>
        <footer>
          <div className="footer"> © Powered By Liquet IT Solutions</div>
        </footer>
      </div>

      <div className="dashboard-image"></div>

      {isLoading && (
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Container>
  );
};
export default Login;
