import {
  getResignationByUserIdRepo,
  postResignationByUserIdRepo,
} from "../../repositories/EmployeeManagement/Resign";

import {
  getResignationPending,
  getResignationFullfilled,
  getResignationRejected,
  postResignationByUserIdPending,
  postResignationByUserIdFullfilled,
  postResignationByUserIdRejected,
} from "../../slices/EmployeeManagement/Resign";

export const getResignationByUserId = (id) => {
  return async (dispatch) => {
    dispatch(getResignationPending());
    await getResignationByUserIdRepo(id)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getResignationFullfilled(response?.data?.data));
        } else {
          dispatch(getResignationRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(getResignationRejected(error.message));
        }
      });
  };
};

export const postResignationByUserId = (data) => {
  return async (dispatch) => {
    dispatch(postResignationByUserIdPending());
    await postResignationByUserIdRepo(data)
      .then((response) => {
        if (response.status === 200) {
          dispatch(postResignationByUserIdFullfilled(response?.data?.data));
        } else {
          dispatch(postResignationByUserIdRejected(response?.message));
        }
      })
      .catch((error) => {
        if (error && error.response) {
          dispatch(postResignationByUserIdRejected(error.message));
        }
      });
  };
};
