import React, { useState, useEffect } from 'react';
import { Typography, IconButton } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';

import { useDasboardStyles } from './Style';
import SJobSiteCard from './SJobSiteCard';

function SProjectCard(props) {
  const classes = useDasboardStyles();
  const [row, setProjectRow] = useState(props.row);
  const [projectOpen, setProjectOpen] = useState(true);
  useEffect(() => {
    setProjectRow(props.row);
  }, [props.row]); //eslint-disable-line react-hooks/exhaustive-deps
  // console.log(props);
  return (
    <React.Fragment>
      <TableRow key={row._id} sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell
          component='th'
          scope='row'
          style={{ padding: '0px' }}
          colSpan={25}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0px',
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  width: '210px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                  style={{
                    textAlign: 'left',
                    marginLeft: '16px',
                  }}
                >
                  Project Total :
                </Typography>

                <Typography
                  style={{
                    fontWeight: '600',
                    textAlign: 'left',
                    marginLeft: '16px',
                  }}
                >
                  {row.projectTotal}
                </Typography>
              </div>
              <div> Project Name: {row.project_name}</div>
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                aria-label='expand row'
                size='small'
                onClick={() => setProjectOpen(!projectOpen)}
              >
                {projectOpen ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            </div>
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={25}>
          <Collapse in={projectOpen} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table
                className={classes.table}
                size='small'
                aria-label='purchases'
                style={{ tableLayout: 'fixed' }}
              >
                <TableBody>
                  {row.jobsites ? (
                    row.jobsites.map((siteRow, index) => (
                      <SJobSiteCard
                        showUnscheduledEmps={props.showUnscheduledEmps}
                        key={index + row._id}
                        siteRow={siteRow}
                        cellWidth={props.cellWidth}
                        selectedScheduleRange={props.selectedScheduleRange}
                        afterOpn={props.afterOpn}
                        id={row._id}
                        handleLoading={props.handleLoading}
                        setAssignShiftError={props.setAssignShiftError}
                        scheduleData={props.scheduleData}
                        weeks={props.weeks}
                      />
                    ))
                  ) : (
                    <></>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default SProjectCard;
