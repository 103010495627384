import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  resignation: {},
  postResignation: {},
};

export const resignationSlice = createSlice({
  name: "resignation",
  initialState,
  reducers: {
    getResignationPending(state, action) {
      return {
        ...state,
        resignation: { loading: true },
      };
    },
    getResignationFullfilled(state, action) {
      return {
        ...state,
        resignation: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    getResignationRejected(state, action) {
      return {
        ...state,
        resignation: {
          loading: false,
          rejected: action.payload,
        },
      };
    },

    postResignationByUserIdPending(state, action) {
      return {
        ...state,
        postResignation: { loading: true },
      };
    },
    postResignationByUserIdFullfilled(state, action) {
      return {
        ...state,
        postResignation: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    postResignationByUserIdRejected(state, action) {
      return {
        ...state,
        postResignation: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
  },
});

export const {
  getResignationPending,
  getResignationFullfilled,
  getResignationRejected,
  postResignationByUserIdPending,
  postResignationByUserIdFullfilled,
  postResignationByUserIdRejected,
} = resignationSlice.actions;

export default resignationSlice.reducer;
