import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orgUsers: {},
  orgCount: {}
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    getUsersByOrgIdPending(state, action) {
      return {
        ...state,
        orgUsers: { loading: true },
      };
    },
    getUsersByOrgIdFulfilled(state, action) {
      return {
        ...state,
        orgUsers: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    getUsersByOrgIdRejected(state, action) {
      return {
        ...state,
        orgUsers: {
          loading: false,
          rejected: action.payload,
        },
      };
    },

    getOrgCountPending(state, action) {
      return {
        ...state,
        orgCount: { loading: true },
      };
    },
    getOrgCountFulfilled(state, action) {
      return {
        ...state,
        orgCount: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    getOrgCountRejected(state, action) {
      return {
        ...state,
        orgCount: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
  },
});

export const {
  getUsersByOrgIdPending,
  getUsersByOrgIdFulfilled,
  getUsersByOrgIdRejected,
  getOrgCountPending,
  getOrgCountFulfilled,
  getOrgCountRejected
  
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
