import { getPunchinByUserRepo ,getLeavesByUserIdRepo} from "../../repositories/EmployeeManagement/AttendanceAndShifts";

import {
  getPunchinPending,
  getPunchinFullfilled,
  getPunchinRejected,
  getLeavesByUserPending,
  getLeavesByUserFullfilled,
  getLeavesByUserRejected
} from "../../slices/EmployeeManagement/AttendanceAndShifts";

export const getPunchinByUserId = (id) => {
    return async (dispatch) => {
      dispatch(getPunchinPending());
      await getPunchinByUserRepo(id)
        .then((response) => {
          if (response.status === 200) {
            dispatch(getPunchinFullfilled(response?.data?.data));
          } else {
            dispatch(getPunchinRejected(response?.message));
          }
        })
        .catch((error) => {
          if (error && error.response) {
            dispatch(getPunchinRejected(error.message));
          }
        });
    };
  };

  export const getLeavesByUserId = (id) => {
    return async (dispatch) => {
      dispatch(getLeavesByUserPending());
      await getLeavesByUserIdRepo(id)
        .then((response) => {
          if (response.status === 200) {
            dispatch(getLeavesByUserFullfilled(response?.data?.data));
          } else {
            dispatch(getLeavesByUserRejected(response?.message));
          }
        })
        .catch((error) => {
          if (error && error.response) {
            dispatch(getLeavesByUserRejected(error.message));
          }
        });
    };
  };

 

