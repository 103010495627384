import React from "react";
import {
  Typography,
  InputBase,
  InputLabel,
  Select,
  Button,
  MenuItem,
  FormControl,
  IconButton,
  // CssBaseline,
  Paper,
  Grid
} from "@material-ui/core";
import clsx from "clsx";
import "@fontsource/roboto";
// import useAuth from "hooks/useAuth";
import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { useDasboardStyles } from "./Style";
import SearchIcon from "@material-ui/icons/Search";
// import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import AvTimerIcon from "@material-ui/icons/AvTimer";
import AddIcon from "@material-ui/icons/Add";

export function SAllEmployees(props) {
  let theme = createTheme();
  theme = responsiveFontSizes(theme);
  const classes = useDasboardStyles();

  // const [selectedSchedule, setSelectedSchedule] = React.useState("");

  // const auth = useAuth();

  // const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    // API call goes here
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <div className="root">
        <ThemeProvider theme={theme}>
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: props.isDrawerOpen
            })}
          >
            <Typography
              style={{
                textAlign: "left",
                marignBottom: "20px",
                minWidth: "230px"
              }}
            >
              Home {">"} Shift Management {">"} All Employees
            </Typography>

            <Paper
              style={{
                padding: "20px 20px 50px 20px",
                minWidth: "230px",
                marginTop: "20px",
                minHeight: "800px"
              }}
            >
              {/* <Typography
                  variant =  "h5"
                  style={{
                    fontWeight:"600",
                    textAlign:"left",
                    marginBottom:"30px",
                    color:"rgb(22,33,91)"
                  }}
                >
                  Timesheets
                </Typography> */}

              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  marginBottom: "10px",
                  position: "relative"
                }}
              >
                <FormControl
                  variant="outlined"
                  style={{
                    minWidth: "225px",
                    marginRight: "20px",
                    marginBottom: "10px"
                  }}
                >
                  <InputLabel
                    // style={{
                    //   paddingLeft:"5px"
                    // }}
                    id="demo-simple-select-label-1"
                  >
                    Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label-1"
                    label="Schedules"
                    id="demo-simple-select"
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    <MenuItem value={10}>Active</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>

                <FormControl
                  variant="outlined"
                  style={{
                    width: "275px",
                    minWidth: "225px",
                    marginRight: "20px",
                    marginBottom: "10px"
                  }}
                >
                  <InputLabel
                    // style={{
                    //   paddingLeft:"5px"
                    // }}
                    id="demo-simple-select-label-2"
                  >
                    All Sites
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label-2"
                    label="All Sites"
                    id="demo-simple-select"
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    <MenuItem value={10}>Active</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>

                <Paper
                  style={{
                    width: "275px",
                    minWidth: "225px",
                    height: "56px",
                    backgroundColor: "rgb(248,248,248)",
                    marginRight: "50px",
                    marginBottom: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    style={{
                      paddingLeft: "5px"
                    }}
                    placeholder="  Search..."
                  />
                  <IconButton
                    style={{ paddingleft: "-5px", paddingRight: "0px" }}
                    aria-label="search"
                  >
                    <SearchIcon
                      style={{ paddingleft: "-5px", paddingRight: "0px" }}
                    />
                  </IconButton>
                </Paper>

                <Button
                  variant="contained"
                  style={{
                    color: "white",
                    backgroundColor: "rgb(31, 41, 156)",
                    height: "56px",
                    marginLeft: "auto"
                  }}
                >
                  <span style={{ fontSize: "1.5em" }}>+</span> &nbsp; Add
                  Employee
                </Button>
              </div>

              <hr
                style={{
                  margin: "20px -20px"
                }}
              ></hr>

              {/* apply mapping here ------------------ */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around"
                }}
              >
                <div className={classes.nameTagContainer}>
                  <div className={classes.nameTag}>S</div>
                </div>

                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={10}
                    md={4}
                    style={{ textAlign: "left", paddingLeft: "30px" }}
                  >
                    <Typography
                      className={classes.colorBlue}
                      variant="h6"
                      style={{
                        fontWeight: "600",
                        textAlign: "left"
                      }}
                    >
                      Bhupinder Singh (ID123456)
                    </Typography>

                    <Typography variant="body1" className={classes.colorBlue}>
                      Position:
                      <span style={{ fontWeight: "600" }}>
                        &nbsp;Manager, IT Infra and Security&nbsp;&nbsp;
                      </span>
                    </Typography>

                    <Typography variant="body1" className={classes.colorBlue}>
                      Schedule:
                      <span style={{ fontWeight: "600" }}>
                        &nbsp;Seaforth Supply Chain Solution
                      </span>
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={11}
                    md={8}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between"
                      // marginRight:"10px"
                    }}
                  >
                    <div
                      style={{
                        textAlign: "right",
                        color: "rgb(250,155,71)",
                        marginBottom: "10px"
                      }}
                    >
                      Tag: Covid Screening
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap"
                      }}
                    >
                      {/* <div>Schedule</div> */}
                      {/* <div>Availability</div>
                        <div>Assign Shift</div>
                        <div>Active</div> */}

                      <Button
                        style={{
                          padding: "5px",
                          margin: "auto"
                        }}
                      >
                        <EventAvailableIcon className={classes.colorBlue} />
                        &nbsp;
                        <Typography
                          className={classes.colorBlue}
                          variant="subtitle2"
                        >
                          Schedule
                        </Typography>
                      </Button>

                      <Button
                        style={{
                          padding: "5px",
                          margin: "auto"
                        }}
                      >
                        <AvTimerIcon className={classes.colorBlue} />
                        &nbsp;
                        <Typography
                          className={classes.colorBlue}
                          variant="subtitle2"
                        >
                          Availability
                        </Typography>
                      </Button>

                      <Button
                        style={{
                          padding: "5px",
                          margin: "auto"
                        }}
                      >
                        <AddIcon className={classes.colorBlue} />
                        &nbsp;
                        <Typography
                          className={classes.colorBlue}
                          variant="subtitle2"
                        >
                          Assign Shift
                        </Typography>
                      </Button>

                      <div
                        style={{
                          margin: "auto",
                          padding: "5px",
                          display: "inline-flex"
                        }}
                      >
                        <div
                          style={{
                            width: "20px",
                            height: "20px",
                            borderRadius: "50%",
                            backgroundColor: "lightGreen",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                          }}
                        >
                          <div
                            style={{
                              width: "10px",
                              height: "10px",
                              borderRadius: "50%",
                              backgroundColor: "green"
                            }}
                          ></div>
                        </div>
                        &nbsp;&nbsp;
                        <Typography
                          style={{
                            color: "green"
                          }}
                          variant="subtitle2"
                        >
                          Active
                        </Typography>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <hr
                style={{
                  marginTop: "20px"
                }}
              ></hr>
              {/* map till here ---------------- */}

              {/* =======================REMOVE THIS========================= */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around"
                }}
              >
                <div className={classes.nameTagContainer}>
                  <div className={classes.nameTag}>S</div>
                </div>

                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={10}
                    md={4}
                    style={{ textAlign: "left", paddingLeft: "30px" }}
                  >
                    <Typography
                      className={classes.colorBlue}
                      variant="h6"
                      style={{
                        fontWeight: "600",
                        textAlign: "left"
                      }}
                    >
                      Bhupinder Singh (ID123456)
                    </Typography>

                    <Typography variant="body1" className={classes.colorBlue}>
                      Position:
                      <span style={{ fontWeight: "600" }}>
                        &nbsp;Manager, IT Infra and Security&nbsp;&nbsp;
                      </span>
                    </Typography>

                    <Typography variant="body1" className={classes.colorBlue}>
                      Schedule:
                      <span style={{ fontWeight: "600" }}>
                        &nbsp;Seaforth Supply Chain Solution
                      </span>
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={11}
                    md={8}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between"
                      // marginRight:"10px"
                    }}
                  >
                    <div
                      style={{
                        textAlign: "right",
                        color: "rgb(250,155,71)",
                        marginBottom: "10px"
                      }}
                    >
                      Tag: Covid Screening
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap"
                      }}
                    >
                      {/* <div>Schedule</div> */}
                      {/* <div>Availability</div>
                        <div>Assign Shift</div>
                        <div>Active</div> */}

                      <Button
                        style={{
                          padding: "5px",
                          margin: "auto"
                        }}
                      >
                        <EventAvailableIcon className={classes.colorBlue} />
                        &nbsp;
                        <Typography
                          className={classes.colorBlue}
                          variant="subtitle2"
                        >
                          Schedule
                        </Typography>
                      </Button>

                      <Button
                        style={{
                          padding: "5px",
                          margin: "auto"
                        }}
                      >
                        <AvTimerIcon className={classes.colorBlue} />
                        &nbsp;
                        <Typography
                          className={classes.colorBlue}
                          variant="subtitle2"
                        >
                          Availability
                        </Typography>
                      </Button>

                      <Button
                        style={{
                          padding: "5px",
                          margin: "auto"
                        }}
                      >
                        <AddIcon className={classes.colorBlue} />
                        &nbsp;
                        <Typography
                          className={classes.colorBlue}
                          variant="subtitle2"
                        >
                          Assign Shift
                        </Typography>
                      </Button>

                      <div
                        style={{
                          margin: "auto",
                          padding: "5px",
                          display: "inline-flex"
                        }}
                      >
                        <div
                          style={{
                            width: "20px",
                            height: "20px",
                            borderRadius: "50%",
                            backgroundColor: "lightGreen",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                          }}
                        >
                          <div
                            style={{
                              width: "10px",
                              height: "10px",
                              borderRadius: "50%",
                              backgroundColor: "green"
                            }}
                          ></div>
                        </div>
                        &nbsp;&nbsp;
                        <Typography
                          style={{
                            color: "green"
                          }}
                          variant="subtitle2"
                        >
                          Active
                        </Typography>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <hr
                style={{
                  marginTop: "20px"
                }}
              ></hr>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around"
                }}
              >
                <div className={classes.nameTagContainer}>
                  <div className={classes.nameTag}>S</div>
                </div>

                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={10}
                    md={4}
                    style={{ textAlign: "left", paddingLeft: "30px" }}
                  >
                    <Typography
                      className={classes.colorBlue}
                      variant="h6"
                      style={{
                        fontWeight: "600",
                        textAlign: "left"
                      }}
                    >
                      Bhupinder Singh (ID123456)
                    </Typography>

                    <Typography variant="body1" className={classes.colorBlue}>
                      Position:
                      <span style={{ fontWeight: "600" }}>
                        &nbsp;Manager, IT Infra and Security&nbsp;&nbsp;
                      </span>
                    </Typography>

                    <Typography variant="body1" className={classes.colorBlue}>
                      Schedule:
                      <span style={{ fontWeight: "600" }}>
                        &nbsp;Seaforth Supply Chain Solution
                      </span>
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={11}
                    md={8}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between"
                      // marginRight:"10px"
                    }}
                  >
                    <div
                      style={{
                        textAlign: "right",
                        color: "rgb(250,155,71)",
                        marginBottom: "10px"
                      }}
                    >
                      Tag: Covid Screening
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap"
                      }}
                    >
                      {/* <div>Schedule</div> */}
                      {/* <div>Availability</div>
                        <div>Assign Shift</div>
                        <div>Active</div> */}

                      <Button
                        style={{
                          padding: "5px",
                          margin: "auto"
                        }}
                      >
                        <EventAvailableIcon className={classes.colorBlue} />
                        &nbsp;
                        <Typography
                          className={classes.colorBlue}
                          variant="subtitle2"
                        >
                          Schedule
                        </Typography>
                      </Button>

                      <Button
                        style={{
                          padding: "5px",
                          margin: "auto"
                        }}
                      >
                        <AvTimerIcon className={classes.colorBlue} />
                        &nbsp;
                        <Typography
                          className={classes.colorBlue}
                          variant="subtitle2"
                        >
                          Availability
                        </Typography>
                      </Button>

                      <Button
                        style={{
                          padding: "5px",
                          margin: "auto"
                        }}
                      >
                        <AddIcon className={classes.colorBlue} />
                        &nbsp;
                        <Typography
                          className={classes.colorBlue}
                          variant="subtitle2"
                        >
                          Assign Shift
                        </Typography>
                      </Button>

                      <div
                        style={{
                          margin: "auto",
                          padding: "5px",
                          display: "inline-flex"
                        }}
                      >
                        <div
                          style={{
                            width: "20px",
                            height: "20px",
                            borderRadius: "50%",
                            backgroundColor: "lightGreen",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                          }}
                        >
                          <div
                            style={{
                              width: "10px",
                              height: "10px",
                              borderRadius: "50%",
                              backgroundColor: "green"
                            }}
                          ></div>
                        </div>
                        &nbsp;&nbsp;
                        <Typography
                          style={{
                            color: "green"
                          }}
                          variant="subtitle2"
                        >
                          Active
                        </Typography>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <hr
                style={{
                  marginTop: "20px"
                }}
              ></hr>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around"
                }}
              >
                <div className={classes.nameTagContainer}>
                  <div className={classes.nameTag}>S</div>
                </div>

                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={10}
                    md={4}
                    style={{ textAlign: "left", paddingLeft: "30px" }}
                  >
                    <Typography
                      className={classes.colorBlue}
                      variant="h6"
                      style={{
                        fontWeight: "600",
                        textAlign: "left"
                      }}
                    >
                      Bhupinder Singh (ID123456)
                    </Typography>

                    <Typography variant="body1" className={classes.colorBlue}>
                      Position:
                      <span style={{ fontWeight: "600" }}>
                        &nbsp;Manager, IT Infra and Security&nbsp;&nbsp;
                      </span>
                    </Typography>

                    <Typography variant="body1" className={classes.colorBlue}>
                      Schedule:
                      <span style={{ fontWeight: "600" }}>
                        &nbsp;Seaforth Supply Chain Solution
                      </span>
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={11}
                    md={8}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between"
                      // marginRight:"10px"
                    }}
                  >
                    <div
                      style={{
                        textAlign: "right",
                        color: "rgb(250,155,71)",
                        marginBottom: "10px"
                      }}
                    >
                      Tag: Covid Screening
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap"
                      }}
                    >
                      {/* <div>Schedule</div> */}
                      {/* <div>Availability</div>
                        <div>Assign Shift</div>
                        <div>Active</div> */}

                      <Button
                        style={{
                          padding: "5px",
                          margin: "auto"
                        }}
                      >
                        <EventAvailableIcon className={classes.colorBlue} />
                        &nbsp;
                        <Typography
                          className={classes.colorBlue}
                          variant="subtitle2"
                        >
                          Schedule
                        </Typography>
                      </Button>

                      <Button
                        style={{
                          padding: "5px",
                          margin: "auto"
                        }}
                      >
                        <AvTimerIcon className={classes.colorBlue} />
                        &nbsp;
                        <Typography
                          className={classes.colorBlue}
                          variant="subtitle2"
                        >
                          Availability
                        </Typography>
                      </Button>

                      <Button
                        style={{
                          padding: "5px",
                          margin: "auto"
                        }}
                      >
                        <AddIcon className={classes.colorBlue} />
                        &nbsp;
                        <Typography
                          className={classes.colorBlue}
                          variant="subtitle2"
                        >
                          Assign Shift
                        </Typography>
                      </Button>

                      <div
                        style={{
                          margin: "auto",
                          padding: "5px",
                          display: "inline-flex"
                        }}
                      >
                        <div
                          style={{
                            width: "20px",
                            height: "20px",
                            borderRadius: "50%",
                            backgroundColor: "lightGreen",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                          }}
                        >
                          <div
                            style={{
                              width: "10px",
                              height: "10px",
                              borderRadius: "50%",
                              backgroundColor: "green"
                            }}
                          ></div>
                        </div>
                        &nbsp;&nbsp;
                        <Typography
                          style={{
                            color: "green"
                          }}
                          variant="subtitle2"
                        >
                          Active
                        </Typography>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <hr
                style={{
                  marginTop: "20px"
                }}
              ></hr>
              {/* ======= REMOVE THIS============================= */}
            </Paper>
          </main>
        </ThemeProvider>
      </div>
    </React.Fragment>
  );
}

// export default SM_Dashboard;
