import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  attendanceByOrg: {},
  leaves: {},
  updateLeaves: {},
};

export const leaveAttendanceHrSlice = createSlice({
  name: "leavesAttendanceHr",
  initialState,
  reducers: {
    getAttendanceByOrgPending(state, action) {
      return {
        ...state,
        attendanceByOrg: { loading: true },
      };
    },
    getAttendanceByOrgFulfilled(state, action) {
      return {
        ...state,
        attendanceByOrg: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    getAttendanceByOrgRejected(state, action) {
      return {
        ...state,
        attendanceByOrg: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
    getLeavesApprovalPending(state, action) {
      return {
        ...state,
        leaves: { loading: true },
      };
    },
    getLeavesApprovalFulfilled(state, action) {
      return {
        ...state,
        leaves: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    getLeavesApprovalRejected(state, action) {
      return {
        ...state,
        leaves: {
          loading: false,
          rejected: action.payload,
        },
      };
    },

    putLeavesUpdatePending(state, action) {
      return {
        ...state,
        updateLeaves: { loading: true },
      };
    },
    putLeavesUpdateFulfilled(state, action) {
      return {
        ...state,
        updateLeaves: {
          loading: false,
          fulfilled: action.payload,
        },
      };
    },
    putLeavesUpdateRejected(state, action) {
      return {
        ...state,
        updateLeaves: {
          loading: false,
          rejected: action.payload,
        },
      };
    },
  },
});

export const {
  getAttendanceByOrgPending,
  getAttendanceByOrgFulfilled,
  getAttendanceByOrgRejected,
  getLeavesApprovalPending,
  getLeavesApprovalFulfilled,
  getLeavesApprovalRejected,
  putLeavesUpdatePending,
  putLeavesUpdateFulfilled,
  putLeavesUpdateRejected,
} = leaveAttendanceHrSlice.actions;

export default leaveAttendanceHrSlice.reducer;
