import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  TextField,
  IconButton,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
import { withStyles } from "@material-ui/styles";
import "react-phone-input-labelled/dist/style.css";
import { makeStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

// ---- toggle switch ------

import { green } from "@material-ui/core/colors";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useDispatch, useSelector } from "react-redux";
import { addShiftTask, updateShiftTask } from "store/actions/ShiftManagement/ShiftTasks";

const GreenSwitch = withStyles({
  switchBase: {
    color: green[300],
    "&$checked": {
      color: green[500],
    },
    "&$checked + $track": {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

//  form element styling --------------------------
const formStyles = makeStyles((theme) => ({
  sectionHeading: {
    color: "#1F299C",
    fontWeight: "700",
    margin: "10px",
    marginBottom: "20px",
  },

  fieldContainer: {
    maxWidth: "250px",
    display: "inline-block",
    marginRight: "20px",
    // flexDirection: 'column'
  },

  subtitle: {
    marginLeft: "10px",
    color: "black",
    fontWeight: "600",
  },
  colorBlue: {
    color: "rgb(31,41,156)",
  },
  colorDarkBlue: {
    color: "rgb(22,33,91)",
  },
}));

// --------- CssTextFeild ----------------
const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "black",
    },
    "& .Mui-disabled": {
      color: "black",
    },

    "& .MuiInputBase": {
      background: "#F7F8FC",
      backgroundColor: "#F7F8FC",
    },
  },
})(TextField);

// ---------  Input for Snackbar -----------------

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}
// -----------------------------------------------

function AddShiftTaskDialog(props) {
  const myClasses = formStyles();
  const [formError, setFormError] = useState(null);
  const [shiftTaskTitle, setShiftTaskTitle] = useState("");
  const [activityList, setActivityList] = useState([]);
  // const [titleError, setTitleError] = useState(true);
  const [activeStatus, setActiveStatus] = useState("");
  const [newActivity, setNewActivity] = useState("");
  const [errOpen, setErrOpen] = React.useState(false);
  const [successOpen, setSucOpen] = React.useState(false);

  const dispatch = useDispatch();
  const { shiftTasks} = useSelector((shiftTasks)=> shiftTasks )
  const handleToggleSwitch = (event) => {
    if (props.operation !== "view") {
      setActiveStatus(!activeStatus);
    }
  };

  const handleCreateShiftTask = async () => {
    setSucOpen(false);
    setFormError(null);
    if (shiftTaskTitle !== "") {
        let data = {
          active: `${activeStatus}`,
          jobTask_title: shiftTaskTitle,
          activity_list: activityList,
        };
        dispatch(addShiftTask(data))
    } else {
      setFormError("Task title is a required field");
      setSucOpen(false);
    }
  };

  const handleUpdateShiftTask = async () => {
    setSucOpen(false);
    setFormError(null);
    if (shiftTaskTitle !== "") {
        let data = {
          _id: props.task._id,
          active: `${activeStatus}`,
          jobTask_title: shiftTaskTitle,
          activity_list: activityList,
        };

        await dispatch(updateShiftTask(data))
    } else {
      setFormError("Task title is a required field");
      setSucOpen(false);
    }
  };

  useEffect(()=>{
    if(shiftTasks?.success){
      setSucOpen(true);
      handleCloseDialog();
    }
    if(shiftTasks.rejeted){
      setFormError("Something went wrong!");
      setErrOpen(true);
      setSucOpen(false);
    }
  },[shiftTasks])

  const handleDeleteTag = (item, i) => {
    if (props.operation === "edit" || props.operation === "create") {
      let activity_list = activityList.filter((activity, idx) => {
        if (idx === i && activity === item) return false;
        return true;
      });
      setActivityList(activity_list);
    }
  };

  useEffect(() => {
    if (props.operation === "view" || props.operation === "edit") {
      if (props.task.active === "true") {
        setActiveStatus(true);
      } else {
        setActiveStatus(false);
      }
      setShiftTaskTitle(props.task.jobTask_title);
      setActivityList(props.task.activity_list);
    } else if (props.operation === "create") {
      setActiveStatus(false);
      setActivityList([]);
      setShiftTaskTitle("");
    }
  }, [props]);

  // const handleFormSubmit = async e => {
  //   e.preventDefault();

  //   try {
  //     let response;
  //     if (props.operation === "add") {
  //       // Call Create API
  //       response = await axios.post(
  //         `${process.env.REACT_APP_API_BASE_URL}/users/create`,
  //         body
  //       );
  //     } else {
  //       // Call Update API
  //       body._id = props._id;
  //       response = await axios.put(
  //         `${process.env.REACT_APP_API_BASE_URL}/users/update`,
  //         body
  //       );
  //     }

  //     if (response.status === 200) {
  //       console.log("Response Data >>", response.data.data);
  //       props.closeForm();
  //     }
  //   } catch (e) {
  //     if (e.response.status === 400) {
  //       setFormError("Some form value missing");
  //     }
  //   }
  // };

  const handleClose = (event, reason) => {
    setSucOpen(false);
    setSucOpen(false);
  };

  const handleCloseDialog = () => {
    setShiftTaskTitle("");
    setActivityList([]);
    setActiveStatus(false);
    setNewActivity("");
    props.closeForm();
  };

  const handleAddActivity = () => {
    if (newActivity.length > 0) {
      let activity_list = activityList;
      activity_list.push(newActivity);
      setActivityList(activity_list);
      setNewActivity("");
    }
  };

  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  return (
    <ThemeProvider theme={theme}>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "10px",
          }}
        >
          <Typography
            variant='h5'
            // className = {classes.colorDarkBlue}
            style={{
              fontWeight: "600",
              display: "flex",
              alignItems: "center",
            }}
          >
            {props.operation === "view"
              ? "Shift Task"
              : props.operation === "create"
              ? " Add Shift Task"
              : " Edit Shift Task"}
          </Typography>

          <IconButton
            aria-label='close'
            onClick={handleCloseDialog}
            style={{
              margin: "0",
              padding: "10px",
              color: "black",
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <hr></hr>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "20px",
            padding: "5px 10px",
            backgroundColor: "rgb(247,248,252)",
            borderRadius: "5px",
            boxShadow: "lightgray 0px 0px 5px",
          }}
        >
          <Typography
            variant='h6'
            className={myClasses.colorDarkBlue}
            style={{
              fontWeight: "600",
            }}
          >
            Active
          </Typography>

          <FormControlLabel
            control={
              <GreenSwitch
                onChange={handleToggleSwitch}
                checked={activeStatus}
              />
            }
          />
        </div>

        <Typography
          variant='h6'
          className={myClasses.colorDarkBlue}
          style={{
            fontWeight: "600",
            margin: "10px 20px",
          }}
        >
          List Title*
        </Typography>

        <CssTextField
          variant='outlined'
          style={{
            backgroundColor: "rgb(247,248,252)",
            boxShadow: "lightgray 0px 0px 5px",
            margin: "0px 20px",
            width: "300px",
          }}
          value={shiftTaskTitle}
          disabled={props.readOnly}
          onChange={(e) => {
            setShiftTaskTitle(e.target.value);
            setFormError(false);
          }}
        />

        <Typography
          variant='h5'
          className={myClasses.colorBlue}
          style={{
            fontWeight: "600",
            margin: "20px",
          }}
        >
          Activity List ({activityList.length})
        </Typography>

        {activityList &&
          activityList.map((activity, idx) => {
            return (
              <div
                className={myClasses.colorDarkBlue}
                style={{
                  display: "flex",
                  margin: "20px",
                  padding: "20px",
                  border: "1px solid lightgrey",
                  borderRadius: "3px",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p>{activity}</p>
                {props.operation === "edit" || props.operation === "create" ? (
                  <IconButton
                    aria-label='close'
                    onClick={() => handleDeleteTag(activity, idx)}
                    style={{
                      margin: "0",
                      padding: "0",
                      color: "red",
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                ) : (
                  ""
                )}
              </div>
            );
          })}

        {props.operation !== "view" && (
          <React.Fragment>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingRight: "20px",
              }}
            >
              <CssTextField
                variant='outlined'
                style={{
                  backgroundColor: "rgb(247,248,252)",
                  boxShadow: "lightgray 0px 0px 5px",
                  margin: "0px 20px",
                  width: "100%",
                }}
                className={myClasses.colorDarkBlue}
                value={newActivity}
                placeholder='Type activity name'
                disabled={props.readOnly}
                onChange={(e) => {
                  setNewActivity(e.target.value);
                  // setFnameError(false);
                }}
              />
              <div>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={handleAddActivity}
                >
                  Add
                </Button>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>

      {props.operation !== "view" && (
        <div>
          <hr></hr>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "0px 10px 10px 0px",
              margin: "20px 10px 0 0",
            }}
          >
            <Button
              variant='outlined'
              color='primary'
              style={{
                marginRight: "10px",
              }}
              onClick={handleCloseDialog}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              color='primary'
              onClick={
                props.operation === "create"
                  ? handleCreateShiftTask
                  : handleUpdateShiftTask
              }
            >
              Save
            </Button>
          </div>
        </div>
      )}

      {/* ---------------------------------------------- */}
      <React.Fragment>
        <Snackbar
          open={!!successOpen}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity='success'
            sx={{ width: "100%" }}
          >
            Saved!
          </Alert>
        </Snackbar>
        <Snackbar
          open={!!formError}
          autoHideDuration={5000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity='error' sx={{ width: "100%" }}>
            {formError}
          </Alert>
        </Snackbar>
      </React.Fragment>
    </ThemeProvider>
  );
}

export default AddShiftTaskDialog;
