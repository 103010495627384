import React from "react";
import TopFilter from "./TopFilter";
import SoftwareDeveloper from "./SoftwareDeveloper";

const Index = () => {
  return (
    <div className="bg-white text-left mt-4">
      <TopFilter />
      <div className="px-5 py-1 border">
        <SoftwareDeveloper />
      </div>
    </div>
  );
};

export default Index;
