import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Breadcrumbs,
  Container,
  Paper,
  makeStyles,
  Link,
  Typography,
  Grid,
  Tabs,
  Tab,
  Button,
  withStyles,
  TextField,
  Avatar,
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  DialogContentText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@material-ui/core";

import useAuth from "hooks/useAuth";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import "date-fns";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
// import protection from "../../assets/images/protection-shield-4.png";
import Autocomplete from "@material-ui/lab/Autocomplete";

import clsx from "clsx";
import { ViewState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  MonthView,
  WeekView,
  Appointments,
  Toolbar,
  DateNavigator,
  TodayButton,
  AppointmentTooltip,
} from "@devexpress/dx-react-scheduler-material-ui";
import { ExpandMore } from "@material-ui/icons";
import "./index.css";

const drawerWidth = 240;
const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    backgroundColor: "white",
    color: "#1F299C",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,

    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    maxWidth: "unset",
  },
  table: {
    minWidth: 700,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    borderRadius: "6px ",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: "#ffffff",
    color: "#1F299C",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 0,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
  large: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    border: "2px solid #FFFFFF",
  },
}));

// const currentDate = "2021-06-19";
// const schedulerData = [
//   {
//     startDate: "2021-06-13T10:45",
//     endDate: "2021-06-13T18:45",
//     title: "Description of the shift appears here"
//   },
//   {
//     startDate: "2021-06-14T10:45",
//     endDate: "2021-06-14T18:45",
//     title: "Description of the shift appears here"
//   },
//   {
//     startDate: "2021-06-15T10:45",
//     endDate: "2021-06-15T18:45",
//     title: "Description of the shift appears here"
//   },
//   {
//     startDate: "2021-06-16T09:45",
//     endDate: "2021-06-16T15:45",
//     title: "Description of the shift appears here"
//   },

//   {
//     startDate: "2021-06-17T08:45",
//     endDate: "2021-06-17T15:45",
//     title: "Description of the shift appears here"
//   },
//   {
//     startDate: "2021-06-18T09:45",
//     endDate: "2021-06-18T15:45",
//     title: "Description of the shift appears here"
//   },
//   {
//     startDate: "2021-06-20T09:45",
//     endDate: "2021-06-20T15:45",
//     title: "Description of the shift appears here"
//   }
// ];

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const Shifts = (props) => {
  const auth = useAuth();
  const classes = useStyles();
  // const [page] = React.useState(0);

  // const [rowsPerPage] = React.useState(10);

  // const [selectedDate] = useState(new Date("2021-06-18T21:11:54"));

  const [projectName, setProjectName] = React.useState("");

  const [shiftStartTime, setShiftStartTime] = React.useState(new Date());
  const [shiftEndTime, setShiftEndTime] = React.useState(new Date());
  const [openDialog, setOpenDialog] = React.useState(false);

  const [projectData, setProjectData] = React.useState("");
  const [shiftData, setShiftdata] = React.useState([]);
  const [empData, setEmpdata] = React.useState([]);
  const [currentEmployee, setCurrentEmployee] = React.useState("");
  const [jobRole, setJobRole] = React.useState("");
  const handleProjectName = (event) => {
    setProjectName(event.target.value);
  };
  // const [selectedRadioValue, setRadioSelectedValue] = React.useState(
  //   "half_day"
  // );

  const handleShiftStartChange = (date) => {
    setShiftStartTime(date);
  };
  const handleShiftEndChange = (date) => {
    setShiftEndTime(date);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleClickOpenAddProject = (emp) => {
    console.log("true");
    setCurrentEmployee(emp);
    setOpenDialog(true);
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel, id) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    getShiftByUser(id);
  };
  const [currentDate, setCurrentDate] = useState(new Date());
  const [tabValue, setTabValue] = useState(0);

  const [currentViewName, setCurrentViewName] = useState("work-week");

  const handleClick = (event) => {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  };

  // const handleDateChange = date => {
  //   setSelectedDate(date);
  // };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    switch (newValue) {
      case 0:
        setCurrentViewName("work-week");
        break;
      case 1:
        setCurrentViewName("work-month");
        break;
      default:
        break;
    }
  };

  const [empOptions, setEmpOptions] = React.useState([]);

  function createSearchBarData(optionData) {
    return {
      title: `${optionData.first_name} ${optionData.last_name}`,
      ...optionData,
    };
  }
  const getAllEmployees = async () => {
    const orgId = auth.user.org_id._id;
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/users/getbyorg/${orgId}?page_number=${""}&limit=${""}`
      );
      if (response.status === 200) {
        const data = response.data.data;

        setEmpdata(data.data);

        console.log("data.data", data.data);
        // ##### used by search box
        let optionsDataArray = [];

        data.data.map((item) => {
          return optionsDataArray.push(createSearchBarData(item));
        });

        setEmpOptions(optionsDataArray);
      }
    } catch (e) {
      if (e.response) {
        console.log("Error >", e.response);
      }
    }
  };

  const getShiftByUser = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/shift/getbyuserid/${id}`
      );
      if (response.status === 200) {
        const data = response.data.data;
        let calenderData = [];
        if (data && data.length) {
          data.forEach((item) => {
            calenderData.push({
              startDate: item.start_time,
              endDate: item.end_time,
              title: item.instructions,
            });
          });
        }
        setShiftdata(calenderData);
      }
    } catch (e) {
      if (e.response) {
        console.log("Error >", e.response);
      }
    }
  };
  const getAllProjects = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/project/getAllProjects`
      );
      if (response.status === 200) {
        const data = response.data.data;
        setProjectData(data);
      }
    } catch (e) {
      if (e.response) {
        console.log("Error >", e.response);
      }
    }
  };
  // const getAllShifts = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API_BASE_URL}/shift/getAllshifts`
  //     );
  //     if (response.status === 200) {
  //       console.log("all shifts", response.data.data);
  //       // const data = response.data.data;
  //     }
  //   } catch (e) {
  //     if (e.response) {
  //       console.log("Error >", e.response);
  //     }
  //   }
  // };

  async function arrayMove(arr, fromIndex, toIndex) {
    let element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
    let newArr = [...arr];
    setEmpdata(newArr);
    console.log(empData);
  }

  const handleSaveShift = async (e) => {
    e.preventDefault();
    const orgId = auth.user.org_id._id;

    try {
      let data = {
        project_id: projectName._id,
        type: "Schedule",
        org_id: orgId,
        name: projectName.project_name,
        start_time: shiftStartTime,
        end_time: shiftEndTime,
        instructions: "Technology",
        members: [currentEmployee._id],
        job_role: jobRole,
        is_active: "true",
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/shift/create`,
        data
      );
      if (response.status === 200) {
        console.log("shift creation", response.data.data);
        setOpenDialog(false);
      }
    } catch (e) {
      if (e.response) {
        console.log("Error >", e.response);
      }
    }
  };

  useEffect(() => {
    getAllProjects();
    getAllEmployees();
    // getAllShifts();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <div className={classes.root}>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: props.isDrawerOpen,
          })}
        >
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize='small' />}
            aria-label='breadcrumb'
          >
            <Link color='inherit' onClick={handleClick}>
              Leaves & Attendance
            </Link>
            <Link color='inherit' onClick={handleClick}>
              Employee Project Shifts
            </Link>
          </Breadcrumbs>
          <Grid container spacing={3}>
            <br></br>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <h2 style={{ color: "#1F299C", textAlign: "left" }}>
                Employee Project Shifts{" "}
              </h2>

              {/* Add autocomplete searchbar */}
              {/* ########################################################################################
             ############################################################################################## */}
              {empOptions && (
                <Autocomplete
                  id='combo-box-demo'
                  options={empOptions}
                  getOptionLabel={(option) =>
                    `${option.first_name} (${option.emp_code})`
                  }
                  style={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Search Employee'
                      variant='outlined'
                    />
                  )}
                  onChange={(e) => {
                    console.log(
                      "Selected Employee -------->",
                      e.target.innerText
                    );
                    // use the emp_code of target to search for the emp in array
                    // delete the element from array
                    // prepend this emp at postition 0 of array

                    const regExp = /\(([^)]+)\)/;
                    let matches = regExp.exec(e.target.innerText);
                    matches && console.log("matches --> ", matches[1]);

                    if (matches) {
                      let index = empData.findIndex(
                        (x) => x.emp_code === matches[1]
                      );
                      console.log("index: ", index);
                      arrayMove(empData, index, 0);
                    }
                  }}
                />
              )}
            </div>

            {/* ########################################################################################
             ############################################################################################## */}

            {empData &&
              empData.map((emp, index) => {
                return (
                  <Grid item xs={10} md={12} lg={12}>
                    <Accordion
                      square={false}
                      style={{ backgroundColor: "white", margin: "12px 0" }}
                      expanded={expanded === "panel" + index}
                      onChange={handleChange("panel" + index, emp._id)}
                    >
                      <AccordionSummary
                        aria-controls='panel1d-content'
                        id='panel1d-header'
                        style={{
                          backgroundColor: "white",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        expandIcon={
                          <ExpandMore
                            style={{ color: "#2E6BDC", fontWeight: "600" }}
                          />
                        }
                      >
                        <Grid container>
                          <Grid
                            item
                            xs={2}
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Avatar
                              alt='Image'
                              src={auth.user.image_url || ""}
                              className={classes.large}
                            />
                            &nbsp; &nbsp; &nbsp; &nbsp;
                            <Typography
                              style={{ color: "#2E6BDC", fontWeight: "600" }}
                            >
                              {emp.first_name}
                            </Typography>
                          </Grid>
                          <Grid item xs={10}>
                            <Button
                              variant='contained'
                              style={{
                                backgroundColor: "#1F299C",
                                color: "white",
                                marginLeft: "60%",
                              }}
                              onClick={(event) => {
                                event.stopPropagation();
                                handleClickOpenAddProject(emp);
                              }}
                            >
                              create shift
                            </Button>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid item xs={12} md={12} lg={12}>
                          <Paper>
                            <Scheduler data={shiftData} height={660}>
                              <ViewState
                                currentDate={currentDate}
                                currentViewName={currentViewName}
                                onCurrentDateChange={(newCurrentName) =>
                                  setCurrentDate(newCurrentName)
                                }
                                onCurrentViewNameChange={(viewName) =>
                                  console.log("viewName", viewName)
                                }
                              />

                              <WeekView
                                name='work-week'
                                startDayHour={10}
                                endDayHour={20}
                              />
                              <MonthView name='work-month' />
                              <Toolbar style={{ color: "#1F299C" }} />
                              <DateNavigator />
                              <TodayButton />

                              <Appointments />
                              <AppointmentTooltip showCloseButton />
                              <Tabs
                                TabIndicatorProps={{
                                  style: { backgroundColor: "white" },
                                }}
                                value={tabValue}
                                variant='scrollable'
                                onChange={handleTabChange}
                                indicatorColor='primary'
                                style={{
                                  border: "1px solid #1F299C",
                                  padding: " 4px",
                                  borderRadius: "52px",
                                  color: "#1F299C",
                                }}
                              >
                                <Tab
                                  label=' Current Week Shift'
                                  {...a11yProps(0)}
                                  style={
                                    tabValue === 0
                                      ? {
                                          backgroundColor: "#1F299C",
                                          color: "#ffffff",
                                          borderRadius: "40px",
                                        }
                                      : {
                                          borderRadius: "40px",
                                        }
                                  }
                                />
                                <Tab
                                  label='Monthly Shifts'
                                  {...a11yProps(1)}
                                  style={
                                    tabValue === 1
                                      ? {
                                          backgroundColor: "#1F299C",
                                          color: "#ffffff",
                                          borderRadius: "40px",
                                        }
                                      : {
                                          borderRadius: "40px",
                                        }
                                  }
                                />
                              </Tabs>
                            </Scheduler>
                          </Paper>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                );
              })}
          </Grid>
          <Dialog
            open={openDialog}
            onClose={handleClose}
            aria-labelledby='form-dialog-title'
            style={{ padding: "12px" }}
          >
            <DialogTitle style={{ color: "#1F299C" }} id='form-dialog-title'>
              Create Shift
            </DialogTitle>
            <Divider />
            <DialogContent>
              <DialogContentText>
                To add a shift, please fill in the details below.
              </DialogContentText>
              <TextField
                margin='dense'
                id='name'
                label='Employee Name'
                type='text'
                value={currentEmployee.first_name}
                fullWidth
                disabled
              />
              <FormControl component='fieldset'>
                <br></br>
                <FormControl variant='outlined'>
                  <InputLabel id='demo-simple-select-outlined-label'>
                    Project Name
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId='demo-simple-select-outlined-label'
                    id='demo-simple-select-outlined1'
                    value={projectName}
                    onChange={handleProjectName}
                    label=' Project Name'
                  >
                    {projectData &&
                      projectData.map((project) => {
                        return (
                          <MenuItem value={project}>
                            {project.project_name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                <br></br>
                <FormControl variant='outlined'>
                  <InputLabel id='demo-simple-select-outlined-label1'>
                    Job Role
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId='demo-simple-select-outlined-label1'
                    id='select-job-role'
                    value={jobRole}
                    onChange={(e) => {
                      setJobRole(e.target.value);
                    }}
                    label=' Job Role'
                  >
                    <MenuItem value='ECP'>ECP</MenuItem>
                    <MenuItem value='LCD'>LCD</MenuItem>
                    <MenuItem value='HSO'>HSO</MenuItem>
                    <MenuItem value='supervisor'>Supervisor</MenuItem>
                    <MenuItem value='coordinator'>Coordinator</MenuItem>
                    <MenuItem value='Order Picker'>Order Picker</MenuItem>
                    <MenuItem value='Forklift Driver'>Forklift Driver</MenuItem>
                    <MenuItem value='General Labour'>General Labour</MenuItem>
                  </Select>
                </FormControl>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <Grid container justify='space-between'>
                    {/* <Grid item xs={4}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog1"
                      label="Start Date"
                      value={shiftStartDate}
                      format="ddd/MM/yyyy"
                      onChange={handleStartDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </Grid> */}
                    <Grid item xs={5}>
                      <DateTimePicker
                        margin='normal'
                        id='date-picker-dialog2'
                        placeholder='08:00 AM'
                        label=' Start Time'
                        value={shiftStartTime}
                        showTodayButton
                        disablePast
                        onChange={handleShiftStartChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </Grid>
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    <Grid item xs={5}>
                      <DateTimePicker
                        margin='normal'
                        disablePast
                        showTodayButton
                        id='date-picker-dialog3'
                        placeholder='08:00 AM'
                        label=' End Time'
                        value={shiftEndTime}
                        onChange={handleShiftEndChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </Grid>
                  </Grid>
                </MuiPickersUtilsProvider>

                <br></br>
                <br></br>
              </FormControl>
            </DialogContent>
            <br></br>
            <br></br>
            <Divider />
            <br></br>

            <DialogActions>
              <Button
                variant='contained'
                style={{
                  color: "#1F299C",
                  border: "1px solid #1F299C",
                  backgroundColor: "white",
                }}
                onClick={handleClose}
                color='primary'
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                style={{ backgroundColor: "#1F299C", color: "white" }}
                onClick={handleSaveShift}
                color='primary'
              >
                Save
              </Button>
            </DialogActions>
            <br></br>
            <br></br>
          </Dialog>
        </main>
      </div>
    </Container>
  );
};

export default Shifts;
