import React, { useState, useEffect } from "react";
import {
  Button,
  Divider,
  TextareaAutosize,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { postAddMissedPunch } from "../../store/actions/EmployeeManagement/HomePage";
const AddMissedPunchForm = (props) => {
  const {
    auth,
    setPunchingError,
    setIsLoading,
    setDisplayMessage,
    setPunchingSuccess,
    setDisplayError,
    openMissedPunchin,
    setOpenMissedPunchin,
  } = props;

  const { postMissedPunchData } = useSelector((state) => state.homepage);
  const dispatch = useDispatch();
  const [missedpunchoutTime, setMissedPunchoutTime] = useState(
    moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
  );
  const [missedPunchDate, setMissedPunchDate] = useState(new Date());
  const [missedPunchinTime, setMissedMissedPunchinTime] = useState(
    moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
  );
  const [missedPunchNote, setMissedPunchNote] = useState("");

  const handleMissedPunchin = async () => {
    if (!missedPunchDate || !missedPunchinTime || !missedpunchoutTime) {
      setPunchingError("Missing Form Value !");
      return;
    }
    setIsLoading(true);
    const userId = auth.user._id;
    let day = moment(missedPunchDate).format("DD");
    let month = moment(missedPunchDate).format("MM") - 1;
    let year = moment(missedPunchDate).format("YYYY");
    let fromTime = moment(missedPunchinTime).format("YYYY-MM-DD hh:mm:ss A");
    let toTime = moment(missedpunchoutTime).format("YYYY-MM-DD hh:mm:ss A");
    dispatch(
      postAddMissedPunch({
        emp_id: userId,
        day: `${day}`,
        month: `${month}`,
        year: `${year}`,
        punchin_time: fromTime,
        punchout_time: toTime,
        missed_punch_note: missedPunchNote,
        type: "missed",
        status: "Pending",
      })
    );
    setMissedPunchNote("");
    setOpenMissedPunchin(false);
  };

  useEffect(() => {
    if (postMissedPunchData?.fullfilled) {
      setIsLoading(false);
      setDisplayMessage("Missed Punchin submission successful!");
      setPunchingSuccess("Missed Punch submission succesful!");
    }
    if (postMissedPunchData?.rejected) {
      setIsLoading(false);
      setDisplayError(postMissedPunchData?.rejected);
      setPunchingError("You dont have any attendence in selected date");
    }
  }, [postMissedPunchData]);

  return (
    <Dialog
      open={openMissedPunchin}
      onClose={() => setOpenMissedPunchin(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle
        style={{ color: "#1F299C", padding: "20px 24px 0px 24px" }}
        id="form-dialog-title"
      >
        <Typography variant="h4" fontWeight="600">
          Add Missed Punchin
        </Typography>
      </DialogTitle>
      <br></br>
      <Divider />
      <DialogContent>
        {/* <DateTimePicker /> */}

        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            label="Missed Date"
            format="DD/MM/yyyy"
            value={missedPunchDate}
            onChange={(date) => {
              setMissedPunchDate(date);
            }}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
          <KeyboardTimePicker
            label="Punchin Time"
            // placeholder="08:00 AM"
            value={missedPunchinTime}
            onChange={(time) => {
              setMissedMissedPunchinTime(time);
            }}
          />
          <KeyboardTimePicker
            label="Punchout Time"
            // placeholder="08:00 AM"
            value={missedpunchoutTime}
            onChange={(time) => {
              setMissedPunchoutTime(time);
            }}
          />
        </MuiPickersUtilsProvider>

        {/* <TextField
        autoFocus
        name="missedPunchReason"
        margin="dense"
        id="reason"
        value={missedPunchNote}
        label="Missed Punch Note"
        type="text"
        fullWidth
        onChange={event => setMissedPunchNote(event.target.value)}
      /> */}

        <h4 style={{ margin: "30px 0px 10px 0px" }}>
          Missed Punch Note (Optional)
        </h4>
        <TextareaAutosize
          name="missedPunchReason"
          onChange={(e) => setMissedPunchNote(e.target.value)}
          style={{ width: "90%", height: "110px", padding: "10px" }}
          value={missedPunchNote}
          aria-label="minimum height"
          minRows={10}
          placeholder="Enter reason for Missed Punch"
        />
      </DialogContent>
      <Divider />
      <br></br>
      <DialogActions>
        <Button
          onClick={() => setOpenMissedPunchin(false)}
          variant="contained"
          style={{
            color: "#1F299C",
            border: "1px solid #1F299C",
            backgroundColor: "white",
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={handleMissedPunchin}
          variant="contained"
          style={{ backgroundColor: "#1F299C", color: "white" }}
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AddMissedPunchForm;
